<template>
  <div class="addCart">
    <div class="goodsAndSum">
      <div class="goods">
        <div class="goods_title">我的购物车<span>1件商品</span></div>
        <div class="s"></div>
        <div class="goods_check">
          <div class="check">
            <el-checkbox v-model="checked"></el-checkbox>
          </div>
          <div class="img">
            <img src="../../assets/img/index/sp1.png" alt="sp" />
          </div>
          <div class="name">商品名称</div>
        </div>
        <div class="goods_details">
          <div class="details_imgs">
            <div class="imgs">
              <div class="imgs_igm">
                <img src="../../assets/img/index/sp1.png" alt="sp" />
              </div>
              <div class="imgs_name">goods</div>
            </div>
            <div class="imgs">
              <div class="imgs_igm">
                <img src="../../assets/img/index/sp1.png" alt="sp" />
              </div>
              <div class="imgs_name">goods</div>
            </div>
          </div>
          <div class="details_desc">
            <div class="desc_price">￥5000</div>
            <div class="desc_like">
              <i class="icon">&#xe6a9;</i>
              移入收藏夹
            </div>
            <div class="desc_delete">删除</div>
          </div>
        </div>
      </div>
      <div class="sum">
        <total></total>
      </div>
    </div>
  </div>
</template>
<script>
import total from "../../components/cart/total";
export default {
  props: [],
  data() {
    return {
      checked: "",
    };
  },
  mounted() {},
  methods: {},
  components: {
    total,
  },
};
</script>
<style lang="less" scoped>
.addCart {
      margin-top: 140px;
  .goodsAndSum {
    width: 77%;
    margin: 0 auto;
    display: flex;
    padding: 20px 0 40px 0;
    justify-content: space-between;
    .goods {
      width: 60.7%;
      .s {
        border-top: 1px solid #ccc;
        margin: 30px 0;
      }
      .goods_title {
        font-size: 24px;
        color: #000011;
        font-weight: 700;
        span {
          margin-left: 29px;
          font-size: 16px;
          color: #111;
        }
      }
      .goods_check {
        display: flex;
        align-items: center;
        .img {
          width: 22%;
          padding: 20px 0;
          background-color: #fff;
          margin-left: 32px;
          margin-right: 50px;
        }
        .name {
          align-self: start;
        }
      }
      .goods_details {
        display: flex;
        margin-top: 26px;
        .details_imgs {
          width: 61%;
          min-height: 120px;
          padding: 30px 60px;
          background-color: #e5e5e5;
          .imgs {
            display: flex;
            align-content: center;
            margin-top: 22px;
            .imgs_igm {
              width: 120px;
              background-color: #fff;
              padding: 10px;
              margin-right: 20px;
              img {
                width: 100%;
              }
            }
            .imgs_name {
              line-height: 60px;
            }
          }
        }
        .details_desc {
          display: flex;
          width: 38%;
          justify-content: space-around;
          align-items: center;

          div {
            font-size: 14px;
            color: #111;
          }
          .desc_price {
            font-size: 18px;
            color: #e60012;
          }
          .desc_like {
            min-width: 70px;
          }
          .desc_delete {
            text-decoration: underline;
          }
        }
      }
    }
    .sum {
      width: 32.3%;
    }
  }
}
</style>
