<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="11"></navbar>
        <div class="mainbox">
          <div class="title">意见反馈</div>
          <div class="showbox">
            <div class="waittext">期待您的宝贵意见</div>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="写下您的意见，我们会改进的哦~"
              v-model="textarea"
              maxlength="200"
              resize="none"
              clearable
            >
            </el-input>
            <div class="waittext">添加图片（最多3张）</div>
            <el-upload
              :action="upload.url"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :multiple="true"
              :limit="3"
              :auto-upload="true"
              :on-exceed="uploadOverrun"
              accept=".jpeg,.jpg,.png"
              :on-success="handleSuccess"
              :on-error="handlefail"
              ref="my-upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <div class="submitbtn" @click="submit">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      textarea: "",
      dialogImageUrl: "",
      dialogVisible: false,
      fileData: [],
      upload:{
        url:process.env.VUE_APP_BASE_API + 'common/upload'
      }
    };
  },
  mounted() {},
  methods: {
    handleRemove(file, fileList) {
      this.fileData = [];
      fileList.map((item) => {
        this.fileData.push(item.response.data);
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(file) {
      this.fileData.push(file.data);
    },
    handlefail() {
      this.$message.error("图片上传失败！请稍后重试！");
    },
    uploadOverrun() {
      this.$message.error("最多上传3张图片");
    },
    submit() {
      console.log(this.fileData);
      if (this.textarea.trim() == "") {
        this.$message({
          message: "请填写您的意见！",
          type: "warning",
          offset:170
        });
      } else {
        let postData = {
          content: this.textarea,
        };
        if (this.fileData.length) {
          let jsonimg = "";
          this.fileData.map((item) => {
            jsonimg += item.url + ",";
          });
          postData.images = jsonimg.substring(0, jsonimg.length - 1);
        }
        this.$myAjax("user/add_feedback", "post", postData, () => {
          this.$message({
            message: "提交成功",
            type: "success",
            offset:170
          });
          this.textarea = ''
          this.fileData = []
          this.$refs['my-upload'].clearFiles();
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      flex: 1;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .showbox {
        padding: 0 35px;
        display: inline-block;
        width: 100%;
        .waittext {
          padding: 26px 0 27px 0;
          color: #000000;
          font-size: 16px;
        }
        .imgbox {
          height: 155px;
          img {
            width: 155px;
            height: 155px;
            float: left;
            margin-left: 20px;
            cursor: pointer;
          }
          .upimg {
            width: 155px;
            height: 155px;
            float: left;
            margin-left: 20px;
            background: #f3f3f3;
            line-height: 155px;
            text-align: center;
            cursor: pointer;
            .icon {
              font-size: 31px;
              color: #666;
            }
          }
          :first-child {
            margin-left: 0;
          }
        }
        .submitbtn {
          width: 263px;
          height: 48px;
          background: #e60012;
          text-align: center;
          line-height: 48px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          margin-top: 105px;
          cursor: pointer;
          left: 50%;
          margin-left: -131.5px;
          position: relative;
        }
      }
    }
  }
}
</style>
