<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="8"></navbar>
        <div class="mainbox">
          <div class="title">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                :label="'全部订单（' + (total.all_count || 0) + '）'"
                name="first"
              ></el-tab-pane>
              <el-tab-pane
                :label="'待付款（' + (total.stay_pay_count || 0) + '）'"
                name="second"
              ></el-tab-pane>
              <el-tab-pane
                :label="'待发货（' + (total.stay_delivery_count || 0) + '）'"
                name="third"
              ></el-tab-pane>
              <el-tab-pane
                :label="'待收货（' + (total.stay_finish_count || 0) + '）'"
                name="fourth"
              ></el-tab-pane>
              <el-tab-pane
                :label="'待评价（' + (total.stay_comment_count || 0) + '）'"
                name="fifth"
              ></el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="!listdata.length" class="nosbox">
            <div class="nodatas">
              <img :src="nodataimg" alt="" class="nodataimg" />
              <div class="nodatatext">你的订单为空！</div>
            </div>
            <div v-if="footdata.length">
              <p class="rowtitle">浏览足迹</p>
              <div class="box">
                <div
                  class="shopsone"
                  v-for="(item, index) in footdata"
                  :key="index"
                  @click.stop="todetail(item.goods_id)"
                >
                  <div
                    class="icon tardiness"
                    :class="item.is_follow == 1 ? 'redheart' : ''"
                    @click="selectgoods(index)"
                  >
                    {{ item.is_follow == 1 ? "&#xe627;" : "&#xe75d;" }}
                  </div>
                  <img :src="item.image" :alt="item.subtitle" class="shopimg" />
                  <div class="name">{{ item.subtitle }}</div>
                  <div class="price">￥{{ item.price }}</div>
                </div>
              </div>
            </div>
            <!-- <div v-if="selectedata.length">
              <p class="rowtitle">你可能喜欢</p>
              <div class="box">
                <div
                  class="shopsone"
                  v-for="(item, index) in selectedata"
                  :key="index"
                  @click.stop="todetail(item.goods_id)"
                >
                  <div
                    class="icon tardiness"
                    :class="item.is_follow == 1 ? 'redheart' : ''"
                    @click="selectgoods2(index)"
                  >
                    {{ item.is_follow == 1 ? "&#xe627;" : "&#xe75d;" }}
                  </div>
                  <img :src="item.image" :alt="item.subtitle" class="shopimg" />
                  <div class="name">{{ item.subtitle }}</div>
                  <div class="price">￥{{ item.price }}</div>
                </div>
              </div>
            </div> -->
          </div>
          <div v-else class="showbox">
            <div
              class="onerecord"
              v-for="(item, index) in listdata"
              :key="index"
            >
              <div class="comet">
                {{
                  item.order_status == 1
                    ? "待付款"
                    : item.order_status == 2
                    ? "待发货 "
                    : item.order_status == 3
                    ? "已发货"
                    : item.order_status == 4
                    ? "已收货"
                    : item.order_status == 5
                    ? "已评价 "
                    : item.order_status == 6
                    ? "已取消"
                    : item.order_status == 7
                    ? "已退款"
                    : ""
                }}
                <span>(共{{ item.goods_list.length }}件商品)</span>
              </div>
              <div class="time">
                <span>订单编号：{{ item.order_sn }}</span>
                <div class="duringtime" v-if="item.order_status == 1">
                  付款倒计时
                  <span>{{ time(item.pre_at) }}</span>
                </div>
                <div class="price">￥{{ item.actual_pay }}</div>
              </div>
              <div class="shops" v-for="(v, i) in item.goods_list" :key="i">
                <div class="shopdetail">
                  <img
                    :src="v.goods_image"
                    :alt="v.goods_title"
                    @click="toshopdetail(v.goods_id)"
                  />
                  <div class="detailbox">
                    <p class="name">{{ v.goods_title }}</p>
                    <p class="ids" v-if="v.goods_sn.length > 0">
                      商品编号：{{ v.goods_sn }}
                    </p>
                    <p v-if="v.goods_sn.length > 0">规格：{{ v.spec_value }}</p>
                    <p>数量：{{ v.goods_num }}</p>
                  </div>
                </div>
              </div>
              <div class="shops">
                <router-link
                  :to="{ name: 'OrderDetail', query: { id: item.order_id } }"
                  class="looksdetail"
                  >订单详情</router-link
                >
                <div
                  class="looksdetail"
                  @click="topay(item.order_id)"
                  v-if="item.order_status == 1"
                >
                  立即支付
                </div>
              </div>
            </div>
          </div>
          <div v-if="listdata.length">
            <div class="more-goods" v-if="page < totalpage" @click="loadmore">
              发现更多
            </div>
            <div class="nomore" v-else>没有更多了</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-show="paytype">
      <div class="windows">
        <div class="widowtitle">
          支付方式
          <div class="icon tardiness" @click="paytype = false">&#xe61c;</div>
        </div>
        <div class="payWay_way">
          <!-- <div class="way" @click="tofetchpay('A01')">
            <img src="@/assets/img/alipay.png" class="alipay" alt="s" />
          </div> -->
          <div class="way" @click="tofetchpay('W01')">
            <img src="@/assets/img/wechat.png" class="wechatpay" alt="s" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessorder.png"),
      listdata: [],
      totalpage: 0,
      page: 1,
      total: {},
      activeName: "first",
      status: "",
      ticker: null,
      footdata: [],
      selectedata: [],
      paytype: false,
      order_id: 0,
    };
  },
  mounted() {
    this.getList();
  },
  beforeDestroy() {
    //清除定时器
    clearInterval(this.ticker);
  },
  methods: {
    topay(order_id) {
      this.paytype = true;
      this.order_id = order_id;
    },
    handleClick() {
      if (this.activeName == "first") {
        this.status = "";
      } else if (this.activeName == "second") {
        this.status = 1;
      } else if (this.activeName == "third") {
        this.status = 2;
      } else if (this.activeName == "fourth") {
        this.status = 3;
      } else if (this.activeName == "fifth") {
        this.status = 4;
      }
      this.listdata = [];
      this.page = 1;
      this.total_page = 1;
      this.getList();
    },
    getList() {
      this.$myAjax(
        "order/pc_order_list?order_status=" +
          this.status +
          "&page=" +
          this.page,
        "get",
        {},
        (res) => {
          res.order_list.list.map((item) => {
            if (item.order_status == 1) {
              item.pre_at =
                new Date(item.expire_time).getTime() - new Date().getTime();
            }
          });
          if (this.ticker) {
            clearInterval(this.ticker);
          }
          this.listdata = this.listdata.concat(res.order_list.list);
          if (this.listdata.length) {
            this.beginTimer();
          }

          this.totalpage = res.order_list.total_page;
          this.footdata = res.foot_list;
          this.selectedata = res.selected_list;
          this.total = res;
        }
      );
    },
    beginTimer() {
      this.ticker = setInterval(() => {
        for (let i = 0, len = this.listdata.length; i < len; i++) {
          const item = this.listdata[i];
          if (item.pre_at > 0) {
            item.pre_at = item.pre_at - 1000;
          }
        }
      }, 1000);
    },
    time(time) {
      if (time >= 0) {
        // let d = Math.floor(time / 1000 / 60 / 60 / 24);
        let h =
          Math.floor((time / 1000 / 60 / 60) % 24) < 10
            ? "0" + Math.floor((time / 1000 / 60 / 60) % 24)
            : Math.floor((time / 1000 / 60 / 60) % 24);
        let m =
          Math.floor((time / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((time / 1000 / 60) % 60)
            : Math.floor((time / 1000 / 60) % 60);
        let s =
          Math.floor((time / 1000) % 60) < 10
            ? "0" + Math.floor((time / 1000) % 60)
            : Math.floor((time / 1000) % 60);
        return h + ":" + m + ":" + s;
      } else {
        return "00:00:00";
      }
    },
    loadmore() {
      this.page++;
      this.getList();
    },
    todetail(_id) {
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}`),
        },
      });
    },
    selectgoods(_index) {
      let url = "";
      this.footdata[_index].is_follow == 1
        ? (url = "user/cancel_follow")
        : (url = "user/add_follow");
      this.$myAjax(
        url,
        "post",
        {
          goods_id: this.footdata[_index].goods_id,
        },
        () => {
          this.footdata[_index].is_follow == 1
            ? (this.footdata[_index].is_follow = 2)
            : (this.footdata[_index].is_follow = 1);
          this.$message({
            message:
              this.footdata[_index].is_follow == 1 ? "收藏成功" : "已取消收藏",
            type: "success",
            offset: 170,
          });
        }
      );
    },
    selectgoods2(_index) {
      let url = "";
      this.selectedata[_index].is_follow == 1
        ? (url = "user/cancel_follow")
        : (url = "user/add_follow");
      this.$myAjax(
        url,
        "post",
        {
          goods_id: this.selectedata[_index].goods_id,
        },
        () => {
          this.selectedata[_index].is_follow == 1
            ? (this.selectedata[_index].is_follow = 2)
            : (this.selectedata[_index].is_follow = 1);
          this.$message({
            message:
              this.selectedata[_index].is_follow == 1
                ? "收藏成功"
                : "已取消收藏",
            type: "success",
            offset: 170,
          });
        }
      );
    },
    tofetchpay(pay_type) {
      this.$router.push({
        name: "fetchPay",
        query: {
          pay_type: pay_type,
          order_id: this.order_id,
        },
      });
    },
    toshopdetail(id) {
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${id}`),
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      flex: 1;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nosbox {
        display: inline-block;
        width: 100%;
        .nodatas {
          display: inline-block;
          width: 268px;
          text-align: center;
          // top: 50%;
          left: 50%;
          margin: 125px 0 104px -148px;
          position: relative;
          .nodataimg {
            width: 268px;
            height: 167px;
            float: left;
          }
          .nodatatext {
            font-size: 22px;
            color: #000000;
            float: left;
            text-align: center;
            width: 100%;
          }
        }
        .rowtitle {
          padding: 27px 40px;
          font-size: 20px;
          color: #333333;
          border-top: 1px solid #e6e6e6;
          width: 100%;
          float: left;
        }
        .box {
          display: inline-block;
          width: 100%;
          float: left;
          margin-bottom: 47px;
          .shopsone {
            width: 234px;
            margin: 27px 0 0 27px;
            float: left;
            position: relative;
            cursor: pointer;
            .icon {
              font-size: 15px;
              position: absolute;
              top: 15px;
              right: 15px;
            }
            .redheart {
              color: #e00b24;
            }
            .shopimg {
              width: 188px;
              height: 188px;
              margin: 23px 0 0 23px;
              float: left;
            }
            .name {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
              margin-top: 50px;
              color: #111111;
              font-size: 20px;
              float: left;
              width: 100%;
            }
            .price {
              margin-top: 27px;
              text-align: center;
              color: #111111;
              font-size: 18px;
              font-weight: bold;
              float: left;
              width: 100%;
            }
          }
        }
      }
      .showbox {
        width: 100%;
        padding: 0 40px;
        display: inline-block;
        .onerecord {
          padding: 25px 0;
          width: 100%;
          border-bottom: 1px solid #e6e6e6;
          float: left;
          .comet {
            font-size: 16px;
            color: #000000;
            font-weight: bold;
            span {
              color: #999999;
              font-weight: normal;
              font-size: 14px;
            }
          }
          .time {
            margin-top: 14px;
            display: inline-block;
            width: 100%;
            span {
              font-size: 16px;
              color: #111111;
              float: left;
            }
            .duringtime {
              margin-left: 52px;
              font-size: 16px;
              color: #111111;
              float: left;
              span {
                color: #e7020f;
                float: right;
                margin-left: 10px;
              }
            }
            .price {
              font-size: 18px;
              color: #111111;
              font-weight: bold;
              float: right;
            }
          }
          .shops {
            width: 100%;
            padding-right: 40px;
            margin-top: 34px;
            .shopdetail {
              display: flex;
              flex-direction: row;
              width: 100%;
              img {
                width: 203px;
                height: 203px;
                float: left;
                cursor: pointer;
              }
              .detailbox {
                margin-left: 29px;
                float: left;
                flex: 1;
                p {
                  font-size: 16px;
                  color: #111111;
                  margin-top: 19px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                .name {
                  font-size: 20px;
                  color: #111111;
                  margin-top: 0;
                }
              }
            }
            .looksdetail {
              float: right;
              width: 168px;
              height: 48px;
              background: #fff;
              border: 1px solid #e00b24;
              font-size: 14px;
              color: #e00b24;
              line-height: 48px;
              text-align: center;
              font-weight: bold;
              cursor: pointer;
              margin-left: 10px;
              border-radius: 5px;
              &:hover {
                color: #fff;
                background: #e00b24;
              }
            }
          }
        }
      }
      .nomore {
        width: 100%;
        padding: 30px 0;
        font-size: 16px;
        color: #666666;
        text-align: center;
        float: left;
      }
      .more-goods {
        text-align: center;
        border: 1px solid #f00;
        width: 153px;
        height: 45px;
        line-height: 45px;
        margin: 30px auto 0 auto;
        color: red;
        cursor: pointer;
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;
  .windows {
    width: 400px;
    min-height: 200px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -265px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #000000;
      font-size: 16px;
      .icon {
        position: absolute;
        color: #666666;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .way {
      text-align: center;
      // padding: 20px;
    }
    .alipay {
      width: 200px;
      height: auto;
      border: 1px solid #e6e6e6;
      text-align: center;
      cursor: pointer;
      margin: 40px 0 20px 0;
      padding: 20px 30px;
    }
    .wechatpay {
      width: 200px;
      height: auto;
      border: 1px solid #e6e6e6;
      text-align: center;
      cursor: pointer;
      margin: 20px 0 40px 0;
      padding: 20px 30px;
    }
  }
}
</style>
<style scope>
.el-tabs__header {
  margin: 0 !important;
}
.el-tabs__nav-wrap::after {
  display: none !important;
}
.el-tabs__item:hover {
  color: #e7020f !important;
}
.el-tabs__item.is-active {
  color: #e7020f !important;
}
.el-tabs__active-bar {
  background-color: #e7020f !important;
  bottom: -27px !important;
}
.el-tabs__nav-wrap,
.el-tabs__nav-scroll {
  overflow: visible !important;
}
</style>
