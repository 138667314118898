<template>
  <div class="suitBox mwframe" v-show="show">
    <div class="box">
      <div class="box_head">
        <div class="head_title">请选择一个项目</div>
        <div class="head_desc">
          请从以下一项中选择您喜欢的项目，然后单击“购买此内容”按钮。
        </div>
        <i class="icon tardiness" @click="close">&#xe61c;</i>
      </div>
      <div class="box_content">
        <div class="suitCard" v-for="(value, key) in chooseData" :key="key">
          <div class="suitCard_title">{{ value.key }}</div>
          <div class="suitCard_content">
            <div
              class="goodsCard"
              :class="item.checked ? 'goodsCardon' : ''"
              v-for="(item, index) in value.list"
              :key="index"
              @click="choose(key, index)"
            >
              <div class="card_check">
                <div class="nocheck" v-if="!item.checked"></div>
                <i class="el-icon-success" v-else></i>
              </div>
              <div class="card_img">
                <img :src="item.image" :alt="item.subtitle" />
              </div>
              <div class="card_name">{{ item.subtitle }}</div>
              <div class="card_size">
                {{ item.spec_value }}
              </div>
              <div class="card_price">￥{{ item.cost_price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_tip">
        如果每个类别中的所有产品都‘缺货’，则无法购买‘可选产品’，请注意
      </div>
      <div class="box_btn">
        <div class="btn1" @click="addtocar">加入购物车</div>
        <div class="btn2" @click="limitbuy">立即购买</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean,
    chooseData: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    close() {
      this.$parent.closepop();
    },
    choose(key, index) {
      let json = {
        key: key,
        index: index,
      };
      this.$emit("chooseChange", json);
    },
    addtocar() {
      this.$emit("addcar");
    },
    limitbuy(){
      this.$emit("justbuy")
    }
  },
};
</script>
<style lang="less" scoped>
.suitBox {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  .box {
    width: 1300px;
    height: 100%;
    background-color: #fff;
    position: relative;
    // top: 10%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    .box_head {
      height: 100px;
      padding: 15px 0;
      background-color: #e60012;
      text-align: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      position: relative;
      > div {
        color: #fff;
      }
      .head_title {
        font-size: 24px;
      }
      .head_desc {
        font-size: 14px;
        margin-top: 17px;
      }
      .icon {
        position: absolute;
        top: 22px;
        right: 22px;
        color: #fff;
        cursor: pointer;
      }
    }
    .box_content {
      height: 670px;
      overflow-y: auto;
      width: 82%;
      margin: 0 auto;
      flex-grow: 1;
      .suitCard {
        // margin-top: 58px;
        // padding-bottom: 73px;
        .suitCard_title {
          height: 36px;
          background-color: #ffefe2;
          font-size: 18px;
          color: #000;
          line-height: 36px;
          padding-left: 30px;
          font-size: 14px;
          margin-top: 40px;
        }
        .suitCard_content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .goodsCard {
            width: 320px;
            padding: 22px 20px 44px 20px;
            background-color: #f9f9f9;
            text-align: center;
            margin-right: 1.5%;
            position: relative;
            margin-top: 20px;
            cursor: pointer;
            &:hover {
              background: #fff3f3;
              transition: all 0.5s;
            }
            .card_check {
              float: left;
              position: absolute;
              top: 11px;
              left: 9px;
              .nocheck {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #e5e5e5;
                cursor: pointer;
              }
              i {
                color: #e60012;
                font-size: 18px;
                cursor: pointer;
              }
            }
            .card_img {
              background-color: #fff;
              padding: 10px;
              width: 100%;
              height: 280px;
              width: 280px;
              margin-bottom: 20px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .card_name,
            .card_size {
              font-size: 16px;
              line-height: 24px;
            }
            .card_price {
              font-size: 20px;
              margin-top: 24px;
            }
          }
          .goodsCardon {
            background: #fff3f3;
          }
        }
      }
    }
    .box_tip {
      width: 76.9%;
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      border: 1px dashed #ffd6ba;
      border-bottom-right-radius: 60px;
      border-bottom-left-radius: 60px;
      text-align: center;
      color: #ffd6ba;
      margin-top: 27px;
      margin-bottom: 25px;
      font-size: 14px;
    }
    .box_btn {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
      [class^="btn"] {
        height: 40px;
        line-height: 40px;
        width: 30%;
        border: 1px solid #ccc;
        text-align: center;
        margin: 0 10px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn1 {
        border-color: #e60012;
        color: #e60012;
      }
      .btn2 {
        background-color: #e60012;
        color: #fff;
      }
    }
  }
}
</style>
