import axios from "axios"
import router from '@/router'
import { MessageBox } from 'element-ui';

let baseURL = ""
// http://mikihouse.jwkj.site/
// 定义请求头及过期时间
const http = axios.create({
  timeout: 2000 * 500,
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  // baseURL: '/api',
  baseURL: process.env.VUE_APP_BASE_API,
});
http.__proto__ = axios;
/**
 * 请求拦截 配置header请求参数
 */
http.interceptors.request.use(
  config => {
    config.headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : '')
    config.headers.sn = new Date().getTime();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  response => {
    if (response.status == 200 && response.data.code !== 1) {
      console.log(response)
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 请求地址处理
 */
http.adornUrl = actionName => {
  return baseURL + actionName;
};

/**
 * 封装ajax请求
 * 1.reqUrl、reqMethod为必填选项
 * 2.type存在时导出数据
 * @param {String}   reqUrl                   请求地址
 * @param {String}   reqMethod                请求方式
 * @param {JSON}     reqData                  请求数据
 * @param {Function} callback                 回调函数
 * @param {Function} dir                      直接返回响应数据
 */

const myAjax = (reqUrl, reqMethod, reqData, callback, dir) => {
  http({
    url: http.adornUrl(reqUrl),
    method: reqMethod,
    data: reqMethod != 'get' ? reqData:null,
    params:reqMethod == 'get' ? reqData:null
  })
    .then(({ data }) => {
      if (dir) {
        callback(data);
      } else if (data && data.code == 1) {
        callback(data.data);
      } else if (data.code == -1) {
        MessageBox({
          // type: 'error',
          title:'MIKI HOUSE提示您',
          message: data.msg
        })
        setTimeout(function () {
          router.push({ name: "login" });
        }, 2000)
      } else {
        MessageBox({
          // type: 'error',
          title:'MIKI HOUSE提示您',
          message: data.msg
        })
      }
    })
    .catch(() => { });
};

export default {
  myAjax,
};
