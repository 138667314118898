var areaData = [
  {
    "id": 1,
    "pid": 0,
    "value": "北京",
    "label": "北京",
    "children": [
      {
        "id": 2,
        "pid": 1,
        "value": "北京市",
        "label": "北京市",
        "children": [
          {
            "id": 3,
            "pid": 2,
            "value": "东城区",
            "label": "东城区"
          },
          {
            "id": 4,
            "pid": 2,
            "value": "西城区",
            "label": "西城区"
          },
          {
            "id": 5,
            "pid": 2,
            "value": "朝阳区",
            "label": "朝阳区"
          },
          {
            "id": 6,
            "pid": 2,
            "value": "丰台区",
            "label": "丰台区"
          },
          {
            "id": 7,
            "pid": 2,
            "value": "石景山区",
            "label": "石景山区"
          },
          {
            "id": 8,
            "pid": 2,
            "value": "海淀区",
            "label": "海淀区"
          },
          {
            "id": 9,
            "pid": 2,
            "value": "门头沟区",
            "label": "门头沟区"
          },
          {
            "id": 10,
            "pid": 2,
            "value": "房山区",
            "label": "房山区"
          },
          {
            "id": 11,
            "pid": 2,
            "value": "通州区",
            "label": "通州区"
          },
          {
            "id": 12,
            "pid": 2,
            "value": "顺义区",
            "label": "顺义区"
          },
          {
            "id": 13,
            "pid": 2,
            "value": "昌平区",
            "label": "昌平区"
          },
          {
            "id": 14,
            "pid": 2,
            "value": "大兴区",
            "label": "大兴区"
          },
          {
            "id": 15,
            "pid": 2,
            "value": "怀柔区",
            "label": "怀柔区"
          },
          {
            "id": 16,
            "pid": 2,
            "value": "平谷区",
            "label": "平谷区"
          },
          {
            "id": 17,
            "pid": 2,
            "value": "密云县",
            "label": "密云县"
          },
          {
            "id": 18,
            "pid": 2,
            "value": "延庆县",
            "label": "延庆县"
          }
        ]
      }
    ]
  },
  {
    "id": 19,
    "pid": 0,
    "value": "天津",
    "label": "天津",
    "children": [
      {
        "id": 20,
        "pid": 19,
        "value": "天津市",
        "label": "天津市",
        "children": [
          {
            "id": 21,
            "pid": 20,
            "value": "和平区",
            "label": "和平区"
          },
          {
            "id": 22,
            "pid": 20,
            "value": "河东区",
            "label": "河东区"
          },
          {
            "id": 23,
            "pid": 20,
            "value": "河西区",
            "label": "河西区"
          },
          {
            "id": 24,
            "pid": 20,
            "value": "南开区",
            "label": "南开区"
          },
          {
            "id": 25,
            "pid": 20,
            "value": "河北区",
            "label": "河北区"
          },
          {
            "id": 26,
            "pid": 20,
            "value": "红桥区",
            "label": "红桥区"
          },
          {
            "id": 27,
            "pid": 20,
            "value": "东丽区",
            "label": "东丽区"
          },
          {
            "id": 28,
            "pid": 20,
            "value": "西青区",
            "label": "西青区"
          },
          {
            "id": 29,
            "pid": 20,
            "value": "津南区",
            "label": "津南区"
          },
          {
            "id": 30,
            "pid": 20,
            "value": "北辰区",
            "label": "北辰区"
          },
          {
            "id": 31,
            "pid": 20,
            "value": "武清区",
            "label": "武清区"
          },
          {
            "id": 32,
            "pid": 20,
            "value": "宝坻区",
            "label": "宝坻区"
          },
          {
            "id": 33,
            "pid": 20,
            "value": "滨海新区",
            "label": "滨海新区"
          },
          {
            "id": 34,
            "pid": 20,
            "value": "宁河县",
            "label": "宁河县"
          },
          {
            "id": 35,
            "pid": 20,
            "value": "静海县",
            "label": "静海县"
          },
          {
            "id": 36,
            "pid": 20,
            "value": "蓟县",
            "label": "蓟县"
          }
        ]
      }
    ]
  },
  {
    "id": 37,
    "pid": 0,
    "value": "河北省",
    "label": "河北省",
    "children": [
      {
        "id": 38,
        "pid": 37,
        "value": "石家庄市",
        "label": "石家庄市",
        "children": [
          {
            "id": 39,
            "pid": 38,
            "value": "长安区",
            "label": "长安区"
          },
          {
            "id": 40,
            "pid": 38,
            "value": "桥西区",
            "label": "桥西区"
          },
          {
            "id": 41,
            "pid": 38,
            "value": "新华区",
            "label": "新华区"
          },
          {
            "id": 42,
            "pid": 38,
            "value": "井陉矿区",
            "label": "井陉矿区"
          },
          {
            "id": 43,
            "pid": 38,
            "value": "裕华区",
            "label": "裕华区"
          },
          {
            "id": 44,
            "pid": 38,
            "value": "藁城区",
            "label": "藁城区"
          },
          {
            "id": 45,
            "pid": 38,
            "value": "鹿泉区",
            "label": "鹿泉区"
          },
          {
            "id": 46,
            "pid": 38,
            "value": "栾城区",
            "label": "栾城区"
          },
          {
            "id": 47,
            "pid": 38,
            "value": "井陉县",
            "label": "井陉县"
          },
          {
            "id": 48,
            "pid": 38,
            "value": "正定县",
            "label": "正定县"
          },
          {
            "id": 49,
            "pid": 38,
            "value": "行唐县",
            "label": "行唐县"
          },
          {
            "id": 50,
            "pid": 38,
            "value": "灵寿县",
            "label": "灵寿县"
          },
          {
            "id": 51,
            "pid": 38,
            "value": "高邑县",
            "label": "高邑县"
          },
          {
            "id": 52,
            "pid": 38,
            "value": "深泽县",
            "label": "深泽县"
          },
          {
            "id": 53,
            "pid": 38,
            "value": "赞皇县",
            "label": "赞皇县"
          },
          {
            "id": 54,
            "pid": 38,
            "value": "无极县",
            "label": "无极县"
          },
          {
            "id": 55,
            "pid": 38,
            "value": "平山县",
            "label": "平山县"
          },
          {
            "id": 56,
            "pid": 38,
            "value": "元氏县",
            "label": "元氏县"
          },
          {
            "id": 57,
            "pid": 38,
            "value": "赵县",
            "label": "赵县"
          },
          {
            "id": 58,
            "pid": 38,
            "value": "辛集市",
            "label": "辛集市"
          },
          {
            "id": 59,
            "pid": 38,
            "value": "晋州市",
            "label": "晋州市"
          },
          {
            "id": 60,
            "pid": 38,
            "value": "新乐市",
            "label": "新乐市"
          }
        ]
      },
      {
        "id": 61,
        "pid": 37,
        "value": "唐山市",
        "label": "唐山市",
        "children": [
          {
            "id": 62,
            "pid": 61,
            "value": "路南区",
            "label": "路南区"
          },
          {
            "id": 63,
            "pid": 61,
            "value": "路北区",
            "label": "路北区"
          },
          {
            "id": 64,
            "pid": 61,
            "value": "古冶区",
            "label": "古冶区"
          },
          {
            "id": 65,
            "pid": 61,
            "value": "开平区",
            "label": "开平区"
          },
          {
            "id": 66,
            "pid": 61,
            "value": "丰南区",
            "label": "丰南区"
          },
          {
            "id": 67,
            "pid": 61,
            "value": "丰润区",
            "label": "丰润区"
          },
          {
            "id": 68,
            "pid": 61,
            "value": "曹妃甸区",
            "label": "曹妃甸区"
          },
          {
            "id": 69,
            "pid": 61,
            "value": "滦县",
            "label": "滦县"
          },
          {
            "id": 70,
            "pid": 61,
            "value": "滦南县",
            "label": "滦南县"
          },
          {
            "id": 71,
            "pid": 61,
            "value": "乐亭县",
            "label": "乐亭县"
          },
          {
            "id": 72,
            "pid": 61,
            "value": "迁西县",
            "label": "迁西县"
          },
          {
            "id": 73,
            "pid": 61,
            "value": "玉田县",
            "label": "玉田县"
          },
          {
            "id": 74,
            "pid": 61,
            "value": "遵化市",
            "label": "遵化市"
          },
          {
            "id": 75,
            "pid": 61,
            "value": "迁安市",
            "label": "迁安市"
          }
        ]
      },
      {
        "id": 76,
        "pid": 37,
        "value": "秦皇岛市",
        "label": "秦皇岛市",
        "children": [
          {
            "id": 77,
            "pid": 76,
            "value": "海港区",
            "label": "海港区"
          },
          {
            "id": 78,
            "pid": 76,
            "value": "山海关区",
            "label": "山海关区"
          },
          {
            "id": 79,
            "pid": 76,
            "value": "北戴河区",
            "label": "北戴河区"
          },
          {
            "id": 80,
            "pid": 76,
            "value": "青龙满族自治县",
            "label": "青龙满族自治县"
          },
          {
            "id": 81,
            "pid": 76,
            "value": "昌黎县",
            "label": "昌黎县"
          },
          {
            "id": 82,
            "pid": 76,
            "value": "抚宁县",
            "label": "抚宁县"
          },
          {
            "id": 83,
            "pid": 76,
            "value": "卢龙县",
            "label": "卢龙县"
          }
        ]
      },
      {
        "id": 84,
        "pid": 37,
        "value": "邯郸市",
        "label": "邯郸市",
        "children": [
          {
            "id": 85,
            "pid": 84,
            "value": "邯山区",
            "label": "邯山区"
          },
          {
            "id": 86,
            "pid": 84,
            "value": "丛台区",
            "label": "丛台区"
          },
          {
            "id": 87,
            "pid": 84,
            "value": "复兴区",
            "label": "复兴区"
          },
          {
            "id": 88,
            "pid": 84,
            "value": "峰峰矿区",
            "label": "峰峰矿区"
          },
          {
            "id": 89,
            "pid": 84,
            "value": "邯郸县",
            "label": "邯郸县"
          },
          {
            "id": 90,
            "pid": 84,
            "value": "临漳县",
            "label": "临漳县"
          },
          {
            "id": 91,
            "pid": 84,
            "value": "成安县",
            "label": "成安县"
          },
          {
            "id": 92,
            "pid": 84,
            "value": "大名县",
            "label": "大名县"
          },
          {
            "id": 93,
            "pid": 84,
            "value": "涉县",
            "label": "涉县"
          },
          {
            "id": 94,
            "pid": 84,
            "value": "磁县",
            "label": "磁县"
          },
          {
            "id": 95,
            "pid": 84,
            "value": "肥乡县",
            "label": "肥乡县"
          },
          {
            "id": 96,
            "pid": 84,
            "value": "永年县",
            "label": "永年县"
          },
          {
            "id": 97,
            "pid": 84,
            "value": "邱县",
            "label": "邱县"
          },
          {
            "id": 98,
            "pid": 84,
            "value": "鸡泽县",
            "label": "鸡泽县"
          },
          {
            "id": 99,
            "pid": 84,
            "value": "广平县",
            "label": "广平县"
          },
          {
            "id": 100,
            "pid": 84,
            "value": "馆陶县",
            "label": "馆陶县"
          },
          {
            "id": 101,
            "pid": 84,
            "value": "魏县",
            "label": "魏县"
          },
          {
            "id": 102,
            "pid": 84,
            "value": "曲周县",
            "label": "曲周县"
          },
          {
            "id": 103,
            "pid": 84,
            "value": "武安市",
            "label": "武安市"
          }
        ]
      },
      {
        "id": 104,
        "pid": 37,
        "value": "邢台市",
        "label": "邢台市",
        "children": [
          {
            "id": 105,
            "pid": 104,
            "value": "桥东区",
            "label": "桥东区"
          },
          {
            "id": 106,
            "pid": 104,
            "value": "桥西区",
            "label": "桥西区"
          },
          {
            "id": 107,
            "pid": 104,
            "value": "邢台县",
            "label": "邢台县"
          },
          {
            "id": 108,
            "pid": 104,
            "value": "临城县",
            "label": "临城县"
          },
          {
            "id": 109,
            "pid": 104,
            "value": "内丘县",
            "label": "内丘县"
          },
          {
            "id": 110,
            "pid": 104,
            "value": "柏乡县",
            "label": "柏乡县"
          },
          {
            "id": 111,
            "pid": 104,
            "value": "隆尧县",
            "label": "隆尧县"
          },
          {
            "id": 112,
            "pid": 104,
            "value": "任县",
            "label": "任县"
          },
          {
            "id": 113,
            "pid": 104,
            "value": "南和县",
            "label": "南和县"
          },
          {
            "id": 114,
            "pid": 104,
            "value": "宁晋县",
            "label": "宁晋县"
          },
          {
            "id": 115,
            "pid": 104,
            "value": "巨鹿县",
            "label": "巨鹿县"
          },
          {
            "id": 116,
            "pid": 104,
            "value": "新河县",
            "label": "新河县"
          },
          {
            "id": 117,
            "pid": 104,
            "value": "广宗县",
            "label": "广宗县"
          },
          {
            "id": 118,
            "pid": 104,
            "value": "平乡县",
            "label": "平乡县"
          },
          {
            "id": 119,
            "pid": 104,
            "value": "威县",
            "label": "威县"
          },
          {
            "id": 120,
            "pid": 104,
            "value": "清河县",
            "label": "清河县"
          },
          {
            "id": 121,
            "pid": 104,
            "value": "临西县",
            "label": "临西县"
          },
          {
            "id": 122,
            "pid": 104,
            "value": "南宫市",
            "label": "南宫市"
          },
          {
            "id": 123,
            "pid": 104,
            "value": "沙河市",
            "label": "沙河市"
          }
        ]
      },
      {
        "id": 124,
        "pid": 37,
        "value": "保定市",
        "label": "保定市",
        "children": [
          {
            "id": 125,
            "pid": 124,
            "value": "新市区",
            "label": "新市区"
          },
          {
            "id": 126,
            "pid": 124,
            "value": "北市区",
            "label": "北市区"
          },
          {
            "id": 127,
            "pid": 124,
            "value": "南市区",
            "label": "南市区"
          },
          {
            "id": 128,
            "pid": 124,
            "value": "满城县",
            "label": "满城县"
          },
          {
            "id": 129,
            "pid": 124,
            "value": "清苑县",
            "label": "清苑县"
          },
          {
            "id": 130,
            "pid": 124,
            "value": "涞水县",
            "label": "涞水县"
          },
          {
            "id": 131,
            "pid": 124,
            "value": "阜平县",
            "label": "阜平县"
          },
          {
            "id": 132,
            "pid": 124,
            "value": "徐水县",
            "label": "徐水县"
          },
          {
            "id": 133,
            "pid": 124,
            "value": "定兴县",
            "label": "定兴县"
          },
          {
            "id": 134,
            "pid": 124,
            "value": "唐县",
            "label": "唐县"
          },
          {
            "id": 135,
            "pid": 124,
            "value": "高阳县",
            "label": "高阳县"
          },
          {
            "id": 136,
            "pid": 124,
            "value": "容城县",
            "label": "容城县"
          },
          {
            "id": 137,
            "pid": 124,
            "value": "涞源县",
            "label": "涞源县"
          },
          {
            "id": 138,
            "pid": 124,
            "value": "望都县",
            "label": "望都县"
          },
          {
            "id": 139,
            "pid": 124,
            "value": "安新县",
            "label": "安新县"
          },
          {
            "id": 140,
            "pid": 124,
            "value": "易县",
            "label": "易县"
          },
          {
            "id": 141,
            "pid": 124,
            "value": "曲阳县",
            "label": "曲阳县"
          },
          {
            "id": 142,
            "pid": 124,
            "value": "蠡县",
            "label": "蠡县"
          },
          {
            "id": 143,
            "pid": 124,
            "value": "顺平县",
            "label": "顺平县"
          },
          {
            "id": 144,
            "pid": 124,
            "value": "博野县",
            "label": "博野县"
          },
          {
            "id": 145,
            "pid": 124,
            "value": "雄县",
            "label": "雄县"
          },
          {
            "id": 146,
            "pid": 124,
            "value": "涿州市",
            "label": "涿州市"
          },
          {
            "id": 147,
            "pid": 124,
            "value": "定州市",
            "label": "定州市"
          },
          {
            "id": 148,
            "pid": 124,
            "value": "安国市",
            "label": "安国市"
          },
          {
            "id": 149,
            "pid": 124,
            "value": "高碑店市",
            "label": "高碑店市"
          }
        ]
      },
      {
        "id": 150,
        "pid": 37,
        "value": "张家口市",
        "label": "张家口市",
        "children": [
          {
            "id": 151,
            "pid": 150,
            "value": "桥东区",
            "label": "桥东区"
          },
          {
            "id": 152,
            "pid": 150,
            "value": "桥西区",
            "label": "桥西区"
          },
          {
            "id": 153,
            "pid": 150,
            "value": "宣化区",
            "label": "宣化区"
          },
          {
            "id": 154,
            "pid": 150,
            "value": "下花园区",
            "label": "下花园区"
          },
          {
            "id": 155,
            "pid": 150,
            "value": "宣化县",
            "label": "宣化县"
          },
          {
            "id": 156,
            "pid": 150,
            "value": "张北县",
            "label": "张北县"
          },
          {
            "id": 157,
            "pid": 150,
            "value": "康保县",
            "label": "康保县"
          },
          {
            "id": 158,
            "pid": 150,
            "value": "沽源县",
            "label": "沽源县"
          },
          {
            "id": 159,
            "pid": 150,
            "value": "尚义县",
            "label": "尚义县"
          },
          {
            "id": 160,
            "pid": 150,
            "value": "蔚县",
            "label": "蔚县"
          },
          {
            "id": 161,
            "pid": 150,
            "value": "阳原县",
            "label": "阳原县"
          },
          {
            "id": 162,
            "pid": 150,
            "value": "怀安县",
            "label": "怀安县"
          },
          {
            "id": 163,
            "pid": 150,
            "value": "万全县",
            "label": "万全县"
          },
          {
            "id": 164,
            "pid": 150,
            "value": "怀来县",
            "label": "怀来县"
          },
          {
            "id": 165,
            "pid": 150,
            "value": "涿鹿县",
            "label": "涿鹿县"
          },
          {
            "id": 166,
            "pid": 150,
            "value": "赤城县",
            "label": "赤城县"
          },
          {
            "id": 167,
            "pid": 150,
            "value": "崇礼县",
            "label": "崇礼县"
          }
        ]
      },
      {
        "id": 168,
        "pid": 37,
        "value": "承德市",
        "label": "承德市",
        "children": [
          {
            "id": 169,
            "pid": 168,
            "value": "双桥区",
            "label": "双桥区"
          },
          {
            "id": 170,
            "pid": 168,
            "value": "双滦区",
            "label": "双滦区"
          },
          {
            "id": 171,
            "pid": 168,
            "value": "鹰手营子矿区",
            "label": "鹰手营子矿区"
          },
          {
            "id": 172,
            "pid": 168,
            "value": "承德县",
            "label": "承德县"
          },
          {
            "id": 173,
            "pid": 168,
            "value": "兴隆县",
            "label": "兴隆县"
          },
          {
            "id": 174,
            "pid": 168,
            "value": "平泉县",
            "label": "平泉县"
          },
          {
            "id": 175,
            "pid": 168,
            "value": "滦平县",
            "label": "滦平县"
          },
          {
            "id": 176,
            "pid": 168,
            "value": "隆化县",
            "label": "隆化县"
          },
          {
            "id": 177,
            "pid": 168,
            "value": "丰宁满族自治县",
            "label": "丰宁满族自治县"
          },
          {
            "id": 178,
            "pid": 168,
            "value": "宽城满族自治县",
            "label": "宽城满族自治县"
          },
          {
            "id": 179,
            "pid": 168,
            "value": "围场满族蒙古族自治县",
            "label": "围场满族蒙古族自治县"
          }
        ]
      },
      {
        "id": 180,
        "pid": 37,
        "value": "沧州市",
        "label": "沧州市",
        "children": [
          {
            "id": 181,
            "pid": 180,
            "value": "新华区",
            "label": "新华区"
          },
          {
            "id": 182,
            "pid": 180,
            "value": "运河区",
            "label": "运河区"
          },
          {
            "id": 183,
            "pid": 180,
            "value": "沧县",
            "label": "沧县"
          },
          {
            "id": 184,
            "pid": 180,
            "value": "青县",
            "label": "青县"
          },
          {
            "id": 185,
            "pid": 180,
            "value": "东光县",
            "label": "东光县"
          },
          {
            "id": 186,
            "pid": 180,
            "value": "海兴县",
            "label": "海兴县"
          },
          {
            "id": 187,
            "pid": 180,
            "value": "盐山县",
            "label": "盐山县"
          },
          {
            "id": 188,
            "pid": 180,
            "value": "肃宁县",
            "label": "肃宁县"
          },
          {
            "id": 189,
            "pid": 180,
            "value": "南皮县",
            "label": "南皮县"
          },
          {
            "id": 190,
            "pid": 180,
            "value": "吴桥县",
            "label": "吴桥县"
          },
          {
            "id": 191,
            "pid": 180,
            "value": "献县",
            "label": "献县"
          },
          {
            "id": 192,
            "pid": 180,
            "value": "孟村回族自治县",
            "label": "孟村回族自治县"
          },
          {
            "id": 193,
            "pid": 180,
            "value": "泊头市",
            "label": "泊头市"
          },
          {
            "id": 194,
            "pid": 180,
            "value": "任丘市",
            "label": "任丘市"
          },
          {
            "id": 195,
            "pid": 180,
            "value": "黄骅市",
            "label": "黄骅市"
          },
          {
            "id": 196,
            "pid": 180,
            "value": "河间市",
            "label": "河间市"
          }
        ]
      },
      {
        "id": 197,
        "pid": 37,
        "value": "廊坊市",
        "label": "廊坊市",
        "children": [
          {
            "id": 198,
            "pid": 197,
            "value": "安次区",
            "label": "安次区"
          },
          {
            "id": 199,
            "pid": 197,
            "value": "广阳区",
            "label": "广阳区"
          },
          {
            "id": 200,
            "pid": 197,
            "value": "固安县",
            "label": "固安县"
          },
          {
            "id": 201,
            "pid": 197,
            "value": "永清县",
            "label": "永清县"
          },
          {
            "id": 202,
            "pid": 197,
            "value": "香河县",
            "label": "香河县"
          },
          {
            "id": 203,
            "pid": 197,
            "value": "大城县",
            "label": "大城县"
          },
          {
            "id": 204,
            "pid": 197,
            "value": "文安县",
            "label": "文安县"
          },
          {
            "id": 205,
            "pid": 197,
            "value": "大厂回族自治县",
            "label": "大厂回族自治县"
          },
          {
            "id": 206,
            "pid": 197,
            "value": "霸州市",
            "label": "霸州市"
          },
          {
            "id": 207,
            "pid": 197,
            "value": "三河市",
            "label": "三河市"
          }
        ]
      },
      {
        "id": 208,
        "pid": 37,
        "value": "衡水市",
        "label": "衡水市",
        "children": [
          {
            "id": 209,
            "pid": 208,
            "value": "桃城区",
            "label": "桃城区"
          },
          {
            "id": 210,
            "pid": 208,
            "value": "枣强县",
            "label": "枣强县"
          },
          {
            "id": 211,
            "pid": 208,
            "value": "武邑县",
            "label": "武邑县"
          },
          {
            "id": 212,
            "pid": 208,
            "value": "武强县",
            "label": "武强县"
          },
          {
            "id": 213,
            "pid": 208,
            "value": "饶阳县",
            "label": "饶阳县"
          },
          {
            "id": 214,
            "pid": 208,
            "value": "安平县",
            "label": "安平县"
          },
          {
            "id": 215,
            "pid": 208,
            "value": "故城县",
            "label": "故城县"
          },
          {
            "id": 216,
            "pid": 208,
            "value": "景县",
            "label": "景县"
          },
          {
            "id": 217,
            "pid": 208,
            "value": "阜城县",
            "label": "阜城县"
          },
          {
            "id": 218,
            "pid": 208,
            "value": "冀州市",
            "label": "冀州市"
          },
          {
            "id": 219,
            "pid": 208,
            "value": "深州市",
            "label": "深州市"
          }
        ]
      }
    ]
  },
  {
    "id": 220,
    "pid": 0,
    "value": "山西省",
    "label": "山西省",
    "children": [
      {
        "id": 221,
        "pid": 220,
        "value": "太原市",
        "label": "太原市",
        "children": [
          {
            "id": 222,
            "pid": 221,
            "value": "小店区",
            "label": "小店区"
          },
          {
            "id": 223,
            "pid": 221,
            "value": "迎泽区",
            "label": "迎泽区"
          },
          {
            "id": 224,
            "pid": 221,
            "value": "杏花岭区",
            "label": "杏花岭区"
          },
          {
            "id": 225,
            "pid": 221,
            "value": "尖草坪区",
            "label": "尖草坪区"
          },
          {
            "id": 226,
            "pid": 221,
            "value": "万柏林区",
            "label": "万柏林区"
          },
          {
            "id": 227,
            "pid": 221,
            "value": "晋源区",
            "label": "晋源区"
          },
          {
            "id": 228,
            "pid": 221,
            "value": "清徐县",
            "label": "清徐县"
          },
          {
            "id": 229,
            "pid": 221,
            "value": "阳曲县",
            "label": "阳曲县"
          },
          {
            "id": 230,
            "pid": 221,
            "value": "娄烦县",
            "label": "娄烦县"
          },
          {
            "id": 231,
            "pid": 221,
            "value": "古交市",
            "label": "古交市"
          }
        ]
      },
      {
        "id": 232,
        "pid": 220,
        "value": "大同市",
        "label": "大同市",
        "children": [
          {
            "id": 233,
            "pid": 232,
            "value": "城区",
            "label": "城区"
          },
          {
            "id": 234,
            "pid": 232,
            "value": "矿区",
            "label": "矿区"
          },
          {
            "id": 235,
            "pid": 232,
            "value": "南郊区",
            "label": "南郊区"
          },
          {
            "id": 236,
            "pid": 232,
            "value": "新荣区",
            "label": "新荣区"
          },
          {
            "id": 237,
            "pid": 232,
            "value": "阳高县",
            "label": "阳高县"
          },
          {
            "id": 238,
            "pid": 232,
            "value": "天镇县",
            "label": "天镇县"
          },
          {
            "id": 239,
            "pid": 232,
            "value": "广灵县",
            "label": "广灵县"
          },
          {
            "id": 240,
            "pid": 232,
            "value": "灵丘县",
            "label": "灵丘县"
          },
          {
            "id": 241,
            "pid": 232,
            "value": "浑源县",
            "label": "浑源县"
          },
          {
            "id": 242,
            "pid": 232,
            "value": "左云县",
            "label": "左云县"
          },
          {
            "id": 243,
            "pid": 232,
            "value": "大同县",
            "label": "大同县"
          }
        ]
      },
      {
        "id": 244,
        "pid": 220,
        "value": "阳泉市",
        "label": "阳泉市",
        "children": [
          {
            "id": 245,
            "pid": 244,
            "value": "城区",
            "label": "城区"
          },
          {
            "id": 246,
            "pid": 244,
            "value": "矿区",
            "label": "矿区"
          },
          {
            "id": 247,
            "pid": 244,
            "value": "郊区",
            "label": "郊区"
          },
          {
            "id": 248,
            "pid": 244,
            "value": "平定县",
            "label": "平定县"
          },
          {
            "id": 249,
            "pid": 244,
            "value": "盂县",
            "label": "盂县"
          }
        ]
      },
      {
        "id": 250,
        "pid": 220,
        "value": "长治市",
        "label": "长治市",
        "children": [
          {
            "id": 251,
            "pid": 250,
            "value": "城区",
            "label": "城区"
          },
          {
            "id": 252,
            "pid": 250,
            "value": "郊区",
            "label": "郊区"
          },
          {
            "id": 253,
            "pid": 250,
            "value": "长治县",
            "label": "长治县"
          },
          {
            "id": 254,
            "pid": 250,
            "value": "襄垣县",
            "label": "襄垣县"
          },
          {
            "id": 255,
            "pid": 250,
            "value": "屯留县",
            "label": "屯留县"
          },
          {
            "id": 256,
            "pid": 250,
            "value": "平顺县",
            "label": "平顺县"
          },
          {
            "id": 257,
            "pid": 250,
            "value": "黎城县",
            "label": "黎城县"
          },
          {
            "id": 258,
            "pid": 250,
            "value": "壶关县",
            "label": "壶关县"
          },
          {
            "id": 259,
            "pid": 250,
            "value": "长子县",
            "label": "长子县"
          },
          {
            "id": 260,
            "pid": 250,
            "value": "武乡县",
            "label": "武乡县"
          },
          {
            "id": 261,
            "pid": 250,
            "value": "沁县",
            "label": "沁县"
          },
          {
            "id": 262,
            "pid": 250,
            "value": "沁源县",
            "label": "沁源县"
          },
          {
            "id": 263,
            "pid": 250,
            "value": "潞城市",
            "label": "潞城市"
          }
        ]
      },
      {
        "id": 264,
        "pid": 220,
        "value": "晋城市",
        "label": "晋城市",
        "children": [
          {
            "id": 265,
            "pid": 264,
            "value": "城区",
            "label": "城区"
          },
          {
            "id": 266,
            "pid": 264,
            "value": "沁水县",
            "label": "沁水县"
          },
          {
            "id": 267,
            "pid": 264,
            "value": "阳城县",
            "label": "阳城县"
          },
          {
            "id": 268,
            "pid": 264,
            "value": "陵川县",
            "label": "陵川县"
          },
          {
            "id": 269,
            "pid": 264,
            "value": "泽州县",
            "label": "泽州县"
          },
          {
            "id": 270,
            "pid": 264,
            "value": "高平市",
            "label": "高平市"
          }
        ]
      },
      {
        "id": 271,
        "pid": 220,
        "value": "朔州市",
        "label": "朔州市",
        "children": [
          {
            "id": 272,
            "pid": 271,
            "value": "朔城区",
            "label": "朔城区"
          },
          {
            "id": 273,
            "pid": 271,
            "value": "平鲁区",
            "label": "平鲁区"
          },
          {
            "id": 274,
            "pid": 271,
            "value": "山阴县",
            "label": "山阴县"
          },
          {
            "id": 275,
            "pid": 271,
            "value": "应县",
            "label": "应县"
          },
          {
            "id": 276,
            "pid": 271,
            "value": "右玉县",
            "label": "右玉县"
          },
          {
            "id": 277,
            "pid": 271,
            "value": "怀仁县",
            "label": "怀仁县"
          }
        ]
      },
      {
        "id": 278,
        "pid": 220,
        "value": "晋中市",
        "label": "晋中市",
        "children": [
          {
            "id": 279,
            "pid": 278,
            "value": "榆次区",
            "label": "榆次区"
          },
          {
            "id": 280,
            "pid": 278,
            "value": "榆社县",
            "label": "榆社县"
          },
          {
            "id": 281,
            "pid": 278,
            "value": "左权县",
            "label": "左权县"
          },
          {
            "id": 282,
            "pid": 278,
            "value": "和顺县",
            "label": "和顺县"
          },
          {
            "id": 283,
            "pid": 278,
            "value": "昔阳县",
            "label": "昔阳县"
          },
          {
            "id": 284,
            "pid": 278,
            "value": "寿阳县",
            "label": "寿阳县"
          },
          {
            "id": 285,
            "pid": 278,
            "value": "太谷县",
            "label": "太谷县"
          },
          {
            "id": 286,
            "pid": 278,
            "value": "祁县",
            "label": "祁县"
          },
          {
            "id": 287,
            "pid": 278,
            "value": "平遥县",
            "label": "平遥县"
          },
          {
            "id": 288,
            "pid": 278,
            "value": "灵石县",
            "label": "灵石县"
          },
          {
            "id": 289,
            "pid": 278,
            "value": "介休市",
            "label": "介休市"
          }
        ]
      },
      {
        "id": 290,
        "pid": 220,
        "value": "运城市",
        "label": "运城市",
        "children": [
          {
            "id": 291,
            "pid": 290,
            "value": "盐湖区",
            "label": "盐湖区"
          },
          {
            "id": 292,
            "pid": 290,
            "value": "临猗县",
            "label": "临猗县"
          },
          {
            "id": 293,
            "pid": 290,
            "value": "万荣县",
            "label": "万荣县"
          },
          {
            "id": 294,
            "pid": 290,
            "value": "闻喜县",
            "label": "闻喜县"
          },
          {
            "id": 295,
            "pid": 290,
            "value": "稷山县",
            "label": "稷山县"
          },
          {
            "id": 296,
            "pid": 290,
            "value": "新绛县",
            "label": "新绛县"
          },
          {
            "id": 297,
            "pid": 290,
            "value": "绛县",
            "label": "绛县"
          },
          {
            "id": 298,
            "pid": 290,
            "value": "垣曲县",
            "label": "垣曲县"
          },
          {
            "id": 299,
            "pid": 290,
            "value": "夏县",
            "label": "夏县"
          },
          {
            "id": 300,
            "pid": 290,
            "value": "平陆县",
            "label": "平陆县"
          },
          {
            "id": 301,
            "pid": 290,
            "value": "芮城县",
            "label": "芮城县"
          },
          {
            "id": 302,
            "pid": 290,
            "value": "永济市",
            "label": "永济市"
          },
          {
            "id": 303,
            "pid": 290,
            "value": "河津市",
            "label": "河津市"
          }
        ]
      },
      {
        "id": 304,
        "pid": 220,
        "value": "忻州市",
        "label": "忻州市",
        "children": [
          {
            "id": 305,
            "pid": 304,
            "value": "忻府区",
            "label": "忻府区"
          },
          {
            "id": 306,
            "pid": 304,
            "value": "定襄县",
            "label": "定襄县"
          },
          {
            "id": 307,
            "pid": 304,
            "value": "五台县",
            "label": "五台县"
          },
          {
            "id": 308,
            "pid": 304,
            "value": "代县",
            "label": "代县"
          },
          {
            "id": 309,
            "pid": 304,
            "value": "繁峙县",
            "label": "繁峙县"
          },
          {
            "id": 310,
            "pid": 304,
            "value": "宁武县",
            "label": "宁武县"
          },
          {
            "id": 311,
            "pid": 304,
            "value": "静乐县",
            "label": "静乐县"
          },
          {
            "id": 312,
            "pid": 304,
            "value": "神池县",
            "label": "神池县"
          },
          {
            "id": 313,
            "pid": 304,
            "value": "五寨县",
            "label": "五寨县"
          },
          {
            "id": 314,
            "pid": 304,
            "value": "岢岚县",
            "label": "岢岚县"
          },
          {
            "id": 315,
            "pid": 304,
            "value": "河曲县",
            "label": "河曲县"
          },
          {
            "id": 316,
            "pid": 304,
            "value": "保德县",
            "label": "保德县"
          },
          {
            "id": 317,
            "pid": 304,
            "value": "偏关县",
            "label": "偏关县"
          },
          {
            "id": 318,
            "pid": 304,
            "value": "原平市",
            "label": "原平市"
          }
        ]
      },
      {
        "id": 319,
        "pid": 220,
        "value": "临汾市",
        "label": "临汾市",
        "children": [
          {
            "id": 320,
            "pid": 319,
            "value": "尧都区",
            "label": "尧都区"
          },
          {
            "id": 321,
            "pid": 319,
            "value": "曲沃县",
            "label": "曲沃县"
          },
          {
            "id": 322,
            "pid": 319,
            "value": "翼城县",
            "label": "翼城县"
          },
          {
            "id": 323,
            "pid": 319,
            "value": "襄汾县",
            "label": "襄汾县"
          },
          {
            "id": 324,
            "pid": 319,
            "value": "洪洞县",
            "label": "洪洞县"
          },
          {
            "id": 325,
            "pid": 319,
            "value": "古县",
            "label": "古县"
          },
          {
            "id": 326,
            "pid": 319,
            "value": "安泽县",
            "label": "安泽县"
          },
          {
            "id": 327,
            "pid": 319,
            "value": "浮山县",
            "label": "浮山县"
          },
          {
            "id": 328,
            "pid": 319,
            "value": "吉县",
            "label": "吉县"
          },
          {
            "id": 329,
            "pid": 319,
            "value": "乡宁县",
            "label": "乡宁县"
          },
          {
            "id": 330,
            "pid": 319,
            "value": "大宁县",
            "label": "大宁县"
          },
          {
            "id": 331,
            "pid": 319,
            "value": "隰县",
            "label": "隰县"
          },
          {
            "id": 332,
            "pid": 319,
            "value": "永和县",
            "label": "永和县"
          },
          {
            "id": 333,
            "pid": 319,
            "value": "蒲县",
            "label": "蒲县"
          },
          {
            "id": 334,
            "pid": 319,
            "value": "汾西县",
            "label": "汾西县"
          },
          {
            "id": 335,
            "pid": 319,
            "value": "侯马市",
            "label": "侯马市"
          },
          {
            "id": 336,
            "pid": 319,
            "value": "霍州市",
            "label": "霍州市"
          }
        ]
      },
      {
        "id": 337,
        "pid": 220,
        "value": "吕梁市",
        "label": "吕梁市",
        "children": [
          {
            "id": 338,
            "pid": 337,
            "value": "离石区",
            "label": "离石区"
          },
          {
            "id": 339,
            "pid": 337,
            "value": "文水县",
            "label": "文水县"
          },
          {
            "id": 340,
            "pid": 337,
            "value": "交城县",
            "label": "交城县"
          },
          {
            "id": 341,
            "pid": 337,
            "value": "兴县",
            "label": "兴县"
          },
          {
            "id": 342,
            "pid": 337,
            "value": "临县",
            "label": "临县"
          },
          {
            "id": 343,
            "pid": 337,
            "value": "柳林县",
            "label": "柳林县"
          },
          {
            "id": 344,
            "pid": 337,
            "value": "石楼县",
            "label": "石楼县"
          },
          {
            "id": 345,
            "pid": 337,
            "value": "岚县",
            "label": "岚县"
          },
          {
            "id": 346,
            "pid": 337,
            "value": "方山县",
            "label": "方山县"
          },
          {
            "id": 347,
            "pid": 337,
            "value": "中阳县",
            "label": "中阳县"
          },
          {
            "id": 348,
            "pid": 337,
            "value": "交口县",
            "label": "交口县"
          },
          {
            "id": 349,
            "pid": 337,
            "value": "孝义市",
            "label": "孝义市"
          },
          {
            "id": 350,
            "pid": 337,
            "value": "汾阳市",
            "label": "汾阳市"
          }
        ]
      }
    ]
  },
  {
    "id": 351,
    "pid": 0,
    "value": "内蒙古自治区",
    "label": "内蒙古自治区",
    "children": [
      {
        "id": 352,
        "pid": 351,
        "value": "呼和浩特市",
        "label": "呼和浩特市",
        "children": [
          {
            "id": 353,
            "pid": 352,
            "value": "新城区",
            "label": "新城区"
          },
          {
            "id": 354,
            "pid": 352,
            "value": "回民区",
            "label": "回民区"
          },
          {
            "id": 355,
            "pid": 352,
            "value": "玉泉区",
            "label": "玉泉区"
          },
          {
            "id": 356,
            "pid": 352,
            "value": "赛罕区",
            "label": "赛罕区"
          },
          {
            "id": 357,
            "pid": 352,
            "value": "土默特左旗",
            "label": "土默特左旗"
          },
          {
            "id": 358,
            "pid": 352,
            "value": "托克托县",
            "label": "托克托县"
          },
          {
            "id": 359,
            "pid": 352,
            "value": "和林格尔县",
            "label": "和林格尔县"
          },
          {
            "id": 360,
            "pid": 352,
            "value": "清水河县",
            "label": "清水河县"
          },
          {
            "id": 361,
            "pid": 352,
            "value": "武川县",
            "label": "武川县"
          }
        ]
      },
      {
        "id": 362,
        "pid": 351,
        "value": "包头市",
        "label": "包头市",
        "children": [
          {
            "id": 363,
            "pid": 362,
            "value": "东河区",
            "label": "东河区"
          },
          {
            "id": 364,
            "pid": 362,
            "value": "昆都仑区",
            "label": "昆都仑区"
          },
          {
            "id": 365,
            "pid": 362,
            "value": "青山区",
            "label": "青山区"
          },
          {
            "id": 366,
            "pid": 362,
            "value": "石拐区",
            "label": "石拐区"
          },
          {
            "id": 367,
            "pid": 362,
            "value": "白云鄂博矿区",
            "label": "白云鄂博矿区"
          },
          {
            "id": 368,
            "pid": 362,
            "value": "九原区",
            "label": "九原区"
          },
          {
            "id": 369,
            "pid": 362,
            "value": "土默特右旗",
            "label": "土默特右旗"
          },
          {
            "id": 370,
            "pid": 362,
            "value": "固阳县",
            "label": "固阳县"
          },
          {
            "id": 371,
            "pid": 362,
            "value": "达尔罕茂明安联合旗",
            "label": "达尔罕茂明安联合旗"
          }
        ]
      },
      {
        "id": 372,
        "pid": 351,
        "value": "乌海市",
        "label": "乌海市",
        "children": [
          {
            "id": 373,
            "pid": 372,
            "value": "海勃湾区",
            "label": "海勃湾区"
          },
          {
            "id": 374,
            "pid": 372,
            "value": "海南区",
            "label": "海南区"
          },
          {
            "id": 375,
            "pid": 372,
            "value": "乌达区",
            "label": "乌达区"
          }
        ]
      },
      {
        "id": 376,
        "pid": 351,
        "value": "赤峰市",
        "label": "赤峰市",
        "children": [
          {
            "id": 377,
            "pid": 376,
            "value": "红山区",
            "label": "红山区"
          },
          {
            "id": 378,
            "pid": 376,
            "value": "元宝山区",
            "label": "元宝山区"
          },
          {
            "id": 379,
            "pid": 376,
            "value": "松山区",
            "label": "松山区"
          },
          {
            "id": 380,
            "pid": 376,
            "value": "阿鲁科尔沁旗",
            "label": "阿鲁科尔沁旗"
          },
          {
            "id": 381,
            "pid": 376,
            "value": "巴林左旗",
            "label": "巴林左旗"
          },
          {
            "id": 382,
            "pid": 376,
            "value": "巴林右旗",
            "label": "巴林右旗"
          },
          {
            "id": 383,
            "pid": 376,
            "value": "林西县",
            "label": "林西县"
          },
          {
            "id": 384,
            "pid": 376,
            "value": "克什克腾旗",
            "label": "克什克腾旗"
          },
          {
            "id": 385,
            "pid": 376,
            "value": "翁牛特旗",
            "label": "翁牛特旗"
          },
          {
            "id": 386,
            "pid": 376,
            "value": "喀喇沁旗",
            "label": "喀喇沁旗"
          },
          {
            "id": 387,
            "pid": 376,
            "value": "宁城县",
            "label": "宁城县"
          },
          {
            "id": 388,
            "pid": 376,
            "value": "敖汉旗",
            "label": "敖汉旗"
          }
        ]
      },
      {
        "id": 389,
        "pid": 351,
        "value": "通辽市",
        "label": "通辽市",
        "children": [
          {
            "id": 390,
            "pid": 389,
            "value": "科尔沁区",
            "label": "科尔沁区"
          },
          {
            "id": 391,
            "pid": 389,
            "value": "科尔沁左翼中旗",
            "label": "科尔沁左翼中旗"
          },
          {
            "id": 392,
            "pid": 389,
            "value": "科尔沁左翼后旗",
            "label": "科尔沁左翼后旗"
          },
          {
            "id": 393,
            "pid": 389,
            "value": "开鲁县",
            "label": "开鲁县"
          },
          {
            "id": 394,
            "pid": 389,
            "value": "库伦旗",
            "label": "库伦旗"
          },
          {
            "id": 395,
            "pid": 389,
            "value": "奈曼旗",
            "label": "奈曼旗"
          },
          {
            "id": 396,
            "pid": 389,
            "value": "扎鲁特旗",
            "label": "扎鲁特旗"
          },
          {
            "id": 397,
            "pid": 389,
            "value": "霍林郭勒市",
            "label": "霍林郭勒市"
          }
        ]
      },
      {
        "id": 398,
        "pid": 351,
        "value": "鄂尔多斯市",
        "label": "鄂尔多斯市",
        "children": [
          {
            "id": 399,
            "pid": 398,
            "value": "东胜区",
            "label": "东胜区"
          },
          {
            "id": 400,
            "pid": 398,
            "value": "达拉特旗",
            "label": "达拉特旗"
          },
          {
            "id": 401,
            "pid": 398,
            "value": "准格尔旗",
            "label": "准格尔旗"
          },
          {
            "id": 402,
            "pid": 398,
            "value": "鄂托克前旗",
            "label": "鄂托克前旗"
          },
          {
            "id": 403,
            "pid": 398,
            "value": "鄂托克旗",
            "label": "鄂托克旗"
          },
          {
            "id": 404,
            "pid": 398,
            "value": "杭锦旗",
            "label": "杭锦旗"
          },
          {
            "id": 405,
            "pid": 398,
            "value": "乌审旗",
            "label": "乌审旗"
          },
          {
            "id": 406,
            "pid": 398,
            "value": "伊金霍洛旗",
            "label": "伊金霍洛旗"
          }
        ]
      },
      {
        "id": 407,
        "pid": 351,
        "value": "呼伦贝尔市",
        "label": "呼伦贝尔市",
        "children": [
          {
            "id": 408,
            "pid": 407,
            "value": "海拉尔区",
            "label": "海拉尔区"
          },
          {
            "id": 409,
            "pid": 407,
            "value": "扎赉诺尔区",
            "label": "扎赉诺尔区"
          },
          {
            "id": 410,
            "pid": 407,
            "value": "阿荣旗",
            "label": "阿荣旗"
          },
          {
            "id": 411,
            "pid": 407,
            "value": "莫力达瓦达斡尔族自治旗",
            "label": "莫力达瓦达斡尔族自治旗"
          },
          {
            "id": 412,
            "pid": 407,
            "value": "鄂伦春自治旗",
            "label": "鄂伦春自治旗"
          },
          {
            "id": 413,
            "pid": 407,
            "value": "鄂温克族自治旗",
            "label": "鄂温克族自治旗"
          },
          {
            "id": 414,
            "pid": 407,
            "value": "陈巴尔虎旗",
            "label": "陈巴尔虎旗"
          },
          {
            "id": 415,
            "pid": 407,
            "value": "新巴尔虎左旗",
            "label": "新巴尔虎左旗"
          },
          {
            "id": 416,
            "pid": 407,
            "value": "新巴尔虎右旗",
            "label": "新巴尔虎右旗"
          },
          {
            "id": 417,
            "pid": 407,
            "value": "满洲里市",
            "label": "满洲里市"
          },
          {
            "id": 418,
            "pid": 407,
            "value": "牙克石市",
            "label": "牙克石市"
          },
          {
            "id": 419,
            "pid": 407,
            "value": "扎兰屯市",
            "label": "扎兰屯市"
          },
          {
            "id": 420,
            "pid": 407,
            "value": "额尔古纳市",
            "label": "额尔古纳市"
          },
          {
            "id": 421,
            "pid": 407,
            "value": "根河市",
            "label": "根河市"
          }
        ]
      },
      {
        "id": 422,
        "pid": 351,
        "value": "巴彦淖尔市",
        "label": "巴彦淖尔市",
        "children": [
          {
            "id": 423,
            "pid": 422,
            "value": "临河区",
            "label": "临河区"
          },
          {
            "id": 424,
            "pid": 422,
            "value": "五原县",
            "label": "五原县"
          },
          {
            "id": 425,
            "pid": 422,
            "value": "磴口县",
            "label": "磴口县"
          },
          {
            "id": 426,
            "pid": 422,
            "value": "乌拉特前旗",
            "label": "乌拉特前旗"
          },
          {
            "id": 427,
            "pid": 422,
            "value": "乌拉特中旗",
            "label": "乌拉特中旗"
          },
          {
            "id": 428,
            "pid": 422,
            "value": "乌拉特后旗",
            "label": "乌拉特后旗"
          },
          {
            "id": 429,
            "pid": 422,
            "value": "杭锦后旗",
            "label": "杭锦后旗"
          }
        ]
      },
      {
        "id": 430,
        "pid": 351,
        "value": "乌兰察布市",
        "label": "乌兰察布市",
        "children": [
          {
            "id": 431,
            "pid": 430,
            "value": "集宁区",
            "label": "集宁区"
          },
          {
            "id": 432,
            "pid": 430,
            "value": "卓资县",
            "label": "卓资县"
          },
          {
            "id": 433,
            "pid": 430,
            "value": "化德县",
            "label": "化德县"
          },
          {
            "id": 434,
            "pid": 430,
            "value": "商都县",
            "label": "商都县"
          },
          {
            "id": 435,
            "pid": 430,
            "value": "兴和县",
            "label": "兴和县"
          },
          {
            "id": 436,
            "pid": 430,
            "value": "凉城县",
            "label": "凉城县"
          },
          {
            "id": 437,
            "pid": 430,
            "value": "察哈尔右翼前旗",
            "label": "察哈尔右翼前旗"
          },
          {
            "id": 438,
            "pid": 430,
            "value": "察哈尔右翼中旗",
            "label": "察哈尔右翼中旗"
          },
          {
            "id": 439,
            "pid": 430,
            "value": "察哈尔右翼后旗",
            "label": "察哈尔右翼后旗"
          },
          {
            "id": 440,
            "pid": 430,
            "value": "四子王旗",
            "label": "四子王旗"
          },
          {
            "id": 441,
            "pid": 430,
            "value": "丰镇市",
            "label": "丰镇市"
          }
        ]
      },
      {
        "id": 442,
        "pid": 351,
        "value": "兴安盟",
        "label": "兴安盟",
        "children": [
          {
            "id": 443,
            "pid": 442,
            "value": "乌兰浩特市",
            "label": "乌兰浩特市"
          },
          {
            "id": 444,
            "pid": 442,
            "value": "阿尔山市",
            "label": "阿尔山市"
          },
          {
            "id": 445,
            "pid": 442,
            "value": "科尔沁右翼前旗",
            "label": "科尔沁右翼前旗"
          },
          {
            "id": 446,
            "pid": 442,
            "value": "科尔沁右翼中旗",
            "label": "科尔沁右翼中旗"
          },
          {
            "id": 447,
            "pid": 442,
            "value": "扎赉特旗",
            "label": "扎赉特旗"
          },
          {
            "id": 448,
            "pid": 442,
            "value": "突泉县",
            "label": "突泉县"
          }
        ]
      },
      {
        "id": 449,
        "pid": 351,
        "value": "锡林郭勒盟",
        "label": "锡林郭勒盟",
        "children": [
          {
            "id": 450,
            "pid": 449,
            "value": "二连浩特市",
            "label": "二连浩特市"
          },
          {
            "id": 451,
            "pid": 449,
            "value": "锡林浩特市",
            "label": "锡林浩特市"
          },
          {
            "id": 452,
            "pid": 449,
            "value": "阿巴嘎旗",
            "label": "阿巴嘎旗"
          },
          {
            "id": 453,
            "pid": 449,
            "value": "苏尼特左旗",
            "label": "苏尼特左旗"
          },
          {
            "id": 454,
            "pid": 449,
            "value": "苏尼特右旗",
            "label": "苏尼特右旗"
          },
          {
            "id": 455,
            "pid": 449,
            "value": "东乌珠穆沁旗",
            "label": "东乌珠穆沁旗"
          },
          {
            "id": 456,
            "pid": 449,
            "value": "西乌珠穆沁旗",
            "label": "西乌珠穆沁旗"
          },
          {
            "id": 457,
            "pid": 449,
            "value": "太仆寺旗",
            "label": "太仆寺旗"
          },
          {
            "id": 458,
            "pid": 449,
            "value": "镶黄旗",
            "label": "镶黄旗"
          },
          {
            "id": 459,
            "pid": 449,
            "value": "正镶白旗",
            "label": "正镶白旗"
          },
          {
            "id": 460,
            "pid": 449,
            "value": "正蓝旗",
            "label": "正蓝旗"
          },
          {
            "id": 461,
            "pid": 449,
            "value": "多伦县",
            "label": "多伦县"
          }
        ]
      },
      {
        "id": 462,
        "pid": 351,
        "value": "阿拉善盟",
        "label": "阿拉善盟",
        "children": [
          {
            "id": 463,
            "pid": 462,
            "value": "阿拉善左旗",
            "label": "阿拉善左旗"
          },
          {
            "id": 464,
            "pid": 462,
            "value": "阿拉善右旗",
            "label": "阿拉善右旗"
          },
          {
            "id": 465,
            "pid": 462,
            "value": "额济纳旗",
            "label": "额济纳旗"
          }
        ]
      }
    ]
  },
  {
    "id": 466,
    "pid": 0,
    "value": "辽宁省",
    "label": "辽宁省",
    "children": [
      {
        "id": 467,
        "pid": 466,
        "value": "沈阳市",
        "label": "沈阳市",
        "children": [
          {
            "id": 468,
            "pid": 467,
            "value": "和平区",
            "label": "和平区"
          },
          {
            "id": 469,
            "pid": 467,
            "value": "沈河区",
            "label": "沈河区"
          },
          {
            "id": 470,
            "pid": 467,
            "value": "大东区",
            "label": "大东区"
          },
          {
            "id": 471,
            "pid": 467,
            "value": "皇姑区",
            "label": "皇姑区"
          },
          {
            "id": 472,
            "pid": 467,
            "value": "铁西区",
            "label": "铁西区"
          },
          {
            "id": 473,
            "pid": 467,
            "value": "苏家屯区",
            "label": "苏家屯区"
          },
          {
            "id": 474,
            "pid": 467,
            "value": "浑南区",
            "label": "浑南区"
          },
          {
            "id": 475,
            "pid": 467,
            "value": "沈北新区",
            "label": "沈北新区"
          },
          {
            "id": 476,
            "pid": 467,
            "value": "于洪区",
            "label": "于洪区"
          },
          {
            "id": 477,
            "pid": 467,
            "value": "辽中县",
            "label": "辽中县"
          },
          {
            "id": 478,
            "pid": 467,
            "value": "康平县",
            "label": "康平县"
          },
          {
            "id": 479,
            "pid": 467,
            "value": "法库县",
            "label": "法库县"
          },
          {
            "id": 480,
            "pid": 467,
            "value": "新民市",
            "label": "新民市"
          }
        ]
      },
      {
        "id": 481,
        "pid": 466,
        "value": "大连市",
        "label": "大连市",
        "children": [
          {
            "id": 482,
            "pid": 481,
            "value": "中山区",
            "label": "中山区"
          },
          {
            "id": 483,
            "pid": 481,
            "value": "西岗区",
            "label": "西岗区"
          },
          {
            "id": 484,
            "pid": 481,
            "value": "沙河口区",
            "label": "沙河口区"
          },
          {
            "id": 485,
            "pid": 481,
            "value": "甘井子区",
            "label": "甘井子区"
          },
          {
            "id": 486,
            "pid": 481,
            "value": "旅顺口区",
            "label": "旅顺口区"
          },
          {
            "id": 487,
            "pid": 481,
            "value": "金州区",
            "label": "金州区"
          },
          {
            "id": 488,
            "pid": 481,
            "value": "长海县",
            "label": "长海县"
          },
          {
            "id": 489,
            "pid": 481,
            "value": "瓦房店市",
            "label": "瓦房店市"
          },
          {
            "id": 490,
            "pid": 481,
            "value": "普兰店市",
            "label": "普兰店市"
          },
          {
            "id": 491,
            "pid": 481,
            "value": "庄河市",
            "label": "庄河市"
          }
        ]
      },
      {
        "id": 492,
        "pid": 466,
        "value": "鞍山市",
        "label": "鞍山市",
        "children": [
          {
            "id": 493,
            "pid": 492,
            "value": "铁东区",
            "label": "铁东区"
          },
          {
            "id": 494,
            "pid": 492,
            "value": "铁西区",
            "label": "铁西区"
          },
          {
            "id": 495,
            "pid": 492,
            "value": "立山区",
            "label": "立山区"
          },
          {
            "id": 496,
            "pid": 492,
            "value": "千山区",
            "label": "千山区"
          },
          {
            "id": 497,
            "pid": 492,
            "value": "台安县",
            "label": "台安县"
          },
          {
            "id": 498,
            "pid": 492,
            "value": "岫岩满族自治县",
            "label": "岫岩满族自治县"
          },
          {
            "id": 499,
            "pid": 492,
            "value": "海城市",
            "label": "海城市"
          }
        ]
      },
      {
        "id": 500,
        "pid": 466,
        "value": "抚顺市",
        "label": "抚顺市",
        "children": [
          {
            "id": 501,
            "pid": 500,
            "value": "新抚区",
            "label": "新抚区"
          },
          {
            "id": 502,
            "pid": 500,
            "value": "东洲区",
            "label": "东洲区"
          },
          {
            "id": 503,
            "pid": 500,
            "value": "望花区",
            "label": "望花区"
          },
          {
            "id": 504,
            "pid": 500,
            "value": "顺城区",
            "label": "顺城区"
          },
          {
            "id": 505,
            "pid": 500,
            "value": "抚顺县",
            "label": "抚顺县"
          },
          {
            "id": 506,
            "pid": 500,
            "value": "新宾满族自治县",
            "label": "新宾满族自治县"
          },
          {
            "id": 507,
            "pid": 500,
            "value": "清原满族自治县",
            "label": "清原满族自治县"
          }
        ]
      },
      {
        "id": 508,
        "pid": 466,
        "value": "本溪市",
        "label": "本溪市",
        "children": [
          {
            "id": 509,
            "pid": 508,
            "value": "平山区",
            "label": "平山区"
          },
          {
            "id": 510,
            "pid": 508,
            "value": "溪湖区",
            "label": "溪湖区"
          },
          {
            "id": 511,
            "pid": 508,
            "value": "明山区",
            "label": "明山区"
          },
          {
            "id": 512,
            "pid": 508,
            "value": "南芬区",
            "label": "南芬区"
          },
          {
            "id": 513,
            "pid": 508,
            "value": "本溪满族自治县",
            "label": "本溪满族自治县"
          },
          {
            "id": 514,
            "pid": 508,
            "value": "桓仁满族自治县",
            "label": "桓仁满族自治县"
          }
        ]
      },
      {
        "id": 515,
        "pid": 466,
        "value": "丹东市",
        "label": "丹东市",
        "children": [
          {
            "id": 516,
            "pid": 515,
            "value": "元宝区",
            "label": "元宝区"
          },
          {
            "id": 517,
            "pid": 515,
            "value": "振兴区",
            "label": "振兴区"
          },
          {
            "id": 518,
            "pid": 515,
            "value": "振安区",
            "label": "振安区"
          },
          {
            "id": 519,
            "pid": 515,
            "value": "宽甸满族自治县",
            "label": "宽甸满族自治县"
          },
          {
            "id": 520,
            "pid": 515,
            "value": "东港市",
            "label": "东港市"
          },
          {
            "id": 521,
            "pid": 515,
            "value": "凤城市",
            "label": "凤城市"
          }
        ]
      },
      {
        "id": 522,
        "pid": 466,
        "value": "锦州市",
        "label": "锦州市",
        "children": [
          {
            "id": 523,
            "pid": 522,
            "value": "古塔区",
            "label": "古塔区"
          },
          {
            "id": 524,
            "pid": 522,
            "value": "凌河区",
            "label": "凌河区"
          },
          {
            "id": 525,
            "pid": 522,
            "value": "太和区",
            "label": "太和区"
          },
          {
            "id": 526,
            "pid": 522,
            "value": "黑山县",
            "label": "黑山县"
          },
          {
            "id": 527,
            "pid": 522,
            "value": "义县",
            "label": "义县"
          },
          {
            "id": 528,
            "pid": 522,
            "value": "凌海市",
            "label": "凌海市"
          },
          {
            "id": 529,
            "pid": 522,
            "value": "北镇市",
            "label": "北镇市"
          }
        ]
      },
      {
        "id": 530,
        "pid": 466,
        "value": "营口市",
        "label": "营口市",
        "children": [
          {
            "id": 531,
            "pid": 530,
            "value": "站前区",
            "label": "站前区"
          },
          {
            "id": 532,
            "pid": 530,
            "value": "西市区",
            "label": "西市区"
          },
          {
            "id": 533,
            "pid": 530,
            "value": "鲅鱼圈区",
            "label": "鲅鱼圈区"
          },
          {
            "id": 534,
            "pid": 530,
            "value": "老边区",
            "label": "老边区"
          },
          {
            "id": 535,
            "pid": 530,
            "value": "盖州市",
            "label": "盖州市"
          },
          {
            "id": 536,
            "pid": 530,
            "value": "大石桥市",
            "label": "大石桥市"
          }
        ]
      },
      {
        "id": 537,
        "pid": 466,
        "value": "阜新市",
        "label": "阜新市",
        "children": [
          {
            "id": 538,
            "pid": 537,
            "value": "海州区",
            "label": "海州区"
          },
          {
            "id": 539,
            "pid": 537,
            "value": "新邱区",
            "label": "新邱区"
          },
          {
            "id": 540,
            "pid": 537,
            "value": "太平区",
            "label": "太平区"
          },
          {
            "id": 541,
            "pid": 537,
            "value": "清河门区",
            "label": "清河门区"
          },
          {
            "id": 542,
            "pid": 537,
            "value": "细河区",
            "label": "细河区"
          },
          {
            "id": 543,
            "pid": 537,
            "value": "阜新蒙古族自治县",
            "label": "阜新蒙古族自治县"
          },
          {
            "id": 544,
            "pid": 537,
            "value": "彰武县",
            "label": "彰武县"
          }
        ]
      },
      {
        "id": 545,
        "pid": 466,
        "value": "辽阳市",
        "label": "辽阳市",
        "children": [
          {
            "id": 546,
            "pid": 545,
            "value": "白塔区",
            "label": "白塔区"
          },
          {
            "id": 547,
            "pid": 545,
            "value": "文圣区",
            "label": "文圣区"
          },
          {
            "id": 548,
            "pid": 545,
            "value": "宏伟区",
            "label": "宏伟区"
          },
          {
            "id": 549,
            "pid": 545,
            "value": "弓长岭区",
            "label": "弓长岭区"
          },
          {
            "id": 550,
            "pid": 545,
            "value": "太子河区",
            "label": "太子河区"
          },
          {
            "id": 551,
            "pid": 545,
            "value": "辽阳县",
            "label": "辽阳县"
          },
          {
            "id": 552,
            "pid": 545,
            "value": "灯塔市",
            "label": "灯塔市"
          }
        ]
      },
      {
        "id": 553,
        "pid": 466,
        "value": "盘锦市",
        "label": "盘锦市",
        "children": [
          {
            "id": 554,
            "pid": 553,
            "value": "双台子区",
            "label": "双台子区"
          },
          {
            "id": 555,
            "pid": 553,
            "value": "兴隆台区",
            "label": "兴隆台区"
          },
          {
            "id": 556,
            "pid": 553,
            "value": "大洼县",
            "label": "大洼县"
          },
          {
            "id": 557,
            "pid": 553,
            "value": "盘山县",
            "label": "盘山县"
          }
        ]
      },
      {
        "id": 558,
        "pid": 466,
        "value": "铁岭市",
        "label": "铁岭市",
        "children": [
          {
            "id": 559,
            "pid": 558,
            "value": "银州区",
            "label": "银州区"
          },
          {
            "id": 560,
            "pid": 558,
            "value": "清河区",
            "label": "清河区"
          },
          {
            "id": 561,
            "pid": 558,
            "value": "铁岭县",
            "label": "铁岭县"
          },
          {
            "id": 562,
            "pid": 558,
            "value": "西丰县",
            "label": "西丰县"
          },
          {
            "id": 563,
            "pid": 558,
            "value": "昌图县",
            "label": "昌图县"
          },
          {
            "id": 564,
            "pid": 558,
            "value": "调兵山市",
            "label": "调兵山市"
          },
          {
            "id": 565,
            "pid": 558,
            "value": "开原市",
            "label": "开原市"
          }
        ]
      },
      {
        "id": 566,
        "pid": 466,
        "value": "朝阳市",
        "label": "朝阳市",
        "children": [
          {
            "id": 567,
            "pid": 566,
            "value": "双塔区",
            "label": "双塔区"
          },
          {
            "id": 568,
            "pid": 566,
            "value": "龙城区",
            "label": "龙城区"
          },
          {
            "id": 569,
            "pid": 566,
            "value": "朝阳县",
            "label": "朝阳县"
          },
          {
            "id": 570,
            "pid": 566,
            "value": "建平县",
            "label": "建平县"
          },
          {
            "id": 571,
            "pid": 566,
            "value": "喀喇沁左翼蒙古族自治县",
            "label": "喀喇沁左翼蒙古族自治县"
          },
          {
            "id": 572,
            "pid": 566,
            "value": "北票市",
            "label": "北票市"
          },
          {
            "id": 573,
            "pid": 566,
            "value": "凌源市",
            "label": "凌源市"
          }
        ]
      },
      {
        "id": 574,
        "pid": 466,
        "value": "葫芦岛市",
        "label": "葫芦岛市",
        "children": [
          {
            "id": 575,
            "pid": 574,
            "value": "连山区",
            "label": "连山区"
          },
          {
            "id": 576,
            "pid": 574,
            "value": "龙港区",
            "label": "龙港区"
          },
          {
            "id": 577,
            "pid": 574,
            "value": "南票区",
            "label": "南票区"
          },
          {
            "id": 578,
            "pid": 574,
            "value": "绥中县",
            "label": "绥中县"
          },
          {
            "id": 579,
            "pid": 574,
            "value": "建昌县",
            "label": "建昌县"
          },
          {
            "id": 580,
            "pid": 574,
            "value": "兴城市",
            "label": "兴城市"
          }
        ]
      },
      {
        "id": 581,
        "pid": 466,
        "value": "金普新区",
        "label": "金普新区",
        "children": [
          {
            "id": 582,
            "pid": 581,
            "value": "金州新区",
            "label": "金州新区"
          },
          {
            "id": 583,
            "pid": 581,
            "value": "普湾新区",
            "label": "普湾新区"
          },
          {
            "id": 584,
            "pid": 581,
            "value": "保税区",
            "label": "保税区"
          }
        ]
      }
    ]
  },
  {
    "id": 585,
    "pid": 0,
    "value": "吉林省",
    "label": "吉林省",
    "children": [
      {
        "id": 586,
        "pid": 585,
        "value": "长春市",
        "label": "长春市",
        "children": [
          {
            "id": 587,
            "pid": 586,
            "value": "南关区",
            "label": "南关区"
          },
          {
            "id": 588,
            "pid": 586,
            "value": "宽城区",
            "label": "宽城区"
          },
          {
            "id": 589,
            "pid": 586,
            "value": "朝阳区",
            "label": "朝阳区"
          },
          {
            "id": 590,
            "pid": 586,
            "value": "二道区",
            "label": "二道区"
          },
          {
            "id": 591,
            "pid": 586,
            "value": "绿园区",
            "label": "绿园区"
          },
          {
            "id": 592,
            "pid": 586,
            "value": "双阳区",
            "label": "双阳区"
          },
          {
            "id": 593,
            "pid": 586,
            "value": "九台区",
            "label": "九台区"
          },
          {
            "id": 594,
            "pid": 586,
            "value": "农安县",
            "label": "农安县"
          },
          {
            "id": 595,
            "pid": 586,
            "value": "榆树市",
            "label": "榆树市"
          },
          {
            "id": 596,
            "pid": 586,
            "value": "德惠市",
            "label": "德惠市"
          }
        ]
      },
      {
        "id": 597,
        "pid": 585,
        "value": "吉林市",
        "label": "吉林市",
        "children": [
          {
            "id": 598,
            "pid": 597,
            "value": "昌邑区",
            "label": "昌邑区"
          },
          {
            "id": 599,
            "pid": 597,
            "value": "龙潭区",
            "label": "龙潭区"
          },
          {
            "id": 600,
            "pid": 597,
            "value": "船营区",
            "label": "船营区"
          },
          {
            "id": 601,
            "pid": 597,
            "value": "丰满区",
            "label": "丰满区"
          },
          {
            "id": 602,
            "pid": 597,
            "value": "永吉县",
            "label": "永吉县"
          },
          {
            "id": 603,
            "pid": 597,
            "value": "蛟河市",
            "label": "蛟河市"
          },
          {
            "id": 604,
            "pid": 597,
            "value": "桦甸市",
            "label": "桦甸市"
          },
          {
            "id": 605,
            "pid": 597,
            "value": "舒兰市",
            "label": "舒兰市"
          },
          {
            "id": 606,
            "pid": 597,
            "value": "磐石市",
            "label": "磐石市"
          }
        ]
      },
      {
        "id": 607,
        "pid": 585,
        "value": "四平市",
        "label": "四平市",
        "children": [
          {
            "id": 608,
            "pid": 607,
            "value": "铁西区",
            "label": "铁西区"
          },
          {
            "id": 609,
            "pid": 607,
            "value": "铁东区",
            "label": "铁东区"
          },
          {
            "id": 610,
            "pid": 607,
            "value": "梨树县",
            "label": "梨树县"
          },
          {
            "id": 611,
            "pid": 607,
            "value": "伊通满族自治县",
            "label": "伊通满族自治县"
          },
          {
            "id": 612,
            "pid": 607,
            "value": "公主岭市",
            "label": "公主岭市"
          },
          {
            "id": 613,
            "pid": 607,
            "value": "双辽市",
            "label": "双辽市"
          }
        ]
      },
      {
        "id": 614,
        "pid": 585,
        "value": "辽源市",
        "label": "辽源市",
        "children": [
          {
            "id": 615,
            "pid": 614,
            "value": "龙山区",
            "label": "龙山区"
          },
          {
            "id": 616,
            "pid": 614,
            "value": "西安区",
            "label": "西安区"
          },
          {
            "id": 617,
            "pid": 614,
            "value": "东丰县",
            "label": "东丰县"
          },
          {
            "id": 618,
            "pid": 614,
            "value": "东辽县",
            "label": "东辽县"
          }
        ]
      },
      {
        "id": 619,
        "pid": 585,
        "value": "通化市",
        "label": "通化市",
        "children": [
          {
            "id": 620,
            "pid": 619,
            "value": "东昌区",
            "label": "东昌区"
          },
          {
            "id": 621,
            "pid": 619,
            "value": "二道江区",
            "label": "二道江区"
          },
          {
            "id": 622,
            "pid": 619,
            "value": "通化县",
            "label": "通化县"
          },
          {
            "id": 623,
            "pid": 619,
            "value": "辉南县",
            "label": "辉南县"
          },
          {
            "id": 624,
            "pid": 619,
            "value": "柳河县",
            "label": "柳河县"
          },
          {
            "id": 625,
            "pid": 619,
            "value": "梅河口市",
            "label": "梅河口市"
          },
          {
            "id": 626,
            "pid": 619,
            "value": "集安市",
            "label": "集安市"
          }
        ]
      },
      {
        "id": 627,
        "pid": 585,
        "value": "白山市",
        "label": "白山市",
        "children": [
          {
            "id": 628,
            "pid": 627,
            "value": "浑江区",
            "label": "浑江区"
          },
          {
            "id": 629,
            "pid": 627,
            "value": "江源区",
            "label": "江源区"
          },
          {
            "id": 630,
            "pid": 627,
            "value": "抚松县",
            "label": "抚松县"
          },
          {
            "id": 631,
            "pid": 627,
            "value": "靖宇县",
            "label": "靖宇县"
          },
          {
            "id": 632,
            "pid": 627,
            "value": "长白朝鲜族自治县",
            "label": "长白朝鲜族自治县"
          },
          {
            "id": 633,
            "pid": 627,
            "value": "临江市",
            "label": "临江市"
          }
        ]
      },
      {
        "id": 634,
        "pid": 585,
        "value": "松原市",
        "label": "松原市",
        "children": [
          {
            "id": 635,
            "pid": 634,
            "value": "宁江区",
            "label": "宁江区"
          },
          {
            "id": 636,
            "pid": 634,
            "value": "前郭尔罗斯蒙古族自治县",
            "label": "前郭尔罗斯蒙古族自治县"
          },
          {
            "id": 637,
            "pid": 634,
            "value": "长岭县",
            "label": "长岭县"
          },
          {
            "id": 638,
            "pid": 634,
            "value": "乾安县",
            "label": "乾安县"
          },
          {
            "id": 639,
            "pid": 634,
            "value": "扶余市",
            "label": "扶余市"
          }
        ]
      },
      {
        "id": 640,
        "pid": 585,
        "value": "白城市",
        "label": "白城市",
        "children": [
          {
            "id": 641,
            "pid": 640,
            "value": "洮北区",
            "label": "洮北区"
          },
          {
            "id": 642,
            "pid": 640,
            "value": "镇赉县",
            "label": "镇赉县"
          },
          {
            "id": 643,
            "pid": 640,
            "value": "通榆县",
            "label": "通榆县"
          },
          {
            "id": 644,
            "pid": 640,
            "value": "洮南市",
            "label": "洮南市"
          },
          {
            "id": 645,
            "pid": 640,
            "value": "大安市",
            "label": "大安市"
          }
        ]
      },
      {
        "id": 646,
        "pid": 585,
        "value": "延边朝鲜族自治州",
        "label": "延边朝鲜族自治州",
        "children": [
          {
            "id": 647,
            "pid": 646,
            "value": "延吉市",
            "label": "延吉市"
          },
          {
            "id": 648,
            "pid": 646,
            "value": "图们市",
            "label": "图们市"
          },
          {
            "id": 649,
            "pid": 646,
            "value": "敦化市",
            "label": "敦化市"
          },
          {
            "id": 650,
            "pid": 646,
            "value": "珲春市",
            "label": "珲春市"
          },
          {
            "id": 651,
            "pid": 646,
            "value": "龙井市",
            "label": "龙井市"
          },
          {
            "id": 652,
            "pid": 646,
            "value": "和龙市",
            "label": "和龙市"
          },
          {
            "id": 653,
            "pid": 646,
            "value": "汪清县",
            "label": "汪清县"
          },
          {
            "id": 654,
            "pid": 646,
            "value": "安图县",
            "label": "安图县"
          }
        ]
      }
    ]
  },
  {
    "id": 655,
    "pid": 0,
    "value": "黑龙江省",
    "label": "黑龙江省",
    "children": [
      {
        "id": 656,
        "pid": 655,
        "value": "哈尔滨市",
        "label": "哈尔滨市",
        "children": [
          {
            "id": 657,
            "pid": 656,
            "value": "道里区",
            "label": "道里区"
          },
          {
            "id": 658,
            "pid": 656,
            "value": "南岗区",
            "label": "南岗区"
          },
          {
            "id": 659,
            "pid": 656,
            "value": "道外区",
            "label": "道外区"
          },
          {
            "id": 660,
            "pid": 656,
            "value": "平房区",
            "label": "平房区"
          },
          {
            "id": 661,
            "pid": 656,
            "value": "松北区",
            "label": "松北区"
          },
          {
            "id": 662,
            "pid": 656,
            "value": "香坊区",
            "label": "香坊区"
          },
          {
            "id": 663,
            "pid": 656,
            "value": "呼兰区",
            "label": "呼兰区"
          },
          {
            "id": 664,
            "pid": 656,
            "value": "阿城区",
            "label": "阿城区"
          },
          {
            "id": 665,
            "pid": 656,
            "value": "双城区",
            "label": "双城区"
          },
          {
            "id": 666,
            "pid": 656,
            "value": "依兰县",
            "label": "依兰县"
          },
          {
            "id": 667,
            "pid": 656,
            "value": "方正县",
            "label": "方正县"
          },
          {
            "id": 668,
            "pid": 656,
            "value": "宾县",
            "label": "宾县"
          },
          {
            "id": 669,
            "pid": 656,
            "value": "巴彦县",
            "label": "巴彦县"
          },
          {
            "id": 670,
            "pid": 656,
            "value": "木兰县",
            "label": "木兰县"
          },
          {
            "id": 671,
            "pid": 656,
            "value": "通河县",
            "label": "通河县"
          },
          {
            "id": 672,
            "pid": 656,
            "value": "延寿县",
            "label": "延寿县"
          },
          {
            "id": 673,
            "pid": 656,
            "value": "尚志市",
            "label": "尚志市"
          },
          {
            "id": 674,
            "pid": 656,
            "value": "五常市",
            "label": "五常市"
          }
        ]
      },
      {
        "id": 675,
        "pid": 655,
        "value": "齐齐哈尔市",
        "label": "齐齐哈尔市",
        "children": [
          {
            "id": 676,
            "pid": 675,
            "value": "龙沙区",
            "label": "龙沙区"
          },
          {
            "id": 677,
            "pid": 675,
            "value": "建华区",
            "label": "建华区"
          },
          {
            "id": 678,
            "pid": 675,
            "value": "铁锋区",
            "label": "铁锋区"
          },
          {
            "id": 679,
            "pid": 675,
            "value": "昂昂溪区",
            "label": "昂昂溪区"
          },
          {
            "id": 680,
            "pid": 675,
            "value": "富拉尔基区",
            "label": "富拉尔基区"
          },
          {
            "id": 681,
            "pid": 675,
            "value": "碾子山区",
            "label": "碾子山区"
          },
          {
            "id": 682,
            "pid": 675,
            "value": "梅里斯达斡尔族区",
            "label": "梅里斯达斡尔族区"
          },
          {
            "id": 683,
            "pid": 675,
            "value": "龙江县",
            "label": "龙江县"
          },
          {
            "id": 684,
            "pid": 675,
            "value": "依安县",
            "label": "依安县"
          },
          {
            "id": 685,
            "pid": 675,
            "value": "泰来县",
            "label": "泰来县"
          },
          {
            "id": 686,
            "pid": 675,
            "value": "甘南县",
            "label": "甘南县"
          },
          {
            "id": 687,
            "pid": 675,
            "value": "富裕县",
            "label": "富裕县"
          },
          {
            "id": 688,
            "pid": 675,
            "value": "克山县",
            "label": "克山县"
          },
          {
            "id": 689,
            "pid": 675,
            "value": "克东县",
            "label": "克东县"
          },
          {
            "id": 690,
            "pid": 675,
            "value": "拜泉县",
            "label": "拜泉县"
          },
          {
            "id": 691,
            "pid": 675,
            "value": "讷河市",
            "label": "讷河市"
          }
        ]
      },
      {
        "id": 692,
        "pid": 655,
        "value": "鸡西市",
        "label": "鸡西市",
        "children": [
          {
            "id": 693,
            "pid": 692,
            "value": "鸡冠区",
            "label": "鸡冠区"
          },
          {
            "id": 694,
            "pid": 692,
            "value": "恒山区",
            "label": "恒山区"
          },
          {
            "id": 695,
            "pid": 692,
            "value": "滴道区",
            "label": "滴道区"
          },
          {
            "id": 696,
            "pid": 692,
            "value": "梨树区",
            "label": "梨树区"
          },
          {
            "id": 697,
            "pid": 692,
            "value": "城子河区",
            "label": "城子河区"
          },
          {
            "id": 698,
            "pid": 692,
            "value": "麻山区",
            "label": "麻山区"
          },
          {
            "id": 699,
            "pid": 692,
            "value": "鸡东县",
            "label": "鸡东县"
          },
          {
            "id": 700,
            "pid": 692,
            "value": "虎林市",
            "label": "虎林市"
          },
          {
            "id": 701,
            "pid": 692,
            "value": "密山市",
            "label": "密山市"
          }
        ]
      },
      {
        "id": 702,
        "pid": 655,
        "value": "鹤岗市",
        "label": "鹤岗市",
        "children": [
          {
            "id": 703,
            "pid": 702,
            "value": "向阳区",
            "label": "向阳区"
          },
          {
            "id": 704,
            "pid": 702,
            "value": "工农区",
            "label": "工农区"
          },
          {
            "id": 705,
            "pid": 702,
            "value": "南山区",
            "label": "南山区"
          },
          {
            "id": 706,
            "pid": 702,
            "value": "兴安区",
            "label": "兴安区"
          },
          {
            "id": 707,
            "pid": 702,
            "value": "东山区",
            "label": "东山区"
          },
          {
            "id": 708,
            "pid": 702,
            "value": "兴山区",
            "label": "兴山区"
          },
          {
            "id": 709,
            "pid": 702,
            "value": "萝北县",
            "label": "萝北县"
          },
          {
            "id": 710,
            "pid": 702,
            "value": "绥滨县",
            "label": "绥滨县"
          }
        ]
      },
      {
        "id": 711,
        "pid": 655,
        "value": "双鸭山市",
        "label": "双鸭山市",
        "children": [
          {
            "id": 712,
            "pid": 711,
            "value": "尖山区",
            "label": "尖山区"
          },
          {
            "id": 713,
            "pid": 711,
            "value": "岭东区",
            "label": "岭东区"
          },
          {
            "id": 714,
            "pid": 711,
            "value": "四方台区",
            "label": "四方台区"
          },
          {
            "id": 715,
            "pid": 711,
            "value": "宝山区",
            "label": "宝山区"
          },
          {
            "id": 716,
            "pid": 711,
            "value": "集贤县",
            "label": "集贤县"
          },
          {
            "id": 717,
            "pid": 711,
            "value": "友谊县",
            "label": "友谊县"
          },
          {
            "id": 718,
            "pid": 711,
            "value": "宝清县",
            "label": "宝清县"
          },
          {
            "id": 719,
            "pid": 711,
            "value": "饶河县",
            "label": "饶河县"
          }
        ]
      },
      {
        "id": 720,
        "pid": 655,
        "value": "大庆市",
        "label": "大庆市",
        "children": [
          {
            "id": 721,
            "pid": 720,
            "value": "萨尔图区",
            "label": "萨尔图区"
          },
          {
            "id": 722,
            "pid": 720,
            "value": "龙凤区",
            "label": "龙凤区"
          },
          {
            "id": 723,
            "pid": 720,
            "value": "让胡路区",
            "label": "让胡路区"
          },
          {
            "id": 724,
            "pid": 720,
            "value": "红岗区",
            "label": "红岗区"
          },
          {
            "id": 725,
            "pid": 720,
            "value": "大同区",
            "label": "大同区"
          },
          {
            "id": 726,
            "pid": 720,
            "value": "肇州县",
            "label": "肇州县"
          },
          {
            "id": 727,
            "pid": 720,
            "value": "肇源县",
            "label": "肇源县"
          },
          {
            "id": 728,
            "pid": 720,
            "value": "林甸县",
            "label": "林甸县"
          },
          {
            "id": 729,
            "pid": 720,
            "value": "杜尔伯特蒙古族自治县",
            "label": "杜尔伯特蒙古族自治县"
          }
        ]
      },
      {
        "id": 730,
        "pid": 655,
        "value": "伊春市",
        "label": "伊春市",
        "children": [
          {
            "id": 731,
            "pid": 730,
            "value": "伊春区",
            "label": "伊春区"
          },
          {
            "id": 732,
            "pid": 730,
            "value": "南岔区",
            "label": "南岔区"
          },
          {
            "id": 733,
            "pid": 730,
            "value": "友好区",
            "label": "友好区"
          },
          {
            "id": 734,
            "pid": 730,
            "value": "西林区",
            "label": "西林区"
          },
          {
            "id": 735,
            "pid": 730,
            "value": "翠峦区",
            "label": "翠峦区"
          },
          {
            "id": 736,
            "pid": 730,
            "value": "新青区",
            "label": "新青区"
          },
          {
            "id": 737,
            "pid": 730,
            "value": "美溪区",
            "label": "美溪区"
          },
          {
            "id": 738,
            "pid": 730,
            "value": "金山屯区",
            "label": "金山屯区"
          },
          {
            "id": 739,
            "pid": 730,
            "value": "五营区",
            "label": "五营区"
          },
          {
            "id": 740,
            "pid": 730,
            "value": "乌马河区",
            "label": "乌马河区"
          },
          {
            "id": 741,
            "pid": 730,
            "value": "汤旺河区",
            "label": "汤旺河区"
          },
          {
            "id": 742,
            "pid": 730,
            "value": "带岭区",
            "label": "带岭区"
          },
          {
            "id": 743,
            "pid": 730,
            "value": "乌伊岭区",
            "label": "乌伊岭区"
          },
          {
            "id": 744,
            "pid": 730,
            "value": "红星区",
            "label": "红星区"
          },
          {
            "id": 745,
            "pid": 730,
            "value": "上甘岭区",
            "label": "上甘岭区"
          },
          {
            "id": 746,
            "pid": 730,
            "value": "嘉荫县",
            "label": "嘉荫县"
          },
          {
            "id": 747,
            "pid": 730,
            "value": "铁力市",
            "label": "铁力市"
          }
        ]
      },
      {
        "id": 748,
        "pid": 655,
        "value": "佳木斯市",
        "label": "佳木斯市",
        "children": [
          {
            "id": 749,
            "pid": 748,
            "value": "向阳区",
            "label": "向阳区"
          },
          {
            "id": 750,
            "pid": 748,
            "value": "前进区",
            "label": "前进区"
          },
          {
            "id": 751,
            "pid": 748,
            "value": "东风区",
            "label": "东风区"
          },
          {
            "id": 752,
            "pid": 748,
            "value": "郊区",
            "label": "郊区"
          },
          {
            "id": 753,
            "pid": 748,
            "value": "桦南县",
            "label": "桦南县"
          },
          {
            "id": 754,
            "pid": 748,
            "value": "桦川县",
            "label": "桦川县"
          },
          {
            "id": 755,
            "pid": 748,
            "value": "汤原县",
            "label": "汤原县"
          },
          {
            "id": 756,
            "pid": 748,
            "value": "抚远县",
            "label": "抚远县"
          },
          {
            "id": 757,
            "pid": 748,
            "value": "同江市",
            "label": "同江市"
          },
          {
            "id": 758,
            "pid": 748,
            "value": "富锦市",
            "label": "富锦市"
          }
        ]
      },
      {
        "id": 759,
        "pid": 655,
        "value": "七台河市",
        "label": "七台河市",
        "children": [
          {
            "id": 760,
            "pid": 759,
            "value": "新兴区",
            "label": "新兴区"
          },
          {
            "id": 761,
            "pid": 759,
            "value": "桃山区",
            "label": "桃山区"
          },
          {
            "id": 762,
            "pid": 759,
            "value": "茄子河区",
            "label": "茄子河区"
          },
          {
            "id": 763,
            "pid": 759,
            "value": "勃利县",
            "label": "勃利县"
          }
        ]
      },
      {
        "id": 764,
        "pid": 655,
        "value": "牡丹江市",
        "label": "牡丹江市",
        "children": [
          {
            "id": 765,
            "pid": 764,
            "value": "东安区",
            "label": "东安区"
          },
          {
            "id": 766,
            "pid": 764,
            "value": "阳明区",
            "label": "阳明区"
          },
          {
            "id": 767,
            "pid": 764,
            "value": "爱民区",
            "label": "爱民区"
          },
          {
            "id": 768,
            "pid": 764,
            "value": "西安区",
            "label": "西安区"
          },
          {
            "id": 769,
            "pid": 764,
            "value": "东宁县",
            "label": "东宁县"
          },
          {
            "id": 770,
            "pid": 764,
            "value": "林口县",
            "label": "林口县"
          },
          {
            "id": 771,
            "pid": 764,
            "value": "绥芬河市",
            "label": "绥芬河市"
          },
          {
            "id": 772,
            "pid": 764,
            "value": "海林市",
            "label": "海林市"
          },
          {
            "id": 773,
            "pid": 764,
            "value": "宁安市",
            "label": "宁安市"
          },
          {
            "id": 774,
            "pid": 764,
            "value": "穆棱市",
            "label": "穆棱市"
          }
        ]
      },
      {
        "id": 775,
        "pid": 655,
        "value": "黑河市",
        "label": "黑河市",
        "children": [
          {
            "id": 776,
            "pid": 775,
            "value": "爱辉区",
            "label": "爱辉区"
          },
          {
            "id": 777,
            "pid": 775,
            "value": "嫩江县",
            "label": "嫩江县"
          },
          {
            "id": 778,
            "pid": 775,
            "value": "逊克县",
            "label": "逊克县"
          },
          {
            "id": 779,
            "pid": 775,
            "value": "孙吴县",
            "label": "孙吴县"
          },
          {
            "id": 780,
            "pid": 775,
            "value": "北安市",
            "label": "北安市"
          },
          {
            "id": 781,
            "pid": 775,
            "value": "五大连池市",
            "label": "五大连池市"
          }
        ]
      },
      {
        "id": 782,
        "pid": 655,
        "value": "绥化市",
        "label": "绥化市",
        "children": [
          {
            "id": 783,
            "pid": 782,
            "value": "北林区",
            "label": "北林区"
          },
          {
            "id": 784,
            "pid": 782,
            "value": "望奎县",
            "label": "望奎县"
          },
          {
            "id": 785,
            "pid": 782,
            "value": "兰西县",
            "label": "兰西县"
          },
          {
            "id": 786,
            "pid": 782,
            "value": "青冈县",
            "label": "青冈县"
          },
          {
            "id": 787,
            "pid": 782,
            "value": "庆安县",
            "label": "庆安县"
          },
          {
            "id": 788,
            "pid": 782,
            "value": "明水县",
            "label": "明水县"
          },
          {
            "id": 789,
            "pid": 782,
            "value": "绥棱县",
            "label": "绥棱县"
          },
          {
            "id": 790,
            "pid": 782,
            "value": "安达市",
            "label": "安达市"
          },
          {
            "id": 791,
            "pid": 782,
            "value": "肇东市",
            "label": "肇东市"
          },
          {
            "id": 792,
            "pid": 782,
            "value": "海伦市",
            "label": "海伦市"
          }
        ]
      },
      {
        "id": 793,
        "pid": 655,
        "value": "大兴安岭地区",
        "label": "大兴安岭地区",
        "children": [
          {
            "id": 794,
            "pid": 793,
            "value": "加格达奇区",
            "label": "加格达奇区"
          },
          {
            "id": 795,
            "pid": 793,
            "value": "新林区",
            "label": "新林区"
          },
          {
            "id": 796,
            "pid": 793,
            "value": "松岭区",
            "label": "松岭区"
          },
          {
            "id": 797,
            "pid": 793,
            "value": "呼中区",
            "label": "呼中区"
          },
          {
            "id": 798,
            "pid": 793,
            "value": "呼玛县",
            "label": "呼玛县"
          },
          {
            "id": 799,
            "pid": 793,
            "value": "塔河县",
            "label": "塔河县"
          },
          {
            "id": 800,
            "pid": 793,
            "value": "漠河县",
            "label": "漠河县"
          }
        ]
      }
    ]
  },
  {
    "id": 801,
    "pid": 0,
    "value": "上海",
    "label": "上海",
    "children": [
      {
        "id": 802,
        "pid": 801,
        "value": "上海市",
        "label": "上海市",
        "children": [
          {
            "id": 803,
            "pid": 802,
            "value": "黄浦区",
            "label": "黄浦区"
          },
          {
            "id": 804,
            "pid": 802,
            "value": "徐汇区",
            "label": "徐汇区"
          },
          {
            "id": 805,
            "pid": 802,
            "value": "长宁区",
            "label": "长宁区"
          },
          {
            "id": 806,
            "pid": 802,
            "value": "静安区",
            "label": "静安区"
          },
          {
            "id": 807,
            "pid": 802,
            "value": "普陀区",
            "label": "普陀区"
          },
          {
            "id": 808,
            "pid": 802,
            "value": "闸北区",
            "label": "闸北区"
          },
          {
            "id": 809,
            "pid": 802,
            "value": "虹口区",
            "label": "虹口区"
          },
          {
            "id": 810,
            "pid": 802,
            "value": "杨浦区",
            "label": "杨浦区"
          },
          {
            "id": 811,
            "pid": 802,
            "value": "闵行区",
            "label": "闵行区"
          },
          {
            "id": 812,
            "pid": 802,
            "value": "宝山区",
            "label": "宝山区"
          },
          {
            "id": 813,
            "pid": 802,
            "value": "嘉定区",
            "label": "嘉定区"
          },
          {
            "id": 814,
            "pid": 802,
            "value": "浦东新区",
            "label": "浦东新区"
          },
          {
            "id": 815,
            "pid": 802,
            "value": "金山区",
            "label": "金山区"
          },
          {
            "id": 816,
            "pid": 802,
            "value": "松江区",
            "label": "松江区"
          },
          {
            "id": 817,
            "pid": 802,
            "value": "青浦区",
            "label": "青浦区"
          },
          {
            "id": 818,
            "pid": 802,
            "value": "奉贤区",
            "label": "奉贤区"
          },
          {
            "id": 819,
            "pid": 802,
            "value": "崇明县",
            "label": "崇明县"
          }
        ]
      }
    ]
  },
  {
    "id": 820,
    "pid": 0,
    "value": "江苏省",
    "label": "江苏省",
    "children": [
      {
        "id": 821,
        "pid": 820,
        "value": "南京市",
        "label": "南京市",
        "children": [
          {
            "id": 822,
            "pid": 821,
            "value": "玄武区",
            "label": "玄武区"
          },
          {
            "id": 823,
            "pid": 821,
            "value": "秦淮区",
            "label": "秦淮区"
          },
          {
            "id": 824,
            "pid": 821,
            "value": "建邺区",
            "label": "建邺区"
          },
          {
            "id": 825,
            "pid": 821,
            "value": "鼓楼区",
            "label": "鼓楼区"
          },
          {
            "id": 826,
            "pid": 821,
            "value": "浦口区",
            "label": "浦口区"
          },
          {
            "id": 827,
            "pid": 821,
            "value": "栖霞区",
            "label": "栖霞区"
          },
          {
            "id": 828,
            "pid": 821,
            "value": "雨花台区",
            "label": "雨花台区"
          },
          {
            "id": 829,
            "pid": 821,
            "value": "江宁区",
            "label": "江宁区"
          },
          {
            "id": 830,
            "pid": 821,
            "value": "六合区",
            "label": "六合区"
          },
          {
            "id": 831,
            "pid": 821,
            "value": "溧水区",
            "label": "溧水区"
          },
          {
            "id": 832,
            "pid": 821,
            "value": "高淳区",
            "label": "高淳区"
          }
        ]
      },
      {
        "id": 833,
        "pid": 820,
        "value": "无锡市",
        "label": "无锡市",
        "children": [
          {
            "id": 834,
            "pid": 833,
            "value": "崇安区",
            "label": "崇安区"
          },
          {
            "id": 835,
            "pid": 833,
            "value": "南长区",
            "label": "南长区"
          },
          {
            "id": 836,
            "pid": 833,
            "value": "北塘区",
            "label": "北塘区"
          },
          {
            "id": 837,
            "pid": 833,
            "value": "锡山区",
            "label": "锡山区"
          },
          {
            "id": 838,
            "pid": 833,
            "value": "惠山区",
            "label": "惠山区"
          },
          {
            "id": 839,
            "pid": 833,
            "value": "滨湖区",
            "label": "滨湖区"
          },
          {
            "id": 840,
            "pid": 833,
            "value": "江阴市",
            "label": "江阴市"
          },
          {
            "id": 841,
            "pid": 833,
            "value": "宜兴市",
            "label": "宜兴市"
          }
        ]
      },
      {
        "id": 842,
        "pid": 820,
        "value": "徐州市",
        "label": "徐州市",
        "children": [
          {
            "id": 843,
            "pid": 842,
            "value": "鼓楼区",
            "label": "鼓楼区"
          },
          {
            "id": 844,
            "pid": 842,
            "value": "云龙区",
            "label": "云龙区"
          },
          {
            "id": 845,
            "pid": 842,
            "value": "贾汪区",
            "label": "贾汪区"
          },
          {
            "id": 846,
            "pid": 842,
            "value": "泉山区",
            "label": "泉山区"
          },
          {
            "id": 847,
            "pid": 842,
            "value": "铜山区",
            "label": "铜山区"
          },
          {
            "id": 848,
            "pid": 842,
            "value": "丰县",
            "label": "丰县"
          },
          {
            "id": 849,
            "pid": 842,
            "value": "沛县",
            "label": "沛县"
          },
          {
            "id": 850,
            "pid": 842,
            "value": "睢宁县",
            "label": "睢宁县"
          },
          {
            "id": 851,
            "pid": 842,
            "value": "新沂市",
            "label": "新沂市"
          },
          {
            "id": 852,
            "pid": 842,
            "value": "邳州市",
            "label": "邳州市"
          }
        ]
      },
      {
        "id": 853,
        "pid": 820,
        "value": "常州市",
        "label": "常州市",
        "children": [
          {
            "id": 854,
            "pid": 853,
            "value": "天宁区",
            "label": "天宁区"
          },
          {
            "id": 855,
            "pid": 853,
            "value": "钟楼区",
            "label": "钟楼区"
          },
          {
            "id": 856,
            "pid": 853,
            "value": "戚墅堰区",
            "label": "戚墅堰区"
          },
          {
            "id": 857,
            "pid": 853,
            "value": "新北区",
            "label": "新北区"
          },
          {
            "id": 858,
            "pid": 853,
            "value": "武进区",
            "label": "武进区"
          },
          {
            "id": 859,
            "pid": 853,
            "value": "溧阳市",
            "label": "溧阳市"
          },
          {
            "id": 860,
            "pid": 853,
            "value": "金坛市",
            "label": "金坛市"
          }
        ]
      },
      {
        "id": 861,
        "pid": 820,
        "value": "苏州市",
        "label": "苏州市",
        "children": [
          {
            "id": 862,
            "pid": 861,
            "value": "虎丘区",
            "label": "虎丘区"
          },
          {
            "id": 863,
            "pid": 861,
            "value": "吴中区",
            "label": "吴中区"
          },
          {
            "id": 864,
            "pid": 861,
            "value": "相城区",
            "label": "相城区"
          },
          {
            "id": 865,
            "pid": 861,
            "value": "姑苏区",
            "label": "姑苏区"
          },
          {
            "id": 866,
            "pid": 861,
            "value": "吴江区",
            "label": "吴江区"
          },
          {
            "id": 867,
            "pid": 861,
            "value": "常熟市",
            "label": "常熟市"
          },
          {
            "id": 868,
            "pid": 861,
            "value": "张家港市",
            "label": "张家港市"
          },
          {
            "id": 869,
            "pid": 861,
            "value": "昆山市",
            "label": "昆山市"
          },
          {
            "id": 870,
            "pid": 861,
            "value": "太仓市",
            "label": "太仓市"
          }
        ]
      },
      {
        "id": 871,
        "pid": 820,
        "value": "南通市",
        "label": "南通市",
        "children": [
          {
            "id": 872,
            "pid": 871,
            "value": "崇川区",
            "label": "崇川区"
          },
          {
            "id": 873,
            "pid": 871,
            "value": "港闸区",
            "label": "港闸区"
          },
          {
            "id": 874,
            "pid": 871,
            "value": "通州区",
            "label": "通州区"
          },
          {
            "id": 875,
            "pid": 871,
            "value": "海安县",
            "label": "海安县"
          },
          {
            "id": 876,
            "pid": 871,
            "value": "如东县",
            "label": "如东县"
          },
          {
            "id": 877,
            "pid": 871,
            "value": "启东市",
            "label": "启东市"
          },
          {
            "id": 878,
            "pid": 871,
            "value": "如皋市",
            "label": "如皋市"
          },
          {
            "id": 879,
            "pid": 871,
            "value": "海门市",
            "label": "海门市"
          }
        ]
      },
      {
        "id": 880,
        "pid": 820,
        "value": "连云港市",
        "label": "连云港市",
        "children": [
          {
            "id": 881,
            "pid": 880,
            "value": "连云区",
            "label": "连云区"
          },
          {
            "id": 882,
            "pid": 880,
            "value": "海州区",
            "label": "海州区"
          },
          {
            "id": 883,
            "pid": 880,
            "value": "赣榆区",
            "label": "赣榆区"
          },
          {
            "id": 884,
            "pid": 880,
            "value": "东海县",
            "label": "东海县"
          },
          {
            "id": 885,
            "pid": 880,
            "value": "灌云县",
            "label": "灌云县"
          },
          {
            "id": 886,
            "pid": 880,
            "value": "灌南县",
            "label": "灌南县"
          }
        ]
      },
      {
        "id": 887,
        "pid": 820,
        "value": "淮安市",
        "label": "淮安市",
        "children": [
          {
            "id": 888,
            "pid": 887,
            "value": "清河区",
            "label": "清河区"
          },
          {
            "id": 889,
            "pid": 887,
            "value": "淮安区",
            "label": "淮安区"
          },
          {
            "id": 890,
            "pid": 887,
            "value": "淮阴区",
            "label": "淮阴区"
          },
          {
            "id": 891,
            "pid": 887,
            "value": "清浦区",
            "label": "清浦区"
          },
          {
            "id": 892,
            "pid": 887,
            "value": "涟水县",
            "label": "涟水县"
          },
          {
            "id": 893,
            "pid": 887,
            "value": "洪泽县",
            "label": "洪泽县"
          },
          {
            "id": 894,
            "pid": 887,
            "value": "盱眙县",
            "label": "盱眙县"
          },
          {
            "id": 895,
            "pid": 887,
            "value": "金湖县",
            "label": "金湖县"
          }
        ]
      },
      {
        "id": 896,
        "pid": 820,
        "value": "盐城市",
        "label": "盐城市",
        "children": [
          {
            "id": 897,
            "pid": 896,
            "value": "亭湖区",
            "label": "亭湖区"
          },
          {
            "id": 898,
            "pid": 896,
            "value": "盐都区",
            "label": "盐都区"
          },
          {
            "id": 899,
            "pid": 896,
            "value": "响水县",
            "label": "响水县"
          },
          {
            "id": 900,
            "pid": 896,
            "value": "滨海县",
            "label": "滨海县"
          },
          {
            "id": 901,
            "pid": 896,
            "value": "阜宁县",
            "label": "阜宁县"
          },
          {
            "id": 902,
            "pid": 896,
            "value": "射阳县",
            "label": "射阳县"
          },
          {
            "id": 903,
            "pid": 896,
            "value": "建湖县",
            "label": "建湖县"
          },
          {
            "id": 904,
            "pid": 896,
            "value": "东台市",
            "label": "东台市"
          },
          {
            "id": 905,
            "pid": 896,
            "value": "大丰市",
            "label": "大丰市"
          }
        ]
      },
      {
        "id": 906,
        "pid": 820,
        "value": "扬州市",
        "label": "扬州市",
        "children": [
          {
            "id": 907,
            "pid": 906,
            "value": "广陵区",
            "label": "广陵区"
          },
          {
            "id": 908,
            "pid": 906,
            "value": "邗江区",
            "label": "邗江区"
          },
          {
            "id": 909,
            "pid": 906,
            "value": "江都区",
            "label": "江都区"
          },
          {
            "id": 910,
            "pid": 906,
            "value": "宝应县",
            "label": "宝应县"
          },
          {
            "id": 911,
            "pid": 906,
            "value": "仪征市",
            "label": "仪征市"
          },
          {
            "id": 912,
            "pid": 906,
            "value": "高邮市",
            "label": "高邮市"
          }
        ]
      },
      {
        "id": 913,
        "pid": 820,
        "value": "镇江市",
        "label": "镇江市",
        "children": [
          {
            "id": 914,
            "pid": 913,
            "value": "京口区",
            "label": "京口区"
          },
          {
            "id": 915,
            "pid": 913,
            "value": "润州区",
            "label": "润州区"
          },
          {
            "id": 916,
            "pid": 913,
            "value": "丹徒区",
            "label": "丹徒区"
          },
          {
            "id": 917,
            "pid": 913,
            "value": "丹阳市",
            "label": "丹阳市"
          },
          {
            "id": 918,
            "pid": 913,
            "value": "扬中市",
            "label": "扬中市"
          },
          {
            "id": 919,
            "pid": 913,
            "value": "句容市",
            "label": "句容市"
          }
        ]
      },
      {
        "id": 920,
        "pid": 820,
        "value": "泰州市",
        "label": "泰州市",
        "children": [
          {
            "id": 921,
            "pid": 920,
            "value": "海陵区",
            "label": "海陵区"
          },
          {
            "id": 922,
            "pid": 920,
            "value": "高港区",
            "label": "高港区"
          },
          {
            "id": 923,
            "pid": 920,
            "value": "姜堰区",
            "label": "姜堰区"
          },
          {
            "id": 924,
            "pid": 920,
            "value": "兴化市",
            "label": "兴化市"
          },
          {
            "id": 925,
            "pid": 920,
            "value": "靖江市",
            "label": "靖江市"
          },
          {
            "id": 926,
            "pid": 920,
            "value": "泰兴市",
            "label": "泰兴市"
          }
        ]
      },
      {
        "id": 927,
        "pid": 820,
        "value": "宿迁市",
        "label": "宿迁市",
        "children": [
          {
            "id": 928,
            "pid": 927,
            "value": "宿城区",
            "label": "宿城区"
          },
          {
            "id": 929,
            "pid": 927,
            "value": "宿豫区",
            "label": "宿豫区"
          },
          {
            "id": 930,
            "pid": 927,
            "value": "沭阳县",
            "label": "沭阳县"
          },
          {
            "id": 931,
            "pid": 927,
            "value": "泗阳县",
            "label": "泗阳县"
          },
          {
            "id": 932,
            "pid": 927,
            "value": "泗洪县",
            "label": "泗洪县"
          }
        ]
      }
    ]
  },
  {
    "id": 933,
    "pid": 0,
    "value": "浙江省",
    "label": "浙江省",
    "children": [
      {
        "id": 934,
        "pid": 933,
        "value": "杭州市",
        "label": "杭州市",
        "children": [
          {
            "id": 935,
            "pid": 934,
            "value": "上城区",
            "label": "上城区"
          },
          {
            "id": 936,
            "pid": 934,
            "value": "下城区",
            "label": "下城区"
          },
          {
            "id": 937,
            "pid": 934,
            "value": "江干区",
            "label": "江干区"
          },
          {
            "id": 938,
            "pid": 934,
            "value": "拱墅区",
            "label": "拱墅区"
          },
          {
            "id": 939,
            "pid": 934,
            "value": "西湖区",
            "label": "西湖区"
          },
          {
            "id": 940,
            "pid": 934,
            "value": "滨江区",
            "label": "滨江区"
          },
          {
            "id": 941,
            "pid": 934,
            "value": "萧山区",
            "label": "萧山区"
          },
          {
            "id": 942,
            "pid": 934,
            "value": "余杭区",
            "label": "余杭区"
          },
          {
            "id": 943,
            "pid": 934,
            "value": "桐庐县",
            "label": "桐庐县"
          },
          {
            "id": 944,
            "pid": 934,
            "value": "淳安县",
            "label": "淳安县"
          },
          {
            "id": 945,
            "pid": 934,
            "value": "建德市",
            "label": "建德市"
          },
          {
            "id": 946,
            "pid": 934,
            "value": "富阳区",
            "label": "富阳区"
          },
          {
            "id": 947,
            "pid": 934,
            "value": "临安市",
            "label": "临安市"
          }
        ]
      },
      {
        "id": 948,
        "pid": 933,
        "value": "宁波市",
        "label": "宁波市",
        "children": [
          {
            "id": 949,
            "pid": 948,
            "value": "海曙区",
            "label": "海曙区"
          },
          {
            "id": 950,
            "pid": 948,
            "value": "江东区",
            "label": "江东区"
          },
          {
            "id": 951,
            "pid": 948,
            "value": "江北区",
            "label": "江北区"
          },
          {
            "id": 952,
            "pid": 948,
            "value": "北仑区",
            "label": "北仑区"
          },
          {
            "id": 953,
            "pid": 948,
            "value": "镇海区",
            "label": "镇海区"
          },
          {
            "id": 954,
            "pid": 948,
            "value": "鄞州区",
            "label": "鄞州区"
          },
          {
            "id": 955,
            "pid": 948,
            "value": "象山县",
            "label": "象山县"
          },
          {
            "id": 956,
            "pid": 948,
            "value": "宁海县",
            "label": "宁海县"
          },
          {
            "id": 957,
            "pid": 948,
            "value": "余姚市",
            "label": "余姚市"
          },
          {
            "id": 958,
            "pid": 948,
            "value": "慈溪市",
            "label": "慈溪市"
          },
          {
            "id": 959,
            "pid": 948,
            "value": "奉化市",
            "label": "奉化市"
          }
        ]
      },
      {
        "id": 960,
        "pid": 933,
        "value": "温州市",
        "label": "温州市",
        "children": [
          {
            "id": 961,
            "pid": 960,
            "value": "鹿城区",
            "label": "鹿城区"
          },
          {
            "id": 962,
            "pid": 960,
            "value": "龙湾区",
            "label": "龙湾区"
          },
          {
            "id": 963,
            "pid": 960,
            "value": "瓯海区",
            "label": "瓯海区"
          },
          {
            "id": 964,
            "pid": 960,
            "value": "洞头县",
            "label": "洞头县"
          },
          {
            "id": 965,
            "pid": 960,
            "value": "永嘉县",
            "label": "永嘉县"
          },
          {
            "id": 966,
            "pid": 960,
            "value": "平阳县",
            "label": "平阳县"
          },
          {
            "id": 967,
            "pid": 960,
            "value": "苍南县",
            "label": "苍南县"
          },
          {
            "id": 968,
            "pid": 960,
            "value": "文成县",
            "label": "文成县"
          },
          {
            "id": 969,
            "pid": 960,
            "value": "泰顺县",
            "label": "泰顺县"
          },
          {
            "id": 970,
            "pid": 960,
            "value": "瑞安市",
            "label": "瑞安市"
          },
          {
            "id": 971,
            "pid": 960,
            "value": "乐清市",
            "label": "乐清市"
          }
        ]
      },
      {
        "id": 972,
        "pid": 933,
        "value": "嘉兴市",
        "label": "嘉兴市",
        "children": [
          {
            "id": 973,
            "pid": 972,
            "value": "南湖区",
            "label": "南湖区"
          },
          {
            "id": 974,
            "pid": 972,
            "value": "秀洲区",
            "label": "秀洲区"
          },
          {
            "id": 975,
            "pid": 972,
            "value": "嘉善县",
            "label": "嘉善县"
          },
          {
            "id": 976,
            "pid": 972,
            "value": "海盐县",
            "label": "海盐县"
          },
          {
            "id": 977,
            "pid": 972,
            "value": "海宁市",
            "label": "海宁市"
          },
          {
            "id": 978,
            "pid": 972,
            "value": "平湖市",
            "label": "平湖市"
          },
          {
            "id": 979,
            "pid": 972,
            "value": "桐乡市",
            "label": "桐乡市"
          }
        ]
      },
      {
        "id": 980,
        "pid": 933,
        "value": "湖州市",
        "label": "湖州市",
        "children": [
          {
            "id": 981,
            "pid": 980,
            "value": "吴兴区",
            "label": "吴兴区"
          },
          {
            "id": 982,
            "pid": 980,
            "value": "南浔区",
            "label": "南浔区"
          },
          {
            "id": 983,
            "pid": 980,
            "value": "德清县",
            "label": "德清县"
          },
          {
            "id": 984,
            "pid": 980,
            "value": "长兴县",
            "label": "长兴县"
          },
          {
            "id": 985,
            "pid": 980,
            "value": "安吉县",
            "label": "安吉县"
          }
        ]
      },
      {
        "id": 986,
        "pid": 933,
        "value": "绍兴市",
        "label": "绍兴市",
        "children": [
          {
            "id": 987,
            "pid": 986,
            "value": "越城区",
            "label": "越城区"
          },
          {
            "id": 988,
            "pid": 986,
            "value": "柯桥区",
            "label": "柯桥区"
          },
          {
            "id": 989,
            "pid": 986,
            "value": "上虞区",
            "label": "上虞区"
          },
          {
            "id": 990,
            "pid": 986,
            "value": "新昌县",
            "label": "新昌县"
          },
          {
            "id": 991,
            "pid": 986,
            "value": "诸暨市",
            "label": "诸暨市"
          },
          {
            "id": 992,
            "pid": 986,
            "value": "嵊州市",
            "label": "嵊州市"
          }
        ]
      },
      {
        "id": 993,
        "pid": 933,
        "value": "金华市",
        "label": "金华市",
        "children": [
          {
            "id": 994,
            "pid": 993,
            "value": "婺城区",
            "label": "婺城区"
          },
          {
            "id": 995,
            "pid": 993,
            "value": "金东区",
            "label": "金东区"
          },
          {
            "id": 996,
            "pid": 993,
            "value": "武义县",
            "label": "武义县"
          },
          {
            "id": 997,
            "pid": 993,
            "value": "浦江县",
            "label": "浦江县"
          },
          {
            "id": 998,
            "pid": 993,
            "value": "磐安县",
            "label": "磐安县"
          },
          {
            "id": 999,
            "pid": 993,
            "value": "兰溪市",
            "label": "兰溪市"
          },
          {
            "id": 1000,
            "pid": 993,
            "value": "义乌市",
            "label": "义乌市"
          },
          {
            "id": 1001,
            "pid": 993,
            "value": "东阳市",
            "label": "东阳市"
          },
          {
            "id": 1002,
            "pid": 993,
            "value": "永康市",
            "label": "永康市"
          }
        ]
      },
      {
        "id": 1003,
        "pid": 933,
        "value": "衢州市",
        "label": "衢州市",
        "children": [
          {
            "id": 1004,
            "pid": 1003,
            "value": "柯城区",
            "label": "柯城区"
          },
          {
            "id": 1005,
            "pid": 1003,
            "value": "衢江区",
            "label": "衢江区"
          },
          {
            "id": 1006,
            "pid": 1003,
            "value": "常山县",
            "label": "常山县"
          },
          {
            "id": 1007,
            "pid": 1003,
            "value": "开化县",
            "label": "开化县"
          },
          {
            "id": 1008,
            "pid": 1003,
            "value": "龙游县",
            "label": "龙游县"
          },
          {
            "id": 1009,
            "pid": 1003,
            "value": "江山市",
            "label": "江山市"
          }
        ]
      },
      {
        "id": 1010,
        "pid": 933,
        "value": "舟山市",
        "label": "舟山市",
        "children": [
          {
            "id": 1011,
            "pid": 1010,
            "value": "定海区",
            "label": "定海区"
          },
          {
            "id": 1012,
            "pid": 1010,
            "value": "普陀区",
            "label": "普陀区"
          },
          {
            "id": 1013,
            "pid": 1010,
            "value": "岱山县",
            "label": "岱山县"
          },
          {
            "id": 1014,
            "pid": 1010,
            "value": "嵊泗县",
            "label": "嵊泗县"
          }
        ]
      },
      {
        "id": 1015,
        "pid": 933,
        "value": "台州市",
        "label": "台州市",
        "children": [
          {
            "id": 1016,
            "pid": 1015,
            "value": "椒江区",
            "label": "椒江区"
          },
          {
            "id": 1017,
            "pid": 1015,
            "value": "黄岩区",
            "label": "黄岩区"
          },
          {
            "id": 1018,
            "pid": 1015,
            "value": "路桥区",
            "label": "路桥区"
          },
          {
            "id": 1019,
            "pid": 1015,
            "value": "玉环县",
            "label": "玉环县"
          },
          {
            "id": 1020,
            "pid": 1015,
            "value": "三门县",
            "label": "三门县"
          },
          {
            "id": 1021,
            "pid": 1015,
            "value": "天台县",
            "label": "天台县"
          },
          {
            "id": 1022,
            "pid": 1015,
            "value": "仙居县",
            "label": "仙居县"
          },
          {
            "id": 1023,
            "pid": 1015,
            "value": "温岭市",
            "label": "温岭市"
          },
          {
            "id": 1024,
            "pid": 1015,
            "value": "临海市",
            "label": "临海市"
          }
        ]
      },
      {
        "id": 1025,
        "pid": 933,
        "value": "丽水市",
        "label": "丽水市",
        "children": [
          {
            "id": 1026,
            "pid": 1025,
            "value": "莲都区",
            "label": "莲都区"
          },
          {
            "id": 1027,
            "pid": 1025,
            "value": "青田县",
            "label": "青田县"
          },
          {
            "id": 1028,
            "pid": 1025,
            "value": "缙云县",
            "label": "缙云县"
          },
          {
            "id": 1029,
            "pid": 1025,
            "value": "遂昌县",
            "label": "遂昌县"
          },
          {
            "id": 1030,
            "pid": 1025,
            "value": "松阳县",
            "label": "松阳县"
          },
          {
            "id": 1031,
            "pid": 1025,
            "value": "云和县",
            "label": "云和县"
          },
          {
            "id": 1032,
            "pid": 1025,
            "value": "庆元县",
            "label": "庆元县"
          },
          {
            "id": 1033,
            "pid": 1025,
            "value": "景宁畲族自治县",
            "label": "景宁畲族自治县"
          },
          {
            "id": 1034,
            "pid": 1025,
            "value": "龙泉市",
            "label": "龙泉市"
          }
        ]
      },
      {
        "id": 1035,
        "pid": 933,
        "value": "舟山群岛新区",
        "label": "舟山群岛新区",
        "children": [
          {
            "id": 1036,
            "pid": 1035,
            "value": "金塘岛",
            "label": "金塘岛"
          },
          {
            "id": 1037,
            "pid": 1035,
            "value": "六横岛",
            "label": "六横岛"
          },
          {
            "id": 1038,
            "pid": 1035,
            "value": "衢山岛",
            "label": "衢山岛"
          },
          {
            "id": 1039,
            "pid": 1035,
            "value": "舟山本岛西北部",
            "label": "舟山本岛西北部"
          },
          {
            "id": 1040,
            "pid": 1035,
            "value": "岱山岛西南部",
            "label": "岱山岛西南部"
          },
          {
            "id": 1041,
            "pid": 1035,
            "value": "泗礁岛",
            "label": "泗礁岛"
          },
          {
            "id": 1042,
            "pid": 1035,
            "value": "朱家尖岛",
            "label": "朱家尖岛"
          },
          {
            "id": 1043,
            "pid": 1035,
            "value": "洋山岛",
            "label": "洋山岛"
          },
          {
            "id": 1044,
            "pid": 1035,
            "value": "长涂岛",
            "label": "长涂岛"
          },
          {
            "id": 1045,
            "pid": 1035,
            "value": "虾峙岛",
            "label": "虾峙岛"
          }
        ]
      }
    ]
  },
  {
    "id": 1046,
    "pid": 0,
    "value": "安徽省",
    "label": "安徽省",
    "children": [
      {
        "id": 1047,
        "pid": 1046,
        "value": "合肥市",
        "label": "合肥市",
        "children": [
          {
            "id": 1048,
            "pid": 1047,
            "value": "瑶海区",
            "label": "瑶海区"
          },
          {
            "id": 1049,
            "pid": 1047,
            "value": "庐阳区",
            "label": "庐阳区"
          },
          {
            "id": 1050,
            "pid": 1047,
            "value": "蜀山区",
            "label": "蜀山区"
          },
          {
            "id": 1051,
            "pid": 1047,
            "value": "包河区",
            "label": "包河区"
          },
          {
            "id": 1052,
            "pid": 1047,
            "value": "长丰县",
            "label": "长丰县"
          },
          {
            "id": 1053,
            "pid": 1047,
            "value": "肥东县",
            "label": "肥东县"
          },
          {
            "id": 1054,
            "pid": 1047,
            "value": "肥西县",
            "label": "肥西县"
          },
          {
            "id": 1055,
            "pid": 1047,
            "value": "庐江县",
            "label": "庐江县"
          },
          {
            "id": 1056,
            "pid": 1047,
            "value": "巢湖市",
            "label": "巢湖市"
          }
        ]
      },
      {
        "id": 1057,
        "pid": 1046,
        "value": "芜湖市",
        "label": "芜湖市",
        "children": [
          {
            "id": 1058,
            "pid": 1057,
            "value": "镜湖区",
            "label": "镜湖区"
          },
          {
            "id": 1059,
            "pid": 1057,
            "value": "弋江区",
            "label": "弋江区"
          },
          {
            "id": 1060,
            "pid": 1057,
            "value": "鸠江区",
            "label": "鸠江区"
          },
          {
            "id": 1061,
            "pid": 1057,
            "value": "三山区",
            "label": "三山区"
          },
          {
            "id": 1062,
            "pid": 1057,
            "value": "芜湖县",
            "label": "芜湖县"
          },
          {
            "id": 1063,
            "pid": 1057,
            "value": "繁昌县",
            "label": "繁昌县"
          },
          {
            "id": 1064,
            "pid": 1057,
            "value": "南陵县",
            "label": "南陵县"
          },
          {
            "id": 1065,
            "pid": 1057,
            "value": "无为县",
            "label": "无为县"
          }
        ]
      },
      {
        "id": 1066,
        "pid": 1046,
        "value": "蚌埠市",
        "label": "蚌埠市",
        "children": [
          {
            "id": 1067,
            "pid": 1066,
            "value": "龙子湖区",
            "label": "龙子湖区"
          },
          {
            "id": 1068,
            "pid": 1066,
            "value": "蚌山区",
            "label": "蚌山区"
          },
          {
            "id": 1069,
            "pid": 1066,
            "value": "禹会区",
            "label": "禹会区"
          },
          {
            "id": 1070,
            "pid": 1066,
            "value": "淮上区",
            "label": "淮上区"
          },
          {
            "id": 1071,
            "pid": 1066,
            "value": "怀远县",
            "label": "怀远县"
          },
          {
            "id": 1072,
            "pid": 1066,
            "value": "五河县",
            "label": "五河县"
          },
          {
            "id": 1073,
            "pid": 1066,
            "value": "固镇县",
            "label": "固镇县"
          }
        ]
      },
      {
        "id": 1074,
        "pid": 1046,
        "value": "淮南市",
        "label": "淮南市",
        "children": [
          {
            "id": 1075,
            "pid": 1074,
            "value": "大通区",
            "label": "大通区"
          },
          {
            "id": 1076,
            "pid": 1074,
            "value": "田家庵区",
            "label": "田家庵区"
          },
          {
            "id": 1077,
            "pid": 1074,
            "value": "谢家集区",
            "label": "谢家集区"
          },
          {
            "id": 1078,
            "pid": 1074,
            "value": "八公山区",
            "label": "八公山区"
          },
          {
            "id": 1079,
            "pid": 1074,
            "value": "潘集区",
            "label": "潘集区"
          },
          {
            "id": 1080,
            "pid": 1074,
            "value": "凤台县",
            "label": "凤台县"
          }
        ]
      },
      {
        "id": 1081,
        "pid": 1046,
        "value": "马鞍山市",
        "label": "马鞍山市",
        "children": [
          {
            "id": 1082,
            "pid": 1081,
            "value": "花山区",
            "label": "花山区"
          },
          {
            "id": 1083,
            "pid": 1081,
            "value": "雨山区",
            "label": "雨山区"
          },
          {
            "id": 1084,
            "pid": 1081,
            "value": "博望区",
            "label": "博望区"
          },
          {
            "id": 1085,
            "pid": 1081,
            "value": "当涂县",
            "label": "当涂县"
          },
          {
            "id": 1086,
            "pid": 1081,
            "value": "含山县",
            "label": "含山县"
          },
          {
            "id": 1087,
            "pid": 1081,
            "value": "和县",
            "label": "和县"
          }
        ]
      },
      {
        "id": 1088,
        "pid": 1046,
        "value": "淮北市",
        "label": "淮北市",
        "children": [
          {
            "id": 1089,
            "pid": 1088,
            "value": "杜集区",
            "label": "杜集区"
          },
          {
            "id": 1090,
            "pid": 1088,
            "value": "相山区",
            "label": "相山区"
          },
          {
            "id": 1091,
            "pid": 1088,
            "value": "烈山区",
            "label": "烈山区"
          },
          {
            "id": 1092,
            "pid": 1088,
            "value": "濉溪县",
            "label": "濉溪县"
          }
        ]
      },
      {
        "id": 1093,
        "pid": 1046,
        "value": "铜陵市",
        "label": "铜陵市",
        "children": [
          {
            "id": 1094,
            "pid": 1093,
            "value": "铜官山区",
            "label": "铜官山区"
          },
          {
            "id": 1095,
            "pid": 1093,
            "value": "狮子山区",
            "label": "狮子山区"
          },
          {
            "id": 1096,
            "pid": 1093,
            "value": "郊区",
            "label": "郊区"
          },
          {
            "id": 1097,
            "pid": 1093,
            "value": "铜陵县",
            "label": "铜陵县"
          }
        ]
      },
      {
        "id": 1098,
        "pid": 1046,
        "value": "安庆市",
        "label": "安庆市",
        "children": [
          {
            "id": 1099,
            "pid": 1098,
            "value": "迎江区",
            "label": "迎江区"
          },
          {
            "id": 1100,
            "pid": 1098,
            "value": "大观区",
            "label": "大观区"
          },
          {
            "id": 1101,
            "pid": 1098,
            "value": "宜秀区",
            "label": "宜秀区"
          },
          {
            "id": 1102,
            "pid": 1098,
            "value": "怀宁县",
            "label": "怀宁县"
          },
          {
            "id": 1103,
            "pid": 1098,
            "value": "枞阳县",
            "label": "枞阳县"
          },
          {
            "id": 1104,
            "pid": 1098,
            "value": "潜山县",
            "label": "潜山县"
          },
          {
            "id": 1105,
            "pid": 1098,
            "value": "太湖县",
            "label": "太湖县"
          },
          {
            "id": 1106,
            "pid": 1098,
            "value": "宿松县",
            "label": "宿松县"
          },
          {
            "id": 1107,
            "pid": 1098,
            "value": "望江县",
            "label": "望江县"
          },
          {
            "id": 1108,
            "pid": 1098,
            "value": "岳西县",
            "label": "岳西县"
          },
          {
            "id": 1109,
            "pid": 1098,
            "value": "桐城市",
            "label": "桐城市"
          }
        ]
      },
      {
        "id": 1110,
        "pid": 1046,
        "value": "黄山市",
        "label": "黄山市",
        "children": [
          {
            "id": 1111,
            "pid": 1110,
            "value": "屯溪区",
            "label": "屯溪区"
          },
          {
            "id": 1112,
            "pid": 1110,
            "value": "黄山区",
            "label": "黄山区"
          },
          {
            "id": 1113,
            "pid": 1110,
            "value": "徽州区",
            "label": "徽州区"
          },
          {
            "id": 1114,
            "pid": 1110,
            "value": "歙县",
            "label": "歙县"
          },
          {
            "id": 1115,
            "pid": 1110,
            "value": "休宁县",
            "label": "休宁县"
          },
          {
            "id": 1116,
            "pid": 1110,
            "value": "黟县",
            "label": "黟县"
          },
          {
            "id": 1117,
            "pid": 1110,
            "value": "祁门县",
            "label": "祁门县"
          }
        ]
      },
      {
        "id": 1118,
        "pid": 1046,
        "value": "滁州市",
        "label": "滁州市",
        "children": [
          {
            "id": 1119,
            "pid": 1118,
            "value": "琅琊区",
            "label": "琅琊区"
          },
          {
            "id": 1120,
            "pid": 1118,
            "value": "南谯区",
            "label": "南谯区"
          },
          {
            "id": 1121,
            "pid": 1118,
            "value": "来安县",
            "label": "来安县"
          },
          {
            "id": 1122,
            "pid": 1118,
            "value": "全椒县",
            "label": "全椒县"
          },
          {
            "id": 1123,
            "pid": 1118,
            "value": "定远县",
            "label": "定远县"
          },
          {
            "id": 1124,
            "pid": 1118,
            "value": "凤阳县",
            "label": "凤阳县"
          },
          {
            "id": 1125,
            "pid": 1118,
            "value": "天长市",
            "label": "天长市"
          },
          {
            "id": 1126,
            "pid": 1118,
            "value": "明光市",
            "label": "明光市"
          }
        ]
      },
      {
        "id": 1127,
        "pid": 1046,
        "value": "阜阳市",
        "label": "阜阳市",
        "children": [
          {
            "id": 1128,
            "pid": 1127,
            "value": "颍州区",
            "label": "颍州区"
          },
          {
            "id": 1129,
            "pid": 1127,
            "value": "颍东区",
            "label": "颍东区"
          },
          {
            "id": 1130,
            "pid": 1127,
            "value": "颍泉区",
            "label": "颍泉区"
          },
          {
            "id": 1131,
            "pid": 1127,
            "value": "临泉县",
            "label": "临泉县"
          },
          {
            "id": 1132,
            "pid": 1127,
            "value": "太和县",
            "label": "太和县"
          },
          {
            "id": 1133,
            "pid": 1127,
            "value": "阜南县",
            "label": "阜南县"
          },
          {
            "id": 1134,
            "pid": 1127,
            "value": "颍上县",
            "label": "颍上县"
          },
          {
            "id": 1135,
            "pid": 1127,
            "value": "界首市",
            "label": "界首市"
          }
        ]
      },
      {
        "id": 1136,
        "pid": 1046,
        "value": "宿州市",
        "label": "宿州市",
        "children": [
          {
            "id": 1137,
            "pid": 1136,
            "value": "埇桥区",
            "label": "埇桥区"
          },
          {
            "id": 1138,
            "pid": 1136,
            "value": "砀山县",
            "label": "砀山县"
          },
          {
            "id": 1139,
            "pid": 1136,
            "value": "萧县",
            "label": "萧县"
          },
          {
            "id": 1140,
            "pid": 1136,
            "value": "灵璧县",
            "label": "灵璧县"
          },
          {
            "id": 1141,
            "pid": 1136,
            "value": "泗县",
            "label": "泗县"
          }
        ]
      },
      {
        "id": 1142,
        "pid": 1046,
        "value": "六安市",
        "label": "六安市",
        "children": [
          {
            "id": 1143,
            "pid": 1142,
            "value": "金安区",
            "label": "金安区"
          },
          {
            "id": 1144,
            "pid": 1142,
            "value": "裕安区",
            "label": "裕安区"
          },
          {
            "id": 1145,
            "pid": 1142,
            "value": "寿县",
            "label": "寿县"
          },
          {
            "id": 1146,
            "pid": 1142,
            "value": "霍邱县",
            "label": "霍邱县"
          },
          {
            "id": 1147,
            "pid": 1142,
            "value": "舒城县",
            "label": "舒城县"
          },
          {
            "id": 1148,
            "pid": 1142,
            "value": "金寨县",
            "label": "金寨县"
          },
          {
            "id": 1149,
            "pid": 1142,
            "value": "霍山县",
            "label": "霍山县"
          }
        ]
      },
      {
        "id": 1150,
        "pid": 1046,
        "value": "亳州市",
        "label": "亳州市",
        "children": [
          {
            "id": 1151,
            "pid": 1150,
            "value": "谯城区",
            "label": "谯城区"
          },
          {
            "id": 1152,
            "pid": 1150,
            "value": "涡阳县",
            "label": "涡阳县"
          },
          {
            "id": 1153,
            "pid": 1150,
            "value": "蒙城县",
            "label": "蒙城县"
          },
          {
            "id": 1154,
            "pid": 1150,
            "value": "利辛县",
            "label": "利辛县"
          }
        ]
      },
      {
        "id": 1155,
        "pid": 1046,
        "value": "池州市",
        "label": "池州市",
        "children": [
          {
            "id": 1156,
            "pid": 1155,
            "value": "贵池区",
            "label": "贵池区"
          },
          {
            "id": 1157,
            "pid": 1155,
            "value": "东至县",
            "label": "东至县"
          },
          {
            "id": 1158,
            "pid": 1155,
            "value": "石台县",
            "label": "石台县"
          },
          {
            "id": 1159,
            "pid": 1155,
            "value": "青阳县",
            "label": "青阳县"
          }
        ]
      },
      {
        "id": 1160,
        "pid": 1046,
        "value": "宣城市",
        "label": "宣城市",
        "children": [
          {
            "id": 1161,
            "pid": 1160,
            "value": "宣州区",
            "label": "宣州区"
          },
          {
            "id": 1162,
            "pid": 1160,
            "value": "郎溪县",
            "label": "郎溪县"
          },
          {
            "id": 1163,
            "pid": 1160,
            "value": "广德县",
            "label": "广德县"
          },
          {
            "id": 1164,
            "pid": 1160,
            "value": "泾县",
            "label": "泾县"
          },
          {
            "id": 1165,
            "pid": 1160,
            "value": "绩溪县",
            "label": "绩溪县"
          },
          {
            "id": 1166,
            "pid": 1160,
            "value": "旌德县",
            "label": "旌德县"
          },
          {
            "id": 1167,
            "pid": 1160,
            "value": "宁国市",
            "label": "宁国市"
          }
        ]
      }
    ]
  },
  {
    "id": 1168,
    "pid": 0,
    "value": "福建省",
    "label": "福建省",
    "children": [
      {
        "id": 1169,
        "pid": 1168,
        "value": "福州市",
        "label": "福州市",
        "children": [
          {
            "id": 1170,
            "pid": 1169,
            "value": "鼓楼区",
            "label": "鼓楼区"
          },
          {
            "id": 1171,
            "pid": 1169,
            "value": "台江区",
            "label": "台江区"
          },
          {
            "id": 1172,
            "pid": 1169,
            "value": "仓山区",
            "label": "仓山区"
          },
          {
            "id": 1173,
            "pid": 1169,
            "value": "马尾区",
            "label": "马尾区"
          },
          {
            "id": 1174,
            "pid": 1169,
            "value": "晋安区",
            "label": "晋安区"
          },
          {
            "id": 1175,
            "pid": 1169,
            "value": "闽侯县",
            "label": "闽侯县"
          },
          {
            "id": 1176,
            "pid": 1169,
            "value": "连江县",
            "label": "连江县"
          },
          {
            "id": 1177,
            "pid": 1169,
            "value": "罗源县",
            "label": "罗源县"
          },
          {
            "id": 1178,
            "pid": 1169,
            "value": "闽清县",
            "label": "闽清县"
          },
          {
            "id": 1179,
            "pid": 1169,
            "value": "永泰县",
            "label": "永泰县"
          },
          {
            "id": 1180,
            "pid": 1169,
            "value": "平潭县",
            "label": "平潭县"
          },
          {
            "id": 1181,
            "pid": 1169,
            "value": "福清市",
            "label": "福清市"
          },
          {
            "id": 1182,
            "pid": 1169,
            "value": "长乐市",
            "label": "长乐市"
          }
        ]
      },
      {
        "id": 1183,
        "pid": 1168,
        "value": "厦门市",
        "label": "厦门市",
        "children": [
          {
            "id": 1184,
            "pid": 1183,
            "value": "思明区",
            "label": "思明区"
          },
          {
            "id": 1185,
            "pid": 1183,
            "value": "海沧区",
            "label": "海沧区"
          },
          {
            "id": 1186,
            "pid": 1183,
            "value": "湖里区",
            "label": "湖里区"
          },
          {
            "id": 1187,
            "pid": 1183,
            "value": "集美区",
            "label": "集美区"
          },
          {
            "id": 1188,
            "pid": 1183,
            "value": "同安区",
            "label": "同安区"
          },
          {
            "id": 1189,
            "pid": 1183,
            "value": "翔安区",
            "label": "翔安区"
          }
        ]
      },
      {
        "id": 1190,
        "pid": 1168,
        "value": "莆田市",
        "label": "莆田市",
        "children": [
          {
            "id": 1191,
            "pid": 1190,
            "value": "城厢区",
            "label": "城厢区"
          },
          {
            "id": 1192,
            "pid": 1190,
            "value": "涵江区",
            "label": "涵江区"
          },
          {
            "id": 1193,
            "pid": 1190,
            "value": "荔城区",
            "label": "荔城区"
          },
          {
            "id": 1194,
            "pid": 1190,
            "value": "秀屿区",
            "label": "秀屿区"
          },
          {
            "id": 1195,
            "pid": 1190,
            "value": "仙游县",
            "label": "仙游县"
          }
        ]
      },
      {
        "id": 1196,
        "pid": 1168,
        "value": "三明市",
        "label": "三明市",
        "children": [
          {
            "id": 1197,
            "pid": 1196,
            "value": "梅列区",
            "label": "梅列区"
          },
          {
            "id": 1198,
            "pid": 1196,
            "value": "三元区",
            "label": "三元区"
          },
          {
            "id": 1199,
            "pid": 1196,
            "value": "明溪县",
            "label": "明溪县"
          },
          {
            "id": 1200,
            "pid": 1196,
            "value": "清流县",
            "label": "清流县"
          },
          {
            "id": 1201,
            "pid": 1196,
            "value": "宁化县",
            "label": "宁化县"
          },
          {
            "id": 1202,
            "pid": 1196,
            "value": "大田县",
            "label": "大田县"
          },
          {
            "id": 1203,
            "pid": 1196,
            "value": "尤溪县",
            "label": "尤溪县"
          },
          {
            "id": 1204,
            "pid": 1196,
            "value": "沙县",
            "label": "沙县"
          },
          {
            "id": 1205,
            "pid": 1196,
            "value": "将乐县",
            "label": "将乐县"
          },
          {
            "id": 1206,
            "pid": 1196,
            "value": "泰宁县",
            "label": "泰宁县"
          },
          {
            "id": 1207,
            "pid": 1196,
            "value": "建宁县",
            "label": "建宁县"
          },
          {
            "id": 1208,
            "pid": 1196,
            "value": "永安市",
            "label": "永安市"
          }
        ]
      },
      {
        "id": 1209,
        "pid": 1168,
        "value": "泉州市",
        "label": "泉州市",
        "children": [
          {
            "id": 1210,
            "pid": 1209,
            "value": "鲤城区",
            "label": "鲤城区"
          },
          {
            "id": 1211,
            "pid": 1209,
            "value": "丰泽区",
            "label": "丰泽区"
          },
          {
            "id": 1212,
            "pid": 1209,
            "value": "洛江区",
            "label": "洛江区"
          },
          {
            "id": 1213,
            "pid": 1209,
            "value": "泉港区",
            "label": "泉港区"
          },
          {
            "id": 1214,
            "pid": 1209,
            "value": "惠安县",
            "label": "惠安县"
          },
          {
            "id": 1215,
            "pid": 1209,
            "value": "安溪县",
            "label": "安溪县"
          },
          {
            "id": 1216,
            "pid": 1209,
            "value": "永春县",
            "label": "永春县"
          },
          {
            "id": 1217,
            "pid": 1209,
            "value": "德化县",
            "label": "德化县"
          },
          {
            "id": 1218,
            "pid": 1209,
            "value": "金门县",
            "label": "金门县"
          },
          {
            "id": 1219,
            "pid": 1209,
            "value": "石狮市",
            "label": "石狮市"
          },
          {
            "id": 1220,
            "pid": 1209,
            "value": "晋江市",
            "label": "晋江市"
          },
          {
            "id": 1221,
            "pid": 1209,
            "value": "南安市",
            "label": "南安市"
          }
        ]
      },
      {
        "id": 1222,
        "pid": 1168,
        "value": "漳州市",
        "label": "漳州市",
        "children": [
          {
            "id": 1223,
            "pid": 1222,
            "value": "芗城区",
            "label": "芗城区"
          },
          {
            "id": 1224,
            "pid": 1222,
            "value": "龙文区",
            "label": "龙文区"
          },
          {
            "id": 1225,
            "pid": 1222,
            "value": "云霄县",
            "label": "云霄县"
          },
          {
            "id": 1226,
            "pid": 1222,
            "value": "漳浦县",
            "label": "漳浦县"
          },
          {
            "id": 1227,
            "pid": 1222,
            "value": "诏安县",
            "label": "诏安县"
          },
          {
            "id": 1228,
            "pid": 1222,
            "value": "长泰县",
            "label": "长泰县"
          },
          {
            "id": 1229,
            "pid": 1222,
            "value": "东山县",
            "label": "东山县"
          },
          {
            "id": 1230,
            "pid": 1222,
            "value": "南靖县",
            "label": "南靖县"
          },
          {
            "id": 1231,
            "pid": 1222,
            "value": "平和县",
            "label": "平和县"
          },
          {
            "id": 1232,
            "pid": 1222,
            "value": "华安县",
            "label": "华安县"
          },
          {
            "id": 1233,
            "pid": 1222,
            "value": "龙海市",
            "label": "龙海市"
          }
        ]
      },
      {
        "id": 1234,
        "pid": 1168,
        "value": "南平市",
        "label": "南平市",
        "children": [
          {
            "id": 1235,
            "pid": 1234,
            "value": "延平区",
            "label": "延平区"
          },
          {
            "id": 1236,
            "pid": 1234,
            "value": "建阳区",
            "label": "建阳区"
          },
          {
            "id": 1237,
            "pid": 1234,
            "value": "顺昌县",
            "label": "顺昌县"
          },
          {
            "id": 1238,
            "pid": 1234,
            "value": "浦城县",
            "label": "浦城县"
          },
          {
            "id": 1239,
            "pid": 1234,
            "value": "光泽县",
            "label": "光泽县"
          },
          {
            "id": 1240,
            "pid": 1234,
            "value": "松溪县",
            "label": "松溪县"
          },
          {
            "id": 1241,
            "pid": 1234,
            "value": "政和县",
            "label": "政和县"
          },
          {
            "id": 1242,
            "pid": 1234,
            "value": "邵武市",
            "label": "邵武市"
          },
          {
            "id": 1243,
            "pid": 1234,
            "value": "武夷山市",
            "label": "武夷山市"
          },
          {
            "id": 1244,
            "pid": 1234,
            "value": "建瓯市",
            "label": "建瓯市"
          }
        ]
      },
      {
        "id": 1245,
        "pid": 1168,
        "value": "龙岩市",
        "label": "龙岩市",
        "children": [
          {
            "id": 1246,
            "pid": 1245,
            "value": "新罗区",
            "label": "新罗区"
          },
          {
            "id": 1247,
            "pid": 1245,
            "value": "长汀县",
            "label": "长汀县"
          },
          {
            "id": 1248,
            "pid": 1245,
            "value": "永定区",
            "label": "永定区"
          },
          {
            "id": 1249,
            "pid": 1245,
            "value": "上杭县",
            "label": "上杭县"
          },
          {
            "id": 1250,
            "pid": 1245,
            "value": "武平县",
            "label": "武平县"
          },
          {
            "id": 1251,
            "pid": 1245,
            "value": "连城县",
            "label": "连城县"
          },
          {
            "id": 1252,
            "pid": 1245,
            "value": "漳平市",
            "label": "漳平市"
          }
        ]
      },
      {
        "id": 1253,
        "pid": 1168,
        "value": "宁德市",
        "label": "宁德市",
        "children": [
          {
            "id": 1254,
            "pid": 1253,
            "value": "蕉城区",
            "label": "蕉城区"
          },
          {
            "id": 1255,
            "pid": 1253,
            "value": "霞浦县",
            "label": "霞浦县"
          },
          {
            "id": 1256,
            "pid": 1253,
            "value": "古田县",
            "label": "古田县"
          },
          {
            "id": 1257,
            "pid": 1253,
            "value": "屏南县",
            "label": "屏南县"
          },
          {
            "id": 1258,
            "pid": 1253,
            "value": "寿宁县",
            "label": "寿宁县"
          },
          {
            "id": 1259,
            "pid": 1253,
            "value": "周宁县",
            "label": "周宁县"
          },
          {
            "id": 1260,
            "pid": 1253,
            "value": "柘荣县",
            "label": "柘荣县"
          },
          {
            "id": 1261,
            "pid": 1253,
            "value": "福安市",
            "label": "福安市"
          },
          {
            "id": 1262,
            "pid": 1253,
            "value": "福鼎市",
            "label": "福鼎市"
          }
        ]
      }
    ]
  },
  {
    "id": 1263,
    "pid": 0,
    "value": "江西省",
    "label": "江西省",
    "children": [
      {
        "id": 1264,
        "pid": 1263,
        "value": "南昌市",
        "label": "南昌市",
        "children": [
          {
            "id": 1265,
            "pid": 1264,
            "value": "东湖区",
            "label": "东湖区"
          },
          {
            "id": 1266,
            "pid": 1264,
            "value": "西湖区",
            "label": "西湖区"
          },
          {
            "id": 1267,
            "pid": 1264,
            "value": "青云谱区",
            "label": "青云谱区"
          },
          {
            "id": 1268,
            "pid": 1264,
            "value": "湾里区",
            "label": "湾里区"
          },
          {
            "id": 1269,
            "pid": 1264,
            "value": "青山湖区",
            "label": "青山湖区"
          },
          {
            "id": 1270,
            "pid": 1264,
            "value": "南昌县",
            "label": "南昌县"
          },
          {
            "id": 1271,
            "pid": 1264,
            "value": "新建县",
            "label": "新建县"
          },
          {
            "id": 1272,
            "pid": 1264,
            "value": "安义县",
            "label": "安义县"
          },
          {
            "id": 1273,
            "pid": 1264,
            "value": "进贤县",
            "label": "进贤县"
          }
        ]
      },
      {
        "id": 1274,
        "pid": 1263,
        "value": "景德镇市",
        "label": "景德镇市",
        "children": [
          {
            "id": 1275,
            "pid": 1274,
            "value": "昌江区",
            "label": "昌江区"
          },
          {
            "id": 1276,
            "pid": 1274,
            "value": "珠山区",
            "label": "珠山区"
          },
          {
            "id": 1277,
            "pid": 1274,
            "value": "浮梁县",
            "label": "浮梁县"
          },
          {
            "id": 1278,
            "pid": 1274,
            "value": "乐平市",
            "label": "乐平市"
          }
        ]
      },
      {
        "id": 1279,
        "pid": 1263,
        "value": "萍乡市",
        "label": "萍乡市",
        "children": [
          {
            "id": 1280,
            "pid": 1279,
            "value": "安源区",
            "label": "安源区"
          },
          {
            "id": 1281,
            "pid": 1279,
            "value": "湘东区",
            "label": "湘东区"
          },
          {
            "id": 1282,
            "pid": 1279,
            "value": "莲花县",
            "label": "莲花县"
          },
          {
            "id": 1283,
            "pid": 1279,
            "value": "上栗县",
            "label": "上栗县"
          },
          {
            "id": 1284,
            "pid": 1279,
            "value": "芦溪县",
            "label": "芦溪县"
          }
        ]
      },
      {
        "id": 1285,
        "pid": 1263,
        "value": "九江市",
        "label": "九江市",
        "children": [
          {
            "id": 1286,
            "pid": 1285,
            "value": "庐山区",
            "label": "庐山区"
          },
          {
            "id": 1287,
            "pid": 1285,
            "value": "浔阳区",
            "label": "浔阳区"
          },
          {
            "id": 1288,
            "pid": 1285,
            "value": "九江县",
            "label": "九江县"
          },
          {
            "id": 1289,
            "pid": 1285,
            "value": "武宁县",
            "label": "武宁县"
          },
          {
            "id": 1290,
            "pid": 1285,
            "value": "修水县",
            "label": "修水县"
          },
          {
            "id": 1291,
            "pid": 1285,
            "value": "永修县",
            "label": "永修县"
          },
          {
            "id": 1292,
            "pid": 1285,
            "value": "德安县",
            "label": "德安县"
          },
          {
            "id": 1293,
            "pid": 1285,
            "value": "星子县",
            "label": "星子县"
          },
          {
            "id": 1294,
            "pid": 1285,
            "value": "都昌县",
            "label": "都昌县"
          },
          {
            "id": 1295,
            "pid": 1285,
            "value": "湖口县",
            "label": "湖口县"
          },
          {
            "id": 1296,
            "pid": 1285,
            "value": "彭泽县",
            "label": "彭泽县"
          },
          {
            "id": 1297,
            "pid": 1285,
            "value": "瑞昌市",
            "label": "瑞昌市"
          },
          {
            "id": 1298,
            "pid": 1285,
            "value": "共青城市",
            "label": "共青城市"
          }
        ]
      },
      {
        "id": 1299,
        "pid": 1263,
        "value": "新余市",
        "label": "新余市",
        "children": [
          {
            "id": 1300,
            "pid": 1299,
            "value": "渝水区",
            "label": "渝水区"
          },
          {
            "id": 1301,
            "pid": 1299,
            "value": "分宜县",
            "label": "分宜县"
          }
        ]
      },
      {
        "id": 1302,
        "pid": 1263,
        "value": "鹰潭市",
        "label": "鹰潭市",
        "children": [
          {
            "id": 1303,
            "pid": 1302,
            "value": "月湖区",
            "label": "月湖区"
          },
          {
            "id": 1304,
            "pid": 1302,
            "value": "余江县",
            "label": "余江县"
          },
          {
            "id": 1305,
            "pid": 1302,
            "value": "贵溪市",
            "label": "贵溪市"
          }
        ]
      },
      {
        "id": 1306,
        "pid": 1263,
        "value": "赣州市",
        "label": "赣州市",
        "children": [
          {
            "id": 1307,
            "pid": 1306,
            "value": "章贡区",
            "label": "章贡区"
          },
          {
            "id": 1308,
            "pid": 1306,
            "value": "南康区",
            "label": "南康区"
          },
          {
            "id": 1309,
            "pid": 1306,
            "value": "赣县",
            "label": "赣县"
          },
          {
            "id": 1310,
            "pid": 1306,
            "value": "信丰县",
            "label": "信丰县"
          },
          {
            "id": 1311,
            "pid": 1306,
            "value": "大余县",
            "label": "大余县"
          },
          {
            "id": 1312,
            "pid": 1306,
            "value": "上犹县",
            "label": "上犹县"
          },
          {
            "id": 1313,
            "pid": 1306,
            "value": "崇义县",
            "label": "崇义县"
          },
          {
            "id": 1314,
            "pid": 1306,
            "value": "安远县",
            "label": "安远县"
          },
          {
            "id": 1315,
            "pid": 1306,
            "value": "龙南县",
            "label": "龙南县"
          },
          {
            "id": 1316,
            "pid": 1306,
            "value": "定南县",
            "label": "定南县"
          },
          {
            "id": 1317,
            "pid": 1306,
            "value": "全南县",
            "label": "全南县"
          },
          {
            "id": 1318,
            "pid": 1306,
            "value": "宁都县",
            "label": "宁都县"
          },
          {
            "id": 1319,
            "pid": 1306,
            "value": "于都县",
            "label": "于都县"
          },
          {
            "id": 1320,
            "pid": 1306,
            "value": "兴国县",
            "label": "兴国县"
          },
          {
            "id": 1321,
            "pid": 1306,
            "value": "会昌县",
            "label": "会昌县"
          },
          {
            "id": 1322,
            "pid": 1306,
            "value": "寻乌县",
            "label": "寻乌县"
          },
          {
            "id": 1323,
            "pid": 1306,
            "value": "石城县",
            "label": "石城县"
          },
          {
            "id": 1324,
            "pid": 1306,
            "value": "瑞金市",
            "label": "瑞金市"
          }
        ]
      },
      {
        "id": 1325,
        "pid": 1263,
        "value": "吉安市",
        "label": "吉安市",
        "children": [
          {
            "id": 1326,
            "pid": 1325,
            "value": "吉州区",
            "label": "吉州区"
          },
          {
            "id": 1327,
            "pid": 1325,
            "value": "青原区",
            "label": "青原区"
          },
          {
            "id": 1328,
            "pid": 1325,
            "value": "吉安县",
            "label": "吉安县"
          },
          {
            "id": 1329,
            "pid": 1325,
            "value": "吉水县",
            "label": "吉水县"
          },
          {
            "id": 1330,
            "pid": 1325,
            "value": "峡江县",
            "label": "峡江县"
          },
          {
            "id": 1331,
            "pid": 1325,
            "value": "新干县",
            "label": "新干县"
          },
          {
            "id": 1332,
            "pid": 1325,
            "value": "永丰县",
            "label": "永丰县"
          },
          {
            "id": 1333,
            "pid": 1325,
            "value": "泰和县",
            "label": "泰和县"
          },
          {
            "id": 1334,
            "pid": 1325,
            "value": "遂川县",
            "label": "遂川县"
          },
          {
            "id": 1335,
            "pid": 1325,
            "value": "万安县",
            "label": "万安县"
          },
          {
            "id": 1336,
            "pid": 1325,
            "value": "安福县",
            "label": "安福县"
          },
          {
            "id": 1337,
            "pid": 1325,
            "value": "永新县",
            "label": "永新县"
          },
          {
            "id": 1338,
            "pid": 1325,
            "value": "井冈山市",
            "label": "井冈山市"
          }
        ]
      },
      {
        "id": 1339,
        "pid": 1263,
        "value": "宜春市",
        "label": "宜春市",
        "children": [
          {
            "id": 1340,
            "pid": 1339,
            "value": "袁州区",
            "label": "袁州区"
          },
          {
            "id": 1341,
            "pid": 1339,
            "value": "奉新县",
            "label": "奉新县"
          },
          {
            "id": 1342,
            "pid": 1339,
            "value": "万载县",
            "label": "万载县"
          },
          {
            "id": 1343,
            "pid": 1339,
            "value": "上高县",
            "label": "上高县"
          },
          {
            "id": 1344,
            "pid": 1339,
            "value": "宜丰县",
            "label": "宜丰县"
          },
          {
            "id": 1345,
            "pid": 1339,
            "value": "靖安县",
            "label": "靖安县"
          },
          {
            "id": 1346,
            "pid": 1339,
            "value": "铜鼓县",
            "label": "铜鼓县"
          },
          {
            "id": 1347,
            "pid": 1339,
            "value": "丰城市",
            "label": "丰城市"
          },
          {
            "id": 1348,
            "pid": 1339,
            "value": "樟树市",
            "label": "樟树市"
          },
          {
            "id": 1349,
            "pid": 1339,
            "value": "高安市",
            "label": "高安市"
          }
        ]
      },
      {
        "id": 1350,
        "pid": 1263,
        "value": "抚州市",
        "label": "抚州市",
        "children": [
          {
            "id": 1351,
            "pid": 1350,
            "value": "临川区",
            "label": "临川区"
          },
          {
            "id": 1352,
            "pid": 1350,
            "value": "南城县",
            "label": "南城县"
          },
          {
            "id": 1353,
            "pid": 1350,
            "value": "黎川县",
            "label": "黎川县"
          },
          {
            "id": 1354,
            "pid": 1350,
            "value": "南丰县",
            "label": "南丰县"
          },
          {
            "id": 1355,
            "pid": 1350,
            "value": "崇仁县",
            "label": "崇仁县"
          },
          {
            "id": 1356,
            "pid": 1350,
            "value": "乐安县",
            "label": "乐安县"
          },
          {
            "id": 1357,
            "pid": 1350,
            "value": "宜黄县",
            "label": "宜黄县"
          },
          {
            "id": 1358,
            "pid": 1350,
            "value": "金溪县",
            "label": "金溪县"
          },
          {
            "id": 1359,
            "pid": 1350,
            "value": "资溪县",
            "label": "资溪县"
          },
          {
            "id": 1360,
            "pid": 1350,
            "value": "东乡县",
            "label": "东乡县"
          },
          {
            "id": 1361,
            "pid": 1350,
            "value": "广昌县",
            "label": "广昌县"
          }
        ]
      },
      {
        "id": 1362,
        "pid": 1263,
        "value": "上饶市",
        "label": "上饶市",
        "children": [
          {
            "id": 1363,
            "pid": 1362,
            "value": "信州区",
            "label": "信州区"
          },
          {
            "id": 1364,
            "pid": 1362,
            "value": "上饶县",
            "label": "上饶县"
          },
          {
            "id": 1365,
            "pid": 1362,
            "value": "广丰县",
            "label": "广丰县"
          },
          {
            "id": 1366,
            "pid": 1362,
            "value": "玉山县",
            "label": "玉山县"
          },
          {
            "id": 1367,
            "pid": 1362,
            "value": "铅山县",
            "label": "铅山县"
          },
          {
            "id": 1368,
            "pid": 1362,
            "value": "横峰县",
            "label": "横峰县"
          },
          {
            "id": 1369,
            "pid": 1362,
            "value": "弋阳县",
            "label": "弋阳县"
          },
          {
            "id": 1370,
            "pid": 1362,
            "value": "余干县",
            "label": "余干县"
          },
          {
            "id": 1371,
            "pid": 1362,
            "value": "鄱阳县",
            "label": "鄱阳县"
          },
          {
            "id": 1372,
            "pid": 1362,
            "value": "万年县",
            "label": "万年县"
          },
          {
            "id": 1373,
            "pid": 1362,
            "value": "婺源县",
            "label": "婺源县"
          },
          {
            "id": 1374,
            "pid": 1362,
            "value": "德兴市",
            "label": "德兴市"
          }
        ]
      }
    ]
  },
  {
    "id": 1375,
    "pid": 0,
    "value": "山东省",
    "label": "山东省",
    "children": [
      {
        "id": 1376,
        "pid": 1375,
        "value": "济南市",
        "label": "济南市",
        "children": [
          {
            "id": 1377,
            "pid": 1376,
            "value": "历下区",
            "label": "历下区"
          },
          {
            "id": 1378,
            "pid": 1376,
            "value": "市中区",
            "label": "市中区"
          },
          {
            "id": 1379,
            "pid": 1376,
            "value": "槐荫区",
            "label": "槐荫区"
          },
          {
            "id": 1380,
            "pid": 1376,
            "value": "天桥区",
            "label": "天桥区"
          },
          {
            "id": 1381,
            "pid": 1376,
            "value": "历城区",
            "label": "历城区"
          },
          {
            "id": 1382,
            "pid": 1376,
            "value": "长清区",
            "label": "长清区"
          },
          {
            "id": 1383,
            "pid": 1376,
            "value": "平阴县",
            "label": "平阴县"
          },
          {
            "id": 1384,
            "pid": 1376,
            "value": "济阳县",
            "label": "济阳县"
          },
          {
            "id": 1385,
            "pid": 1376,
            "value": "商河县",
            "label": "商河县"
          },
          {
            "id": 1386,
            "pid": 1376,
            "value": "章丘市",
            "label": "章丘市"
          }
        ]
      },
      {
        "id": 1387,
        "pid": 1375,
        "value": "青岛市",
        "label": "青岛市",
        "children": [
          {
            "id": 1388,
            "pid": 1387,
            "value": "市南区",
            "label": "市南区"
          },
          {
            "id": 1389,
            "pid": 1387,
            "value": "市北区",
            "label": "市北区"
          },
          {
            "id": 1390,
            "pid": 1387,
            "value": "黄岛区",
            "label": "黄岛区"
          },
          {
            "id": 1391,
            "pid": 1387,
            "value": "崂山区",
            "label": "崂山区"
          },
          {
            "id": 1392,
            "pid": 1387,
            "value": "李沧区",
            "label": "李沧区"
          },
          {
            "id": 1393,
            "pid": 1387,
            "value": "城阳区",
            "label": "城阳区"
          },
          {
            "id": 1394,
            "pid": 1387,
            "value": "胶州市",
            "label": "胶州市"
          },
          {
            "id": 1395,
            "pid": 1387,
            "value": "即墨市",
            "label": "即墨市"
          },
          {
            "id": 1396,
            "pid": 1387,
            "value": "平度市",
            "label": "平度市"
          },
          {
            "id": 1397,
            "pid": 1387,
            "value": "莱西市",
            "label": "莱西市"
          },
          {
            "id": 1398,
            "pid": 1387,
            "value": "西海岸新区",
            "label": "西海岸新区"
          }
        ]
      },
      {
        "id": 1399,
        "pid": 1375,
        "value": "淄博市",
        "label": "淄博市",
        "children": [
          {
            "id": 1400,
            "pid": 1399,
            "value": "淄川区",
            "label": "淄川区"
          },
          {
            "id": 1401,
            "pid": 1399,
            "value": "张店区",
            "label": "张店区"
          },
          {
            "id": 1402,
            "pid": 1399,
            "value": "博山区",
            "label": "博山区"
          },
          {
            "id": 1403,
            "pid": 1399,
            "value": "临淄区",
            "label": "临淄区"
          },
          {
            "id": 1404,
            "pid": 1399,
            "value": "周村区",
            "label": "周村区"
          },
          {
            "id": 1405,
            "pid": 1399,
            "value": "桓台县",
            "label": "桓台县"
          },
          {
            "id": 1406,
            "pid": 1399,
            "value": "高青县",
            "label": "高青县"
          },
          {
            "id": 1407,
            "pid": 1399,
            "value": "沂源县",
            "label": "沂源县"
          }
        ]
      },
      {
        "id": 1408,
        "pid": 1375,
        "value": "枣庄市",
        "label": "枣庄市",
        "children": [
          {
            "id": 1409,
            "pid": 1408,
            "value": "市中区",
            "label": "市中区"
          },
          {
            "id": 1410,
            "pid": 1408,
            "value": "薛城区",
            "label": "薛城区"
          },
          {
            "id": 1411,
            "pid": 1408,
            "value": "峄城区",
            "label": "峄城区"
          },
          {
            "id": 1412,
            "pid": 1408,
            "value": "台儿庄区",
            "label": "台儿庄区"
          },
          {
            "id": 1413,
            "pid": 1408,
            "value": "山亭区",
            "label": "山亭区"
          },
          {
            "id": 1414,
            "pid": 1408,
            "value": "滕州市",
            "label": "滕州市"
          }
        ]
      },
      {
        "id": 1415,
        "pid": 1375,
        "value": "东营市",
        "label": "东营市",
        "children": [
          {
            "id": 1416,
            "pid": 1415,
            "value": "东营区",
            "label": "东营区"
          },
          {
            "id": 1417,
            "pid": 1415,
            "value": "河口区",
            "label": "河口区"
          },
          {
            "id": 1418,
            "pid": 1415,
            "value": "垦利县",
            "label": "垦利县"
          },
          {
            "id": 1419,
            "pid": 1415,
            "value": "利津县",
            "label": "利津县"
          },
          {
            "id": 1420,
            "pid": 1415,
            "value": "广饶县",
            "label": "广饶县"
          }
        ]
      },
      {
        "id": 1421,
        "pid": 1375,
        "value": "烟台市",
        "label": "烟台市",
        "children": [
          {
            "id": 1422,
            "pid": 1421,
            "value": "芝罘区",
            "label": "芝罘区"
          },
          {
            "id": 1423,
            "pid": 1421,
            "value": "福山区",
            "label": "福山区"
          },
          {
            "id": 1424,
            "pid": 1421,
            "value": "牟平区",
            "label": "牟平区"
          },
          {
            "id": 1425,
            "pid": 1421,
            "value": "莱山区",
            "label": "莱山区"
          },
          {
            "id": 1426,
            "pid": 1421,
            "value": "长岛县",
            "label": "长岛县"
          },
          {
            "id": 1427,
            "pid": 1421,
            "value": "龙口市",
            "label": "龙口市"
          },
          {
            "id": 1428,
            "pid": 1421,
            "value": "莱阳市",
            "label": "莱阳市"
          },
          {
            "id": 1429,
            "pid": 1421,
            "value": "莱州市",
            "label": "莱州市"
          },
          {
            "id": 1430,
            "pid": 1421,
            "value": "蓬莱市",
            "label": "蓬莱市"
          },
          {
            "id": 1431,
            "pid": 1421,
            "value": "招远市",
            "label": "招远市"
          },
          {
            "id": 1432,
            "pid": 1421,
            "value": "栖霞市",
            "label": "栖霞市"
          },
          {
            "id": 1433,
            "pid": 1421,
            "value": "海阳市",
            "label": "海阳市"
          }
        ]
      },
      {
        "id": 1434,
        "pid": 1375,
        "value": "潍坊市",
        "label": "潍坊市",
        "children": [
          {
            "id": 1435,
            "pid": 1434,
            "value": "潍城区",
            "label": "潍城区"
          },
          {
            "id": 1436,
            "pid": 1434,
            "value": "寒亭区",
            "label": "寒亭区"
          },
          {
            "id": 1437,
            "pid": 1434,
            "value": "坊子区",
            "label": "坊子区"
          },
          {
            "id": 1438,
            "pid": 1434,
            "value": "奎文区",
            "label": "奎文区"
          },
          {
            "id": 1439,
            "pid": 1434,
            "value": "临朐县",
            "label": "临朐县"
          },
          {
            "id": 1440,
            "pid": 1434,
            "value": "昌乐县",
            "label": "昌乐县"
          },
          {
            "id": 1441,
            "pid": 1434,
            "value": "青州市",
            "label": "青州市"
          },
          {
            "id": 1442,
            "pid": 1434,
            "value": "诸城市",
            "label": "诸城市"
          },
          {
            "id": 1443,
            "pid": 1434,
            "value": "寿光市",
            "label": "寿光市"
          },
          {
            "id": 1444,
            "pid": 1434,
            "value": "安丘市",
            "label": "安丘市"
          },
          {
            "id": 1445,
            "pid": 1434,
            "value": "高密市",
            "label": "高密市"
          },
          {
            "id": 1446,
            "pid": 1434,
            "value": "昌邑市",
            "label": "昌邑市"
          }
        ]
      },
      {
        "id": 1447,
        "pid": 1375,
        "value": "济宁市",
        "label": "济宁市",
        "children": [
          {
            "id": 1448,
            "pid": 1447,
            "value": "任城区",
            "label": "任城区"
          },
          {
            "id": 1449,
            "pid": 1447,
            "value": "兖州区",
            "label": "兖州区"
          },
          {
            "id": 1450,
            "pid": 1447,
            "value": "微山县",
            "label": "微山县"
          },
          {
            "id": 1451,
            "pid": 1447,
            "value": "鱼台县",
            "label": "鱼台县"
          },
          {
            "id": 1452,
            "pid": 1447,
            "value": "金乡县",
            "label": "金乡县"
          },
          {
            "id": 1453,
            "pid": 1447,
            "value": "嘉祥县",
            "label": "嘉祥县"
          },
          {
            "id": 1454,
            "pid": 1447,
            "value": "汶上县",
            "label": "汶上县"
          },
          {
            "id": 1455,
            "pid": 1447,
            "value": "泗水县",
            "label": "泗水县"
          },
          {
            "id": 1456,
            "pid": 1447,
            "value": "梁山县",
            "label": "梁山县"
          },
          {
            "id": 1457,
            "pid": 1447,
            "value": "曲阜市",
            "label": "曲阜市"
          },
          {
            "id": 1458,
            "pid": 1447,
            "value": "邹城市",
            "label": "邹城市"
          }
        ]
      },
      {
        "id": 1459,
        "pid": 1375,
        "value": "泰安市",
        "label": "泰安市",
        "children": [
          {
            "id": 1460,
            "pid": 1459,
            "value": "泰山区",
            "label": "泰山区"
          },
          {
            "id": 1461,
            "pid": 1459,
            "value": "岱岳区",
            "label": "岱岳区"
          },
          {
            "id": 1462,
            "pid": 1459,
            "value": "宁阳县",
            "label": "宁阳县"
          },
          {
            "id": 1463,
            "pid": 1459,
            "value": "东平县",
            "label": "东平县"
          },
          {
            "id": 1464,
            "pid": 1459,
            "value": "新泰市",
            "label": "新泰市"
          },
          {
            "id": 1465,
            "pid": 1459,
            "value": "肥城市",
            "label": "肥城市"
          }
        ]
      },
      {
        "id": 1466,
        "pid": 1375,
        "value": "威海市",
        "label": "威海市",
        "children": [
          {
            "id": 1467,
            "pid": 1466,
            "value": "环翠区",
            "label": "环翠区"
          },
          {
            "id": 1468,
            "pid": 1466,
            "value": "文登区",
            "label": "文登区"
          },
          {
            "id": 1469,
            "pid": 1466,
            "value": "荣成市",
            "label": "荣成市"
          },
          {
            "id": 1470,
            "pid": 1466,
            "value": "乳山市",
            "label": "乳山市"
          }
        ]
      },
      {
        "id": 1471,
        "pid": 1375,
        "value": "日照市",
        "label": "日照市",
        "children": [
          {
            "id": 1472,
            "pid": 1471,
            "value": "东港区",
            "label": "东港区"
          },
          {
            "id": 1473,
            "pid": 1471,
            "value": "岚山区",
            "label": "岚山区"
          },
          {
            "id": 1474,
            "pid": 1471,
            "value": "五莲县",
            "label": "五莲县"
          },
          {
            "id": 1475,
            "pid": 1471,
            "value": "莒县",
            "label": "莒县"
          }
        ]
      },
      {
        "id": 1476,
        "pid": 1375,
        "value": "莱芜市",
        "label": "莱芜市",
        "children": [
          {
            "id": 1477,
            "pid": 1476,
            "value": "莱城区",
            "label": "莱城区"
          },
          {
            "id": 1478,
            "pid": 1476,
            "value": "钢城区",
            "label": "钢城区"
          }
        ]
      },
      {
        "id": 1479,
        "pid": 1375,
        "value": "临沂市",
        "label": "临沂市",
        "children": [
          {
            "id": 1480,
            "pid": 1479,
            "value": "兰山区",
            "label": "兰山区"
          },
          {
            "id": 1481,
            "pid": 1479,
            "value": "罗庄区",
            "label": "罗庄区"
          },
          {
            "id": 1482,
            "pid": 1479,
            "value": "河东区",
            "label": "河东区"
          },
          {
            "id": 1483,
            "pid": 1479,
            "value": "沂南县",
            "label": "沂南县"
          },
          {
            "id": 1484,
            "pid": 1479,
            "value": "郯城县",
            "label": "郯城县"
          },
          {
            "id": 1485,
            "pid": 1479,
            "value": "沂水县",
            "label": "沂水县"
          },
          {
            "id": 1486,
            "pid": 1479,
            "value": "兰陵县",
            "label": "兰陵县"
          },
          {
            "id": 1487,
            "pid": 1479,
            "value": "费县",
            "label": "费县"
          },
          {
            "id": 1488,
            "pid": 1479,
            "value": "平邑县",
            "label": "平邑县"
          },
          {
            "id": 1489,
            "pid": 1479,
            "value": "莒南县",
            "label": "莒南县"
          },
          {
            "id": 1490,
            "pid": 1479,
            "value": "蒙阴县",
            "label": "蒙阴县"
          },
          {
            "id": 1491,
            "pid": 1479,
            "value": "临沭县",
            "label": "临沭县"
          }
        ]
      },
      {
        "id": 1492,
        "pid": 1375,
        "value": "德州市",
        "label": "德州市",
        "children": [
          {
            "id": 1493,
            "pid": 1492,
            "value": "德城区",
            "label": "德城区"
          },
          {
            "id": 1494,
            "pid": 1492,
            "value": "陵城区",
            "label": "陵城区"
          },
          {
            "id": 1495,
            "pid": 1492,
            "value": "宁津县",
            "label": "宁津县"
          },
          {
            "id": 1496,
            "pid": 1492,
            "value": "庆云县",
            "label": "庆云县"
          },
          {
            "id": 1497,
            "pid": 1492,
            "value": "临邑县",
            "label": "临邑县"
          },
          {
            "id": 1498,
            "pid": 1492,
            "value": "齐河县",
            "label": "齐河县"
          },
          {
            "id": 1499,
            "pid": 1492,
            "value": "平原县",
            "label": "平原县"
          },
          {
            "id": 1500,
            "pid": 1492,
            "value": "夏津县",
            "label": "夏津县"
          },
          {
            "id": 1501,
            "pid": 1492,
            "value": "武城县",
            "label": "武城县"
          },
          {
            "id": 1502,
            "pid": 1492,
            "value": "乐陵市",
            "label": "乐陵市"
          },
          {
            "id": 1503,
            "pid": 1492,
            "value": "禹城市",
            "label": "禹城市"
          }
        ]
      },
      {
        "id": 1504,
        "pid": 1375,
        "value": "聊城市",
        "label": "聊城市",
        "children": [
          {
            "id": 1505,
            "pid": 1504,
            "value": "东昌府区",
            "label": "东昌府区"
          },
          {
            "id": 1506,
            "pid": 1504,
            "value": "阳谷县",
            "label": "阳谷县"
          },
          {
            "id": 1507,
            "pid": 1504,
            "value": "莘县",
            "label": "莘县"
          },
          {
            "id": 1508,
            "pid": 1504,
            "value": "茌平县",
            "label": "茌平县"
          },
          {
            "id": 1509,
            "pid": 1504,
            "value": "东阿县",
            "label": "东阿县"
          },
          {
            "id": 1510,
            "pid": 1504,
            "value": "冠县",
            "label": "冠县"
          },
          {
            "id": 1511,
            "pid": 1504,
            "value": "高唐县",
            "label": "高唐县"
          },
          {
            "id": 1512,
            "pid": 1504,
            "value": "临清市",
            "label": "临清市"
          }
        ]
      },
      {
        "id": 1513,
        "pid": 1375,
        "value": "滨州市",
        "label": "滨州市",
        "children": [
          {
            "id": 1514,
            "pid": 1513,
            "value": "滨城区",
            "label": "滨城区"
          },
          {
            "id": 1515,
            "pid": 1513,
            "value": "沾化区",
            "label": "沾化区"
          },
          {
            "id": 1516,
            "pid": 1513,
            "value": "惠民县",
            "label": "惠民县"
          },
          {
            "id": 1517,
            "pid": 1513,
            "value": "阳信县",
            "label": "阳信县"
          },
          {
            "id": 1518,
            "pid": 1513,
            "value": "无棣县",
            "label": "无棣县"
          },
          {
            "id": 1519,
            "pid": 1513,
            "value": "博兴县",
            "label": "博兴县"
          },
          {
            "id": 1520,
            "pid": 1513,
            "value": "邹平县",
            "label": "邹平县"
          },
          {
            "id": 1521,
            "pid": 1513,
            "value": "北海新区",
            "label": "北海新区"
          }
        ]
      },
      {
        "id": 1522,
        "pid": 1375,
        "value": "菏泽市",
        "label": "菏泽市",
        "children": [
          {
            "id": 1523,
            "pid": 1522,
            "value": "牡丹区",
            "label": "牡丹区"
          },
          {
            "id": 1524,
            "pid": 1522,
            "value": "曹县",
            "label": "曹县"
          },
          {
            "id": 1525,
            "pid": 1522,
            "value": "单县",
            "label": "单县"
          },
          {
            "id": 1526,
            "pid": 1522,
            "value": "成武县",
            "label": "成武县"
          },
          {
            "id": 1527,
            "pid": 1522,
            "value": "巨野县",
            "label": "巨野县"
          },
          {
            "id": 1528,
            "pid": 1522,
            "value": "郓城县",
            "label": "郓城县"
          },
          {
            "id": 1529,
            "pid": 1522,
            "value": "鄄城县",
            "label": "鄄城县"
          },
          {
            "id": 1530,
            "pid": 1522,
            "value": "定陶县",
            "label": "定陶县"
          },
          {
            "id": 1531,
            "pid": 1522,
            "value": "东明县",
            "label": "东明县"
          }
        ]
      }
    ]
  },
  {
    "id": 1532,
    "pid": 0,
    "value": "河南省",
    "label": "河南省",
    "children": [
      {
        "id": 1533,
        "pid": 1532,
        "value": "郑州市",
        "label": "郑州市",
        "children": [
          {
            "id": 1534,
            "pid": 1533,
            "value": "中原区",
            "label": "中原区"
          },
          {
            "id": 1535,
            "pid": 1533,
            "value": "二七区",
            "label": "二七区"
          },
          {
            "id": 1536,
            "pid": 1533,
            "value": "管城回族区",
            "label": "管城回族区"
          },
          {
            "id": 1537,
            "pid": 1533,
            "value": "金水区",
            "label": "金水区"
          },
          {
            "id": 1538,
            "pid": 1533,
            "value": "上街区",
            "label": "上街区"
          },
          {
            "id": 1539,
            "pid": 1533,
            "value": "惠济区",
            "label": "惠济区"
          },
          {
            "id": 1540,
            "pid": 1533,
            "value": "中牟县",
            "label": "中牟县"
          },
          {
            "id": 1541,
            "pid": 1533,
            "value": "巩义市",
            "label": "巩义市"
          },
          {
            "id": 1542,
            "pid": 1533,
            "value": "荥阳市",
            "label": "荥阳市"
          },
          {
            "id": 1543,
            "pid": 1533,
            "value": "新密市",
            "label": "新密市"
          },
          {
            "id": 1544,
            "pid": 1533,
            "value": "新郑市",
            "label": "新郑市"
          },
          {
            "id": 1545,
            "pid": 1533,
            "value": "登封市",
            "label": "登封市"
          }
        ]
      },
      {
        "id": 1546,
        "pid": 1532,
        "value": "开封市",
        "label": "开封市",
        "children": [
          {
            "id": 1547,
            "pid": 1546,
            "value": "龙亭区",
            "label": "龙亭区"
          },
          {
            "id": 1548,
            "pid": 1546,
            "value": "顺河回族区",
            "label": "顺河回族区"
          },
          {
            "id": 1549,
            "pid": 1546,
            "value": "鼓楼区",
            "label": "鼓楼区"
          },
          {
            "id": 1550,
            "pid": 1546,
            "value": "禹王台区",
            "label": "禹王台区"
          },
          {
            "id": 1551,
            "pid": 1546,
            "value": "祥符区",
            "label": "祥符区"
          },
          {
            "id": 1552,
            "pid": 1546,
            "value": "杞县",
            "label": "杞县"
          },
          {
            "id": 1553,
            "pid": 1546,
            "value": "通许县",
            "label": "通许县"
          },
          {
            "id": 1554,
            "pid": 1546,
            "value": "尉氏县",
            "label": "尉氏县"
          },
          {
            "id": 1555,
            "pid": 1546,
            "value": "兰考县",
            "label": "兰考县"
          }
        ]
      },
      {
        "id": 1556,
        "pid": 1532,
        "value": "洛阳市",
        "label": "洛阳市",
        "children": [
          {
            "id": 1557,
            "pid": 1556,
            "value": "老城区",
            "label": "老城区"
          },
          {
            "id": 1558,
            "pid": 1556,
            "value": "西工区",
            "label": "西工区"
          },
          {
            "id": 1559,
            "pid": 1556,
            "value": "瀍河回族区",
            "label": "瀍河回族区"
          },
          {
            "id": 1560,
            "pid": 1556,
            "value": "涧西区",
            "label": "涧西区"
          },
          {
            "id": 1561,
            "pid": 1556,
            "value": "吉利区",
            "label": "吉利区"
          },
          {
            "id": 1562,
            "pid": 1556,
            "value": "洛龙区",
            "label": "洛龙区"
          },
          {
            "id": 1563,
            "pid": 1556,
            "value": "孟津县",
            "label": "孟津县"
          },
          {
            "id": 1564,
            "pid": 1556,
            "value": "新安县",
            "label": "新安县"
          },
          {
            "id": 1565,
            "pid": 1556,
            "value": "栾川县",
            "label": "栾川县"
          },
          {
            "id": 1566,
            "pid": 1556,
            "value": "嵩县",
            "label": "嵩县"
          },
          {
            "id": 1567,
            "pid": 1556,
            "value": "汝阳县",
            "label": "汝阳县"
          },
          {
            "id": 1568,
            "pid": 1556,
            "value": "宜阳县",
            "label": "宜阳县"
          },
          {
            "id": 1569,
            "pid": 1556,
            "value": "洛宁县",
            "label": "洛宁县"
          },
          {
            "id": 1570,
            "pid": 1556,
            "value": "伊川县",
            "label": "伊川县"
          },
          {
            "id": 1571,
            "pid": 1556,
            "value": "偃师市",
            "label": "偃师市"
          }
        ]
      },
      {
        "id": 1572,
        "pid": 1532,
        "value": "平顶山市",
        "label": "平顶山市",
        "children": [
          {
            "id": 1573,
            "pid": 1572,
            "value": "新华区",
            "label": "新华区"
          },
          {
            "id": 1574,
            "pid": 1572,
            "value": "卫东区",
            "label": "卫东区"
          },
          {
            "id": 1575,
            "pid": 1572,
            "value": "石龙区",
            "label": "石龙区"
          },
          {
            "id": 1576,
            "pid": 1572,
            "value": "湛河区",
            "label": "湛河区"
          },
          {
            "id": 1577,
            "pid": 1572,
            "value": "宝丰县",
            "label": "宝丰县"
          },
          {
            "id": 1578,
            "pid": 1572,
            "value": "叶县",
            "label": "叶县"
          },
          {
            "id": 1579,
            "pid": 1572,
            "value": "鲁山县",
            "label": "鲁山县"
          },
          {
            "id": 1580,
            "pid": 1572,
            "value": "郏县",
            "label": "郏县"
          },
          {
            "id": 1581,
            "pid": 1572,
            "value": "舞钢市",
            "label": "舞钢市"
          },
          {
            "id": 1582,
            "pid": 1572,
            "value": "汝州市",
            "label": "汝州市"
          }
        ]
      },
      {
        "id": 1583,
        "pid": 1532,
        "value": "安阳市",
        "label": "安阳市",
        "children": [
          {
            "id": 1584,
            "pid": 1583,
            "value": "文峰区",
            "label": "文峰区"
          },
          {
            "id": 1585,
            "pid": 1583,
            "value": "北关区",
            "label": "北关区"
          },
          {
            "id": 1586,
            "pid": 1583,
            "value": "殷都区",
            "label": "殷都区"
          },
          {
            "id": 1587,
            "pid": 1583,
            "value": "龙安区",
            "label": "龙安区"
          },
          {
            "id": 1588,
            "pid": 1583,
            "value": "安阳县",
            "label": "安阳县"
          },
          {
            "id": 1589,
            "pid": 1583,
            "value": "汤阴县",
            "label": "汤阴县"
          },
          {
            "id": 1590,
            "pid": 1583,
            "value": "滑县",
            "label": "滑县"
          },
          {
            "id": 1591,
            "pid": 1583,
            "value": "内黄县",
            "label": "内黄县"
          },
          {
            "id": 1592,
            "pid": 1583,
            "value": "林州市",
            "label": "林州市"
          }
        ]
      },
      {
        "id": 1593,
        "pid": 1532,
        "value": "鹤壁市",
        "label": "鹤壁市",
        "children": [
          {
            "id": 1594,
            "pid": 1593,
            "value": "鹤山区",
            "label": "鹤山区"
          },
          {
            "id": 1595,
            "pid": 1593,
            "value": "山城区",
            "label": "山城区"
          },
          {
            "id": 1596,
            "pid": 1593,
            "value": "淇滨区",
            "label": "淇滨区"
          },
          {
            "id": 1597,
            "pid": 1593,
            "value": "浚县",
            "label": "浚县"
          },
          {
            "id": 1598,
            "pid": 1593,
            "value": "淇县",
            "label": "淇县"
          }
        ]
      },
      {
        "id": 1599,
        "pid": 1532,
        "value": "新乡市",
        "label": "新乡市",
        "children": [
          {
            "id": 1600,
            "pid": 1599,
            "value": "红旗区",
            "label": "红旗区"
          },
          {
            "id": 1601,
            "pid": 1599,
            "value": "卫滨区",
            "label": "卫滨区"
          },
          {
            "id": 1602,
            "pid": 1599,
            "value": "凤泉区",
            "label": "凤泉区"
          },
          {
            "id": 1603,
            "pid": 1599,
            "value": "牧野区",
            "label": "牧野区"
          },
          {
            "id": 1604,
            "pid": 1599,
            "value": "新乡县",
            "label": "新乡县"
          },
          {
            "id": 1605,
            "pid": 1599,
            "value": "获嘉县",
            "label": "获嘉县"
          },
          {
            "id": 1606,
            "pid": 1599,
            "value": "原阳县",
            "label": "原阳县"
          },
          {
            "id": 1607,
            "pid": 1599,
            "value": "延津县",
            "label": "延津县"
          },
          {
            "id": 1608,
            "pid": 1599,
            "value": "封丘县",
            "label": "封丘县"
          },
          {
            "id": 1609,
            "pid": 1599,
            "value": "长垣县",
            "label": "长垣县"
          },
          {
            "id": 1610,
            "pid": 1599,
            "value": "卫辉市",
            "label": "卫辉市"
          },
          {
            "id": 1611,
            "pid": 1599,
            "value": "辉县市",
            "label": "辉县市"
          }
        ]
      },
      {
        "id": 1612,
        "pid": 1532,
        "value": "焦作市",
        "label": "焦作市",
        "children": [
          {
            "id": 1613,
            "pid": 1612,
            "value": "解放区",
            "label": "解放区"
          },
          {
            "id": 1614,
            "pid": 1612,
            "value": "中站区",
            "label": "中站区"
          },
          {
            "id": 1615,
            "pid": 1612,
            "value": "马村区",
            "label": "马村区"
          },
          {
            "id": 1616,
            "pid": 1612,
            "value": "山阳区",
            "label": "山阳区"
          },
          {
            "id": 1617,
            "pid": 1612,
            "value": "修武县",
            "label": "修武县"
          },
          {
            "id": 1618,
            "pid": 1612,
            "value": "博爱县",
            "label": "博爱县"
          },
          {
            "id": 1619,
            "pid": 1612,
            "value": "武陟县",
            "label": "武陟县"
          },
          {
            "id": 1620,
            "pid": 1612,
            "value": "温县",
            "label": "温县"
          },
          {
            "id": 1621,
            "pid": 1612,
            "value": "沁阳市",
            "label": "沁阳市"
          },
          {
            "id": 1622,
            "pid": 1612,
            "value": "孟州市",
            "label": "孟州市"
          }
        ]
      },
      {
        "id": 1623,
        "pid": 1532,
        "value": "濮阳市",
        "label": "濮阳市",
        "children": [
          {
            "id": 1624,
            "pid": 1623,
            "value": "华龙区",
            "label": "华龙区"
          },
          {
            "id": 1625,
            "pid": 1623,
            "value": "清丰县",
            "label": "清丰县"
          },
          {
            "id": 1626,
            "pid": 1623,
            "value": "南乐县",
            "label": "南乐县"
          },
          {
            "id": 1627,
            "pid": 1623,
            "value": "范县",
            "label": "范县"
          },
          {
            "id": 1628,
            "pid": 1623,
            "value": "台前县",
            "label": "台前县"
          },
          {
            "id": 1629,
            "pid": 1623,
            "value": "濮阳县",
            "label": "濮阳县"
          }
        ]
      },
      {
        "id": 1630,
        "pid": 1532,
        "value": "许昌市",
        "label": "许昌市",
        "children": [
          {
            "id": 1631,
            "pid": 1630,
            "value": "魏都区",
            "label": "魏都区"
          },
          {
            "id": 1632,
            "pid": 1630,
            "value": "许昌县",
            "label": "许昌县"
          },
          {
            "id": 1633,
            "pid": 1630,
            "value": "鄢陵县",
            "label": "鄢陵县"
          },
          {
            "id": 1634,
            "pid": 1630,
            "value": "襄城县",
            "label": "襄城县"
          },
          {
            "id": 1635,
            "pid": 1630,
            "value": "禹州市",
            "label": "禹州市"
          },
          {
            "id": 1636,
            "pid": 1630,
            "value": "长葛市",
            "label": "长葛市"
          }
        ]
      },
      {
        "id": 1637,
        "pid": 1532,
        "value": "漯河市",
        "label": "漯河市",
        "children": [
          {
            "id": 1638,
            "pid": 1637,
            "value": "源汇区",
            "label": "源汇区"
          },
          {
            "id": 1639,
            "pid": 1637,
            "value": "郾城区",
            "label": "郾城区"
          },
          {
            "id": 1640,
            "pid": 1637,
            "value": "召陵区",
            "label": "召陵区"
          },
          {
            "id": 1641,
            "pid": 1637,
            "value": "舞阳县",
            "label": "舞阳县"
          },
          {
            "id": 1642,
            "pid": 1637,
            "value": "临颍县",
            "label": "临颍县"
          }
        ]
      },
      {
        "id": 1643,
        "pid": 1532,
        "value": "三门峡市",
        "label": "三门峡市",
        "children": [
          {
            "id": 1644,
            "pid": 1643,
            "value": "湖滨区",
            "label": "湖滨区"
          },
          {
            "id": 1645,
            "pid": 1643,
            "value": "渑池县",
            "label": "渑池县"
          },
          {
            "id": 1646,
            "pid": 1643,
            "value": "陕县",
            "label": "陕县"
          },
          {
            "id": 1647,
            "pid": 1643,
            "value": "卢氏县",
            "label": "卢氏县"
          },
          {
            "id": 1648,
            "pid": 1643,
            "value": "义马市",
            "label": "义马市"
          },
          {
            "id": 1649,
            "pid": 1643,
            "value": "灵宝市",
            "label": "灵宝市"
          }
        ]
      },
      {
        "id": 1650,
        "pid": 1532,
        "value": "南阳市",
        "label": "南阳市",
        "children": [
          {
            "id": 1651,
            "pid": 1650,
            "value": "宛城区",
            "label": "宛城区"
          },
          {
            "id": 1652,
            "pid": 1650,
            "value": "卧龙区",
            "label": "卧龙区"
          },
          {
            "id": 1653,
            "pid": 1650,
            "value": "南召县",
            "label": "南召县"
          },
          {
            "id": 1654,
            "pid": 1650,
            "value": "方城县",
            "label": "方城县"
          },
          {
            "id": 1655,
            "pid": 1650,
            "value": "西峡县",
            "label": "西峡县"
          },
          {
            "id": 1656,
            "pid": 1650,
            "value": "镇平县",
            "label": "镇平县"
          },
          {
            "id": 1657,
            "pid": 1650,
            "value": "内乡县",
            "label": "内乡县"
          },
          {
            "id": 1658,
            "pid": 1650,
            "value": "淅川县",
            "label": "淅川县"
          },
          {
            "id": 1659,
            "pid": 1650,
            "value": "社旗县",
            "label": "社旗县"
          },
          {
            "id": 1660,
            "pid": 1650,
            "value": "唐河县",
            "label": "唐河县"
          },
          {
            "id": 1661,
            "pid": 1650,
            "value": "新野县",
            "label": "新野县"
          },
          {
            "id": 1662,
            "pid": 1650,
            "value": "桐柏县",
            "label": "桐柏县"
          },
          {
            "id": 1663,
            "pid": 1650,
            "value": "邓州市",
            "label": "邓州市"
          }
        ]
      },
      {
        "id": 1664,
        "pid": 1532,
        "value": "商丘市",
        "label": "商丘市",
        "children": [
          {
            "id": 1665,
            "pid": 1664,
            "value": "梁园区",
            "label": "梁园区"
          },
          {
            "id": 1666,
            "pid": 1664,
            "value": "睢阳区",
            "label": "睢阳区"
          },
          {
            "id": 1667,
            "pid": 1664,
            "value": "民权县",
            "label": "民权县"
          },
          {
            "id": 1668,
            "pid": 1664,
            "value": "睢县",
            "label": "睢县"
          },
          {
            "id": 1669,
            "pid": 1664,
            "value": "宁陵县",
            "label": "宁陵县"
          },
          {
            "id": 1670,
            "pid": 1664,
            "value": "柘城县",
            "label": "柘城县"
          },
          {
            "id": 1671,
            "pid": 1664,
            "value": "虞城县",
            "label": "虞城县"
          },
          {
            "id": 1672,
            "pid": 1664,
            "value": "夏邑县",
            "label": "夏邑县"
          },
          {
            "id": 1673,
            "pid": 1664,
            "value": "永城市",
            "label": "永城市"
          }
        ]
      },
      {
        "id": 1674,
        "pid": 1532,
        "value": "信阳市",
        "label": "信阳市",
        "children": [
          {
            "id": 1675,
            "pid": 1674,
            "value": "浉河区",
            "label": "浉河区"
          },
          {
            "id": 1676,
            "pid": 1674,
            "value": "平桥区",
            "label": "平桥区"
          },
          {
            "id": 1677,
            "pid": 1674,
            "value": "罗山县",
            "label": "罗山县"
          },
          {
            "id": 1678,
            "pid": 1674,
            "value": "光山县",
            "label": "光山县"
          },
          {
            "id": 1679,
            "pid": 1674,
            "value": "新县",
            "label": "新县"
          },
          {
            "id": 1680,
            "pid": 1674,
            "value": "商城县",
            "label": "商城县"
          },
          {
            "id": 1681,
            "pid": 1674,
            "value": "固始县",
            "label": "固始县"
          },
          {
            "id": 1682,
            "pid": 1674,
            "value": "潢川县",
            "label": "潢川县"
          },
          {
            "id": 1683,
            "pid": 1674,
            "value": "淮滨县",
            "label": "淮滨县"
          },
          {
            "id": 1684,
            "pid": 1674,
            "value": "息县",
            "label": "息县"
          }
        ]
      },
      {
        "id": 1685,
        "pid": 1532,
        "value": "周口市",
        "label": "周口市",
        "children": [
          {
            "id": 1686,
            "pid": 1685,
            "value": "川汇区",
            "label": "川汇区"
          },
          {
            "id": 1687,
            "pid": 1685,
            "value": "扶沟县",
            "label": "扶沟县"
          },
          {
            "id": 1688,
            "pid": 1685,
            "value": "西华县",
            "label": "西华县"
          },
          {
            "id": 1689,
            "pid": 1685,
            "value": "商水县",
            "label": "商水县"
          },
          {
            "id": 1690,
            "pid": 1685,
            "value": "沈丘县",
            "label": "沈丘县"
          },
          {
            "id": 1691,
            "pid": 1685,
            "value": "郸城县",
            "label": "郸城县"
          },
          {
            "id": 1692,
            "pid": 1685,
            "value": "淮阳县",
            "label": "淮阳县"
          },
          {
            "id": 1693,
            "pid": 1685,
            "value": "太康县",
            "label": "太康县"
          },
          {
            "id": 1694,
            "pid": 1685,
            "value": "鹿邑县",
            "label": "鹿邑县"
          },
          {
            "id": 1695,
            "pid": 1685,
            "value": "项城市",
            "label": "项城市"
          }
        ]
      },
      {
        "id": 1696,
        "pid": 1532,
        "value": "驻马店市",
        "label": "驻马店市",
        "children": [
          {
            "id": 1697,
            "pid": 1696,
            "value": "驿城区",
            "label": "驿城区"
          },
          {
            "id": 1698,
            "pid": 1696,
            "value": "西平县",
            "label": "西平县"
          },
          {
            "id": 1699,
            "pid": 1696,
            "value": "上蔡县",
            "label": "上蔡县"
          },
          {
            "id": 1700,
            "pid": 1696,
            "value": "平舆县",
            "label": "平舆县"
          },
          {
            "id": 1701,
            "pid": 1696,
            "value": "正阳县",
            "label": "正阳县"
          },
          {
            "id": 1702,
            "pid": 1696,
            "value": "确山县",
            "label": "确山县"
          },
          {
            "id": 1703,
            "pid": 1696,
            "value": "泌阳县",
            "label": "泌阳县"
          },
          {
            "id": 1704,
            "pid": 1696,
            "value": "汝南县",
            "label": "汝南县"
          },
          {
            "id": 1705,
            "pid": 1696,
            "value": "遂平县",
            "label": "遂平县"
          },
          {
            "id": 1706,
            "pid": 1696,
            "value": "新蔡县",
            "label": "新蔡县"
          }
        ]
      },
      {
        "id": 1707,
        "pid": 1532,
        "value": "直辖县级",
        "label": "直辖县级",
        "children": [
          {
            "id": 1708,
            "pid": 1707,
            "value": "济源市",
            "label": "济源市"
          }
        ]
      }
    ]
  },
  {
    "id": 1709,
    "pid": 0,
    "value": "湖北省",
    "label": "湖北省",
    "children": [
      {
        "id": 1710,
        "pid": 1709,
        "value": "武汉市",
        "label": "武汉市",
        "children": [
          {
            "id": 1711,
            "pid": 1710,
            "value": "江岸区",
            "label": "江岸区"
          },
          {
            "id": 1712,
            "pid": 1710,
            "value": "江汉区",
            "label": "江汉区"
          },
          {
            "id": 1713,
            "pid": 1710,
            "value": "硚口区",
            "label": "硚口区"
          },
          {
            "id": 1714,
            "pid": 1710,
            "value": "汉阳区",
            "label": "汉阳区"
          },
          {
            "id": 1715,
            "pid": 1710,
            "value": "武昌区",
            "label": "武昌区"
          },
          {
            "id": 1716,
            "pid": 1710,
            "value": "青山区",
            "label": "青山区"
          },
          {
            "id": 1717,
            "pid": 1710,
            "value": "洪山区",
            "label": "洪山区"
          },
          {
            "id": 1718,
            "pid": 1710,
            "value": "东西湖区",
            "label": "东西湖区"
          },
          {
            "id": 1719,
            "pid": 1710,
            "value": "汉南区",
            "label": "汉南区"
          },
          {
            "id": 1720,
            "pid": 1710,
            "value": "蔡甸区",
            "label": "蔡甸区"
          },
          {
            "id": 1721,
            "pid": 1710,
            "value": "江夏区",
            "label": "江夏区"
          },
          {
            "id": 1722,
            "pid": 1710,
            "value": "黄陂区",
            "label": "黄陂区"
          },
          {
            "id": 1723,
            "pid": 1710,
            "value": "新洲区",
            "label": "新洲区"
          }
        ]
      },
      {
        "id": 1724,
        "pid": 1709,
        "value": "黄石市",
        "label": "黄石市",
        "children": [
          {
            "id": 1725,
            "pid": 1724,
            "value": "黄石港区",
            "label": "黄石港区"
          },
          {
            "id": 1726,
            "pid": 1724,
            "value": "西塞山区",
            "label": "西塞山区"
          },
          {
            "id": 1727,
            "pid": 1724,
            "value": "下陆区",
            "label": "下陆区"
          },
          {
            "id": 1728,
            "pid": 1724,
            "value": "铁山区",
            "label": "铁山区"
          },
          {
            "id": 1729,
            "pid": 1724,
            "value": "阳新县",
            "label": "阳新县"
          },
          {
            "id": 1730,
            "pid": 1724,
            "value": "大冶市",
            "label": "大冶市"
          }
        ]
      },
      {
        "id": 1731,
        "pid": 1709,
        "value": "十堰市",
        "label": "十堰市",
        "children": [
          {
            "id": 1732,
            "pid": 1731,
            "value": "茅箭区",
            "label": "茅箭区"
          },
          {
            "id": 1733,
            "pid": 1731,
            "value": "张湾区",
            "label": "张湾区"
          },
          {
            "id": 1734,
            "pid": 1731,
            "value": "郧阳区",
            "label": "郧阳区"
          },
          {
            "id": 1735,
            "pid": 1731,
            "value": "郧西县",
            "label": "郧西县"
          },
          {
            "id": 1736,
            "pid": 1731,
            "value": "竹山县",
            "label": "竹山县"
          },
          {
            "id": 1737,
            "pid": 1731,
            "value": "竹溪县",
            "label": "竹溪县"
          },
          {
            "id": 1738,
            "pid": 1731,
            "value": "房县",
            "label": "房县"
          },
          {
            "id": 1739,
            "pid": 1731,
            "value": "丹江口市",
            "label": "丹江口市"
          }
        ]
      },
      {
        "id": 1740,
        "pid": 1709,
        "value": "宜昌市",
        "label": "宜昌市",
        "children": [
          {
            "id": 1741,
            "pid": 1740,
            "value": "西陵区",
            "label": "西陵区"
          },
          {
            "id": 1742,
            "pid": 1740,
            "value": "伍家岗区",
            "label": "伍家岗区"
          },
          {
            "id": 1743,
            "pid": 1740,
            "value": "点军区",
            "label": "点军区"
          },
          {
            "id": 1744,
            "pid": 1740,
            "value": "猇亭区",
            "label": "猇亭区"
          },
          {
            "id": 1745,
            "pid": 1740,
            "value": "夷陵区",
            "label": "夷陵区"
          },
          {
            "id": 1746,
            "pid": 1740,
            "value": "远安县",
            "label": "远安县"
          },
          {
            "id": 1747,
            "pid": 1740,
            "value": "兴山县",
            "label": "兴山县"
          },
          {
            "id": 1748,
            "pid": 1740,
            "value": "秭归县",
            "label": "秭归县"
          },
          {
            "id": 1749,
            "pid": 1740,
            "value": "长阳土家族自治县",
            "label": "长阳土家族自治县"
          },
          {
            "id": 1750,
            "pid": 1740,
            "value": "五峰土家族自治县",
            "label": "五峰土家族自治县"
          },
          {
            "id": 1751,
            "pid": 1740,
            "value": "宜都市",
            "label": "宜都市"
          },
          {
            "id": 1752,
            "pid": 1740,
            "value": "当阳市",
            "label": "当阳市"
          },
          {
            "id": 1753,
            "pid": 1740,
            "value": "枝江市",
            "label": "枝江市"
          }
        ]
      },
      {
        "id": 1754,
        "pid": 1709,
        "value": "襄阳市",
        "label": "襄阳市",
        "children": [
          {
            "id": 1755,
            "pid": 1754,
            "value": "襄城区",
            "label": "襄城区"
          },
          {
            "id": 1756,
            "pid": 1754,
            "value": "樊城区",
            "label": "樊城区"
          },
          {
            "id": 1757,
            "pid": 1754,
            "value": "襄州区",
            "label": "襄州区"
          },
          {
            "id": 1758,
            "pid": 1754,
            "value": "南漳县",
            "label": "南漳县"
          },
          {
            "id": 1759,
            "pid": 1754,
            "value": "谷城县",
            "label": "谷城县"
          },
          {
            "id": 1760,
            "pid": 1754,
            "value": "保康县",
            "label": "保康县"
          },
          {
            "id": 1761,
            "pid": 1754,
            "value": "老河口市",
            "label": "老河口市"
          },
          {
            "id": 1762,
            "pid": 1754,
            "value": "枣阳市",
            "label": "枣阳市"
          },
          {
            "id": 1763,
            "pid": 1754,
            "value": "宜城市",
            "label": "宜城市"
          }
        ]
      },
      {
        "id": 1764,
        "pid": 1709,
        "value": "鄂州市",
        "label": "鄂州市",
        "children": [
          {
            "id": 1765,
            "pid": 1764,
            "value": "梁子湖区",
            "label": "梁子湖区"
          },
          {
            "id": 1766,
            "pid": 1764,
            "value": "华容区",
            "label": "华容区"
          },
          {
            "id": 1767,
            "pid": 1764,
            "value": "鄂城区",
            "label": "鄂城区"
          }
        ]
      },
      {
        "id": 1768,
        "pid": 1709,
        "value": "荆门市",
        "label": "荆门市",
        "children": [
          {
            "id": 1769,
            "pid": 1768,
            "value": "东宝区",
            "label": "东宝区"
          },
          {
            "id": 1770,
            "pid": 1768,
            "value": "掇刀区",
            "label": "掇刀区"
          },
          {
            "id": 1771,
            "pid": 1768,
            "value": "京山县",
            "label": "京山县"
          },
          {
            "id": 1772,
            "pid": 1768,
            "value": "沙洋县",
            "label": "沙洋县"
          },
          {
            "id": 1773,
            "pid": 1768,
            "value": "钟祥市",
            "label": "钟祥市"
          }
        ]
      },
      {
        "id": 1774,
        "pid": 1709,
        "value": "孝感市",
        "label": "孝感市",
        "children": [
          {
            "id": 1775,
            "pid": 1774,
            "value": "孝南区",
            "label": "孝南区"
          },
          {
            "id": 1776,
            "pid": 1774,
            "value": "孝昌县",
            "label": "孝昌县"
          },
          {
            "id": 1777,
            "pid": 1774,
            "value": "大悟县",
            "label": "大悟县"
          },
          {
            "id": 1778,
            "pid": 1774,
            "value": "云梦县",
            "label": "云梦县"
          },
          {
            "id": 1779,
            "pid": 1774,
            "value": "应城市",
            "label": "应城市"
          },
          {
            "id": 1780,
            "pid": 1774,
            "value": "安陆市",
            "label": "安陆市"
          },
          {
            "id": 1781,
            "pid": 1774,
            "value": "汉川市",
            "label": "汉川市"
          }
        ]
      },
      {
        "id": 1782,
        "pid": 1709,
        "value": "荆州市",
        "label": "荆州市",
        "children": [
          {
            "id": 1783,
            "pid": 1782,
            "value": "沙市区",
            "label": "沙市区"
          },
          {
            "id": 1784,
            "pid": 1782,
            "value": "荆州区",
            "label": "荆州区"
          },
          {
            "id": 1785,
            "pid": 1782,
            "value": "公安县",
            "label": "公安县"
          },
          {
            "id": 1786,
            "pid": 1782,
            "value": "监利县",
            "label": "监利县"
          },
          {
            "id": 1787,
            "pid": 1782,
            "value": "江陵县",
            "label": "江陵县"
          },
          {
            "id": 1788,
            "pid": 1782,
            "value": "石首市",
            "label": "石首市"
          },
          {
            "id": 1789,
            "pid": 1782,
            "value": "洪湖市",
            "label": "洪湖市"
          },
          {
            "id": 1790,
            "pid": 1782,
            "value": "松滋市",
            "label": "松滋市"
          }
        ]
      },
      {
        "id": 1791,
        "pid": 1709,
        "value": "黄冈市",
        "label": "黄冈市",
        "children": [
          {
            "id": 1792,
            "pid": 1791,
            "value": "黄州区",
            "label": "黄州区"
          },
          {
            "id": 1793,
            "pid": 1791,
            "value": "团风县",
            "label": "团风县"
          },
          {
            "id": 1794,
            "pid": 1791,
            "value": "红安县",
            "label": "红安县"
          },
          {
            "id": 1795,
            "pid": 1791,
            "value": "罗田县",
            "label": "罗田县"
          },
          {
            "id": 1796,
            "pid": 1791,
            "value": "英山县",
            "label": "英山县"
          },
          {
            "id": 1797,
            "pid": 1791,
            "value": "浠水县",
            "label": "浠水县"
          },
          {
            "id": 1798,
            "pid": 1791,
            "value": "蕲春县",
            "label": "蕲春县"
          },
          {
            "id": 1799,
            "pid": 1791,
            "value": "黄梅县",
            "label": "黄梅县"
          },
          {
            "id": 1800,
            "pid": 1791,
            "value": "麻城市",
            "label": "麻城市"
          },
          {
            "id": 1801,
            "pid": 1791,
            "value": "武穴市",
            "label": "武穴市"
          }
        ]
      },
      {
        "id": 1802,
        "pid": 1709,
        "value": "咸宁市",
        "label": "咸宁市",
        "children": [
          {
            "id": 1803,
            "pid": 1802,
            "value": "咸安区",
            "label": "咸安区"
          },
          {
            "id": 1804,
            "pid": 1802,
            "value": "嘉鱼县",
            "label": "嘉鱼县"
          },
          {
            "id": 1805,
            "pid": 1802,
            "value": "通城县",
            "label": "通城县"
          },
          {
            "id": 1806,
            "pid": 1802,
            "value": "崇阳县",
            "label": "崇阳县"
          },
          {
            "id": 1807,
            "pid": 1802,
            "value": "通山县",
            "label": "通山县"
          },
          {
            "id": 1808,
            "pid": 1802,
            "value": "赤壁市",
            "label": "赤壁市"
          }
        ]
      },
      {
        "id": 1809,
        "pid": 1709,
        "value": "随州市",
        "label": "随州市",
        "children": [
          {
            "id": 1810,
            "pid": 1809,
            "value": "曾都区",
            "label": "曾都区"
          },
          {
            "id": 1811,
            "pid": 1809,
            "value": "随县",
            "label": "随县"
          },
          {
            "id": 1812,
            "pid": 1809,
            "value": "广水市",
            "label": "广水市"
          }
        ]
      },
      {
        "id": 1813,
        "pid": 1709,
        "value": "恩施土家族苗族自治州",
        "label": "恩施土家族苗族自治州",
        "children": [
          {
            "id": 1814,
            "pid": 1813,
            "value": "恩施市",
            "label": "恩施市"
          },
          {
            "id": 1815,
            "pid": 1813,
            "value": "利川市",
            "label": "利川市"
          },
          {
            "id": 1816,
            "pid": 1813,
            "value": "建始县",
            "label": "建始县"
          },
          {
            "id": 1817,
            "pid": 1813,
            "value": "巴东县",
            "label": "巴东县"
          },
          {
            "id": 1818,
            "pid": 1813,
            "value": "宣恩县",
            "label": "宣恩县"
          },
          {
            "id": 1819,
            "pid": 1813,
            "value": "咸丰县",
            "label": "咸丰县"
          },
          {
            "id": 1820,
            "pid": 1813,
            "value": "来凤县",
            "label": "来凤县"
          },
          {
            "id": 1821,
            "pid": 1813,
            "value": "鹤峰县",
            "label": "鹤峰县"
          }
        ]
      },
      {
        "id": 1822,
        "pid": 1709,
        "value": "直辖县级",
        "label": "直辖县级",
        "children": [
          {
            "id": 1823,
            "pid": 1822,
            "value": "仙桃市",
            "label": "仙桃市"
          },
          {
            "id": 1824,
            "pid": 1822,
            "value": "潜江市",
            "label": "潜江市"
          },
          {
            "id": 1825,
            "pid": 1822,
            "value": "天门市",
            "label": "天门市"
          },
          {
            "id": 1826,
            "pid": 1822,
            "value": "神农架林区",
            "label": "神农架林区"
          }
        ]
      }
    ]
  },
  {
    "id": 1827,
    "pid": 0,
    "value": "湖南省",
    "label": "湖南省",
    "children": [
      {
        "id": 1828,
        "pid": 1827,
        "value": "长沙市",
        "label": "长沙市",
        "children": [
          {
            "id": 1829,
            "pid": 1828,
            "value": "芙蓉区",
            "label": "芙蓉区"
          },
          {
            "id": 1830,
            "pid": 1828,
            "value": "天心区",
            "label": "天心区"
          },
          {
            "id": 1831,
            "pid": 1828,
            "value": "岳麓区",
            "label": "岳麓区"
          },
          {
            "id": 1832,
            "pid": 1828,
            "value": "开福区",
            "label": "开福区"
          },
          {
            "id": 1833,
            "pid": 1828,
            "value": "雨花区",
            "label": "雨花区"
          },
          {
            "id": 1834,
            "pid": 1828,
            "value": "望城区",
            "label": "望城区"
          },
          {
            "id": 1835,
            "pid": 1828,
            "value": "长沙县",
            "label": "长沙县"
          },
          {
            "id": 1836,
            "pid": 1828,
            "value": "宁乡县",
            "label": "宁乡县"
          },
          {
            "id": 1837,
            "pid": 1828,
            "value": "浏阳市",
            "label": "浏阳市"
          }
        ]
      },
      {
        "id": 1838,
        "pid": 1827,
        "value": "株洲市",
        "label": "株洲市",
        "children": [
          {
            "id": 1839,
            "pid": 1838,
            "value": "荷塘区",
            "label": "荷塘区"
          },
          {
            "id": 1840,
            "pid": 1838,
            "value": "芦淞区",
            "label": "芦淞区"
          },
          {
            "id": 1841,
            "pid": 1838,
            "value": "石峰区",
            "label": "石峰区"
          },
          {
            "id": 1842,
            "pid": 1838,
            "value": "天元区",
            "label": "天元区"
          },
          {
            "id": 1843,
            "pid": 1838,
            "value": "株洲县",
            "label": "株洲县"
          },
          {
            "id": 1844,
            "pid": 1838,
            "value": "攸县",
            "label": "攸县"
          },
          {
            "id": 1845,
            "pid": 1838,
            "value": "茶陵县",
            "label": "茶陵县"
          },
          {
            "id": 1846,
            "pid": 1838,
            "value": "炎陵县",
            "label": "炎陵县"
          },
          {
            "id": 1847,
            "pid": 1838,
            "value": "醴陵市",
            "label": "醴陵市"
          }
        ]
      },
      {
        "id": 1848,
        "pid": 1827,
        "value": "湘潭市",
        "label": "湘潭市",
        "children": [
          {
            "id": 1849,
            "pid": 1848,
            "value": "雨湖区",
            "label": "雨湖区"
          },
          {
            "id": 1850,
            "pid": 1848,
            "value": "岳塘区",
            "label": "岳塘区"
          },
          {
            "id": 1851,
            "pid": 1848,
            "value": "湘潭县",
            "label": "湘潭县"
          },
          {
            "id": 1852,
            "pid": 1848,
            "value": "湘乡市",
            "label": "湘乡市"
          },
          {
            "id": 1853,
            "pid": 1848,
            "value": "韶山市",
            "label": "韶山市"
          }
        ]
      },
      {
        "id": 1854,
        "pid": 1827,
        "value": "衡阳市",
        "label": "衡阳市",
        "children": [
          {
            "id": 1855,
            "pid": 1854,
            "value": "珠晖区",
            "label": "珠晖区"
          },
          {
            "id": 1856,
            "pid": 1854,
            "value": "雁峰区",
            "label": "雁峰区"
          },
          {
            "id": 1857,
            "pid": 1854,
            "value": "石鼓区",
            "label": "石鼓区"
          },
          {
            "id": 1858,
            "pid": 1854,
            "value": "蒸湘区",
            "label": "蒸湘区"
          },
          {
            "id": 1859,
            "pid": 1854,
            "value": "南岳区",
            "label": "南岳区"
          },
          {
            "id": 1860,
            "pid": 1854,
            "value": "衡阳县",
            "label": "衡阳县"
          },
          {
            "id": 1861,
            "pid": 1854,
            "value": "衡南县",
            "label": "衡南县"
          },
          {
            "id": 1862,
            "pid": 1854,
            "value": "衡山县",
            "label": "衡山县"
          },
          {
            "id": 1863,
            "pid": 1854,
            "value": "衡东县",
            "label": "衡东县"
          },
          {
            "id": 1864,
            "pid": 1854,
            "value": "祁东县",
            "label": "祁东县"
          },
          {
            "id": 1865,
            "pid": 1854,
            "value": "耒阳市",
            "label": "耒阳市"
          },
          {
            "id": 1866,
            "pid": 1854,
            "value": "常宁市",
            "label": "常宁市"
          }
        ]
      },
      {
        "id": 1867,
        "pid": 1827,
        "value": "邵阳市",
        "label": "邵阳市",
        "children": [
          {
            "id": 1868,
            "pid": 1867,
            "value": "双清区",
            "label": "双清区"
          },
          {
            "id": 1869,
            "pid": 1867,
            "value": "大祥区",
            "label": "大祥区"
          },
          {
            "id": 1870,
            "pid": 1867,
            "value": "北塔区",
            "label": "北塔区"
          },
          {
            "id": 1871,
            "pid": 1867,
            "value": "邵东县",
            "label": "邵东县"
          },
          {
            "id": 1872,
            "pid": 1867,
            "value": "新邵县",
            "label": "新邵县"
          },
          {
            "id": 1873,
            "pid": 1867,
            "value": "邵阳县",
            "label": "邵阳县"
          },
          {
            "id": 1874,
            "pid": 1867,
            "value": "隆回县",
            "label": "隆回县"
          },
          {
            "id": 1875,
            "pid": 1867,
            "value": "洞口县",
            "label": "洞口县"
          },
          {
            "id": 1876,
            "pid": 1867,
            "value": "绥宁县",
            "label": "绥宁县"
          },
          {
            "id": 1877,
            "pid": 1867,
            "value": "新宁县",
            "label": "新宁县"
          },
          {
            "id": 1878,
            "pid": 1867,
            "value": "城步苗族自治县",
            "label": "城步苗族自治县"
          },
          {
            "id": 1879,
            "pid": 1867,
            "value": "武冈市",
            "label": "武冈市"
          }
        ]
      },
      {
        "id": 1880,
        "pid": 1827,
        "value": "岳阳市",
        "label": "岳阳市",
        "children": [
          {
            "id": 1881,
            "pid": 1880,
            "value": "岳阳楼区",
            "label": "岳阳楼区"
          },
          {
            "id": 1882,
            "pid": 1880,
            "value": "云溪区",
            "label": "云溪区"
          },
          {
            "id": 1883,
            "pid": 1880,
            "value": "君山区",
            "label": "君山区"
          },
          {
            "id": 1884,
            "pid": 1880,
            "value": "岳阳县",
            "label": "岳阳县"
          },
          {
            "id": 1885,
            "pid": 1880,
            "value": "华容县",
            "label": "华容县"
          },
          {
            "id": 1886,
            "pid": 1880,
            "value": "湘阴县",
            "label": "湘阴县"
          },
          {
            "id": 1887,
            "pid": 1880,
            "value": "平江县",
            "label": "平江县"
          },
          {
            "id": 1888,
            "pid": 1880,
            "value": "汨罗市",
            "label": "汨罗市"
          },
          {
            "id": 1889,
            "pid": 1880,
            "value": "临湘市",
            "label": "临湘市"
          }
        ]
      },
      {
        "id": 1890,
        "pid": 1827,
        "value": "常德市",
        "label": "常德市",
        "children": [
          {
            "id": 1891,
            "pid": 1890,
            "value": "武陵区",
            "label": "武陵区"
          },
          {
            "id": 1892,
            "pid": 1890,
            "value": "鼎城区",
            "label": "鼎城区"
          },
          {
            "id": 1893,
            "pid": 1890,
            "value": "安乡县",
            "label": "安乡县"
          },
          {
            "id": 1894,
            "pid": 1890,
            "value": "汉寿县",
            "label": "汉寿县"
          },
          {
            "id": 1895,
            "pid": 1890,
            "value": "澧县",
            "label": "澧县"
          },
          {
            "id": 1896,
            "pid": 1890,
            "value": "临澧县",
            "label": "临澧县"
          },
          {
            "id": 1897,
            "pid": 1890,
            "value": "桃源县",
            "label": "桃源县"
          },
          {
            "id": 1898,
            "pid": 1890,
            "value": "石门县",
            "label": "石门县"
          },
          {
            "id": 1899,
            "pid": 1890,
            "value": "津市市",
            "label": "津市市"
          }
        ]
      },
      {
        "id": 1900,
        "pid": 1827,
        "value": "张家界市",
        "label": "张家界市",
        "children": [
          {
            "id": 1901,
            "pid": 1900,
            "value": "永定区",
            "label": "永定区"
          },
          {
            "id": 1902,
            "pid": 1900,
            "value": "武陵源区",
            "label": "武陵源区"
          },
          {
            "id": 1903,
            "pid": 1900,
            "value": "慈利县",
            "label": "慈利县"
          },
          {
            "id": 1904,
            "pid": 1900,
            "value": "桑植县",
            "label": "桑植县"
          }
        ]
      },
      {
        "id": 1905,
        "pid": 1827,
        "value": "益阳市",
        "label": "益阳市",
        "children": [
          {
            "id": 1906,
            "pid": 1905,
            "value": "资阳区",
            "label": "资阳区"
          },
          {
            "id": 1907,
            "pid": 1905,
            "value": "赫山区",
            "label": "赫山区"
          },
          {
            "id": 1908,
            "pid": 1905,
            "value": "南县",
            "label": "南县"
          },
          {
            "id": 1909,
            "pid": 1905,
            "value": "桃江县",
            "label": "桃江县"
          },
          {
            "id": 1910,
            "pid": 1905,
            "value": "安化县",
            "label": "安化县"
          },
          {
            "id": 1911,
            "pid": 1905,
            "value": "沅江市",
            "label": "沅江市"
          }
        ]
      },
      {
        "id": 1912,
        "pid": 1827,
        "value": "郴州市",
        "label": "郴州市",
        "children": [
          {
            "id": 1913,
            "pid": 1912,
            "value": "北湖区",
            "label": "北湖区"
          },
          {
            "id": 1914,
            "pid": 1912,
            "value": "苏仙区",
            "label": "苏仙区"
          },
          {
            "id": 1915,
            "pid": 1912,
            "value": "桂阳县",
            "label": "桂阳县"
          },
          {
            "id": 1916,
            "pid": 1912,
            "value": "宜章县",
            "label": "宜章县"
          },
          {
            "id": 1917,
            "pid": 1912,
            "value": "永兴县",
            "label": "永兴县"
          },
          {
            "id": 1918,
            "pid": 1912,
            "value": "嘉禾县",
            "label": "嘉禾县"
          },
          {
            "id": 1919,
            "pid": 1912,
            "value": "临武县",
            "label": "临武县"
          },
          {
            "id": 1920,
            "pid": 1912,
            "value": "汝城县",
            "label": "汝城县"
          },
          {
            "id": 1921,
            "pid": 1912,
            "value": "桂东县",
            "label": "桂东县"
          },
          {
            "id": 1922,
            "pid": 1912,
            "value": "安仁县",
            "label": "安仁县"
          },
          {
            "id": 1923,
            "pid": 1912,
            "value": "资兴市",
            "label": "资兴市"
          }
        ]
      },
      {
        "id": 1924,
        "pid": 1827,
        "value": "永州市",
        "label": "永州市",
        "children": [
          {
            "id": 1925,
            "pid": 1924,
            "value": "零陵区",
            "label": "零陵区"
          },
          {
            "id": 1926,
            "pid": 1924,
            "value": "冷水滩区",
            "label": "冷水滩区"
          },
          {
            "id": 1927,
            "pid": 1924,
            "value": "祁阳县",
            "label": "祁阳县"
          },
          {
            "id": 1928,
            "pid": 1924,
            "value": "东安县",
            "label": "东安县"
          },
          {
            "id": 1929,
            "pid": 1924,
            "value": "双牌县",
            "label": "双牌县"
          },
          {
            "id": 1930,
            "pid": 1924,
            "value": "道县",
            "label": "道县"
          },
          {
            "id": 1931,
            "pid": 1924,
            "value": "江永县",
            "label": "江永县"
          },
          {
            "id": 1932,
            "pid": 1924,
            "value": "宁远县",
            "label": "宁远县"
          },
          {
            "id": 1933,
            "pid": 1924,
            "value": "蓝山县",
            "label": "蓝山县"
          },
          {
            "id": 1934,
            "pid": 1924,
            "value": "新田县",
            "label": "新田县"
          },
          {
            "id": 1935,
            "pid": 1924,
            "value": "江华瑶族自治县",
            "label": "江华瑶族自治县"
          }
        ]
      },
      {
        "id": 1936,
        "pid": 1827,
        "value": "怀化市",
        "label": "怀化市",
        "children": [
          {
            "id": 1937,
            "pid": 1936,
            "value": "鹤城区",
            "label": "鹤城区"
          },
          {
            "id": 1938,
            "pid": 1936,
            "value": "中方县",
            "label": "中方县"
          },
          {
            "id": 1939,
            "pid": 1936,
            "value": "沅陵县",
            "label": "沅陵县"
          },
          {
            "id": 1940,
            "pid": 1936,
            "value": "辰溪县",
            "label": "辰溪县"
          },
          {
            "id": 1941,
            "pid": 1936,
            "value": "溆浦县",
            "label": "溆浦县"
          },
          {
            "id": 1942,
            "pid": 1936,
            "value": "会同县",
            "label": "会同县"
          },
          {
            "id": 1943,
            "pid": 1936,
            "value": "麻阳苗族自治县",
            "label": "麻阳苗族自治县"
          },
          {
            "id": 1944,
            "pid": 1936,
            "value": "新晃侗族自治县",
            "label": "新晃侗族自治县"
          },
          {
            "id": 1945,
            "pid": 1936,
            "value": "芷江侗族自治县",
            "label": "芷江侗族自治县"
          },
          {
            "id": 1946,
            "pid": 1936,
            "value": "靖州苗族侗族自治县",
            "label": "靖州苗族侗族自治县"
          },
          {
            "id": 1947,
            "pid": 1936,
            "value": "通道侗族自治县",
            "label": "通道侗族自治县"
          },
          {
            "id": 1948,
            "pid": 1936,
            "value": "洪江市",
            "label": "洪江市"
          }
        ]
      },
      {
        "id": 1949,
        "pid": 1827,
        "value": "娄底市",
        "label": "娄底市",
        "children": [
          {
            "id": 1950,
            "pid": 1949,
            "value": "娄星区",
            "label": "娄星区"
          },
          {
            "id": 1951,
            "pid": 1949,
            "value": "双峰县",
            "label": "双峰县"
          },
          {
            "id": 1952,
            "pid": 1949,
            "value": "新化县",
            "label": "新化县"
          },
          {
            "id": 1953,
            "pid": 1949,
            "value": "冷水江市",
            "label": "冷水江市"
          },
          {
            "id": 1954,
            "pid": 1949,
            "value": "涟源市",
            "label": "涟源市"
          }
        ]
      },
      {
        "id": 1955,
        "pid": 1827,
        "value": "湘西土家族苗族自治州",
        "label": "湘西土家族苗族自治州",
        "children": [
          {
            "id": 1956,
            "pid": 1955,
            "value": "吉首市",
            "label": "吉首市"
          },
          {
            "id": 1957,
            "pid": 1955,
            "value": "泸溪县",
            "label": "泸溪县"
          },
          {
            "id": 1958,
            "pid": 1955,
            "value": "凤凰县",
            "label": "凤凰县"
          },
          {
            "id": 1959,
            "pid": 1955,
            "value": "花垣县",
            "label": "花垣县"
          },
          {
            "id": 1960,
            "pid": 1955,
            "value": "保靖县",
            "label": "保靖县"
          },
          {
            "id": 1961,
            "pid": 1955,
            "value": "古丈县",
            "label": "古丈县"
          },
          {
            "id": 1962,
            "pid": 1955,
            "value": "永顺县",
            "label": "永顺县"
          },
          {
            "id": 1963,
            "pid": 1955,
            "value": "龙山县",
            "label": "龙山县"
          }
        ]
      }
    ]
  },
  {
    "id": 1964,
    "pid": 0,
    "value": "广东省",
    "label": "广东省",
    "children": [
      {
        "id": 1965,
        "pid": 1964,
        "value": "广州市",
        "label": "广州市",
        "children": [
          {
            "id": 1966,
            "pid": 1965,
            "value": "荔湾区",
            "label": "荔湾区"
          },
          {
            "id": 1967,
            "pid": 1965,
            "value": "越秀区",
            "label": "越秀区"
          },
          {
            "id": 1968,
            "pid": 1965,
            "value": "海珠区",
            "label": "海珠区"
          },
          {
            "id": 1969,
            "pid": 1965,
            "value": "天河区",
            "label": "天河区"
          },
          {
            "id": 1970,
            "pid": 1965,
            "value": "白云区",
            "label": "白云区"
          },
          {
            "id": 1971,
            "pid": 1965,
            "value": "黄埔区",
            "label": "黄埔区"
          },
          {
            "id": 1972,
            "pid": 1965,
            "value": "番禺区",
            "label": "番禺区"
          },
          {
            "id": 1973,
            "pid": 1965,
            "value": "花都区",
            "label": "花都区"
          },
          {
            "id": 1974,
            "pid": 1965,
            "value": "南沙区",
            "label": "南沙区"
          },
          {
            "id": 1975,
            "pid": 1965,
            "value": "从化区",
            "label": "从化区"
          },
          {
            "id": 1976,
            "pid": 1965,
            "value": "增城区",
            "label": "增城区"
          }
        ]
      },
      {
        "id": 1977,
        "pid": 1964,
        "value": "韶关市",
        "label": "韶关市",
        "children": [
          {
            "id": 1978,
            "pid": 1977,
            "value": "武江区",
            "label": "武江区"
          },
          {
            "id": 1979,
            "pid": 1977,
            "value": "浈江区",
            "label": "浈江区"
          },
          {
            "id": 1980,
            "pid": 1977,
            "value": "曲江区",
            "label": "曲江区"
          },
          {
            "id": 1981,
            "pid": 1977,
            "value": "始兴县",
            "label": "始兴县"
          },
          {
            "id": 1982,
            "pid": 1977,
            "value": "仁化县",
            "label": "仁化县"
          },
          {
            "id": 1983,
            "pid": 1977,
            "value": "翁源县",
            "label": "翁源县"
          },
          {
            "id": 1984,
            "pid": 1977,
            "value": "乳源瑶族自治县",
            "label": "乳源瑶族自治县"
          },
          {
            "id": 1985,
            "pid": 1977,
            "value": "新丰县",
            "label": "新丰县"
          },
          {
            "id": 1986,
            "pid": 1977,
            "value": "乐昌市",
            "label": "乐昌市"
          },
          {
            "id": 1987,
            "pid": 1977,
            "value": "南雄市",
            "label": "南雄市"
          }
        ]
      },
      {
        "id": 1988,
        "pid": 1964,
        "value": "深圳市",
        "label": "深圳市",
        "children": [
          {
            "id": 1989,
            "pid": 1988,
            "value": "罗湖区",
            "label": "罗湖区"
          },
          {
            "id": 1990,
            "pid": 1988,
            "value": "福田区",
            "label": "福田区"
          },
          {
            "id": 1991,
            "pid": 1988,
            "value": "南山区",
            "label": "南山区"
          },
          {
            "id": 1992,
            "pid": 1988,
            "value": "宝安区",
            "label": "宝安区"
          },
          {
            "id": 1993,
            "pid": 1988,
            "value": "龙岗区",
            "label": "龙岗区"
          },
          {
            "id": 1994,
            "pid": 1988,
            "value": "盐田区",
            "label": "盐田区"
          },
          {
            "id": 1995,
            "pid": 1988,
            "value": "光明新区",
            "label": "光明新区"
          },
          {
            "id": 1996,
            "pid": 1988,
            "value": "坪山新区",
            "label": "坪山新区"
          },
          {
            "id": 1997,
            "pid": 1988,
            "value": "大鹏新区",
            "label": "大鹏新区"
          },
          {
            "id": 1998,
            "pid": 1988,
            "value": "龙华新区",
            "label": "龙华新区"
          }
        ]
      },
      {
        "id": 1999,
        "pid": 1964,
        "value": "珠海市",
        "label": "珠海市",
        "children": [
          {
            "id": 2000,
            "pid": 1999,
            "value": "香洲区",
            "label": "香洲区"
          },
          {
            "id": 2001,
            "pid": 1999,
            "value": "斗门区",
            "label": "斗门区"
          },
          {
            "id": 2002,
            "pid": 1999,
            "value": "金湾区",
            "label": "金湾区"
          }
        ]
      },
      {
        "id": 2003,
        "pid": 1964,
        "value": "汕头市",
        "label": "汕头市",
        "children": [
          {
            "id": 2004,
            "pid": 2003,
            "value": "龙湖区",
            "label": "龙湖区"
          },
          {
            "id": 2005,
            "pid": 2003,
            "value": "金平区",
            "label": "金平区"
          },
          {
            "id": 2006,
            "pid": 2003,
            "value": "濠江区",
            "label": "濠江区"
          },
          {
            "id": 2007,
            "pid": 2003,
            "value": "潮阳区",
            "label": "潮阳区"
          },
          {
            "id": 2008,
            "pid": 2003,
            "value": "潮南区",
            "label": "潮南区"
          },
          {
            "id": 2009,
            "pid": 2003,
            "value": "澄海区",
            "label": "澄海区"
          },
          {
            "id": 2010,
            "pid": 2003,
            "value": "南澳县",
            "label": "南澳县"
          }
        ]
      },
      {
        "id": 2011,
        "pid": 1964,
        "value": "佛山市",
        "label": "佛山市",
        "children": [
          {
            "id": 2012,
            "pid": 2011,
            "value": "禅城区",
            "label": "禅城区"
          },
          {
            "id": 2013,
            "pid": 2011,
            "value": "南海区",
            "label": "南海区"
          },
          {
            "id": 2014,
            "pid": 2011,
            "value": "顺德区",
            "label": "顺德区"
          },
          {
            "id": 2015,
            "pid": 2011,
            "value": "三水区",
            "label": "三水区"
          },
          {
            "id": 2016,
            "pid": 2011,
            "value": "高明区",
            "label": "高明区"
          }
        ]
      },
      {
        "id": 2017,
        "pid": 1964,
        "value": "江门市",
        "label": "江门市",
        "children": [
          {
            "id": 2018,
            "pid": 2017,
            "value": "蓬江区",
            "label": "蓬江区"
          },
          {
            "id": 2019,
            "pid": 2017,
            "value": "江海区",
            "label": "江海区"
          },
          {
            "id": 2020,
            "pid": 2017,
            "value": "新会区",
            "label": "新会区"
          },
          {
            "id": 2021,
            "pid": 2017,
            "value": "台山市",
            "label": "台山市"
          },
          {
            "id": 2022,
            "pid": 2017,
            "value": "开平市",
            "label": "开平市"
          },
          {
            "id": 2023,
            "pid": 2017,
            "value": "鹤山市",
            "label": "鹤山市"
          },
          {
            "id": 2024,
            "pid": 2017,
            "value": "恩平市",
            "label": "恩平市"
          }
        ]
      },
      {
        "id": 2025,
        "pid": 1964,
        "value": "湛江市",
        "label": "湛江市",
        "children": [
          {
            "id": 2026,
            "pid": 2025,
            "value": "赤坎区",
            "label": "赤坎区"
          },
          {
            "id": 2027,
            "pid": 2025,
            "value": "霞山区",
            "label": "霞山区"
          },
          {
            "id": 2028,
            "pid": 2025,
            "value": "坡头区",
            "label": "坡头区"
          },
          {
            "id": 2029,
            "pid": 2025,
            "value": "麻章区",
            "label": "麻章区"
          },
          {
            "id": 2030,
            "pid": 2025,
            "value": "遂溪县",
            "label": "遂溪县"
          },
          {
            "id": 2031,
            "pid": 2025,
            "value": "徐闻县",
            "label": "徐闻县"
          },
          {
            "id": 2032,
            "pid": 2025,
            "value": "廉江市",
            "label": "廉江市"
          },
          {
            "id": 2033,
            "pid": 2025,
            "value": "雷州市",
            "label": "雷州市"
          },
          {
            "id": 2034,
            "pid": 2025,
            "value": "吴川市",
            "label": "吴川市"
          }
        ]
      },
      {
        "id": 2035,
        "pid": 1964,
        "value": "茂名市",
        "label": "茂名市",
        "children": [
          {
            "id": 2036,
            "pid": 2035,
            "value": "茂南区",
            "label": "茂南区"
          },
          {
            "id": 2037,
            "pid": 2035,
            "value": "电白区",
            "label": "电白区"
          },
          {
            "id": 2038,
            "pid": 2035,
            "value": "高州市",
            "label": "高州市"
          },
          {
            "id": 2039,
            "pid": 2035,
            "value": "化州市",
            "label": "化州市"
          },
          {
            "id": 2040,
            "pid": 2035,
            "value": "信宜市",
            "label": "信宜市"
          }
        ]
      },
      {
        "id": 2041,
        "pid": 1964,
        "value": "肇庆市",
        "label": "肇庆市",
        "children": [
          {
            "id": 2042,
            "pid": 2041,
            "value": "端州区",
            "label": "端州区"
          },
          {
            "id": 2043,
            "pid": 2041,
            "value": "鼎湖区",
            "label": "鼎湖区"
          },
          {
            "id": 2044,
            "pid": 2041,
            "value": "广宁县",
            "label": "广宁县"
          },
          {
            "id": 2045,
            "pid": 2041,
            "value": "怀集县",
            "label": "怀集县"
          },
          {
            "id": 2046,
            "pid": 2041,
            "value": "封开县",
            "label": "封开县"
          },
          {
            "id": 2047,
            "pid": 2041,
            "value": "德庆县",
            "label": "德庆县"
          },
          {
            "id": 2048,
            "pid": 2041,
            "value": "高要市",
            "label": "高要市"
          },
          {
            "id": 2049,
            "pid": 2041,
            "value": "四会市",
            "label": "四会市"
          }
        ]
      },
      {
        "id": 2050,
        "pid": 1964,
        "value": "惠州市",
        "label": "惠州市",
        "children": [
          {
            "id": 2051,
            "pid": 2050,
            "value": "惠城区",
            "label": "惠城区"
          },
          {
            "id": 2052,
            "pid": 2050,
            "value": "惠阳区",
            "label": "惠阳区"
          },
          {
            "id": 2053,
            "pid": 2050,
            "value": "博罗县",
            "label": "博罗县"
          },
          {
            "id": 2054,
            "pid": 2050,
            "value": "惠东县",
            "label": "惠东县"
          },
          {
            "id": 2055,
            "pid": 2050,
            "value": "龙门县",
            "label": "龙门县"
          }
        ]
      },
      {
        "id": 2056,
        "pid": 1964,
        "value": "梅州市",
        "label": "梅州市",
        "children": [
          {
            "id": 2057,
            "pid": 2056,
            "value": "梅江区",
            "label": "梅江区"
          },
          {
            "id": 2058,
            "pid": 2056,
            "value": "梅县区",
            "label": "梅县区"
          },
          {
            "id": 2059,
            "pid": 2056,
            "value": "大埔县",
            "label": "大埔县"
          },
          {
            "id": 2060,
            "pid": 2056,
            "value": "丰顺县",
            "label": "丰顺县"
          },
          {
            "id": 2061,
            "pid": 2056,
            "value": "五华县",
            "label": "五华县"
          },
          {
            "id": 2062,
            "pid": 2056,
            "value": "平远县",
            "label": "平远县"
          },
          {
            "id": 2063,
            "pid": 2056,
            "value": "蕉岭县",
            "label": "蕉岭县"
          },
          {
            "id": 2064,
            "pid": 2056,
            "value": "兴宁市",
            "label": "兴宁市"
          }
        ]
      },
      {
        "id": 2065,
        "pid": 1964,
        "value": "汕尾市",
        "label": "汕尾市",
        "children": [
          {
            "id": 2066,
            "pid": 2065,
            "value": "城区",
            "label": "城区"
          },
          {
            "id": 2067,
            "pid": 2065,
            "value": "海丰县",
            "label": "海丰县"
          },
          {
            "id": 2068,
            "pid": 2065,
            "value": "陆河县",
            "label": "陆河县"
          },
          {
            "id": 2069,
            "pid": 2065,
            "value": "陆丰市",
            "label": "陆丰市"
          }
        ]
      },
      {
        "id": 2070,
        "pid": 1964,
        "value": "河源市",
        "label": "河源市",
        "children": [
          {
            "id": 2071,
            "pid": 2070,
            "value": "源城区",
            "label": "源城区"
          },
          {
            "id": 2072,
            "pid": 2070,
            "value": "紫金县",
            "label": "紫金县"
          },
          {
            "id": 2073,
            "pid": 2070,
            "value": "龙川县",
            "label": "龙川县"
          },
          {
            "id": 2074,
            "pid": 2070,
            "value": "连平县",
            "label": "连平县"
          },
          {
            "id": 2075,
            "pid": 2070,
            "value": "和平县",
            "label": "和平县"
          },
          {
            "id": 2076,
            "pid": 2070,
            "value": "东源县",
            "label": "东源县"
          }
        ]
      },
      {
        "id": 2077,
        "pid": 1964,
        "value": "阳江市",
        "label": "阳江市",
        "children": [
          {
            "id": 2078,
            "pid": 2077,
            "value": "江城区",
            "label": "江城区"
          },
          {
            "id": 2079,
            "pid": 2077,
            "value": "阳东区",
            "label": "阳东区"
          },
          {
            "id": 2080,
            "pid": 2077,
            "value": "阳西县",
            "label": "阳西县"
          },
          {
            "id": 2081,
            "pid": 2077,
            "value": "阳春市",
            "label": "阳春市"
          }
        ]
      },
      {
        "id": 2082,
        "pid": 1964,
        "value": "清远市",
        "label": "清远市",
        "children": [
          {
            "id": 2083,
            "pid": 2082,
            "value": "清城区",
            "label": "清城区"
          },
          {
            "id": 2084,
            "pid": 2082,
            "value": "清新区",
            "label": "清新区"
          },
          {
            "id": 2085,
            "pid": 2082,
            "value": "佛冈县",
            "label": "佛冈县"
          },
          {
            "id": 2086,
            "pid": 2082,
            "value": "阳山县",
            "label": "阳山县"
          },
          {
            "id": 2087,
            "pid": 2082,
            "value": "连山壮族瑶族自治县",
            "label": "连山壮族瑶族自治县"
          },
          {
            "id": 2088,
            "pid": 2082,
            "value": "连南瑶族自治县",
            "label": "连南瑶族自治县"
          },
          {
            "id": 2089,
            "pid": 2082,
            "value": "英德市",
            "label": "英德市"
          },
          {
            "id": 2090,
            "pid": 2082,
            "value": "连州市",
            "label": "连州市"
          }
        ]
      },
      {
        "id": 2091,
        "pid": 1964,
        "value": "东莞市",
        "label": "东莞市",
        "children": [
          {
            "id": 2092,
            "pid": 2091,
            "value": "莞城区",
            "label": "莞城区"
          },
          {
            "id": 2093,
            "pid": 2091,
            "value": "南城区",
            "label": "南城区"
          },
          {
            "id": 2094,
            "pid": 2091,
            "value": "万江区",
            "label": "万江区"
          },
          {
            "id": 2095,
            "pid": 2091,
            "value": "石碣镇",
            "label": "石碣镇"
          },
          {
            "id": 2096,
            "pid": 2091,
            "value": "石龙镇",
            "label": "石龙镇"
          },
          {
            "id": 2097,
            "pid": 2091,
            "value": "茶山镇",
            "label": "茶山镇"
          },
          {
            "id": 2098,
            "pid": 2091,
            "value": "石排镇",
            "label": "石排镇"
          },
          {
            "id": 2099,
            "pid": 2091,
            "value": "企石镇",
            "label": "企石镇"
          },
          {
            "id": 2100,
            "pid": 2091,
            "value": "横沥镇",
            "label": "横沥镇"
          },
          {
            "id": 2101,
            "pid": 2091,
            "value": "桥头镇",
            "label": "桥头镇"
          },
          {
            "id": 2102,
            "pid": 2091,
            "value": "谢岗镇",
            "label": "谢岗镇"
          },
          {
            "id": 2103,
            "pid": 2091,
            "value": "东坑镇",
            "label": "东坑镇"
          },
          {
            "id": 2104,
            "pid": 2091,
            "value": "常平镇",
            "label": "常平镇"
          },
          {
            "id": 2105,
            "pid": 2091,
            "value": "寮步镇",
            "label": "寮步镇"
          },
          {
            "id": 2106,
            "pid": 2091,
            "value": "大朗镇",
            "label": "大朗镇"
          },
          {
            "id": 2107,
            "pid": 2091,
            "value": "麻涌镇",
            "label": "麻涌镇"
          },
          {
            "id": 2108,
            "pid": 2091,
            "value": "中堂镇",
            "label": "中堂镇"
          },
          {
            "id": 2109,
            "pid": 2091,
            "value": "高埗镇",
            "label": "高埗镇"
          },
          {
            "id": 2110,
            "pid": 2091,
            "value": "樟木头镇",
            "label": "樟木头镇"
          },
          {
            "id": 2111,
            "pid": 2091,
            "value": "大岭山镇",
            "label": "大岭山镇"
          },
          {
            "id": 2112,
            "pid": 2091,
            "value": "望牛墩镇",
            "label": "望牛墩镇"
          },
          {
            "id": 2113,
            "pid": 2091,
            "value": "黄江镇",
            "label": "黄江镇"
          },
          {
            "id": 2114,
            "pid": 2091,
            "value": "洪梅镇",
            "label": "洪梅镇"
          },
          {
            "id": 2115,
            "pid": 2091,
            "value": "清溪镇",
            "label": "清溪镇"
          },
          {
            "id": 2116,
            "pid": 2091,
            "value": "沙田镇",
            "label": "沙田镇"
          },
          {
            "id": 2117,
            "pid": 2091,
            "value": "道滘镇",
            "label": "道滘镇"
          },
          {
            "id": 2118,
            "pid": 2091,
            "value": "塘厦镇",
            "label": "塘厦镇"
          },
          {
            "id": 2119,
            "pid": 2091,
            "value": "虎门镇",
            "label": "虎门镇"
          },
          {
            "id": 2120,
            "pid": 2091,
            "value": "厚街镇",
            "label": "厚街镇"
          },
          {
            "id": 2121,
            "pid": 2091,
            "value": "凤岗镇",
            "label": "凤岗镇"
          },
          {
            "id": 2122,
            "pid": 2091,
            "value": "长安镇",
            "label": "长安镇"
          }
        ]
      },
      {
        "id": 2123,
        "pid": 1964,
        "value": "中山市",
        "label": "中山市",
        "children": [
          {
            "id": 2124,
            "pid": 2123,
            "value": "石岐区",
            "label": "石岐区"
          },
          {
            "id": 2125,
            "pid": 2123,
            "value": "南区",
            "label": "南区"
          },
          {
            "id": 2126,
            "pid": 2123,
            "value": "五桂山区",
            "label": "五桂山区"
          },
          {
            "id": 2127,
            "pid": 2123,
            "value": "火炬开发区",
            "label": "火炬开发区"
          },
          {
            "id": 2128,
            "pid": 2123,
            "value": "黄圃镇",
            "label": "黄圃镇"
          },
          {
            "id": 2129,
            "pid": 2123,
            "value": "南头镇",
            "label": "南头镇"
          },
          {
            "id": 2130,
            "pid": 2123,
            "value": "东凤镇",
            "label": "东凤镇"
          },
          {
            "id": 2131,
            "pid": 2123,
            "value": "阜沙镇",
            "label": "阜沙镇"
          },
          {
            "id": 2132,
            "pid": 2123,
            "value": "小榄镇",
            "label": "小榄镇"
          },
          {
            "id": 2133,
            "pid": 2123,
            "value": "东升镇",
            "label": "东升镇"
          },
          {
            "id": 2134,
            "pid": 2123,
            "value": "古镇镇",
            "label": "古镇镇"
          },
          {
            "id": 2135,
            "pid": 2123,
            "value": "横栏镇",
            "label": "横栏镇"
          },
          {
            "id": 2136,
            "pid": 2123,
            "value": "三角镇",
            "label": "三角镇"
          },
          {
            "id": 2137,
            "pid": 2123,
            "value": "民众镇",
            "label": "民众镇"
          },
          {
            "id": 2138,
            "pid": 2123,
            "value": "南朗镇",
            "label": "南朗镇"
          },
          {
            "id": 2139,
            "pid": 2123,
            "value": "港口镇",
            "label": "港口镇"
          },
          {
            "id": 2140,
            "pid": 2123,
            "value": "大涌镇",
            "label": "大涌镇"
          },
          {
            "id": 2141,
            "pid": 2123,
            "value": "沙溪镇",
            "label": "沙溪镇"
          },
          {
            "id": 2142,
            "pid": 2123,
            "value": "三乡镇",
            "label": "三乡镇"
          },
          {
            "id": 2143,
            "pid": 2123,
            "value": "板芙镇",
            "label": "板芙镇"
          },
          {
            "id": 2144,
            "pid": 2123,
            "value": "神湾镇",
            "label": "神湾镇"
          },
          {
            "id": 2145,
            "pid": 2123,
            "value": "坦洲镇",
            "label": "坦洲镇"
          }
        ]
      },
      {
        "id": 2146,
        "pid": 1964,
        "value": "潮州市",
        "label": "潮州市",
        "children": [
          {
            "id": 2147,
            "pid": 2146,
            "value": "湘桥区",
            "label": "湘桥区"
          },
          {
            "id": 2148,
            "pid": 2146,
            "value": "潮安区",
            "label": "潮安区"
          },
          {
            "id": 2149,
            "pid": 2146,
            "value": "饶平县",
            "label": "饶平县"
          }
        ]
      },
      {
        "id": 2150,
        "pid": 1964,
        "value": "揭阳市",
        "label": "揭阳市",
        "children": [
          {
            "id": 2151,
            "pid": 2150,
            "value": "榕城区",
            "label": "榕城区"
          },
          {
            "id": 2152,
            "pid": 2150,
            "value": "揭东区",
            "label": "揭东区"
          },
          {
            "id": 2153,
            "pid": 2150,
            "value": "揭西县",
            "label": "揭西县"
          },
          {
            "id": 2154,
            "pid": 2150,
            "value": "惠来县",
            "label": "惠来县"
          },
          {
            "id": 2155,
            "pid": 2150,
            "value": "普宁市",
            "label": "普宁市"
          }
        ]
      },
      {
        "id": 2156,
        "pid": 1964,
        "value": "云浮市",
        "label": "云浮市",
        "children": [
          {
            "id": 2157,
            "pid": 2156,
            "value": "云城区",
            "label": "云城区"
          },
          {
            "id": 2158,
            "pid": 2156,
            "value": "云安区",
            "label": "云安区"
          },
          {
            "id": 2159,
            "pid": 2156,
            "value": "新兴县",
            "label": "新兴县"
          },
          {
            "id": 2160,
            "pid": 2156,
            "value": "郁南县",
            "label": "郁南县"
          },
          {
            "id": 2161,
            "pid": 2156,
            "value": "罗定市",
            "label": "罗定市"
          }
        ]
      }
    ]
  },
  {
    "id": 2162,
    "pid": 0,
    "value": "广西壮族自治区",
    "label": "广西壮族自治区",
    "children": [
      {
        "id": 2163,
        "pid": 2162,
        "value": "南宁市",
        "label": "南宁市",
        "children": [
          {
            "id": 2164,
            "pid": 2163,
            "value": "兴宁区",
            "label": "兴宁区"
          },
          {
            "id": 2165,
            "pid": 2163,
            "value": "青秀区",
            "label": "青秀区"
          },
          {
            "id": 2166,
            "pid": 2163,
            "value": "江南区",
            "label": "江南区"
          },
          {
            "id": 2167,
            "pid": 2163,
            "value": "西乡塘区",
            "label": "西乡塘区"
          },
          {
            "id": 2168,
            "pid": 2163,
            "value": "良庆区",
            "label": "良庆区"
          },
          {
            "id": 2169,
            "pid": 2163,
            "value": "邕宁区",
            "label": "邕宁区"
          },
          {
            "id": 2170,
            "pid": 2163,
            "value": "武鸣县",
            "label": "武鸣县"
          },
          {
            "id": 2171,
            "pid": 2163,
            "value": "隆安县",
            "label": "隆安县"
          },
          {
            "id": 2172,
            "pid": 2163,
            "value": "马山县",
            "label": "马山县"
          },
          {
            "id": 2173,
            "pid": 2163,
            "value": "上林县",
            "label": "上林县"
          },
          {
            "id": 2174,
            "pid": 2163,
            "value": "宾阳县",
            "label": "宾阳县"
          },
          {
            "id": 2175,
            "pid": 2163,
            "value": "横县",
            "label": "横县"
          },
          {
            "id": 2176,
            "pid": 2163,
            "value": "埌东新区",
            "label": "埌东新区"
          }
        ]
      },
      {
        "id": 2177,
        "pid": 2162,
        "value": "柳州市",
        "label": "柳州市",
        "children": [
          {
            "id": 2178,
            "pid": 2177,
            "value": "城中区",
            "label": "城中区"
          },
          {
            "id": 2179,
            "pid": 2177,
            "value": "鱼峰区",
            "label": "鱼峰区"
          },
          {
            "id": 2180,
            "pid": 2177,
            "value": "柳南区",
            "label": "柳南区"
          },
          {
            "id": 2181,
            "pid": 2177,
            "value": "柳北区",
            "label": "柳北区"
          },
          {
            "id": 2182,
            "pid": 2177,
            "value": "柳江县",
            "label": "柳江县"
          },
          {
            "id": 2183,
            "pid": 2177,
            "value": "柳城县",
            "label": "柳城县"
          },
          {
            "id": 2184,
            "pid": 2177,
            "value": "鹿寨县",
            "label": "鹿寨县"
          },
          {
            "id": 2185,
            "pid": 2177,
            "value": "融安县",
            "label": "融安县"
          },
          {
            "id": 2186,
            "pid": 2177,
            "value": "融水苗族自治县",
            "label": "融水苗族自治县"
          },
          {
            "id": 2187,
            "pid": 2177,
            "value": "三江侗族自治县",
            "label": "三江侗族自治县"
          },
          {
            "id": 2188,
            "pid": 2177,
            "value": "柳东新区",
            "label": "柳东新区"
          }
        ]
      },
      {
        "id": 2189,
        "pid": 2162,
        "value": "桂林市",
        "label": "桂林市",
        "children": [
          {
            "id": 2190,
            "pid": 2189,
            "value": "秀峰区",
            "label": "秀峰区"
          },
          {
            "id": 2191,
            "pid": 2189,
            "value": "叠彩区",
            "label": "叠彩区"
          },
          {
            "id": 2192,
            "pid": 2189,
            "value": "象山区",
            "label": "象山区"
          },
          {
            "id": 2193,
            "pid": 2189,
            "value": "七星区",
            "label": "七星区"
          },
          {
            "id": 2194,
            "pid": 2189,
            "value": "雁山区",
            "label": "雁山区"
          },
          {
            "id": 2195,
            "pid": 2189,
            "value": "临桂区",
            "label": "临桂区"
          },
          {
            "id": 2196,
            "pid": 2189,
            "value": "阳朔县",
            "label": "阳朔县"
          },
          {
            "id": 2197,
            "pid": 2189,
            "value": "灵川县",
            "label": "灵川县"
          },
          {
            "id": 2198,
            "pid": 2189,
            "value": "全州县",
            "label": "全州县"
          },
          {
            "id": 2199,
            "pid": 2189,
            "value": "兴安县",
            "label": "兴安县"
          },
          {
            "id": 2200,
            "pid": 2189,
            "value": "永福县",
            "label": "永福县"
          },
          {
            "id": 2201,
            "pid": 2189,
            "value": "灌阳县",
            "label": "灌阳县"
          },
          {
            "id": 2202,
            "pid": 2189,
            "value": "龙胜各族自治县",
            "label": "龙胜各族自治县"
          },
          {
            "id": 2203,
            "pid": 2189,
            "value": "资源县",
            "label": "资源县"
          },
          {
            "id": 2204,
            "pid": 2189,
            "value": "平乐县",
            "label": "平乐县"
          },
          {
            "id": 2205,
            "pid": 2189,
            "value": "荔浦县",
            "label": "荔浦县"
          },
          {
            "id": 2206,
            "pid": 2189,
            "value": "恭城瑶族自治县",
            "label": "恭城瑶族自治县"
          }
        ]
      },
      {
        "id": 2207,
        "pid": 2162,
        "value": "梧州市",
        "label": "梧州市",
        "children": [
          {
            "id": 2208,
            "pid": 2207,
            "value": "万秀区",
            "label": "万秀区"
          },
          {
            "id": 2209,
            "pid": 2207,
            "value": "长洲区",
            "label": "长洲区"
          },
          {
            "id": 2210,
            "pid": 2207,
            "value": "龙圩区",
            "label": "龙圩区"
          },
          {
            "id": 2211,
            "pid": 2207,
            "value": "苍梧县",
            "label": "苍梧县"
          },
          {
            "id": 2212,
            "pid": 2207,
            "value": "藤县",
            "label": "藤县"
          },
          {
            "id": 2213,
            "pid": 2207,
            "value": "蒙山县",
            "label": "蒙山县"
          },
          {
            "id": 2214,
            "pid": 2207,
            "value": "岑溪市",
            "label": "岑溪市"
          }
        ]
      },
      {
        "id": 2215,
        "pid": 2162,
        "value": "北海市",
        "label": "北海市",
        "children": [
          {
            "id": 2216,
            "pid": 2215,
            "value": "海城区",
            "label": "海城区"
          },
          {
            "id": 2217,
            "pid": 2215,
            "value": "银海区",
            "label": "银海区"
          },
          {
            "id": 2218,
            "pid": 2215,
            "value": "铁山港区",
            "label": "铁山港区"
          },
          {
            "id": 2219,
            "pid": 2215,
            "value": "合浦县",
            "label": "合浦县"
          }
        ]
      },
      {
        "id": 2220,
        "pid": 2162,
        "value": "防城港市",
        "label": "防城港市",
        "children": [
          {
            "id": 2221,
            "pid": 2220,
            "value": "港口区",
            "label": "港口区"
          },
          {
            "id": 2222,
            "pid": 2220,
            "value": "防城区",
            "label": "防城区"
          },
          {
            "id": 2223,
            "pid": 2220,
            "value": "上思县",
            "label": "上思县"
          },
          {
            "id": 2224,
            "pid": 2220,
            "value": "东兴市",
            "label": "东兴市"
          }
        ]
      },
      {
        "id": 2225,
        "pid": 2162,
        "value": "钦州市",
        "label": "钦州市",
        "children": [
          {
            "id": 2226,
            "pid": 2225,
            "value": "钦南区",
            "label": "钦南区"
          },
          {
            "id": 2227,
            "pid": 2225,
            "value": "钦北区",
            "label": "钦北区"
          },
          {
            "id": 2228,
            "pid": 2225,
            "value": "灵山县",
            "label": "灵山县"
          },
          {
            "id": 2229,
            "pid": 2225,
            "value": "浦北县",
            "label": "浦北县"
          }
        ]
      },
      {
        "id": 2230,
        "pid": 2162,
        "value": "贵港市",
        "label": "贵港市",
        "children": [
          {
            "id": 2231,
            "pid": 2230,
            "value": "港北区",
            "label": "港北区"
          },
          {
            "id": 2232,
            "pid": 2230,
            "value": "港南区",
            "label": "港南区"
          },
          {
            "id": 2233,
            "pid": 2230,
            "value": "覃塘区",
            "label": "覃塘区"
          },
          {
            "id": 2234,
            "pid": 2230,
            "value": "平南县",
            "label": "平南县"
          },
          {
            "id": 2235,
            "pid": 2230,
            "value": "桂平市",
            "label": "桂平市"
          }
        ]
      },
      {
        "id": 2236,
        "pid": 2162,
        "value": "玉林市",
        "label": "玉林市",
        "children": [
          {
            "id": 2237,
            "pid": 2236,
            "value": "玉州区",
            "label": "玉州区"
          },
          {
            "id": 2238,
            "pid": 2236,
            "value": "福绵区",
            "label": "福绵区"
          },
          {
            "id": 2239,
            "pid": 2236,
            "value": "玉东新区",
            "label": "玉东新区"
          },
          {
            "id": 2240,
            "pid": 2236,
            "value": "容县",
            "label": "容县"
          },
          {
            "id": 2241,
            "pid": 2236,
            "value": "陆川县",
            "label": "陆川县"
          },
          {
            "id": 2242,
            "pid": 2236,
            "value": "博白县",
            "label": "博白县"
          },
          {
            "id": 2243,
            "pid": 2236,
            "value": "兴业县",
            "label": "兴业县"
          },
          {
            "id": 2244,
            "pid": 2236,
            "value": "北流市",
            "label": "北流市"
          }
        ]
      },
      {
        "id": 2245,
        "pid": 2162,
        "value": "百色市",
        "label": "百色市",
        "children": [
          {
            "id": 2246,
            "pid": 2245,
            "value": "右江区",
            "label": "右江区"
          },
          {
            "id": 2247,
            "pid": 2245,
            "value": "田阳县",
            "label": "田阳县"
          },
          {
            "id": 2248,
            "pid": 2245,
            "value": "田东县",
            "label": "田东县"
          },
          {
            "id": 2249,
            "pid": 2245,
            "value": "平果县",
            "label": "平果县"
          },
          {
            "id": 2250,
            "pid": 2245,
            "value": "德保县",
            "label": "德保县"
          },
          {
            "id": 2251,
            "pid": 2245,
            "value": "靖西县",
            "label": "靖西县"
          },
          {
            "id": 2252,
            "pid": 2245,
            "value": "那坡县",
            "label": "那坡县"
          },
          {
            "id": 2253,
            "pid": 2245,
            "value": "凌云县",
            "label": "凌云县"
          },
          {
            "id": 2254,
            "pid": 2245,
            "value": "乐业县",
            "label": "乐业县"
          },
          {
            "id": 2255,
            "pid": 2245,
            "value": "田林县",
            "label": "田林县"
          },
          {
            "id": 2256,
            "pid": 2245,
            "value": "西林县",
            "label": "西林县"
          },
          {
            "id": 2257,
            "pid": 2245,
            "value": "隆林各族自治县",
            "label": "隆林各族自治县"
          }
        ]
      },
      {
        "id": 2258,
        "pid": 2162,
        "value": "贺州市",
        "label": "贺州市",
        "children": [
          {
            "id": 2259,
            "pid": 2258,
            "value": "八步区",
            "label": "八步区"
          },
          {
            "id": 2260,
            "pid": 2258,
            "value": "昭平县",
            "label": "昭平县"
          },
          {
            "id": 2261,
            "pid": 2258,
            "value": "钟山县",
            "label": "钟山县"
          },
          {
            "id": 2262,
            "pid": 2258,
            "value": "富川瑶族自治县",
            "label": "富川瑶族自治县"
          },
          {
            "id": 2263,
            "pid": 2258,
            "value": "平桂管理区",
            "label": "平桂管理区"
          }
        ]
      },
      {
        "id": 2264,
        "pid": 2162,
        "value": "河池市",
        "label": "河池市",
        "children": [
          {
            "id": 2265,
            "pid": 2264,
            "value": "金城江区",
            "label": "金城江区"
          },
          {
            "id": 2266,
            "pid": 2264,
            "value": "南丹县",
            "label": "南丹县"
          },
          {
            "id": 2267,
            "pid": 2264,
            "value": "天峨县",
            "label": "天峨县"
          },
          {
            "id": 2268,
            "pid": 2264,
            "value": "凤山县",
            "label": "凤山县"
          },
          {
            "id": 2269,
            "pid": 2264,
            "value": "东兰县",
            "label": "东兰县"
          },
          {
            "id": 2270,
            "pid": 2264,
            "value": "罗城仫佬族自治县",
            "label": "罗城仫佬族自治县"
          },
          {
            "id": 2271,
            "pid": 2264,
            "value": "环江毛南族自治县",
            "label": "环江毛南族自治县"
          },
          {
            "id": 2272,
            "pid": 2264,
            "value": "巴马瑶族自治县",
            "label": "巴马瑶族自治县"
          },
          {
            "id": 2273,
            "pid": 2264,
            "value": "都安瑶族自治县",
            "label": "都安瑶族自治县"
          },
          {
            "id": 2274,
            "pid": 2264,
            "value": "大化瑶族自治县",
            "label": "大化瑶族自治县"
          },
          {
            "id": 2275,
            "pid": 2264,
            "value": "宜州市",
            "label": "宜州市"
          }
        ]
      },
      {
        "id": 2276,
        "pid": 2162,
        "value": "来宾市",
        "label": "来宾市",
        "children": [
          {
            "id": 2277,
            "pid": 2276,
            "value": "兴宾区",
            "label": "兴宾区"
          },
          {
            "id": 2278,
            "pid": 2276,
            "value": "忻城县",
            "label": "忻城县"
          },
          {
            "id": 2279,
            "pid": 2276,
            "value": "象州县",
            "label": "象州县"
          },
          {
            "id": 2280,
            "pid": 2276,
            "value": "武宣县",
            "label": "武宣县"
          },
          {
            "id": 2281,
            "pid": 2276,
            "value": "金秀瑶族自治县",
            "label": "金秀瑶族自治县"
          },
          {
            "id": 2282,
            "pid": 2276,
            "value": "合山市",
            "label": "合山市"
          }
        ]
      },
      {
        "id": 2283,
        "pid": 2162,
        "value": "崇左市",
        "label": "崇左市",
        "children": [
          {
            "id": 2284,
            "pid": 2283,
            "value": "江州区",
            "label": "江州区"
          },
          {
            "id": 2285,
            "pid": 2283,
            "value": "扶绥县",
            "label": "扶绥县"
          },
          {
            "id": 2286,
            "pid": 2283,
            "value": "宁明县",
            "label": "宁明县"
          },
          {
            "id": 2287,
            "pid": 2283,
            "value": "龙州县",
            "label": "龙州县"
          },
          {
            "id": 2288,
            "pid": 2283,
            "value": "大新县",
            "label": "大新县"
          },
          {
            "id": 2289,
            "pid": 2283,
            "value": "天等县",
            "label": "天等县"
          },
          {
            "id": 2290,
            "pid": 2283,
            "value": "凭祥市",
            "label": "凭祥市"
          }
        ]
      }
    ]
  },
  {
    "id": 2291,
    "pid": 0,
    "value": "海南省",
    "label": "海南省",
    "children": [
      {
        "id": 2292,
        "pid": 2291,
        "value": "海口市",
        "label": "海口市",
        "children": [
          {
            "id": 2293,
            "pid": 2292,
            "value": "秀英区",
            "label": "秀英区"
          },
          {
            "id": 2294,
            "pid": 2292,
            "value": "龙华区",
            "label": "龙华区"
          },
          {
            "id": 2295,
            "pid": 2292,
            "value": "琼山区",
            "label": "琼山区"
          },
          {
            "id": 2296,
            "pid": 2292,
            "value": "美兰区",
            "label": "美兰区"
          }
        ]
      },
      {
        "id": 2297,
        "pid": 2291,
        "value": "三亚市",
        "label": "三亚市",
        "children": [
          {
            "id": 2298,
            "pid": 2297,
            "value": "海棠区",
            "label": "海棠区"
          },
          {
            "id": 2299,
            "pid": 2297,
            "value": "吉阳区",
            "label": "吉阳区"
          },
          {
            "id": 2300,
            "pid": 2297,
            "value": "天涯区",
            "label": "天涯区"
          },
          {
            "id": 2301,
            "pid": 2297,
            "value": "崖州区",
            "label": "崖州区"
          }
        ]
      },
      {
        "id": 2302,
        "pid": 2291,
        "value": "三沙市",
        "label": "三沙市",
        "children": [
          {
            "id": 2303,
            "pid": 2302,
            "value": "西沙群岛",
            "label": "西沙群岛"
          },
          {
            "id": 2304,
            "pid": 2302,
            "value": "南沙群岛",
            "label": "南沙群岛"
          },
          {
            "id": 2305,
            "pid": 2302,
            "value": "中沙群岛",
            "label": "中沙群岛"
          }
        ]
      },
      {
        "id": 2306,
        "pid": 2291,
        "value": "直辖县级",
        "label": "直辖县级",
        "children": [
          {
            "id": 2307,
            "pid": 2306,
            "value": "五指山市",
            "label": "五指山市"
          },
          {
            "id": 2308,
            "pid": 2306,
            "value": "琼海市",
            "label": "琼海市"
          },
          {
            "id": 2309,
            "pid": 2306,
            "value": "儋州市",
            "label": "儋州市"
          },
          {
            "id": 2310,
            "pid": 2306,
            "value": "文昌市",
            "label": "文昌市"
          },
          {
            "id": 2311,
            "pid": 2306,
            "value": "万宁市",
            "label": "万宁市"
          },
          {
            "id": 2312,
            "pid": 2306,
            "value": "东方市",
            "label": "东方市"
          },
          {
            "id": 2313,
            "pid": 2306,
            "value": "定安县",
            "label": "定安县"
          },
          {
            "id": 2314,
            "pid": 2306,
            "value": "屯昌县",
            "label": "屯昌县"
          },
          {
            "id": 2315,
            "pid": 2306,
            "value": "澄迈县",
            "label": "澄迈县"
          },
          {
            "id": 2316,
            "pid": 2306,
            "value": "临高县",
            "label": "临高县"
          },
          {
            "id": 2317,
            "pid": 2306,
            "value": "白沙黎族自治县",
            "label": "白沙黎族自治县"
          },
          {
            "id": 2318,
            "pid": 2306,
            "value": "昌江黎族自治县",
            "label": "昌江黎族自治县"
          },
          {
            "id": 2319,
            "pid": 2306,
            "value": "乐东黎族自治县",
            "label": "乐东黎族自治县"
          },
          {
            "id": 2320,
            "pid": 2306,
            "value": "陵水黎族自治县",
            "label": "陵水黎族自治县"
          },
          {
            "id": 2321,
            "pid": 2306,
            "value": "保亭黎族苗族自治县",
            "label": "保亭黎族苗族自治县"
          },
          {
            "id": 2322,
            "pid": 2306,
            "value": "琼中黎族苗族自治县",
            "label": "琼中黎族苗族自治县"
          }
        ]
      }
    ]
  },
  {
    "id": 2323,
    "pid": 0,
    "value": "重庆",
    "label": "重庆",
    "children": [
      {
        "id": 2324,
        "pid": 2323,
        "value": "重庆市",
        "label": "重庆市",
        "children": [
          {
            "id": 2325,
            "pid": 2324,
            "value": "万州区",
            "label": "万州区"
          },
          {
            "id": 2326,
            "pid": 2324,
            "value": "涪陵区",
            "label": "涪陵区"
          },
          {
            "id": 2327,
            "pid": 2324,
            "value": "渝中区",
            "label": "渝中区"
          },
          {
            "id": 2328,
            "pid": 2324,
            "value": "大渡口区",
            "label": "大渡口区"
          },
          {
            "id": 2329,
            "pid": 2324,
            "value": "江北区",
            "label": "江北区"
          },
          {
            "id": 2330,
            "pid": 2324,
            "value": "沙坪坝区",
            "label": "沙坪坝区"
          },
          {
            "id": 2331,
            "pid": 2324,
            "value": "九龙坡区",
            "label": "九龙坡区"
          },
          {
            "id": 2332,
            "pid": 2324,
            "value": "南岸区",
            "label": "南岸区"
          },
          {
            "id": 2333,
            "pid": 2324,
            "value": "北碚区",
            "label": "北碚区"
          },
          {
            "id": 2334,
            "pid": 2324,
            "value": "綦江区",
            "label": "綦江区"
          },
          {
            "id": 2335,
            "pid": 2324,
            "value": "大足区",
            "label": "大足区"
          },
          {
            "id": 2336,
            "pid": 2324,
            "value": "渝北区",
            "label": "渝北区"
          },
          {
            "id": 2337,
            "pid": 2324,
            "value": "巴南区",
            "label": "巴南区"
          },
          {
            "id": 2338,
            "pid": 2324,
            "value": "黔江区",
            "label": "黔江区"
          },
          {
            "id": 2339,
            "pid": 2324,
            "value": "长寿区",
            "label": "长寿区"
          },
          {
            "id": 2340,
            "pid": 2324,
            "value": "江津区",
            "label": "江津区"
          },
          {
            "id": 2341,
            "pid": 2324,
            "value": "合川区",
            "label": "合川区"
          },
          {
            "id": 2342,
            "pid": 2324,
            "value": "永川区",
            "label": "永川区"
          },
          {
            "id": 2343,
            "pid": 2324,
            "value": "南川区",
            "label": "南川区"
          },
          {
            "id": 2344,
            "pid": 2324,
            "value": "璧山区",
            "label": "璧山区"
          },
          {
            "id": 2345,
            "pid": 2324,
            "value": "铜梁区",
            "label": "铜梁区"
          },
          {
            "id": 2346,
            "pid": 2324,
            "value": "潼南县",
            "label": "潼南县"
          },
          {
            "id": 2347,
            "pid": 2324,
            "value": "荣昌县",
            "label": "荣昌县"
          },
          {
            "id": 2348,
            "pid": 2324,
            "value": "梁平县",
            "label": "梁平县"
          },
          {
            "id": 2349,
            "pid": 2324,
            "value": "城口县",
            "label": "城口县"
          },
          {
            "id": 2350,
            "pid": 2324,
            "value": "丰都县",
            "label": "丰都县"
          },
          {
            "id": 2351,
            "pid": 2324,
            "value": "垫江县",
            "label": "垫江县"
          },
          {
            "id": 2352,
            "pid": 2324,
            "value": "武隆县",
            "label": "武隆县"
          },
          {
            "id": 2353,
            "pid": 2324,
            "value": "忠县",
            "label": "忠县"
          },
          {
            "id": 2354,
            "pid": 2324,
            "value": "开县",
            "label": "开县"
          },
          {
            "id": 2355,
            "pid": 2324,
            "value": "云阳县",
            "label": "云阳县"
          },
          {
            "id": 2356,
            "pid": 2324,
            "value": "奉节县",
            "label": "奉节县"
          },
          {
            "id": 2357,
            "pid": 2324,
            "value": "巫山县",
            "label": "巫山县"
          },
          {
            "id": 2358,
            "pid": 2324,
            "value": "巫溪县",
            "label": "巫溪县"
          },
          {
            "id": 2359,
            "pid": 2324,
            "value": "石柱土家族自治县",
            "label": "石柱土家族自治县"
          },
          {
            "id": 2360,
            "pid": 2324,
            "value": "秀山土家族苗族自治县",
            "label": "秀山土家族苗族自治县"
          },
          {
            "id": 2361,
            "pid": 2324,
            "value": "酉阳土家族苗族自治县",
            "label": "酉阳土家族苗族自治县"
          },
          {
            "id": 2362,
            "pid": 2324,
            "value": "彭水苗族土家族自治县",
            "label": "彭水苗族土家族自治县"
          }
        ]
      },
      {
        "id": 2363,
        "pid": 2323,
        "value": "两江新区",
        "label": "两江新区",
        "children": [
          {
            "id": 2364,
            "pid": 2363,
            "value": "北部新区",
            "label": "北部新区"
          },
          {
            "id": 2365,
            "pid": 2363,
            "value": "保税港区",
            "label": "保税港区"
          },
          {
            "id": 2366,
            "pid": 2363,
            "value": "工业园区",
            "label": "工业园区"
          }
        ]
      }
    ]
  },
  {
    "id": 2367,
    "pid": 0,
    "value": "四川省",
    "label": "四川省",
    "children": [
      {
        "id": 2368,
        "pid": 2367,
        "value": "成都市",
        "label": "成都市",
        "children": [
          {
            "id": 2369,
            "pid": 2368,
            "value": "锦江区",
            "label": "锦江区"
          },
          {
            "id": 2370,
            "pid": 2368,
            "value": "青羊区",
            "label": "青羊区"
          },
          {
            "id": 2371,
            "pid": 2368,
            "value": "金牛区",
            "label": "金牛区"
          },
          {
            "id": 2372,
            "pid": 2368,
            "value": "武侯区",
            "label": "武侯区"
          },
          {
            "id": 2373,
            "pid": 2368,
            "value": "成华区",
            "label": "成华区"
          },
          {
            "id": 2374,
            "pid": 2368,
            "value": "龙泉驿区",
            "label": "龙泉驿区"
          },
          {
            "id": 2375,
            "pid": 2368,
            "value": "青白江区",
            "label": "青白江区"
          },
          {
            "id": 2376,
            "pid": 2368,
            "value": "新都区",
            "label": "新都区"
          },
          {
            "id": 2377,
            "pid": 2368,
            "value": "温江区",
            "label": "温江区"
          },
          {
            "id": 2378,
            "pid": 2368,
            "value": "金堂县",
            "label": "金堂县"
          },
          {
            "id": 2379,
            "pid": 2368,
            "value": "双流县",
            "label": "双流县"
          },
          {
            "id": 2380,
            "pid": 2368,
            "value": "郫县",
            "label": "郫县"
          },
          {
            "id": 2381,
            "pid": 2368,
            "value": "大邑县",
            "label": "大邑县"
          },
          {
            "id": 2382,
            "pid": 2368,
            "value": "蒲江县",
            "label": "蒲江县"
          },
          {
            "id": 2383,
            "pid": 2368,
            "value": "新津县",
            "label": "新津县"
          },
          {
            "id": 2384,
            "pid": 2368,
            "value": "都江堰市",
            "label": "都江堰市"
          },
          {
            "id": 2385,
            "pid": 2368,
            "value": "彭州市",
            "label": "彭州市"
          },
          {
            "id": 2386,
            "pid": 2368,
            "value": "邛崃市",
            "label": "邛崃市"
          },
          {
            "id": 2387,
            "pid": 2368,
            "value": "崇州市",
            "label": "崇州市"
          }
        ]
      },
      {
        "id": 2388,
        "pid": 2367,
        "value": "自贡市",
        "label": "自贡市",
        "children": [
          {
            "id": 2389,
            "pid": 2388,
            "value": "自流井区",
            "label": "自流井区"
          },
          {
            "id": 2390,
            "pid": 2388,
            "value": "贡井区",
            "label": "贡井区"
          },
          {
            "id": 2391,
            "pid": 2388,
            "value": "大安区",
            "label": "大安区"
          },
          {
            "id": 2392,
            "pid": 2388,
            "value": "沿滩区",
            "label": "沿滩区"
          },
          {
            "id": 2393,
            "pid": 2388,
            "value": "荣县",
            "label": "荣县"
          },
          {
            "id": 2394,
            "pid": 2388,
            "value": "富顺县",
            "label": "富顺县"
          }
        ]
      },
      {
        "id": 2395,
        "pid": 2367,
        "value": "攀枝花市",
        "label": "攀枝花市",
        "children": [
          {
            "id": 2396,
            "pid": 2395,
            "value": "东区",
            "label": "东区"
          },
          {
            "id": 2397,
            "pid": 2395,
            "value": "西区",
            "label": "西区"
          },
          {
            "id": 2398,
            "pid": 2395,
            "value": "仁和区",
            "label": "仁和区"
          },
          {
            "id": 2399,
            "pid": 2395,
            "value": "米易县",
            "label": "米易县"
          },
          {
            "id": 2400,
            "pid": 2395,
            "value": "盐边县",
            "label": "盐边县"
          }
        ]
      },
      {
        "id": 2401,
        "pid": 2367,
        "value": "泸州市",
        "label": "泸州市",
        "children": [
          {
            "id": 2402,
            "pid": 2401,
            "value": "江阳区",
            "label": "江阳区"
          },
          {
            "id": 2403,
            "pid": 2401,
            "value": "纳溪区",
            "label": "纳溪区"
          },
          {
            "id": 2404,
            "pid": 2401,
            "value": "龙马潭区",
            "label": "龙马潭区"
          },
          {
            "id": 2405,
            "pid": 2401,
            "value": "泸县",
            "label": "泸县"
          },
          {
            "id": 2406,
            "pid": 2401,
            "value": "合江县",
            "label": "合江县"
          },
          {
            "id": 2407,
            "pid": 2401,
            "value": "叙永县",
            "label": "叙永县"
          },
          {
            "id": 2408,
            "pid": 2401,
            "value": "古蔺县",
            "label": "古蔺县"
          }
        ]
      },
      {
        "id": 2409,
        "pid": 2367,
        "value": "德阳市",
        "label": "德阳市",
        "children": [
          {
            "id": 2410,
            "pid": 2409,
            "value": "旌阳区",
            "label": "旌阳区"
          },
          {
            "id": 2411,
            "pid": 2409,
            "value": "中江县",
            "label": "中江县"
          },
          {
            "id": 2412,
            "pid": 2409,
            "value": "罗江县",
            "label": "罗江县"
          },
          {
            "id": 2413,
            "pid": 2409,
            "value": "广汉市",
            "label": "广汉市"
          },
          {
            "id": 2414,
            "pid": 2409,
            "value": "什邡市",
            "label": "什邡市"
          },
          {
            "id": 2415,
            "pid": 2409,
            "value": "绵竹市",
            "label": "绵竹市"
          }
        ]
      },
      {
        "id": 2416,
        "pid": 2367,
        "value": "绵阳市",
        "label": "绵阳市",
        "children": [
          {
            "id": 2417,
            "pid": 2416,
            "value": "涪城区",
            "label": "涪城区"
          },
          {
            "id": 2418,
            "pid": 2416,
            "value": "游仙区",
            "label": "游仙区"
          },
          {
            "id": 2419,
            "pid": 2416,
            "value": "三台县",
            "label": "三台县"
          },
          {
            "id": 2420,
            "pid": 2416,
            "value": "盐亭县",
            "label": "盐亭县"
          },
          {
            "id": 2421,
            "pid": 2416,
            "value": "安县",
            "label": "安县"
          },
          {
            "id": 2422,
            "pid": 2416,
            "value": "梓潼县",
            "label": "梓潼县"
          },
          {
            "id": 2423,
            "pid": 2416,
            "value": "北川羌族自治县",
            "label": "北川羌族自治县"
          },
          {
            "id": 2424,
            "pid": 2416,
            "value": "平武县",
            "label": "平武县"
          },
          {
            "id": 2425,
            "pid": 2416,
            "value": "江油市",
            "label": "江油市"
          }
        ]
      },
      {
        "id": 2426,
        "pid": 2367,
        "value": "广元市",
        "label": "广元市",
        "children": [
          {
            "id": 2427,
            "pid": 2426,
            "value": "利州区",
            "label": "利州区"
          },
          {
            "id": 2428,
            "pid": 2426,
            "value": "昭化区",
            "label": "昭化区"
          },
          {
            "id": 2429,
            "pid": 2426,
            "value": "朝天区",
            "label": "朝天区"
          },
          {
            "id": 2430,
            "pid": 2426,
            "value": "旺苍县",
            "label": "旺苍县"
          },
          {
            "id": 2431,
            "pid": 2426,
            "value": "青川县",
            "label": "青川县"
          },
          {
            "id": 2432,
            "pid": 2426,
            "value": "剑阁县",
            "label": "剑阁县"
          },
          {
            "id": 2433,
            "pid": 2426,
            "value": "苍溪县",
            "label": "苍溪县"
          }
        ]
      },
      {
        "id": 2434,
        "pid": 2367,
        "value": "遂宁市",
        "label": "遂宁市",
        "children": [
          {
            "id": 2435,
            "pid": 2434,
            "value": "船山区",
            "label": "船山区"
          },
          {
            "id": 2436,
            "pid": 2434,
            "value": "安居区",
            "label": "安居区"
          },
          {
            "id": 2437,
            "pid": 2434,
            "value": "蓬溪县",
            "label": "蓬溪县"
          },
          {
            "id": 2438,
            "pid": 2434,
            "value": "射洪县",
            "label": "射洪县"
          },
          {
            "id": 2439,
            "pid": 2434,
            "value": "大英县",
            "label": "大英县"
          }
        ]
      },
      {
        "id": 2440,
        "pid": 2367,
        "value": "内江市",
        "label": "内江市",
        "children": [
          {
            "id": 2441,
            "pid": 2440,
            "value": "市中区",
            "label": "市中区"
          },
          {
            "id": 2442,
            "pid": 2440,
            "value": "东兴区",
            "label": "东兴区"
          },
          {
            "id": 2443,
            "pid": 2440,
            "value": "威远县",
            "label": "威远县"
          },
          {
            "id": 2444,
            "pid": 2440,
            "value": "资中县",
            "label": "资中县"
          },
          {
            "id": 2445,
            "pid": 2440,
            "value": "隆昌县",
            "label": "隆昌县"
          }
        ]
      },
      {
        "id": 2446,
        "pid": 2367,
        "value": "乐山市",
        "label": "乐山市",
        "children": [
          {
            "id": 2447,
            "pid": 2446,
            "value": "市中区",
            "label": "市中区"
          },
          {
            "id": 2448,
            "pid": 2446,
            "value": "沙湾区",
            "label": "沙湾区"
          },
          {
            "id": 2449,
            "pid": 2446,
            "value": "五通桥区",
            "label": "五通桥区"
          },
          {
            "id": 2450,
            "pid": 2446,
            "value": "金口河区",
            "label": "金口河区"
          },
          {
            "id": 2451,
            "pid": 2446,
            "value": "犍为县",
            "label": "犍为县"
          },
          {
            "id": 2452,
            "pid": 2446,
            "value": "井研县",
            "label": "井研县"
          },
          {
            "id": 2453,
            "pid": 2446,
            "value": "夹江县",
            "label": "夹江县"
          },
          {
            "id": 2454,
            "pid": 2446,
            "value": "沐川县",
            "label": "沐川县"
          },
          {
            "id": 2455,
            "pid": 2446,
            "value": "峨边彝族自治县",
            "label": "峨边彝族自治县"
          },
          {
            "id": 2456,
            "pid": 2446,
            "value": "马边彝族自治县",
            "label": "马边彝族自治县"
          },
          {
            "id": 2457,
            "pid": 2446,
            "value": "峨眉山市",
            "label": "峨眉山市"
          }
        ]
      },
      {
        "id": 2458,
        "pid": 2367,
        "value": "南充市",
        "label": "南充市",
        "children": [
          {
            "id": 2459,
            "pid": 2458,
            "value": "顺庆区",
            "label": "顺庆区"
          },
          {
            "id": 2460,
            "pid": 2458,
            "value": "高坪区",
            "label": "高坪区"
          },
          {
            "id": 2461,
            "pid": 2458,
            "value": "嘉陵区",
            "label": "嘉陵区"
          },
          {
            "id": 2462,
            "pid": 2458,
            "value": "南部县",
            "label": "南部县"
          },
          {
            "id": 2463,
            "pid": 2458,
            "value": "营山县",
            "label": "营山县"
          },
          {
            "id": 2464,
            "pid": 2458,
            "value": "蓬安县",
            "label": "蓬安县"
          },
          {
            "id": 2465,
            "pid": 2458,
            "value": "仪陇县",
            "label": "仪陇县"
          },
          {
            "id": 2466,
            "pid": 2458,
            "value": "西充县",
            "label": "西充县"
          },
          {
            "id": 2467,
            "pid": 2458,
            "value": "阆中市",
            "label": "阆中市"
          }
        ]
      },
      {
        "id": 2468,
        "pid": 2367,
        "value": "眉山市",
        "label": "眉山市",
        "children": [
          {
            "id": 2469,
            "pid": 2468,
            "value": "东坡区",
            "label": "东坡区"
          },
          {
            "id": 2470,
            "pid": 2468,
            "value": "彭山区",
            "label": "彭山区"
          },
          {
            "id": 2471,
            "pid": 2468,
            "value": "仁寿县",
            "label": "仁寿县"
          },
          {
            "id": 2472,
            "pid": 2468,
            "value": "洪雅县",
            "label": "洪雅县"
          },
          {
            "id": 2473,
            "pid": 2468,
            "value": "丹棱县",
            "label": "丹棱县"
          },
          {
            "id": 2474,
            "pid": 2468,
            "value": "青神县",
            "label": "青神县"
          }
        ]
      },
      {
        "id": 2475,
        "pid": 2367,
        "value": "宜宾市",
        "label": "宜宾市",
        "children": [
          {
            "id": 2476,
            "pid": 2475,
            "value": "翠屏区",
            "label": "翠屏区"
          },
          {
            "id": 2477,
            "pid": 2475,
            "value": "南溪区",
            "label": "南溪区"
          },
          {
            "id": 2478,
            "pid": 2475,
            "value": "宜宾县",
            "label": "宜宾县"
          },
          {
            "id": 2479,
            "pid": 2475,
            "value": "江安县",
            "label": "江安县"
          },
          {
            "id": 2480,
            "pid": 2475,
            "value": "长宁县",
            "label": "长宁县"
          },
          {
            "id": 2481,
            "pid": 2475,
            "value": "高县",
            "label": "高县"
          },
          {
            "id": 2482,
            "pid": 2475,
            "value": "珙县",
            "label": "珙县"
          },
          {
            "id": 2483,
            "pid": 2475,
            "value": "筠连县",
            "label": "筠连县"
          },
          {
            "id": 2484,
            "pid": 2475,
            "value": "兴文县",
            "label": "兴文县"
          },
          {
            "id": 2485,
            "pid": 2475,
            "value": "屏山县",
            "label": "屏山县"
          }
        ]
      },
      {
        "id": 2486,
        "pid": 2367,
        "value": "广安市",
        "label": "广安市",
        "children": [
          {
            "id": 2487,
            "pid": 2486,
            "value": "广安区",
            "label": "广安区"
          },
          {
            "id": 2488,
            "pid": 2486,
            "value": "前锋区",
            "label": "前锋区"
          },
          {
            "id": 2489,
            "pid": 2486,
            "value": "岳池县",
            "label": "岳池县"
          },
          {
            "id": 2490,
            "pid": 2486,
            "value": "武胜县",
            "label": "武胜县"
          },
          {
            "id": 2491,
            "pid": 2486,
            "value": "邻水县",
            "label": "邻水县"
          },
          {
            "id": 2492,
            "pid": 2486,
            "value": "华蓥市",
            "label": "华蓥市"
          }
        ]
      },
      {
        "id": 2493,
        "pid": 2367,
        "value": "达州市",
        "label": "达州市",
        "children": [
          {
            "id": 2494,
            "pid": 2493,
            "value": "通川区",
            "label": "通川区"
          },
          {
            "id": 2495,
            "pid": 2493,
            "value": "达川区",
            "label": "达川区"
          },
          {
            "id": 2496,
            "pid": 2493,
            "value": "宣汉县",
            "label": "宣汉县"
          },
          {
            "id": 2497,
            "pid": 2493,
            "value": "开江县",
            "label": "开江县"
          },
          {
            "id": 2498,
            "pid": 2493,
            "value": "大竹县",
            "label": "大竹县"
          },
          {
            "id": 2499,
            "pid": 2493,
            "value": "渠县",
            "label": "渠县"
          },
          {
            "id": 2500,
            "pid": 2493,
            "value": "万源市",
            "label": "万源市"
          }
        ]
      },
      {
        "id": 2501,
        "pid": 2367,
        "value": "雅安市",
        "label": "雅安市",
        "children": [
          {
            "id": 2502,
            "pid": 2501,
            "value": "雨城区",
            "label": "雨城区"
          },
          {
            "id": 2503,
            "pid": 2501,
            "value": "名山区",
            "label": "名山区"
          },
          {
            "id": 2504,
            "pid": 2501,
            "value": "荥经县",
            "label": "荥经县"
          },
          {
            "id": 2505,
            "pid": 2501,
            "value": "汉源县",
            "label": "汉源县"
          },
          {
            "id": 2506,
            "pid": 2501,
            "value": "石棉县",
            "label": "石棉县"
          },
          {
            "id": 2507,
            "pid": 2501,
            "value": "天全县",
            "label": "天全县"
          },
          {
            "id": 2508,
            "pid": 2501,
            "value": "芦山县",
            "label": "芦山县"
          },
          {
            "id": 2509,
            "pid": 2501,
            "value": "宝兴县",
            "label": "宝兴县"
          }
        ]
      },
      {
        "id": 2510,
        "pid": 2367,
        "value": "巴中市",
        "label": "巴中市",
        "children": [
          {
            "id": 2511,
            "pid": 2510,
            "value": "巴州区",
            "label": "巴州区"
          },
          {
            "id": 2512,
            "pid": 2510,
            "value": "恩阳区",
            "label": "恩阳区"
          },
          {
            "id": 2513,
            "pid": 2510,
            "value": "通江县",
            "label": "通江县"
          },
          {
            "id": 2514,
            "pid": 2510,
            "value": "南江县",
            "label": "南江县"
          },
          {
            "id": 2515,
            "pid": 2510,
            "value": "平昌县",
            "label": "平昌县"
          }
        ]
      },
      {
        "id": 2516,
        "pid": 2367,
        "value": "资阳市",
        "label": "资阳市",
        "children": [
          {
            "id": 2517,
            "pid": 2516,
            "value": "雁江区",
            "label": "雁江区"
          },
          {
            "id": 2518,
            "pid": 2516,
            "value": "安岳县",
            "label": "安岳县"
          },
          {
            "id": 2519,
            "pid": 2516,
            "value": "乐至县",
            "label": "乐至县"
          },
          {
            "id": 2520,
            "pid": 2516,
            "value": "简阳市",
            "label": "简阳市"
          }
        ]
      },
      {
        "id": 2521,
        "pid": 2367,
        "value": "阿坝藏族羌族自治州",
        "label": "阿坝藏族羌族自治州",
        "children": [
          {
            "id": 2522,
            "pid": 2521,
            "value": "汶川县",
            "label": "汶川县"
          },
          {
            "id": 2523,
            "pid": 2521,
            "value": "理县",
            "label": "理县"
          },
          {
            "id": 2524,
            "pid": 2521,
            "value": "茂县",
            "label": "茂县"
          },
          {
            "id": 2525,
            "pid": 2521,
            "value": "松潘县",
            "label": "松潘县"
          },
          {
            "id": 2526,
            "pid": 2521,
            "value": "九寨沟县",
            "label": "九寨沟县"
          },
          {
            "id": 2527,
            "pid": 2521,
            "value": "金川县",
            "label": "金川县"
          },
          {
            "id": 2528,
            "pid": 2521,
            "value": "小金县",
            "label": "小金县"
          },
          {
            "id": 2529,
            "pid": 2521,
            "value": "黑水县",
            "label": "黑水县"
          },
          {
            "id": 2530,
            "pid": 2521,
            "value": "马尔康县",
            "label": "马尔康县"
          },
          {
            "id": 2531,
            "pid": 2521,
            "value": "壤塘县",
            "label": "壤塘县"
          },
          {
            "id": 2532,
            "pid": 2521,
            "value": "阿坝县",
            "label": "阿坝县"
          },
          {
            "id": 2533,
            "pid": 2521,
            "value": "若尔盖县",
            "label": "若尔盖县"
          },
          {
            "id": 2534,
            "pid": 2521,
            "value": "红原县",
            "label": "红原县"
          }
        ]
      },
      {
        "id": 2535,
        "pid": 2367,
        "value": "甘孜藏族自治州",
        "label": "甘孜藏族自治州",
        "children": [
          {
            "id": 2536,
            "pid": 2535,
            "value": "康定县",
            "label": "康定县"
          },
          {
            "id": 2537,
            "pid": 2535,
            "value": "泸定县",
            "label": "泸定县"
          },
          {
            "id": 2538,
            "pid": 2535,
            "value": "丹巴县",
            "label": "丹巴县"
          },
          {
            "id": 2539,
            "pid": 2535,
            "value": "九龙县",
            "label": "九龙县"
          },
          {
            "id": 2540,
            "pid": 2535,
            "value": "雅江县",
            "label": "雅江县"
          },
          {
            "id": 2541,
            "pid": 2535,
            "value": "道孚县",
            "label": "道孚县"
          },
          {
            "id": 2542,
            "pid": 2535,
            "value": "炉霍县",
            "label": "炉霍县"
          },
          {
            "id": 2543,
            "pid": 2535,
            "value": "甘孜县",
            "label": "甘孜县"
          },
          {
            "id": 2544,
            "pid": 2535,
            "value": "新龙县",
            "label": "新龙县"
          },
          {
            "id": 2545,
            "pid": 2535,
            "value": "德格县",
            "label": "德格县"
          },
          {
            "id": 2546,
            "pid": 2535,
            "value": "白玉县",
            "label": "白玉县"
          },
          {
            "id": 2547,
            "pid": 2535,
            "value": "石渠县",
            "label": "石渠县"
          },
          {
            "id": 2548,
            "pid": 2535,
            "value": "色达县",
            "label": "色达县"
          },
          {
            "id": 2549,
            "pid": 2535,
            "value": "理塘县",
            "label": "理塘县"
          },
          {
            "id": 2550,
            "pid": 2535,
            "value": "巴塘县",
            "label": "巴塘县"
          },
          {
            "id": 2551,
            "pid": 2535,
            "value": "乡城县",
            "label": "乡城县"
          },
          {
            "id": 2552,
            "pid": 2535,
            "value": "稻城县",
            "label": "稻城县"
          },
          {
            "id": 2553,
            "pid": 2535,
            "value": "得荣县",
            "label": "得荣县"
          }
        ]
      },
      {
        "id": 2554,
        "pid": 2367,
        "value": "凉山彝族自治州",
        "label": "凉山彝族自治州",
        "children": [
          {
            "id": 2555,
            "pid": 2554,
            "value": "西昌市",
            "label": "西昌市"
          },
          {
            "id": 2556,
            "pid": 2554,
            "value": "木里藏族自治县",
            "label": "木里藏族自治县"
          },
          {
            "id": 2557,
            "pid": 2554,
            "value": "盐源县",
            "label": "盐源县"
          },
          {
            "id": 2558,
            "pid": 2554,
            "value": "德昌县",
            "label": "德昌县"
          },
          {
            "id": 2559,
            "pid": 2554,
            "value": "会理县",
            "label": "会理县"
          },
          {
            "id": 2560,
            "pid": 2554,
            "value": "会东县",
            "label": "会东县"
          },
          {
            "id": 2561,
            "pid": 2554,
            "value": "宁南县",
            "label": "宁南县"
          },
          {
            "id": 2562,
            "pid": 2554,
            "value": "普格县",
            "label": "普格县"
          },
          {
            "id": 2563,
            "pid": 2554,
            "value": "布拖县",
            "label": "布拖县"
          },
          {
            "id": 2564,
            "pid": 2554,
            "value": "金阳县",
            "label": "金阳县"
          },
          {
            "id": 2565,
            "pid": 2554,
            "value": "昭觉县",
            "label": "昭觉县"
          },
          {
            "id": 2566,
            "pid": 2554,
            "value": "喜德县",
            "label": "喜德县"
          },
          {
            "id": 2567,
            "pid": 2554,
            "value": "冕宁县",
            "label": "冕宁县"
          },
          {
            "id": 2568,
            "pid": 2554,
            "value": "越西县",
            "label": "越西县"
          },
          {
            "id": 2569,
            "pid": 2554,
            "value": "甘洛县",
            "label": "甘洛县"
          },
          {
            "id": 2570,
            "pid": 2554,
            "value": "美姑县",
            "label": "美姑县"
          },
          {
            "id": 2571,
            "pid": 2554,
            "value": "雷波县",
            "label": "雷波县"
          }
        ]
      }
    ]
  },
  {
    "id": 2572,
    "pid": 0,
    "value": "贵州省",
    "label": "贵州省",
    "children": [
      {
        "id": 2573,
        "pid": 2572,
        "value": "贵阳市",
        "label": "贵阳市",
        "children": [
          {
            "id": 2574,
            "pid": 2573,
            "value": "南明区",
            "label": "南明区"
          },
          {
            "id": 2575,
            "pid": 2573,
            "value": "云岩区",
            "label": "云岩区"
          },
          {
            "id": 2576,
            "pid": 2573,
            "value": "花溪区",
            "label": "花溪区"
          },
          {
            "id": 2577,
            "pid": 2573,
            "value": "乌当区",
            "label": "乌当区"
          },
          {
            "id": 2578,
            "pid": 2573,
            "value": "白云区",
            "label": "白云区"
          },
          {
            "id": 2579,
            "pid": 2573,
            "value": "观山湖区",
            "label": "观山湖区"
          },
          {
            "id": 2580,
            "pid": 2573,
            "value": "开阳县",
            "label": "开阳县"
          },
          {
            "id": 2581,
            "pid": 2573,
            "value": "息烽县",
            "label": "息烽县"
          },
          {
            "id": 2582,
            "pid": 2573,
            "value": "修文县",
            "label": "修文县"
          },
          {
            "id": 2583,
            "pid": 2573,
            "value": "清镇市",
            "label": "清镇市"
          }
        ]
      },
      {
        "id": 2584,
        "pid": 2572,
        "value": "六盘水市",
        "label": "六盘水市",
        "children": [
          {
            "id": 2585,
            "pid": 2584,
            "value": "钟山区",
            "label": "钟山区"
          },
          {
            "id": 2586,
            "pid": 2584,
            "value": "六枝特区",
            "label": "六枝特区"
          },
          {
            "id": 2587,
            "pid": 2584,
            "value": "水城县",
            "label": "水城县"
          },
          {
            "id": 2588,
            "pid": 2584,
            "value": "盘县",
            "label": "盘县"
          }
        ]
      },
      {
        "id": 2589,
        "pid": 2572,
        "value": "遵义市",
        "label": "遵义市",
        "children": [
          {
            "id": 2590,
            "pid": 2589,
            "value": "红花岗区",
            "label": "红花岗区"
          },
          {
            "id": 2591,
            "pid": 2589,
            "value": "汇川区",
            "label": "汇川区"
          },
          {
            "id": 2592,
            "pid": 2589,
            "value": "遵义县",
            "label": "遵义县"
          },
          {
            "id": 2593,
            "pid": 2589,
            "value": "桐梓县",
            "label": "桐梓县"
          },
          {
            "id": 2594,
            "pid": 2589,
            "value": "绥阳县",
            "label": "绥阳县"
          },
          {
            "id": 2595,
            "pid": 2589,
            "value": "正安县",
            "label": "正安县"
          },
          {
            "id": 2596,
            "pid": 2589,
            "value": "道真仡佬族苗族自治县",
            "label": "道真仡佬族苗族自治县"
          },
          {
            "id": 2597,
            "pid": 2589,
            "value": "务川仡佬族苗族自治县",
            "label": "务川仡佬族苗族自治县"
          },
          {
            "id": 2598,
            "pid": 2589,
            "value": "凤冈县",
            "label": "凤冈县"
          },
          {
            "id": 2599,
            "pid": 2589,
            "value": "湄潭县",
            "label": "湄潭县"
          },
          {
            "id": 2600,
            "pid": 2589,
            "value": "余庆县",
            "label": "余庆县"
          },
          {
            "id": 2601,
            "pid": 2589,
            "value": "习水县",
            "label": "习水县"
          },
          {
            "id": 2602,
            "pid": 2589,
            "value": "赤水市",
            "label": "赤水市"
          },
          {
            "id": 2603,
            "pid": 2589,
            "value": "仁怀市",
            "label": "仁怀市"
          }
        ]
      },
      {
        "id": 2604,
        "pid": 2572,
        "value": "安顺市",
        "label": "安顺市",
        "children": [
          {
            "id": 2605,
            "pid": 2604,
            "value": "西秀区",
            "label": "西秀区"
          },
          {
            "id": 2606,
            "pid": 2604,
            "value": "平坝区",
            "label": "平坝区"
          },
          {
            "id": 2607,
            "pid": 2604,
            "value": "普定县",
            "label": "普定县"
          },
          {
            "id": 2608,
            "pid": 2604,
            "value": "镇宁布依族苗族自治县",
            "label": "镇宁布依族苗族自治县"
          },
          {
            "id": 2609,
            "pid": 2604,
            "value": "关岭布依族苗族自治县",
            "label": "关岭布依族苗族自治县"
          },
          {
            "id": 2610,
            "pid": 2604,
            "value": "紫云苗族布依族自治县",
            "label": "紫云苗族布依族自治县"
          }
        ]
      },
      {
        "id": 2611,
        "pid": 2572,
        "value": "毕节市",
        "label": "毕节市",
        "children": [
          {
            "id": 2612,
            "pid": 2611,
            "value": "七星关区",
            "label": "七星关区"
          },
          {
            "id": 2613,
            "pid": 2611,
            "value": "大方县",
            "label": "大方县"
          },
          {
            "id": 2614,
            "pid": 2611,
            "value": "黔西县",
            "label": "黔西县"
          },
          {
            "id": 2615,
            "pid": 2611,
            "value": "金沙县",
            "label": "金沙县"
          },
          {
            "id": 2616,
            "pid": 2611,
            "value": "织金县",
            "label": "织金县"
          },
          {
            "id": 2617,
            "pid": 2611,
            "value": "纳雍县",
            "label": "纳雍县"
          },
          {
            "id": 2618,
            "pid": 2611,
            "value": "威宁彝族回族苗族自治县",
            "label": "威宁彝族回族苗族自治县"
          },
          {
            "id": 2619,
            "pid": 2611,
            "value": "赫章县",
            "label": "赫章县"
          }
        ]
      },
      {
        "id": 2620,
        "pid": 2572,
        "value": "铜仁市",
        "label": "铜仁市",
        "children": [
          {
            "id": 2621,
            "pid": 2620,
            "value": "碧江区",
            "label": "碧江区"
          },
          {
            "id": 2622,
            "pid": 2620,
            "value": "万山区",
            "label": "万山区"
          },
          {
            "id": 2623,
            "pid": 2620,
            "value": "江口县",
            "label": "江口县"
          },
          {
            "id": 2624,
            "pid": 2620,
            "value": "玉屏侗族自治县",
            "label": "玉屏侗族自治县"
          },
          {
            "id": 2625,
            "pid": 2620,
            "value": "石阡县",
            "label": "石阡县"
          },
          {
            "id": 2626,
            "pid": 2620,
            "value": "思南县",
            "label": "思南县"
          },
          {
            "id": 2627,
            "pid": 2620,
            "value": "印江土家族苗族自治县",
            "label": "印江土家族苗族自治县"
          },
          {
            "id": 2628,
            "pid": 2620,
            "value": "德江县",
            "label": "德江县"
          },
          {
            "id": 2629,
            "pid": 2620,
            "value": "沿河土家族自治县",
            "label": "沿河土家族自治县"
          },
          {
            "id": 2630,
            "pid": 2620,
            "value": "松桃苗族自治县",
            "label": "松桃苗族自治县"
          }
        ]
      },
      {
        "id": 2631,
        "pid": 2572,
        "value": "黔西南布依族苗族自治州",
        "label": "黔西南布依族苗族自治州",
        "children": [
          {
            "id": 2632,
            "pid": 2631,
            "value": "兴义市 ",
            "label": "兴义市 "
          },
          {
            "id": 2633,
            "pid": 2631,
            "value": "兴仁县",
            "label": "兴仁县"
          },
          {
            "id": 2634,
            "pid": 2631,
            "value": "普安县",
            "label": "普安县"
          },
          {
            "id": 2635,
            "pid": 2631,
            "value": "晴隆县",
            "label": "晴隆县"
          },
          {
            "id": 2636,
            "pid": 2631,
            "value": "贞丰县",
            "label": "贞丰县"
          },
          {
            "id": 2637,
            "pid": 2631,
            "value": "望谟县",
            "label": "望谟县"
          },
          {
            "id": 2638,
            "pid": 2631,
            "value": "册亨县",
            "label": "册亨县"
          },
          {
            "id": 2639,
            "pid": 2631,
            "value": "安龙县",
            "label": "安龙县"
          }
        ]
      },
      {
        "id": 2640,
        "pid": 2572,
        "value": "黔东南苗族侗族自治州",
        "label": "黔东南苗族侗族自治州",
        "children": [
          {
            "id": 2641,
            "pid": 2640,
            "value": "凯里市",
            "label": "凯里市"
          },
          {
            "id": 2642,
            "pid": 2640,
            "value": "黄平县",
            "label": "黄平县"
          },
          {
            "id": 2643,
            "pid": 2640,
            "value": "施秉县",
            "label": "施秉县"
          },
          {
            "id": 2644,
            "pid": 2640,
            "value": "三穗县",
            "label": "三穗县"
          },
          {
            "id": 2645,
            "pid": 2640,
            "value": "镇远县",
            "label": "镇远县"
          },
          {
            "id": 2646,
            "pid": 2640,
            "value": "岑巩县",
            "label": "岑巩县"
          },
          {
            "id": 2647,
            "pid": 2640,
            "value": "天柱县",
            "label": "天柱县"
          },
          {
            "id": 2648,
            "pid": 2640,
            "value": "锦屏县",
            "label": "锦屏县"
          },
          {
            "id": 2649,
            "pid": 2640,
            "value": "剑河县",
            "label": "剑河县"
          },
          {
            "id": 2650,
            "pid": 2640,
            "value": "台江县",
            "label": "台江县"
          },
          {
            "id": 2651,
            "pid": 2640,
            "value": "黎平县",
            "label": "黎平县"
          },
          {
            "id": 2652,
            "pid": 2640,
            "value": "榕江县",
            "label": "榕江县"
          },
          {
            "id": 2653,
            "pid": 2640,
            "value": "从江县",
            "label": "从江县"
          },
          {
            "id": 2654,
            "pid": 2640,
            "value": "雷山县",
            "label": "雷山县"
          },
          {
            "id": 2655,
            "pid": 2640,
            "value": "麻江县",
            "label": "麻江县"
          },
          {
            "id": 2656,
            "pid": 2640,
            "value": "丹寨县",
            "label": "丹寨县"
          }
        ]
      },
      {
        "id": 2657,
        "pid": 2572,
        "value": "黔南布依族苗族自治州",
        "label": "黔南布依族苗族自治州",
        "children": [
          {
            "id": 2658,
            "pid": 2657,
            "value": "都匀市",
            "label": "都匀市"
          },
          {
            "id": 2659,
            "pid": 2657,
            "value": "福泉市",
            "label": "福泉市"
          },
          {
            "id": 2660,
            "pid": 2657,
            "value": "荔波县",
            "label": "荔波县"
          },
          {
            "id": 2661,
            "pid": 2657,
            "value": "贵定县",
            "label": "贵定县"
          },
          {
            "id": 2662,
            "pid": 2657,
            "value": "瓮安县",
            "label": "瓮安县"
          },
          {
            "id": 2663,
            "pid": 2657,
            "value": "独山县",
            "label": "独山县"
          },
          {
            "id": 2664,
            "pid": 2657,
            "value": "平塘县",
            "label": "平塘县"
          },
          {
            "id": 2665,
            "pid": 2657,
            "value": "罗甸县",
            "label": "罗甸县"
          },
          {
            "id": 2666,
            "pid": 2657,
            "value": "长顺县",
            "label": "长顺县"
          },
          {
            "id": 2667,
            "pid": 2657,
            "value": "龙里县",
            "label": "龙里县"
          },
          {
            "id": 2668,
            "pid": 2657,
            "value": "惠水县",
            "label": "惠水县"
          },
          {
            "id": 2669,
            "pid": 2657,
            "value": "三都水族自治县",
            "label": "三都水族自治县"
          }
        ]
      }
    ]
  },
  {
    "id": 2670,
    "pid": 0,
    "value": "云南省",
    "label": "云南省",
    "children": [
      {
        "id": 2671,
        "pid": 2670,
        "value": "昆明市",
        "label": "昆明市",
        "children": [
          {
            "id": 2672,
            "pid": 2671,
            "value": "五华区",
            "label": "五华区"
          },
          {
            "id": 2673,
            "pid": 2671,
            "value": "盘龙区",
            "label": "盘龙区"
          },
          {
            "id": 2674,
            "pid": 2671,
            "value": "官渡区",
            "label": "官渡区"
          },
          {
            "id": 2675,
            "pid": 2671,
            "value": "西山区",
            "label": "西山区"
          },
          {
            "id": 2676,
            "pid": 2671,
            "value": "东川区",
            "label": "东川区"
          },
          {
            "id": 2677,
            "pid": 2671,
            "value": "呈贡区",
            "label": "呈贡区"
          },
          {
            "id": 2678,
            "pid": 2671,
            "value": "晋宁县",
            "label": "晋宁县"
          },
          {
            "id": 2679,
            "pid": 2671,
            "value": "富民县",
            "label": "富民县"
          },
          {
            "id": 2680,
            "pid": 2671,
            "value": "宜良县",
            "label": "宜良县"
          },
          {
            "id": 2681,
            "pid": 2671,
            "value": "石林彝族自治县",
            "label": "石林彝族自治县"
          },
          {
            "id": 2682,
            "pid": 2671,
            "value": "嵩明县",
            "label": "嵩明县"
          },
          {
            "id": 2683,
            "pid": 2671,
            "value": "禄劝彝族苗族自治县",
            "label": "禄劝彝族苗族自治县"
          },
          {
            "id": 2684,
            "pid": 2671,
            "value": "寻甸回族彝族自治县 ",
            "label": "寻甸回族彝族自治县 "
          },
          {
            "id": 2685,
            "pid": 2671,
            "value": "安宁市",
            "label": "安宁市"
          }
        ]
      },
      {
        "id": 2686,
        "pid": 2670,
        "value": "曲靖市",
        "label": "曲靖市",
        "children": [
          {
            "id": 2687,
            "pid": 2686,
            "value": "麒麟区",
            "label": "麒麟区"
          },
          {
            "id": 2688,
            "pid": 2686,
            "value": "马龙县",
            "label": "马龙县"
          },
          {
            "id": 2689,
            "pid": 2686,
            "value": "陆良县",
            "label": "陆良县"
          },
          {
            "id": 2690,
            "pid": 2686,
            "value": "师宗县",
            "label": "师宗县"
          },
          {
            "id": 2691,
            "pid": 2686,
            "value": "罗平县",
            "label": "罗平县"
          },
          {
            "id": 2692,
            "pid": 2686,
            "value": "富源县",
            "label": "富源县"
          },
          {
            "id": 2693,
            "pid": 2686,
            "value": "会泽县",
            "label": "会泽县"
          },
          {
            "id": 2694,
            "pid": 2686,
            "value": "沾益县",
            "label": "沾益县"
          },
          {
            "id": 2695,
            "pid": 2686,
            "value": "宣威市",
            "label": "宣威市"
          }
        ]
      },
      {
        "id": 2696,
        "pid": 2670,
        "value": "玉溪市",
        "label": "玉溪市",
        "children": [
          {
            "id": 2697,
            "pid": 2696,
            "value": "红塔区",
            "label": "红塔区"
          },
          {
            "id": 2698,
            "pid": 2696,
            "value": "江川县",
            "label": "江川县"
          },
          {
            "id": 2699,
            "pid": 2696,
            "value": "澄江县",
            "label": "澄江县"
          },
          {
            "id": 2700,
            "pid": 2696,
            "value": "通海县",
            "label": "通海县"
          },
          {
            "id": 2701,
            "pid": 2696,
            "value": "华宁县",
            "label": "华宁县"
          },
          {
            "id": 2702,
            "pid": 2696,
            "value": "易门县",
            "label": "易门县"
          },
          {
            "id": 2703,
            "pid": 2696,
            "value": "峨山彝族自治县",
            "label": "峨山彝族自治县"
          },
          {
            "id": 2704,
            "pid": 2696,
            "value": "新平彝族傣族自治县",
            "label": "新平彝族傣族自治县"
          },
          {
            "id": 2705,
            "pid": 2696,
            "value": "元江哈尼族彝族傣族自治县",
            "label": "元江哈尼族彝族傣族自治县"
          }
        ]
      },
      {
        "id": 2706,
        "pid": 2670,
        "value": "保山市",
        "label": "保山市",
        "children": [
          {
            "id": 2707,
            "pid": 2706,
            "value": "隆阳区",
            "label": "隆阳区"
          },
          {
            "id": 2708,
            "pid": 2706,
            "value": "施甸县",
            "label": "施甸县"
          },
          {
            "id": 2709,
            "pid": 2706,
            "value": "腾冲县",
            "label": "腾冲县"
          },
          {
            "id": 2710,
            "pid": 2706,
            "value": "龙陵县",
            "label": "龙陵县"
          },
          {
            "id": 2711,
            "pid": 2706,
            "value": "昌宁县",
            "label": "昌宁县"
          }
        ]
      },
      {
        "id": 2712,
        "pid": 2670,
        "value": "昭通市",
        "label": "昭通市",
        "children": [
          {
            "id": 2713,
            "pid": 2712,
            "value": "昭阳区",
            "label": "昭阳区"
          },
          {
            "id": 2714,
            "pid": 2712,
            "value": "鲁甸县",
            "label": "鲁甸县"
          },
          {
            "id": 2715,
            "pid": 2712,
            "value": "巧家县",
            "label": "巧家县"
          },
          {
            "id": 2716,
            "pid": 2712,
            "value": "盐津县",
            "label": "盐津县"
          },
          {
            "id": 2717,
            "pid": 2712,
            "value": "大关县",
            "label": "大关县"
          },
          {
            "id": 2718,
            "pid": 2712,
            "value": "永善县",
            "label": "永善县"
          },
          {
            "id": 2719,
            "pid": 2712,
            "value": "绥江县",
            "label": "绥江县"
          },
          {
            "id": 2720,
            "pid": 2712,
            "value": "镇雄县",
            "label": "镇雄县"
          },
          {
            "id": 2721,
            "pid": 2712,
            "value": "彝良县",
            "label": "彝良县"
          },
          {
            "id": 2722,
            "pid": 2712,
            "value": "威信县",
            "label": "威信县"
          },
          {
            "id": 2723,
            "pid": 2712,
            "value": "水富县",
            "label": "水富县"
          }
        ]
      },
      {
        "id": 2724,
        "pid": 2670,
        "value": "丽江市",
        "label": "丽江市",
        "children": [
          {
            "id": 2725,
            "pid": 2724,
            "value": "古城区",
            "label": "古城区"
          },
          {
            "id": 2726,
            "pid": 2724,
            "value": "玉龙纳西族自治县",
            "label": "玉龙纳西族自治县"
          },
          {
            "id": 2727,
            "pid": 2724,
            "value": "永胜县",
            "label": "永胜县"
          },
          {
            "id": 2728,
            "pid": 2724,
            "value": "华坪县",
            "label": "华坪县"
          },
          {
            "id": 2729,
            "pid": 2724,
            "value": "宁蒗彝族自治县",
            "label": "宁蒗彝族自治县"
          }
        ]
      },
      {
        "id": 2730,
        "pid": 2670,
        "value": "普洱市",
        "label": "普洱市",
        "children": [
          {
            "id": 2731,
            "pid": 2730,
            "value": "思茅区",
            "label": "思茅区"
          },
          {
            "id": 2732,
            "pid": 2730,
            "value": "宁洱哈尼族彝族自治县",
            "label": "宁洱哈尼族彝族自治县"
          },
          {
            "id": 2733,
            "pid": 2730,
            "value": "墨江哈尼族自治县",
            "label": "墨江哈尼族自治县"
          },
          {
            "id": 2734,
            "pid": 2730,
            "value": "景东彝族自治县",
            "label": "景东彝族自治县"
          },
          {
            "id": 2735,
            "pid": 2730,
            "value": "景谷傣族彝族自治县",
            "label": "景谷傣族彝族自治县"
          },
          {
            "id": 2736,
            "pid": 2730,
            "value": "镇沅彝族哈尼族拉祜族自治县",
            "label": "镇沅彝族哈尼族拉祜族自治县"
          },
          {
            "id": 2737,
            "pid": 2730,
            "value": "江城哈尼族彝族自治县",
            "label": "江城哈尼族彝族自治县"
          },
          {
            "id": 2738,
            "pid": 2730,
            "value": "孟连傣族拉祜族佤族自治县",
            "label": "孟连傣族拉祜族佤族自治县"
          },
          {
            "id": 2739,
            "pid": 2730,
            "value": "澜沧拉祜族自治县",
            "label": "澜沧拉祜族自治县"
          },
          {
            "id": 2740,
            "pid": 2730,
            "value": "西盟佤族自治县",
            "label": "西盟佤族自治县"
          }
        ]
      },
      {
        "id": 2741,
        "pid": 2670,
        "value": "临沧市",
        "label": "临沧市",
        "children": [
          {
            "id": 2742,
            "pid": 2741,
            "value": "临翔区",
            "label": "临翔区"
          },
          {
            "id": 2743,
            "pid": 2741,
            "value": "凤庆县",
            "label": "凤庆县"
          },
          {
            "id": 2744,
            "pid": 2741,
            "value": "云县",
            "label": "云县"
          },
          {
            "id": 2745,
            "pid": 2741,
            "value": "永德县",
            "label": "永德县"
          },
          {
            "id": 2746,
            "pid": 2741,
            "value": "镇康县",
            "label": "镇康县"
          },
          {
            "id": 2747,
            "pid": 2741,
            "value": "双江拉祜族佤族布朗族傣族自治县",
            "label": "双江拉祜族佤族布朗族傣族自治县"
          },
          {
            "id": 2748,
            "pid": 2741,
            "value": "耿马傣族佤族自治县",
            "label": "耿马傣族佤族自治县"
          },
          {
            "id": 2749,
            "pid": 2741,
            "value": "沧源佤族自治县",
            "label": "沧源佤族自治县"
          }
        ]
      },
      {
        "id": 2750,
        "pid": 2670,
        "value": "楚雄彝族自治州",
        "label": "楚雄彝族自治州",
        "children": [
          {
            "id": 2751,
            "pid": 2750,
            "value": "楚雄市",
            "label": "楚雄市"
          },
          {
            "id": 2752,
            "pid": 2750,
            "value": "双柏县",
            "label": "双柏县"
          },
          {
            "id": 2753,
            "pid": 2750,
            "value": "牟定县",
            "label": "牟定县"
          },
          {
            "id": 2754,
            "pid": 2750,
            "value": "南华县",
            "label": "南华县"
          },
          {
            "id": 2755,
            "pid": 2750,
            "value": "姚安县",
            "label": "姚安县"
          },
          {
            "id": 2756,
            "pid": 2750,
            "value": "大姚县",
            "label": "大姚县"
          },
          {
            "id": 2757,
            "pid": 2750,
            "value": "永仁县",
            "label": "永仁县"
          },
          {
            "id": 2758,
            "pid": 2750,
            "value": "元谋县",
            "label": "元谋县"
          },
          {
            "id": 2759,
            "pid": 2750,
            "value": "武定县",
            "label": "武定县"
          },
          {
            "id": 2760,
            "pid": 2750,
            "value": "禄丰县",
            "label": "禄丰县"
          }
        ]
      },
      {
        "id": 2761,
        "pid": 2670,
        "value": "红河哈尼族彝族自治州",
        "label": "红河哈尼族彝族自治州",
        "children": [
          {
            "id": 2762,
            "pid": 2761,
            "value": "个旧市",
            "label": "个旧市"
          },
          {
            "id": 2763,
            "pid": 2761,
            "value": "开远市",
            "label": "开远市"
          },
          {
            "id": 2764,
            "pid": 2761,
            "value": "蒙自市",
            "label": "蒙自市"
          },
          {
            "id": 2765,
            "pid": 2761,
            "value": "弥勒市",
            "label": "弥勒市"
          },
          {
            "id": 2766,
            "pid": 2761,
            "value": "屏边苗族自治县",
            "label": "屏边苗族自治县"
          },
          {
            "id": 2767,
            "pid": 2761,
            "value": "建水县",
            "label": "建水县"
          },
          {
            "id": 2768,
            "pid": 2761,
            "value": "石屏县",
            "label": "石屏县"
          },
          {
            "id": 2769,
            "pid": 2761,
            "value": "泸西县",
            "label": "泸西县"
          },
          {
            "id": 2770,
            "pid": 2761,
            "value": "元阳县",
            "label": "元阳县"
          },
          {
            "id": 2771,
            "pid": 2761,
            "value": "红河县",
            "label": "红河县"
          },
          {
            "id": 2772,
            "pid": 2761,
            "value": "金平苗族瑶族傣族自治县",
            "label": "金平苗族瑶族傣族自治县"
          },
          {
            "id": 2773,
            "pid": 2761,
            "value": "绿春县",
            "label": "绿春县"
          },
          {
            "id": 2774,
            "pid": 2761,
            "value": "河口瑶族自治县",
            "label": "河口瑶族自治县"
          }
        ]
      },
      {
        "id": 2775,
        "pid": 2670,
        "value": "文山壮族苗族自治州",
        "label": "文山壮族苗族自治州",
        "children": [
          {
            "id": 2776,
            "pid": 2775,
            "value": "文山市",
            "label": "文山市"
          },
          {
            "id": 2777,
            "pid": 2775,
            "value": "砚山县",
            "label": "砚山县"
          },
          {
            "id": 2778,
            "pid": 2775,
            "value": "西畴县",
            "label": "西畴县"
          },
          {
            "id": 2779,
            "pid": 2775,
            "value": "麻栗坡县",
            "label": "麻栗坡县"
          },
          {
            "id": 2780,
            "pid": 2775,
            "value": "马关县",
            "label": "马关县"
          },
          {
            "id": 2781,
            "pid": 2775,
            "value": "丘北县",
            "label": "丘北县"
          },
          {
            "id": 2782,
            "pid": 2775,
            "value": "广南县",
            "label": "广南县"
          },
          {
            "id": 2783,
            "pid": 2775,
            "value": "富宁县",
            "label": "富宁县"
          }
        ]
      },
      {
        "id": 2784,
        "pid": 2670,
        "value": "西双版纳傣族自治州",
        "label": "西双版纳傣族自治州",
        "children": [
          {
            "id": 2785,
            "pid": 2784,
            "value": "景洪市",
            "label": "景洪市"
          },
          {
            "id": 2786,
            "pid": 2784,
            "value": "勐海县",
            "label": "勐海县"
          },
          {
            "id": 2787,
            "pid": 2784,
            "value": "勐腊县",
            "label": "勐腊县"
          }
        ]
      },
      {
        "id": 2788,
        "pid": 2670,
        "value": "大理白族自治州",
        "label": "大理白族自治州",
        "children": [
          {
            "id": 2789,
            "pid": 2788,
            "value": "大理市",
            "label": "大理市"
          },
          {
            "id": 2790,
            "pid": 2788,
            "value": "漾濞彝族自治县",
            "label": "漾濞彝族自治县"
          },
          {
            "id": 2791,
            "pid": 2788,
            "value": "祥云县",
            "label": "祥云县"
          },
          {
            "id": 2792,
            "pid": 2788,
            "value": "宾川县",
            "label": "宾川县"
          },
          {
            "id": 2793,
            "pid": 2788,
            "value": "弥渡县",
            "label": "弥渡县"
          },
          {
            "id": 2794,
            "pid": 2788,
            "value": "南涧彝族自治县",
            "label": "南涧彝族自治县"
          },
          {
            "id": 2795,
            "pid": 2788,
            "value": "巍山彝族回族自治县",
            "label": "巍山彝族回族自治县"
          },
          {
            "id": 2796,
            "pid": 2788,
            "value": "永平县",
            "label": "永平县"
          },
          {
            "id": 2797,
            "pid": 2788,
            "value": "云龙县",
            "label": "云龙县"
          },
          {
            "id": 2798,
            "pid": 2788,
            "value": "洱源县",
            "label": "洱源县"
          },
          {
            "id": 2799,
            "pid": 2788,
            "value": "剑川县",
            "label": "剑川县"
          },
          {
            "id": 2800,
            "pid": 2788,
            "value": "鹤庆县",
            "label": "鹤庆县"
          }
        ]
      },
      {
        "id": 2801,
        "pid": 2670,
        "value": "德宏傣族景颇族自治州",
        "label": "德宏傣族景颇族自治州",
        "children": [
          {
            "id": 2802,
            "pid": 2801,
            "value": "瑞丽市",
            "label": "瑞丽市"
          },
          {
            "id": 2803,
            "pid": 2801,
            "value": "芒市",
            "label": "芒市"
          },
          {
            "id": 2804,
            "pid": 2801,
            "value": "梁河县",
            "label": "梁河县"
          },
          {
            "id": 2805,
            "pid": 2801,
            "value": "盈江县",
            "label": "盈江县"
          },
          {
            "id": 2806,
            "pid": 2801,
            "value": "陇川县",
            "label": "陇川县"
          }
        ]
      },
      {
        "id": 2807,
        "pid": 2670,
        "value": "怒江傈僳族自治州",
        "label": "怒江傈僳族自治州",
        "children": [
          {
            "id": 2808,
            "pid": 2807,
            "value": "泸水县",
            "label": "泸水县"
          },
          {
            "id": 2809,
            "pid": 2807,
            "value": "福贡县",
            "label": "福贡县"
          },
          {
            "id": 2810,
            "pid": 2807,
            "value": "贡山独龙族怒族自治县",
            "label": "贡山独龙族怒族自治县"
          },
          {
            "id": 2811,
            "pid": 2807,
            "value": "兰坪白族普米族自治县",
            "label": "兰坪白族普米族自治县"
          }
        ]
      },
      {
        "id": 2812,
        "pid": 2670,
        "value": "迪庆藏族自治州",
        "label": "迪庆藏族自治州",
        "children": [
          {
            "id": 2813,
            "pid": 2812,
            "value": "香格里拉市",
            "label": "香格里拉市"
          },
          {
            "id": 2814,
            "pid": 2812,
            "value": "德钦县",
            "label": "德钦县"
          },
          {
            "id": 2815,
            "pid": 2812,
            "value": "维西傈僳族自治县",
            "label": "维西傈僳族自治县"
          }
        ]
      }
    ]
  },
  {
    "id": 2816,
    "pid": 0,
    "value": "西藏自治区",
    "label": "西藏自治区",
    "children": [
      {
        "id": 2817,
        "pid": 2816,
        "value": "拉萨市",
        "label": "拉萨市",
        "children": [
          {
            "id": 2818,
            "pid": 2817,
            "value": "城关区",
            "label": "城关区"
          },
          {
            "id": 2819,
            "pid": 2817,
            "value": "林周县",
            "label": "林周县"
          },
          {
            "id": 2820,
            "pid": 2817,
            "value": "当雄县",
            "label": "当雄县"
          },
          {
            "id": 2821,
            "pid": 2817,
            "value": "尼木县",
            "label": "尼木县"
          },
          {
            "id": 2822,
            "pid": 2817,
            "value": "曲水县",
            "label": "曲水县"
          },
          {
            "id": 2823,
            "pid": 2817,
            "value": "堆龙德庆县",
            "label": "堆龙德庆县"
          },
          {
            "id": 2824,
            "pid": 2817,
            "value": "达孜县",
            "label": "达孜县"
          },
          {
            "id": 2825,
            "pid": 2817,
            "value": "墨竹工卡县",
            "label": "墨竹工卡县"
          }
        ]
      },
      {
        "id": 2826,
        "pid": 2816,
        "value": "日喀则市",
        "label": "日喀则市",
        "children": [
          {
            "id": 2827,
            "pid": 2826,
            "value": "桑珠孜区",
            "label": "桑珠孜区"
          },
          {
            "id": 2828,
            "pid": 2826,
            "value": "南木林县",
            "label": "南木林县"
          },
          {
            "id": 2829,
            "pid": 2826,
            "value": "江孜县",
            "label": "江孜县"
          },
          {
            "id": 2830,
            "pid": 2826,
            "value": "定日县",
            "label": "定日县"
          },
          {
            "id": 2831,
            "pid": 2826,
            "value": "萨迦县",
            "label": "萨迦县"
          },
          {
            "id": 2832,
            "pid": 2826,
            "value": "拉孜县",
            "label": "拉孜县"
          },
          {
            "id": 2833,
            "pid": 2826,
            "value": "昂仁县",
            "label": "昂仁县"
          },
          {
            "id": 2834,
            "pid": 2826,
            "value": "谢通门县",
            "label": "谢通门县"
          },
          {
            "id": 2835,
            "pid": 2826,
            "value": "白朗县",
            "label": "白朗县"
          },
          {
            "id": 2836,
            "pid": 2826,
            "value": "仁布县",
            "label": "仁布县"
          },
          {
            "id": 2837,
            "pid": 2826,
            "value": "康马县",
            "label": "康马县"
          },
          {
            "id": 2838,
            "pid": 2826,
            "value": "定结县",
            "label": "定结县"
          },
          {
            "id": 2839,
            "pid": 2826,
            "value": "仲巴县",
            "label": "仲巴县"
          },
          {
            "id": 2840,
            "pid": 2826,
            "value": "亚东县",
            "label": "亚东县"
          },
          {
            "id": 2841,
            "pid": 2826,
            "value": "吉隆县",
            "label": "吉隆县"
          },
          {
            "id": 2842,
            "pid": 2826,
            "value": "聂拉木县",
            "label": "聂拉木县"
          },
          {
            "id": 2843,
            "pid": 2826,
            "value": "萨嘎县",
            "label": "萨嘎县"
          },
          {
            "id": 2844,
            "pid": 2826,
            "value": "岗巴县",
            "label": "岗巴县"
          }
        ]
      },
      {
        "id": 2845,
        "pid": 2816,
        "value": "昌都市",
        "label": "昌都市",
        "children": [
          {
            "id": 2846,
            "pid": 2845,
            "value": "卡若区",
            "label": "卡若区"
          },
          {
            "id": 2847,
            "pid": 2845,
            "value": "江达县",
            "label": "江达县"
          },
          {
            "id": 2848,
            "pid": 2845,
            "value": "贡觉县",
            "label": "贡觉县"
          },
          {
            "id": 2849,
            "pid": 2845,
            "value": "类乌齐县",
            "label": "类乌齐县"
          },
          {
            "id": 2850,
            "pid": 2845,
            "value": "丁青县",
            "label": "丁青县"
          },
          {
            "id": 2851,
            "pid": 2845,
            "value": "察雅县",
            "label": "察雅县"
          },
          {
            "id": 2852,
            "pid": 2845,
            "value": "八宿县",
            "label": "八宿县"
          },
          {
            "id": 2853,
            "pid": 2845,
            "value": "左贡县",
            "label": "左贡县"
          },
          {
            "id": 2854,
            "pid": 2845,
            "value": "芒康县",
            "label": "芒康县"
          },
          {
            "id": 2855,
            "pid": 2845,
            "value": "洛隆县",
            "label": "洛隆县"
          },
          {
            "id": 2856,
            "pid": 2845,
            "value": "边坝县",
            "label": "边坝县"
          }
        ]
      },
      {
        "id": 2857,
        "pid": 2816,
        "value": "山南地区",
        "label": "山南地区",
        "children": [
          {
            "id": 2858,
            "pid": 2857,
            "value": "乃东县",
            "label": "乃东县"
          },
          {
            "id": 2859,
            "pid": 2857,
            "value": "扎囊县",
            "label": "扎囊县"
          },
          {
            "id": 2860,
            "pid": 2857,
            "value": "贡嘎县",
            "label": "贡嘎县"
          },
          {
            "id": 2861,
            "pid": 2857,
            "value": "桑日县",
            "label": "桑日县"
          },
          {
            "id": 2862,
            "pid": 2857,
            "value": "琼结县",
            "label": "琼结县"
          },
          {
            "id": 2863,
            "pid": 2857,
            "value": "曲松县",
            "label": "曲松县"
          },
          {
            "id": 2864,
            "pid": 2857,
            "value": "措美县",
            "label": "措美县"
          },
          {
            "id": 2865,
            "pid": 2857,
            "value": "洛扎县",
            "label": "洛扎县"
          },
          {
            "id": 2866,
            "pid": 2857,
            "value": "加查县",
            "label": "加查县"
          },
          {
            "id": 2867,
            "pid": 2857,
            "value": "隆子县",
            "label": "隆子县"
          },
          {
            "id": 2868,
            "pid": 2857,
            "value": "错那县",
            "label": "错那县"
          },
          {
            "id": 2869,
            "pid": 2857,
            "value": "浪卡子县",
            "label": "浪卡子县"
          }
        ]
      },
      {
        "id": 2870,
        "pid": 2816,
        "value": "那曲地区",
        "label": "那曲地区",
        "children": [
          {
            "id": 2871,
            "pid": 2870,
            "value": "那曲县",
            "label": "那曲县"
          },
          {
            "id": 2872,
            "pid": 2870,
            "value": "嘉黎县",
            "label": "嘉黎县"
          },
          {
            "id": 2873,
            "pid": 2870,
            "value": "比如县",
            "label": "比如县"
          },
          {
            "id": 2874,
            "pid": 2870,
            "value": "聂荣县",
            "label": "聂荣县"
          },
          {
            "id": 2875,
            "pid": 2870,
            "value": "安多县",
            "label": "安多县"
          },
          {
            "id": 2876,
            "pid": 2870,
            "value": "申扎县",
            "label": "申扎县"
          },
          {
            "id": 2877,
            "pid": 2870,
            "value": "索县",
            "label": "索县"
          },
          {
            "id": 2878,
            "pid": 2870,
            "value": "班戈县",
            "label": "班戈县"
          },
          {
            "id": 2879,
            "pid": 2870,
            "value": "巴青县",
            "label": "巴青县"
          },
          {
            "id": 2880,
            "pid": 2870,
            "value": "尼玛县",
            "label": "尼玛县"
          },
          {
            "id": 2881,
            "pid": 2870,
            "value": "双湖县",
            "label": "双湖县"
          }
        ]
      },
      {
        "id": 2882,
        "pid": 2816,
        "value": "阿里地区",
        "label": "阿里地区",
        "children": [
          {
            "id": 2883,
            "pid": 2882,
            "value": "普兰县",
            "label": "普兰县"
          },
          {
            "id": 2884,
            "pid": 2882,
            "value": "札达县",
            "label": "札达县"
          },
          {
            "id": 2885,
            "pid": 2882,
            "value": "噶尔县",
            "label": "噶尔县"
          },
          {
            "id": 2886,
            "pid": 2882,
            "value": "日土县",
            "label": "日土县"
          },
          {
            "id": 2887,
            "pid": 2882,
            "value": "革吉县",
            "label": "革吉县"
          },
          {
            "id": 2888,
            "pid": 2882,
            "value": "改则县",
            "label": "改则县"
          },
          {
            "id": 2889,
            "pid": 2882,
            "value": "措勤县",
            "label": "措勤县"
          }
        ]
      },
      {
        "id": 2890,
        "pid": 2816,
        "value": "林芝地区",
        "label": "林芝地区",
        "children": [
          {
            "id": 2891,
            "pid": 2890,
            "value": "林芝县",
            "label": "林芝县"
          },
          {
            "id": 2892,
            "pid": 2890,
            "value": "工布江达县",
            "label": "工布江达县"
          },
          {
            "id": 2893,
            "pid": 2890,
            "value": "米林县",
            "label": "米林县"
          },
          {
            "id": 2894,
            "pid": 2890,
            "value": "墨脱县",
            "label": "墨脱县"
          },
          {
            "id": 2895,
            "pid": 2890,
            "value": "波密县",
            "label": "波密县"
          },
          {
            "id": 2896,
            "pid": 2890,
            "value": "察隅县",
            "label": "察隅县"
          },
          {
            "id": 2897,
            "pid": 2890,
            "value": "朗县",
            "label": "朗县"
          }
        ]
      }
    ]
  },
  {
    "id": 2898,
    "pid": 0,
    "value": "陕西省",
    "label": "陕西省",
    "children": [
      {
        "id": 2899,
        "pid": 2898,
        "value": "西安市",
        "label": "西安市",
        "children": [
          {
            "id": 2900,
            "pid": 2899,
            "value": "新城区",
            "label": "新城区"
          },
          {
            "id": 2901,
            "pid": 2899,
            "value": "碑林区",
            "label": "碑林区"
          },
          {
            "id": 2902,
            "pid": 2899,
            "value": "莲湖区",
            "label": "莲湖区"
          },
          {
            "id": 2903,
            "pid": 2899,
            "value": "灞桥区",
            "label": "灞桥区"
          },
          {
            "id": 2904,
            "pid": 2899,
            "value": "未央区",
            "label": "未央区"
          },
          {
            "id": 2905,
            "pid": 2899,
            "value": "雁塔区",
            "label": "雁塔区"
          },
          {
            "id": 2906,
            "pid": 2899,
            "value": "阎良区",
            "label": "阎良区"
          },
          {
            "id": 2907,
            "pid": 2899,
            "value": "临潼区",
            "label": "临潼区"
          },
          {
            "id": 2908,
            "pid": 2899,
            "value": "长安区",
            "label": "长安区"
          },
          {
            "id": 2909,
            "pid": 2899,
            "value": "蓝田县",
            "label": "蓝田县"
          },
          {
            "id": 2910,
            "pid": 2899,
            "value": "周至县",
            "label": "周至县"
          },
          {
            "id": 2911,
            "pid": 2899,
            "value": "户县",
            "label": "户县"
          },
          {
            "id": 2912,
            "pid": 2899,
            "value": "高陵区",
            "label": "高陵区"
          }
        ]
      },
      {
        "id": 2913,
        "pid": 2898,
        "value": "铜川市",
        "label": "铜川市",
        "children": [
          {
            "id": 2914,
            "pid": 2913,
            "value": "王益区",
            "label": "王益区"
          },
          {
            "id": 2915,
            "pid": 2913,
            "value": "印台区",
            "label": "印台区"
          },
          {
            "id": 2916,
            "pid": 2913,
            "value": "耀州区",
            "label": "耀州区"
          },
          {
            "id": 2917,
            "pid": 2913,
            "value": "宜君县",
            "label": "宜君县"
          }
        ]
      },
      {
        "id": 2918,
        "pid": 2898,
        "value": "宝鸡市",
        "label": "宝鸡市",
        "children": [
          {
            "id": 2919,
            "pid": 2918,
            "value": "渭滨区",
            "label": "渭滨区"
          },
          {
            "id": 2920,
            "pid": 2918,
            "value": "金台区",
            "label": "金台区"
          },
          {
            "id": 2921,
            "pid": 2918,
            "value": "陈仓区",
            "label": "陈仓区"
          },
          {
            "id": 2922,
            "pid": 2918,
            "value": "凤翔县",
            "label": "凤翔县"
          },
          {
            "id": 2923,
            "pid": 2918,
            "value": "岐山县",
            "label": "岐山县"
          },
          {
            "id": 2924,
            "pid": 2918,
            "value": "扶风县",
            "label": "扶风县"
          },
          {
            "id": 2925,
            "pid": 2918,
            "value": "眉县",
            "label": "眉县"
          },
          {
            "id": 2926,
            "pid": 2918,
            "value": "陇县",
            "label": "陇县"
          },
          {
            "id": 2927,
            "pid": 2918,
            "value": "千阳县",
            "label": "千阳县"
          },
          {
            "id": 2928,
            "pid": 2918,
            "value": "麟游县",
            "label": "麟游县"
          },
          {
            "id": 2929,
            "pid": 2918,
            "value": "凤县",
            "label": "凤县"
          },
          {
            "id": 2930,
            "pid": 2918,
            "value": "太白县",
            "label": "太白县"
          }
        ]
      },
      {
        "id": 2931,
        "pid": 2898,
        "value": "咸阳市",
        "label": "咸阳市",
        "children": [
          {
            "id": 2932,
            "pid": 2931,
            "value": "秦都区",
            "label": "秦都区"
          },
          {
            "id": 2933,
            "pid": 2931,
            "value": "杨陵区",
            "label": "杨陵区"
          },
          {
            "id": 2934,
            "pid": 2931,
            "value": "渭城区",
            "label": "渭城区"
          },
          {
            "id": 2935,
            "pid": 2931,
            "value": "三原县",
            "label": "三原县"
          },
          {
            "id": 2936,
            "pid": 2931,
            "value": "泾阳县",
            "label": "泾阳县"
          },
          {
            "id": 2937,
            "pid": 2931,
            "value": "乾县",
            "label": "乾县"
          },
          {
            "id": 2938,
            "pid": 2931,
            "value": "礼泉县",
            "label": "礼泉县"
          },
          {
            "id": 2939,
            "pid": 2931,
            "value": "永寿县",
            "label": "永寿县"
          },
          {
            "id": 2940,
            "pid": 2931,
            "value": "彬县",
            "label": "彬县"
          },
          {
            "id": 2941,
            "pid": 2931,
            "value": "长武县",
            "label": "长武县"
          },
          {
            "id": 2942,
            "pid": 2931,
            "value": "旬邑县",
            "label": "旬邑县"
          },
          {
            "id": 2943,
            "pid": 2931,
            "value": "淳化县",
            "label": "淳化县"
          },
          {
            "id": 2944,
            "pid": 2931,
            "value": "武功县",
            "label": "武功县"
          },
          {
            "id": 2945,
            "pid": 2931,
            "value": "兴平市",
            "label": "兴平市"
          }
        ]
      },
      {
        "id": 2946,
        "pid": 2898,
        "value": "渭南市",
        "label": "渭南市",
        "children": [
          {
            "id": 2947,
            "pid": 2946,
            "value": "临渭区",
            "label": "临渭区"
          },
          {
            "id": 2948,
            "pid": 2946,
            "value": "华县",
            "label": "华县"
          },
          {
            "id": 2949,
            "pid": 2946,
            "value": "潼关县",
            "label": "潼关县"
          },
          {
            "id": 2950,
            "pid": 2946,
            "value": "大荔县",
            "label": "大荔县"
          },
          {
            "id": 2951,
            "pid": 2946,
            "value": "合阳县",
            "label": "合阳县"
          },
          {
            "id": 2952,
            "pid": 2946,
            "value": "澄城县",
            "label": "澄城县"
          },
          {
            "id": 2953,
            "pid": 2946,
            "value": "蒲城县",
            "label": "蒲城县"
          },
          {
            "id": 2954,
            "pid": 2946,
            "value": "白水县",
            "label": "白水县"
          },
          {
            "id": 2955,
            "pid": 2946,
            "value": "富平县",
            "label": "富平县"
          },
          {
            "id": 2956,
            "pid": 2946,
            "value": "韩城市",
            "label": "韩城市"
          },
          {
            "id": 2957,
            "pid": 2946,
            "value": "华阴市",
            "label": "华阴市"
          }
        ]
      },
      {
        "id": 2958,
        "pid": 2898,
        "value": "延安市",
        "label": "延安市",
        "children": [
          {
            "id": 2959,
            "pid": 2958,
            "value": "宝塔区",
            "label": "宝塔区"
          },
          {
            "id": 2960,
            "pid": 2958,
            "value": "延长县",
            "label": "延长县"
          },
          {
            "id": 2961,
            "pid": 2958,
            "value": "延川县",
            "label": "延川县"
          },
          {
            "id": 2962,
            "pid": 2958,
            "value": "子长县",
            "label": "子长县"
          },
          {
            "id": 2963,
            "pid": 2958,
            "value": "安塞县",
            "label": "安塞县"
          },
          {
            "id": 2964,
            "pid": 2958,
            "value": "志丹县",
            "label": "志丹县"
          },
          {
            "id": 2965,
            "pid": 2958,
            "value": "吴起县",
            "label": "吴起县"
          },
          {
            "id": 2966,
            "pid": 2958,
            "value": "甘泉县",
            "label": "甘泉县"
          },
          {
            "id": 2967,
            "pid": 2958,
            "value": "富县",
            "label": "富县"
          },
          {
            "id": 2968,
            "pid": 2958,
            "value": "洛川县",
            "label": "洛川县"
          },
          {
            "id": 2969,
            "pid": 2958,
            "value": "宜川县",
            "label": "宜川县"
          },
          {
            "id": 2970,
            "pid": 2958,
            "value": "黄龙县",
            "label": "黄龙县"
          },
          {
            "id": 2971,
            "pid": 2958,
            "value": "黄陵县",
            "label": "黄陵县"
          }
        ]
      },
      {
        "id": 2972,
        "pid": 2898,
        "value": "汉中市",
        "label": "汉中市",
        "children": [
          {
            "id": 2973,
            "pid": 2972,
            "value": "汉台区",
            "label": "汉台区"
          },
          {
            "id": 2974,
            "pid": 2972,
            "value": "南郑县",
            "label": "南郑县"
          },
          {
            "id": 2975,
            "pid": 2972,
            "value": "城固县",
            "label": "城固县"
          },
          {
            "id": 2976,
            "pid": 2972,
            "value": "洋县",
            "label": "洋县"
          },
          {
            "id": 2977,
            "pid": 2972,
            "value": "西乡县",
            "label": "西乡县"
          },
          {
            "id": 2978,
            "pid": 2972,
            "value": "勉县",
            "label": "勉县"
          },
          {
            "id": 2979,
            "pid": 2972,
            "value": "宁强县",
            "label": "宁强县"
          },
          {
            "id": 2980,
            "pid": 2972,
            "value": "略阳县",
            "label": "略阳县"
          },
          {
            "id": 2981,
            "pid": 2972,
            "value": "镇巴县",
            "label": "镇巴县"
          },
          {
            "id": 2982,
            "pid": 2972,
            "value": "留坝县",
            "label": "留坝县"
          },
          {
            "id": 2983,
            "pid": 2972,
            "value": "佛坪县",
            "label": "佛坪县"
          }
        ]
      },
      {
        "id": 2984,
        "pid": 2898,
        "value": "榆林市",
        "label": "榆林市",
        "children": [
          {
            "id": 2985,
            "pid": 2984,
            "value": "榆阳区",
            "label": "榆阳区"
          },
          {
            "id": 2986,
            "pid": 2984,
            "value": "神木县",
            "label": "神木县"
          },
          {
            "id": 2987,
            "pid": 2984,
            "value": "府谷县",
            "label": "府谷县"
          },
          {
            "id": 2988,
            "pid": 2984,
            "value": "横山县",
            "label": "横山县"
          },
          {
            "id": 2989,
            "pid": 2984,
            "value": "靖边县",
            "label": "靖边县"
          },
          {
            "id": 2990,
            "pid": 2984,
            "value": "定边县",
            "label": "定边县"
          },
          {
            "id": 2991,
            "pid": 2984,
            "value": "绥德县",
            "label": "绥德县"
          },
          {
            "id": 2992,
            "pid": 2984,
            "value": "米脂县",
            "label": "米脂县"
          },
          {
            "id": 2993,
            "pid": 2984,
            "value": "佳县",
            "label": "佳县"
          },
          {
            "id": 2994,
            "pid": 2984,
            "value": "吴堡县",
            "label": "吴堡县"
          },
          {
            "id": 2995,
            "pid": 2984,
            "value": "清涧县",
            "label": "清涧县"
          },
          {
            "id": 2996,
            "pid": 2984,
            "value": "子洲县",
            "label": "子洲县"
          }
        ]
      },
      {
        "id": 2997,
        "pid": 2898,
        "value": "安康市",
        "label": "安康市",
        "children": [
          {
            "id": 2998,
            "pid": 2997,
            "value": "汉滨区",
            "label": "汉滨区"
          },
          {
            "id": 2999,
            "pid": 2997,
            "value": "汉阴县",
            "label": "汉阴县"
          },
          {
            "id": 3000,
            "pid": 2997,
            "value": "石泉县",
            "label": "石泉县"
          },
          {
            "id": 3001,
            "pid": 2997,
            "value": "宁陕县",
            "label": "宁陕县"
          },
          {
            "id": 3002,
            "pid": 2997,
            "value": "紫阳县",
            "label": "紫阳县"
          },
          {
            "id": 3003,
            "pid": 2997,
            "value": "岚皋县",
            "label": "岚皋县"
          },
          {
            "id": 3004,
            "pid": 2997,
            "value": "平利县",
            "label": "平利县"
          },
          {
            "id": 3005,
            "pid": 2997,
            "value": "镇坪县",
            "label": "镇坪县"
          },
          {
            "id": 3006,
            "pid": 2997,
            "value": "旬阳县",
            "label": "旬阳县"
          },
          {
            "id": 3007,
            "pid": 2997,
            "value": "白河县",
            "label": "白河县"
          }
        ]
      },
      {
        "id": 3008,
        "pid": 2898,
        "value": "商洛市",
        "label": "商洛市",
        "children": [
          {
            "id": 3009,
            "pid": 3008,
            "value": "商州区",
            "label": "商州区"
          },
          {
            "id": 3010,
            "pid": 3008,
            "value": "洛南县",
            "label": "洛南县"
          },
          {
            "id": 3011,
            "pid": 3008,
            "value": "丹凤县",
            "label": "丹凤县"
          },
          {
            "id": 3012,
            "pid": 3008,
            "value": "商南县",
            "label": "商南县"
          },
          {
            "id": 3013,
            "pid": 3008,
            "value": "山阳县",
            "label": "山阳县"
          },
          {
            "id": 3014,
            "pid": 3008,
            "value": "镇安县",
            "label": "镇安县"
          },
          {
            "id": 3015,
            "pid": 3008,
            "value": "柞水县",
            "label": "柞水县"
          }
        ]
      },
      {
        "id": 3016,
        "pid": 2898,
        "value": "西咸新区",
        "label": "西咸新区",
        "children": [
          {
            "id": 3017,
            "pid": 3016,
            "value": "空港新城",
            "label": "空港新城"
          },
          {
            "id": 3018,
            "pid": 3016,
            "value": "沣东新城",
            "label": "沣东新城"
          },
          {
            "id": 3019,
            "pid": 3016,
            "value": "秦汉新城",
            "label": "秦汉新城"
          },
          {
            "id": 3020,
            "pid": 3016,
            "value": "沣西新城",
            "label": "沣西新城"
          },
          {
            "id": 3021,
            "pid": 3016,
            "value": "泾河新城",
            "label": "泾河新城"
          }
        ]
      }
    ]
  },
  {
    "id": 3022,
    "pid": 0,
    "value": "甘肃省",
    "label": "甘肃省",
    "children": [
      {
        "id": 3023,
        "pid": 3022,
        "value": "兰州市",
        "label": "兰州市",
        "children": [
          {
            "id": 3024,
            "pid": 3023,
            "value": "城关区",
            "label": "城关区"
          },
          {
            "id": 3025,
            "pid": 3023,
            "value": "七里河区",
            "label": "七里河区"
          },
          {
            "id": 3026,
            "pid": 3023,
            "value": "西固区",
            "label": "西固区"
          },
          {
            "id": 3027,
            "pid": 3023,
            "value": "安宁区",
            "label": "安宁区"
          },
          {
            "id": 3028,
            "pid": 3023,
            "value": "红古区",
            "label": "红古区"
          },
          {
            "id": 3029,
            "pid": 3023,
            "value": "永登县",
            "label": "永登县"
          },
          {
            "id": 3030,
            "pid": 3023,
            "value": "皋兰县",
            "label": "皋兰县"
          },
          {
            "id": 3031,
            "pid": 3023,
            "value": "榆中县",
            "label": "榆中县"
          }
        ]
      },
      {
        "id": 3032,
        "pid": 3022,
        "value": "嘉峪关市",
        "label": "嘉峪关市",
        "children": [
          {
            "id": 3033,
            "pid": 3032,
            "value": "雄关区",
            "label": "雄关区"
          },
          {
            "id": 3034,
            "pid": 3032,
            "value": "长城区",
            "label": "长城区"
          },
          {
            "id": 3035,
            "pid": 3032,
            "value": "镜铁区",
            "label": "镜铁区"
          }
        ]
      },
      {
        "id": 3036,
        "pid": 3022,
        "value": "金昌市",
        "label": "金昌市",
        "children": [
          {
            "id": 3037,
            "pid": 3036,
            "value": "金川区",
            "label": "金川区"
          },
          {
            "id": 3038,
            "pid": 3036,
            "value": "永昌县",
            "label": "永昌县"
          }
        ]
      },
      {
        "id": 3039,
        "pid": 3022,
        "value": "白银市",
        "label": "白银市",
        "children": [
          {
            "id": 3040,
            "pid": 3039,
            "value": "白银区",
            "label": "白银区"
          },
          {
            "id": 3041,
            "pid": 3039,
            "value": "平川区",
            "label": "平川区"
          },
          {
            "id": 3042,
            "pid": 3039,
            "value": "靖远县",
            "label": "靖远县"
          },
          {
            "id": 3043,
            "pid": 3039,
            "value": "会宁县",
            "label": "会宁县"
          },
          {
            "id": 3044,
            "pid": 3039,
            "value": "景泰县",
            "label": "景泰县"
          }
        ]
      },
      {
        "id": 3045,
        "pid": 3022,
        "value": "天水市",
        "label": "天水市",
        "children": [
          {
            "id": 3046,
            "pid": 3045,
            "value": "秦州区",
            "label": "秦州区"
          },
          {
            "id": 3047,
            "pid": 3045,
            "value": "麦积区",
            "label": "麦积区"
          },
          {
            "id": 3048,
            "pid": 3045,
            "value": "清水县",
            "label": "清水县"
          },
          {
            "id": 3049,
            "pid": 3045,
            "value": "秦安县",
            "label": "秦安县"
          },
          {
            "id": 3050,
            "pid": 3045,
            "value": "甘谷县",
            "label": "甘谷县"
          },
          {
            "id": 3051,
            "pid": 3045,
            "value": "武山县",
            "label": "武山县"
          },
          {
            "id": 3052,
            "pid": 3045,
            "value": "张家川回族自治县",
            "label": "张家川回族自治县"
          }
        ]
      },
      {
        "id": 3053,
        "pid": 3022,
        "value": "武威市",
        "label": "武威市",
        "children": [
          {
            "id": 3054,
            "pid": 3053,
            "value": "凉州区",
            "label": "凉州区"
          },
          {
            "id": 3055,
            "pid": 3053,
            "value": "民勤县",
            "label": "民勤县"
          },
          {
            "id": 3056,
            "pid": 3053,
            "value": "古浪县",
            "label": "古浪县"
          },
          {
            "id": 3057,
            "pid": 3053,
            "value": "天祝藏族自治县",
            "label": "天祝藏族自治县"
          }
        ]
      },
      {
        "id": 3058,
        "pid": 3022,
        "value": "张掖市",
        "label": "张掖市",
        "children": [
          {
            "id": 3059,
            "pid": 3058,
            "value": "甘州区",
            "label": "甘州区"
          },
          {
            "id": 3060,
            "pid": 3058,
            "value": "肃南裕固族自治县",
            "label": "肃南裕固族自治县"
          },
          {
            "id": 3061,
            "pid": 3058,
            "value": "民乐县",
            "label": "民乐县"
          },
          {
            "id": 3062,
            "pid": 3058,
            "value": "临泽县",
            "label": "临泽县"
          },
          {
            "id": 3063,
            "pid": 3058,
            "value": "高台县",
            "label": "高台县"
          },
          {
            "id": 3064,
            "pid": 3058,
            "value": "山丹县",
            "label": "山丹县"
          }
        ]
      },
      {
        "id": 3065,
        "pid": 3022,
        "value": "平凉市",
        "label": "平凉市",
        "children": [
          {
            "id": 3066,
            "pid": 3065,
            "value": "崆峒区",
            "label": "崆峒区"
          },
          {
            "id": 3067,
            "pid": 3065,
            "value": "泾川县",
            "label": "泾川县"
          },
          {
            "id": 3068,
            "pid": 3065,
            "value": "灵台县",
            "label": "灵台县"
          },
          {
            "id": 3069,
            "pid": 3065,
            "value": "崇信县",
            "label": "崇信县"
          },
          {
            "id": 3070,
            "pid": 3065,
            "value": "华亭县",
            "label": "华亭县"
          },
          {
            "id": 3071,
            "pid": 3065,
            "value": "庄浪县",
            "label": "庄浪县"
          },
          {
            "id": 3072,
            "pid": 3065,
            "value": "静宁县",
            "label": "静宁县"
          }
        ]
      },
      {
        "id": 3073,
        "pid": 3022,
        "value": "酒泉市",
        "label": "酒泉市",
        "children": [
          {
            "id": 3074,
            "pid": 3073,
            "value": "肃州区",
            "label": "肃州区"
          },
          {
            "id": 3075,
            "pid": 3073,
            "value": "金塔县",
            "label": "金塔县"
          },
          {
            "id": 3076,
            "pid": 3073,
            "value": "瓜州县",
            "label": "瓜州县"
          },
          {
            "id": 3077,
            "pid": 3073,
            "value": "肃北蒙古族自治县",
            "label": "肃北蒙古族自治县"
          },
          {
            "id": 3078,
            "pid": 3073,
            "value": "阿克塞哈萨克族自治县",
            "label": "阿克塞哈萨克族自治县"
          },
          {
            "id": 3079,
            "pid": 3073,
            "value": "玉门市",
            "label": "玉门市"
          },
          {
            "id": 3080,
            "pid": 3073,
            "value": "敦煌市",
            "label": "敦煌市"
          }
        ]
      },
      {
        "id": 3081,
        "pid": 3022,
        "value": "庆阳市",
        "label": "庆阳市",
        "children": [
          {
            "id": 3082,
            "pid": 3081,
            "value": "西峰区",
            "label": "西峰区"
          },
          {
            "id": 3083,
            "pid": 3081,
            "value": "庆城县",
            "label": "庆城县"
          },
          {
            "id": 3084,
            "pid": 3081,
            "value": "环县",
            "label": "环县"
          },
          {
            "id": 3085,
            "pid": 3081,
            "value": "华池县",
            "label": "华池县"
          },
          {
            "id": 3086,
            "pid": 3081,
            "value": "合水县",
            "label": "合水县"
          },
          {
            "id": 3087,
            "pid": 3081,
            "value": "正宁县",
            "label": "正宁县"
          },
          {
            "id": 3088,
            "pid": 3081,
            "value": "宁县",
            "label": "宁县"
          },
          {
            "id": 3089,
            "pid": 3081,
            "value": "镇原县",
            "label": "镇原县"
          }
        ]
      },
      {
        "id": 3090,
        "pid": 3022,
        "value": "定西市",
        "label": "定西市",
        "children": [
          {
            "id": 3091,
            "pid": 3090,
            "value": "安定区",
            "label": "安定区"
          },
          {
            "id": 3092,
            "pid": 3090,
            "value": "通渭县",
            "label": "通渭县"
          },
          {
            "id": 3093,
            "pid": 3090,
            "value": "陇西县",
            "label": "陇西县"
          },
          {
            "id": 3094,
            "pid": 3090,
            "value": "渭源县",
            "label": "渭源县"
          },
          {
            "id": 3095,
            "pid": 3090,
            "value": "临洮县",
            "label": "临洮县"
          },
          {
            "id": 3096,
            "pid": 3090,
            "value": "漳县",
            "label": "漳县"
          },
          {
            "id": 3097,
            "pid": 3090,
            "value": "岷县",
            "label": "岷县"
          }
        ]
      },
      {
        "id": 3098,
        "pid": 3022,
        "value": "陇南市",
        "label": "陇南市",
        "children": [
          {
            "id": 3099,
            "pid": 3098,
            "value": "武都区",
            "label": "武都区"
          },
          {
            "id": 3100,
            "pid": 3098,
            "value": "成县",
            "label": "成县"
          },
          {
            "id": 3101,
            "pid": 3098,
            "value": "文县",
            "label": "文县"
          },
          {
            "id": 3102,
            "pid": 3098,
            "value": "宕昌县",
            "label": "宕昌县"
          },
          {
            "id": 3103,
            "pid": 3098,
            "value": "康县",
            "label": "康县"
          },
          {
            "id": 3104,
            "pid": 3098,
            "value": "西和县",
            "label": "西和县"
          },
          {
            "id": 3105,
            "pid": 3098,
            "value": "礼县",
            "label": "礼县"
          },
          {
            "id": 3106,
            "pid": 3098,
            "value": "徽县",
            "label": "徽县"
          },
          {
            "id": 3107,
            "pid": 3098,
            "value": "两当县",
            "label": "两当县"
          }
        ]
      },
      {
        "id": 3108,
        "pid": 3022,
        "value": "临夏回族自治州",
        "label": "临夏回族自治州",
        "children": [
          {
            "id": 3109,
            "pid": 3108,
            "value": "临夏市",
            "label": "临夏市"
          },
          {
            "id": 3110,
            "pid": 3108,
            "value": "临夏县",
            "label": "临夏县"
          },
          {
            "id": 3111,
            "pid": 3108,
            "value": "康乐县",
            "label": "康乐县"
          },
          {
            "id": 3112,
            "pid": 3108,
            "value": "永靖县",
            "label": "永靖县"
          },
          {
            "id": 3113,
            "pid": 3108,
            "value": "广河县",
            "label": "广河县"
          },
          {
            "id": 3114,
            "pid": 3108,
            "value": "和政县",
            "label": "和政县"
          },
          {
            "id": 3115,
            "pid": 3108,
            "value": "东乡族自治县",
            "label": "东乡族自治县"
          },
          {
            "id": 3116,
            "pid": 3108,
            "value": "积石山保安族东乡族撒拉族自治县",
            "label": "积石山保安族东乡族撒拉族自治县"
          }
        ]
      },
      {
        "id": 3117,
        "pid": 3022,
        "value": "甘南藏族自治州",
        "label": "甘南藏族自治州",
        "children": [
          {
            "id": 3118,
            "pid": 3117,
            "value": "合作市",
            "label": "合作市"
          },
          {
            "id": 3119,
            "pid": 3117,
            "value": "临潭县",
            "label": "临潭县"
          },
          {
            "id": 3120,
            "pid": 3117,
            "value": "卓尼县",
            "label": "卓尼县"
          },
          {
            "id": 3121,
            "pid": 3117,
            "value": "舟曲县",
            "label": "舟曲县"
          },
          {
            "id": 3122,
            "pid": 3117,
            "value": "迭部县",
            "label": "迭部县"
          },
          {
            "id": 3123,
            "pid": 3117,
            "value": "玛曲县",
            "label": "玛曲县"
          },
          {
            "id": 3124,
            "pid": 3117,
            "value": "碌曲县",
            "label": "碌曲县"
          },
          {
            "id": 3125,
            "pid": 3117,
            "value": "夏河县",
            "label": "夏河县"
          }
        ]
      }
    ]
  },
  {
    "id": 3126,
    "pid": 0,
    "value": "青海省",
    "label": "青海省",
    "children": [
      {
        "id": 3127,
        "pid": 3126,
        "value": "西宁市",
        "label": "西宁市",
        "children": [
          {
            "id": 3128,
            "pid": 3127,
            "value": "城东区",
            "label": "城东区"
          },
          {
            "id": 3129,
            "pid": 3127,
            "value": "城中区",
            "label": "城中区"
          },
          {
            "id": 3130,
            "pid": 3127,
            "value": "城西区",
            "label": "城西区"
          },
          {
            "id": 3131,
            "pid": 3127,
            "value": "城北区",
            "label": "城北区"
          },
          {
            "id": 3132,
            "pid": 3127,
            "value": "大通回族土族自治县",
            "label": "大通回族土族自治县"
          },
          {
            "id": 3133,
            "pid": 3127,
            "value": "湟中县",
            "label": "湟中县"
          },
          {
            "id": 3134,
            "pid": 3127,
            "value": "湟源县",
            "label": "湟源县"
          }
        ]
      },
      {
        "id": 3135,
        "pid": 3126,
        "value": "海东市",
        "label": "海东市",
        "children": [
          {
            "id": 3136,
            "pid": 3135,
            "value": "乐都区",
            "label": "乐都区"
          },
          {
            "id": 3137,
            "pid": 3135,
            "value": "平安县",
            "label": "平安县"
          },
          {
            "id": 3138,
            "pid": 3135,
            "value": "民和回族土族自治县",
            "label": "民和回族土族自治县"
          },
          {
            "id": 3139,
            "pid": 3135,
            "value": "互助土族自治县",
            "label": "互助土族自治县"
          },
          {
            "id": 3140,
            "pid": 3135,
            "value": "化隆回族自治县",
            "label": "化隆回族自治县"
          },
          {
            "id": 3141,
            "pid": 3135,
            "value": "循化撒拉族自治县",
            "label": "循化撒拉族自治县"
          }
        ]
      },
      {
        "id": 3142,
        "pid": 3126,
        "value": "海北藏族自治州",
        "label": "海北藏族自治州",
        "children": [
          {
            "id": 3143,
            "pid": 3142,
            "value": "门源回族自治县",
            "label": "门源回族自治县"
          },
          {
            "id": 3144,
            "pid": 3142,
            "value": "祁连县",
            "label": "祁连县"
          },
          {
            "id": 3145,
            "pid": 3142,
            "value": "海晏县",
            "label": "海晏县"
          },
          {
            "id": 3146,
            "pid": 3142,
            "value": "刚察县",
            "label": "刚察县"
          }
        ]
      },
      {
        "id": 3147,
        "pid": 3126,
        "value": "黄南藏族自治州",
        "label": "黄南藏族自治州",
        "children": [
          {
            "id": 3148,
            "pid": 3147,
            "value": "同仁县",
            "label": "同仁县"
          },
          {
            "id": 3149,
            "pid": 3147,
            "value": "尖扎县",
            "label": "尖扎县"
          },
          {
            "id": 3150,
            "pid": 3147,
            "value": "泽库县",
            "label": "泽库县"
          },
          {
            "id": 3151,
            "pid": 3147,
            "value": "河南蒙古族自治县",
            "label": "河南蒙古族自治县"
          }
        ]
      },
      {
        "id": 3152,
        "pid": 3126,
        "value": "海南藏族自治州",
        "label": "海南藏族自治州",
        "children": [
          {
            "id": 3153,
            "pid": 3152,
            "value": "共和县",
            "label": "共和县"
          },
          {
            "id": 3154,
            "pid": 3152,
            "value": "同德县",
            "label": "同德县"
          },
          {
            "id": 3155,
            "pid": 3152,
            "value": "贵德县",
            "label": "贵德县"
          },
          {
            "id": 3156,
            "pid": 3152,
            "value": "兴海县",
            "label": "兴海县"
          },
          {
            "id": 3157,
            "pid": 3152,
            "value": "贵南县",
            "label": "贵南县"
          }
        ]
      },
      {
        "id": 3158,
        "pid": 3126,
        "value": "果洛藏族自治州",
        "label": "果洛藏族自治州",
        "children": [
          {
            "id": 3159,
            "pid": 3158,
            "value": "玛沁县",
            "label": "玛沁县"
          },
          {
            "id": 3160,
            "pid": 3158,
            "value": "班玛县",
            "label": "班玛县"
          },
          {
            "id": 3161,
            "pid": 3158,
            "value": "甘德县",
            "label": "甘德县"
          },
          {
            "id": 3162,
            "pid": 3158,
            "value": "达日县",
            "label": "达日县"
          },
          {
            "id": 3163,
            "pid": 3158,
            "value": "久治县",
            "label": "久治县"
          },
          {
            "id": 3164,
            "pid": 3158,
            "value": "玛多县",
            "label": "玛多县"
          }
        ]
      },
      {
        "id": 3165,
        "pid": 3126,
        "value": "玉树藏族自治州",
        "label": "玉树藏族自治州",
        "children": [
          {
            "id": 3166,
            "pid": 3165,
            "value": "玉树市",
            "label": "玉树市"
          },
          {
            "id": 3167,
            "pid": 3165,
            "value": "杂多县",
            "label": "杂多县"
          },
          {
            "id": 3168,
            "pid": 3165,
            "value": "称多县",
            "label": "称多县"
          },
          {
            "id": 3169,
            "pid": 3165,
            "value": "治多县",
            "label": "治多县"
          },
          {
            "id": 3170,
            "pid": 3165,
            "value": "囊谦县",
            "label": "囊谦县"
          },
          {
            "id": 3171,
            "pid": 3165,
            "value": "曲麻莱县",
            "label": "曲麻莱县"
          }
        ]
      },
      {
        "id": 3172,
        "pid": 3126,
        "value": "海西蒙古族藏族自治州",
        "label": "海西蒙古族藏族自治州",
        "children": [
          {
            "id": 3173,
            "pid": 3172,
            "value": "格尔木市",
            "label": "格尔木市"
          },
          {
            "id": 3174,
            "pid": 3172,
            "value": "德令哈市",
            "label": "德令哈市"
          },
          {
            "id": 3175,
            "pid": 3172,
            "value": "乌兰县",
            "label": "乌兰县"
          },
          {
            "id": 3176,
            "pid": 3172,
            "value": "都兰县",
            "label": "都兰县"
          },
          {
            "id": 3177,
            "pid": 3172,
            "value": "天峻县",
            "label": "天峻县"
          }
        ]
      }
    ]
  },
  {
    "id": 3178,
    "pid": 0,
    "value": "宁夏回族自治区",
    "label": "宁夏回族自治区",
    "children": [
      {
        "id": 3179,
        "pid": 3178,
        "value": "银川市",
        "label": "银川市",
        "children": [
          {
            "id": 3180,
            "pid": 3179,
            "value": "兴庆区",
            "label": "兴庆区"
          },
          {
            "id": 3181,
            "pid": 3179,
            "value": "西夏区",
            "label": "西夏区"
          },
          {
            "id": 3182,
            "pid": 3179,
            "value": "金凤区",
            "label": "金凤区"
          },
          {
            "id": 3183,
            "pid": 3179,
            "value": "永宁县",
            "label": "永宁县"
          },
          {
            "id": 3184,
            "pid": 3179,
            "value": "贺兰县",
            "label": "贺兰县"
          },
          {
            "id": 3185,
            "pid": 3179,
            "value": "灵武市",
            "label": "灵武市"
          }
        ]
      },
      {
        "id": 3186,
        "pid": 3178,
        "value": "石嘴山市",
        "label": "石嘴山市",
        "children": [
          {
            "id": 3187,
            "pid": 3186,
            "value": "大武口区",
            "label": "大武口区"
          },
          {
            "id": 3188,
            "pid": 3186,
            "value": "惠农区",
            "label": "惠农区"
          },
          {
            "id": 3189,
            "pid": 3186,
            "value": "平罗县",
            "label": "平罗县"
          }
        ]
      },
      {
        "id": 3190,
        "pid": 3178,
        "value": "吴忠市",
        "label": "吴忠市",
        "children": [
          {
            "id": 3191,
            "pid": 3190,
            "value": "利通区",
            "label": "利通区"
          },
          {
            "id": 3192,
            "pid": 3190,
            "value": "红寺堡区",
            "label": "红寺堡区"
          },
          {
            "id": 3193,
            "pid": 3190,
            "value": "盐池县",
            "label": "盐池县"
          },
          {
            "id": 3194,
            "pid": 3190,
            "value": "同心县",
            "label": "同心县"
          },
          {
            "id": 3195,
            "pid": 3190,
            "value": "青铜峡市",
            "label": "青铜峡市"
          }
        ]
      },
      {
        "id": 3196,
        "pid": 3178,
        "value": "固原市",
        "label": "固原市",
        "children": [
          {
            "id": 3197,
            "pid": 3196,
            "value": "原州区",
            "label": "原州区"
          },
          {
            "id": 3198,
            "pid": 3196,
            "value": "西吉县",
            "label": "西吉县"
          },
          {
            "id": 3199,
            "pid": 3196,
            "value": "隆德县",
            "label": "隆德县"
          },
          {
            "id": 3200,
            "pid": 3196,
            "value": "泾源县",
            "label": "泾源县"
          },
          {
            "id": 3201,
            "pid": 3196,
            "value": "彭阳县",
            "label": "彭阳县"
          }
        ]
      },
      {
        "id": 3202,
        "pid": 3178,
        "value": "中卫市",
        "label": "中卫市",
        "children": [
          {
            "id": 3203,
            "pid": 3202,
            "value": "沙坡头区",
            "label": "沙坡头区"
          },
          {
            "id": 3204,
            "pid": 3202,
            "value": "中宁县",
            "label": "中宁县"
          },
          {
            "id": 3205,
            "pid": 3202,
            "value": "海原县",
            "label": "海原县"
          }
        ]
      }
    ]
  },
  {
    "id": 3206,
    "pid": 0,
    "value": "新疆维吾尔自治区",
    "label": "新疆维吾尔自治区",
    "children": [
      {
        "id": 3207,
        "pid": 3206,
        "value": "乌鲁木齐市",
        "label": "乌鲁木齐市",
        "children": [
          {
            "id": 3208,
            "pid": 3207,
            "value": "天山区",
            "label": "天山区"
          },
          {
            "id": 3209,
            "pid": 3207,
            "value": "沙依巴克区",
            "label": "沙依巴克区"
          },
          {
            "id": 3210,
            "pid": 3207,
            "value": "新市区",
            "label": "新市区"
          },
          {
            "id": 3211,
            "pid": 3207,
            "value": "水磨沟区",
            "label": "水磨沟区"
          },
          {
            "id": 3212,
            "pid": 3207,
            "value": "头屯河区",
            "label": "头屯河区"
          },
          {
            "id": 3213,
            "pid": 3207,
            "value": "达坂城区",
            "label": "达坂城区"
          },
          {
            "id": 3214,
            "pid": 3207,
            "value": "米东区",
            "label": "米东区"
          },
          {
            "id": 3215,
            "pid": 3207,
            "value": "乌鲁木齐县",
            "label": "乌鲁木齐县"
          }
        ]
      },
      {
        "id": 3216,
        "pid": 3206,
        "value": "克拉玛依市",
        "label": "克拉玛依市",
        "children": [
          {
            "id": 3217,
            "pid": 3216,
            "value": "独山子区",
            "label": "独山子区"
          },
          {
            "id": 3218,
            "pid": 3216,
            "value": "克拉玛依区",
            "label": "克拉玛依区"
          },
          {
            "id": 3219,
            "pid": 3216,
            "value": "白碱滩区",
            "label": "白碱滩区"
          },
          {
            "id": 3220,
            "pid": 3216,
            "value": "乌尔禾区",
            "label": "乌尔禾区"
          }
        ]
      },
      {
        "id": 3221,
        "pid": 3206,
        "value": "吐鲁番地区",
        "label": "吐鲁番地区",
        "children": [
          {
            "id": 3222,
            "pid": 3221,
            "value": "吐鲁番市",
            "label": "吐鲁番市"
          },
          {
            "id": 3223,
            "pid": 3221,
            "value": "鄯善县",
            "label": "鄯善县"
          },
          {
            "id": 3224,
            "pid": 3221,
            "value": "托克逊县",
            "label": "托克逊县"
          }
        ]
      },
      {
        "id": 3225,
        "pid": 3206,
        "value": "哈密地区",
        "label": "哈密地区",
        "children": [
          {
            "id": 3226,
            "pid": 3225,
            "value": "哈密市",
            "label": "哈密市"
          },
          {
            "id": 3227,
            "pid": 3225,
            "value": "巴里坤哈萨克自治县",
            "label": "巴里坤哈萨克自治县"
          },
          {
            "id": 3228,
            "pid": 3225,
            "value": "伊吾县",
            "label": "伊吾县"
          }
        ]
      },
      {
        "id": 3229,
        "pid": 3206,
        "value": "昌吉回族自治州",
        "label": "昌吉回族自治州",
        "children": [
          {
            "id": 3230,
            "pid": 3229,
            "value": "昌吉市",
            "label": "昌吉市"
          },
          {
            "id": 3231,
            "pid": 3229,
            "value": "阜康市",
            "label": "阜康市"
          },
          {
            "id": 3232,
            "pid": 3229,
            "value": "呼图壁县",
            "label": "呼图壁县"
          },
          {
            "id": 3233,
            "pid": 3229,
            "value": "玛纳斯县",
            "label": "玛纳斯县"
          },
          {
            "id": 3234,
            "pid": 3229,
            "value": "奇台县",
            "label": "奇台县"
          },
          {
            "id": 3235,
            "pid": 3229,
            "value": "吉木萨尔县",
            "label": "吉木萨尔县"
          },
          {
            "id": 3236,
            "pid": 3229,
            "value": "木垒哈萨克自治县",
            "label": "木垒哈萨克自治县"
          }
        ]
      },
      {
        "id": 3237,
        "pid": 3206,
        "value": "博尔塔拉蒙古自治州",
        "label": "博尔塔拉蒙古自治州",
        "children": [
          {
            "id": 3238,
            "pid": 3237,
            "value": "博乐市",
            "label": "博乐市"
          },
          {
            "id": 3239,
            "pid": 3237,
            "value": "阿拉山口市",
            "label": "阿拉山口市"
          },
          {
            "id": 3240,
            "pid": 3237,
            "value": "精河县",
            "label": "精河县"
          },
          {
            "id": 3241,
            "pid": 3237,
            "value": "温泉县",
            "label": "温泉县"
          }
        ]
      },
      {
        "id": 3242,
        "pid": 3206,
        "value": "巴音郭楞蒙古自治州",
        "label": "巴音郭楞蒙古自治州",
        "children": [
          {
            "id": 3243,
            "pid": 3242,
            "value": "库尔勒市",
            "label": "库尔勒市"
          },
          {
            "id": 3244,
            "pid": 3242,
            "value": "轮台县",
            "label": "轮台县"
          },
          {
            "id": 3245,
            "pid": 3242,
            "value": "尉犁县",
            "label": "尉犁县"
          },
          {
            "id": 3246,
            "pid": 3242,
            "value": "若羌县",
            "label": "若羌县"
          },
          {
            "id": 3247,
            "pid": 3242,
            "value": "且末县",
            "label": "且末县"
          },
          {
            "id": 3248,
            "pid": 3242,
            "value": "焉耆回族自治县",
            "label": "焉耆回族自治县"
          },
          {
            "id": 3249,
            "pid": 3242,
            "value": "和静县",
            "label": "和静县"
          },
          {
            "id": 3250,
            "pid": 3242,
            "value": "和硕县",
            "label": "和硕县"
          },
          {
            "id": 3251,
            "pid": 3242,
            "value": "博湖县",
            "label": "博湖县"
          }
        ]
      },
      {
        "id": 3252,
        "pid": 3206,
        "value": "阿克苏地区",
        "label": "阿克苏地区",
        "children": [
          {
            "id": 3253,
            "pid": 3252,
            "value": "阿克苏市",
            "label": "阿克苏市"
          },
          {
            "id": 3254,
            "pid": 3252,
            "value": "温宿县",
            "label": "温宿县"
          },
          {
            "id": 3255,
            "pid": 3252,
            "value": "库车县",
            "label": "库车县"
          },
          {
            "id": 3256,
            "pid": 3252,
            "value": "沙雅县",
            "label": "沙雅县"
          },
          {
            "id": 3257,
            "pid": 3252,
            "value": "新和县",
            "label": "新和县"
          },
          {
            "id": 3258,
            "pid": 3252,
            "value": "拜城县",
            "label": "拜城县"
          },
          {
            "id": 3259,
            "pid": 3252,
            "value": "乌什县",
            "label": "乌什县"
          },
          {
            "id": 3260,
            "pid": 3252,
            "value": "阿瓦提县",
            "label": "阿瓦提县"
          },
          {
            "id": 3261,
            "pid": 3252,
            "value": "柯坪县",
            "label": "柯坪县"
          }
        ]
      },
      {
        "id": 3262,
        "pid": 3206,
        "value": "克孜勒苏柯尔克孜自治州",
        "label": "克孜勒苏柯尔克孜自治州",
        "children": [
          {
            "id": 3263,
            "pid": 3262,
            "value": "阿图什市",
            "label": "阿图什市"
          },
          {
            "id": 3264,
            "pid": 3262,
            "value": "阿克陶县",
            "label": "阿克陶县"
          },
          {
            "id": 3265,
            "pid": 3262,
            "value": "阿合奇县",
            "label": "阿合奇县"
          },
          {
            "id": 3266,
            "pid": 3262,
            "value": "乌恰县",
            "label": "乌恰县"
          }
        ]
      },
      {
        "id": 3267,
        "pid": 3206,
        "value": "喀什地区",
        "label": "喀什地区",
        "children": [
          {
            "id": 3268,
            "pid": 3267,
            "value": "喀什市",
            "label": "喀什市"
          },
          {
            "id": 3269,
            "pid": 3267,
            "value": "疏附县",
            "label": "疏附县"
          },
          {
            "id": 3270,
            "pid": 3267,
            "value": "疏勒县",
            "label": "疏勒县"
          },
          {
            "id": 3271,
            "pid": 3267,
            "value": "英吉沙县",
            "label": "英吉沙县"
          },
          {
            "id": 3272,
            "pid": 3267,
            "value": "泽普县",
            "label": "泽普县"
          },
          {
            "id": 3273,
            "pid": 3267,
            "value": "莎车县",
            "label": "莎车县"
          },
          {
            "id": 3274,
            "pid": 3267,
            "value": "叶城县",
            "label": "叶城县"
          },
          {
            "id": 3275,
            "pid": 3267,
            "value": "麦盖提县",
            "label": "麦盖提县"
          },
          {
            "id": 3276,
            "pid": 3267,
            "value": "岳普湖县",
            "label": "岳普湖县"
          },
          {
            "id": 3277,
            "pid": 3267,
            "value": "伽师县",
            "label": "伽师县"
          },
          {
            "id": 3278,
            "pid": 3267,
            "value": "巴楚县",
            "label": "巴楚县"
          },
          {
            "id": 3279,
            "pid": 3267,
            "value": "塔什库尔干塔吉克自治县",
            "label": "塔什库尔干塔吉克自治县"
          }
        ]
      },
      {
        "id": 3280,
        "pid": 3206,
        "value": "和田地区",
        "label": "和田地区",
        "children": [
          {
            "id": 3281,
            "pid": 3280,
            "value": "和田市",
            "label": "和田市"
          },
          {
            "id": 3282,
            "pid": 3280,
            "value": "和田县",
            "label": "和田县"
          },
          {
            "id": 3283,
            "pid": 3280,
            "value": "墨玉县",
            "label": "墨玉县"
          },
          {
            "id": 3284,
            "pid": 3280,
            "value": "皮山县",
            "label": "皮山县"
          },
          {
            "id": 3285,
            "pid": 3280,
            "value": "洛浦县",
            "label": "洛浦县"
          },
          {
            "id": 3286,
            "pid": 3280,
            "value": "策勒县",
            "label": "策勒县"
          },
          {
            "id": 3287,
            "pid": 3280,
            "value": "于田县",
            "label": "于田县"
          },
          {
            "id": 3288,
            "pid": 3280,
            "value": "民丰县",
            "label": "民丰县"
          }
        ]
      },
      {
        "id": 3289,
        "pid": 3206,
        "value": "伊犁哈萨克自治州",
        "label": "伊犁哈萨克自治州",
        "children": [
          {
            "id": 3290,
            "pid": 3289,
            "value": "伊宁市",
            "label": "伊宁市"
          },
          {
            "id": 3291,
            "pid": 3289,
            "value": "奎屯市",
            "label": "奎屯市"
          },
          {
            "id": 3292,
            "pid": 3289,
            "value": "霍尔果斯市",
            "label": "霍尔果斯市"
          },
          {
            "id": 3293,
            "pid": 3289,
            "value": "伊宁县",
            "label": "伊宁县"
          },
          {
            "id": 3294,
            "pid": 3289,
            "value": "察布查尔锡伯自治县",
            "label": "察布查尔锡伯自治县"
          },
          {
            "id": 3295,
            "pid": 3289,
            "value": "霍城县",
            "label": "霍城县"
          },
          {
            "id": 3296,
            "pid": 3289,
            "value": "巩留县",
            "label": "巩留县"
          },
          {
            "id": 3297,
            "pid": 3289,
            "value": "新源县",
            "label": "新源县"
          },
          {
            "id": 3298,
            "pid": 3289,
            "value": "昭苏县",
            "label": "昭苏县"
          },
          {
            "id": 3299,
            "pid": 3289,
            "value": "特克斯县",
            "label": "特克斯县"
          },
          {
            "id": 3300,
            "pid": 3289,
            "value": "尼勒克县",
            "label": "尼勒克县"
          }
        ]
      },
      {
        "id": 3301,
        "pid": 3206,
        "value": "塔城地区",
        "label": "塔城地区",
        "children": [
          {
            "id": 3302,
            "pid": 3301,
            "value": "塔城市",
            "label": "塔城市"
          },
          {
            "id": 3303,
            "pid": 3301,
            "value": "乌苏市",
            "label": "乌苏市"
          },
          {
            "id": 3304,
            "pid": 3301,
            "value": "额敏县",
            "label": "额敏县"
          },
          {
            "id": 3305,
            "pid": 3301,
            "value": "沙湾县",
            "label": "沙湾县"
          },
          {
            "id": 3306,
            "pid": 3301,
            "value": "托里县",
            "label": "托里县"
          },
          {
            "id": 3307,
            "pid": 3301,
            "value": "裕民县",
            "label": "裕民县"
          },
          {
            "id": 3308,
            "pid": 3301,
            "value": "和布克赛尔蒙古自治县",
            "label": "和布克赛尔蒙古自治县"
          }
        ]
      },
      {
        "id": 3309,
        "pid": 3206,
        "value": "阿勒泰地区",
        "label": "阿勒泰地区",
        "children": [
          {
            "id": 3310,
            "pid": 3309,
            "value": "阿勒泰市",
            "label": "阿勒泰市"
          },
          {
            "id": 3311,
            "pid": 3309,
            "value": "布尔津县",
            "label": "布尔津县"
          },
          {
            "id": 3312,
            "pid": 3309,
            "value": "富蕴县",
            "label": "富蕴县"
          },
          {
            "id": 3313,
            "pid": 3309,
            "value": "福海县",
            "label": "福海县"
          },
          {
            "id": 3314,
            "pid": 3309,
            "value": "哈巴河县",
            "label": "哈巴河县"
          },
          {
            "id": 3315,
            "pid": 3309,
            "value": "青河县",
            "label": "青河县"
          },
          {
            "id": 3316,
            "pid": 3309,
            "value": "吉木乃县",
            "label": "吉木乃县"
          }
        ]
      },
      {
        "id": 3317,
        "pid": 3206,
        "value": "直辖县级",
        "label": "直辖县级",
        "children": [
          {
            "id": 3318,
            "pid": 3317,
            "value": "石河子市",
            "label": "石河子市"
          },
          {
            "id": 3319,
            "pid": 3317,
            "value": "阿拉尔市",
            "label": "阿拉尔市"
          },
          {
            "id": 3320,
            "pid": 3317,
            "value": "图木舒克市",
            "label": "图木舒克市"
          },
          {
            "id": 3321,
            "pid": 3317,
            "value": "五家渠市",
            "label": "五家渠市"
          },
          {
            "id": 3322,
            "pid": 3317,
            "value": "北屯市",
            "label": "北屯市"
          },
          {
            "id": 3323,
            "pid": 3317,
            "value": "铁门关市",
            "label": "铁门关市"
          },
          {
            "id": 3324,
            "pid": 3317,
            "value": "双河市",
            "label": "双河市"
          }
        ]
      }
    ]
  },
  {
    "id": 3325,
    "pid": 0,
    "value": "台湾",
    "label": "台湾",
    "children": [
      {
        "id": 3326,
        "pid": 3325,
        "value": "台北市",
        "label": "台北市",
        "children": [
          {
            "id": 3327,
            "pid": 3326,
            "value": "松山区",
            "label": "松山区"
          },
          {
            "id": 3328,
            "pid": 3326,
            "value": "信义区",
            "label": "信义区"
          },
          {
            "id": 3329,
            "pid": 3326,
            "value": "大安区",
            "label": "大安区"
          },
          {
            "id": 3330,
            "pid": 3326,
            "value": "中山区",
            "label": "中山区"
          },
          {
            "id": 3331,
            "pid": 3326,
            "value": "中正区",
            "label": "中正区"
          },
          {
            "id": 3332,
            "pid": 3326,
            "value": "大同区",
            "label": "大同区"
          },
          {
            "id": 3333,
            "pid": 3326,
            "value": "万华区",
            "label": "万华区"
          },
          {
            "id": 3334,
            "pid": 3326,
            "value": "文山区",
            "label": "文山区"
          },
          {
            "id": 3335,
            "pid": 3326,
            "value": "南港区",
            "label": "南港区"
          },
          {
            "id": 3336,
            "pid": 3326,
            "value": "内湖区",
            "label": "内湖区"
          },
          {
            "id": 3337,
            "pid": 3326,
            "value": "士林区",
            "label": "士林区"
          },
          {
            "id": 3338,
            "pid": 3326,
            "value": "北投区",
            "label": "北投区"
          }
        ]
      },
      {
        "id": 3339,
        "pid": 3325,
        "value": "高雄市",
        "label": "高雄市",
        "children": [
          {
            "id": 3340,
            "pid": 3339,
            "value": "盐埕区",
            "label": "盐埕区"
          },
          {
            "id": 3341,
            "pid": 3339,
            "value": "鼓山区",
            "label": "鼓山区"
          },
          {
            "id": 3342,
            "pid": 3339,
            "value": "左营区",
            "label": "左营区"
          },
          {
            "id": 3343,
            "pid": 3339,
            "value": "楠梓区",
            "label": "楠梓区"
          },
          {
            "id": 3344,
            "pid": 3339,
            "value": "三民区",
            "label": "三民区"
          },
          {
            "id": 3345,
            "pid": 3339,
            "value": "新兴区",
            "label": "新兴区"
          },
          {
            "id": 3346,
            "pid": 3339,
            "value": "前金区",
            "label": "前金区"
          },
          {
            "id": 3347,
            "pid": 3339,
            "value": "苓雅区",
            "label": "苓雅区"
          },
          {
            "id": 3348,
            "pid": 3339,
            "value": "前镇区",
            "label": "前镇区"
          },
          {
            "id": 3349,
            "pid": 3339,
            "value": "旗津区",
            "label": "旗津区"
          },
          {
            "id": 3350,
            "pid": 3339,
            "value": "小港区",
            "label": "小港区"
          },
          {
            "id": 3351,
            "pid": 3339,
            "value": "凤山区",
            "label": "凤山区"
          },
          {
            "id": 3352,
            "pid": 3339,
            "value": "林园区",
            "label": "林园区"
          },
          {
            "id": 3353,
            "pid": 3339,
            "value": "大寮区",
            "label": "大寮区"
          },
          {
            "id": 3354,
            "pid": 3339,
            "value": "大树区",
            "label": "大树区"
          },
          {
            "id": 3355,
            "pid": 3339,
            "value": "大社区",
            "label": "大社区"
          },
          {
            "id": 3356,
            "pid": 3339,
            "value": "仁武区",
            "label": "仁武区"
          },
          {
            "id": 3357,
            "pid": 3339,
            "value": "鸟松区",
            "label": "鸟松区"
          },
          {
            "id": 3358,
            "pid": 3339,
            "value": "冈山区",
            "label": "冈山区"
          },
          {
            "id": 3359,
            "pid": 3339,
            "value": "桥头区",
            "label": "桥头区"
          },
          {
            "id": 3360,
            "pid": 3339,
            "value": "燕巢区",
            "label": "燕巢区"
          },
          {
            "id": 3361,
            "pid": 3339,
            "value": "田寮区",
            "label": "田寮区"
          },
          {
            "id": 3362,
            "pid": 3339,
            "value": "阿莲区",
            "label": "阿莲区"
          },
          {
            "id": 3363,
            "pid": 3339,
            "value": "路竹区",
            "label": "路竹区"
          },
          {
            "id": 3364,
            "pid": 3339,
            "value": "湖内区",
            "label": "湖内区"
          },
          {
            "id": 3365,
            "pid": 3339,
            "value": "茄萣区",
            "label": "茄萣区"
          },
          {
            "id": 3366,
            "pid": 3339,
            "value": "永安区",
            "label": "永安区"
          },
          {
            "id": 3367,
            "pid": 3339,
            "value": "弥陀区",
            "label": "弥陀区"
          },
          {
            "id": 3368,
            "pid": 3339,
            "value": "梓官区",
            "label": "梓官区"
          },
          {
            "id": 3369,
            "pid": 3339,
            "value": "旗山区",
            "label": "旗山区"
          },
          {
            "id": 3370,
            "pid": 3339,
            "value": "美浓区",
            "label": "美浓区"
          },
          {
            "id": 3371,
            "pid": 3339,
            "value": "六龟区",
            "label": "六龟区"
          },
          {
            "id": 3372,
            "pid": 3339,
            "value": "甲仙区",
            "label": "甲仙区"
          },
          {
            "id": 3373,
            "pid": 3339,
            "value": "杉林区",
            "label": "杉林区"
          },
          {
            "id": 3374,
            "pid": 3339,
            "value": "内门区",
            "label": "内门区"
          },
          {
            "id": 3375,
            "pid": 3339,
            "value": "茂林区",
            "label": "茂林区"
          },
          {
            "id": 3376,
            "pid": 3339,
            "value": "桃源区",
            "label": "桃源区"
          },
          {
            "id": 3377,
            "pid": 3339,
            "value": "那玛夏区",
            "label": "那玛夏区"
          }
        ]
      },
      {
        "id": 3378,
        "pid": 3325,
        "value": "基隆市",
        "label": "基隆市",
        "children": [
          {
            "id": 3379,
            "pid": 3378,
            "value": "中正区",
            "label": "中正区"
          },
          {
            "id": 3380,
            "pid": 3378,
            "value": "七堵区",
            "label": "七堵区"
          },
          {
            "id": 3381,
            "pid": 3378,
            "value": "暖暖区",
            "label": "暖暖区"
          },
          {
            "id": 3382,
            "pid": 3378,
            "value": "仁爱区",
            "label": "仁爱区"
          },
          {
            "id": 3383,
            "pid": 3378,
            "value": "中山区",
            "label": "中山区"
          },
          {
            "id": 3384,
            "pid": 3378,
            "value": "安乐区",
            "label": "安乐区"
          },
          {
            "id": 3385,
            "pid": 3378,
            "value": "信义区",
            "label": "信义区"
          }
        ]
      },
      {
        "id": 3386,
        "pid": 3325,
        "value": "台中市",
        "label": "台中市",
        "children": [
          {
            "id": 3387,
            "pid": 3386,
            "value": "中区",
            "label": "中区"
          },
          {
            "id": 3388,
            "pid": 3386,
            "value": "东区",
            "label": "东区"
          },
          {
            "id": 3389,
            "pid": 3386,
            "value": "南区",
            "label": "南区"
          },
          {
            "id": 3390,
            "pid": 3386,
            "value": "西区",
            "label": "西区"
          },
          {
            "id": 3391,
            "pid": 3386,
            "value": "北区",
            "label": "北区"
          },
          {
            "id": 3392,
            "pid": 3386,
            "value": "西屯区",
            "label": "西屯区"
          },
          {
            "id": 3393,
            "pid": 3386,
            "value": "南屯区",
            "label": "南屯区"
          },
          {
            "id": 3394,
            "pid": 3386,
            "value": "北屯区",
            "label": "北屯区"
          },
          {
            "id": 3395,
            "pid": 3386,
            "value": "丰原区",
            "label": "丰原区"
          },
          {
            "id": 3396,
            "pid": 3386,
            "value": "东势区",
            "label": "东势区"
          },
          {
            "id": 3397,
            "pid": 3386,
            "value": "大甲区",
            "label": "大甲区"
          },
          {
            "id": 3398,
            "pid": 3386,
            "value": "清水区",
            "label": "清水区"
          },
          {
            "id": 3399,
            "pid": 3386,
            "value": "沙鹿区",
            "label": "沙鹿区"
          },
          {
            "id": 3400,
            "pid": 3386,
            "value": "梧栖区",
            "label": "梧栖区"
          },
          {
            "id": 3401,
            "pid": 3386,
            "value": "后里区",
            "label": "后里区"
          },
          {
            "id": 3402,
            "pid": 3386,
            "value": "神冈区",
            "label": "神冈区"
          },
          {
            "id": 3403,
            "pid": 3386,
            "value": "潭子区",
            "label": "潭子区"
          },
          {
            "id": 3404,
            "pid": 3386,
            "value": "大雅区",
            "label": "大雅区"
          },
          {
            "id": 3405,
            "pid": 3386,
            "value": "新社区",
            "label": "新社区"
          },
          {
            "id": 3406,
            "pid": 3386,
            "value": "石冈区",
            "label": "石冈区"
          },
          {
            "id": 3407,
            "pid": 3386,
            "value": "外埔区",
            "label": "外埔区"
          },
          {
            "id": 3408,
            "pid": 3386,
            "value": "大安区",
            "label": "大安区"
          },
          {
            "id": 3409,
            "pid": 3386,
            "value": "乌日区",
            "label": "乌日区"
          },
          {
            "id": 3410,
            "pid": 3386,
            "value": "大肚区",
            "label": "大肚区"
          },
          {
            "id": 3411,
            "pid": 3386,
            "value": "龙井区",
            "label": "龙井区"
          },
          {
            "id": 3412,
            "pid": 3386,
            "value": "雾峰区",
            "label": "雾峰区"
          },
          {
            "id": 3413,
            "pid": 3386,
            "value": "太平区",
            "label": "太平区"
          },
          {
            "id": 3414,
            "pid": 3386,
            "value": "大里区",
            "label": "大里区"
          },
          {
            "id": 3415,
            "pid": 3386,
            "value": "和平区",
            "label": "和平区"
          }
        ]
      },
      {
        "id": 3416,
        "pid": 3325,
        "value": "台南市",
        "label": "台南市",
        "children": [
          {
            "id": 3417,
            "pid": 3416,
            "value": "东区",
            "label": "东区"
          },
          {
            "id": 3418,
            "pid": 3416,
            "value": "南区",
            "label": "南区"
          },
          {
            "id": 3419,
            "pid": 3416,
            "value": "北区",
            "label": "北区"
          },
          {
            "id": 3420,
            "pid": 3416,
            "value": "安南区",
            "label": "安南区"
          },
          {
            "id": 3421,
            "pid": 3416,
            "value": "安平区",
            "label": "安平区"
          },
          {
            "id": 3422,
            "pid": 3416,
            "value": "中西区",
            "label": "中西区"
          },
          {
            "id": 3423,
            "pid": 3416,
            "value": "新营区",
            "label": "新营区"
          },
          {
            "id": 3424,
            "pid": 3416,
            "value": "盐水区",
            "label": "盐水区"
          },
          {
            "id": 3425,
            "pid": 3416,
            "value": "白河区",
            "label": "白河区"
          },
          {
            "id": 3426,
            "pid": 3416,
            "value": "柳营区",
            "label": "柳营区"
          },
          {
            "id": 3427,
            "pid": 3416,
            "value": "后壁区",
            "label": "后壁区"
          },
          {
            "id": 3428,
            "pid": 3416,
            "value": "东山区",
            "label": "东山区"
          },
          {
            "id": 3429,
            "pid": 3416,
            "value": "麻豆区",
            "label": "麻豆区"
          },
          {
            "id": 3430,
            "pid": 3416,
            "value": "下营区",
            "label": "下营区"
          },
          {
            "id": 3431,
            "pid": 3416,
            "value": "六甲区",
            "label": "六甲区"
          },
          {
            "id": 3432,
            "pid": 3416,
            "value": "官田区",
            "label": "官田区"
          },
          {
            "id": 3433,
            "pid": 3416,
            "value": "大内区",
            "label": "大内区"
          },
          {
            "id": 3434,
            "pid": 3416,
            "value": "佳里区",
            "label": "佳里区"
          },
          {
            "id": 3435,
            "pid": 3416,
            "value": "学甲区",
            "label": "学甲区"
          },
          {
            "id": 3436,
            "pid": 3416,
            "value": "西港区",
            "label": "西港区"
          },
          {
            "id": 3437,
            "pid": 3416,
            "value": "七股区",
            "label": "七股区"
          },
          {
            "id": 3438,
            "pid": 3416,
            "value": "将军区",
            "label": "将军区"
          },
          {
            "id": 3439,
            "pid": 3416,
            "value": "北门区",
            "label": "北门区"
          },
          {
            "id": 3440,
            "pid": 3416,
            "value": "新化区",
            "label": "新化区"
          },
          {
            "id": 3441,
            "pid": 3416,
            "value": "善化区",
            "label": "善化区"
          },
          {
            "id": 3442,
            "pid": 3416,
            "value": "新市区",
            "label": "新市区"
          },
          {
            "id": 3443,
            "pid": 3416,
            "value": "安定区",
            "label": "安定区"
          },
          {
            "id": 3444,
            "pid": 3416,
            "value": "山上区",
            "label": "山上区"
          },
          {
            "id": 3445,
            "pid": 3416,
            "value": "玉井区",
            "label": "玉井区"
          },
          {
            "id": 3446,
            "pid": 3416,
            "value": "楠西区",
            "label": "楠西区"
          },
          {
            "id": 3447,
            "pid": 3416,
            "value": "南化区",
            "label": "南化区"
          },
          {
            "id": 3448,
            "pid": 3416,
            "value": "左镇区",
            "label": "左镇区"
          },
          {
            "id": 3449,
            "pid": 3416,
            "value": "仁德区",
            "label": "仁德区"
          },
          {
            "id": 3450,
            "pid": 3416,
            "value": "归仁区",
            "label": "归仁区"
          },
          {
            "id": 3451,
            "pid": 3416,
            "value": "关庙区",
            "label": "关庙区"
          },
          {
            "id": 3452,
            "pid": 3416,
            "value": "龙崎区",
            "label": "龙崎区"
          },
          {
            "id": 3453,
            "pid": 3416,
            "value": "永康区",
            "label": "永康区"
          }
        ]
      },
      {
        "id": 3454,
        "pid": 3325,
        "value": "新竹市",
        "label": "新竹市",
        "children": [
          {
            "id": 3455,
            "pid": 3454,
            "value": "东区",
            "label": "东区"
          },
          {
            "id": 3456,
            "pid": 3454,
            "value": "北区",
            "label": "北区"
          },
          {
            "id": 3457,
            "pid": 3454,
            "value": "香山区",
            "label": "香山区"
          }
        ]
      },
      {
        "id": 3458,
        "pid": 3325,
        "value": "嘉义市",
        "label": "嘉义市",
        "children": [
          {
            "id": 3459,
            "pid": 3458,
            "value": "东区",
            "label": "东区"
          },
          {
            "id": 3460,
            "pid": 3458,
            "value": "西区",
            "label": "西区"
          }
        ]
      },
      {
        "id": 3461,
        "pid": 3325,
        "value": "新北市",
        "label": "新北市",
        "children": [
          {
            "id": 3462,
            "pid": 3461,
            "value": "板桥区",
            "label": "板桥区"
          },
          {
            "id": 3463,
            "pid": 3461,
            "value": "三重区",
            "label": "三重区"
          },
          {
            "id": 3464,
            "pid": 3461,
            "value": "中和区",
            "label": "中和区"
          },
          {
            "id": 3465,
            "pid": 3461,
            "value": "永和区",
            "label": "永和区"
          },
          {
            "id": 3466,
            "pid": 3461,
            "value": "新庄区",
            "label": "新庄区"
          },
          {
            "id": 3467,
            "pid": 3461,
            "value": "新店区",
            "label": "新店区"
          },
          {
            "id": 3468,
            "pid": 3461,
            "value": "树林区",
            "label": "树林区"
          },
          {
            "id": 3469,
            "pid": 3461,
            "value": "莺歌区",
            "label": "莺歌区"
          },
          {
            "id": 3470,
            "pid": 3461,
            "value": "三峡区",
            "label": "三峡区"
          },
          {
            "id": 3471,
            "pid": 3461,
            "value": "淡水区",
            "label": "淡水区"
          },
          {
            "id": 3472,
            "pid": 3461,
            "value": "汐止区",
            "label": "汐止区"
          },
          {
            "id": 3473,
            "pid": 3461,
            "value": "瑞芳区",
            "label": "瑞芳区"
          },
          {
            "id": 3474,
            "pid": 3461,
            "value": "土城区",
            "label": "土城区"
          },
          {
            "id": 3475,
            "pid": 3461,
            "value": "芦洲区",
            "label": "芦洲区"
          },
          {
            "id": 3476,
            "pid": 3461,
            "value": "五股区",
            "label": "五股区"
          },
          {
            "id": 3477,
            "pid": 3461,
            "value": "泰山区",
            "label": "泰山区"
          },
          {
            "id": 3478,
            "pid": 3461,
            "value": "林口区",
            "label": "林口区"
          },
          {
            "id": 3479,
            "pid": 3461,
            "value": "深坑区",
            "label": "深坑区"
          },
          {
            "id": 3480,
            "pid": 3461,
            "value": "石碇区",
            "label": "石碇区"
          },
          {
            "id": 3481,
            "pid": 3461,
            "value": "坪林区",
            "label": "坪林区"
          },
          {
            "id": 3482,
            "pid": 3461,
            "value": "三芝区",
            "label": "三芝区"
          },
          {
            "id": 3483,
            "pid": 3461,
            "value": "石门区",
            "label": "石门区"
          },
          {
            "id": 3484,
            "pid": 3461,
            "value": "八里区",
            "label": "八里区"
          },
          {
            "id": 3485,
            "pid": 3461,
            "value": "平溪区",
            "label": "平溪区"
          },
          {
            "id": 3486,
            "pid": 3461,
            "value": "双溪区",
            "label": "双溪区"
          },
          {
            "id": 3487,
            "pid": 3461,
            "value": "贡寮区",
            "label": "贡寮区"
          },
          {
            "id": 3488,
            "pid": 3461,
            "value": "金山区",
            "label": "金山区"
          },
          {
            "id": 3489,
            "pid": 3461,
            "value": "万里区",
            "label": "万里区"
          },
          {
            "id": 3490,
            "pid": 3461,
            "value": "乌来区",
            "label": "乌来区"
          }
        ]
      },
      {
        "id": 3491,
        "pid": 3325,
        "value": "宜兰县",
        "label": "宜兰县",
        "children": [
          {
            "id": 3492,
            "pid": 3491,
            "value": "宜兰市",
            "label": "宜兰市"
          },
          {
            "id": 3493,
            "pid": 3491,
            "value": "罗东镇",
            "label": "罗东镇"
          },
          {
            "id": 3494,
            "pid": 3491,
            "value": "苏澳镇",
            "label": "苏澳镇"
          },
          {
            "id": 3495,
            "pid": 3491,
            "value": "头城镇",
            "label": "头城镇"
          },
          {
            "id": 3496,
            "pid": 3491,
            "value": "礁溪乡",
            "label": "礁溪乡"
          },
          {
            "id": 3497,
            "pid": 3491,
            "value": "壮围乡",
            "label": "壮围乡"
          },
          {
            "id": 3498,
            "pid": 3491,
            "value": "员山乡",
            "label": "员山乡"
          },
          {
            "id": 3499,
            "pid": 3491,
            "value": "冬山乡",
            "label": "冬山乡"
          },
          {
            "id": 3500,
            "pid": 3491,
            "value": "五结乡",
            "label": "五结乡"
          },
          {
            "id": 3501,
            "pid": 3491,
            "value": "三星乡",
            "label": "三星乡"
          },
          {
            "id": 3502,
            "pid": 3491,
            "value": "大同乡",
            "label": "大同乡"
          },
          {
            "id": 3503,
            "pid": 3491,
            "value": "南澳乡",
            "label": "南澳乡"
          }
        ]
      },
      {
        "id": 3504,
        "pid": 3325,
        "value": "桃园县",
        "label": "桃园县",
        "children": [
          {
            "id": 3505,
            "pid": 3504,
            "value": "桃园市",
            "label": "桃园市"
          },
          {
            "id": 3506,
            "pid": 3504,
            "value": "中坜市",
            "label": "中坜市"
          },
          {
            "id": 3507,
            "pid": 3504,
            "value": "平镇市",
            "label": "平镇市"
          },
          {
            "id": 3508,
            "pid": 3504,
            "value": "八德市",
            "label": "八德市"
          },
          {
            "id": 3509,
            "pid": 3504,
            "value": "杨梅市",
            "label": "杨梅市"
          },
          {
            "id": 3510,
            "pid": 3504,
            "value": "芦竹市",
            "label": "芦竹市"
          },
          {
            "id": 3511,
            "pid": 3504,
            "value": "大溪镇",
            "label": "大溪镇"
          },
          {
            "id": 3512,
            "pid": 3504,
            "value": "大园乡",
            "label": "大园乡"
          },
          {
            "id": 3513,
            "pid": 3504,
            "value": "龟山乡",
            "label": "龟山乡"
          },
          {
            "id": 3514,
            "pid": 3504,
            "value": "龙潭乡",
            "label": "龙潭乡"
          },
          {
            "id": 3515,
            "pid": 3504,
            "value": "新屋乡",
            "label": "新屋乡"
          },
          {
            "id": 3516,
            "pid": 3504,
            "value": "观音乡",
            "label": "观音乡"
          },
          {
            "id": 3517,
            "pid": 3504,
            "value": "复兴乡",
            "label": "复兴乡"
          }
        ]
      },
      {
        "id": 3518,
        "pid": 3325,
        "value": "新竹县",
        "label": "新竹县",
        "children": [
          {
            "id": 3519,
            "pid": 3518,
            "value": "竹北市",
            "label": "竹北市"
          },
          {
            "id": 3520,
            "pid": 3518,
            "value": "竹东镇",
            "label": "竹东镇"
          },
          {
            "id": 3521,
            "pid": 3518,
            "value": "新埔镇",
            "label": "新埔镇"
          },
          {
            "id": 3522,
            "pid": 3518,
            "value": "关西镇",
            "label": "关西镇"
          },
          {
            "id": 3523,
            "pid": 3518,
            "value": "湖口乡",
            "label": "湖口乡"
          },
          {
            "id": 3524,
            "pid": 3518,
            "value": "新丰乡",
            "label": "新丰乡"
          },
          {
            "id": 3525,
            "pid": 3518,
            "value": "芎林乡",
            "label": "芎林乡"
          },
          {
            "id": 3526,
            "pid": 3518,
            "value": "横山乡",
            "label": "横山乡"
          },
          {
            "id": 3527,
            "pid": 3518,
            "value": "北埔乡",
            "label": "北埔乡"
          },
          {
            "id": 3528,
            "pid": 3518,
            "value": "宝山乡",
            "label": "宝山乡"
          },
          {
            "id": 3529,
            "pid": 3518,
            "value": "峨眉乡",
            "label": "峨眉乡"
          },
          {
            "id": 3530,
            "pid": 3518,
            "value": "尖石乡",
            "label": "尖石乡"
          },
          {
            "id": 3531,
            "pid": 3518,
            "value": "五峰乡",
            "label": "五峰乡"
          }
        ]
      },
      {
        "id": 3532,
        "pid": 3325,
        "value": "苗栗县",
        "label": "苗栗县",
        "children": [
          {
            "id": 3533,
            "pid": 3532,
            "value": "苗栗市",
            "label": "苗栗市"
          },
          {
            "id": 3534,
            "pid": 3532,
            "value": "苑里镇",
            "label": "苑里镇"
          },
          {
            "id": 3535,
            "pid": 3532,
            "value": "通霄镇",
            "label": "通霄镇"
          },
          {
            "id": 3536,
            "pid": 3532,
            "value": "竹南镇",
            "label": "竹南镇"
          },
          {
            "id": 3537,
            "pid": 3532,
            "value": "头份镇",
            "label": "头份镇"
          },
          {
            "id": 3538,
            "pid": 3532,
            "value": "后龙镇",
            "label": "后龙镇"
          },
          {
            "id": 3539,
            "pid": 3532,
            "value": "卓兰镇",
            "label": "卓兰镇"
          },
          {
            "id": 3540,
            "pid": 3532,
            "value": "大湖乡",
            "label": "大湖乡"
          },
          {
            "id": 3541,
            "pid": 3532,
            "value": "公馆乡",
            "label": "公馆乡"
          },
          {
            "id": 3542,
            "pid": 3532,
            "value": "铜锣乡",
            "label": "铜锣乡"
          },
          {
            "id": 3543,
            "pid": 3532,
            "value": "南庄乡",
            "label": "南庄乡"
          },
          {
            "id": 3544,
            "pid": 3532,
            "value": "头屋乡",
            "label": "头屋乡"
          },
          {
            "id": 3545,
            "pid": 3532,
            "value": "三义乡",
            "label": "三义乡"
          },
          {
            "id": 3546,
            "pid": 3532,
            "value": "西湖乡",
            "label": "西湖乡"
          },
          {
            "id": 3547,
            "pid": 3532,
            "value": "造桥乡",
            "label": "造桥乡"
          },
          {
            "id": 3548,
            "pid": 3532,
            "value": "三湾乡",
            "label": "三湾乡"
          },
          {
            "id": 3549,
            "pid": 3532,
            "value": "狮潭乡",
            "label": "狮潭乡"
          },
          {
            "id": 3550,
            "pid": 3532,
            "value": "泰安乡",
            "label": "泰安乡"
          }
        ]
      },
      {
        "id": 3551,
        "pid": 3325,
        "value": "彰化县",
        "label": "彰化县",
        "children": [
          {
            "id": 3552,
            "pid": 3551,
            "value": "彰化市",
            "label": "彰化市"
          },
          {
            "id": 3553,
            "pid": 3551,
            "value": "鹿港镇",
            "label": "鹿港镇"
          },
          {
            "id": 3554,
            "pid": 3551,
            "value": "和美镇",
            "label": "和美镇"
          },
          {
            "id": 3555,
            "pid": 3551,
            "value": "线西乡",
            "label": "线西乡"
          },
          {
            "id": 3556,
            "pid": 3551,
            "value": "伸港乡",
            "label": "伸港乡"
          },
          {
            "id": 3557,
            "pid": 3551,
            "value": "福兴乡",
            "label": "福兴乡"
          },
          {
            "id": 3558,
            "pid": 3551,
            "value": "秀水乡",
            "label": "秀水乡"
          },
          {
            "id": 3559,
            "pid": 3551,
            "value": "花坛乡",
            "label": "花坛乡"
          },
          {
            "id": 3560,
            "pid": 3551,
            "value": "芬园乡",
            "label": "芬园乡"
          },
          {
            "id": 3561,
            "pid": 3551,
            "value": "员林镇",
            "label": "员林镇"
          },
          {
            "id": 3562,
            "pid": 3551,
            "value": "溪湖镇",
            "label": "溪湖镇"
          },
          {
            "id": 3563,
            "pid": 3551,
            "value": "田中镇",
            "label": "田中镇"
          },
          {
            "id": 3564,
            "pid": 3551,
            "value": "大村乡",
            "label": "大村乡"
          },
          {
            "id": 3565,
            "pid": 3551,
            "value": "埔盐乡",
            "label": "埔盐乡"
          },
          {
            "id": 3566,
            "pid": 3551,
            "value": "埔心乡",
            "label": "埔心乡"
          },
          {
            "id": 3567,
            "pid": 3551,
            "value": "永靖乡",
            "label": "永靖乡"
          },
          {
            "id": 3568,
            "pid": 3551,
            "value": "社头乡",
            "label": "社头乡"
          },
          {
            "id": 3569,
            "pid": 3551,
            "value": "二水乡",
            "label": "二水乡"
          },
          {
            "id": 3570,
            "pid": 3551,
            "value": "北斗镇",
            "label": "北斗镇"
          },
          {
            "id": 3571,
            "pid": 3551,
            "value": "二林镇",
            "label": "二林镇"
          },
          {
            "id": 3572,
            "pid": 3551,
            "value": "田尾乡",
            "label": "田尾乡"
          },
          {
            "id": 3573,
            "pid": 3551,
            "value": "埤头乡",
            "label": "埤头乡"
          },
          {
            "id": 3574,
            "pid": 3551,
            "value": "芳苑乡",
            "label": "芳苑乡"
          },
          {
            "id": 3575,
            "pid": 3551,
            "value": "大城乡",
            "label": "大城乡"
          },
          {
            "id": 3576,
            "pid": 3551,
            "value": "竹塘乡",
            "label": "竹塘乡"
          },
          {
            "id": 3577,
            "pid": 3551,
            "value": "溪州乡",
            "label": "溪州乡"
          }
        ]
      },
      {
        "id": 3578,
        "pid": 3325,
        "value": "南投县",
        "label": "南投县",
        "children": [
          {
            "id": 3579,
            "pid": 3578,
            "value": "南投市",
            "label": "南投市"
          },
          {
            "id": 3580,
            "pid": 3578,
            "value": "埔里镇",
            "label": "埔里镇"
          },
          {
            "id": 3581,
            "pid": 3578,
            "value": "草屯镇",
            "label": "草屯镇"
          },
          {
            "id": 3582,
            "pid": 3578,
            "value": "竹山镇",
            "label": "竹山镇"
          },
          {
            "id": 3583,
            "pid": 3578,
            "value": "集集镇",
            "label": "集集镇"
          },
          {
            "id": 3584,
            "pid": 3578,
            "value": "名间乡",
            "label": "名间乡"
          },
          {
            "id": 3585,
            "pid": 3578,
            "value": "鹿谷乡",
            "label": "鹿谷乡"
          },
          {
            "id": 3586,
            "pid": 3578,
            "value": "中寮乡",
            "label": "中寮乡"
          },
          {
            "id": 3587,
            "pid": 3578,
            "value": "鱼池乡",
            "label": "鱼池乡"
          },
          {
            "id": 3588,
            "pid": 3578,
            "value": "国姓乡",
            "label": "国姓乡"
          },
          {
            "id": 3589,
            "pid": 3578,
            "value": "水里乡",
            "label": "水里乡"
          },
          {
            "id": 3590,
            "pid": 3578,
            "value": "信义乡",
            "label": "信义乡"
          },
          {
            "id": 3591,
            "pid": 3578,
            "value": "仁爱乡",
            "label": "仁爱乡"
          }
        ]
      },
      {
        "id": 3592,
        "pid": 3325,
        "value": "云林县",
        "label": "云林县",
        "children": [
          {
            "id": 3593,
            "pid": 3592,
            "value": "斗六市",
            "label": "斗六市"
          },
          {
            "id": 3594,
            "pid": 3592,
            "value": "斗南镇",
            "label": "斗南镇"
          },
          {
            "id": 3595,
            "pid": 3592,
            "value": "虎尾镇",
            "label": "虎尾镇"
          },
          {
            "id": 3596,
            "pid": 3592,
            "value": "西螺镇",
            "label": "西螺镇"
          },
          {
            "id": 3597,
            "pid": 3592,
            "value": "土库镇",
            "label": "土库镇"
          },
          {
            "id": 3598,
            "pid": 3592,
            "value": "北港镇",
            "label": "北港镇"
          },
          {
            "id": 3599,
            "pid": 3592,
            "value": "古坑乡",
            "label": "古坑乡"
          },
          {
            "id": 3600,
            "pid": 3592,
            "value": "大埤乡",
            "label": "大埤乡"
          },
          {
            "id": 3601,
            "pid": 3592,
            "value": "莿桐乡",
            "label": "莿桐乡"
          },
          {
            "id": 3602,
            "pid": 3592,
            "value": "林内乡",
            "label": "林内乡"
          },
          {
            "id": 3603,
            "pid": 3592,
            "value": "二仑乡",
            "label": "二仑乡"
          },
          {
            "id": 3604,
            "pid": 3592,
            "value": "仑背乡",
            "label": "仑背乡"
          },
          {
            "id": 3605,
            "pid": 3592,
            "value": "麦寮乡",
            "label": "麦寮乡"
          },
          {
            "id": 3606,
            "pid": 3592,
            "value": "东势乡",
            "label": "东势乡"
          },
          {
            "id": 3607,
            "pid": 3592,
            "value": "褒忠乡",
            "label": "褒忠乡"
          },
          {
            "id": 3608,
            "pid": 3592,
            "value": "台西乡",
            "label": "台西乡"
          },
          {
            "id": 3609,
            "pid": 3592,
            "value": "元长乡",
            "label": "元长乡"
          },
          {
            "id": 3610,
            "pid": 3592,
            "value": "四湖乡",
            "label": "四湖乡"
          },
          {
            "id": 3611,
            "pid": 3592,
            "value": "口湖乡",
            "label": "口湖乡"
          },
          {
            "id": 3612,
            "pid": 3592,
            "value": "水林乡",
            "label": "水林乡"
          }
        ]
      },
      {
        "id": 3613,
        "pid": 3325,
        "value": "嘉义县",
        "label": "嘉义县",
        "children": [
          {
            "id": 3614,
            "pid": 3613,
            "value": "太保市",
            "label": "太保市"
          },
          {
            "id": 3615,
            "pid": 3613,
            "value": "朴子市",
            "label": "朴子市"
          },
          {
            "id": 3616,
            "pid": 3613,
            "value": "布袋镇",
            "label": "布袋镇"
          },
          {
            "id": 3617,
            "pid": 3613,
            "value": "大林镇",
            "label": "大林镇"
          },
          {
            "id": 3618,
            "pid": 3613,
            "value": "民雄乡",
            "label": "民雄乡"
          },
          {
            "id": 3619,
            "pid": 3613,
            "value": "溪口乡",
            "label": "溪口乡"
          },
          {
            "id": 3620,
            "pid": 3613,
            "value": "新港乡",
            "label": "新港乡"
          },
          {
            "id": 3621,
            "pid": 3613,
            "value": "六脚乡",
            "label": "六脚乡"
          },
          {
            "id": 3622,
            "pid": 3613,
            "value": "东石乡",
            "label": "东石乡"
          },
          {
            "id": 3623,
            "pid": 3613,
            "value": "义竹乡",
            "label": "义竹乡"
          },
          {
            "id": 3624,
            "pid": 3613,
            "value": "鹿草乡",
            "label": "鹿草乡"
          },
          {
            "id": 3625,
            "pid": 3613,
            "value": "水上乡",
            "label": "水上乡"
          },
          {
            "id": 3626,
            "pid": 3613,
            "value": "中埔乡",
            "label": "中埔乡"
          },
          {
            "id": 3627,
            "pid": 3613,
            "value": "竹崎乡",
            "label": "竹崎乡"
          },
          {
            "id": 3628,
            "pid": 3613,
            "value": "梅山乡",
            "label": "梅山乡"
          },
          {
            "id": 3629,
            "pid": 3613,
            "value": "番路乡",
            "label": "番路乡"
          },
          {
            "id": 3630,
            "pid": 3613,
            "value": "大埔乡",
            "label": "大埔乡"
          },
          {
            "id": 3631,
            "pid": 3613,
            "value": "阿里山乡",
            "label": "阿里山乡"
          }
        ]
      },
      {
        "id": 3632,
        "pid": 3325,
        "value": "屏东县",
        "label": "屏东县",
        "children": [
          {
            "id": 3633,
            "pid": 3632,
            "value": "屏东市",
            "label": "屏东市"
          },
          {
            "id": 3634,
            "pid": 3632,
            "value": "潮州镇",
            "label": "潮州镇"
          },
          {
            "id": 3635,
            "pid": 3632,
            "value": "东港镇",
            "label": "东港镇"
          },
          {
            "id": 3636,
            "pid": 3632,
            "value": "恒春镇",
            "label": "恒春镇"
          },
          {
            "id": 3637,
            "pid": 3632,
            "value": "万丹乡",
            "label": "万丹乡"
          },
          {
            "id": 3638,
            "pid": 3632,
            "value": "长治乡",
            "label": "长治乡"
          },
          {
            "id": 3639,
            "pid": 3632,
            "value": "麟洛乡",
            "label": "麟洛乡"
          },
          {
            "id": 3640,
            "pid": 3632,
            "value": "九如乡",
            "label": "九如乡"
          },
          {
            "id": 3641,
            "pid": 3632,
            "value": "里港乡",
            "label": "里港乡"
          },
          {
            "id": 3642,
            "pid": 3632,
            "value": "盐埔乡",
            "label": "盐埔乡"
          },
          {
            "id": 3643,
            "pid": 3632,
            "value": "高树乡",
            "label": "高树乡"
          },
          {
            "id": 3644,
            "pid": 3632,
            "value": "万峦乡",
            "label": "万峦乡"
          },
          {
            "id": 3645,
            "pid": 3632,
            "value": "内埔乡",
            "label": "内埔乡"
          },
          {
            "id": 3646,
            "pid": 3632,
            "value": "竹田乡",
            "label": "竹田乡"
          },
          {
            "id": 3647,
            "pid": 3632,
            "value": "新埤乡",
            "label": "新埤乡"
          },
          {
            "id": 3648,
            "pid": 3632,
            "value": "枋寮乡",
            "label": "枋寮乡"
          },
          {
            "id": 3649,
            "pid": 3632,
            "value": "新园乡",
            "label": "新园乡"
          },
          {
            "id": 3650,
            "pid": 3632,
            "value": "崁顶乡",
            "label": "崁顶乡"
          },
          {
            "id": 3651,
            "pid": 3632,
            "value": "林边乡",
            "label": "林边乡"
          },
          {
            "id": 3652,
            "pid": 3632,
            "value": "南州乡",
            "label": "南州乡"
          },
          {
            "id": 3653,
            "pid": 3632,
            "value": "佳冬乡",
            "label": "佳冬乡"
          },
          {
            "id": 3654,
            "pid": 3632,
            "value": "琉球乡",
            "label": "琉球乡"
          },
          {
            "id": 3655,
            "pid": 3632,
            "value": "车城乡",
            "label": "车城乡"
          },
          {
            "id": 3656,
            "pid": 3632,
            "value": "满州乡",
            "label": "满州乡"
          },
          {
            "id": 3657,
            "pid": 3632,
            "value": "枋山乡",
            "label": "枋山乡"
          },
          {
            "id": 3658,
            "pid": 3632,
            "value": "三地门乡",
            "label": "三地门乡"
          },
          {
            "id": 3659,
            "pid": 3632,
            "value": "雾台乡",
            "label": "雾台乡"
          },
          {
            "id": 3660,
            "pid": 3632,
            "value": "玛家乡",
            "label": "玛家乡"
          },
          {
            "id": 3661,
            "pid": 3632,
            "value": "泰武乡",
            "label": "泰武乡"
          },
          {
            "id": 3662,
            "pid": 3632,
            "value": "来义乡",
            "label": "来义乡"
          },
          {
            "id": 3663,
            "pid": 3632,
            "value": "春日乡",
            "label": "春日乡"
          },
          {
            "id": 3664,
            "pid": 3632,
            "value": "狮子乡",
            "label": "狮子乡"
          },
          {
            "id": 3665,
            "pid": 3632,
            "value": "牡丹乡",
            "label": "牡丹乡"
          }
        ]
      },
      {
        "id": 3666,
        "pid": 3325,
        "value": "台东县",
        "label": "台东县",
        "children": [
          {
            "id": 3667,
            "pid": 3666,
            "value": "台东市",
            "label": "台东市"
          },
          {
            "id": 3668,
            "pid": 3666,
            "value": "成功镇",
            "label": "成功镇"
          },
          {
            "id": 3669,
            "pid": 3666,
            "value": "关山镇",
            "label": "关山镇"
          },
          {
            "id": 3670,
            "pid": 3666,
            "value": "卑南乡",
            "label": "卑南乡"
          },
          {
            "id": 3671,
            "pid": 3666,
            "value": "鹿野乡",
            "label": "鹿野乡"
          },
          {
            "id": 3672,
            "pid": 3666,
            "value": "池上乡",
            "label": "池上乡"
          },
          {
            "id": 3673,
            "pid": 3666,
            "value": "东河乡",
            "label": "东河乡"
          },
          {
            "id": 3674,
            "pid": 3666,
            "value": "长滨乡",
            "label": "长滨乡"
          },
          {
            "id": 3675,
            "pid": 3666,
            "value": "太麻里乡",
            "label": "太麻里乡"
          },
          {
            "id": 3676,
            "pid": 3666,
            "value": "大武乡",
            "label": "大武乡"
          },
          {
            "id": 3677,
            "pid": 3666,
            "value": "绿岛乡",
            "label": "绿岛乡"
          },
          {
            "id": 3678,
            "pid": 3666,
            "value": "海端乡",
            "label": "海端乡"
          },
          {
            "id": 3679,
            "pid": 3666,
            "value": "延平乡",
            "label": "延平乡"
          },
          {
            "id": 3680,
            "pid": 3666,
            "value": "金峰乡",
            "label": "金峰乡"
          },
          {
            "id": 3681,
            "pid": 3666,
            "value": "达仁乡",
            "label": "达仁乡"
          },
          {
            "id": 3682,
            "pid": 3666,
            "value": "兰屿乡",
            "label": "兰屿乡"
          }
        ]
      },
      {
        "id": 3683,
        "pid": 3325,
        "value": "花莲县",
        "label": "花莲县",
        "children": [
          {
            "id": 3684,
            "pid": 3683,
            "value": "花莲市",
            "label": "花莲市"
          },
          {
            "id": 3685,
            "pid": 3683,
            "value": "凤林镇",
            "label": "凤林镇"
          },
          {
            "id": 3686,
            "pid": 3683,
            "value": "玉里镇",
            "label": "玉里镇"
          },
          {
            "id": 3687,
            "pid": 3683,
            "value": "新城乡",
            "label": "新城乡"
          },
          {
            "id": 3688,
            "pid": 3683,
            "value": "吉安乡",
            "label": "吉安乡"
          },
          {
            "id": 3689,
            "pid": 3683,
            "value": "寿丰乡",
            "label": "寿丰乡"
          },
          {
            "id": 3690,
            "pid": 3683,
            "value": "光复乡",
            "label": "光复乡"
          },
          {
            "id": 3691,
            "pid": 3683,
            "value": "丰滨乡",
            "label": "丰滨乡"
          },
          {
            "id": 3692,
            "pid": 3683,
            "value": "瑞穗乡",
            "label": "瑞穗乡"
          },
          {
            "id": 3693,
            "pid": 3683,
            "value": "富里乡",
            "label": "富里乡"
          },
          {
            "id": 3694,
            "pid": 3683,
            "value": "秀林乡",
            "label": "秀林乡"
          },
          {
            "id": 3695,
            "pid": 3683,
            "value": "万荣乡",
            "label": "万荣乡"
          },
          {
            "id": 3696,
            "pid": 3683,
            "value": "卓溪乡",
            "label": "卓溪乡"
          }
        ]
      },
      {
        "id": 3697,
        "pid": 3325,
        "value": "澎湖县",
        "label": "澎湖县",
        "children": [
          {
            "id": 3698,
            "pid": 3697,
            "value": "马公市",
            "label": "马公市"
          },
          {
            "id": 3699,
            "pid": 3697,
            "value": "湖西乡",
            "label": "湖西乡"
          },
          {
            "id": 3700,
            "pid": 3697,
            "value": "白沙乡",
            "label": "白沙乡"
          },
          {
            "id": 3701,
            "pid": 3697,
            "value": "西屿乡",
            "label": "西屿乡"
          },
          {
            "id": 3702,
            "pid": 3697,
            "value": "望安乡",
            "label": "望安乡"
          },
          {
            "id": 3703,
            "pid": 3697,
            "value": "七美乡",
            "label": "七美乡"
          }
        ]
      },
      {
        "id": 3704,
        "pid": 3325,
        "value": "金门县",
        "label": "金门县",
        "children": [
          {
            "id": 3705,
            "pid": 3704,
            "value": "金城镇",
            "label": "金城镇"
          },
          {
            "id": 3706,
            "pid": 3704,
            "value": "金湖镇",
            "label": "金湖镇"
          },
          {
            "id": 3707,
            "pid": 3704,
            "value": "金沙镇",
            "label": "金沙镇"
          },
          {
            "id": 3708,
            "pid": 3704,
            "value": "金宁乡",
            "label": "金宁乡"
          },
          {
            "id": 3709,
            "pid": 3704,
            "value": "烈屿乡",
            "label": "烈屿乡"
          },
          {
            "id": 3710,
            "pid": 3704,
            "value": "乌丘乡",
            "label": "乌丘乡"
          }
        ]
      },
      {
        "id": 3711,
        "pid": 3325,
        "value": "连江县",
        "label": "连江县",
        "children": [
          {
            "id": 3712,
            "pid": 3711,
            "value": "南竿乡",
            "label": "南竿乡"
          },
          {
            "id": 3713,
            "pid": 3711,
            "value": "北竿乡",
            "label": "北竿乡"
          },
          {
            "id": 3714,
            "pid": 3711,
            "value": "莒光乡",
            "label": "莒光乡"
          },
          {
            "id": 3715,
            "pid": 3711,
            "value": "东引乡",
            "label": "东引乡"
          }
        ]
      }
    ]
  },
  {
    "id": 3716,
    "pid": 0,
    "value": "香港特别行政区",
    "label": "香港特别行政区",
    "children": [
      {
        "id": 3717,
        "pid": 3716,
        "value": "香港岛",
        "label": "香港岛",
        "children": [
          {
            "id": 3718,
            "pid": 3717,
            "value": "中西区",
            "label": "中西区"
          },
          {
            "id": 3719,
            "pid": 3717,
            "value": "湾仔区",
            "label": "湾仔区"
          },
          {
            "id": 3720,
            "pid": 3717,
            "value": "东区",
            "label": "东区"
          },
          {
            "id": 3721,
            "pid": 3717,
            "value": "南区",
            "label": "南区"
          }
        ]
      },
      {
        "id": 3722,
        "pid": 3716,
        "value": "九龙",
        "label": "九龙",
        "children": [
          {
            "id": 3723,
            "pid": 3722,
            "value": "油尖旺区",
            "label": "油尖旺区"
          },
          {
            "id": 3724,
            "pid": 3722,
            "value": "深水埗区",
            "label": "深水埗区"
          },
          {
            "id": 3725,
            "pid": 3722,
            "value": "九龙城区",
            "label": "九龙城区"
          },
          {
            "id": 3726,
            "pid": 3722,
            "value": "黄大仙区",
            "label": "黄大仙区"
          },
          {
            "id": 3727,
            "pid": 3722,
            "value": "观塘区",
            "label": "观塘区"
          }
        ]
      },
      {
        "id": 3728,
        "pid": 3716,
        "value": "新界",
        "label": "新界",
        "children": [
          {
            "id": 3729,
            "pid": 3728,
            "value": "荃湾区",
            "label": "荃湾区"
          },
          {
            "id": 3730,
            "pid": 3728,
            "value": "屯门区",
            "label": "屯门区"
          },
          {
            "id": 3731,
            "pid": 3728,
            "value": "元朗区",
            "label": "元朗区"
          },
          {
            "id": 3732,
            "pid": 3728,
            "value": "北区",
            "label": "北区"
          },
          {
            "id": 3733,
            "pid": 3728,
            "value": "大埔区",
            "label": "大埔区"
          },
          {
            "id": 3734,
            "pid": 3728,
            "value": "西贡区",
            "label": "西贡区"
          },
          {
            "id": 3735,
            "pid": 3728,
            "value": "沙田区",
            "label": "沙田区"
          },
          {
            "id": 3736,
            "pid": 3728,
            "value": "葵青区",
            "label": "葵青区"
          },
          {
            "id": 3737,
            "pid": 3728,
            "value": "离岛区",
            "label": "离岛区"
          }
        ]
      }
    ]
  },
  {
    "id": 3738,
    "pid": 0,
    "value": "澳门特别行政区",
    "label": "澳门特别行政区",
    "children": [
      {
        "id": 3739,
        "pid": 3738,
        "value": "澳门半岛",
        "label": "澳门半岛",
        "children": [
          {
            "id": 3740,
            "pid": 3739,
            "value": "花地玛堂区",
            "label": "花地玛堂区"
          },
          {
            "id": 3741,
            "pid": 3739,
            "value": "圣安多尼堂区",
            "label": "圣安多尼堂区"
          },
          {
            "id": 3742,
            "pid": 3739,
            "value": "大堂区",
            "label": "大堂区"
          },
          {
            "id": 3743,
            "pid": 3739,
            "value": "望德堂区",
            "label": "望德堂区"
          },
          {
            "id": 3744,
            "pid": 3739,
            "value": "风顺堂区",
            "label": "风顺堂区"
          }
        ]
      },
      {
        "id": 3745,
        "pid": 3738,
        "value": "氹仔岛",
        "label": "氹仔岛",
        "children": [
          {
            "id": 3746,
            "pid": 3745,
            "value": "嘉模堂区",
            "label": "嘉模堂区"
          }
        ]
      },
      {
        "id": 3747,
        "pid": 3738,
        "value": "路环岛",
        "label": "路环岛",
        "children": [
          {
            "id": 3748,
            "pid": 3747,
            "value": "圣方济各堂区",
            "label": "圣方济各堂区"
          }
        ]
      }
    ]
  }
]

export { areaData }
