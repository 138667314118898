<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <servernav :tag="1"></servernav>
        <div class="mainbox">
          <div class="title">
            <span class="titlename">退还货</span>
          </div>
          <div class="showbox">
            <div v-html="html"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import servernav from "@/components/servernav.vue";
export default {
  components: { servernav },
  data() {
    return {
      html: "",
    };
  },
  mounted() {
    this.getdatas()
  },
  methods: {
    getdatas() {
      this.$myAjax("pc/common_problem", "get", {}, (res) => {
        this.html = res.return_exchange_service.content;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    .mainbox {
      margin-left: 21px;
      width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .titlename {
          color: #000000;
          font-size: 20px;
        }
      }
      .showbox {
        display: inline-block;
        width: 100%;
        float: left;
        padding: 30px 35px;
        .onequestion {
          padding: 28px 34px;
          border-bottom: 1px solid #e6e6e6;
          .onetop {
            display: inline-block;
            width: 100%;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #000000;
              float: left;
            }
            .icon {
              font-size: 12px;
              color: #999999;
              float: right;
              font-weight: bold;
            }
          }
          .contents {
            margin-top: 27px;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
