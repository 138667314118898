<template>
  <div class="login mwframe">
    <div class="bable">
      <div class="login">
        <div class="login_title">登录您的账户</div>
        <div class="log_desc">
          已注册会员请登录结算，未注册会员短信验证码登录后即可成为会员。登录后可加快结算流程，并保存购买记录。
        </div>
        <div class="login_check">
          <span :class="[isway ? 'noCheck' : 'check']" @click="iswayChange"
            >快捷登录</span
          >
          <span class="fenge">|</span>
          <span @click="iswayChange" :class="[!isway ? 'noCheck' : 'check']"
            >会员登录</span
          >
        </div>
        <div class="login_form">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
          >
            <el-form-item
              :label="!isway ? '手机号码' : '手机号码/用户名/电子邮箱'"
              prop="user"
            >
              <el-input
                v-model="form.user"
                :placeholder="
                  !isway ? '请输入手机号码' : '请输入手机号码/用户名/电子邮箱'
                "
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" v-if="isway">
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" v-else prop="captcha">
              <el-input v-model="form.captcha" placeholder="请输入短信验证码"
                ><el-button
                  slot="append"
                  @click="sendemail"
                  :loading="loading"
                  :disabled="cannot"
                  style="width: 120px"
                  >{{ countdetail }}</el-button
                ></el-input
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="login_remember" v-if="isway" @click="forget">
          <!-- <el-checkbox v-model="checked">记住我</el-checkbox> -->
          <span>忘记密码？</span>
        </div>
        <div class="chooseallbox">
          <el-checkbox v-model="checked" fill="#e60012"></el-checkbox>
          <span
            >我已阅读并接受miki house
            <a style="color: #e60012" @click="toxieyi">《用户协议》</a> 和
            <a style="color: #e60012" @click="toyinsi">《隐私条款》</a>
          </span>
        </div>
        <div class="login_btn" v-if="checked" @click="submit('form')">登录</div>
        <div class="login_btn doesnot" v-else>登录</div>
        <div class="other_login">
          <span>其他登录方式:</span>
          <i @click="wx_login()" class="icon tardiness">&#xe69a;</i>
          </div>
      </div>
      <div class="ss"></div>
      <div class="newRegister">
        <div class="newRegister_title">创建一个新账户</div>
        <div class="newRegister_desc">
          <p>拥有MIKI HOUSE账户您将获得以下权利：</p>
          <ul>
            <li>保存您的心愿清单</li>
            <li>管理您的个人信息</li>

            <li>订单历史和地址簿</li>

            <li>订单寄送追踪及退换货管理</li>

            <li>记录宝宝成长信息</li>
          </ul>
        </div>
        <div class="newRegister_btn" @click="createaccount">创建账户</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var userCheck = (rule, value, callback) => {
      if (this.isway) {
        if (!value) {
          return callback(new Error("请输入手机号码/用户名/电子邮箱"));
        } else {
          callback();
        }
      } else {
        if (!value) {
          return callback(new Error("请输入电话号码"));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          return callback(new Error("请输入有效的电话号码"));
        } else {
          callback();
        }
      }
    };
    var passCheck = (rule, value, callback) => {
      if (this.isway) {
        if (!value) {
          return callback(new Error("请输入密码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var codeCheck = (rule, value, callback) => {
      if (!this.isway) {
        if (!value) {
          return callback(new Error("请输入验证码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      form: {
        user: "",
        password: "",
        captcha: "",
      },
      rules: {
        user: [
          {
            required: true,
            validator: userCheck,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: passCheck,
            trigger: "blur",
          },
        ],
        captcha: [
          {
            required: true,
            validator: codeCheck,
            trigger: "blur",
          },
        ],
      },
      isway: true, //是否另一个方法登录
      checked: false,
      countdetail: "获取验证码",
      cannot: false,
      loading: false,
      ids: {},
    };
  },
  computed: {
    redirect_url: {
      get() {
        return encodeURIComponent(this.url);
      },
      set() {},
    },
  },
  mounted() {
    if (this.$route.query.loginout) {
      this.loginOut();
    }
    this.getids();
  },
  methods: {
    wx_login() {
      this.$myAjax('wechat/oauth2_scan','get',{},(res)=>{
        console.log(res)
        window.location.href = res;
      })
    },
    loginOut() {
      this.$myAjax("account/logout", "post", {}, () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        this.$message({
          showClose: true,
          message: "已退出登录",
          offset:170
        });
      });
    },
    getids() {
      this.$myAjax("common/problem_config", "get", {}, (res) => {
        this.ids = res;
      });
    },
    toxieyi() {
      this.$router.push({
        name: "Questions",
        query: { titleid: this.ids.user_agreement },
      });
    },
    toyinsi() {
      this.$router.push({
        name: "Questions",
        query: { titleid: this.ids.privacy_policy },
      });
    },
    createaccount() {
      this.$router.push({ name: "register" });
    },
    forget() {
      this.$router.push({ name: "findPassword" });
    },
    iswayChange() {
      this.isway = !this.isway;
      this.$refs["form"].resetFields();
    },
    sendemail() {
      if (this.form.user) {
        this.$myAjax(
          "sms/send",
          "post",
          {
            mobile: this.form.user,
            event: "mobilelogin",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset:170
            });
            let count = 60;
            this.loading = true;
            this.cannot = true;
            var interval = setInterval(() => {
              this.loading = false;
              count--;
              if (count == 0) {
                clearInterval(interval);
                this.countdetail = "获取验证码";
                this.cannot = false;
              } else {
                this.countdetail = count + "s";
              }
            }, 1000);
          }
        );
      } else {
        this.$message({
          showClose: true,
          message: "请先填写手机号码",
          offset:170
        });
      }
    },
    submit(formName) {
      let url = "",
        postdata = {};
      this.isway ? (url = "account/login") : (url = "account/mobile_login");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.isway) {
            postdata.captcha = this.form.captcha;
            postdata.mobile = this.form.user;
          } else {
            postdata.password = this.form.password;
            postdata.account = this.form.user;
          }
          this.$myAjax(url, "post", postdata, (res) => {
            let that = this;
            this.$message({
              type: "success",
              message: "登录成功",
              offset:170
            });
            localStorage.removeItem("token");
            localStorage.setItem("token", res.user_info.token);
            localStorage.setItem("userId", res.user_info.id);
            setTimeout(function () {
              // that.$router.push({ name: "HomeCenter" });
              that.$router.go(-1);
            }, 1000);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mwframe {
  margin-top: 140px;
}
.login {
  .bable {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 106px 0 77px 0;
    .ss {
      height: 469px;
      border-right: 1px solid #ccc;
    }
    .login {
      width: 60%;
      .login_title {
        font-size: 30px;
      }
      .log_desc {
        font-size: 14px;
        color: #999;
        margin-top: 10px;
        margin-bottom: 54px;
      }
      .login_check {
        font-size: 24px;
        .fenge {
          margin: 0 37px;
          color: #dcdcdc;
        }
        .noCheck {
          color: #999;
          cursor: pointer;
        }
        .check {
          cursor: pointer;
        }
      }
      .login_form {
        margin-top: 35px;
      }
      .login_remember {
        span {
          float: right;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .chooseallbox {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 14px;
          color: #111111;
          margin-left: 10px;
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .login_btn {
        height: 48px;
        width: 300px;
        background-color: #e60012;
        line-height: 48px;
        margin-top: 44px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      .doesnot {
        cursor: not-allowed;
        background: #dcdcdc;
        color: #999;
      }
    }
    .newRegister {
      .newRegister_title {
        font-size: 24px;
        font-weight: 700;
        color: #000011;
        margin-bottom: 50px;
      }
      .newRegister_desc {
        p {
          font-size: 14px;
          color: #111;
          margin-bottom: 15px;
        }
        li {
          font-size: 14px;
          color: #111;
          list-style-type: disc;
          padding: 5px 0;
        }
      }
      .newRegister_btn {
        width: 300px;
        height: 48px;
        background-color: #e60012;
        text-align: center;
        color: #fff;
        line-height: 48px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;
  .windows {
    width: 600px;
    height: 450px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -313.5px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;
      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .showaddressBox {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      height: 572px;
      overflow-y: auto;
    }
    .contents {
      padding: 0 40px;
    }
  }

}
  .other_login{
    padding: 5px 0;
    span{
      margin-right: 10px;
      font-size: 14px;
      vertical-align: middle;
    }
    i{
      font-size: 40px;
      color: #00c800;
      vertical-align: middle;
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
