<template>
  <div class="register mwframe" v-loading="loading2">
    <div class="mw">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: 'login' }">登录</el-breadcrumb-item>
          <el-breadcrumb-item>创建用户</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form">
        <div class="form_title">创建您的账户</div>
        <div class="form_box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="box_left"
            label-position="top"
          >
            <div class="left_form">
              <div>登录信息</div>
              <div>
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请输入11位的手机号"
                    maxlength="11"
                  >
                    <template v-slot:prepend>
                      <div>+86</div>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="短信验证码" prop="ecode">
                  <el-input v-model="ruleForm.ecode">
                    <el-button
                      slot="append"
                      class="elmbutton"
                      @click="sendemail"
                      :loading="loading"
                      :disabled="cannot"
                      style="width: 120px"
                      >{{ countdetail }}</el-button
                    >
                  </el-input>
                </el-form-item>
                <el-form-item label="设置密码" prop="password">
                  <el-input
                    v-model="ruleForm.password"
                    placeholder="请设置登录密码"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="email">
                  <el-input
                    v-model="ruleForm.email"
                    placeholder="请输入电子邮箱"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="left_form">
              <div>个人信息</div>
              <div>
                <el-form-item label="用户名" prop="name" class="w100">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="出生年月(设置后不可修改)"
                  prop="birth"
                  class="w100"
                >
                  <el-date-picker
                    v-model="ruleForm.birth"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    class="w100"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="checks">
              <!-- <div class="checks_check">
                <el-checkbox v-model="checked"
                  >订阅关于miki house产品、服务和活动的邮件</el-checkbox
                >
              </div> -->
              <div class="checks_check">
                <el-checkbox v-model="checked"></el-checkbox>
                <span
                  >我已阅读并接受miki house
                  <a @click="toxieyi">《用户协议》</a> 和
                  <a @click="toyinsi">《隐私条款》</a></span
                >
              </div>
            </div>
            <div class="btn" v-if="checked" @click="submit('ruleForm')">
              注册
            </div>
            <div class="btn doesnot" v-else>注册</div>
          </el-form>
          <div class="box_right">
            <div class="box_right_text">
              <div class="text_title">已有账号</div>
              <div class="text_text">请登录并查看您的账户</div>
              <div class="text_btn" @click="tologin">登录</div>
              <div class="text_text">您是否需要帮助？</div>
              <div class="text_text">请联系我们的客户专员</div>
            </div>
            <div class="box_right_help">
              <div class="help_title" @click="toserver">需要帮助？</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入电话号码"));
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error("请输入有效的电话号码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "",
        ecode: "",
        password: "",
        email: "",
        name: "",
        birth: "",
      },
      rules: {
        phone: [{ required: true, validator: checkphone, trigger: "blur" }],
        ecode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请设置登录密码", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        birth: [{ required: true, message: "请选择出生年月", trigger: "blur" }],
      },
      checked: false,
      countdetail: "获取验证码",
      cannot: false,
      loading: false,
      loading2: false,
      ids: {},
    };
  },
  mounted() {
    this.getids();
  },
  methods: {
    getids() {
      this.$myAjax("common/problem_config", "get", {}, (res) => {
        this.ids = res;
      });
    },
    tologin() {
      this.$router.push({ name: "login" });
    },
    sendemail() {
      if (this.ruleForm.phone) {
        this.$myAjax(
          "sms/send",
          "post",
          {
            mobile: this.ruleForm.phone,
            event: "register",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset: 170,
            });
            let count = 60;
            this.loading = true;
            this.cannot = true;
            var interval = setInterval(() => {
              this.loading = false;
              count--;
              if (count == 0) {
                clearInterval(interval);
                this.countdetail = "获取验证码";
                this.cannot = false;
              } else {
                this.countdetail = count + "s";
              }
            }, 1000);
          }
        );
      } else {
        this.$message({
          showClose: true,
          message: "请先填写手机号码",
          offset: 170,
        });
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading2 = true;
          this.$myAjax(
            "account/register",
            "post",
            {
              username: this.ruleForm.name,
              password: this.ruleForm.password,
              email: this.ruleForm.email,
              mobile: this.ruleForm.phone,
              code: this.ruleForm.ecode,
              birthday: this.ruleForm.birth,
            },
            (res) => {
              this.loading2 = false;
              if (res.code === 1) {
                let that = this;
                this.$message({
                  type: "success",
                  message: "注册成功，已自动登录 ... ...",
                  offset: 170,
                });
                localStorage.setItem("token", res.user_info.token);
                setTimeout(function () {
                  that.$router.push({ name: "HomeCenter" });
                }, 1000);
              } else {
                this.$alert(res.msg, "MIKI HOUSE提示您", {
                  confirmButtonText: "确定",
                });
              }
            },
            true
          );
        }
      });
    },
    toserver() {
      this.$router.push({
        name: "Questions",
        query: { titleid: this.ids.contact_us },
      });
    },
    toxieyi() {
      this.$router.push({
        name: "Questions",
        query: { titleid: this.ids.user_agreement },
      });
    },
    toyinsi() {
      this.$router.push({
        name: "Questions",
        query: { titleid: this.ids.privacy_policy },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  margin-top: 140px;
  .breadcrumb {
    padding: 28px 0 25px 0;
  }
  .form {
    width: 90%;
    background-color: #fff;
    margin: auto;
    padding: 57px 8%;
    margin-bottom: 100px;
    .form_title {
      font-size: 30px;
      text-align: center;
    }
    .form_box {
      display: flex;
      justify-content: space-between;
      margin-top: 64px;
      .box_left {
        width: 45%;
        .w100 {
          width: 100%;
        }
        .w30 {
          width: 30%;
        }
        .margin_right15px {
          margin-right: 15px;
        }
        .w65 {
          width: 65%;
        }

        > div {
          font-size: 24px;
          color: #111;
        }
        .left_form {
          .selects {
            display: flex;
            justify-content: space-between;
          }
        }

        .checks {
          margin: 50px 0 37px 0;
          color: #111;
          .checks_check {
            span {
              font-size: 14px;
              color: #111111;
              margin-left: 10px;
              a {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
        .btn {
          height: 48px;
          background-color: #e60012;
          color: #fff;
          text-align: center;
          line-height: 48px;
          font-size: 14px;
          cursor: pointer;
        }
        .doesnot {
          cursor: not-allowed;
          background: #dcdcdc;
          color: #999;
        }
      }
      .box_right {
        width: 45%;
        .box_right_text {
          text-align: center;
          > div {
            margin-bottom: 10px;
          }
          .text_title {
            font-size: 24px;
            color: #111;
          }
          .text_text {
            color: #000;
          }
          .text_btn {
            background-color: #e60012;
            height: 48px;
            line-height: 48px;
            color: #fff;
            margin: 40px 0;
            cursor: pointer;
            font-size: 14px;
          }
        }
        .box_right_help {
          color: #999;
          > div {
            margin-bottom: 10px;
          }
          .help_title {
            margin-bottom: 30px;
            cursor: pointer;
          }
          .help_text {
            cursor: pointer;
            .icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
