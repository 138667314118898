<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="9"></navbar>
        <div class="mainbox">
          <div class="title">我的评价（{{ listdata.length }}）</div>
          <div v-if="!listdata.length" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">暂无任何评价！</div>
          </div>
          <div v-else class="scorebox">
            <div class="showbox">
              <div
                class="onerecord"
                v-for="(item, index) in listdata"
                :key="index"
              >
                <div class="comet">{{ item.content }}</div>
                <div class="comet_imgs">
                  <img
                    v-for="(img, key) in item.images"
                    :key="key"
                    :src="img"
                  />
                </div>
                <div class="time">{{ item.createtime }}</div>
                <div class="shops">
                  <!-- <div class="ifoutsides" >
                    <img :src="shipimg" alt="" />
                    <div class="oustnumber">跨境进口（1）</div>
                    <div class="outsaddress">成都保税仓发货</div>
                  </div> -->
                  <div class="shopdetail">
                    <router-link
                      :to="{
                        name: 'flashSaleDetails',
                        params: {
                          s: window.btoa(
                            `type=${item.type}&id=${item.goods_id}`
                          ),
                        },
                      }"
                    >
                      <img :src="item.goods_image" alt="" />
                      <div class="detailbox">
                        <p class="name">{{ item.goods_title }}</p>
                        <p class="ids">商品编号：{{ item.goods_sn }}</p>
                        <p>规格：{{ item.spec_value }}</p>
                        <p>数量：{{ item.goods_num }}</p>
                        <!-- <div class="metions">
                        <div class="icon tardiness">&#xe602;</div>
                        <span>此产品为跨境商品</span>
                      </div> -->
                      </div>
                      <div class="price">￥{{ item.goods_price }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-if="listdata.length">
                <div
                  class="more-goods"
                  v-if="page < total_page"
                  @click="loadmore"
                >
                  发现更多
                </div>
                <div class="nomore" v-else>没有更多了</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessbaby.png"),
      shipimg: require("/src/assets/img/ship.png"),
      listdata: [],
      total_page: 1,
      page: 1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let { page } = { ...this };
      this.$myAjax("order/order_comment_list", "get", { page }, (res) => {
        this.listdata = res.list;
        this.total_page = res.total_page;
      });
    },
    loadmore() {
      this.page++;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      flex: 1;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 20px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .scorebox {
        width: 100%;
        display: inline-block;
        .showbox {
          margin: 0 40px;
          .onerecord {
            padding: 25px 0;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            float: left;
            .comet {
              font-size: 14px;
              color: #000000;
            }
            .comet_imgs {
              img {
                width: 110px;
                height: 110px;
                margin: 16px 16px 0 0;
                cursor: pointer;
              }
            }
            .time {
              margin-top: 14px;
              font-size: 14px;
              color: #666666;
            }
            .shops {
              width: 100%;
              padding: 12px 16px 27px 15px;
              background: #f3f3f3;
              margin-top: 25px;
              .ifoutsides {
                display: inline-block;
                width: 100%;
                margin-bottom: 21px;
                img {
                  width: 29px;
                  height: 25px;
                  float: left;
                }
                .oustnumber {
                  margin-left: 12px;
                  font-size: 16px;
                  color: #111111;
                  float: left;
                }
                .outsaddress {
                  margin-left: 174px;
                  font-size: 16px;
                  color: #111111;
                  float: left;
                }
              }
              .shopdetail {
                display: inline-block;
                width: 100%;
                cursor: pointer;
                img {
                  width: 110px;
                  height: 110px;
                  float: left;
                }
                .detailbox {
                  margin-left: 17px;
                  float: left;
                  p {
                    font-size: 14px;
                    color: #111111;
                  }
                  .name {
                    font-size: 18px;
                    color: #111111;
                  }
                  .ids {
                    margin-top: 10px;
                  }
                  .metions {
                    margin-top: 16px;
                    float: left;
                    display: inline-block;
                    .icon {
                      font-size: 16px;
                      color: #999999;
                      float: left;
                    }
                    span {
                      margin-left: 8px;
                      color: #999999;
                      float: left;
                      font-size: 14px;
                    }
                  }
                }
                .price {
                  float: right;
                  line-height: 110px;
                  font-size: 14px;
                  color: #111111;
                }
              }
            }
          }
        }
      }
    }
  }
  .nomore {
    width: 100%;
    padding: 30px 0;
    font-size: 16px;
    color: #666666;
    text-align: center;
    float: left;
  }
  .more-goods {
    text-align: center;
    border: 1px solid #f00;
    width: 153px;
    height: 45px;
    line-height: 45px;
    margin: 30px auto 0 auto;
    color: red;
    cursor: pointer;
  }
}
</style>
