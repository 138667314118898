<template>
  <div class="footer mwframe">
    <div class="relevancy">
      <div class="mw">
        <div class="correla">
          <div class="ul" v-for="(item, index) in list" :key="index">
            <div class="h3">{{ item.title }}</div>
            <a
              class="li"
              v-for="(tagment, tag) in item.next"
              :key="tag"
              :href="tagment.link"
              >{{ tagment.title }}</a
            >
          </div>
        </div>
        <div class="shareicon">
          <div class="icon tardiness">&#xe6b2;</div>
          <div class="icon tardiness" @click="showwx">&#xe69a;</div>
          <div class="icon tardiness">&#xe603;</div>
        </div>
      </div>
    </div>
    <div class="records">
      <div class="mw">
        <div class="internet">
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #777"
            >{{ info.beian }}</a
          >
          <img
            :src="baimg"
            style="margin-left: 10px; width: 17px; vertical-align: sub"
          />
          电子营业照
        </div>
        <div class="friendship">
          <span id="cnzz_stat_icon_1280369036" style="display: none"
            ><a
              href="https://www.cnzz.com/stat/website.php?web_id=1280369036"
              target="_blank"
              title="站长统计"
              class="a"
              >站长统计</a
            ></span
          >
 <!--         <a @click="toquestion(info.use_terms)" class="a">使用条款</a>
          <a @click="toquestion(info.sale_terms)" class="a">销售条款</a>
          <a @click="toquestion(info.legal_statement)" class="a">法律声明</a>
          <a @click="toquestion(info.privacy_policy)" class="a">隐私条款</a> -->
        </div>
      </div>
    </div>
    <el-dialog
      title="请关注微信公众号"
      :visible.sync="dialogVisible"
      width="300px"
      class="wxewm"
    >
      <img :src="wximg" />
    </el-dialog>
  </div>
</template>
 <script type="text/javascript">
</script>
<script>
export default {
  props: {
    list: Array,
    info: Object,
  },
  data() {
    return {
      toquestion(id) {
        this.$router.push({
          name: "Questions",
          query: {
            titleid: id,
          },
        });
      },
      baimg: require("@/assets/img/ba.png"),
      wximg: require("@/assets/img/wx.jpg"),
      dialogVisible: false,
    };
  },
  methods: {
    showwx() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  background-color: #ffffff;
  font-size: 14px;
  border-top: 1px solid #f2f2f2;
}
.relevancy {
  padding: 50px 0;
  height: 280px;
}
.correla {
  float: left;
  height: 180px;
  display: inline-block;
}
.correla .ul {
  width: 300px;
  height: 180px;
  float: left;
  overflow: hidden;
}
.correla .h3 {
  font-weight: bold;
  color: #000000;
  font-size: 14px;
  margin-bottom: 34px;
  line-height: 14px;
}
.correla .li {
  line-height: 14px;
  margin-bottom: 15px;
  display: block;
  color: #777777;
}
.correla .li:hover {
  color: #e7000a;
  cursor: pointer;
}
.shareicon {
  float: right;
  padding-top: 10px;
}
.shareicon .icon {
  width: 40px;
  height: 40px;
  border: 1px solid #e1e1e1;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  float: right;
  font-size: 24px;
  margin-left: 10px;
  color: #b2b2b2;
  background-color: #ffffff;
}
.shareicon .icon:hover {
  background-color: #e7000a;
  color: #ffffff;
  border-color: #e7000a;
  cursor: pointer;
}
.records {
  border-top: 1px solid #eeeeee;
  height: 60px;
  padding: 20px 0;
  color: #777777;
  line-height: 20px;
}
.records .internet {
  float: left;
}
.records .friendship {
  float: right;
}
.records .friendship .a {
  color: #777777;
  margin-left: 20px;
}
.records .friendship .a:hover {
  color: #e7000a;
  cursor: pointer;
}
img {
  width: 100%;
}
</style>
<style>
.wxewm .el-dialog__body {
  padding-top: 10px;
}
.wxewm {
  border-radius: 15px;
}
</style>
