<template>
  <div class="goodsRight">
    <div class="goods_head">
      <slot name="goods_head"></slot>
    </div>
    <div class="checks">
      <div class="checks_check" v-if="productGood">
        <div class="checktops" @click="pgstate = !pgstate">
          <div class="lefts">
            <i class="icon tardiness">&#xe606;</i>
            <div class="desc">产品参数</div>
          </div>
          <div class="icon tardiness icont">
            {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
          </div>
        </div>
        <transition name="linear_in">
          <div class="contents" v-if="pgstate">
            <div
              v-for="(item, index) in productGood"
              :key="index"
              class="rowline"
            >
              <div class="title">{{ item.title }}：</div>
              <span>{{ item.con }}</span>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="deliveryInfo">
        <div class="s"></div>
        <div class="checks_check">
          <div class="checktops" @click="distate = !distate">
            <div class="desc">{{ deliveryInfo.title }}</div>
            <div class="icon tardiness icont">
              {{ distate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <transition name="linear_in">
            <div
              class="contents"
              v-if="distate"
              v-html="deliveryInfo.content"
            ></div>
          </transition>
        </div>
      </div>
      <div v-if="payWay">
        <div class="s"></div>
        <div class="checks_check">
          <div class="checktops" @click="pwstate = !pwstate">
            <div class="desc">{{ payWay.title }}</div>
            <div class="icon tardiness icont">
              {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <div class="contents" v-if="pwstate" v-html="payWay.content"></div>
        </div>
      </div>
      <div v-if="returnExchangeService">
        <div class="s"></div>
        <div class="checks_check">
          <div class="checktops" @click="restate = !restate">
            <div class="desc">{{ returnExchangeService.title }}</div>
            <div class="icon tardiness icont">
              {{ restate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <transition name="linear_in"
            ><div
              class="contents"
              v-if="restate"
              v-html="returnExchangeService.content"
            ></div
          ></transition>
        </div>
      </div>
      <div class="s"></div>
      <router-link
        :to="{ name: 'suitList' }"
        class="checks_check"
        v-if="is_menu === 1"
      >
        <div class="checktops">
          <div class="desc">优惠套装</div>
          <div class="icon tardiness icont">&#xe620;</div>
        </div>
      </router-link>
      <div class="s"></div>
      <div class="checks_check" v-if="goods_sn != ''">
        <div class="checktops">
          <div class="desc">品番</div>
          <div class="icon tardiness icont">{{ goods_sn }}</div>
        </div>
      </div>
      <div class="s"></div>
      <router-link
        :to="{ name: 'CometDetail', query: { goods_id: goods_id } }"
        class="checks_check"
      >
        <div class="checktops">
          <div class="desc">商品评价</div>
          <div class="icon tardiness icont">&#xe620;</div>
        </div>
      </router-link>
      <div class="s"></div>
      <router-link :to="{ name: 'couponCenter' }" class="checks_check">
        <div class="checktops">
          <div class="desc">领取优惠券</div>
          <div class="icon tardiness icont">&#xe620;</div>
        </div>
      </router-link>
     
      <!-- <div class="checks_check"  v-if="active_type!=null"> 
        
        <div class="checktops" @click="toactive(active_type)">
          <div class="desc" v-if="active_type==5">满减活动</div>
           <div class="desc" v-if="active_type==6">多件多折</div>
            <div class="desc" v-if="active_type==7">千元任选</div>
          <div class="icon tardiness icont">&#xe620;</div>
        </div>
      </div>   -->
     <block v-for="(item,index) in  active"   :key="index">
       <div class="s"></div>
      <div class="checks_check"> 
        <div class="checktops" @click="toactive(item.active_type)">
            <div class="desc">{{ item.name }}</div>
          <div class="icon tardiness icont">&#xe620;</div>
        </div>
      </div>  
     </block>


    </div>
      
  </div>
</template>
<script>
export default {
  props: {
    goods_id: {
      type: Number,
      default: 0,
    },
    productGood: Array,
    payWay: Object,
    deliveryInfo: Object,
    returnExchangeService: Object,
    is_menu: Number,
    active:Array,
    active_type:String,
    goods_sn: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
    };
  },
  mounted() {
    console.log("active",this.active)
  },
  methods: {
   toser(){
      this.$router.push({ name: "seriesDetails",query:{
        goods_id:this.goods_id
      } });
   },
   toactive(active_type){
    localStorage.removeItem("page")
     this.$router.push({
        name: "seriesDetailsactive",
        query: {
          active_type:active_type,
          sid:this.goods_id
        },
      });
   },
  
  },
};
</script>
<style lang="less" scoped>
.linear_in-enter-active,
.linear_in-leave-active {
  max-height: 1000px;
  transition: all 0.5s;
  opacity: 1;
}
.linear_in-enter,
.linear_in-leave-to {
  max-height: 0;
  opacity: 0;
}
.goodsRight {
  width: 30%;
  padding: 0 30px;
  background-color: #fff;
  height: 100%;
  top: 10px;
  .checks {
    padding: 1px 0;
    margin-top: 30px;
    .s {
      width: 100%;
      border-top: 1px solid #cccccc52;
    }
    .checks_check {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      .checktops {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        .lefts {
          display: flex;
          align-items: center;
          .icon {
            font-size: 22px;
          }
          .desc {
            margin-left: 14px;
            color: #000000;
            line-height: 24px;
          }
        }
        .icont {
          font-size: 14px;
        }
      }
      .contents {
        color: #7c7c7c;
        font-size: 14px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        .rowline {
          padding: 0 5px;
          clear: both;
          .title {
            width: 80px;
            text-align: justify;
            float: left;
            color: #000000;
            &:after {
              content: ".";
              width: 100%;
              display: inline-block;
              overflow: hidden;
              height: 0;
            }
          }
          span {
            display: -webkit-box;
          }
        }
      }
    }
  }
}
</style>
