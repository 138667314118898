var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-zoom-pro"},[_c('div',{staticClass:"container"},[_c('div',{on:{"mouseenter":function($event){!_vm.disabled && !_vm.enterEvent && _vm.mouseEnter($event)},"mousemove":function($event){!_vm.disabled && !_vm.moveEvent && _vm.mouseMove($event)},"mouseleave":function($event){!_vm.disabled && !_vm.leaveEvent && _vm.mouseLeave($event)}}},[(_vm.mask)?_c('photo-mask',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideZoomer),expression:"!hideZoomer"}],attrs:{"mask-color":_vm.maskColor,"p-width":_vm.imgInfo.width,"p-height":_vm.imgInfo.height,"width":_vm.width,"height":_vm.zoomerHeight,"left":_vm.zoomerRect.left,"top":_vm.zoomerRect.top}}):_vm._e(),_c('img',{ref:"img",staticClass:"origin-img",on:{"load":function($event){return _vm.imgLoaded($event)}}}),(_vm.selector)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideZoomer && _vm.imgLoadedFlag),expression:"!hideZoomer && imgLoadedFlag"}],class:['img-zoomer', { circle: _vm.type === 'circle' }],style:([
          _vm.zoomerStyle,
          _vm.zoomerSize,
          _vm.zoomerPosition,
          !_vm.outZoomer && _vm.zoomerBgUrl,
          !_vm.outZoomer && _vm.zoomerBgSize,
          !_vm.outZoomer && _vm.zoomerBgPosition ])},[_vm._t("zoomer")],2):_vm._e()],1),(_vm.outZoomer)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideOutZoomer),expression:"!hideOutZoomer"}],class:['img-out-show', { 'base-line': _vm.baseline }],style:([
        _vm.outZoomerStyle,
        _vm.outZoomerSize,
        _vm.outZoomerPosition,
        _vm.zoomerBgUrl,
        _vm.zoomerBgSize,
        _vm.zoomerBgPosition ])},[(_vm.pointer)?_c('div',{staticClass:"img-zoomer-point"}):_vm._e(),_vm._t("outzoomer")],2):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }