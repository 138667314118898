<template>
  <div>
    <div class="homepage" v-loading="loading2">
      <div class="mw fromtop">
        <div class="leftcontent" v-if="sales_data">
          <div class="status">
            <p class="title">订单详情</p>
          </div>
          <div class="servertype">1.服务类型</div>
          <div class="serverbox">
            <div v-for="(value, key) in sales_data.type" :key="key">
              <div class="server" :class="type == key ? 'serveron' : ''" @click="type = key">
                <div class="icon tardiness">&#xe758;</div>
                <div class="textbox" v-if="key == 1">
                  <p class="th">仅退款</p>
                  <p class="td">没收到货</p>
                </div>
                <div class="textbox" v-if="key == 2">
                  <p class="th">退货退款</p>
                  <p class="td">已收到货，需要退货退款</p>
                </div>
              </div>
            </div>
          </div>
          <div class="rebacktitle">2.退款信息</div>
          <p class="rebackidtitle">退款原因</p>
          <el-select v-model="refund_reason" placeholder="请选择退款原因" class="elminput">
            <el-option v-for="(item, key) in sales_data.reason" :key="key" :label="item" :value="item"></el-option>
          </el-select>
          <p class="rebackidtitle">退款说明</p>
          <el-input v-model="refund_intro" placeholder="请输入退款原因" class="elminput" clearable=""></el-input>
          <div class="rebacktitle">3.凭证信息 <span v-if="!is_return_goods">(选填)</span></div>
          <p class="rebackidtitle">
            上传图片<span>（请您上传商品细节图，便于审核）</span>
          </p>
          <div class="imgbox">
            <div v-for="(item, key) in refund_images" :key="key">
              <img class="imaga" :src="item.imageUrl" alt="" />
              <img @click="deletes(key)" class="imagb" src="../../assets/img/delet.png">
            </div>
            <el-upload v-show="refund_images.length < 6" class="upimg" :action="upload.url" accept=".jpeg,.jpg,.png" :show-file-list="false" :on-success="handleAvatarSuccess" :on-remove="onRemoveTxt" :on-error="handlefail" :file-list="refund_images">
              <div class="icon tardiness">&#xe615;</div>
              <p>上传凭证</p>
              <p>(最多6张)</p>
            </el-upload>
          </div>
          <!-- <p class="beizhu">备注说明（选填）：希望能够尽快处理</p> -->
          <div class="submit" @click="submit">提交</div>
          <div class="cancel" @click="backto">取消</div>
        </div>
        <div class="rightcontent">
          <div class="refundinfo" v-if="sales_data.goods">
            <div class="refundtitle">正在申请售后</div>
            <div class="shops">
              <div class="ifoutsides" v-if="sales_data.warehouse_id == 2">
                <img :src="shipimg" alt="" />
                <div class="oustnumber">
                  跨境进口（{{ sales_data.goods.length }}）
                </div>
                <div class="outsaddress">成都保税仓发货</div>
              </div>
              <div class="ifoutsides" v-else>
                <div class="oustnumber">
                  国内仓发货（{{ sales_data.goods.length }}）
                </div>
              </div>
              <div class="shopdetail" v-for="(item, key) in sales_data.goods" :key="key">
                <img :src="item.goods_image" alt="" />
                <div class="detailbox">
                  <p class="name">{{ item.goods_title }}</p>
                  <p class="ids" v-if="item.goods_sn.length > 0">
                    商品编号：{{ item.goods_sn }}
                  </p>
                  <p v-if="item.spec_value.length > 0">
                    规格：{{ item.spec_value }}
                  </p>
                  <p>数量：{{ item.goods_num }}</p>
                  <div class="metions" v-if="sales_data.warehouse_id == 2">
                    <div class="icon tardiness">&#xe602;</div>
                    <span>此产品为跨境商品</span>
                  </div>
                </div>
                <div class="price">￥{{ item.goods_price }}</div>
              </div>
            </div>
          </div>
          <div class="refundinfo" v-else>
            <div class="refundtitle" style="display:flex;justify-content:center">该商品不满足退款条件，请点击咨询
              <a target="_blank" href="https://care60.live800.com/live800/chatClient/chatbox.jsp?companyID=80017004&configID=2889" class="row" style="color:red;display:flex">
        【
          <div  style="text-decoration:underline">在线客服</div>
          】
        </a>
            </div>
          </div>
          <div class="help">
            <div class="checks">
              <div v-if="order_program.delivery_info">
                <div class="checks_check">
                  <div class="checktops" @click="distate = !distate">
                    <div class="desc">
                      {{ order_program.delivery_info.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ distate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div class="contents" v-if="distate" v-html="order_program.delivery_info.content"></div>
                </div>
              </div>
              <div v-if="order_program.pay_way">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pwstate = !pwstate">
                    <div class="desc">{{ order_program.pay_way.title }}</div>
                    <div class="icon tardiness icont">
                      {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div class="contents" v-if="pwstate" v-html="order_program.pay_way.content"></div>
                </div>
              </div>
              <div v-if="order_program.electronic_invoice">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="restate = !restate">
                    <div class="desc">
                      {{ order_program.electronic_invoice.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ restate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div class="contents" v-if="restate" v-html="order_program.electronic_invoice.content"></div>
                </div>
              </div>
              <div v-if="order_program.common_problem">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pgstate = !pgstate">
                    <div class="desc">
                      {{ order_program.common_problem.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div class="contents" v-if="pgstate" v-html="order_program.common_problem.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: [],
    data() {
      return {
        refund_intro: "",
        refund_reason: "",
        type: 0,
        shipimg: require("/src/assets/img/ship.png"),
        goods_id: 0,
        order_id: 0,
        menu_id: 0,
        refund_id: 0,
        sales_data: {},
        is_return_goods: false,
        refund_num: "",
        refund_images: [],
        order_program: {},
        order_goods_id: "",
        pgstate: false,
        distate: false,
        pwstate: false,
        restate: false,
        state: 0,
        upload: {
          url: process.env.VUE_APP_BASE_API + "common/upload",
        },
        loading2: false,
        lists: [],
        list: [],
      };
    },
    mounted() {
      this.order_id = this.$route.query.order_id;
      this.list = this.$route.query.list
      this.lists = this.$route.query.lists
      console.log(this.list, this.lists)
      // this.order_goods_id = this.$route.query.order_goods_id;
      // this.menu_id = this.$route.query.menu_id;
      // this.refund_num = this.$route.query.refund_num
      console.log("this.refund_id", this.refund_id)
      this.refund_id = this.$route.query.refund_id ?
        this.$route.query.refund_id :
        0;
      if (this.refund_id === 0) {
        this.getData();
      } else {
        this.getRefundData();
      }
      this.get_order_program();
      this.get_goods_program();
    },
    methods: {
      deletes(e) {
        let index = e
        console.log(index)
        let imgs = this.refund_images
        imgs.splice(index, 1)
        this.refund_images = imgs
      },
      backto() {
        this.$router.go(-1);
      },
      getData() {
        let list = []
        for (var i = 0; i < this.list.length; i++) {
          for (var j = 0; j < this.lists.length; j++) {
            if (this.lists[j] == this.list[i].order_goods_id) {
              let obj = {
                order_goods_id: this.list[i].type == 2 ? 0 : this.list[i].order_goods_id,
                refund_num: this.list[i].true_num,
                menu_id: this.list[i].type == 2 ? this.list[i].order_goods_id : 0
              }
              list.push(obj)
            }
          }
        }
        let option = {
          goods_id: JSON.stringify(list)
        }
        option.order_id = this.order_id
        // option.menu_id = this.menu_id
        console.log(option)
        this.$myAjax(
          "order/after_sales_page",
          "get",
          option,
          (res) => {
            this.sales_data = res;
            this.is_return_goods = res.is_return_goods
          }
        );
      },
      handleAvatarSuccess(res, file) {
        console.log(res);
        let imgs = this.refund_images
        imgs.push({
          imageUrl: URL.createObjectURL(file.raw),
          upimgUrl: file.response.data.url,
        });
      },
      onRemoveTxt(e) {
        console.log(e)
      },
      handlefail() {
        this.$message.error("图片上传失败！请稍后重试！");
      },
      submit() {
        if (this.state == 1) {
          return
        }
        let that = this
        let {
          refund_images,
          refund_reason,
          type,
          refund_id,
        } = { ...this
        };
        if (type === 0) {
          this.$message({
            message: "请选择退货方式",
            type: "error",
            offset: 170,
          });
          return;
        }
        if (refund_reason == "") {
          this.$message({
            message: "请选择退款原因",
            type: "error",
            offset: 170,
          });
          return;
        }
        if (this.is_return_goods == true) {
          if (refund_images.length < 4) {
            this.$message({
              message: "请上传退款凭证(4张)"
            })
            return
          }
        }
        if (refund_images.length > 0) {
          refund_images = refund_images.map((value) => {
            return value.upimgUrl;
          });
          refund_images = refund_images.join(",");
        }
        this.loading2 = true;
        if (refund_id === 0) {
          let list = []
          for (var i = 0; i < this.list.length; i++) {
            for (var j = 0; j < this.lists.length; j++) {
              if (this.lists[j] == this.list[i].order_goods_id) {
                let obj = {
                  order_goods_id: this.list[i].type == 2 ? 0 : this.list[i].order_goods_id,
                  refund_num: this.list[i].goods_num,
                  menu_id: this.list[i].type == 2 ? this.list[i].order_goods_id : 0
                }
                list.push(obj)
              }
            }
          }
          let option = {
            goods_id: JSON.stringify(list)
          }
          option.order_id = this.order_id
          option.refund_images = refund_images
          option.refund_intro = this.refund_intro
          option.refund_reason = this.refund_reason
          option.type = this.type
          that.state = 1
          console.log(that.state)
          this.$myAjax(
            "order/submit_apply",
            "post",
            option,
            () => {
              this.loading2 = false;
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1000,
                offset: 170,
                onClose: () => {
                  this.$router.replace({
                    name: "SalesCenter",
                  });
                },
              });
            }
          );
          setTimeout(() => {
            that.state = 0
          }, 5000);
          this.loading2 = false;
        } else {
          let option = {}
          option.refund_images = refund_images
          option.refund_intro = this.refund_intro
          option.refund_reason = this.refund_reason
          option.type = this.type
          option.refund_id = this.refund_id
          that.state = 1
          this.$myAjax(
            "order/again_submit",
            "post",
            option,
            (res) => {
              this.loading2 = false;
              if (res.code == 1) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1000,
                  offset: 170,
                  onClose: () => {
                    this.$router.replace({
                      name: "SalesCenter",
                    });
                  },
                });
              } else {
                this.$alert(res.msg, "MIKI HOUSE提示您", {
                  confirmButtonText: "确定",
                });
              }
            },
            true
          );
          this.loading2 = false;
          setTimeout(() => {
            that.state = 0
          }, 5000);
        }
      },
      getRefundData() {
        let {
          refund_id
        } = { ...this
        };
        this.$myAjax("order/again_after_sales", "get", {
          refund_id
        }, (res) => {
          this.sales_data = res;
          // this.refund_reason = res.refund.refund_reason
          // this.refund_intro = res.refund.refund_intro
          this.type = res.refund.type;
        });
      },
      get_order_program() {
        this.$myAjax("common/order_program", "get", {}, (res) => {
          this.order_program = res;
        });
      },
      get_goods_program() {
        this.$myAjax("common/goods_program", "get", {}, (res) => {
          this.goods_program = res;
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .homepage {
    width: 100%;
    background: #fafafa;
    margin-top: 140px;
    display: inline-block;
    padding-bottom: 53px;
    min-height: 1157px;
    .fromtop {
      margin-top: 50px;
      .leftcontent {
        width: 900px;
        float: left;
        .status {
          .title {
            font-size: 30px;
            color: #000000;
            font-weight: bold;
          }
          .status_canbe {
            margin-top: 32px;
            font-size: 16px;
            font-weight: bold;
          }
          .tips {
            margin-top: 17px;
            font-size: 14px;
            color: #333333;
          }
        }
        .servertype {
          padding: 23px 0;
          border-bottom: 1px solid #e6e6e6;
          font-size: 16px;
          color: #000000;
          font-weight: bold;
        }
        .serverbox {
          display: inline-block;
          float: left;
          width: 100%;
          margin-bottom: 55px;
          .server {
            margin-top: 40px;
            width: 298px;
            height: 118px;
            border: 1px solid #d9d9d9;
            float: left;
            &:hover {
              border: 1px solid #000000;
            }
            cursor: pointer;
            .icon {
              font-size: 36px;
              margin-left: 37px;
              color: #666666;
              margin-top: 38px;
              float: left;
            }
            .textbox {
              margin-left: 22px;
              float: left;
              .th {
                font-size: 16px;
                color: #000000;
                margin-top: 35px;
              }
              .td {
                font-size: 14px;
                color: #666666;
                margin-top: 5px;
              }
            }
          }
          .serveron {
            border: 1px solid #000000;
          }
        }
        .rebacktitle {
          margin-top: 30px;
          padding: 24px 0;
          border-bottom: 1px solid #e6e6e6;
          font-size: 16px;
          color: #000000;
        }
        .rebackidtitle {
          font-size: 14px;
          color: #000000;
          margin-top: 32px;
          span {
            color: #e70012;
          }
        }
        .elminput {
          width: 100%;
          margin-top: 18px;
        }
        .imgbox {
          height: 155px;
          margin-top: 31px;
          div {
            position: relative;
            float: left;
            width: 155px;
            float: left;
            height: 155px;
            margin-left: 20px;
            cursor: pointer;
            .imaga {
              position: relative;
              float: left;
              width: 155px;
              height: 155px;
            }
            .imagb {
              position: absolute;
              right: 4px;
              top: 4px;
              width: 30px;
              height: 30px;
              background: white;
              border-radius: 50%;
            }
          }
          .upimg {
            width: 155px;
            height: 155px;
            float: left;
            margin-left: 20px; // line-height: 155px;
            text-align: center;
            cursor: pointer;
            border: 1px solid #dbdbdb;
            .icon {
              font-size: 31px;
              color: #666;
              margin-top: 58px;
            }
            p {
              font-size: 14px;
              color: #333333;
            }
          }
           :first-child {
            margin-left: 0;
          }
        }
        .beizhu {
          padding: 35px 0;
          font-size: 14px;
          color: #000000;
          margin-top: 55px;
          border-top: 1px solid #e6e6e6;
        }
        .submit {
          width: 168px;
          height: 48px;
          background: #e70010;
          float: right;
          margin-top: 117px;
          text-align: center;
          line-height: 48px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
        }
        .cancel {
          width: 168px;
          height: 48px;
          border: 1px solid #999999;
          float: right;
          margin-top: 117px;
          text-align: center;
          line-height: 48px;
          color: #999999;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          margin-right: 17px;
        }
      }
      .rightcontent {
        width: 480px;
        float: right;
        .refundinfo {
          width: 480px;
          background: #fff;
          .refundtitle {
            height: 64px;
            text-align: center;
            line-height: 64px;
            border-bottom: 1px solid #e6e6e6;
            font-size: 20px;
            color: #000000;
          }
          .shops {
            width: 100%;
            padding: 12px 16px 27px 15px;
            .ifoutsides {
              display: inline-block;
              width: 100%;
              margin-bottom: 21px;
              img {
                width: 29px;
                height: 25px;
                float: left;
              }
              .oustnumber {
                margin-left: 12px;
                font-size: 16px;
                color: #111111;
                float: left;
              }
              .outsaddress {
                font-size: 16px;
                color: #111111;
                float: right;
              }
            }
            .shopdetail {
              display: inline-block;
              width: 100%;
              img {
                width: 110px;
                height: 110px;
                float: left;
              }
              .detailbox {
                margin-left: 17px;
                float: left;
                p {
                  font-size: 14px;
                  color: #111111;
                }
                .name {
                  font-size: 18px;
                  color: #111111;
                }
                .ids {
                  margin-top: 10px;
                }
                .metions {
                  margin-top: 16px;
                  float: left;
                  display: inline-block;
                  .icon {
                    font-size: 16px;
                    color: #999999;
                    float: left;
                  }
                  span {
                    margin-left: 8px;
                    color: #999999;
                    float: left;
                    font-size: 14px;
                  }
                }
              }
              .price {
                float: right;
                line-height: 110px;
                font-size: 14px;
                color: #111111;
              }
            }
          }
        }
      } // .helps {
      //   width: 480px;
      //   display: inline-block;
      //   margin-top: 14px;
      //   .helpone {
      //     float: left;
      //     padding: 0 32px;
      //     height: 80px;
      //     margin-top: 1px;
      //     background: #fff;
      //     line-height: 80px;
      //     width: 100%;
      //     cursor: pointer;
      //     span {
      //       font-size: 18px;
      //       color: #111111;
      //       float: left;
      //     }
      //     .icon {
      //       color: #111111;
      //       float: right;
      //     }
      //   }
      // }
      .help {
        background-color: #fff;
        margin-top: 12px;
        padding: 0 30px;
        .checks {
          padding: 1px 0;
          .s {
            width: 100%;
            border-top: 1px solid #ccc;
          }
          .checks_check {
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            .checktops {
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              flex-direction: row;
              .lefts {
                display: flex;
                align-items: center;
                .icon {
                  font-size: 22px;
                }
                .desc {
                  margin-left: 14px;
                  color: #000000;
                  line-height: 24px;
                }
              }
              .icont {
                font-size: 14px;
              }
            }
            .contents {
              color: #7c7c7c;
              font-size: 14px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
</style>
