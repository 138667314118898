<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <div class="leftcontent">
          <div class="status">
            <p class="title">订单详情</p>
            <p class="status_canbe">请填写退回寄信息</p>
            <p class="tips">请寄回商品</p>
          </div>
          <div class="servertype">1.服务类型</div>
          <div class="server">
            <div class="icon tardiness">&#xe758;</div>
            <div class="textbox">
              <p class="th">退货退款</p>
              <p class="td">已收到货，需要退货退款</p>
            </div>
          </div>
          <div class="rebacktitle">2.退货信息</div>
          <p class="rebackidtitle">回寄单号</p>
          <el-input
            v-model="logistics_sn"
            placeholder="请输入回寄单号"
            class="elminput"
            clearable=""
          ></el-input>
          <p class="rebackidtitle">物流公司</p>
          <el-select
            v-model="logistics_id"
            placeholder="请选择物流公司"
            class="elminput"
          >
            <el-option
              v-for="(item, key) in company_list"
              :key="key"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="rebacktitle">3.凭证信息</div>
          <p class="rebackidtitle">
            上传图片<span>（请您上传寄出包裹信息照）</span>
          </p>
          <div class="imgbox">
            <div v-for="(item, key) in return_images" :key="key">
              <img  class="imaga" :src="item.imageUrl" alt="" />
              <img  @click="deletes(key)"  class="imagb" src="../../assets/img/delet.png">
            </div>
            <el-upload
              v-show="return_images.length < 6"
              class="upimg"
              :action="upload.url"
              accept=".jpeg,.jpg,.png"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="handlefail"
            >
              <div class="icon tardiness">&#xe615;</div>
              <p>上传凭证</p>
              <p>(最多6张)</p>
            </el-upload>
          </div>
          <p class="beizhu">备注说明（选填）：</p>
          <el-input
            v-model="return_remarks"
            placeholder="请输入备注说明"
            class="elminput"
            clearable=""
          ></el-input>
          <div class="submit" @click="submit">提交</div>
        </div>
        <div class="rightcontent">
          <div class="refundinfo" v-if="refund_data">
            <div class="refundtitle">退款信息</div>
            <div class="refundrow">
              <span class="rowtitle">申请时间</span>
              <span class="rowvalue">{{ refund_data.createtime }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款金额</span>
              <span class="rowvalue">￥{{ refund_data.refund_price }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款原因</span>
              <span class="rowvalue">{{ refund_data.refund_reason }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款方式</span>
              <span class="rowvalue">原路返回</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退货方式</span>
              <span class="rowvalue">快递</span>
            </div>
          </div>
          <div class="help">
            <div class="checks">
              <div v-if="order_program.delivery_info">
                <div class="checks_check">
                  <div class="checktops" @click="distate = !distate">
                    <div class="desc">
                      {{ order_program.delivery_info.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ distate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="distate"
                    v-html="order_program.delivery_info.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.pay_way">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pwstate = !pwstate">
                    <div class="desc">{{ order_program.pay_way.title }}</div>
                    <div class="icon tardiness icont">
                      {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pwstate"
                    v-html="order_program.pay_way.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.electronic_invoice">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="restate = !restate">
                    <div class="desc">
                      {{ order_program.electronic_invoice.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ restate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="restate"
                    v-html="order_program.electronic_invoice.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.common_problem">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pgstate = !pgstate">
                    <div class="desc">
                      {{ order_program.common_problem.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pgstate"
                    v-html="order_program.common_problem.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      logistics_sn: "",
      logistics_id: "",
      order_program: {},
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      return_images: [],
      company_list: {},
      refund_data: {},
      order_data: {},
      return_remarks: "",
      upload: {
          url: process.env.VUE_APP_BASE_API + "common/upload",
        },
    };
  },
  mounted() {
    this.refund_id = this.$route.query.refund_id;
    this.getData();
    this.get_order_program();
    this.get_logistics_company_list();
  },
  methods: {
    deletes(e){
      let index=e
      console.log(index)
      let imgs=this.return_images
        imgs.splice(index,1)
      this.return_images=imgs
    },
    getData() {
      let { refund_id } = { ...this };
      this.$myAjax("order/refund_details", "get", { refund_id }, (res) => {
        this.refund_data = res.refund;
        this.order_data = res.order;
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.return_images.push({
        imageUrl: URL.createObjectURL(file.raw),
        upimgUrl: file.response.data.url,
      });
    },
    handlefail() {
      this.$message.error("图片上传失败！请稍后重试！");
    },
    get_order_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.order_program = res;
      });
    },
    get_logistics_company_list() {
      this.$myAjax("order/logistics_company_list", "get", {}, (res) => {
        this.company_list = res;
      });
    },
    submit() {
      let {
        refund_id,
        logistics_id,
        logistics_sn,
        return_remarks,
        return_images,
      } = { ...this };
      if (logistics_id === "") {
        this.$message({
          message: "请选择物流公司",
          type: "error",
          offset:170
        });
        return;
      }
      if (logistics_sn == "") {
        this.$message({
          message: "请填写物流单号",
          type: "error",
          offset:170
        });
        return;
      }
      return_images = return_images.map((value) => {
        return value.upimgUrl;
      });
      return_images = return_images.join(",");
      this.$myAjax(
        "order/input_logistics_info",
        "post",
        {
          refund_id,
          logistics_id,
          logistics_sn,
          return_remarks,
          return_images,
        },
        (res) => {
          console.log(res);
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1000,
            offset:170,
            onClose: () => {
              this.$router.back();
            },
          });
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  min-height: 1157px;
  .fromtop {
    margin-top: 50px;
    .leftcontent {
      width: 900px;
      float: left;
      .status {
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        .title {
          font-size: 30px;
          color: #000000;
          font-weight: bold;
        }
        .status_canbe {
          margin-top: 32px;
          font-size: 16px;
          font-weight: bold;
        }
        .tips {
          margin-top: 17px;
          font-size: 14px;
          color: #333333;
        }
      }
      .servertype {
        padding: 23px 0;
        border-bottom: 1px solid #e6e6e6;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
      }
      .server {
        margin-top: 40px;
        width: 298px;
        height: 118px;
        border: 1px solid #000000;
        cursor: pointer;
        .icon {
          font-size: 36px;
          margin-left: 37px;
          color: #666666;
          margin-top: 38px;
          float: left;
        }
        .textbox {
          margin-left: 22px;
          float: left;
          .th {
            font-size: 16px;
            color: #000000;
            margin-top: 35px;
          }
          .td {
            font-size: 14px;
            color: #666666;
            margin-top: 5px;
          }
        }
      }
      .rebacktitle {
        margin-top: 30px;
        padding: 24px 0;
        border-bottom: 1px solid #e6e6e6;
        font-size: 16px;
        color: #000000;
      }
      .rebackidtitle {
        font-size: 14px;
        color: #000000;
        margin-top: 32px;
        span {
          color: #e70012;
        }
      }
      .elminput {
        width: 100%;
        margin-top: 18px;
      }
      .imgbox {
        height: 155px;
        margin-top: 31px;

        div {
          position: relative;
    width: 155px;
    float: left;
    /* height: 155px; */
    margin-left: 20px;
    cursor: pointer;
          .imaga {
            position: relative;
            float: left;
            width: 155px;
            height: 155px;
          }
          .imagb{
            position: absolute;
            right: 4px;
            top: 4px;
            width: 30px;
            height: 30px;
            background: white;
            border-radius: 50%;
          }
        }
       
        .upimg {
          width: 155px;
          height: 155px;
          float: left;
          margin-left: 20px;
          // line-height: 155px;
          text-align: center;
          cursor: pointer;
          border: 1px solid #dbdbdb;
          .icon {
            font-size: 31px;
            color: #666;
            margin-top: 34px;
            margin-bottom: 19px;
          }
        }
        :first-child {
          margin-left: 0;
        }
      }
      .beizhu {
        padding: 35px 0 0 0;
        font-size: 14px;
        color: #000000;
        margin-top: 55px;
      }
      .submit {
        width: 168px;
        height: 48px;
        background: #e70010;
        float: right;
        margin-top: 117px;
        text-align: center;
        line-height: 48px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .rightcontent {
      width: 480px;
      float: right;
      .refundinfo {
        width: 480px;
        height: 270px;
        background: #fff;
        .refundtitle {
          height: 64px;
          text-align: center;
          line-height: 64px;
          border-bottom: 1px solid #e6e6e6;
          font-size: 20px;
          color: #000000;
        }
        .refundrow {
          padding: 0 27px 0 34px;
          width: 100%;
          float: left;
          margin-top: 15px;
          span {
            font-size: 16px;
            color: #111111;
          }
          .rowtitle {
            float: left;
          }
          .rowvalue {
            float: right;
            font-size: 14px;
          }
        }
      }
    }
    .help {
      background-color: #fff;
      margin-top: 12px;
      padding: 0 30px;
      .checks {
        padding: 1px 0;
        .s {
          width: 100%;
          border-top: 1px solid #ccc;
        }
        .checks_check {
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          .checktops {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            flex-direction: row;
            .lefts {
              display: flex;
              align-items: center;
              .icon {
                font-size: 22px;
              }
              .desc {
                margin-left: 14px;
                color: #000000;
                line-height: 24px;
              }
            }
            .icont {
              font-size: 14px;
            }
          }
          .contents {
            color: #7c7c7c;
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
