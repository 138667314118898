<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <div class="leftcontent">
          <div class="status">
            <p class="title">订单详情</p>
            <p class="status_canbe">
              {{
                database.status == 1
                ? "待付款"
                : database.status == 2
                  ? "待发货"
                  : database.status == 3
                    ? "已发货"
                    : database.status == 4
                      ? "已收货"
                      : database.status == 5
                        ? "已评价"
                        : database.status == 6
                          ? "已取消"
                          : database.status == 7
                            ? "已退款"
                            : ""
              }}
            </p>
            <p class="tips" v-if="database.status == 1">
              请您在<span style="color: #e00b24">{{ time(pre_at) }}</span>内付款，超过则自动取消订单
            </p>
            <p class="tips" v-if="database.status == 2">
              请耐心等待商家发货，24小时内完成收货
            </p>
            <p class="tips" v-if="database.status == 3">
              商家已发货，七天自动收货
            </p>
            <p class="tips" v-if="database.status == 4">
              交易成功，七天自动收货
            </p>
          </div>
          <div class="status">
            <p class="ids">订单编号：{{ database.order_sn }}</p>
            <p class="row">下单日期：{{ database.createtime }}</p>
            <p class="row">
              送货地址：{{ database.contacts }} {{ database.mobile }}
              {{ database.province }}{{ database.city }}{{ database.area
              }}{{ database.address }}
            </p>
            <!-- <p class="row">发货方式：xxxxxxx</p>
            <p class="row">物流配送：xxxxx</p> -->
            <p class="row">支付方式：{{ database.pay_type_format }}</p>
            <p class="row">获取积分：{{ database.get_score }}</p>
            <p class="row" v-if="database.pay_time">
              支付时间：{{ database.pay_time }}
            </p>
            <p class="price">
              支付总额：<span>￥{{ database.actual_pay }}</span>
            </p>
            <div class="ordercation">
              <div class="limitpay" @click="paytype = true" v-if="database.status == 1">
                立即支付
              </div>
              <div class="canclorder" @click="chooseothers" v-if="database.status == 2">
                修改地址
              </div>
              <div class="limitpay" v-if="database.status == 3 ||
                database.status == 4 ||
                database.status == 5
                " @click="tocarpos">
                查看物流
              </div>
              <div class="limitpay" @click="confirmorder" v-if="database.status == 3">
                确认收货
              </div>
              <div class="canclorder" v-if="database.status == 1" @click="cancelorder">
                取消订单
              </div>
              <div class="canclorder" v-if="database.status == 6" @click="deleteorder">
                删除订单
              </div>
            </div>
          </div>
          <div class="status">
            <div style="display:flex; justify-content:flex-start">
              <p class="shoptitle">商品详情</p>
              <p class="shoptitle  shoptitles"> <el-checkbox v-model="allchecked" @change="allcheckeds" v-if="database.can_refund == 1 &&
                ((database.warehouse_id == 1 &&
                  database.status > 1 &&
                  database.status <= 5 &&
                  database.status != 3) ||
                  (database.warehouse_id == 2 && database.status == 4))
                ">全选</el-checkbox> </p>
              <p class="shoptitle  shoptitles" v-if="database.can_refund == 1 &&
                ((database.warehouse_id == 1 &&
                  database.status > 1 &&
                  database.status <= 5 &&
                  database.status != 3) ||
                  (database.warehouse_id == 2 && database.status == 4))
                " style="padding-top: 0px;"> <span class="shouhou" @click="pshouhou">批量售后</span> </p>
            </div>
            <div v-for="(item, index) in database.goods_list" :key="index">
              <div v-if="item.is_refund == 2 &&
                database.can_refund == 1 &&
                ((database.warehouse_id == 1 &&
                  database.status > 1 &&
                  database.status <= 5 &&
                  database.status != 3) ||
                  (database.warehouse_id == 2 && database.status == 4))
                "> <el-checkbox v-model="item.ischecked" @change="taps(item.order_goods_id)"></el-checkbox> </div>
              <div class="shopscontent">
                <img :src="item.goods_image" :alt="item.goods_title" @click="toshopdetail(item.goods_id)" />
                <div class="contents">
                  <p class="name">{{ item.goods_title }}</p>
                  <p class="row" v-if="item.goods_sn.length > 0">
                    商品编码：{{ item.goods_sn }}
                  </p>
                  <p class="row" v-if="item.spec_value.length > 0">
                    规格:{{ item.spec_value }}
                  </p>
                  <p class="row">数量:
                    <span class="info_num">
                      <i class="icon tardiness" @click="shopsnumchange(item, index, 'less')">&#xe711;</i>
                      <span style="border:1pxs solid black;padding:10px  5px">{{ item.true_num }}</span>
                      <i class="icon tardiness" @click="shopsnumchange(item, index, 'plus')">&#xe60a;</i>
                    </span>
                  </p>
                  <p class="price">￥{{ item.goods_price }}</p>
                </div>
                <!-- 再次购买，评价晒单，确认收货 -->
                <div class="redbtn" @click="tocomet(item.goods_id, item.sku_unique)"
                  v-if="database.status == 4 && item.is_comment == 2">
                  评价晒单
                </div>
                <!-- 申请售后，查看物流 -->
                <div v-if="item.true_num>0">
                <div class="graybtn" v-if="item.is_refund == 2 &&
                  database.can_refund == 1 &&
                  ((database.warehouse_id == 1 &&
                    database.status > 1 &&
                    database.status <= 5 &&
                    database.status != 3) ||
                    (database.warehouse_id == 2 && database.status == 4))
                  " @click="toapply(item.order_goods_id, item.type, item.true_num)">
                  申请售后
                </div>
              </div>
              </div>
              <div class="shopscontent" v-for="(v, i) in item.gift_info" :key="'gg' + i">
                <img :src="v.image" :alt="v.title" />
                <div class="contents">
                  <p class="name">{{ v.title }}</p>
                  <p class="row gift_txt">赠品</p>
                  <p class="row">数量:1</p>
                </div>
              </div>
            </div>
            <p class="shoptitle" v-if="database.gift_list && database.gift_list.length > 0">
              赠品活动
            </p>
            <div class="shopscontent" v-for="(item, key) in database.gift_list" :key="'g' + key">
              <img :src="item.gift_image" :alt="item.gift_title" />
              <div class="contents">
                <p class="name">{{ item.gift_title }}</p>
                <p class="row gift_txt">赠品</p>
                <p class="row">数量:1</p>
              </div>
            </div>
          </div>
          <div class="status">
            <div class="pricedetail">
              <span>商品金额</span>
              <span class="fromright">￥{{ database.total_price }}</span>
            </div>
            <div class="pricedetail">
              <span>活动优惠</span>
              <span class="fromright">-￥{{ database.active_price || 0.00 }}</span>
            </div>
            <div class="pricedetail" v-if="database.coupon_price > 0">
              <span>优惠券 </span>
              <span class="fromright">-￥{{ database.coupon_price }}</span>
            </div>
            <!-- <div class="pricedetail">
              <span>活动优惠 </span>
              <span class="fromright">-￥0</span>
            </div> -->
            <div class="pricedetail">
              <span>积分抵扣</span>
              <span class="fromright">-￥{{ database.score_price }}</span>
            </div>
            <div class="pricedetail">
              <span> 运费</span>
              <span class="fromright">{{ database.freight }}</span>
            </div>
            <div class="pricedetail">
              <span>实付金额</span>
              <span class="fromright">￥{{ database.actual_pay }}</span>
            </div>
          </div>
        </div>
        <div class="rightcontent">
          <div class="helps">
            <div v-if="deliveryInfo">
              <div class="checks_check">
                <div class="checktops" @click="distate = !distate">
                  <div class="desc">{{ deliveryInfo.title }}</div>
                  <div class="icon tardiness icont">
                    {{ distate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="distate" v-html="deliveryInfo.content"></div>
              </div>
            </div>
            <div v-if="payWay">
              <div class="s"></div>
              <div class="checks_check">
                <div class="checktops" @click="pwstate = !pwstate">
                  <div class="desc">{{ payWay.title }}</div>
                  <div class="icon tardiness icont">
                    {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="pwstate" v-html="payWay.content"></div>
              </div>
            </div>
            <div v-if="productGood">
              <div class="s"></div>
              <div class="checks_check">
                <div class="checktops" @click="pgstate = !pgstate">
                  <div class="desc">{{ productGood.title }}</div>
                  <div class="icon tardiness icont">
                    {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="pgstate" v-html="productGood.content"></div>
              </div>
            </div>
            <div v-if="returnExchangeService">
              <div class="s"></div>
              <div class="checks_check">
                <div class="checktops" @click="restate = !restate">
                  <div class="desc">{{ returnExchangeService.title }}</div>
                  <div class="icon tardiness icont">
                    {{ restate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="restate" v-html="returnExchangeService.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-show="paytype || chooseaddress">
      <div class="windows" v-if="paytype">
        <div class="widowtitle">
          支付方式
          <div class="icon tardiness" @click="paytype = false">&#xe61c;</div>
        </div>
        <div class="payWay_way">
          <!-- <div class="way" @click="tofetchpay('A01')">
            <img src="@/assets/img/alipay.png" class="alipay" alt="s" />
          </div> -->
          <div class="way" @click="tofetchpay('W01')">
            <img src="@/assets/img/wechat.png" class="wechatpay" alt="s" />
          </div>
        </div>
      </div>
      <div class="windowsadd" v-if="chooseaddress">
        <div class="widowtitle">
          选择地址
          <div class="icon tardiness" @click="chooseaddress = false">
            &#xe61c;
          </div>
        </div>
        <div class="showaddressBox">
          <div class="moreninfos" v-for="(item, index) in showAddressData" :key="index" @click="choosethis(index)">
            <div class="namebox">
              <span>{{ item.contacts }}</span>
              <div class="morentag" v-if="item.is_default == 1">默认</div>
            </div>
            <p class="address">
              {{ item.province }} {{ item.city }} {{ item.area }}
              {{ item.address }}
            </p>
            <p class="phone">{{ item.mobile }}</p>
            <p class="phone">{{ item.email }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      paytype: false,
      order_id: "",
      database: {},
      productGood: {},
      payWay: {},
      deliveryInfo: {},
      returnExchangeService: {},
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      chooseaddress: false,
      pre_at: 0,
      list: {},
      ticker: null,
      checkList: [],
      allchecked: false,
      state:false
    };
  },
  mounted() {
    this.order_id = this.$route.query.id;
    this.getOrderDetail();
    this.get_goods_program();
  },
  methods: {
    pshouhou() {
      
       if(this.state==false){
        this.state=true
        let list = this.database.goods_list
      let lists = []
      list.map(item => {
        if (item.ischecked === true) {
          lists.push(item.order_goods_id)
        }
      })
      this.$router.push({
        name: "applybackall",
        query: {
          order_id: this.order_id,
          list:this.database.goods_list,
          lists:lists
        },
      });
       }

       setTimeout(() => {
        this.state=false
       }, 6000);
     
    },
    allcheckeds() {
      console.log(this.allchecked)
      let list = this.database.goods_list
      console.log(list)
      if (this.allchecked == true) {
        list.map(item => {
          if(item.true_num>0){
            item.ischecked = true
          }
        })
      } else {
        list.map(item => {
          item.ischecked = false
        })
      }
      this.database.goods_list = list
    },
    taps(e) {
      console.log(e)
      this.ischecked()
    },

    ischecked() {
      let state = true
      let list = this.database.goods_list
      list.map(item => {
        if (item.ischecked == false) {
          state = false
          return
        }
      })
      this.allchecked = state
    },

    shopsnumchange(_key, _index, type) {
      console.log(_key)
      if (type == "less") {
        if (_key.true_num > 1) {
          _key.true_num = _key.true_num - 1
        }
      } else {
        if (_key.true_num < this.list.goods_list[_index].true_num) {
          _key.true_num = _key.true_num + 1
        }
      }
    },
    setTime() {
      this.ticker = setInterval(() => {
        this.pre_at = this.pre_at - 1000;
      }, 1000);
    },
    chooseothers() {
      this.$myAjax("user/address_list", "get", {}, (res) => {
        this.showAddressData = res;
        this.chooseaddress = true;
      });
    },
    choosethis(_index) {
      let order_id = this.order_id;
      let address_id = this.showAddressData[_index].address_id;
      this.$myAjax(
        "order/edit_address",
        "post",
        {
          order_id,
          address_id,
        },
        () => {
          this.getOrderDetail();
          this.chooseaddress = false;
        }
      );
    },
    tocomet(goods_id, sku_unique) {
      this.$router.push({
        name: "CometOrder",
        query: {
          order_id: this.order_id,
          goods_id: goods_id,
          sku_unique: sku_unique,
        },
      });
    },
    toapply(order_goods_id, type, num) {
      let menu_id = 0;
      if (type == 2) {
        menu_id = order_goods_id;
        order_goods_id = 0;
      }
      this.$router.push({
        name: "ApplyBack",
        query: {
          order_id: this.order_id,
          order_goods_id: order_goods_id,
          refund_num: num,
          menu_id: menu_id,
        },
      });
    },
    tocarpos() {
      this.$router.push({
        name: "Ali",
        query: {
          order_id: this.order_id,
        },
      });
    },
    getOrderDetail() {
      this.$myAjax(
        "order/pc_order_details?order_id=" + this.order_id,
        "get",
        {},
        (res) => {
          res.goods_list.map(item => {
            item.ischecked = false
          })
          this.database = res;
          this.list = JSON.parse(JSON.stringify(res))
          if (res.pay_type.indexOf("A") > -1) {
            this.database.pay_type_format = "支付宝支付";
          } else if (res.pay_type.indexOf("W") > -1) {
            this.database.pay_type_format = "微信支付";
          } else {
            this.database.pay_type_format = "在线支付";
          }
          this.pre_at =
            this.database.expire_time_int * 1000 - new Date().getTime();
          this.setTime();
        }
      );
    },
    time(time) {
      if (time >= 0) {
        let h =
          Math.floor((time / 1000 / 60 / 60) % 24) < 10
            ? "0" + Math.floor((time / 1000 / 60 / 60) % 24)
            : Math.floor((time / 1000 / 60 / 60) % 24);
        let m =
          Math.floor((time / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((time / 1000 / 60) % 60)
            : Math.floor((time / 1000 / 60) % 60);
        let s =
          Math.floor((time / 1000) % 60) < 10
            ? "0" + Math.floor((time / 1000) % 60)
            : Math.floor((time / 1000) % 60);
        return h + ":" + m + ":" + s;
      } else {
        clearInterval(this.ticker);
        return "00:00:00";
      }
    },
    tofetchpay(pay_type) {
      this.$router.push({
        name: "fetchPay",
        query: {
          pay_type: pay_type,
          order_id: this.order_id,
        },
      });
    },
    cancelorder() {
      let { order_id } = { ...this };
      this.$confirm("确定取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$myAjax(
            "order/cancel_order",
            "post",
            {
              order_id,
            },
            () => {
              this.$message({
                type: "success",
                message: "取消成功!",
                offset: 170,
              });
              this.getOrderDetail();
            }
          );
        })
        .catch(() => { });
    },
    deleteorder() {
      let { order_id } = { ...this };
      this.$confirm("确定删除订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$myAjax(
            "order/del_order",
            "post",
            {
              order_id,
            },
            () => {
              this.$message({
                type: "success",
                message: "删除成功!",
                offset: 170,
              });
              this.$router.back();
            }
          );
        })
        .catch(() => { });
    },
    confirmorder() {
      let { order_id } = { ...this };
      this.$confirm("确定确认收货?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$myAjax(
            "order/confirm_receipt",
            "post",
            {
              order_id,
            },
            () => {
              this.$message({
                type: "success",
                message: "确认收货成功!",
                offset: 170,
              });
              this.getOrderDetail();
            }
          );
        })
        .catch(() => { });
    },
    get_goods_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.productGood = res.common_problem;
        this.deliveryInfo = res.delivery_info;
        this.payWay = res.pay_way;
        this.returnExchangeService = res.electronic_invoice;
      });
    },
    toshopdetail(id) {
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${id}`),
        },
      });
    },
  },
  destroyed() {
    clearInterval(this.ticker);
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  min-height: 1157px;

  .fromtop {
    margin-top: 50px;

    .leftcontent {
      width: 60%;
      float: left;

      .status {
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;

        .title {
          font-size: 30px;
          color: #000000;
          font-weight: bold;
        }

        .status_canbe {
          margin-top: 32px;
          font-size: 16px;
          font-weight: bold;
        }

        .tips {
          margin-top: 17px;
          font-size: 14px;
          color: #333333;
        }

        .ids {
          margin-top: 21px;
          font-size: 14px;
          color: #111111;
        }

        .row {
          margin-top: 22px;
          color: #111111;
          font-size: 14px;
        }

        .price {
          margin-top: 20px;
          color: #111111;
          font-size: 14px;

          span {
            font-size: 18px;
            font-weight: bold;
          }
        }

        .ordercation {
          overflow: auto;
        }

        .limitpay {
          width: 168px;
          height: 48px;
          background: #e60012;
          margin-top: 32px;
          font-size: 14px;
          color: #ffffff;
          font-weight: bold;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
          float: left;
          margin-right: 10px;
          border-radius: 5px;
        }

        .canclorder {
          width: 168px;
          height: 48px;
          background: #f2f2f2;
          border: 1px solid #999999;
          margin-top: 32px;
          font-size: 14px;
          color: #999999;
          font-weight: bold;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
          float: left;
          margin-right: 10px;
          border-radius: 5px;
        }

        .shoptitle {
          padding: 45px 0 27px 0;
          font-size: 16px;
          color: #000000;
        }

        .shopscontent {
          padding: 10px 0;
          display: inline-block;
          width: 100%;

          img {
            width: 205px;
            height: 205px;
            float: left;
            cursor: pointer;
          }

          .contents {
            margin-left: 30px;
            float: left;

            .name {
              font-size: 20px;
              color: #111111;
            }

            .row {
              margin-top: 10px;
              color: #111111;
              font-size: 16px;
            }

            .price {
              margin-top: 30px;
              font-size: 18px;
              font-weight: bold;
              color: #111111;
            }

            .gift_txt {
              display: inline-block;
              border: 1px solid #e60012;
              color: #e60012;
              padding: 0 10px;
              border-radius: 5px;
            }
          }

          .redbtn {
            width: 100px;
            height: 48px;
            background: #e70010;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            line-height: 48px;
            text-align: center;
            float: right;
            margin-top: 157px;
            cursor: pointer;
          }

          .graybtn {
            width: 100px;
            height: 48px;
            background: #f2f2f2;
            border: 1px solid #999999;
            font-size: 14px;
            font-weight: bold;
            color: #999999;
            line-height: 48px;
            text-align: center;
            float: right;
            margin-top: 157px;
            cursor: pointer;
            margin-right: 17px;
          }
        }

        .pricedetail {
          margin-top: 15px;
          display: inline-block;
          width: 100%;

          span {
            font-size: 16px;
            color: #111111;
            float: left;
          }

          .fromright {
            float: right;
          }
        }
      }

      .beizhu {
        padding: 25px 0;
        font-size: 14px;
        color: #000000;
      }
    }

    .rightcontent {
      width: 480px;
      float: right;
    }

    .helps {
      width: 480px;
      display: inline-block;
      margin-top: 14px;
      padding: 0 30px;
      background-color: #fff;

      .s {
        width: 100%;
        border-top: 1px solid #ccc;
      }

      .checks_check {
        margin: 20px 0;
        display: flex;
        flex-direction: column;

        .checktops {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          flex-direction: row;

          .lefts {
            display: flex;
            align-items: center;

            .icon {
              font-size: 22px;
            }

            .desc {
              margin-left: 14px;
              color: #000000;
              line-height: 24px;
            }
          }

          .icont {
            font-size: 14px;
          }
        }

        .contents {
          color: #7c7c7c;
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;

  .windows {
    width: 400px;
    min-height: 200px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -265px;
    position: relative;

    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #000000;
      font-size: 16px;

      .icon {
        position: absolute;
        color: #666666;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }

    .way {
      text-align: center;
      // padding: 20px;
    }

    .alipay {
      width: 200px;
      height: auto;
      border: 1px solid #e6e6e6;
      text-align: center;
      cursor: pointer;
      margin: 40px 0 20px 0;
      padding: 20px 30px;
    }

    .wechatpay {
      width: 200px;
      height: auto;
      border: 1px solid #e6e6e6;
      text-align: center;
      cursor: pointer;
      margin: 20px 0 40px 0;
      padding: 20px 30px;
    }
  }
}

.windowsadd {
  width: 603px;
  height: 627px;
  background: #fff;
  top: 50%;
  left: 50%;
  margin-left: -301.5px;
  margin-top: -313.5px;
  position: relative;

  .widowtitle {
    position: relative;
    height: 55px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    text-align: center;
    line-height: 55px;
    color: #fff;
    font-size: 16px;
    background: #e60012;

    .icon {
      position: absolute;
      color: #fff;
      right: 22px;
      top: 0;
      cursor: pointer;
    }
  }

  .showaddressBox {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    height: 572px;
    overflow-y: auto;

    .moreninfos {
      float: left;
      display: inline-block;
      border-bottom: 1px solid #eee;
      cursor: pointer;

      .namebox {
        margin-top: 27px;
        width: 100%;
        display: inline-block;

        span {
          color: #000000;
          font-size: 16px;
          float: left;
        }

        .morentag {
          margin-left: 16px;
          width: 41px;
          height: 19px;
          border: 1px solid #666666;
          text-align: center;
          line-height: 19px;
          font-size: 13px;
          color: #666666;
          float: left;
        }
      }

      .address {
        margin-top: 10px;
        font-size: 14px;
        color: #666666;
      }

      .phone {
        margin-top: 8px;
        font-size: 14px;
        color: #666666;
      }
    }
  }

  .contents {
    padding: 0 40px;

    .title {
      font-size: 14px;
      color: #000000;
    }

    .eleinput {
      width: 100%;
      margin-top: 12px;
    }

    .eleinput2 {
      width: 250px;
      margin-top: 12px;
    }

    .eleinput3 {
      margin-top: 12px;
      width: 250px;
      margin-left: 22px;
    }

    .setbox {
      margin-top: 34px;
      cursor: pointer;

      .icon {
        font-size: 17px;
        color: #000000;
        float: left;
      }

      span {
        margin-left: 9px;
        font-size: 14px;
        color: #000000;
        float: left;
      }
    }

    .savebtn {
      width: 182px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #e60012;
      margin-top: 33px;
      font-size: 16px;
      font-weight: bold;
      color: #e60012;
      text-align: center;
      line-height: 48px;
      position: relative;
      left: 50%;
      margin-left: -182px;
      float: left;
      cursor: pointer;
    }
  }
}

.shoptitles {
  margin-left: 20px;
}

.info_num {
  width: 110px;
  height: 40px;
  border: 1px solid #ccc;
  line-height: 40px;
  text-align: center;
  margin-top: 27px;

  span {
    margin: 0 10px;
    font-size: 16px;
  }

  .icon {
    margin: 0 10px;
    cursor: pointer;
    font-size: 14px;
  }
}

.shouhou{
  width: 168px;
    height: 48px;
    background: #e60012;
    margin-top: 32px;
    font-size: 14px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    line-height: 48px!important;
    cursor: pointer;
    float: left;
    margin-right: 10px;
    border-radius: 5px;
}

</style>
