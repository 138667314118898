<template>
  <div class="sifting-list" :class="{ is_fixed: is_fixed }">
    <div
      class="list-title"
      :class="!is_show ? 'setW' : ''"
      :style="{ top: top }"
      ref="list_title"
    >
      <div class="title-name">
        <slot name="title_name">{{ shiwtitle }}</slot>
      </div>
      <div class="conditional-sort">
        <div v-if="show_list" class="list-btn" @click="hideleft">
          <span>{{ is_show ? "隐藏" : "显示" }}条件筛选</span>
          <span
            class="icon-filters css-kd8nn6"
            :class="is_show ? 'is--toggled' : ''"
          ></span>
        </div>
        <span>按下列条件排序： </span>
        <el-select v-model="value" @change="sortChange" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="list-content" v-if="shopsData.length > 0">
      <div class="goods-card" v-for="(v, i) in shopsData" :key="i">
        <div class="img">
          <i
            :class="v.is_follow == 1 ? 'icon iconon' : 'icon iconnot'"
            @click.stop="followChange(v.goods_id, i)"
            v-if="v.market_type != 3 && show_follow"
            >{{ v.is_follow == 1 ? "&#xe60f;" : "&#xe611;" }}</i
          >
          <img
            @click="toshopdetail(v.goods_id)"
            :src="v.image"
            :alt="v.subtitle"
          />
        </div>
        <div class="swiper-container" :class="'swiper-container' + i">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(sku, key) in v.sku_image"
              :key="key"
            >
              <img
                :src="sku"
                @click="handleSku(i, key)"
                :class="v.sku_img_index === key ? 'sku_on' : ''"
              />
            </div>
          </div>
          <div class="swiper-button-prev" v-show="v.sku_image.length > 5">
            <i class="icon tardiness icon-arrow">&#xe62e;</i>
          </div>
          <div class="swiper-button-next" v-show="v.sku_image.length > 5">
            <i class="icon tardiness icon-arrow">&#xe62c;</i>
          </div>
        </div>

        <div class="goods-name">
          {{ v.subtitle }}
        </div>
        <slot name="goods_price" :goods="v">
          <div class="goods-price">
            <span class="price"
              >￥{{
                v.market_type == 2
                  ? v.discount_price
                  : v.market_type == 3
                  ? v.member_price
                  : v.price
              }}{{v.spec_type==2?'起':''}}</span
            >
            <span
              class="sale-price"
              v-if="v.market_type == 3 || v.market_type == 4"
              >￥{{ v.cost_price }}{{v.spec_type==2?'起':''}}</span
            >
            <i class="icon" v-if="v.warehouse_id == 2">&#xe637;</i>
          </div>
        </slot>
        <div class="remain">
          <slot name="remain" :goods="v"> </slot>
        </div>
      </div>
    </div>
    <div class="nodatas" v-else>
      <p style="line-height: 50px; color: #b5bace">
        {{ tips != "" ? tips : "没有找到匹配商品，可修改筛选内容试试" }}
      </p>
      <img src="@/assets/img/lesssearch.png" alt="" />
    </div>
    <!-- <div class="more-goods" v-if="page < total_page" @click="loadmore">
      发现更多
    </div> -->
    <el-pagination
      v-if="total_size > 0"
      @current-change="loadmore"
      :current-page.sync="page_new"
      :page-count="total_page"
      :total="total_size"
      :page-size="32"
      layout=" total,prev, pager, next,jumper"
    >
    </el-pagination>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  props: {
    shopsData: Array,
    total_page: Number,
    total_size: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    shiwtitle: String,
    show_list: Boolean,
    show_follow: {
      type: Boolean,
      default: true,
    },
    active_type:String,
    tips: String,
     active:{
       type: Object,
       default: () => ({
        name:String,
        active_type:Number,
        status:Number
      })
    },
  },
  data() {
    return {
      options: [
        {
          value: "",
          label: "综合排序",
        },
        {
          value: "price,asc",
          label: "价格升序",
        },
        {
          value: "price,desc",
          label: "价格降序",
        },
        // {
        //   value: "sales,asc",
        //   label: "销量升序",
        // },
        {
          value: "sales,desc",
          label: "热门排序",
        },
      ],
      value: "综合排序",
      is_show: true,
      is_fixed: false,
      top: "0px",
      list_top: -1,
      page1: 1,
    };
  },
  mounted() {},
  computed: {
    page_new: {
      get() {
        return this.page;
      },
      set(v) {
        this.page1 = v;
      },
    },
  },
  watch: {
    shopsData(value) {
      this.$nextTick(() => {
        for (let i = 0; i < value.length; i++) {
          new Swiper(".swiper-container" + i, {
            slidesPerView: 5,
            spaceBetween: 5,
            navigation: {
              nextEl: `.swiper-container${i} .swiper-button-next`,
              prevEl: `.swiper-container${i} .swiper-button-prev`,
            },
          });
        }
        window.addEventListener("scroll", this.handleScroll);
      });
    },
  },
  updated() {},
  methods: {
    loadmore() {
      this.$parent.loadmore(this.page1);
    },
    followChange(_id, i) {
      let url = "";
      this.shopsData[i].is_follow == 1
        ? (url = "user/cancel_follow")
        : (url = "user/add_follow");
      this.$myAjax(
        url,
        "post",
        {
          goods_id: _id,
        },
        () => {
          this.shopsData[i].is_follow == 1
            ? ((this.shopsData[i].is_follow = 2),
              this.$message({
                message: "已取消收藏",
                type: "success",
                offset: 170,
              }))
            : ((this.shopsData[i].is_follow = 1),
              this.$message({
                message: "收藏成功",
                type: "success",
                offset: 170,
              }));
        }
      );
    },
    toshopdetail(id) {

      if(this.active.status==3){
         this.$message({
                message: "活动已结束",
                type: "success",
                offset: 170,
           })
           return 
      }
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${id}`),
        },
        query:{
        active_type:this.active_type
        },
      });

    },
    sortChange(e) {
      this.$emit("update", e);
    },
    hideleft() {
      this.$emit("hideleft");
      this.is_show = !this.is_show;
    },
    handleScroll: function () {
      this.currentScroll = window.pageYOffset;
      this.innerHeight = window.innerHeight;
      if (this.list_top < 0) {
        this.list_top =
          window.pageYOffset +
          this.$refs.list_title.getBoundingClientRect().top;
      }
      if (
        this.currentScroll - this.list_top >= 80 ||
        this.list_top - this.currentScroll <= 80
      ) {
        this.is_fixed = true;
        this.top = this.currentScroll - this.list_top + 80 + "px";
      } else {
        this.top = 0;
        this.is_fixed = false;
      }
    },
    handleSku: function (i1, i2) {
      this.$emit("handlesku", { i1, i2 });
    },
  },
};
</script>
<style lang="less">
.sifting-list {
  .list-title {
    padding: 20px 0 30px 0;
    top: 0;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 999;
    .title-name {
      span.time {
        span {
          color: #fff;
          display: inline-block;
          width: 30px;
          height: 30px;
          background-color: red;
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
  &.is_fixed {
    .list-title {
      // max-width: 100%;
      &.setW {
        // left: 40px;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-image: unset;
    outline: none;
    width: auto;
    height: auto;
    .icon-arrow {
      font-size: 30px;
    }
  }
  .el-pagination {
    text-align: center;
    .el-pager li:hover {
      color: #e00b24;
    }
    .el-pager li.active {
      color: #e00b24;
      cursor: default;
    }
    button:hover {
      color: #e00b24;
    }
  }
}
</style>
<style lang="less" scoped>
.sifting-list {
  width: calc(100% - 300px);
  // padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid transparent;
  position: relative;
  padding-top: 90px;
  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    .title-name {
      font-size: 18px;
      color: #222;
      .t30 {
        font-size: 30px;
        color: #000011;
      }
      span.time {
        color: red;
        margin-left: 17px;
      }
    }
    .conditional-sort {
      .list-btn {
        display: inline-block;
        margin: 0 26px 0 0;
        cursor: pointer;
        &:hover {
          color: #e00b24;
          .css-kd8nn6::before,
          .css-kd8nn6::after {
            color: #e00b24;
            background: #e00b24;
          }
        }
      }
      span {
        font-size: 14px;
        margin-right: 10px;
      }
      .css-kd8nn6 {
        width: 16px;
        height: 16px;
        position: relative;
        display: inline-block;
      }
      .css-kd8nn6::before {
        content: "";
        color: rgb(51, 51, 51);
        width: 100%;
        height: 2px;
        box-shadow: currentcolor 0px 7px 0px 0px;
        display: block;
        position: absolute;
        top: 5px;
        left: 0px;
        background: rgb(51, 51, 51);
      }
      .css-kd8nn6::after {
        content: "";
        color: rgb(51, 51, 51);
        width: 2px;
        height: 6px;
        box-shadow: currentcolor 8px 7px 0px 0px;
        display: block;
        position: absolute;
        top: 2px;
        left: 0px;
        transform: translate3d(3px, 1px, 0px);
        background: rgb(51, 51, 51);
        transition: transform 200ms ease 0s, box-shadow 200ms ease 0s;
      }
      .css-kd8nn6.is--toggled::after {
        box-shadow: currentcolor -8px 7px 0px 0px;
        transform: translate3d(11px, 1px, 0px);
      }
    }
  }
  .list-content {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 31px;
    .goods-card {
      width: 25%;
      text-align: center;
      padding: 0 5px 36px 5px;
      cursor: pointer;
      &:nth-child(4n + 1) {
        padding-left: 0;
        padding-right: 10px;
      }
      &:nth-child(4n) {
        padding-right: 0;
        padding-left: 10px;
      }

      .img {
        background-color: #fff;
        // height: 380px;
        text-align: center;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          // padding: 40px;
        }
        .iconon {
          float: right;
          font-size: 21px;
          color: #e70010;
          position: absolute;
          top: 16px;
          right: 16px;
        }
        .iconnot {
          float: right;
          font-size: 21px;
          color: #999;
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
      .goods-name {
        margin-top: 18px;
        margin-bottom: 12px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }
      .goods-ptitle {
        // margin-bottom: 5px;
      }
      .goods-price {
        font-size: 14px;
        color: #111111;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        span.price {
          font-size: 16px;
          // font-weight: bold;
          color: #111111;
          font-weight: 400;
        }
        span.sale-price {
          text-decoration: line-through;
          color: #999;
          margin-left: 14px;
        }
        .icon {
          color: #e00b24;
          font-size: 20px;
          padding-left: 5px;
          font-weight: 400;
        }
      }
      .remain {
        margin-top: 10px;
        overflow: hidden;

        .text {
          float: left;
          color: #999999;
          font-size: 12px;
          width: 40%;
          margin-right: 5%;
          text-align: right;
        }
        .progress {
          float: left;
          margin-top: 8px;
          margin-left: 10px;
          border-radius: 2px;
          width: 40%;
        }
        &::after {
          clear: both;
          content: "";
        }
      }
      .swiper-container {
        background: #fff;
        .swiper-slide {
          // padding: 5px;
        }
        img {
          width: 100%;
          &.sku_on {
            border: 1px solid #e00b24;
          }
        }
      }
    }
  }
  .page {
    text-align: center;
    margin-bottom: 26px;
    margin-top: 34px;
    font-size: 16px;
  }
  .nodatas {
    padding: 180px 0;
    text-align: center;
  }
  .more-goods {
    text-align: center;
    border: 1px solid #f00;
    width: 153px;
    height: 45px;
    line-height: 45px;
    margin: 10px auto;
    color: red;
    cursor: pointer;
  }
}
</style>
