<template>
  <div>
    <div class="bodycontent">
      <div class="mw">
        <div class="basicbox">
          <img :src="userInfo.avatar" alt="" />
          <div class="basic">
            <span>{{ userInfo.username }}</span>
            <div class="viptime">会员起始时间：{{ userInfo.createtime }}</div>
          </div>
          <router-link :to="{ name: 'MineBaby' }" class="babybox">
            <div class="icon">&#xe751;</div>
            <span>宝宝</span>
          </router-link>
        </div>
        <div class="accountbox">
          <div class="boxtitle">
            <div class="title">账户信息</div>
            <span>完善您的个人信息可获得更多优惠权益</span>
          </div>
          <div class="box_bottoms">
            <div class="personinfo">
              <div class="personphone">手机号码：{{ userInfo.mobile }}</div>
              <div class="personid">客 户 ID：{{ userInfo.user_no }}</div>
            </div>
            <div class="person_nums">
              <router-link :to="{ name: 'MineSelect' }" class="person_numsone">
                <div class="_number">{{ dataBase.follow_count }}</div>
                <div class="_text">收藏</div>
              </router-link>
              <router-link :to="{ name: 'MineFoot' }" class="person_numsone">
                <div class="_number">{{ dataBase.foot_count }}</div>
                <div class="_text">浏览足迹</div>
              </router-link>
              <router-link :to="{ name: 'MineCoups' }" class="person_numsone">
                <div class="_number">{{ dataBase.coupon_count }}</div>
                <div class="_text">优惠券</div>
              </router-link>
              <router-link :to="{ name: 'MineScore' }" class="person_numsone">
                <div class="_number">{{ userInfo.score }}</div>
                <div class="_text">积分</div>
              </router-link>
            </div>
            <div class="editbtn" @click="hreftourl('basicinfo')">编辑资料</div>
          </div>
        </div>
        <div class="vipcenter">
          <div class="boxtitle">
            <div class="title">会员中心</div>
            <span>您可以在这里查看和管理你的会员等级及会员权益</span>
          </div>
          <div class="vipcontent">
            <div class="vipinfo">
              <img :src="grade.image" alt="" />
              <div class="contents">
                <div>{{ grade.gain }}</div>
                <div>您当前的会员等级： {{ grade.name }}</div>
                <div>{{ grade.next_desc }}</div>
              </div>
            </div>
            <div class="looksvip" @click="hreftourl('VipCenter')">
              查看会员权益
            </div>
          </div>
        </div>
        <div class="ordersbox">
          <div class="boxtitle">
            <div class="title">我的订单</div>
            <span>您可以根据订单状态查看和管理您的订单</span>
          </div>
          <div class="listone" v-if="Object.keys(orderinfo).length > 0">
            <div class="listinfo">
              <span class="time">下单时间：{{ orderinfo.createtime }}</span>
              <span class="status"
                >{{
                  orderinfo.order_status == 1
                    ? "待付款"
                    : orderinfo.order_status == 2
                    ? "待发货"
                    : orderinfo.order_status == 3
                    ? "已发货"
                    : orderinfo.order_status == 4
                    ? "已收货"
                    : orderinfo.order_status == 5
                    ? "已评价"
                    : orderinfo.order_status == 6
                    ? "已取消"
                    : orderinfo.order_status == 7
                    ? "已退款"
                    : ""
                }}({{ dataBase.order_status_count }})</span
              >
            </div>
            <div class="shopsbox">
              <img :src="listdata.goods_image" alt="" />
              <div class="shopsinfo">
                <div class="name">
                  {{ listdata.goods_title }}
                </div>
                <div class="id">商品编号：{{ listdata.goods_sn }}</div>
                <div class="color">规格：{{ listdata.spec_value }}</div>
                <!-- <div class="size">尺码：M</div>
                <div class="num">数量：1</div> -->
              </div>
              <div class="pricebox">
                <div class="price">￥{{ orderinfo.actual_pay }}</div>
                <div class="metions" v-if="orderinfo.order_status == 1">
                  付款倒计时
                  <span style="color: #eb040c">{{ time(pre_at) }}</span>
                </div>
              </div>
              <div class="looksmore" @click="hreftourl('MineOrders')">
                查看全部订单
              </div>
            </div>
          </div>
          <div class="listone" v-else>
            <div class="nodatas">
              <img :src="nodataimg" alt="" class="nodataimg" />
              <div class="nodatatext">你的订单为空！</div>
            </div>
          </div>
        </div>
        <div class="appbox">
          <div class="boxtitle">
            <div class="title">相关应用</div>
            <span>您可以在这里查看、修改和添加您的地址</span>
          </div>
          <div class="appcontent">
            <router-link :to="{ name: 'AccountSafe' }" class="appone">
              <img :src="safe" alt="" />
              <span>账户与安全</span>
            </router-link>
            <router-link :to="{ name: 'OpinionBack' }" class="appone">
              <img :src="options" alt="" />
              <span>意见反馈</span>
            </router-link>
            <a
              class="appone"
              target="_blank"
              href="https://care60.live800.com/live800/chatClient/chatbox.jsp?companyID=80017004&configID=2889"
            >
              <img :src="chat" alt="" />
              <span>在线客服</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      safe: require("/src/assets/img/accountsafe.png"),
      options: require("/src/assets/img/opinion.png"),
      chat: require("/src/assets/img/onlinechat.png"),
      nodataimg: require("/src/assets/img/lessorder.png"),
      dataBase: {},
      userInfo: {},
      grade: {},
      orderinfo: {},
      listdata: {},

      newTime: "",
      timeOut: null,
      houer: "00",
      secend: "00",
      timeDay: "00",
      pre_at: 0,
    };
  },
  // created() {
  //   //  this.getBasicInfo();
  // },
  mounted() {
   this.getBasicInfo();
  },
  methods: {
    setTime() {
      this.ticker = setInterval(() => {
        this.pre_at = this.pre_at - 1000;
      }, 1000);
    },
    getBasicInfo() {
      this.$myAjax("user/index", "get", {}, (res) => {
        console.log(res);
        this.dataBase = res;
        this.userInfo = res.user_info;
        this.grade = res.grade_info;
        this.orderinfo = res.order_info;
        if (res.order_info.goods_list) {
          this.listdata = res.order_info.goods_list[0];
        }
        this.newTime = res.order_info.expire_time;
        this.pre_at =
          new Date(res.order_info.expire_time).getTime() - new Date().getTime();
        this.setTime();
      });
    },
    hreftourl: function (_name) {
      this.$router.push({ name: _name });
    },
    time(time) {
      if (time >= 0) {
        // let d = Math.floor(time / 1000 / 60 / 60 / 24);
        let h =
          Math.floor((time / 1000 / 60 / 60) % 24) < 10
            ? "0" + Math.floor((time / 1000 / 60 / 60) % 24)
            : Math.floor((time / 1000 / 60 / 60) % 24);
        let m =
          Math.floor((time / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((time / 1000 / 60) % 60)
            : Math.floor((time / 1000 / 60) % 60);
        let s =
          Math.floor((time / 1000) % 60) < 10
            ? "0" + Math.floor((time / 1000) % 60)
            : Math.floor((time / 1000) % 60);
        return h + ":" + m + ":" + s;
      } else {
        clearInterval(this.ticker);
        return "00:00:00";
      }
    },
  },
  destroyed() {
    clearInterval(this.ticker);
  },
};
</script>
<style lang="less" scoped>
.bodycontent {
  background: #fafafa;
  margin-top: 140px;
  width: 100%;
  height: 1097px;
  .basicbox {
    padding: 29px 0 30px 0;
    display: inline-block;
    width: 100%;
    img {
      width: 111px;
      height: 111px;
      border-radius: 50%;
      float: left;
    }
    .basic {
      margin-left: 19px;
      display: inline-block;
      align-content: center;
      span {
        color: #010101;
        font-size: 24px;
        float: left;
        width: 100%;
        margin-top: 20px;
      }
      .viptime {
        margin-top: 17px;
        color: #666666;
        font-size: 14px;
        float: left;
      }
    }
    .babybox {
      width: 90px;
      height: 40px;
      border: 1px solid #ea051a;
      border-radius: 22px;
      float: right;
      margin-top: 33px;
      display: inline-block;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      .icon {
        color: #e2000b;
        font-size: 25px;
        float: left;
        margin-left: 11px;
      }
      span {
        color: #e60112;
        font-size: 16px;
        float: left;
        margin-left: 5px;
      }
    }
  }
  .accountbox {
    background: #ffffff;
    width: 49.5%;
    float: left;
    height: 430px;
    .boxtitle {
      padding: 30px 0 22px 41px;
      border-bottom: 1px solid #e6e6e6;
      .title {
        font-size: 24px;
        color: #000000;
      }
      span {
        margin-top: 12px;
        color: #999999;
        font-size: 14px;
      }
    }
    .box_bottoms {
      margin: 0 41px;
      .personinfo {
        padding: 27px 0;
        border-bottom: 1px solid #e6e6e6;
        .personphone {
          color: #333333;
          font-size: 14px;
        }
        .personid {
          color: #333333;
          font-size: 14px;
          margin-top: 23px;
        }
      }
      .person_nums {
        width: 100%;
        padding: 35px 0;
        .person_numsone {
          width: 25%;
          display: inline-block;
          cursor: pointer;
          ._number {
            color: #333333;
            font-size: 18px;
            width: 100%;
            text-align: center;
            font-weight: bold;
          }
          ._text {
            color: #333333;
            font-size: 18px;
            margin-top: 10px;
            width: 100%;
            text-align: center;
            font-weight: bold;
          }
        }
      }
      .editbtn {
        width: 100%;
        height: 48px;
        background: #eb040c;
        line-height: 48px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .vipcenter {
    background: #ffffff;
    width: 49.5%;
    float: right;
    height: 430px;
    .boxtitle {
      padding: 30px 0 22px 41px;
      border-bottom: 1px solid #e6e6e6;
      .title {
        font-size: 24px;
        color: #000000;
      }
      span {
        margin-top: 12px;
        color: #999999;
        font-size: 14px;
      }
    }
    .vipcontent {
      margin: 0 41px;
      margin-top: 17px;
      .vipinfo {
        // display: inline-block;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 160px;
          // height: 171px;
          // float: left;
          margin-left: 20px;
        }
        .contents {
          // float: left;
          display: inline-block;
          width: 242px;
          // margin-top: 15px;
          margin-left: 30px;
          div {
            color: #333333;
            font-size: 14px;
            margin-top: 16px;
            float: left;
          }
        }
      }
      .looksvip {
        width: 100%;
        height: 48px;
        background: #eb040c;
        line-height: 48px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 83px;
      }
    }
  }
  .ordersbox {
    background: #ffffff;
    width: 49.5%;
    float: left;
    margin-top: 15px;
    height: 430px;
    .boxtitle {
      padding: 30px 0 22px 41px;
      border-bottom: 1px solid #e6e6e6;
      .title {
        font-size: 24px;
        color: #000000;
      }
      span {
        margin-top: 12px;
        color: #999999;
        font-size: 14px;
      }
    }
    .listone {
      margin: 0 41px;
      .listinfo {
        margin-top: 27px;
        display: inline-block;
        width: 100%;
        .time {
          color: #333333;
          font-size: 14px;
          float: left;
        }
        .status {
          color: #333333;
          font-size: 14px;
          float: right;
        }
      }
      .shopsbox {
        margin-top: 44px;
        display: inline-block;
        width: 100%;
        img {
          width: 111px;
          height: 111px;
          float: left;
        }
        .shopsinfo {
          margin-left: 16px;
          display: inline-block;
          color: #111111;
          font-size: 14px;
          max-width: 56%;
          .name {
            color: #111111;
            font-size: 18px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .id {
            margin-top: 13px;
          }
        }
        .pricebox {
          float: right;
          text-align: right;
          display: inline-block;
          margin-top: 20px;
          .price {
            font-size: 18px;
            font-weight: bold;
          }
          .metions {
            margin-top: 30px;
            color: #111111;
            font-size: 14px;
            span {
              color: #eb040c;
            }
          }
        }
        .looksmore {
          width: 100%;
          height: 48px;
          background: #eb040c;
          line-height: 48px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          margin-top: 70px;
        }
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 104px -74px;
        position: relative;
        transform: translateY(-50%);
        .nodataimg {
          width: 134px;
          height: auto;
          float: left;
        }
        .nodatatext {
          font-size: 20px;
          color: #000000;
          float: left;
          text-align: center;
        }
      }
    }
  }
  .appbox {
    background: #ffffff;
    width: 49.5%;
    float: right;
    margin-top: 15px;
    height: 430px;
    .boxtitle {
      padding: 30px 0 22px 41px;
      border-bottom: 1px solid #e6e6e6;
      .title {
        font-size: 24px;
        color: #000000;
      }
      span {
        margin-top: 12px;
        color: #999999;
        font-size: 14px;
      }
    }
    .appcontent {
      margin-top: 103px;
      padding: 0 41px;
      display: inline-block;
      width: 100%;
      .appone {
        width: 33.33%;
        display: inline-block;
        float: left;
        text-align: center;
        cursor: pointer;
        img {
          width: 86px;
          height: 92px;
        }
        span {
          color: #000;
          font-size: 16px;
          text-align: center;
          float: left;
          width: 100%;
        }
      }
    }
  }
}
</style>
