<template>

    <div class="homepage">
      <div class="mw fromtop">
        <div class="mainbox">
             <div class="back" @click="backto()"><i class="icon">&#xe601;</i> 返回</div>
          <div class="title">
            <span class="titlename">{{ title }}</span>
          </div>
          <div class="showbox">
            <div v-html="html"></div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      html: "",
      titleid: null,
      list: [],
      title: "",
    };
  },
  mounted() {
    var id = Number(this.$route.query.id);
      this.getdatas(id);
  },
  methods: {
    getdatas(id) {
      this.$myAjax("zkp/roll_notice_info", "get", {id:id}, (res) => {
       this.title=res.title
       this.html=res.content
      });
    },
    backto() {
      this.$router.go(-1);
    },
    tabchange(index) {
      this.titleid = this.list[index].id;
      this.title = this.list[index].title;
      this.html = this.list[index].content;
    },
  },
};
</script>
<style lang="less" scoped>
 .back {
    padding: 10px 15px 20px 15px;
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    .icon {
      font-size: 14px;
    }
  }
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      flex: 1;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .titlename {
          color: #000000;
          font-size: 20px;
        }
      }
      .showbox {
        display: inline-block;
        width: 100%;
        float: left;
        padding: 30px 35px;
        .onequestion {
          padding: 28px 34px;
          border-bottom: 1px solid #e6e6e6;
          .onetop {
            display: inline-block;
            width: 100%;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #000000;
              float: left;
            }
            .icon {
              font-size: 12px;
              color: #999999;
              float: right;
              font-weight: bold;
            }
          }
          .contents {
            margin-top: 27px;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
