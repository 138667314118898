<template>
  <div>
    <div class="total">
      <div class="total_titl">{{ selected_num }}件商品</div>
      <div class="s"></div>
      <div class="total_goods">
        <slot name="total_goods"></slot>
      </div>
      <div class="total_price">
        <span>商品金额</span>
        <span>￥{{ select_price }}</span>
      </div>
       <div class="total_price" >
        <span>活动优惠金额</span>
        <span>￥{{ active_price ||0.00}}</span>
      </div>
      <!-- <div class="total_dicout">
        <span>
          优惠金额
          <i class="icon">&#xe602;</i>
        </span>
        <span>-￥100</span>
      </div> -->
      <!-- <div class="total_freight">
        <span>运费</span>
        <span>免邮</span>
      </div> -->
      <div class="s"></div>
      <div class="total_sum">
        <span>总计</span>
        <span style="font-weight: bold">￥{{ select_price }}</span>
      </div>
      <!-- <div class="total_get">
        <span>获得积分</span>
        <span>1400</span>
      </div> -->
      <div class="total_agree">
        <el-checkbox v-model="checked" class="check"></el-checkbox
        >我已阅读并同意
        <a @click="toquestion(2)">《MIKI HOUSE官方线上商城使用条款》</a>及
        <a @click="toquestion(1)">《隐私政策》</a>
      </div>
      <div class="total_btn1" v-if="checked" @click="submitcar">立即结算</div>
      <div class="total_btn2" v-else style="cursor: not-allowed">立即结算</div>
      <div class="total_btn2" @click="togo">继续购物</div>
      <div class="total_des" v-if="order_desc">
        <p>说明</p>
        <p>
          {{ order_desc }}
        </p>
      </div>
    </div>
    <div class="help">
      <div v-if="Object.keys(delivery_info).length > 0">
        <div class="checks_check">
          <div class="checktops" @click="distate = !distate">
            <div class="desc">{{ delivery_info.title }}</div>
            <div class="icon tardiness icont">
              {{ distate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <div
            class="contents"
            v-if="distate"
            v-html="delivery_info.content"
          ></div>
        </div>
      </div>
      <div v-if="Object.keys(pay_way).length > 0">
        <div class="s"></div>
        <div class="checks_check">
          <div class="checktops" @click="pwstate = !pwstate">
            <div class="desc">{{ pay_way.title }}</div>
            <div class="icon tardiness icont">
              {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <div class="contents" v-if="pwstate" v-html="pay_way.content"></div>
        </div>
      </div>
      <div v-if="Object.keys(electronic_invoice).length > 0">
        <div class="s"></div>
        <div class="checks_check">
          <div class="checktops" @click="pgstate = !pgstate">
            <div class="desc">{{ electronic_invoice.title }}</div>
            <div class="icon tardiness icont">
              {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <div
            class="contents"
            v-if="pgstate"
            v-html="electronic_invoice.content"
          ></div>
        </div>
      </div>
      <div v-if="Object.keys(common_problem).length > 0">
        <div class="s"></div>
        <div class="checks_check">
          <div class="checktops" @click="restate = !restate">
            <div class="desc">{{ common_problem.title }}</div>
            <div class="icon tardiness icont">
              {{ restate ? "&#xe711;" : "&#xe60a;" }}
            </div>
          </div>
          <div
            class="contents"
            v-if="restate"
            v-html="common_problem.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    total_price: String,
    selected_num: Number,
    delivery_info: Object,
    pay_way: Object,
    electronic_invoice: {
      type: Object,
      default() {
        return {};
      },
    },
    common_problem: Object,
    order_desc: String,
    privacy_policy: String,
    sale_terms: String,
    select_price: String,
    active_price:String
  },
  data() {
    return {
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      checked: false,
    };
  },
  mounted() {},
  methods: {
    submitcar() {
      this.$emit("sbmit");
    },
    togo() {
      localStorage.removeItem("page")
      this.$router.push({ name: "seriesDetails" });
    },
    toquestion(tag) {
      let titleid = "";
      if (tag == 1) {
        titleid = this.privacy_policy;
      } else {
        titleid = this.sale_terms;
      }
      this.$router.push({
        name: "Questions",
        query: {
          titleid: titleid,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.s {
  border-top: 1px solid #eee;
  margin: 10px 0;
}
.total {
  padding: 20px;
  background-color: #fff;
  .total_titl {
    font-size: 20px;
    text-align: center;
  }
  > div {
    margin-bottom: 16px;
    span:nth-of-type(2) {
      float: right;
    }
    span {
      font-size: 14px;
      color: #111;
    }
    .icon {
      color: #999;
      font-size: 14px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .total_sum {
    span:nth-of-type(1) {
      font-size: 24px;
    }
    span:nth-of-type(2) {
      font-size: 18px;
    }
  }
  .total_agree {
    margin-top: 38px;
    font-size: 14px;
    .check {
      margin-right: 9px;
    }
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .total_btn1 {
    background-color: #e60012;
    height: 46px;
    color: #fff;
    text-align: center;
    line-height: 46px;
    cursor: pointer;
    font-size: 14px;
  }
  .total_btn2 {
    height: 46px;
    background-color: #d2d2d2;
    text-align: center;
    line-height: 46px;
    color: #000;
    cursor: pointer;
    font-size: 14px;
  }
  .total_des {
    margin-top: 41px;
    p {
      color: #111;
    }
    p:nth-of-type(1) {
      font-size: 16px;
    }
    p:nth-of-type(2) {
      font-size: 14px;
      margin-top: 12px;
    }
  }
}
.help {
  padding: 20px;
  background-color: #fff;
  margin-top: 12px;
  .s {
    width: 100%;
    border-top: 1px solid #ccc;
  }
  .checks_check {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    .checktops {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      flex-direction: row;
      .lefts {
        display: flex;
        align-items: center;
        .icon {
          font-size: 22px;
        }
        .desc {
          margin-left: 14px;
          color: #000000;
          line-height: 24px;
        }
      }
      .icont {
        font-size: 14px;
      }
    }
    .contents {
      color: #7c7c7c;
      font-size: 14px;
      margin-top: 20px;
    }
  }
}
</style>
