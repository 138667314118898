<template>
  <div class="findPassword mwframe">
    <div class="findBox">
      <div class="back" @click="backto">
        <span><i class="icon">&#xe601;</i> 返回</span>
      </div>
      <div class="box">
        <div class="box_til">忘记密码</div>
        <div class="box_way">
          <span :class="isway ? 'isway' : 'noway'" @click="watChage"
            >通过手机找回</span
          ><span class="nobold">|</span>
          <span :class="!isway ? 'isway' : 'noway'" @click="watChage"
            >通过电子邮箱</span
          >
        </div>
        <div class="box_tip" v-if="!isway">
          请输入您注册时的手机号码，系统将会验证您的手机并进行下一步操作。
        </div>
        <div class="box_tip" v-else>
          请在下面输入你的电子邮件地址，你将收到一个重置密码的链接。
        </div>

        <div class="box_right" style="cursor: pointer" @click="toserver">
          <p>账户无法使用？</p>
          <p>请联系我们</p>
          <!-- <span> service@mikouhouse.cn</span> -->
        </div>
        <div class="box_form" v-if="!isway">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
          >
            <el-form-item label="手机号码" prop="user">
              <el-input
                v-model="form.user"
                placeholder="请输入手机号码"
                maxlength="11"
              >
                <el-button
                  slot="append"
                  @click="sendemail"
                  :loading="loading"
                  :disabled="cannot"
                  class="sendcode"
                  style="width: 120px"
                  >{{ countdetail }}</el-button
                >
              </el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input
                v-model="form.captcha"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="box_form" v-else>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
          >
            <el-form-item label="电子邮箱地址" prop="email">
              <el-input v-model="form.email" placeholder="请输入电子邮箱地址">
                <span slot="append" class="sendcode" @click="sendecode">{{
                  Emailcountdetail
                }}</span>
              </el-input>
              <!-- <div>请在电子邮件地址中包括“@”。</div> -->
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input
                v-model="form.captcha"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="box_btn" @click="submit('form')">提交</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入电话号码"));
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error("请输入有效的电话号码"));
      } else {
        callback();
      }
    };
    return {
      form: {
        user: "",
        password: "",
        captcha: "",
        email: "",
      },
      rules: {
        user: [
          {
            required: true,
            validator: checkphone,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        captcha: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
      },
      isway: false, //是否为另一个方式
      countdetail: "获取验证码",
      Emailcountdetail: "获取验证码",
      cannot: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    backto() {
      this.$router.go(-1);
    },
    watChage() {
      if (this.isway) {
        this.isway = false;
      } else {
        this.isway = true;
      }
      this.$refs["form"].resetFields();
    },
    sendemail() {
      if (this.form.user) {
        this.$myAjax(
          "sms/send",
          "post",
          {
            mobile: this.form.user,
            event: "resetpwd",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset:170
            });
            let count = 60;
            this.loading = true;
            this.cannot = true;
            var interval = setInterval(() => {
              this.loading = false;
              count--;
              if (count == 0) {
                clearInterval(interval);
                this.countdetail = "获取验证码";
                this.cannot = false;
              } else {
                this.countdetail = count + "s";
              }
            }, 1000);
          }
        );
      } else {
        this.$message({
          showClose: true,
          message: "请先填写手机号码",
          offset:170
        });
      }
    },
    sendecode() {
      if (this.form.email) {
        this.$myAjax(
          "ems/send",
          "post",
          {
            email: this.form.email,
            event: "resetpwd",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset:170
            });
            let count = 60;
            this.loading = true;
            this.cannot = true;
            var interval = setInterval(() => {
              this.loading = false;
              count--;
              if (count == 0) {
                clearInterval(interval);
                this.Emailcountdetail = "获取验证码";
                this.cannot = false;
              } else {
                this.Emailcountdetail = count + "s";
              }
            }, 1000);
          }
        );
      } else {
        this.$message({
          showClose: true,
          message: "请先填写电子邮箱",
          offset:170
        });
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$myAjax(
            "account/reset_password",
            "post",
            {
              type: this.isway ? "email" : "mobile",
              mobile: this.form.user,
              email: this.form.email,
              newpassword: this.form.password,
              captcha: this.form.captcha,
            },
            () => {
              let that = this;
              this.$message({
                type: "success",
                message: "修改成功，请重新登录 ... ...",
                offset:170
              });
              setTimeout(function () {
                that.$router.push({ name: "login" });
              }, 1000);
            }
          );
        }
      });
    },
    toserver() {
      this.$router.push({ name: "OnlineServer" });
    },
  },
};
</script>
<style lang="less" scoped>
.findPassword {
      margin-top: 140px;
  .findBox {
    width: 77%;
    margin: 0 auto;
    padding-top: 25px;
    .back {
      color: #111111;
      font-size: 14px;
      span {
        cursor: pointer;
      }
      .icon {
        font-size: 14px;
      }
    }
    .box {
      width: 76%;
      margin: 0 auto;
      .box_til {
        font-size: 36px;
        color: #000011;
        margin-top: 103px;
        margin-bottom: 39px;
      }
      .box_way {
        font-size: 24px;
        font-weight: bold;
        color: #111;
        margin-bottom: 23px;
        .isway {
          color: #999;
          cursor: pointer;
        }
        .noway {
          cursor: pointer;
        }
        .nobold {
          color: #999;
          margin: 0 20px;
        }
      }
      .box_tip {
        font-size: 14px;
        color: #111;
        margin-bottom: 74px;
      }
      .box_form {
        width: 50%;
        .sendcode {
          cursor: pointer;
        }
      }
      .box_btn {
        width: 300px;
        height: 48px;
        background-color: #e60012;
        text-align: center;
        color: #fff;
        line-height: 48px;
        margin-bottom: 80px;
        margin-top: 60px;
        cursor: pointer;
        font-size: 16px;
      }
      .box_right {
        float: right;
        p {
          margin: 10px 0;
          color: #111;
          span {
            color: #e60012;
          }
        }
      }
    }
  }
}
</style>
