<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <div class="leftcontent">
          <div class="status">
            <p class="title">售后详情</p>
            <p class="status_canbe">
              {{
                refund_data.status == 1
                  ? "审核中"
                  : refund_data.status == 2
                  ? "已通过 "
                  : refund_data.status == 3
                  ? "已拒绝"
                  : refund_data.status == 4
                  ? "已取消"
                  : refund_data.status == 5
                  ? "回寄中 "
                  : refund_data.status == 6
                  ? "已回寄"
                  : refund_data.status == 7
                  ? "已退款"
                  : ""
              }}
              <span v-if="refund_data.status == 3" style="color:red;margin-left:10px">(如有需要请在订单页面重新申请)</span>
            </p>
              <p class="tips" v-if="refund_data.admin_remarks">{{refund_data.admin_remarks}}</p>
       
            <div
              v-if="
                refund_data.status == 2 ||
                refund_data.status == 5 ||
                refund_data.status == 6 ||
                refund_data.status == 7
              "
            >
              <p class="tips">
                回寄地址：{{ refund_data.contacts }} {{ refund_data.mobile }}
                {{ refund_data.province }}{{ refund_data.city
                }}{{ refund_data.area }}{{ refund_data.address }}
              </p>
            </div>
          </div>
          <div class="status">
            <p class="ids">订单编号：{{ order_data.order_sn }}</p>
            <p class="row">订单日期：{{ order_data.createtime }}</p>
            <p class="row">
              送货地址：{{ order_data.contacts }} {{ order_data.mobile }}
              {{ order_data.province }}{{ order_data.city }}{{ order_data.area
              }}{{ order_data.address }}
            </p>
            <!-- <p class="row">发货方式：成都保税仓发货</p>
            <p class="row">物流配送：顺丰快递</p> -->
            <p class="row">
              支付方式：{{
                order_data.pay_type == "A01"
                  ? "支付宝支付"
                  : order_data.pay_type == "W01"
                  ? "微信支付"
                  : ""
              }}
            </p>
            <p class="row">支付时间：{{ order_data.pay_time }}</p>
            <p class="price">
              支付总额：<span>￥{{ order_data.actual_pay }}</span>
            </p>
          </div>
          <div class="status">
            <p class="shoptitle">商品详情</p>
            <div class="shopscontent">
              <img :src="refund_data.goods_image" alt="" />
              <div class="contents">
                <p class="name">{{ refund_data.goods_title }}</p>
                <p class="row">商品编码：{{ refund_data.goods_sn }}</p>
                <p class="row">规格:{{ refund_data.spec_value }}</p>
                <p class="row">数量:{{ refund_data.goods_num }}</p>
                <p class="price">￥{{ refund_data.goods_price }}</p>
              </div>
              <!-- 填写退货单号,取消退款 -->
              <div
                class="redbtn"
                v-if="refund_data.status == 2 && refund_data.type == 2"
                @click="towrite"
              >
                填写退货单号
              </div>
              <div
                class="redbtn"
                v-if="refund_data.status == 1 || refund_data.status == 2"
                @click="handleCancel"
              >
                取消退款
              </div>
              <!-- <div
                class="graybtn"
                v-if="refund_data.status == 3 || refund_data.status == 4"
                @click="handleShenqing"
              >
                再次申请
              </div> -->
            </div>
          </div>
          <div class="status">
            <div class="pricedetail">
              <span>商品金额</span>
              <span class="fromright">￥{{ order_data.total_price }}</span>
            </div>
            <div class="pricedetail" v-if="order_data.coupon_price > 0">
              <span>优惠券 </span>
              <span class="fromright">-￥{{ order_data.coupon_price }}</span>
            </div>
            <!-- <div class="pricedetail">
              <span>活动优惠 </span>
              <span class="fromright">-￥0</span>
            </div> -->
            <div class="pricedetail">
              <span>积分抵扣</span>
              <span class="fromright">-￥{{ order_data.score_price }}</span>
            </div>
            <div class="pricedetail">
              <span> 运费</span>
              <span class="fromright">{{ order_data.freight }}</span>
            </div>
            <div class="pricedetail">
              <span>实付金额</span>
              <span class="fromright">￥{{ order_data.actual_pay }}</span>
            </div>
          </div>
          <p class="beizhu">备注：{{ refund_data.refund_intro }}</p>
        </div>
        <div class="rightcontent">
          <div class="refundinfo">
            <div class="refundtitle">退款信息</div>
            <div class="refundrow">
              <span class="rowtitle">申请时间</span>
              <span class="rowvalue">{{ refund_data.createtime }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款金额</span>
              <span class="rowvalue">￥{{ refund_data.refund_price }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款原因</span>
              <span class="rowvalue">{{ refund_data.refund_reason }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款类型</span>
              <span class="rowvalue">{{
                refund_data.type == 1 ? "仅退款" : "退货退款"
              }}</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退款方式</span>
              <span class="rowvalue">原路返回</span>
            </div>
            <div class="refundrow">
              <span class="rowtitle">退货方式</span>
              <span class="rowvalue">快递</span>
            </div>
          </div>
          <div class="help">
            <div class="checks">
              <div v-if="order_program.delivery_info">
                <div class="checks_check">
                  <div class="checktops" @click="distate = !distate">
                    <div class="desc">
                      {{ order_program.delivery_info.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ distate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="distate"
                    v-html="order_program.delivery_info.content"
                  ></div>
                </div>
       
              </div>
              <div v-if="order_program.pay_way">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pwstate = !pwstate">
                    <div class="desc">{{ order_program.pay_way.title }}</div>
                    <div class="icon tardiness icont">
                      {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pwstate"
                    v-html="order_program.pay_way.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.electronic_invoice">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="restate = !restate">
                    <div class="desc">
                      {{ order_program.electronic_invoice.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ restate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="restate"
                    v-html="order_program.electronic_invoice.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.common_problem">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pgstate = !pgstate">
                    <div class="desc">
                      {{ order_program.common_problem.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pgstate"
                    v-html="order_program.common_problem.content"
                  ></div>
                </div>
              </div>
      <!-- <a target="_blank"  style="display:flex;justify-content:flex-start;align-items:center" :href="urla">
      <img border="0" :src="urlb"    alt="点击这里给我发消息" title="点击这里给我发消息"/>
      <span class="kefu">客服</span>
      </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      refund_id: 0,
      refund_data: {},
      order_data: {},
      order_program: {},
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      contact:"",
      urla:"",
      urlb:"",
    };
  },
  mounted() {
    this.refund_id = this.$route.query.refund_id;
    this.getData();
    this.get_order_program();
    let  contact=localStorage.getItem("contact")
    this.urla="http://wpa.qq.com/msgrd?v=3&uin="+contact+"&site=qq&menu=yes"
    this.urlb="http://wpa.qq.com/pa?p=2:"+contact+":52"
  },
  methods: {
    kefu(){
      window.location.href = `mqqwpa://im/chat?chat_type=wpa&uin=981711485&version=1&src_type=web&web_src=oicqzone.com`
    },
    towrite() {
      this.$router.push({
        name: "WriteIds",
        query: {
          refund_id: this.refund_id,
        },
      });
    },
    getData() {
      let { refund_id } = { ...this };
      this.$myAjax("order/refund_details", "get", { refund_id }, (res) => {
        this.refund_data = res.refund;
        this.order_data = res.order;
      });
    },
    get_order_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.order_program = res;
      });
    },
    handleCancel() {
      let { refund_id } = { ...this };
      this.$confirm("确定取消退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$myAjax(
            "order/cancel_order_refund",
            "post",
            { refund_id },
            (res) => {
              console.log(res);
              this.$message({
                message: "",
                type: "取消成功！如有需要请在订单页面重新申请",
                duration: 6000,
                offset:170,
                onClose: () => {
                  this.getData();
                },
              });

            }
          );
        })
        .catch(() => {});
    },
    handleShenqing() {
      this.$router.push({
        path: "/orderdetail",
        query: {
          id:  this.order_data.order_id,
        },
      });

 
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  min-height: 1157px;
  .fromtop {
    margin-top: 50px;
    .leftcontent {
      width: 60%;
      float: left;
      .status {
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        .title {
          font-size: 30px;
          color: #000000;
          font-weight: bold;
        }
        .status_canbe {
          margin-top: 32px;
          font-size: 16px;
          font-weight: bold;
        }
        .tips {
          margin-top: 17px;
          font-size: 14px;
          color: #333333;
        }
        .ids {
          margin-top: 21px;
          font-size: 14px;
          color: #111111;
        }
        .row {
          margin-top: 22px;
          color: #111111;
          font-size: 14px;
        }
        .price {
          margin-top: 20px;
          color: #111111;
          font-size: 14px;
          span {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .shoptitle {
          padding: 25px 0 27px 0;
          font-size: 16px;
          color: #000000;
        }
        .shopscontent {
          height: 205px;
          display: inline-block;
          width: 100%;
          img {
            width: 205px;
            height: 205px;
            float: left;
          }
          .contents {
            margin-left: 30px;
            float: left;
            .name {
              font-size: 20px;
              color: #111111;
            }
            .row {
              margin-top: 10px;
              color: #111111;
              font-size: 16px;
            }
            .price {
              margin-top: 30px;
              font-size: 18px;
              font-weight: bold;
              color: #111111;
            }
          }
          .redbtn {
            width: 168px;
            height: 48px;
            background: #e70010;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            line-height: 48px;
            text-align: center;
            float: right;
            margin-top: 157px;
            cursor: pointer;
            margin-left: 5px;
          }
          .graybtn {
            width: 168px;
            height: 48px;
            background: #f2f2f2;
            border: 1px solid #999999;
            font-size: 14px;
            font-weight: bold;
            color: #999999;
            line-height: 48px;
            text-align: center;
            float: right;
            margin-top: 157px;
            cursor: pointer;
          }
        }
        .pricedetail {
          margin-top: 15px;
          display: inline-block;
          width: 100%;
          span {
            font-size: 16px;
            color: #111111;
            float: left;
          }
          .fromright {
            float: right;
          }
        }
      }
      .beizhu {
        padding: 25px 0;
        font-size: 14px;
        color: #000000;
      }
    }
    .rightcontent {
      width: 480px;
      float: right;
      .refundinfo {
        width: 480px;
        height: 300px;
        background: #fff;
        .refundtitle {
          height: 64px;
          text-align: center;
          line-height: 64px;
          border-bottom: 1px solid #e6e6e6;
          font-size: 20px;
          color: #000000;
        }
        .refundrow {
          padding: 0 27px 0 34px;
          width: 100%;
          float: left;
          margin-top: 15px;
          span {
            font-size: 16px;
            color: #111111;
          }
          .rowtitle {
            float: left;
          }
          .rowvalue {
            float: right;
            font-size: 14px;
          }
        }
      }
    }
    .help {
      background-color: #fff;
      margin-top: 12px;
      padding: 0 30px;
      .checks {
        padding: 1px 0;
        .s {
          width: 100%;
          border-top: 1px solid #ccc;
        }
        .checks_check {
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          .checktops {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            flex-direction: row;
            .lefts {
              display: flex;
              align-items: center;
              .icon {
                font-size: 22px;
              }
              .desc {
                margin-left: 14px;
                color: #000000;
                line-height: 24px;
              }
            }
            .icont {
              font-size: 14px;
            }
          }
          .contents {
            color: #7c7c7c;
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.kefu{
  line-height:40px
}
</style>
