<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="1"></navbar>
        <div class="mainbox">
          <div class="title">
            我的宝宝<span @click="addbaby">添加宝宝</span>
          </div>
          <div v-if="!listdata.length" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">添加宝宝信息，时刻关注成长哦！</div>
          </div>
          <div v-else class="showbox">
            <div v-for="(item, index) in listdata" :key="index">
              <div class="headbox">
                <img :src="item.header_img" alt="" />
                <div class="name">
                  {{ item.type == 1 ? "怀孕中" : item.pet_name }}
                  <div class="icon tardiness" @click="editbaby(item.id)">
                    &#xe636;
                  </div>
                </div>
                <div class="babybrith" v-if="item.type == 2">
                  宝宝生日：{{ item.birthday }}
                  {{
                    item.gender == 0 ? "未知" : item.gender == 1 ? "男" : "女"
                  }}宝宝
                </div>
              </div>
              <div class="row" v-if="item.type == 1">
                <span>预产期</span>
                <span style="float: right">{{ item.birthday }}</span>
              </div>
              <div
                class="row"
                v-for="(tagment, tag) in item.log_list"
                :key="tag"
              >
                <span>{{
                  tagment.type == 1
                    ? "身高"
                    : tagment.type == 2
                    ? "体重"
                    : "脚长"
                }}</span>
                <div class="rowinfo">
                  <p class="value">{{ tagment.value }} {{ tagment.unit }}</p>
                  <p class="time">更新日期：{{ tagment.createtime }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="editstatus">
      <div class="windows" v-loading="loading2">
        <div class="widowtitle">
          {{ editid ? "编辑宝宝" : "添加宝宝" }}
          <div class="icon tardiness" @click="editstatus = false">&#xe61c;</div>
        </div>
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="0"
          class="contents"
        >
          <el-upload
            class="avatar-uploader"
            :action="upload.url"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-error="handlefail"
            accept=".jpeg,.jpg,.png"
          >
            <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- :style="!form.imageUrl ? 'opacity:1' : 'opacity:0'" -->
          <p class="upimgtext">上传图片</p>
          <div class="nowstatus">
            <span>目前状态</span>
            <div class="onestatus" @click="type = 1">
              <i class="icon tardiness">{{
                type == 1 ? "&#xe624;" : "&#xe64c;"
              }}</i>
              <span>怀孕中</span>
            </div>
            <div class="onestatus" @click="type = 2">
              <i class="icon tardiness">{{
                type == 2 ? "&#xe624;" : "&#xe64c;"
              }}</i>
              <span>宝宝已出生</span>
            </div>
          </div>
          <div v-if="type == 1">
            <el-form-item prop="birthday" class="babydate">
              <p>宝宝生日</p>
              <el-date-picker
                v-model="form.birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择宝宝生日"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item prop="babyname" class="babynamebox">
              <p>宝宝小名</p>
              <el-input
                v-model="form.babyname"
                placeholder="请输入宝宝小名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="gender" class="babysexbox">
              <div>
                <p>性别</p>
                <el-select
                  v-model="form.gender"
                  slot="prepend"
                  placeholder="请选择性别"
                  style="width: 100%"
                >
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item prop="birthday" class="babydate">
              <p>宝宝生日</p>
              <el-date-picker
                v-model="form.birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择宝宝生日"
                style="width: 100%"
                v-if="editid && edittype == 2"
                disabled
              >
              </el-date-picker>
              <el-date-picker
                v-model="form.birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择宝宝生日"
                style="width: 100%"
                v-else
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="height" class="babynamebox">
              <p>身高（cm）</p>
              <el-input
                v-model="form.height"
                placeholder="请输入宝宝身高（cm）"
              ></el-input>
            </el-form-item>
            <el-form-item prop="weight" class="babysexbox">
              <p>体重（kg）</p>
              <el-input
                v-model="form.weight"
                placeholder="请输入宝宝体重（kg）"
              ></el-input>
            </el-form-item>
            <el-form-item prop="foot" class="babydate">
              <p>脚长（cm）</p>
              <el-input
                v-model="form.foot"
                placeholder="请输入宝宝脚长（cm）"
              ></el-input>
            </el-form-item>
          </div>

          <div class="savebtn" @click="submitForm('ruleForm')">立即保存</div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessbaby.png"),
      listdata: [],
      editstatus: false,
      form: {
        foot: "",
        weight: "",
        height: "",
        birthday: "",
        gender: "",
        babyname: "",
        imageUrl: "",
        upimgUrl: "",
      },
      type: 1,
      edittype: "",
      editid: "",
      rules: {
        // babyname: [
        //   { required: true, message: "请输入宝宝小名", trigger: "blur" },
        // ],
        // gender: [
        //   {
        //     required: true,
        //     message: "请选择性别",
        //     trigger: "change",
        //   },
        // ],
        birthday: [
          { required: true, message: "请选择宝宝生日", trigger: "change" },
        ],
      },
      upload: {
        url: process.env.VUE_APP_BASE_API + "common/upload",
      },
      loading2: false,
    };
  },
  mounted() {
    this.getBabys();
  },
  methods: {
    addbaby() {
      (this.edittype = ""), (this.editid = "");
      (this.form = {
        foot: "",
        weight: "",
        height: "",
        birthday: "",
        gender: "",
        babyname: "",
        imageUrl: "",
        upimgUrl: "",
      }),
        (this.editstatus = true);
    },
    getBabys() {
      this.$myAjax("user/baby_list", "get", {}, (res) => {
        this.listdata = res;
      });
    },
    editbaby(_id) {
      this.editid = _id;
      this.$myAjax("user/baby_details?baby_id=" + _id, "get", {}, (res) => {
        console.log(res);
        this.type = res.type;
        this.edittype = res.type;
        let form;
        if (res.type == 1) {
          form = {
            imageUrl: res.header_img,
            upimgUrl: res.upload_img,
            birthday: res.birthday,
          };
        } else {
          form = {
            foot: res.foot_length,
            weight: res.weight,
            height: res.height,
            birthday: res.birthday,
            gender: res.gender,
            babyname: res.pet_name,
            imageUrl: res.header_img,
            upimgUrl: res.upload_img,
          };
        }

        this.form = form;
        this.editstatus = true;
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(file);
      this.form.imageUrl = URL.createObjectURL(file.raw);
      this.form.upimgUrl = file.response.data.url;
    },
    handlefail() {
      this.$message.error("图片上传失败！请稍后重试！");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading2 = true;
          let url = "";
          this.editid ? (url = "user/edit_baby") : (url = "user/add_baby");
          let postData = {
            pet_name: this.form.babyname,
            header_img: this.form.upimgUrl,
            gender: this.form.gender,
            birthday: this.form.birthday,
            height: this.form.height,
            weight: this.form.weight,
            foot_length: this.form.foot,
            type: this.type,
          };
          if (this.editid) {
            postData.baby_id = this.editid;
          }
          this.$myAjax(
            url,
            "post",
            postData,
            (res) => {
              this.loading2 = false;
              if (res.code == 1) {
                this.editstatus = false;
                this.$message({
                  type: "success",
                  message: this.editid ? "修改成功!" : "添加成功!",
                  offset: 170,
                });
                this.$refs[formName].resetFields();
                this.getBabys();
              } else {
                this.$alert(res.msg, "MIKI HOUSE提示您", {
                  confirmButtonText: "确定",
                });
              }
            },
            true
          );
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      flex: 1;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
        float: left;
        span {
          float: right;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 22px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .showbox {
        padding: 0 40px;
        display: inline-block;
        width: 100%;
        float: left;
        .headbox {
          padding: 31px 0 43px;
          border-bottom: 1px solid #e6e6e6;
          display: inline-block;
          width: 100%;
          img {
            width: 107px;
            height: 107px;
            border-radius: 50%;
            float: left;
            left: 50%;
            margin-left: -53.5px;
            position: relative;
          }
          .name {
            margin-top: 16px;
            text-align: center;
            float: left;
            width: 100%;
            text-align: center;
            font-size: 20px;
            color: #000000;
            float: left;
            position: relative;
            .icon {
              margin-left: 12px;
              color: #666666;
              font-size: 22px;
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: 50px;
              cursor: pointer;
            }
          }
          .babybrith {
            margin-top: 17px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #666666;
            float: left;
          }
        }
        .row {
          border-bottom: 1px solid #e6e6e6;
          float: left;
          height: 74px;
          width: 100%;
          span {
            line-height: 74px;
            float: left;
            font-size: 14px;
            color: #000000;
          }
          .rowinfo {
            float: right;
            .value {
              margin-top: 10px;
              font-size: 14px;
              color: #000000;
              text-align: right;
            }
            .time {
              margin-top: 10px;
              font-size: 14px;
              color: #666666;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;
  .windows {
    width: 603px;
    height: 730px;
    padding-bottom: 20px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -320px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;
      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .contents {
      padding: 0 40px;
      width: 100%;
      text-align: center;
      display: inline-block;
      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        cursor: pointer;
      }
      .upimgtext {
        margin-top: 5px;
        margin-bottom: 16px;
        font-size: 14px;
        color: #f56c6c;
        cursor: pointer;
      }
      .nowstatus {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 10px;
        .onestatus {
          margin-left: 75px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          i {
            font-size: 18px;
          }
          span {
            margin-left: 10px;
          }
        }
      }
      .babynamebox {
        width: 250px;
        float: left;
        // margin-top: 37px;
        p {
          font-size: 14px;
          color: #000000;
          margin-bottom: 6px;
          text-align: left;
        }
      }
      .babysexbox {
        width: 250px;
        float: right;
        // margin-top: 37px;
        p {
          font-size: 14px;
          color: #000000;
          margin-bottom: 6px;
          text-align: left;
        }
      }
      .babydate {
        width: 100%;
        float: right;
        // margin-top: 23px;
        p {
          font-size: 14px;
          color: #000000;
          margin-bottom: 6px;
          text-align: left;
        }
      }
      .savebtn {
        // margin-top: 23px;
        width: 182px;
        height: 48px;
        font-size: 16px;
        color: #e60012;
        font-weight: bold;
        border: 1px solid #e50212;
        float: left;
        text-align: center;
        line-height: 48px;
        left: 50%;
        position: relative;
        margin-left: -91px;
        cursor: pointer;
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 72px;
  height: 72px;
  line-height: 72px !important;
  text-align: center;
  cursor: pointer;
}
.avatar {
  width: 72px;
  height: 72px;
  display: block;
}
</style>
