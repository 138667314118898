<template>
  <div class="goodsDetails mwframe">
    <div class="W mw">
      <div class="back" @click="backto()">
        <i class="icon">&#xe601;</i> 返回
      </div>
      <div class="coponent">
        <goodsLeft :goods="goods"></goodsLeft>
        <goodsRight
          :productGood="productGood"
          :payWay="payWay"
          :deliveryInfo="deliveryInfo"
          :returnExchangeService="returnExchangeService"
          :goods_id="parseInt(shopid)"
          :is_menu="is_menu"
          :goods_sn="goods.goods_sn"
          :active_type="active_type"
          :active="active"
        >
          <template v-slot:goods_head>
            <div class="flash">
              <div class="flash_timeAndPrice">
                <div class="flash_left">
                  <div class="lefttime" v-if="is_seckill">
                    <span class="timemetion">{{ active_tip }}</span>
                    <span class="time" v-html="time(pre_at)"> </span>
                  </div>
                  <div class="brand_image">
                    <img :src="goods.brand_image" />
                    <i
                      style="float: right; cursor: pointer; font-size: 20px"
                      class="icon tardiness"
                      v-if="!goods.menu_id"
                      @click="likechange()"
                      :class="goods.is_follow == 1 ? 'likeon' : ''"
                      >{{ goods.is_follow == 1 ? "&#xe60f;" : "&#xe611;" }}</i
                    >
                  </div>

                  <div class="flash_title">
                    <span>{{ goods.title }}</span>
                  </div>
                  <div class="flash_info">
                    {{ goods.subtitle }}
                  </div>
                  <!-- <div class="flash_info">
                    {{ goods.goods_sn }}
                  </div> -->
                </div>

                <div class="flash_right">
                  <div class="price">
                    <span style="font-size: 16px; vertical-align: text-bottom"
                      >￥</span
                    >
                    <span style="vertical-align: middle">{{
                      goods.price
                    }}</span>
                    <i class="icon" v-if="goods.warehouse_id == 2">&#xe637;</i>
                  </div>
                  <div class="useprice" v-if="goods.market_type != 1">
                    ￥{{ goods.cost_price }}
                  </div>
                  <div class="flash_info">{{ goods.delivery }}</div>
                </div>
              </div>

              <div v-if="!Object.keys(specData).length">
                <div class="s"></div>
                <div class="flash_size">
                  <div class="size">规格选择</div>
                  <div class="size_info">
                    <ul>
                      <li
                        class="skuon"
                        v-for="(item, index) in skuData"
                        :key="index"
                      >
                        {{ item.spec_value }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-else
                v-for="(value, key1, index1) in specData"
                :key="key1"
                :index="index1"
              >
                <div class="s"></div>
                <div class="flash_size">
                  <div class="size">{{ key1 }}</div>
                  <div class="size_info">
                    <ul>
                      <li
                        v-bind:class="[
                          item.isShow ? '' : 'noneActive',
                          subIndex[key1] == index ? 'skuon' : '',
                          key1 == '颜色' ? 'skucolor' : '',
                        ]"
                        v-for="(item, index) in value"
                        :key="index"
                        @click="checkChange(index, key1, item.id)"
                      >
                        <img
                          v-if="item.image && key1 == '颜色'"
                          :src="item.image"
                          :title="item.value"
                        />
                        <span v-else>{{ item.value }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="goods.is_gift === 1" class="gifts">
                <p v-for="(item, key) in goods.gift" :key="key">
                  <span class="gift_txt">赠品</span>
                  <span style="flex-grow: 1">{{ item }}</span>
                  <span>x1</span>
                </p>
              </div>
              <div class="goods_num">
                <div class="info_num">
                  <i class="icon tardiness" @click="shopsnumchange('less')"
                    >&#xe711;</i
                  >
                  <span>{{ goods_num }}</span>
                  <i class="icon tardiness" @click="shopsnumchange('plus')"
                    >&#xe60a;</i
                  >
                </div>
                <span class="sku_stock" v-if="chooseindex >= 0">
                  {{
                    skuData[chooseindex].stock >= 3
                      ? "库存充足"
                      : skuData[chooseindex].stock == 0
                      ? "暂无库存"
                      : "仅剩" + skuData[chooseindex].stock + "件"
                  }}
                </span>
              </div>
              <div class="flash_btn" v-if="goods.stock > 0">
                <div
                  class="addcar"
                  @click="addcar"
                  :class="is_seckill ? 'is_show' : ''"
                >
                  加入购物车
                </div>
                <div
                  class="buy"
                  @click="limitbuy"
                  :class="!is_show ? 'is_show' : ''"
                >
                  立即购买
                </div>
                <!-- <div
                  class="icon"
                  @click="likechange()"
                  :class="goods.is_follow == 1 ? 'likeon' : ''"
                >
                  {{ goods.is_follow == 1 ? "&#xe627;" : "&#xe6a9;" }}
                </div>
                <div class="icon">&#xe62d;</div> -->
              </div>
              <div class="flash_btn" v-else>
                <div class="notice" @click="addnotice">到货通知</div>
              </div>
              <div class="flash_table" style="border-top: 1px solid #e6e6e6">
                <div
                  class="flash_one"
                  v-for="(item, index) in coeffs"
                  :key="index"
                >
                  <div class="title">{{ item.key }}</div>
                  <div
                    class="row"
                    v-for="(btem, bndex) in item.list"
                    :key="bndex"
                  >
                    {{ btem }}
                  </div>
                </div>
              </div>
              <div class="flash_table" v-if="coeffs_two.length">
                <div
                  class="flash_one"
                  v-for="(item, index) in coeffs_two"
                  :key="index"
                  style="border-top: none"
                >
                  <div class="title">{{ item.key }}</div>
                  <div
                    class="row"
                    v-for="(btem, bndex) in item.list"
                    :key="bndex"
                  >
                    {{ btem }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </goodsRight>
      </div>
      <goodsBottom
        :ownfoot="ownfoot"
        :otherfoot="otherfoot"
        :concatShops="concatShops"
      ></goodsBottom>
    </div>
  </div>
</template>
<script>
import goodsLeft from "../../components/goodsDetail/goodsDetailLeft";
import goodsRight from "../../components/goodsDetail/goodsDetailRight";
import goodsBottom from "../../components/goodsDetail/goodsDetailBottom";
import { MessageBox } from "element-ui";

export default {
  data() {
    return {
      shopid: "",
      ownfoot: [], //浏览足迹
      otherfoot: [], //其他人浏览足迹
      concatShops: [], //关联商品
      productGood: [], //产品特性
      payWay: {}, //支付方式
      deliveryInfo: {}, //配送方式
      returnExchangeService: {}, //退换货服务
      goods: {}, //商品数据
      skuData: [], //规格数组
      specData: [], //规格参数数组
      selectArr: {}, //存放被选中的值
      subIndex: {}, //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
      seckill_time: {},
      chooseindex: -1,
      userid: "",
      coeffs: [],
      coeffs_two: [],
      goods_num: 1,
      is_seckill: false,
      active_tip: "",
      pre_at: 0,
      active:[],
      is_show: true,
      is_menu: -1,
      type:"",//商品活动类型
      active_type:"",
    };
  },
  mounted() {
    let s = window.atob(this.$route.params.s);
    s = s.split(",");
    s = this.parseQuery(s);
    this.shopid = s.id;
    this.type = s.type;
   this.active_type=s.active_type
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
    }
    this.getshopDetail();
    if(this.$route.query.active_type){
      this.active_type=this.$route.query.active_type
    }



  },
  methods: {
    getshopDetail() {
      this.$myAjax(
        "goods/goods_details?goods_id=" +
          this.shopid +
          "&type=" +
          this.type +
          "&identity_id=" +
          this.userid,
        "get",
        {},
        (res) => {
          this.ownfoot = res.own_foot;
          this.otherfoot = res.other_foot;
          this.concatShops = res.rel_goods;
          this.productGood = res.goods.params;
          this.payWay = res.pay_way;
          this.deliveryInfo = res.delivery_info;
          this.returnExchangeService = res.return_exchange_service;
          this.goods = res.goods;
          this.is_menu = res.is_menu;
          this.skuData = res.sku;
          this.specData = res.spec;
          if(res.active.length>0){
          this.active=res.active
          }
          this.coeffs = res.coeffs.all ? res.coeffs.all : [];
          this.coeffs_two = res.coeffs.buy ? res.coeffs.buy : [];
          this.seckill_time = res.seckill_time;
          console.log("this.specData",this.specData)
          console.log("Object.keys(this.specData)",Object.keys(this.specData))
          console.log("Object.keys(this.specData).length",Object.keys(this.specData).length)
          if (Object.keys(this.specData).length) {
            for (let key in this.specData) {
              for (let i = 0; i < this.specData[key].length; i++) {
                this.specData[key][i].isShow = true;
              }
            }
          }
          console.log("this.specData",this.specData)

          for (let i in this.skuData) {
            if (this.skuData[i].stock > 0) {
              let value_id = this.skuData[i].value_id.split(",");

              for (let j in this.specData) {   //  颜色 尺寸
                let spec_item = this.specData[j]; 
                for (let k in spec_item) {
                  if (value_id.indexOf(String(spec_item[k].id)) > -1) {
                    this.checkChanges(k, j, spec_item[k].id);
                   }
                }
              }
              break;
            }
          }
          console.log("11111111111111",this.skuData,this.specData)
         
          if (typeof res.seckill_time == "object") {
            this.is_seckill = true;
            let nowtime = new Date().getTime();
            if (nowtime < res.seckill_time.start_time_int * 1000) {
              this.active_tip = "距离活动开始还剩";
              this.pre_at =
                res.seckill_time.start_time_int * 1000 - new Date().getTime();
              this.ticker = setInterval(() => {
                this.pre_at = this.pre_at - 1000;
              }, 1000);
              this.is_show = false;
            } else if (nowtime < res.seckill_time.end_time_int * 1000) {
              this.active_tip = "距离结束还剩";
              this.pre_at =
                res.seckill_time.end_time_int * 1000 - new Date().getTime();
              this.is_show = true;
              this.ticker = setInterval(() => {
                this.pre_at = this.pre_at - 1000;
              }, 1000);
            } else {
              this.active_tip = "活动已结束";
              this.is_show = false;
            }
          }
          console.log("0000000000000000000000",this.subIndex,this.selectArr,this.chooseindex)
          console.log("000000000",this.chooseindex)
          if(this.chooseindex!=-1){
            this.goods.price=this.skuData[this.chooseindex].price
           this.goods.cost_price=this.skuData[this.chooseindex].size_sn
          }
       
        }
         
      );
    },
    checkChange(_index, _key, _id) {
        //   if(Object.keys(this.selectArr).length>1){
        //    console.log("11")
        //     if(this.selectArr["尺码"]==_id){
        //       console.log("22")
        //        delete(this.selectArr["尺码"])
        //        delete(this.subIndex["尺码"])
        //     }
        //   this.specChek(this.selectArr);
        //   this.checkItem();
        //  }else{
        //  }
        console.log(_index,_key,_id)
        console.log("00000000000000000000001111",this.subIndex,this.selectArr)

            if(_key=="颜色"){
             this.$set(this.selectArr, _key, _id);     //  id
             this.$set(this.subIndex, _key, _index);  // 索引
             delete(this.subIndex["尺码"])
             delete(this.selectArr["尺码"])
             this.chooseindex=-1
            }

            if(_key=="尺码"){
              console.log(this.subIndex["尺码"],_index)
              if(this.subIndex["尺码"]==_index){
                console.log("zxu")
                 delete(this.subIndex["尺码"])
                 delete(this.selectArr["尺码"])
              }else{
               this.$set(this.selectArr, _key, _id);     //  id
               this.$set(this.subIndex, _key, _index);  // 索引
              }
            }
        
       
          this.specChek(this.selectArr);  //判断选中的数据
          this.checkItem();  //判断是否能点击
          this.goods.price=this.skuData[this.chooseindex].price
          this.goods.cost_price=this.skuData[this.chooseindex].size_sn
          // console.log("this.skuData[this.chooseindex]",this.skuData[this.chooseindex])
    },

    checkChanges(_index, _key, _id) {
        console.log(_index,_key,_id)
            if(_key=="颜色"){
             this.$set(this.selectArr, _key, _id);     //  id
             this.$set(this.subIndex, _key, _index);  // 索引
             delete(this.subIndex["尺码"])
             delete(this.selectArr["尺码"])
             this.chooseindex=-1
            }

            if(_key=="尺码"){
              console.log(this.subIndex["尺码"],_index)
              if(this.subIndex["尺码"]==_index){
                console.log("zxu")
                 delete(this.subIndex["尺码"])
                 delete(this.selectArr["尺码"])
              }else{
               this.$set(this.selectArr, _key, _id);     //  id
               this.$set(this.subIndex, _key, _index);  // 索引
              }
            }
        
          console.log(this.subIndex) 
          this.specChek(this.selectArr);  //判断选中的数据
          this.checkItem();  //判断是否能点击
          // this.goods.price=this.skuData[this.chooseindex].price
          // this.goods.cost_price=this.skuData[this.chooseindex].size_sn
          // console.log("this.skuData[this.chooseindex]",this.skuData[this.chooseindex])
    },

    checkItem: function () {
      var self = this;
      var option = self.specData;
      var result = []; //定义数组存储被选中的值
      for (let i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : "";
      }
      for (let i in option) {
        if(i=="颜色"){
        let last = result[i]; //把选中的值存放到字符串last去
        for (let k in option[i]) {
          result[i] = option[i][k].id; //赋值，存在直接覆盖，不存在往里面添加name值
          option[i][k].isShow = true; //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时避免被覆盖
        }else{
        let last = result[i]; //把选中的值存放到字符串last去
        for (let k in option[i]) {
          result[i] = option[i][k].id; //赋值，存在直接覆盖，不存在往里面添加name值
         option[i][k].isShow =self.isMay(result)
        }
        result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时避免被覆盖
        }
     
      }
      self.$forceUpdate(); //重绘
    },
    isMay: function (result) {
      for (let i in result) {
        if (result[i] == "") {
          return true; //如果数组里有为空的值，那直接返回true
        }
      }
      let res = [];
      for (let key in result) {
        res.push(result[key]);
      }
      res = res.join(",");
      for (let item of this.skuData) {
        if (item.value_id == res && item.stock > 0) {
          return true;
        }
      }
      return false;
    },
    specChek(result) {
      console.log(result)
      for (let i in result) {
        if (result[i] == "") {
          return; //如果数组里有为空的值，那直接返回
        }
      }
      if (Object.keys(result).length == Object.keys(this.specData).length) {
        let res = [];
        for (let key in result) {
          res.push(result[key]);
        }
        res = res.join(",");
        for (let index in this.skuData) {
          if (this.skuData[index].value_id == res) {
            this.goods.image = this.skuData[index].image;
            if(this.chooseindex != index){
               this.chooseindex = index;
            }else{
                 this.chooseindex = -1;
            }
          }
        }
      }
  
      // let chooses = [];
      // for (let key in this.specData) {
      //   for (let i = 0; i < this.specData[key].length; i++) {
      //     if (this.specData[key][i].ischeck) {
      //       chooses.push(this.specData[key][i].value);
      //     }
      //   }
      // }
      // if (chooses.length == Object.keys(this.specData).length) {
      //   console.log(chooses.toString());
      //   this.skuData.map((item, index) => {
      //     if (item.spec_value == chooses.toString()) {
      //       this.goods.image = this.skuData[index].image;
      //       this.chooseindex = index;
      //     }
      //   });
      // }
    },
    backto() {
      this.$router.go(-1);
    },
    likechange() {
      let url = "";
      this.goods.is_follow == 1
        ? (url = "user/cancel_follow")
        : (url = "user/add_follow");
      this.$myAjax(
        url,
        "post",
        {
          goods_id: this.shopid,
        },
        () => {
          this.goods.is_follow == 1
            ? ((this.goods.is_follow = 2),
              this.$message({
                message: "已取消收藏",
                type: "success",
                offset: 170,
              }))
            : ((this.goods.is_follow = 1),
              this.$message({
                message: "收藏成功",
                type: "success",
                offset: 170,
              }));
        }
      );
    },
    addcar() {
      if (Object.keys(this.specData).length && this.chooseindex < 0) {
        this.$message.error("请先选择规格");
      } else {
        console.log(this.active_type)
        console.log(this.active[0])
        if(this.active_type==5||this.active_type==6||this.active_type==7){
          if(this.active[0].status==1){
            this.$message.error("活动还未开始");
            return
          }
        }
        this.$myAjax(
          "car/add_car",
          "post",
          {
            goods_id: this.shopid,
            type: 1,
            goods_num: this.goods_num,
             active_type:this.active_type,
            sku_unique: !Object.keys(this.specData).length
              ? this.skuData[0].sku_unique
              : this.skuData[this.chooseindex].sku_unique,
          },
          () => {
            this.$message({
              message: "添加购物车成功",
              type: "success",
              offset: 170,
            });
          }
        );
      }
    },
    limitbuy() {

      if (Object.keys(this.specData).length && this.chooseindex < 0) {
        this.$message.error("请先选择规格");
      } else {
        if(this.active_type==5||this.active_type==6||this.active_type==7){
          if(this.active[0].status==1){
          this.$message.error("活动还未开始");
          return
        }
        }
        this.$myAjax(
          "order/confirm_order",
          "post",
          {
            goods_id: this.shopid,
            type: 1,
            goods_num: this.goods_num,
            active_type:this.active_type,
            sku_unique: !Object.keys(this.specData).length
              ? this.skuData[0].sku_unique
              : this.skuData[this.chooseindex].sku_unique,
            market_type: this.goods.market_type.toString(),
          },
          (res) => {
            sessionStorage.setItem("orderDetail", JSON.stringify(res));
            // this.$store.state.orderData = res;
            this.$router.push({ name: "confirmOrder" });
          }
        );

      }


    },
    shopsnumchange(type) {
      let num = this.goods_num;
      type == "less" ? num-- : type == "plus" ? num++ : "";
      if (num < 1) {
        num = 1;
      }
      this.goods_num = num;
    },
    addnotice() {
      let _this = this;
      this.$myAjax(
        "user/add_notice",
        "post",
        {
          type_id: this.shopid,
          type: 1,
        },
        (res) => {
          if (res.code == -1) {
            MessageBox({
              title: "MIKI HOUSE提示您",
              message: res.msg,
            });
            setTimeout(function () {
              _this.$router.push({ name: "login" });
            }, 2000);
          } else {
            this.$message({
              message: res.msg,
              type: "success",
              offset: 170,
            });
          }
        },
        true
      );
    },
    time(time) {
      if (time > 0) {
        let h =
          Math.floor((time / 1000 / 60 / 60) % 24) < 10
            ? "0" + Math.floor((time / 1000 / 60 / 60) % 24)
            : Math.floor((time / 1000 / 60 / 60) % 24);
        let m =
          Math.floor((time / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((time / 1000 / 60) % 60)
            : Math.floor((time / 1000 / 60) % 60);
        let s =
          Math.floor((time / 1000) % 60) < 10
            ? "0" + Math.floor((time / 1000) % 60)
            : Math.floor((time / 1000) % 60);
        return `<span class="time_box">${h}</span>：<span class="time_box">${m}</span
            >：<span class="time_box">${s}</span>`;
      } else {
        let nowtime = new Date().getTime();
        if (nowtime < this.seckill_time.start_time_int * 1000) {
          this.active_tip = "距离活动开始还剩";
          this.pre_at =
            this.seckill_time.start_time_int * 1000 - new Date().getTime();
          this.is_show = false;
        } else if (nowtime < this.seckill_time.end_time_int * 1000) {
          this.active_tip = "距离结束还剩";
          this.pre_at =
            this.seckill_time.end_time_int * 1000 - new Date().getTime();
          this.is_show = true;
        } else {
          clearInterval(this.ticker);
          this.active_tip = "活动已结束";
          this.is_show = false;
          return ``;
        }
      }
    },
    parseQuery(query) {
      var reg = /([^=&\s]+)[=\s]*([^&\s]*)/g;
      var obj = {};
      while (reg.exec(query)) {
        obj[RegExp.$1] = RegExp.$2;
      }
      return obj;
    },
  },
  components: {
    goodsLeft,
    goodsRight,
    goodsBottom,
  },
};
</script>
<style lang="less" scoped>
.goodsDetails {
  background: #fafafa;
  margin-top: 140px;
  .back {
    padding: 34px 0 40px 15px;
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    .icon {
      font-size: 14px;
    }
  }
  .coponent {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .flash {
      padding-top: 20px;
      .s {
        width: 100%;
        border-top: 1px solid #cccccc52;
        margin: 26px 0;
      }
      .flash_timeAndPrice {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .flash_left {
          display: flex;
          flex-direction: column;
          .brand_image {
            padding: 10px 0;
          }
          img {
            width: 100px;
            height: auto;
          }
          .lefttime {
            float: left;
            font-size: 14px;
            color: #222222;
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .timemetion {
              float: left;
            }
            span.time {
              color: #e60012;
              margin-left: 8px;
              float: left;
              .time_box {
                padding: 3px;
                background-color: #e60012;
                color: #fff;
                border-radius: 4px;
              }
            }
          }
          .flash_title {
            font-size: 26px;
            color: #222;
            font-weight: 400;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .flash_info {
            font-size: 14px;
            color: #999;
            margin-top: 13px;
            font-weight: 300;
            span {
              margin-left: 20px;
            }
          }
          div.icon {
            font-size: 26px;
            margin-left: 20px;
            color: #666666;
            cursor: pointer;
          }
          .likeon {
            color: #e60012 !important;
          }
        }
        .flash_right {
          display: flex;
          flex-direction: column;
          margin-top: 23px;
          .price {
            float: right;
            color: #e60012;
            font-size: 24px;
            font-weight: bold;
            .icon {
              color: #e00b24;
              font-size: 20px;
              padding-left: 5px;
              font-weight: 400;
            }
          }
          .useprice {
            color: #999999;
            font-size: 16px;
            text-decoration: line-through;
            padding-left: 5px;
          }
          .flash_info {
            font-size: 14px;
            color: #999;
            margin-top: 13px;
            font-weight: 300;
          }
        }
      }
      .flash_imgs {
        .color {
          font-size: 16px;
          margin-bottom: 20px;
        }
        .imgs_boxs {
          display: flex;
          .imgs_img {
            width: 60px;
            height: 60px;
            padding: 10px;
            border: 1px solid #f2f2f2;
            margin-right: 10px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
            &:hover {
              border: 1px solid #e60012;
            }
          }
        }
      }
      .flash_size {
        .size {
          font-size: 16px;
          margin-bottom: 8px;
        }
        .size_info {
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              padding: 5px 10px;
              font-size: 14px;
              text-align: center;
              border: 1px solid #e6e6e6;
              margin-right: 10px;
              cursor: pointer;
              margin-top: 10px;
              border-radius: 5px;
              color: #333;
              img {
                width: 50px;
                height: 50px;
                border-radius: 5px;
              }
              &.skucolor {
                padding: 0;
                width: 52px;
                height: 52px;
              }
            }
            .noneActive {
              background-color: #ececec;
              color: #000;
              pointer-events: none;
              &.skucolor {
                img {
                  opacity: 0.4;
                }
              }
            }
            .skuon {
              color: #e60012;
              border: 1px solid #e60012;
            }
            .skuimg {
              border: none;
              height: auto;
              line-height: auto;
              img {
                width: 50px;
              }
            }
          }
        }
      }
      .gifts {
        margin-top: 15px;
        color: #333;
        p {
          margin: 5px 0;
          display: flex;
          justify-content: space-between;
          .gift_txt {
            display: inline-block;
            border: 1px solid #e60012;
            color: #e60012;
            padding: 0 10px;
            border-radius: 5px;
          }
        }
      }
      .goods_num {
        .info_num {
          width: 110px;
          height: 40px;
          border: 1px solid #ccc;
          line-height: 40px;
          text-align: center;
          margin-top: 27px;
          display: inline-block;
          span {
            margin: 0 10px;
            font-size: 16px;
          }
          .icon {
            margin: 0 10px;
            cursor: pointer;
            font-size: 14px;
          }
        }

        .sku_stock {
          color: #e60012;
          font-size: 14px;
          padding-left: 10px;
        }
      }

      .flash_btn {
        display: flex;
        align-items: center;
        margin: 23px 0;
        .addcar {
          // width: 166px;
          height: 45px;
          border: 1px solid #e60012;
          background-color: #fff;
          color: #e60012;
          text-align: center;
          line-height: 45px;
          cursor: pointer;
          border-radius: 5px;
          flex-grow: 1;
          &.is_show {
            background: #ebebeb;
            opacity: 0.4;
          }
        }
        .buy {
          // width: 166px;
          height: 45px;
          background-color: #e60012;
          color: #fff;
          text-align: center;
          line-height: 45px;
          cursor: pointer;
          border-radius: 5px;
          margin-left: 20px;
          flex-grow: 1;
          &.is_show {
            opacity: 0.4;
          }
        }
        .notice {
          height: 45px;
          background-color: #e6a23c;
          color: #fff;
          text-align: center;
          line-height: 45px;
          cursor: pointer;
          border-radius: 5px;
          flex-grow: 1;
        }
        .is_show {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
      .flash_table {
        display: table;
        width: 100%;
        border-left: 1px solid #e6e6e6;
        .flash_one {
          display: table-cell;
          border: 1px solid #e6e6e6;
          border-top: none;
          border-bottom: none;
          border-left: none;
          .title {
            text-align: center;
            padding: 10px 0;
            border-bottom: 1px solid #e6e6e6;
          }
          .row {
            text-align: center;
            padding: 10px 0;
            border: 1px solid #e6e6e6;
            border-left: none;
            border-right: none;
            border-top: none;
            font-size: 13px;
            color: #666;
          }
        }
        table {
          text-align: center;
          font-size: 13px;
          color: #111111;
          border-collapse: collapse;
          tr {
            border: 1px solid #e6e6e6;
            height: 55px;
            td {
              border: 1px solid #e6e6e6;
              width: 102px;
              height: 55px;
            }
            .usebuy {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: none;
              img {
                width: 34px;
                height: auto;
              }
            }
          }
          .titles {
            height: 45px;
          }
        }
      }
    }
  }
}
</style>
