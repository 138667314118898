<template>
  <div class="confirmOrder mwframe">
    <div class="order mw">
      <div class="order_adress">
        <!-- 选择设置过的地址 -->
        <div class="checkAdress">
          <div class="checkAdress_title">选择收货地址</div>
          <div class="s"></div>
          <div class="checkAdress_info" v-if="Object.keys(addressData).length">
            <div class="name">
              {{ addressData.contacts }}
              <span class="tip" v-if="addressData.is_default == 1">默认</span>
            </div>
            <div class="adress">
              {{ addressData.province }}{{ addressData.city }}
              {{ addressData.area }}
              {{ addressData.address }}
            </div>
            <div class="phone">{{ addressData.mobile }}</div>
          </div>
          <div class="checkAdress_info" v-else>暂无收货地址</div>
          <div class="s"></div>
          <div class="checkAdress_addAdress">
            <a @click="chooseothers">新增或选择其他收货地址</a>
          </div>
          <!-- <div class="checkAdress_addAdress" v-else>
            <a @click="addressstatus = true">增加收货地址</a>
          </div> -->
        </div>
        <div class="discount" >
          <block>
            <div class="discount_title">优惠</div>
            <div class="discount_checkWay">
              <span :class="isway ? 'isway' : 'noway'" @click="changestate()">使用优惠券</span>
              <span class="ss">|</span>
              <span :class="!isway ? 'isway' : 'noway'" @click="changestate()">输入优惠券券码</span>
            </div>
            <div class="discount_selectInput">
              <el-select v-model="value" :multiple="true" v-if="!isway" placeholder="请选择使用的优惠券" class="w100"
                @change="uselistcoup">
                <el-option label="不使用优惠券" value="-1"> </el-option>
                <el-option :label="'满' +
                  item.condition +
                  (item.coupon_type == 1 ? '减' : '打') +
                  item.discount +
                  (item.coupon_type == 2 ? '折' : '')
                  " :value="item.coupon_id" v-for="(item, index) in can_list" :key="index"></el-option>
              </el-select>
              <el-input v-model="coup_account" v-else placeholder="请输入优惠券券码">
                <el-button slot="append" @click="usescoups">确定</el-button></el-input>
            </div>
            <div v-if="isway && coup_secret" class="coupon_account_select">
              <div class="coupon_account_box">
                <div class="coupon_account_content">
                  <div class="coupon_left">
                    <div class="price_box" v-if="coup_secret.coupon_type == 1">
                      ￥<span class="price_big">{{ coup_secret.discount }}</span>
                    </div>
                    <div class="price_box" v-else>
                      <span class="price_big">{{ coup_secret.discount }}</span>折
                    </div>
                    <p class="price_desc">满{{ coup_secret.condition }}可用</p>
                  </div>
                  <div class="coupon_right">
                    <div class="title_box">
                      <span class="type">{{
                        coup_secret.coupon_range == 1
                        ? "通用券"
                        : coup_secret.coupon_range == 2
                          ? "分类券"
                          : "商品券"
                      }}</span>
                      <span class="title_text">
                        {{
                          "满" +
                          coup_secret.condition +
                          (coup_secret.coupon_type == 1 ? "减" : "打") +
                          coup_secret.discount +
                          (coup_secret.coupon_type == 2 ? "折" : "")
                        }}
                      </span>
                    </div>
                    <p class="limit">
                      <template v-if="coup_secret.use_time_type == 1">
                        有效期:{{ coup_secret.use_start_time }} -
                        {{ coup_secret.use_end_time }}
                      </template>
                      <template v-else>
                        有效期:领取后{{ coup_secret.after_receive_day }}天内可用
                      </template>
                    </p>
                    <p class="rule_title">使用规则</p>
                    <div class="rule" v-html="coup_secret.use_rule"></div>
                  </div>
                </div>
              </div>
              <el-checkbox v-model="checked_coupon" @change="handleCheckCouponSecret"></el-checkbox>
            </div>
          </block>

          <div class="discount_integral">
            <div class="title">积分抵扣</div>
            <div class="desc">
              最高可用{{ data.max_score }}积分<span>可用{{ data.can_score }}积分</span><i class="icon tardiness">&#xe602;</i>
            </div>
            <div class="input">
              <el-input v-model="use_score" type="number" placeholder="手动输入积分" min="0"
                :max="Math.min(data.can_score, data.max_score, 0)" @keyup.enter="usescore" @blur="usescore">
                <el-button slot="append" @click="usescore">确定</el-button></el-input>
            </div>
          </div>
          <!-- <div class="discount_activit">
            <div class="title">活动</div>
            <div class="desc">暂不满足满减活动使用条件</div>
          </div> -->
          <div class="discount_payWay">
            <div class="payWay_title">支付方式</div>
            <div class="payWay_desc">在线支付</div>
            <div class="payWay_way">
              <!-- <div class="way" @click="pay_type = 'A01'">
                <img src="@/assets/img/alipay.png" :class="pay_type == 'A01' ? 'imgon' : ''" alt="s" />
              </div> -->
              <div class="way" @click="pay_type = 'W01'">
                <img src="@/assets/img/wechat.png" :class="pay_type == 'W01' ? 'imgon' : ''" alt="s" />
              </div>
            </div>
            <!-- <div class="payWay_need">
              <el-checkbox v-model="checked">需要电子发票</el-checkbox>
            </div> -->
          </div>
          <div class="discount_note">
            <div class="note_title">订单备注</div>
            <div class="input">
              <el-input v-model="remark" maxlength="50" placeholder="订单备注"></el-input>
            </div>
          </div>
          <div class="discount_tip" v-if="orderData.place_order_tips">
            <div class="tip_title">订单提示</div>
            <p>
              {{ orderData.place_order_tips }}
            </p>
          </div>
        </div>
        <div class="tip">
          <div class="tip_title">
            温馨提示
            <i class="icon">&#xe61e;</i>
          </div>
          <div class="tip_content">
            点击提交订单，表示您已阅读并同意
            <a @click="toquestion(order_program.sale_terms)">《MIKI HOUSE官方线上商城使用条款》</a>及
            <a @click="toquestion(order_program.privacy_policy)">《隐私政策》</a>
          </div>
        </div>
      </div>
      <div class="order_info">
        <div class="total">
          <div class="total_titl">{{ parseInt(data.goods_count) }}件商品</div>
          <div class="s"></div>
          <div class="total_goods">
            <div v-for="(goods, key, index) in goodsList" :key="key" :index="index">
              <div class="sendwhere">
                {{ key == 1 ? "国内仓发货" : "保税仓发货" }}({{ goods.length }})
              </div>
              <div class="goods_card" v-for="(item, index) in goods" :key="index">
                <div class="oneshop">
                  <div class="img">
                    <img :src="item.image" alt="" />
                  </div>
                  <div class="info">
                    <div class="info_name">
                      {{ item.subtitle || item.title }}
                    </div>
                    <div class="info_info">
                      <p v-if="item.goods_sn">商品编号：{{ item.goods_sn }}</p>
                      <p v-if="item.spec_value">规格：{{ item.spec_value }}</p>
                      <p>
                        数量：{{ item.goods_num }}
                        <span>￥{{ item.price }}</span>
                      </p>
                    </div>
                    <div class="info_tip" v-if="item.warehouse_id == 2">
                      <i class="icon">&#xe602;</i> 此产品为跨境商品
                    </div>
                  </div>
                </div>
                <div class="goods_details" v-if="item.sku_list && item.sku_list.length > 0">
                  <div class="details_imgs">
                    <div class="imgs" v-for="(q, v) in item.sku_list" :key="v">
                      <div class="imgs_igm">
                        <img :src="q.image" :alt="q.subtitle" />
                      </div>
                      <div class="imgs_name">
                        {{ q.subtitle }}
                        <p class="info_size">{{ q.spec_value }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="oneshop giftslist" v-for="(v, i) in item.gift" :key="i">
                  <div class="img">
                    <img :src="v.image" :alt="v.title" />
                  </div>
                  <div class="info">
                    <div class="info_name">
                      <span class="info_name_name">{{ v.title }}</span>
                    </div>
                    <p class="gifts gift_txt">赠品</p>
                    <p class="number">x 1</p>
                  </div>
                </div>
              </div>
              <div class="s"></div>
            </div>
            <div v-if="data.giftList && data.giftList.length > 0">
              <div class="">赠品活动</div>
              <div class="goods_card" v-for="(item, index) in data.giftList" :key="index">
                <div class="oneshop">
                  <div class="img">
                    <img :src="item.image" alt="item.title" />
                  </div>
                  <div class="info">
                    <div class="info_name">
                      {{ item.title }}
                    </div>
                    <div class="info_info">
                      <p class="gifts gift_txt">赠品</p>
                      <p class="number">x 1</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="s"></div>
            </div>

            <!-- <div class="s"></div> -->
          </div>
          <div class="total_info">
            <div class="total_price">
              <span>商品金额</span>
              <span>￥{{ data.total_price }}</span>
            </div>
            <div class="total_price">
              <span>活动优惠</span>
              <span>-￥{{ data.active_price || 0.00 }}</span>
            </div>
            <div class="total_price">
              <span>优惠券</span>
              <span>-￥{{ data.coupon_price }}</span>
            </div>
            <!-- <div class="total_price">
              <span>活动优惠</span>
              <span>￥-0</span>
            </div> -->
            <div class="total_price">
              <span> 积分抵扣 </span>
              <span>-￥{{ data.score_price }}</span>
            </div>
            <div class="total_price">
              <span>运费</span>
              <span>{{ data.freight }}</span>
            </div>
          </div>
          <div class="s"></div>
          <div class="total_sum">
            <span>总计</span>
            <span>￥{{ data.actual_pay }}</span>
          </div>
          <div class="total_get">
            <span>获得积分</span>
            <span>{{ data.get_score }}</span>
          </div>
          <div class="total_agree">
            <el-checkbox v-model="checked" class="checks"></el-checkbox>我同意
            <a style="color: #e00b24" @click="toquestion(order_program.sale_terms)">《MIKI HOUSE官方线上商城使用条款》</a>及
            <a style="color: #e00b24" @click="toquestion(order_program.privacy_policy)">《隐私政策》</a>
          </div>
          <div class="total_btn20" v-if="checked">
            <p v-if="goodsList.hasOwnProperty(2)" @click="submitOrderBefore">
              提交订单
            </p>
            <p v-else @click="submitOrder">提交订单</p>
          </div>
          <div class="total_btn2" v-else>提交订单</div>
          <div class="total_des" v-if="orderData.order_pay_explain">
            <p>说明</p>
            <p>
              {{ orderData.order_pay_explain }}
            </p>
          </div>
        </div>
        <div class="help">
          <div class="checks">
            <div v-if="order_program.delivery_info">
              <div class="checks_check">
                <div class="checktops" @click="distate = !distate">
                  <div class="desc">
                    {{ order_program.delivery_info.title }}
                  </div>
                  <div class="icon tardiness icont">
                    {{ distate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="distate" v-html="order_program.delivery_info.content"></div>
              </div>
            </div>
            <div v-if="order_program.pay_way">
              <div class="s"></div>
              <div class="checks_check">
                <div class="checktops" @click="pwstate = !pwstate">
                  <div class="desc">{{ order_program.pay_way.title }}</div>
                  <div class="icon tardiness icont">
                    {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="pwstate" v-html="order_program.pay_way.content"></div>
              </div>
            </div>
            <div v-if="order_program.electronic_invoice">
              <div class="s"></div>
              <div class="checks_check">
                <div class="checktops" @click="restate = !restate">
                  <div class="desc">
                    {{ order_program.electronic_invoice.title }}
                  </div>
                  <div class="icon tardiness icont">
                    {{ restate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="restate" v-html="order_program.electronic_invoice.content"></div>
              </div>
            </div>
            <div v-if="order_program.common_problem">
              <div class="s"></div>
              <div class="checks_check">
                <div class="checktops" @click="pgstate = !pgstate">
                  <div class="desc">
                    {{ order_program.common_problem.title }}
                  </div>
                  <div class="icon tardiness icont">
                    {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                  </div>
                </div>
                <div class="contents" v-if="pgstate" v-html="order_program.common_problem.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="isrenzheng || addressstatus || chooseaddress">
      <div class="windows" v-if="isrenzheng">
        <div class="icon x tardiness" @click="isrenzheng = false">&#xe61c;</div>
        <p class="title">实名认证</p>
        <p>
          根据中国跨境电子商务零售进口税收政策条例规定，购买跨境商品需要提供订购人的实名认证信息。本信息仅用于海关清关，miki
          house保证您的信息安全。
        </p>
        <div class="labletitle">真实姓名*</div>
        <input type="text" class="yanzhenginput" placeholder="前填写您的真实姓名" v-model="real_name" />
        <div class="labletitle">身份证号*</div>
        <input type="idcard" class="yanzhenginput" placeholder="前填写您的身份证号" v-model="id_card" />
        <div class="metionsbox">
          <i class="icon tardiness">&#xe61e;</i>
          <span>订购人的实名信息，需与“支付账号实名信息”、“收货人姓名信息”一致。</span>
        </div>
        <div class="submitbtn" @click="prove">提交</div>
      </div>
      <div class="windowsadd" v-if="addressstatus">
        <div class="widowtitle">
          新增地址
          <div class="icon tardiness" @click="addressstatus = false">
            &#xe61c;
          </div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="contents">
          <el-form-item prop="add_name">
            <p class="title">姓名</p>
            <el-input v-model="ruleForm.add_name" placeholder="请输入姓名" clearable class="eleinput"></el-input>
          </el-form-item>
          <el-form-item prop="add_phone">
            <p class="title">电话</p>
            <!-- <el-select v-model="select" placeholder="请选择" class="eleinput2">
              <el-option label="中国 +86" value="1"></el-option>
            </el-select> -->
            <el-input v-model="ruleForm.add_phone" placeholder="请输入电话号码" maxlength="11" type="tel" clearable
              class="eleinput1"></el-input>
          </el-form-item>
          <el-form-item prop="areavalue">
            <p class="title">省市区</p>
            <el-cascader v-model="ruleForm.areavalue" :options="optionsArea" class="eleinput"></el-cascader>
          </el-form-item>
          <el-form-item prop="address">
            <p class="title">详细地址</p>
            <el-input v-model="ruleForm.address" placeholder="请输入详细地址" clearable class="eleinput"></el-input>
          </el-form-item>

          <div class="setbox" @click="ruleForm.choose = !ruleForm.choose">
            <div class="icon tardiness">
              {{ ruleForm.choose ? "&#xe624;" : "&#xe64c;" }}
            </div>
            <span>设为默认地址</span>
          </div>
          <div class="savebtn" @click="submitForm('ruleForm')">立即保存</div>
        </el-form>
      </div>
      <div class="windowsadd" v-if="chooseaddress">
        <div class="widowtitle">
          选择地址
          <div class="icon tardiness" @click="chooseaddress = false">
            &#xe61c;
          </div>
        </div>
        <div class="showaddressBox">
          <div class="moreninfos" v-for="(item, index) in showAddressData" :key="item.address_id"
            @click="choosethis(index)">
            <div class="namebox">
              <span>{{ item.contacts }}</span>
              <div class="morentag" v-if="item.is_default == 1">默认</div>
              <div class="icon icondel tardiness" style="float: right" v-if="item.is_default == 2"
                @click.stop="addressdelete(item.address_id, index)">
                &#xec7b;
              </div>
            </div>
            <p class="address">
              {{ item.province }} {{ item.city }} {{ item.area }}
              {{ item.address }}
            </p>
            <p class="phone">{{ item.mobile }}</p>
            <p class="phone">{{ item.email }}</p>
          </div>
          <el-button type="primary" @click="addchooseothers" style="margin-top: 50px">新增地址</el-button>
        </div>
      </div>
    </div>

    <el-dialog title="订单提示" :visible.sync="centerDialogVisible" width="500px" top="33vh" center>
      <p>您购买的商品将从【保税仓】发货，请注意以下内容：</p>
      <p>1. 因海关政策要求，下单后不支持修改订单信息，包括但不限于收货人地址、手机号、商品规格等。物流不可中途拦截或拒收，请务必仔细确认订单信息；</p>
      <p>2. 每笔订单可包含的商品总价上限为5000元，个人年均跨境电商消费额度累计上限26000元，超限将无法清关；</p>
      <p>3. 受海关政策限制，保税仓发货的商品暂不支持无条件的7天无理由退换；</p>
      <p>4. 保税仓发货的商品视为从国外直接购买，根据相关政策无法为您提供发票。</p>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitOrder">确认下单</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { areaData } from "@/assets/js/area.js";
import { Loading } from "element-ui";
export default {
  props: [],
  data() {
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入电话号码"));
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error("请输入有效的电话号码"));
      } else {
        callback();
      }
    };
    return {
      value: [],
      radio: "1",
      isway: false,
      input: "",
      isrenzheng: false,
      checked: false,
      checked_coupon: false,
      is_max_coupon: 1, //是否最优优惠券 1是 2否
      orderData: [],
      goodsList: [],
      data: {},
      order_program: {},
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      addressData: {},
      addressstatus: false,
      remark: "",
      // 地址
      ruleForm: {
        add_name: "", //新增修改姓名
        add_phone: "", //新增修改电话号码
        areavalue: [], //省市区
        address: "", //具体地址
        choose: false,
      },
      rules: {
        add_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        add_phone: [{ required: true, validator: checkphone, trigger: "blur" }],
        areavalue: [
          {
            required: true,
            message: "请选择省市区",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
      optionsArea: [], //省市区json数据
      chooseaddress: false,
      use_score: 0,
      pay_type: "W01",
      coup_account: "",
      coup_secret: null,
      can_list: [],
      //实名认证
      real_name: "",
      id_card: "",
      centerDialogVisible: false,
      showAddressData: null,
      active_type:"",
    };
  },
  mounted() {
    this.orderData = JSON.parse(sessionStorage.getItem("orderDetail"));
    this.goodsList = this.orderData.goodsList;
    this.data = this.orderData;
    this.optionsArea = areaData;
    this.addressData = this.data.address;
    this.use_score = this.data.use_score == 0 ? "" : this.data.use_score;
    this.can_list = this.data.coupon.can_list ? this.data.coupon.can_list : [];
    this.value = this.data.coupon.max_id ? this.data.coupon.max_id : "";
    this.active_type=this.data.active_type||""
    console.log(this.data)
    if (this.data.coupon.max_id != 0) {
      this.value = [this.data.coupon.max_id]
      this.uselistcoups(this.value);
    }
    this.get_order_program();
  },
  methods: {
    submitOrderBefore() {
      this.centerDialogVisible = true;
    },
    submitOrder() {
      if (this.addressData.address_id == undefined) {
        this.$message.error("请选择收货地址");
        return;
      }
      this.centerDialogVisible = false;
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "下单中...",
      });
      console.log({
        goods_id: this.data.goods_id,
        type: this.data.type,
        goods_num: this.data.goods_num,
        sku_unique: this.data.sku_unique,
        address_id: this.addressData.address_id,
        car_id: this.data.car_id,
        market_type: this.data.market_type,
        remarks: this.remark,
        coupon_id: this.value.length > 0 ? this.value.toString() : "",
        coupon_secret: this.data.coupon_secret,
        use_score: this.data.use_score,
        active_type:this.active_type
      });
      this.$myAjax(
        "order/place_order",
        "post",
        {
          goods_id: this.data.goods_id,
          type: this.data.type,
          goods_num: this.data.goods_num,
          sku_unique: this.data.sku_unique,
          address_id: this.addressData.address_id,
          car_id: this.data.car_id,
          market_type: this.data.market_type,
          remarks: this.remark,
          coupon_id: this.value.length > 0 ? this.value.toString() : "",
          coupon_secret: this.data.coupon_secret,
          use_score: this.data.use_score,
        },
        (res) => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
          if (res.code === 1) {
            this.$router.replace({
              name: "fetchPay",
              query: {
                pay_type: this.pay_type,
                order_id: res.data.order_id,
              },
            });
          } else if (res.code === -4) {
            this.$message({
              message: res.msg,
              type: "warning",
              offset: 170,
            });
            this.isrenzheng = true;
          } else if (res.code === -5) {
            this.$router.replace({
              name: "HomeCenter",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
              offset: 170,
            });
          }
        },
        true
      );
    },
    toquestion(titleid) {
      this.$router.push({
        name: "Questions",
        query: {
          titleid: titleid,
        },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "user/add_address";
          let postData = {
            contacts: this.ruleForm.add_name,
            mobile: this.ruleForm.add_phone,
            province: this.ruleForm.areavalue[0],
            city: this.ruleForm.areavalue[1],
            area: this.ruleForm.areavalue[2],
            address: this.ruleForm.address,
            is_default: this.ruleForm.choose ? 1 : 2,
          };
          this.$myAjax(url, "post", postData, () => {
            this.addressstatus = false;
            this.$message({
              type: "success",
              message: "添加成功!",
              offset: 170,
            });
            this.$myAjax("user/address_list", "get", {}, (res) => {
              this.ruleForm = {
                add_name: "",
                add_phone: "",
                areavalue: [],
                address: "",
                choose: false,
              };
              this.addressData = res[0];
            });
          });
        }
      });
    },
    chooseothers() {
      this.$myAjax("user/address_list", "get", {}, (res) => {
        this.$nextTick(() => {
          this.showAddressData = res;
        });
        this.chooseaddress = true;
      });
    },
    addchooseothers() {
      this.chooseaddress = false;
      this.addressstatus = true;
    },
    choosethis(_index) {
      this.addressData = this.showAddressData[_index];
      this.chooseaddress = false;
    },
    usescore() {
      console.log(this.data);
      if (!this.use_score) {
        this.$message({
          message: "请输入使用积分",
          type: "warning",
          offset: 170,
        });
      } else {
        if (
          this.use_score > parseInt(this.data.max_score) ||
          this.use_score > parseInt(this.data.can_score) ||
          this.use_score < 0
        ) {
          this.$message({
            message: "积分抵扣超出可用范围",
            type: "warning",
            offset: 170,
          });
          this.use_score = 0;
        }
        this.$myAjax(
          "order/confirm_order",
          "post",
          {
            goods_id: this.data.goods_id,
            type: this.data.type,
            goods_num: this.data.goods_num,
            sku_unique: this.data.sku_unique,
            market_type: this.data.market_type,
            use_score: this.use_score,
            car_id: this.data.car_id,
            coupon_id: this.data.coupon_id,
            coupon_secret: this.data.coupon_secret,
            active_type:this.active_type
          },
          (res) => {
            this.orderData = res;
            this.goodsList = this.orderData.goodsList;
            this.data = this.orderData;
            this.optionsArea = areaData;
            this.addressData = this.data.address;
            this.use_score =
              this.data.use_score == 0 ? "" : this.data.use_score;
            this.can_list = this.data.coupon.can_list||[];
          }
        );
      }
    },
    usescoups() {
      if (!this.coup_account) {
        this.$message({
          message: "请输入优惠券券码",
          type: "warning",
          offset: 170,
        });
      } else {
        this.coupon_secret = null;
        this.checked_coupon = false;
        this.coup_secret = null;
        this.$myAjax(
          "order/input_coupon_secret",
          "post",
          {
            coupon_secret: this.coup_account,
          },
          (res) => {
            this.coup_secret = res;
          }
        );
      }
    },
    changestate() {
      if (this.isway == false) {
        this.isway = true
        if (this.data.coupon.max_id) {
          this.value = [this.data.coupon.max_id]
          this.uselistcoups(this.value);
        }
      } else {
        this.isway = false
      }

    },
    uselistcoups(id) {
      console.log(id)
      this.checked_coupon = false;
      if (id == -1) {
        this.is_max_coupon = 2;   // 不是用优惠券
        let postData = {
          goods_id: this.data.goods_id,
          type: this.data.type,
          goods_num: this.data.goods_num,
          sku_unique: this.data.sku_unique,
          market_type: this.data.market_type,
          coupon_id: "",
          car_id: this.data.car_id,
          is_max_coupon: this.is_max_coupon,
          active_type:this.active_type
        };
        if (this.coup_account) {
          postData.use_score = this.use_score;
        }
        this.$myAjax("order/confirm_order", "post", postData, (res) => {
          this.orderData = res;
          this.goodsList = this.orderData.goodsList;
          this.data = this.orderData;
          this.optionsArea = areaData;
          this.addressData = this.data.address;
          this.use_score = this.data.use_score == 0 ? "" : this.data.use_score;
          this.can_list = this.data.coupon.can_list;
        });
      } else {
        this.is_max_coupon = 1;
        let ids = id.toString()
        let postData = {
          goods_id: this.data.goods_id,
          type: this.data.type,
          goods_num: this.data.goods_num,
          sku_unique: this.data.sku_unique,
          market_type: this.data.market_type,
          coupon_id: ids,
          car_id: this.data.car_id,
          is_max_coupon: this.is_max_coupon,
          active_type:this.active_type
        };
        if (this.coup_account) {
          postData.use_score = this.use_score;
        }
        this.$myAjax("order/confirm_order", "post", postData, (res) => {
          this.orderData = res;
          this.goodsList = this.orderData.goodsList;
          this.data = this.orderData;
          this.optionsArea = areaData;
          this.addressData = this.data.address;
          this.use_score = this.data.use_score == 0 ? "" : this.data.use_score;
          this.can_list = this.data.coupon.can_list;
        });

      }
    },

    uselistcoup() {
      if (this.value.length > 1) {
        let lastvalue = this.value[this.value.length - 1]
        let index = this.value.indexOf(lastvalue)
        let zhia = ""
        let zhib = ""
        this.can_list.map((item) => {
          if (item.coupon_id == this.value[0]) {
            zhia = item
          }
        })
        this.can_list.map((item) => {
          if (item.coupon_id == lastvalue) {
            zhib = item
          }
        })
        console.log(zhia, zhib)
        if (zhia.stack == 1) {
          if (zhib.stack == 2) {
            this.value.splice(index, 1)
            this.$message({
              message: "此种类型优惠券不能叠加",
              type: "warning",
              offset: 170,
            });
          }
        } else if (zhia.stack == 2) {
          this.value.splice(index, 1)
          this.$message({
            message: "此种类型优惠券不能叠加",
            type: "warning",
            offset: 170,
          });
        }
      }
      console.log(this.value.length)
      if(this.value.length==0){
        this.value[0]="-1"
      }

      this.uselistcoups(this.value)
    },

    handleCheckCouponSecret() {
      let postData = {
        goods_id: this.data.goods_id,
        type: this.data.type,
        goods_num: this.data.goods_num,
        sku_unique: this.data.sku_unique,
        market_type: this.data.market_type,
        coupon_secret: this.checked_coupon ? this.coup_account : null,
        coupon_id: this.checked_coupon ? this.coup_secret.coupon_id.toString() : null,
        car_id: this.data.car_id,
        active_type:this.active_type,
      };
      if (this.coup_account) {
        postData.use_score = this.use_score;
      }
      this.$myAjax(
        "order/confirm_order",
        "post",
        postData,
        (res) => {
          if (res.code != 1) {
            console.log(res.msg);
            this.$message({
              message: res.msg,
              type: "warning",
              offset: 170,
            });
            this.checked_coupon = !this.checked_coupon;
          } else {
            this.orderData = res.data;
            this.goodsList = this.orderData.goodsList;
            this.data = this.orderData;
            this.optionsArea = areaData;
            this.addressData = this.data.address;
            this.use_score =
              this.data.use_score == 0 ? "" : this.data.use_score;
            this.can_list = this.data.coupon.can_list;
            this.value = [this.coup_secret.coupon_id]
          }
        },
        true
      );
    },
    prove() {
      //认证
      let { real_name, id_card } = { ...this };
      this.$myAjax(
        "user/prove",
        "post",
        {
          real_name,
          id_card,
        },
        () => {
          this.isrenzheng = false;
        }
      );
    },
    get_order_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.order_program = res;
      });
    },
    addressdelete(_id) {
      this.$confirm("此操作将删除地址信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$myAjax(
          "user/del_address",
          "post",
          {
            address_id: _id,
          },
          () => {
            this.$message({
              type: "success",
              message: "删除成功!",
              offset: 170,
            });
            this.chooseothers();
          }
        );
      });
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
.el-button--primary {
  color: #fff;
  background-color: #e00b24;
  border-color: #e00b24;
}

.coupon_account_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  margin: 0 auto;
}

.coupon_account_box {
  position: relative;
  height: 130px;
  width: 500px;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
  margin: 22px 0 18px 0;
  background: radial-gradient(circle at right top, transparent 10px, #fff 0) top left / 150px 51% no-repeat,
    radial-gradient(circle at right bottom, transparent 10px, #fff 0) bottom left / 150px 51% no-repeat,
    radial-gradient(circle at left top, transparent 10px, #fff 0) top right / 350px 51% no-repeat,
    radial-gradient(circle at left bottom, transparent 10px, #fff 0) bottom right / 350px 51% no-repeat;

  .coupon_account_content {
    position: absolute;
    height: 100%;
    left: 10px;
    right: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .coupon_left {
      width: 140px;
      flex-shrink: 0;
      padding-right: 10px;

      .price_box {
        color: #333;
        text-align: center;
        line-height: 40px;
        height: 40px;

        .price_big {
          font-weight: bold;
          font-size: 30px;
        }
      }

      .price_desc {
        font-size: 16px;
        font-family: DengXian Regular, DengXian Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 16px;
        text-align: center;
      }

      &::after {
        content: "";
        position: absolute;
      }
    }

    .coupon_right {
      flex-grow: 1;
      padding-left: 20px;
      align-self: flex-start;
      margin-top: 5px;

      .title_box {
        line-height: 30px;
        height: 30px;

        .type {
          font-size: 12px;
          border: 1px solid;
          padding: 1px 5px;
          margin-right: 5px;
        }

        .title_text {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 16px;
        }
      }

      .limit {
        font-size: 12px;
        font-family: DengXian Regular, DengXian Regular-Regular;
        font-weight: 400;
        color: #999999;
        margin-bottom: 10px;
      }

      .rule_title {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        margin-bottom: 5px;
      }

      .rule {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
      }
    }
  }
}

.coupon_account_box::before {
  content: "";
  position: absolute;
  height: 100%;
  left: -10px;
  width: 20px;
  background-image: radial-gradient(circle at 1px 8px,
      transparent 6px,
      #fff 6px,
      #fff 0px);
  background-size: 200px 18px;
  background-repeat-x: no-repeat;
}

.coupon_account_box::after {
  content: "";
  position: absolute;
  height: 100%;
  right: -10px;
  width: 20px;
  background-image: radial-gradient(circle at 20px 8px,
      transparent 6px,
      #fff 6px,
      #fff 0px);
  background-size: 20px 18px;
  background-repeat-x: no-repeat;
}

.confirmOrder {
  padding-top: 140px;

  .w100 {
    width: 100%;
  }

  .w90 {
    width: 90%;
  }

  .w33 {
    width: 32%;
  }

  .s {
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }

  .order {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 75px;
    padding-top: 53px;

    .order_adress {
      width: 60%;
      padding-top: 40px;

      .setAdress {
        .setAdress_title {
          font-size: 28px;
          color: #000011;
        }

        .form {
          .row {
            display: flex;
            margin-top: 32px;

            .divinput {
              .label {
                margin-bottom: 7px;
                font-size: 14px;
              }

              .input {}

              .cascader {
                width: 110%;
              }
            }
          }

          .setDefault {
            margin-top: 29px;

            .icon {
              color: #111;
              font-size: 14px;
              cursor: pointer;
            }

            span {
              margin-left: 9px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }

      .checkAdress {
        .checkAdress_title {
          font-size: 28px;
          color: #000011;
        }

        .checkAdress_info {
          >div {
            margin: 15px 0;
          }

          .name,
          .adress {
            color: #000011;
            font-size: 14px;
          }

          .name {
            .tip {
              border: 1px solid red;
              padding: 0 5px;
              color: red;
              font-size: 12px;
            }
          }

          .adress {
            a {
              float: right;
              text-decoration: underline;
              cursor: pointer;
            }
          }

          .phone {
            font-size: 16px;
            color: #000011;
          }
        }

        .checkAdress_addAdress {
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .discount {
        .w100 {
          width: 100%;
        }

        .title {
          font-size: 18px;
        }

        .desc {
          font-size: 14px;
        }

        .discount_title {
          font-size: 28px;
          color: #000011;
          margin-top: 58px;
        }

        .discount_checkWay {
          margin: 22px 0 18px 0;

          span {
            font-size: 16px;
          }

          .ss {
            margin: 0 20px;
            color: #999;
            font-size: 12px;
          }

          .isway {
            color: #666;
            font-weight: 400;
            cursor: pointer;
          }

          .noway {
            cursor: pointer;
          }
        }

        .discount_selectInput {}

        .discount_integral {
          >div {
            margin: 10px 0;
            color: #000011;
          }

          .title {
            margin-top: 39px;
          }

          .desc {
            margin-top: 15px;
            font-size: 14px;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
              margin-left: 13px;
            }

            .icon {
              position: absolute;
              right: 0;
              font-size: 12px;
              cursor: pointer;
              color: #999;
            }
          }

          .input {
            input {
              width: 100%;
            }
          }
        }

        .discount_activit {
          .title {
            margin-top: 39px;
            margin-bottom: 13px;
          }
        }

        .discount_note {
          .note_title {
            font-size: 28px;
            color: #000000;
            margin-top: 44px;
            margin-bottom: 13px;
          }

          .input {
            input {
              width: 100%;
            }
          }
        }

        .discount_payWay {
          .payWay_title {
            font-size: 28px;
            color: #000011;
            margin: 44px 0 28px 0;
          }

          .payWay_desc {
            font-size: 14px;
            color: #000011;
          }

          .payWay_way {
            margin: 23px 0 35px 0;
            display: flex;

            .way {
              width: 163px;
              margin-right: 20px;
              height: 50px;

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;

                &:hover {
                  border: 1px solid #000;
                  // transition: all 0.5s;
                }
              }

              .imgon {
                border: 1px solid #000;
              }
            }
          }
        }

        .discount_tip {
          .tip_title {
            font-size: 16px;
            color: #ff6f00;
            margin: 56px 0 30px 0;
          }

          p {
            font-size: 14px;
            color: #e60012;
          }
        }
      }

      .tip {
        .tip_title {
          font-size: 16px;
          margin: 29px 0 20px 0;
          color: #111;

          .icon {
            cursor: pointer;
          }
        }

        .tip_content {
          font-size: 14px;
          color: #111;

          a {
            color: #e00b24;
            cursor: pointer;
          }
        }
      }
    }

    .order_info {
      width: 32.4%;

      .s {
        border-top: 1px solid #eee;
        margin: 10px 0;
      }

      .total {
        padding: 20px;
        background-color: #fff;

        .total_titl {
          font-size: 18px;
          text-align: center;
        }

        >div {
          margin-bottom: 20px;

          span:nth-of-type(2) {
            float: right;
          }

          span {
            font-size: 16px;
            color: #111;
          }
        }

        .total_goods {
          .sendwhere {
            border: 1px solid #e60012;
            border-radius: 5px;
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            color: #e60012;
            display: inline-block;
          }

          .goods_card {
            margin-bottom: 10px;

            .oneshop {
              display: flex;
            }

            .goods_details {
              .details_imgs {
                padding: 8px;
                background-color: #eeeeeea8;

                .imgs {
                  display: flex;
                  align-content: center;
                  margin-top: 12px;
                  cursor: pointer;

                  .imgs_igm {
                    width: 50px;
                    background-color: #fff;
                    padding: 4px;
                    height: 50px;
                    margin-right: 10px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .imgs_name {
                    font-size: 14px;

                    .info_size {
                      margin-top: 5px;
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            // .giftslist {
            //   background-color: #eeeeeea8;
            // }
            .img {
              width: 112px;
              height: 112px;
              padding: 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .info {
              flex-grow: 1;

              .info_name {
                font-size: 16px;
                color: #111;
                margin-bottom: 10px;
              }

              .info_info {
                p {
                  font-size: 14px;

                  span {
                    float: right;
                    font-size: 16px;
                  }
                }
              }

              .info_tip {
                color: #999;
                font-size: 12px;
                margin-top: 16px;

                .icon {
                  font-size: 14px;
                  cursor: pointer;
                }
              }

              .gift_txt {
                display: inline-block;
                border: 1px solid #e60012;
                color: #e60012;
                padding: 0 10px;
                border-radius: 5px;
              }
            }
          }
        }

        .total_info {
          >div {
            margin-bottom: 10px;
          }

          .total_price {
            span {
              font-size: 14px;
            }
          }
        }

        .total_sum {
          span:nth-of-type(1) {
            font-size: 20px;
          }

          span:nth-of-type(2) {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .total_get {
          span {
            font-size: 14px;
          }
        }

        .total_agree {
          font-size: 14px;

          .checks {
            margin-right: 9px;
          }

          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .total_btn2 {
          height: 46px;
          background-color: #dcdcdc;
          text-align: center;
          line-height: 46px;
          color: #000;
          margin-top: 2px;
          cursor: pointer;
          font-size: 14px;
        }

        .total_btn20 {
          height: 46px;
          background-color: #e00b24;
          text-align: center;
          line-height: 46px;
          color: #fff;
          margin-top: 2px;
          cursor: pointer;
          font-size: 14px;
        }

        .total_des {
          p {
            color: #111;
          }

          p:nth-of-type(1) {
            font-size: 16px;
          }

          p:nth-of-type(2) {
            font-size: 14px;
            margin-top: 22px;
          }
        }
      }

      .help {
        background-color: #fff;
        margin-top: 12px;
        padding: 0 30px;

        .checks {
          padding: 1px 0;

          .s {
            width: 100%;
            border-top: 1px solid #ccc;
          }

          .checks_check {
            margin: 20px 0;
            display: flex;
            flex-direction: column;

            .checktops {
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              flex-direction: row;

              .lefts {
                display: flex;
                align-items: center;

                .icon {
                  font-size: 22px;
                }

                .desc {
                  margin-left: 14px;
                  color: #000000;
                  line-height: 24px;
                }
              }

              .icont {
                font-size: 14px;
              }
            }

            .contents {
              color: #7c7c7c;
              font-size: 14px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;

  .windows {
    width: 700px;
    height: 600px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -350px;
    margin-top: -300px;
    position: relative;
    border-radius: 20px;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    z-index: 99;

    .x {
      position: absolute;
      right: 47px;
      top: 38px;
      font-size: 18px;
      color: #111;
      cursor: pointer;
    }

    p {
      margin-top: 33px;
      color: #000000;
      font-size: 16px;
      text-align: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }

    .labletitle {
      margin-top: 35px;
      font-size: 16px;
      color: #000;
    }

    .yanzhenginput {
      padding: 15px 0;
      margin-top: 10px;
      font-size: 16px;
      color: #000;
      outline: none;
      border: none;
      background: transparent;
      border-bottom: 1px solid #dcdcdc;
    }

    .metionsbox {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        font-size: 15px;
        color: #000;
      }

      span {
        margin-left: 6px;
        color: #000000;
        font-size: 14px;
      }
    }

    .submitbtn {
      margin-top: 50px;
      width: 100%;
      height: 54px;
      background: #e60012;
      text-align: center;
      line-height: 54px;
      font-size: 14px;
      cursor: pointer;
      color: #fff;
    }
  }

  .windowsadd {
    width: 603px;
    height: 627px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -313.5px;
    position: relative;

    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;

      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }

    .showaddressBox {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      height: 572px;
      overflow-y: auto;

      .moreninfos {
        float: left;
        display: inline-block;
        border-bottom: 1px solid #eee;
        cursor: pointer;

        .namebox {
          margin-top: 27px;
          width: 100%;
          display: inline-block;

          span {
            color: #000000;
            font-size: 16px;
            float: left;
          }

          .morentag {
            margin-left: 16px;
            width: 41px;
            height: 19px;
            border: 1px solid #666666;
            text-align: center;
            line-height: 19px;
            font-size: 13px;
            color: #666666;
            float: left;
          }
        }

        .address {
          margin-top: 10px;
          font-size: 14px;
          color: #666666;
        }

        .phone {
          margin-top: 8px;
          font-size: 14px;
          color: #666666;
        }
      }
    }

    .contents {
      padding: 0 40px;

      .title {
        font-size: 14px;
        color: #000000;
      }

      .eleinput {
        width: 100%;
        margin-top: 12px;
      }

      .eleinput2 {
        width: 250px;
        margin-top: 12px;
      }

      .eleinput3 {
        margin-top: 12px;
        width: 250px;
        margin-left: 22px;
      }

      .setbox {
        margin-top: 34px;
        cursor: pointer;

        .icon {
          font-size: 17px;
          color: #000000;
          float: left;
        }

        span {
          margin-left: 9px;
          font-size: 14px;
          color: #000000;
          float: left;
        }
      }

      .savebtn {
        width: 182px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #e60012;
        margin-top: 33px;
        font-size: 16px;
        font-weight: bold;
        color: #e60012;
        text-align: center;
        line-height: 48px;
        position: relative;
        left: 50%;
        margin-left: -182px;
        float: left;
        cursor: pointer;
      }
    }
  }
}
</style>
