<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <div class="leftcontent" v-if="wuliu_data">
          <div class="status">
            <p class="title">物流跟踪</p>
            <p class="status_canbe" v-if="wuliu_data.State <= 2">物流配送中</p>
            <p class="status_canbe" v-if="wuliu_data.State >= 3">已签收</p>
          </div>
          <div class="status">
            <p class="ids">运单号：{{ wuliu_data.logistics_sn }}</p>
            <p class="row">承运方：{{ wuliu_data.logistics_company }}</p>
          </div>
          <div class="status">
            <div class="logstimeline">
              <el-timeline v-if="wuliu_data.Traces && wuliu_data.Traces.length > 0">
                <el-timeline-item
                  v-for="(activity, index) in wuliu_data.Traces"
                  :key="index"
                  :timestamp="activity.AcceptTime"
                >
                  {{ activity.AcceptStation }}
                </el-timeline-item>
              </el-timeline>
              <p v-else style="text-align: center;">暂无物流信息</p>
            </div>
          </div>
        </div>
        <div class="rightcontent">
          <div class="righttitle">当前订单物流</div>
          <div class="rightinfo" v-if="sales_data">
            <div class="shops">
              <div
                class="shopdetail"
                v-for="(item, key) in sales_data"
                :key="key"
              >
                <img :src="item.goods_image" alt="" />
                <div class="detailbox">
                  <p class="name">{{ item.goods_title }}</p>
                  <p class="ids" v-if="item.goods_sn.length > 0">
                    商品编号：{{ item.goods_sn }}
                  </p>
                  <p v-if="item.spec_value.length > 0">
                    规格：{{ item.spec_value }}
                  </p>
                  <p>数量：{{ item.goods_num }}</p>
                </div>
                <div class="price">￥{{ item.goods_price }}</div>
              </div>
            </div>
          </div>
          <div class="help">
            <div class="checks" v-if="order_program">
              <div v-if="order_program.delivery_info">
                <div class="checks_check">
                  <div class="checktops" @click="distate = !distate">
                    <div class="desc">
                      {{ order_program.delivery_info.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ distate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="distate"
                    v-html="order_program.delivery_info.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.pay_way">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pwstate = !pwstate">
                    <div class="desc">{{ order_program.pay_way.title }}</div>
                    <div class="icon tardiness icont">
                      {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pwstate"
                    v-html="order_program.pay_way.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.electronic_invoice">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="restate = !restate">
                    <div class="desc">
                      {{ order_program.electronic_invoice.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ restate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="restate"
                    v-html="order_program.electronic_invoice.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.common_problem">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pgstate = !pgstate">
                    <div class="desc">
                      {{ order_program.common_problem.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pgstate"
                    v-html="order_program.common_problem.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      order_id: 0,
      wuliu_data: null,
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      order_program: {},
      sales_data: {},
    };
  },
  mounted() {
    this.order_id = this.$route.query.order_id;
    this.getData();
    this.getGoodsData();
    this.get_order_program();
  },
  methods: {
    getData() {
      let { order_id } = { ...this };
      this.$myAjax(
        "order/query_logistics?order_id=" + order_id,
        "get",
        {},
        (res) => {
          this.wuliu_data = res;
        }
      );
    },
    getGoodsData() {
      let { order_id } = { ...this };
      this.$myAjax("order/order_goods_list", "get", { order_id }, (res) => {
        this.sales_data = res;
      });
    },
    get_order_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.order_program = res;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  min-height: 1157px;
  .fromtop {
    margin-top: 50px;
    .leftcontent {
      width: 60%;
      float: left;
      .status {
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        .title {
          font-size: 30px;
          color: #000000;
          font-weight: bold;
          
        }
        .status_canbe {
          margin-top: 32px;
          font-size: 16px;
          font-weight: bold;
        }
        .tips {
          margin-top: 17px;
          font-size: 14px;
          color: #333333;
        }
        .ids {
          margin-top: 21px;
          font-size: 14px;
          color: #111111;
        }
        .row {
          margin-top: 22px;
          color: #111111;
          font-size: 14px;
        }
        .price {
          margin-top: 20px;
          color: #111111;
          font-size: 14px;
          span {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .pricedetail {
          margin-top: 15px;
          display: inline-block;
          width: 100%;
          span {
            font-size: 16px;
            color: #111111;
            float: left;
          }
          .fromright {
            float: right;
          }
        }
        .logstimeline {
          padding: 25px 0;
        }
      }
    }
    .rightcontent {
      width: 480px;
      float: right;
      .righttitle {
        height: 64px;
        text-align: center;
        line-height: 64px;
        border-bottom: 1px solid #e6e6e6;
        font-size: 20px;
        color: #000000;
        background: #fff;
      }
      .rightinfo {
        width: 480px;
        height: 220px;
        background: #fff;

        .shops {
          width: 100%;
          padding: 12px 16px 27px 15px;
          .ifoutsides {
            display: inline-block;
            width: 100%;
            margin-bottom: 21px;
            img {
              width: 29px;
              height: 25px;
              float: left;
            }
            .oustnumber {
              margin-left: 12px;
              font-size: 16px;
              color: #111111;
              float: left;
            }
            .outsaddress {
              font-size: 16px;
              color: #111111;
              float: right;
            }
          }
          .shopdetail {
            display: inline-block;
            width: 100%;
            img {
              width: 110px;
              height: 110px;
              float: left;
            }
            .detailbox {
              margin-left: 17px;
              float: left;
              p {
                font-size: 14px;
                color: #111111;
              }
              .name {
                font-size: 18px;
                color: #111111;
              }
              .ids {
                margin-top: 10px;
              }
              .metions {
                margin-top: 16px;
                float: left;
                display: inline-block;
                .icon {
                  font-size: 16px;
                  color: #999999;
                  float: left;
                }
                span {
                  margin-left: 8px;
                  color: #999999;
                  float: left;
                  font-size: 14px;
                }
              }
            }
            .price {
              float: right;
              line-height: 110px;
              font-size: 14px;
              color: #111111;
            }
          }
        }
      }
    }
    .help {
      background-color: #fff;
      margin-top: 12px;
      padding: 0 30px;
      .checks {
        padding: 1px 0;
        .s {
          width: 100%;
          border-top: 1px solid #ccc;
        }
        .checks_check {
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          .checktops {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            flex-direction: row;
            .lefts {
              display: flex;
              align-items: center;
              .icon {
                font-size: 22px;
              }
              .desc {
                margin-left: 14px;
                color: #000000;
                line-height: 24px;
              }
            }
            .icont {
              font-size: 14px;
            }
          }
          .contents {
            color: #7c7c7c;
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
