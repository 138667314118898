<template>
  <div class="seriesDetails">
    <!-- 限时抢购组件 -->
    <div class="component mw">
      <siftingStyle
        :screenData="screenData"
        :classifyData="classifyData"
        :brandData="brandData"
        :labelData="labelData"
        :navData="navData"
        :is_show_left="is_show_left"
        @minprice="minprice"
        @maxprice="maxprice"
        @search="search"
        @brand="brandChoose"
        @other="otherChoose"
        @cate="cateChoose(arguments)"
        @spec="specChoose(arguments)"
        :show_stock.sync="in_stock"
      >
        <div slot="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              v-for="(item, index) in navData"
              :key="index"
              :to="{ path: toclassify(item.link, item.id) }"
              class="breadcrumb-item"
              >{{ item.title }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </siftingStyle>
      <siftingList
        :shopsData="shopsData"
        :total_page="total_page"
        :total_size="total_count"
        :page="page"
        :show_list="true"
        :tips="tips"
        @update="datachange"
        @hideleft="hideleft"
        @handlesku="handlesku"
      ></siftingList>
    </div>
  </div>
</template>
<script>
import siftingStyle from "../../components/Sifting/sifting_style";
import siftingList from "../../components/Sifting/sifting_list";
const drag = 0;
export default {
  components: {
    siftingStyle,
    siftingList,
  },
  data() {
    return {
      screenData: [], //筛选数据
      classifyData: [], //分类数据
      brandData: [], //品牌数据
      labelData: [], //、其他数据
      shopsData: [], //商品数据
      page: 1, //加载页数
      total_page: 1, //总页数
      total_count: 0,
      recid: "",
      classify_ids: "",
      sort_field: "",
      sort_order: "",
      minPrice: "",
      maxPrice: "",
      labelid: "",
      brandid: "",
      issend: null,
      searchvalue: "",
      userid: "",
      is_show_left: true,
      spec_value: "",
      nav_id: "",
      navData: [],
      tips: "加载中",
      in_stock: false,
    };
  },
  mounted() {
  
    if (this.$route.query.nav_id) {
      this.nav_id = this.$route.query.nav_id;
    }
    if (this.$route.query.recid) {
      this.recid = this.$route.query.recid;
    }
    if (this.$route.query.classify_ids) {
      this.classify_ids = this.$route.query.classify_ids;
    }
    if (this.$route.query.issend) {
      this.issend = this.$route.query.issend;
    }
    if (this.$route.query.title) {
      this.shiwtitle = this.$route.query.title;
    }
    if (this.$route.query.brandid) {
      this.brandid = this.$route.query.brandid;
    }
    if (this.$route.query.value) {
      this.searchvalue = this.$route.query.value;
    }
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
    }
     if (localStorage.getItem("page")) {
      this.page =Number(localStorage.getItem("page")) ;
    }
    // 查询左边的分类
    this.getDatabase();
    // localStorage.removeItem("page")
  },
  watch: {
    in_stock() {
      this.getshopsData();
    },
    page(newvalue,oldvalue){
      console.log(newvalue,oldvalue)
      localStorage.setItem("page",this.page)
    },
  },
  methods: {
    scrollToTop() {
      const gap = document.documentElement.scrollTop || document.body.scrollTop;
      if (gap > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, gap - gap / drag);
      }
    },
    getDatabase() {
      let newclassify = this.classify_ids;
      if (this.issend) {
        newclassify = "";
      }
      this.$myAjax(
        "goods/goods_attr?classify_id=" +
          newclassify +
          "&brand_id=" +
          this.brandid +
          "&nav_id=" +
          this.nav_id,
        "get",
        {},
        (res) => {
          res.screen.map((item) => {
            item.open = false;
          });
          res.screen.map((item) => {
            item.next.map((btem) => {
              btem.ison = false;
            });
          });
          this.screenData = res.screen;
          if (res.classify) {
            this.classifyData = res.classify.map((item) => {
              if (item.next) {
                item.next = item.next.map((opt) => {
                  opt.is_selected = false;
                  return opt;
                });
              }
              return item;
            });
          }
          res.brand.map((item) => {
            if (this.brandid == item.id) {
              item.ison = true;
            } else {
              item.ison = false;
            }
          });
          this.brandData = res.brand;
          res.label.map((item) => {
            item.ison = false;
          });
          this.labelData = res.label;
          this.navData = res.nav;
          this.getshopsData();
        }
      );
    },
    getshopsData() {
      let classify_ids = [],
        spec_value = [],
        params = [];
      this.classifyData.map((item) => {
        if (item.next) {
          item.next.map((opt) => {
            if (opt.is_selected == true) {
              classify_ids.push(opt.id);
            }
          });
        }
      });
      this.screenData.map((item) => {
        if (item.next) {
          let spec_value_item = [];
          let params_item = [];
          item.next.map((opt) => {
            if (opt.ison == true) {
              if (item.type == 1) {
                spec_value_item.push(opt.title);
              } else {
                params_item.push(opt.title);
              }
            }
          });
          if (spec_value_item.length > 0) {
            spec_value.push(spec_value_item.join(","));
            spec_value_item = [];
          }
          if (params_item.length > 0) {
            params.push(params_item.join(","));
            params_item = [];
          }
        }
      });
      if (classify_ids.length > 0) {
        classify_ids.join(",");
      } else {
        classify_ids = this.classify_ids;
      }
      spec_value = { ...spec_value };
      params = { ...params };

      this.tips = "加载中";
      this.$myAjax(
        "goods/goods_list?page=" +
          this.page +
          "&recd_id=" +
          this.recid +
          "&classify_id=" +
          classify_ids +
          "&sort_field=" +
          this.sort_field +
          "&sort_order=" +
          this.sort_order +
          "&brand_id=" +
          this.brandid +
          "&min_price=" +
          (this.minPrice != "" ? this.minPrice : 0) +
          "&max_price=" +
          (this.maxPrice != "" ? this.maxPrice : 1000000000) +
          "&label_id=" +
          this.labelid +
          "&keywords=" +
          this.searchvalue +
          "&identity_id=" +
          this.userid +
          "&spec_value=" +
          JSON.stringify(spec_value) +
          "&params=" +
          JSON.stringify(params) +
          "&in_stock=" +
          (this.in_stock ? "1" : ""),
        "get",
        {},
        (res) => {
          res.list.map((item) => {
            item.sku_img_index = -1;
            return item;
          });
          this.shopsData = res.list;
          this.total_page = res.total_page;
          this.total_count = res.total_count;
          this.scrollToTop();
          this.tips = "";
        }
      );
    },
    loadmore(page) {
      this.page = page;
      this.getshopsData();
    },
    datachange(e) {
      this.sort_field = e.split(",")[0];
      this.sort_order = e.split(",")[1];
      this.shopsData = [];
      this.getshopsData();
    },
    hideleft() {
      this.is_show_left = !this.is_show_left;
    },
    minprice(e) {
      this.minPrice = e;
    },
    maxprice(e) {
      this.maxPrice = e;
    },
    search() {
      this.getshopsData();
    },
    brandChoose(e) {
      this.brandData[e].ison = !this.brandData[e].ison;
      this.shaix();
    },
    otherChoose(e) {
      this.labelData[e].ison = !this.labelData[e].ison;
      this.shaix();
    },
    cateChoose(arg) {
      let index = arg[2];
      let index1 = arg[3];
      this.classifyData = this.classifyData.map((item, i) => {
        item.next = item.next.map((opt, i1) => {
          if (i === index && i1 === index1) {
            opt.is_selected = !opt.is_selected;
          }
          return opt;
        });
        return item;
      });
      this.shopsData = [];
      this.total_page = 1;
      this.getshopsData();
    },
    specChoose(arg) {
      let index = arg[0];
      let index1 = arg[1];
      this.screenData = this.screenData.map((item, i) => {
        item.next = item.next.map((opt, i1) => {
          if (i === index && i1 === index1) {
            opt.ison = !opt.ison;
          }
          return opt;
        });
        return item;
      });
      this.shopsData = [];
      this.total_page = 1;
      this.getshopsData();
    },
    shaix() {
      let brandid = [],
        labelid = [];
      this.brandData.map((item) => {
        if (item.ison) {
          brandid.push(item.id);
        }
      });
      this.brandid = brandid.toString();
      this.labelData.map((item) => {
        if (item.ison) {
          labelid.push(item.id);
        }
      });
      this.labelid = labelid.toString();
      this.shopsData = [];
      this.getshopsData();
    },
    handlesku({ i1, i2 }) {
      this.shopsData[i1].sku_img_index = i2;
      this.shopsData[i1].image = this.shopsData[i1].sku_image[i2];
    },
    toclassify(link, id) {
      if (link) {
        link = link.replace(/\/index#/i, "");
        if (link.indexOf("?") > -1) {
          link = link + "&t=" + new Date().getTime() + "&nav_id=" + id;
        } else {
          link = link + "?t=" + new Date().getTime() + "&nav_id=" + id;
        }
      }
      return link;
    },
  },
};
</script>
<style lang="less" scoped>
.seriesDetails {
  background: #fafafa;
  margin-top: 140px;
  .breadcrumb-item {
    cursor: pointer;
  }
  .component {
    display: flex;
    justify-content: space-between;
  }
}
</style>
