<template>
  <div class="suitList mwframe">
    <!-- banner -->
    <div class="banner">
      <el-carousel trigger="click" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(v, i) in swiperData" :key="i">
          <img :src="v.image" :alt="v.title" width="100%" height="inherit" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 左右组件 -->
    <div class="lrBOx mw">
      <div style="padding: 32px 0 0 0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"
            >当前位置：首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>优惠套餐</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="lrBOx_right">
        <div class="desc">
          <img :src="dataimg" alt="" />
        </div>
        <div class="sifting-list" :class="{ is_fixed: is_fixed }">
          <div class="list-title" ref="list_title" :style="{ top: top }">
            <div class="title-name">全部套餐</div>
            <div class="conditional-sort">
              <span>按下列条件排序： </span>
              <el-select
                v-model="value"
                @change="sortChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="list-content">
            <div
              class="goods-card"
              v-for="(v, i) in goodsList"
              :key="i"
              @click="tomealdetail(v.menu_id)"
            >
              <div class="img">
                <img :src="v.image" alt="" />
              </div>
              <div class="goods-name">
                {{ v.title }}
              </div>
              <div class="goods-price">
                <slot name="goods_price"> ￥{{ v.price }} </slot>
              </div>
              <div class="remain">
                <slot name="remain"> </slot>
              </div>
            </div>
          </div>
          <div class="more-goods" v-if="page < total_page" @click="loadmore">
            发现更多
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperData: [], //轮播图
      dataimg: "", //中间的图
      page: 1,
      total_page: 1,
      goodsList: [],
      options: [
        {
          value: "",
          label: "综合排序",
        },
        {
          value: "price,asc",
          label: "价格升序",
        },
        {
          value: "price,desc",
          label: "价格降序",
        },

        {
          value: "sales,desc",
          label: "热门排序",
        },
      ],
      value: "综合排序",
      sort_field: "",
      sort_order: "",

      // 图片父容器高度
      bannerHeight: 439,
      // 浏览器宽度
      screenWidth: 0,
      is_show: true,
      is_fixed: false,
      top: "0px",
      list_top: -1,
    };
  },
  mounted() {
    this.getBanner();
    this.getMealData();

    // 首次加载时,需要调用一次
    this.screenWidth = window.innerWidth;
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.setSize();
    };
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getBanner() {
      this.$myAjax("pc/menu_banner", "get", {}, (res) => {
        console.log(res);
        this.swiperData = res.menu_banner;
        this.dataimg = res.menu_explain_img;
      });
    },
    getMealData() {
      this.$myAjax(
        "goods/menu_list?page=" +
          this.page +
          "&sort_field=" +
          this.sort_field +
          "&sort_order=" +
          this.sort_order,
        "get",
        {},
        (res) => {
          console.log(res);
          this.goodsList = this.goodsList.concat(res.list);
          this.total_page = res.total_page;
        }
      );
    },
    loadmore() {
      this.page++;
      this.getMealData();
    },
    tomealdetail(_id) {
      this.$router.push({
        name: "suitDetails",
        query: {
          id: _id,
        },
      });
    },
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight =
        (620 / 1920) * this.screenWidth >= 439
          ? (620 / 1920) * this.screenWidth
          : 439;
    },
    sortChange(e) {
      this.sort_field = e.split(",")[0];
      this.sort_order = e.split(",")[1];
      this.goodsList = [];
      this.getMealData();
    },
    handleScroll: function () {
      this.currentScroll = window.pageYOffset;
      this.innerHeight = window.innerHeight;
      if (this.list_top < 0) {
        this.list_top =
          window.pageYOffset +
          this.$refs.list_title.getBoundingClientRect().top;
      }
      console.log(this.list_top,this.currentScroll)
      if (
        this.currentScroll - this.list_top >= 80 ||
        this.list_top - this.currentScroll <= 80
      ) {
        this.is_fixed = true;
        this.top = this.currentScroll - this.list_top + 80 + "px";
      } else {
        this.top = 0;
        this.is_fixed = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.suitList {
  margin-top: 140px;
  .banner {
    // height: 556px;
    width: 100%;
  }
  .lrBOx {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .lrBOx_right {
      .desc {
        height: 398px;
        margin-top: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .sifting-list {
        min-width: 992px;
        padding-top: 45px;
        padding-bottom: 30px;
        border-top: 1px solid transparent;
        position: relative;
        background: #fff;
        .list-title {
          display: flex;
          justify-content: space-between;
          .title-name {
            font-size: 18px;
            color: #222;
            .t30 {
              font-size: 30px;
              color: #000011;
            }
            span.time {
              color: red;
              margin-left: 17px;
              > span {
                color: #fff;
                display: inline-block;
                width: 30px;
                height: 30px;
                background-color: red;
                text-align: center;
                line-height: 30px;
                border-radius: 50%;
              }
            }
          }
          .conditional-sort {
            span {
              font-size: 14px;
              margin-right: 26px;
            }
          }
        }
        .list-content {
          display: flex;
          flex-wrap: wrap;
          margin-top: 31px;
          .goods-card {
            width: 23%;
            // min-width: 360px;
            max-width: 410px;
            text-align: center;
            margin: 0 2% 36px 0;
            cursor: pointer;

            .img {
              background-color: #fff;
              min-height: 332px;
              padding: 10px;
              text-align: center;
              img {
                width: 100%;
                height: 100%;
              }
              i {
                float: right;
                font-size: 28px;
                margin-right: -20px;
                color: #e70010;
              }
            }
            .goods-name {
              margin-top: 18px;
              margin-bottom: 12px;
              font-size: 18px;
              color: #222222;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            .goods-ptitle {
              // margin-bottom: 5px;
            }
            .goods-price {
              font-size: 14px;
              color: #111111;
              span.price {
                font-size: 14px;
              }
              span.sale-price {
                text-decoration: line-through;
                color: #999;
                margin-left: 4px;
              }
            }
            .remain {
              margin-top: 10px;
              .text {
                float: left;
                margin-left: 20%;
                color: #999999;
                font-size: 14px;
              }
              .progress {
                float: left;
                width: 150px;
                margin-top: 8px;
                margin-left: 10px;
                border-radius: 2px;
              }
            }
          }
        }
        .more-goods {
          text-align: center;
          border: 1px solid #f00;
          width: 153px;
          height: 45px;
          line-height: 45px;
          margin: 10px auto;
          color: red;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
