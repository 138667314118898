<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="2"></navbar>
        <div class="mainbox">
          <div class="title">我的收藏</div>
          <div v-if="!listdata.length" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">暂无任何收藏，去逛逛吧</div>
          </div>
          <div v-else class="showbox">
            <div
              class="shopsone"
              v-for="(item, index) in listdata"
              :key="index"
              @click.stop="todetail(item.goods_id)"
            >
              <div
                class="icon tardiness"
                @click.stop="delselect(item.goods_id)"
              >
                &#xe61c;
              </div>
              <img :src="item.image" :alt="item.subtitle" class="shopimg" />
              <div class="name">{{ item.subtitle }}</div>
              <div class="price">￥{{ item.price }}</div>
              <!-- <div class="addcar">加入购物车</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessselect.png"),
      listdata: [],
    };
  },
  mounted() {
    this.getselects();
  },
  methods: {
    getselects() {
      this.$myAjax("user/follow_list", "get", {}, (res) => {
        console.log(res);
        this.listdata = res.list;
      });
    },
    delselect(_id) {
      this.$myAjax(
        "user/cancel_follow",
        "post",
        {
          goods_id: _id,
        },
        () => {
          this.$message({
            message: "已取消收藏",
            type: "success",
            offset: 170,
          });
          this.getselects();
        }
      );
    },
    todetail(_id) {
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}`),
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      flex: 1;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 22px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .showbox {
        padding: 0 12px;
        display: inline-block;
        float: left;
        width: 100%;
        .shopsone {
          width: 234px;
          margin: 27px 0 0 20px;
          float: left;
          position: relative;
          cursor: pointer;
          .icon {
            font-size: 15px;
            position: absolute;
            top: 15px;
            right: 15px;
          }
          .shopimg {
            width: 188px;
            height: 188px;
            margin: 23px 0 0 23px;
            float: left;
          }
          .name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            margin-top: 50px;
            color: #111111;
            font-size: 20px;
            float: left;
            width: 100%;
          }
          .price {
            margin-top: 27px;
            text-align: center;
            color: #111111;
            font-size: 18px;
            font-weight: bold;
            float: left;
            width: 100%;
          }
          .addcar {
            margin-top: 25px;
            width: 148px;
            height: 48px;
            background: #e90516;
            text-align: center;
            line-height: 48px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            float: left;
            margin-left: 43px;
          }
        }
      }
    }
  }
}
</style>
