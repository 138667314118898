<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <div class="leftcontent">
          <div class="status">
            <p class="title">评价宝贝</p>
          </div>
          <div class="servertype">1.评价等级</div>
          <div class="ratebox">
            <el-rate v-model="star" :colors="colors"></el-rate>
          </div>
          <div class="rebacktitle">2.整体评价</div>
          <el-input
            v-model="content"
            placeholder="满足您的期待吗？说说它的不足的地方吧~"
            class="elminput"
            clearable
            type="textarea"
            maxlength="200"
            resize="none"
            rows="6"
          ></el-input>
          <div class="rebacktitle">3.评价晒图</div>
          <p class="rebackidtitle">
            上传图片<span>（期待您对商品细节的展示）</span>
          </p>
          <div class="imgbox">
            <img
              v-for="(item, key) in images"
              :key="key"
              :src="item.imageUrl"
              alt=""
            />
            <el-upload
              v-show="images.length < 6"
              class="upimg"
              :action="upload.url"
              accept=".jpeg,.jpg,.png"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="handlefail"
            >
              <div class="icon tardiness">&#xe615;</div>
              <p>上传凭证</p>
              <p>(最多6张)</p>
            </el-upload>
          </div>
          <!-- <p class="beizhu">备注说明（选填）：希望能够尽快处理</p> -->
          <div class="submit" @click="submit">提交</div>
          <div class="cancel" @click="backto">取消</div>
        </div>
        <div class="rightcontent">
          <div class="refundinfo" v-if="sales_data">
            <div class="refundtitle">正在评价商品</div>
            <div class="shops">
              <div
                class="shopdetail"
                v-for="(item, key) in sales_data"
                :key="key"
              >
                <img :src="item.goods_image" alt="" />
                <div class="detailbox">
                  <p class="name">{{ item.goods_title }}</p>
                  <p class="ids" v-if="item.goods_sn.length > 0">
                    商品编号：{{ item.goods_sn }}
                  </p>
                  <p v-if="item.spec_value.length > 0">
                    规格：{{ item.spec_value }}
                  </p>
                  <p>数量：{{ item.goods_num }}</p>
                </div>
                <div class="price">￥{{ item.goods_price }}</div>
              </div>
            </div>
          </div>
          <div class="help">
            <div class="checks">
              <div v-if="order_program.delivery_info">
                <div class="checks_check">
                  <div class="checktops" @click="distate = !distate">
                    <div class="desc">
                      {{ order_program.delivery_info.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ distate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="distate"
                    v-html="order_program.delivery_info.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.pay_way">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pwstate = !pwstate">
                    <div class="desc">{{ order_program.pay_way.title }}</div>
                    <div class="icon tardiness icont">
                      {{ pwstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pwstate"
                    v-html="order_program.pay_way.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.electronic_invoice">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="restate = !restate">
                    <div class="desc">
                      {{ order_program.electronic_invoice.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ restate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="restate"
                    v-html="order_program.electronic_invoice.content"
                  ></div>
                </div>
              </div>
              <div v-if="order_program.common_problem">
                <div class="s"></div>
                <div class="checks_check">
                  <div class="checktops" @click="pgstate = !pgstate">
                    <div class="desc">
                      {{ order_program.common_problem.title }}
                    </div>
                    <div class="icon tardiness icont">
                      {{ pgstate ? "&#xe711;" : "&#xe60a;" }}
                    </div>
                  </div>
                  <div
                    class="contents"
                    v-if="pgstate"
                    v-html="order_program.common_problem.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      goods_id: 0,
      order_id: 0,
      sku_unique:'',
      content: "",
      shipimg: require("/src/assets/img/ship.png"),
      star: 0,
      colors: ["#e50111", "#e50111", "#e50111"],
      pgstate: false,
      distate: false,
      pwstate: false,
      restate: false,
      order_program: {},
      sales_data: {},
      images: [],
      upload:{
        url:process.env.VUE_APP_BASE_API + 'common/upload'
      }
    };
  },
  mounted() {
    this.order_id = this.$route.query.order_id;
    this.goods_id = this.$route.query.goods_id;
    this.sku_unique = this.$route.query.sku_unique;
    this.getData();
    this.get_order_program();
  },
  methods: {
    getData() {
      let { order_id, goods_id } = { ...this };
      this.$myAjax(
        "order/order_goods_list",
        "get",
        { order_id, goods_id_id:goods_id },
        (res) => {
          this.sales_data = res;
        }
      );
    },
    backto() {
      this.$router.go(-1);
    },
    get_order_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.order_program = res;
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.images.push({
        imageUrl: URL.createObjectURL(file.raw),
        upimgUrl: file.response.data.url,
      });
    },
    handlefail() {
      this.$message.error("图片上传失败！请稍后重试！");
    },
    submit() {
      let { order_id, goods_id, images, content, star,sku_unique } = { ...this };
      if (star === 0) {
        this.$message({
          message: "请选择等级",
          type: "error",
          offset:170
        });
        return;
      }
      images = images.map((value) => {
        return value.upimgUrl;
      });
      images = images.join(",");
      this.$myAjax(
        "order/submit_order_comment",
        "post",
        {
          order_id,
          goods_id,
          sku_unique,
          images,
          content,
          star,
        },
        (res) => {
          console.log(res);
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1000,
            offset:170,
            onClose: () => {
              this.$router.replace({
                name: "MineComets",
              });
            },
          });
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  min-height: 1157px;
  .fromtop {
    margin-top: 50px;
    .leftcontent {
      width: 900px;
      float: left;
      .status {
        .title {
          font-size: 30px;
          color: #000000;
          font-weight: bold;
        }
        .status_canbe {
          margin-top: 32px;
          font-size: 16px;
          font-weight: bold;
        }
        .tips {
          margin-top: 17px;
          font-size: 14px;
          color: #333333;
        }
      }
      .servertype {
        padding: 23px 0;
        border-bottom: 1px solid #e6e6e6;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
      }
      .ratebox {
        padding: 23px 0;
      }
      .rebacktitle {
        margin-top: 30px;
        padding: 24px 0;
        border-bottom: 1px solid #e6e6e6;
        font-size: 16px;
        color: #000000;
      }
      .rebackidtitle {
        font-size: 14px;
        color: #000000;
        margin-top: 32px;
        span {
          color: #e70012;
        }
      }
      .elminput {
        width: 100%;
        margin-top: 18px;
      }
      .imgbox {
        height: 155px;
        margin-top: 31px;
        img {
          width: 155px;
          height: 155px;
          float: left;
          margin-left: 20px;
          cursor: pointer;
        }
        .upimg {
          width: 155px;
          height: 155px;
          float: left;
          margin-left: 20px;
          // line-height: 155px;
          text-align: center;
          cursor: pointer;
          border: 1px solid #dbdbdb;
          .icon {
            font-size: 31px;
            color: #666;
            margin-top: 34px;
            margin-bottom: 19px;
          }
          p {
            font-size: 14px;
            color: #333333;
          }
        }
        :first-child {
          margin-left: 0;
        }
      }
      .beizhu {
        padding: 35px 0;
        font-size: 14px;
        color: #000000;
        margin-top: 55px;
        border-top: 1px solid #e6e6e6;
      }
      .submit {
        width: 168px;
        height: 48px;
        background: #e70010;
        float: right;
        margin-top: 117px;
        text-align: center;
        line-height: 48px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
      .cancel {
        width: 168px;
        height: 48px;
        border: 1px solid #999999;
        float: right;
        margin-top: 117px;
        text-align: center;
        line-height: 48px;
        color: #999999;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        margin-right: 17px;
      }
    }
    .rightcontent {
      width: 480px;
      float: right;
      .refundinfo {
        width: 480px;
        height: 220px;
        background: #fff;
        .refundtitle {
          height: 64px;
          text-align: center;
          line-height: 64px;
          border-bottom: 1px solid #e6e6e6;
          font-size: 20px;
          color: #000000;
        }
        .shops {
          width: 100%;
          padding: 12px 16px 27px 15px;
          .ifoutsides {
            display: inline-block;
            width: 100%;
            margin-bottom: 21px;
            img {
              width: 29px;
              height: 25px;
              float: left;
            }
            .oustnumber {
              margin-left: 12px;
              font-size: 16px;
              color: #111111;
              float: left;
            }
            .outsaddress {
              font-size: 16px;
              color: #111111;
              float: right;
            }
          }
          .shopdetail {
            display: inline-block;
            width: 100%;
            img {
              width: 110px;
              height: 110px;
              float: left;
            }
            .detailbox {
              margin-left: 17px;
              float: left;
              p {
                font-size: 14px;
                color: #111111;
              }
              .name {
                font-size: 18px;
                color: #111111;
              }
              .ids {
                margin-top: 10px;
              }
              .metions {
                margin-top: 16px;
                float: left;
                display: inline-block;
                .icon {
                  font-size: 16px;
                  color: #999999;
                  float: left;
                }
                span {
                  margin-left: 8px;
                  color: #999999;
                  float: left;
                  font-size: 14px;
                }
              }
            }
            .price {
              float: right;
              line-height: 110px;
              font-size: 14px;
              color: #111111;
            }
          }
        }
      }
    }
    .help {
      background-color: #fff;
      margin-top: 12px;
      padding: 0 30px;
      .checks {
        padding: 1px 0;
        .s {
          width: 100%;
          border-top: 1px solid #ccc;
        }
        .checks_check {
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          .checktops {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            flex-direction: row;
            .lefts {
              display: flex;
              align-items: center;
              .icon {
                font-size: 22px;
              }
              .desc {
                margin-left: 14px;
                color: #000000;
                line-height: 24px;
              }
            }
            .icont {
              font-size: 14px;
            }
          }
          .contents {
            color: #7c7c7c;
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
<style>
.el-rate__icon {
  font-size: 24px !important;
}
</style>
