<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="6"></navbar>
        <div class="mainbox">
          <div class="title">会员中心</div>
          <div class="nowvip">
            <img :src="nowData.image" alt="" />
            <div class="nowcontent">
              <p class="level">尊享{{ nowData.name }}卡</p>
              <p class="status">成功注册</p>
              <p v-html="des"></p>
            </div>
          </div>
          <div class="introduce">会员权益介绍</div>
          <div class="allvipbox">
            <div
              class="onevip"
              v-for="(item, index) in gradeList"
              :key="index"
              @click="openit(index)"
            >
              <img :src="item.image" alt="" />
              <div class="onecontent">
                <span>{{ item.name }}</span>
                <div class="icon tardiness">
                  {{ index == tagindex ? "&#xe711;" : "&#xe615;" }}
                </div>
              </div>
            </div>
          </div>
          <div class="description">权益说明：{{ gain }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="mask" v-if="windowshow">
      <div class="windows">
        <div class="widowtitle">
          会员升级说明
          <div class="icon tardiness" @click="windowshow = false">&#xe61c;</div>
        </div>
        <div class="contents">
          <p>① 会员等级总共分为4个等级，分别为铜牌、银牌、金牌、钻石会员。</p>
          <p>
            ② 用户注册成功后即可成为铜牌会员，享受会员特权；
            当年购买订单金额累计达到2000元人民币（暂定，约四双鞋的金额，门槛设定较低）或单笔金额超过1000元人民币可升级至银牌会员；
            当年购买订单金额累计达到5000元人民币（约四双鞋加四件服装金额）或单笔金额超过3000元可升级至金牌会员；
            当年购买订单金额累计达到10000元人民币或单笔超过5000元可升级至钻石会员。
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      // windowshow: false,
      tagindex: 0,
      nowData: {},
      gradeList: [],
      des: "",
      gain: "",
    };
  },
  mounted() {
    this.getVip();
  },
  methods: {
    getVip() {
      this.$myAjax("user/grade", "get", {}, (res) => {
        this.nowData = res.current_grade;
        this.gradeList = res.grade_list;
        this.des = res.grade_desc
          .replace(/\n/g, "<br/>")
          .replace(/ /g, "&nbsp;");
        this.gain = this.gradeList[this.tagindex].gain;
      });
    },
    openit(index) {
      this.tagindex = index;
      this.gain = this.gradeList[index].gain;
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      flex: 1;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nowvip {
        padding: 30px 34px 0 34px;
        // float: left;
        // display: inline-block;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 35px;
        img {
          width: 160px;
          // height: 171px;
          float: left;
        }
        .nowcontent {
          float: left;
          display: inline-block;
          margin-left: 35px;
          max-width: 588px;
          p {
            font-size: 14px;
            color: #333333;
          }
          .level {
            font-size: 18px;
            color: #000000;
            margin-top: 20px;
          }
          .status {
            margin: 15px 0 16px 0;
            font-size: 14px;
            color: #666666;
          }
        }
      }
      .introduce {
        padding: 27px 34px;
        border-bottom: 1px solid #e6e6e6;
        float: left;
        font-size: 20px;
        color: #000000;
        font-weight: bold;
        margin-top: 66px;
        width: 100%;
      }
      .allvipbox {
        // float: left;
        // display: inline-block;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 34px 0 14px;
        width: 100%;
        .onevip {
          width: 183px;
          float: left;
          display: inline-block;
          margin-left: 22px;
          margin-top: 18px;
          cursor: pointer;
          img {
            width: 160px;
            float: left;
            margin-left: 11.5px;
          }
          .onecontent {
            padding: 19px 0;
            margin-top: 16px;
            float: left;
            border-bottom: 1px solid #e6e6e6;
            width: 100%;
            span {
              color: #030303;
              font-size: 14px;
              float: left;
            }
            .icon {
              color: #999999;
              float: right;
            }
          }
        }
      }
      .description {
        padding: 15px 34px;
        float: left;
        color: #666666;
        font-size: 13px;
        width: 100%;
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
   z-index: 2000;
  .windows {
    width: 603px;
    height: 510px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -265px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #000000;
      font-size: 16px;
      .icon {
        position: absolute;
        color: #666666;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .contents {
      padding: 0 42px;
      margin-top: 19px;
      width: 100%;
      p {
        margin-top: 16px;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
</style>
