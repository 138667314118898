<template>
  <div class="flashsale mwframe">
    <!-- banner -->
    <div class="banner" v-if="banner">
      <img :src="banner" />
    </div>
    <!-- 限时抢购组件 -->
    <div class="component mw">
      <siftingList
        :shopsData="shopsData"
        :total_page="total_page"
        :total_size="total_count"
        :page="page"
        :shiwtitle="shiwtitle"
        :show_list="false"
        :active_type="4"
        @update="datachange"
        @hideleft="hideleft"
        :show_follow="false"
        @handlesku="handlesku"
        :tips="tips"
        :show_stock.sync="in_stock"
      >
        <template v-slot:title_name>
          <span>{{ active_tip }}</span>
          <span class="time" v-html="time(pre_at)"> </span>
        </template>
        <template v-slot:goods_price="slotProps">
          <div class="goods-price">
            <span class="price">￥{{ slotProps.goods.seckill_price }}</span>
            <span class="sale-price">￥{{ slotProps.goods.cost_price }}</span>
          </div>
        </template>
        <!-- <template v-slot:remain="slotProps">
          <div class="text">还剩{{ slotProps.goods.stock }}件</div>
          <div class="progress">
            <el-progress
              :percentage="
                parseInt((slotProps.goods.sales / slotProps.goods.stock) * 100)
              "
              :stroke-width="6"
              color="red"
              :show-text="false"
            ></el-progress>
          </div>
        </template> -->
      </siftingList>
    </div>
  </div>
</template>
<script>
import siftingList from "../../components/Sifting/sifting_list";
const drag = 0;
export default {
  props: [],
  data() {
    return {
      screenData: [], //筛选数据
      classifyData: [], //分类数据
      brandData: [], //品牌数据
      labelData: [], //、其他数据
      shopsData: [], //商品数据
      page: 1, //加载页数
      total_page: 1, //总页数
      total_count: 0,

      recid: "",
      classify_ids: "",
      sort_field: "",
      sort_order: "",
      shiwtitle: "",
      minPrice: "",
      maxPrice: "",
      labelid: "",
      brandid: "",
      issend: null,
      searchvalue: "",
      userid: "",
      is_show_left: true,
      ticker: null,
      pre_at: "",
      active_tip: "",
      banner: "",
      tips: "加载中",
      in_stock: false,
      seckill_time: {},
    };
  },
  mounted() {
    // this.getDatabase();
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
    }
    this.get_seckill_time();
    this.getshopsData();
  },
  components: {
    siftingList,
  },
  methods: {
    scrollToTop() {
      const gap = document.documentElement.scrollTop || document.body.scrollTop;
      if (gap > location) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, gap - gap / drag);
      }
    },
    getDatabase() {
      let newclassify = this.classify_ids;
      if (this.issend) {
        newclassify = "";
      }
      this.$myAjax(
        "goods/goods_attr?classify_id=" +
          newclassify +
          "&brand_id=" +
          this.brandid,
        "get",
        {},
        (res) => {
          res.screen.map((item) => {
            item.open = false;
          });
          res.screen.map((item) => {
            item.next.map((btem) => {
              btem.ison = false;
            });
          });
          this.screenData = res.screen;
          this.classifyData = res.classify;
          res.brand.map((item) => {
            if (this.brandid == item.id) {
              item.ison = true;
            } else {
              item.ison = false;
            }
          });
          this.brandData = res.brand;
          res.label.map((item) => {
            item.ison = false;
          });
          this.labelData = res.label;
          // this.getshopsData();
        }
      );
    },
    getshopsData() {
      this.tips = "加载中";
      this.$myAjax(
        "goods/seckill_goods_list?page=" +
          this.page +
          "&recd_id=" +
          this.recid +
          "&classify_id=" +
          this.classify_ids +
          "&sort_field=" +
          this.sort_field +
          "&sort_order=" +
          this.sort_order +
          "&brand_id=" +
          this.brandid +
          "&min_price=" +
          (this.minPrice != "" ? this.minPrice : 0) +
          "&max_price=" +
          (this.maxPrice != "" ? this.maxPrice : 1000000000) +
          "&label_id=" +
          this.labelid +
          "&keywords=" +
          this.searchvalue +
          "&identity_id=" +
          this.userid,
        "get",
        {},
        (res) => {
          res.list.map((item) => {
            item.sku_img_index = -1;
            return item;
          });
          this.shopsData = res.list;
          // this.shopsData = this.shopsData.concat(res.list);
          this.total_page = res.total_page;
          this.total_count = res.total_count;
          this.scrollToTop();
          this.tips = "";
        }
      );
    },
    loadmore() {
      this.page++;
      this.getshopsData();
    },
    datachange(e) {
      this.sort_field = e.split(",")[0];
      this.sort_order = e.split(",")[1];
      this.shopsData = [];
      this.getshopsData();
    },
    hideleft() {
      this.is_show_left = !this.is_show_left;
    },
    minprice(e) {
      this.minPrice = e;
    },
    maxprice(e) {
      this.maxPrice = e;
    },
    search() {
      this.getshopsData();
    },
    brandChoose(e) {
      this.brandData[e].ison = !this.brandData[e].ison;
      this.shaix();
    },
    otherChoose(e) {
      this.labelData[e].ison = !this.labelData[e].ison;
      this.shaix();
    },
    cateChoose(arg) {
      console.log(arg[0]);
      this.classify_ids = arg[0];
      this.shiwtitle = arg[1];
      this.getshopsData();
    },
    shaix() {
      let brandid = [],
        labelid = [];
      this.brandData.map((item) => {
        if (item.ison) {
          brandid.push(item.id);
        }
      });
      this.brandid = brandid.toString();
      this.labelData.map((item) => {
        if (item.ison) {
          labelid.push(item.id);
        }
      });
      this.labelid = labelid.toString();
      this.shopsData = [];
      this.getshopsData();
    },
    get_seckill_time() {
      this.$myAjax(
        "goods/seckill_time",
        "get",
        { identity_id: this.userid },
        (res) => {
          this.seckill_time = res.seckill_time;
          if (res.seckill_banner.length > 0) {
            this.banner = res.seckill_banner[0].image;
          }
          let nowtime = new Date().getTime();
          if (nowtime < res.seckill_time.start_time_int * 1000) {
            this.active_tip = "距离活动开始还剩";
            this.pre_at =
              res.seckill_time.start_time_int * 1000 - new Date().getTime();
            this.ticker = setInterval(() => {
              this.pre_at = this.pre_at - 1000;
            }, 1000);
          } else if (nowtime < res.seckill_time.end_time_int * 1000) {
            this.active_tip = "距离活动结束还剩";
            this.pre_at =
              res.seckill_time.end_time_int * 1000 - new Date().getTime();
            this.ticker = setInterval(() => {
              this.pre_at = this.pre_at - 1000;
            }, 1000);
          } else {
            this.active_tip = "活动已结束";
          }
        }
      );
    },
    time(time) {
      if (time >= 0) {
        let h =
          Math.floor((time / 1000 / 60 / 60) % 24) < 10
            ? "0" + Math.floor((time / 1000 / 60 / 60) % 24)
            : Math.floor((time / 1000 / 60 / 60) % 24);
        let m =
          Math.floor((time / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((time / 1000 / 60) % 60)
            : Math.floor((time / 1000 / 60) % 60);
        let s =
          Math.floor((time / 1000) % 60) < 10
            ? "0" + Math.floor((time / 1000) % 60)
            : Math.floor((time / 1000) % 60);
        return `<span class="hours">${h}</span>：<span class="minutes">${m}</span
            >：<span class="seconds">${s}</span>`;
      } else {
        let nowtime = new Date().getTime();
        if (nowtime < this.seckill_time.start_time_int * 1000) {
          this.active_tip = "距离活动开始还剩";
          this.pre_at =
            this.seckill_time.start_time_int * 1000 - new Date().getTime();
        } else if (nowtime < this.seckill_time.end_time_int * 1000) {
          this.active_tip = "距离活动结束还剩";
          this.pre_at =
            this.seckill_time.end_time_int * 1000 - new Date().getTime();
        } else {
          clearInterval(this.ticker);
          this.active_tip = "活动已结束";
          return ``;
        }
      }
    },
    handlesku({ i1, i2 }) {
      this.shopsData[i1].sku_img_index = i2;
      this.shopsData[i1].image = this.shopsData[i1].sku_image[i2];
    },
  },
};
</script>
<style lang="less" scoped>
.flashsale {
  margin-top: 140px;
  background: #fafafa;
  // .banner {
  //   // height: 600px;
  //   // background: url("../../assets/img/index/1.png") no-repeat;
  //   // background-size: 100% 100%;
  // }
  .component {
    display: flex;
    justify-content: space-between;
  }
}
</style>
