<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="7"></navbar>
        <div class="mainbox">
          <div class="title">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="first"></el-tab-pane>
              <!-- <el-tab-pane label="退款中" name="second"></el-tab-pane> -->
              <el-tab-pane label="审核中" name="second"></el-tab-pane>
              <el-tab-pane label="审核成功" name="third"></el-tab-pane>
              <el-tab-pane label="审核失败" name="fourth"></el-tab-pane>
              <el-tab-pane label="退款成功" name="fifth"></el-tab-pane>
              <el-tab-pane label="退款失败" name="sixth"></el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="!listdata.length" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">您没有售后单!</div>
          </div>
          <div v-else class="scorebox">
            <div class="showbox">
              <div
                class="onerecord"
                v-for="(item, index) in listdata"
                :key="index"
              >
                <div class="comet">
                  {{
                    item.status == 1
                      ? "审核中"
                      : item.status == 2
                      ? "已通过 "
                      : item.status == 3
                      ? "已拒绝"
                      : item.status == 4
                      ? "已取消"
                      : item.status == 5
                      ? "回寄中 "
                      : item.status == 6
                      ? "已回寄"
                      : item.status == 7
                      ? "已退款"
                      : ""
                  }}
                  <span>(共{{ item.goods_num }}件商品)</span>
                </div>
                <div class="time">
                  <span>退款单号：{{ item.refund_sn }}</span>
                  <div class="price">￥{{ item.refund_price }}</div>
                </div>
                <div class="shops">
                  <div class="shopdetail">
                    <img :src="item.goods_image" alt="" />
                    <div class="detailbox">
                      <p class="name">{{ item.goods_title }}</p>
                      <p class="ids" v-if="item.goods_sn.length > 0">
                        商品编号：{{ item.goods_sn }}
                      </p>
                      <p v-if="item.spec_value.length > 0">
                        规格：{{ item.spec_value }}
                      </p>
                      <p>数量：{{ item.goods_num }}</p>
                    </div>
                    <!-- <router-link
                      :to="{ name: 'SalesOrderDetail',query: {order_id: item. order_id} }"
                      class="looksdetail"
                      >订单详情</router-link
                    > -->
                    <router-link
                      :to="{
                        name: 'SalesOrderDetail',
                        query: { refund_id: item.refund_id },
                      }"
                      class="looksdetail"
                      >售后详情</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="listdata.length">
              <div
                class="more-goods"
                v-if="page < total_page"
                @click="loadmore"
              >
                发现更多
              </div>
              <div class="nomore" v-else>没有更多了</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessorder.png"),
      listdata: [],
      activeName: "first",
      total_page: 1,
      page: 1,
      refund_status: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleClick() {
      if (this.activeName == "first") {
        this.refund_status = "";
      } else if (this.activeName == "second") {
        this.refund_status = 1;
      } else if (this.activeName == "third") {
        this.refund_status = 2;
      } else if (this.activeName == "fourth") {
        this.refund_status = 3;
      } else if (this.activeName == "fifth") {
        this.refund_status = 7;
      } else if (this.activeName == "sixth") {
        this.refund_status = 8;
      }
      this.listdata = []
      this.page = 1
      this.total_page = 1
      this.getList();
    },
    getList() {
      let { page, refund_status } = { ...this };
      this.$myAjax(
        "order/refund_list",
        "get",
        { page, refund_status },
        (res) => {
          this.listdata = res.list;
          this.total_page = res.total_page;
        }
      );
    },
    loadmore() {
      this.page++;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      flex: 1;
      float: left;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 20px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .scorebox {
        width: 100%;
        display: inline-block;
        .showbox {
          margin: 0 40px;
          overflow: hidden;
          .onerecord {
            padding: 25px 0;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            float: left;
            .comet {
              font-size: 16px;
              color: #000000;
              font-weight: bold;
              span {
                color: #999999;
                font-weight: normal;
                font-size: 14px;
              }
            }
            .time {
              margin-top: 14px;
              display: inline-block;
              width: 100%;
              span {
                font-size: 16px;
                color: #111111;
                float: left;
              }
              .price {
                font-size: 18px;
                color: #111111;
                font-weight: bold;
                float: right;
              }
            }
            .shops {
              width: 100%;
              padding-right: 40px;
              margin-top: 34px;
              .shopdetail {
                display: inline-block;
                width: 100%;
                img {
                  width: 203px;
                  height: 203px;
                  float: left;
                }
                .detailbox {
                  margin-left: 29px;
                  float: left;
                  p {
                    font-size: 16px;
                    color: #111111;
                    margin-top: 19px;
                  }
                  .name {
                    font-size: 20px;
                    color: #111111;
                    margin-top: 0;
                  }
                }
                .looksdetail {
                  float: right;
                  width: 168px;
                  height: 48px;
                  background: #ffffff;
                  border: 1px solid #999999;
                  font-size: 14px;
                  color: #999999;
                  line-height: 48px;
                  text-align: center;
                  font-weight: bold;
                  margin-top: 155px;
                  cursor: pointer;
                }
              }
            }
          }
          .nomore {
            width: 100%;
            padding: 30px 0;
            font-size: 16px;
            color: #666666;
            text-align: center;
            float: left;
          }
        }
        .more-goods {
          text-align: center;
          border: 1px solid #f00;
          width: 153px;
          height: 45px;
          line-height: 45px;
          margin: 30px auto 0 auto;
          color: red;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style scope>
.el-tabs__header {
  margin: 0 !important;
}
.el-tabs__nav-wrap::after {
  display: none !important;
}
.el-tabs__item:hover {
  color: #e7020f !important;
}
.el-tabs__item.is-active {
  color: #e7020f !important;
}
.el-tabs__active-bar {
  background-color: #e7020f !important;
  bottom: -27px !important;
}
.el-tabs__nav-wrap,
.el-tabs__nav-scroll {
  overflow: visible !important;
}
</style>
