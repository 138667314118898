<template>
  <div class="goodsLeft" ref="goodsleft" :class="show_desc ? 'open' : ''">
    <div class="imgs">
      <div class="imgs_left">
        <div class="swiper-container swiper-container4">
          <div class="lis swiper-wrapper">
            <li
              class="swiper-slide"
              v-if="goods.video"
              @click="bannerChange(-1)"
            ></li>
            <li
              class="swiper-slide"
              :class="bannerIndex === index ? 'li_on' : ''"
              v-for="(item, index) in goods.images"
              :key="index"
              @click="bannerChange(index)"
            >
              <span id="showimage">
                <img :src="item" alt="" />
              </span>
            </li>
          </div>
        </div>
        <div class="swiper-button-next4" @click="next4()">
          <i class="el-icon-arrow-down"></i>
        </div>
        <div class="swiper-button-prev4" @click="prev4()">
          <i class="el-icon-arrow-up"></i>
        </div>
      </div>
      <div class="imgs_right1">
        <div class="imgs_right">
          <div class="imgs_right_box" ref="imgs_right_box">
            <!-- <img
              v-if="bannerIndex != -1"
              :src="goods.image"
              :alt="goods.subtitle"
            /> -->
            <div v-if="bannerIndex != -1">
              <vue-photo-zoom-pro
                v-if="goods && goods.image"
                :scale="scale"
                :url="goods.image"
                :out-zoomer="true"
                :mask="false"
                type="circle"
                :zoomer-style="{ 'background-color': 'rgba(0,0,0, 0.6)' }"
                @mouseleave="img_mouseleave"
              >
                <template slot="outzoomer">
                  <div class="ks-imagezoom-wrap"></div>
                </template>
              </vue-photo-zoom-pro>
            </div>
            <video v-else :src="showimg" controls></video>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="s"></div>
    <div class="desc">
      <div class="desc_title"><img :src="xiangqing" />产品详情</div>
      <div v-show="show_desc" class="desc_desc" v-html="goods.details"></div>
    </div>
    <div
      v-if="!show_desc && goods.details"
      class="desc_shrink"
      @click="desc_shrink"
    >
      <i class="el-icon-d-arrow-right"></i>
    </div>
    <div
      v-if="show_desc && goods.details"
      class="desc_open"
      @click="desc_shrink"
    >
      <i class="el-icon-d-arrow-right"></i>
    </div>
  </div>
</template>
<script>
// import VuePhotoZoomPro from "vue-photo-zoom-pro";
import Swiper from "swiper";
import "../../../node_modules/swiper/dist/css/swiper.min.css";
import "../../../node_modules/swiper/dist/js/swiper.min";
const drag = 10;
const location = 200;
export default {
  props: {
    goods: Object,
  },
  components: {
    // VuePhotoZoomPro,
  },
  data() {
    return {
      xiangqing: require("/src/assets/img/xiaoxiong.png"),
      bannerIndex: "",
      swiper4: null,
      show_desc: false,
      scale: 2,
    };
  },
  watch: {
    goods: function (val) {
      console.log(val);
      console.log(1111111111);
      this.$nextTick(() => {
        this.swiper4 = new Swiper(".swiper-container4", {
          direction: "vertical",
          slidesPerView: 5,
          spaceBetween: 5,
          navigation: {
            nextEl: ".swiper-button-next4",
            prevEl: ".swiper-button-prev4",
          },
        });
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      let imgWidth = this.$refs.imgs_right_box.offsetWidth;
      this.scale = parseFloat((imgWidth / 168).toFixed(2));
    });
    // 窗口大小发生改变时,调用一次
    // window.onresize = () => {
    //   this.imgWidth = this.$refs.imgs_right_box.$el.offsetWidth;
    //   this.scale = (this.imgWidth/168).toFixed(2)
    // };
  },
  methods: {
    likechange() {
      this.$parent.likechange();
    },
    bannerChange(_index) {
      if (_index != -1) {
        this.bannerIndex = _index;
        this.goods.image = this.goods.images[_index];
      } else {
        this.goods.image = this.goods.video;
      }
    },
    prev4() {
      this.swiper4.slidePrev();
    },
    next4() {
      console.log(11111);
      this.swiper4.slideNext();
    },
    desc_shrink() {
      this.show_desc = !this.show_desc;
      if (!this.show_desc) {
        this.scrollToTop();
      }
    },
    scrollToTop() {
      const gap = document.documentElement.scrollTop || document.body.scrollTop;
      if (gap > location) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, gap - gap / drag);
      }
    },
    img_mouseleave() {
      console.log("img_mouseleave");
    },
  },
};
</script>
<style lang="less">
// .img-out-show{
//   right: -20px !important;
// }
.desc_shrink {
  .el-icon-d-arrow-right {
    color: #e6001282;
    font-size: 30px;
    animation: move 1s linear infinite alternate;
    cursor: pointer;
    @keyframes move {
      0% {
        transform: rotate(90deg);
      }
      to {
        transform: translate(0, 10px) rotate(90deg);
      }
    }
  }
}
.desc_open {
  .el-icon-d-arrow-right {
    color: #e6001282;
    font-size: 30px;
    animation: movetop 1s linear infinite alternate;
    cursor: pointer;
    @keyframes movetop {
      0% {
        transform: rotate(-90deg);
      }
      to {
        transform: translate(0, 10px) rotate(-90deg);
      }
    }
  }
}
</style>
<style lang="less" scoped>
.s {
  width: 100%;
  border-top: 1px solid #cccccc52;
}
.el-icon-arrow-up,
.el-icon-arrow-down {
  font-size: 30px;
  color: #e60012;
}
.imgs_left {
  .swiper-button-prev4 {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    background: #fff;
    line-height: 44px;
    position: absolute;
  }

  .swiper-button-next4 {
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    line-height: 44px;
    width: 100%;
    background: #fff;
    position: absolute;
  }
  .swiper-button-disabled {
    display: none;
  }
}
.goodsLeft {
  width: 67.6%;
  background: #fff;
  max-height: 900px;
  overflow: visible;
  position: relative;
  transition: all 1s linear;
  padding: 0 10px;
  .desc {
    max-height: 72px;
    overflow: hidden;
  }
  &.open {
    max-height: 100000px;
    .desc {
      max-height: 100000px;
    }
  }
  .imgs {
    display: flex;
    margin-bottom: 20px;
    .imgs_left {
      width: 110px;
      height: 630px;
      padding: 50px 0;
      position: relative;
      // overflow-y: auto;
      // margin: 0 10px;
      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .swiper-container4 {
        height: 100%;
      }
      .lis {
        height: 100%;
        li {
          height: 110px;
          // padding: 10px;
          background-color: #fff;
          text-align: center;
          margin-bottom: 20px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            opacity: 0.4;
          }
          &:hover {
            border: 1px solid #e70012;
            img {
              opacity: 1;
            }
          }
        }
        .li_on {
          border: 1px solid #e70012;
          img {
            opacity: 1;
          }
        }
      }
    }
    .imgs_right1 {
      // width: 60%;
      flex: 1;
      // margin-left: 140px;
      .imgs_right {
        width: 80%;
        margin: 0 auto;
        // background-color: #fff;
        // padding: 13px;
        position: relative;
        .icon {
          cursor: pointer;
          font-size: 24px;
          color: #666;
          z-index: 9;
          top: 13px;
          right: 13px;
          position: absolute;
        }
        .likeon {
          color: #e60012 !important;
        }
        .imgs_right_box {
          // padding: 25px 19px;
          text-align: center;
          // width: 500px;
          // height: 500px;
          width: 100%;
          height: auto;
          position: relative;
          img {
            width: 100%;
            cursor: pointer;
          }
          video {
            width: 100%;
            cursor: pointer;
          }
          .ks-imagezoom-wrap {
          }
        }
      }
    }
  }
  .desc {
    // padding: 42px 18px;
    margin-top: 20px;
    background-color: #fff;
    text-align: center;
    max-width: 100%;
    overflow-x: hidden;
    .desc_title {
      font-size: 24px;
      color: #222;
      line-height: 72px;
      height: 72px;
      vertical-align: middle;
      img {
        width: 50px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .desc_desc {
      font-size: 14px;
      color: #222;
      margin: 30px 0 20px 0;
      width: 100%;
      padding: 0;
    }
    .btn {
      margin-top: 20px;
      color: red;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    .icon {
      margin-left: 10px;
      font-weight: bold;
    }
  }
}
.desc_shrink,
.desc_open {
  position: absolute;
  bottom: -50px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  padding-right: 20px;
  background: #fff;
  left: 0;
}
</style>
<style lang="less">
.desc_desc {
  img {
    max-width: 100%;
	margin-top: -4px;
  }
}
</style>
