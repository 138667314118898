<template>
  <div>
    <div class="window_page">
      <div v-for="(item, index) in list" :key="index">
        <div
          class="row"
          :class="titleid == item.id ? 'rowon' : ''"
          @click="typechange(index)"
        >
          <!-- <div class="icon tardiness">&#xe75e;</div> -->
          <div class="icontext">{{ item.title }}</div>
          <div class="sign"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: Array,
    titleid: Number,
  },
  data() {
    return {
      typechange(index) {
        this.$emit("tabchange", index);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.window_page {
  width: 307px;
  background: #fff;
  float: left;
  .row {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    height: 49px;
    line-height: 49px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      .icon {
        color: #e80111;
      }
      .icontext {
        color: #e80111;
      }
    }
    .icon {
      margin-left: 31px;
      float: left;
    }
    .icontext {
      color: #333333;
      font-size: 14px;
      margin-left: 13px;
      float: left;
    }
  }
  .rowon {
    & {
      .icon {
        color: #e80111;
      }
      .icontext {
        color: #e80111;
      }
      .sign {
        display: block;
        position: absolute;
        left: 0;
        width: 2px;
        height: 100%;
        background: #e90010;
      }
    }
  }
  .bordernone {
    border: none;
  }
}
</style>
