<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="3"></navbar>
        <div class="mainbox">
          <div class="title">我的足迹</div>
          <div v-if="Object.keys(listdata).length === 0" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">暂无任何足迹，去逛逛吧</div>
          </div>
          <div v-else class="showbox">
            <div class="oneday" v-for="(item, index) in listdata" :key="index">
              <div class="daytime">
                {{ index }} <span>{{ item.length }}件宝贝</span>
              </div>
              <div
                class="shopsone"
                v-for="(v, i) in item"
                :key="i"
                @click.stop="todetail(v.goods_id)"
              >
                <div class="icon tardiness" @click.stop="delfoots(v.foot_id)">
                  &#xe61c;
                </div>
                <img :src="v.image" :alt="v.subtitle" class="shopimg" />
                <div class="name">{{ v.subtitle }}</div>
                <div class="price">￥{{ v.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessfoot.png"),
      listdata: [],
    };
  },
  mounted() {
    this.getfoots();
  },
  methods: {
    getfoots() {
      this.$myAjax("user/foot_list", "get", {}, (res) => {
        this.listdata = res;
      });
    },
    delfoots(id) {
      this.$myAjax(
        "user/del_foot",
        "post",
        {
          foot_id: id,
        },
        () => {
          this.getfoots();
        }
      );
    },
    todetail(_id) {
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}`),
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      flex: 1;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 22px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .showbox {
        margin: 0 40px;
        display: inline-block;
        float: left;
        .oneday {
          padding-bottom: 28px;
          border-bottom: 1px solid #e6e6e6;
          display: inline-block;
          width: 100%;
          float: left;
          .daytime {
            margin-top: 32px;
            color: #000000;
            font-size: 24px;
            span {
              margin-left: 15px;
              font-size: 14px;
            }
          }
          .shopsone {
            width: 234px;
            padding: 27px 0 0 27px;
            float: left;
            position: relative;
            cursor: pointer;
            width: 25%;
            .icon {
              font-size: 15px;
              position: absolute;
              top: 15px;
              right: 15px;
            }
            .shopimg {
              width: 188px;
              height: 188px;
              margin: 23px 0 0 23px;
              float: left;
            }
            .name {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
              float: left;
              width: 100%;
              margin-top: 18px;
              margin-bottom: 12px;
              font-size: 14px;
              color: #333333;
              font-weight: 400;
            }
            .price {
              // margin-top: 27px;
              text-align: center;
              float: left;
              width: 100%;
              font-size: 16px;
              color: #111111;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
