<template>
  <div>
    <!-- transition: all 0.1s linear;  -->
    <div
      style="overflow: hidden"
      :class="is_show_left ? 'sifting_show' : 'sifting_hide'"
    >
      <div class="sifting">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <slot name="breadcrumb"></slot>
        </div>
        <!--标题或者二级-->
        <slot name="title">
          <div>
            <div v-for="(qtem, qndex) in classifyData" :key="qndex">
              <div :class="qtem.next ? 'title' : 'desc'">
                <p>{{ qtem.title }}</p>
                <!-- 三级内容或者描述 -->
                <div v-if="qtem.next">
                  <div class="desc">
                    <p
                      v-for="(v, i) in qtem.next"
                      :key="i"
                      :class="v.is_selected ? 'noimgname_on' : ''"
                      @click.stop="cateChoose(v.id, v.title, qndex, i)"
                    >
                      {{ v.title }}
                    </p>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </slot>
        <div  class="daojishi"  v-if="isdaojishi">
            {{active.name}}
        </div>
        <div  class="daojishi" v-html="daojishiwen"  v-if="isdaojishi">
        </div>

         <div  v-if="active.active_type!=5&&active.active_type!=6&&active.active_type!=7">
        <div class="Classification">
          <div class="sex" @click="priceopen = !priceopen">
            价格
            <i class="icon fr">{{ priceopen ? "&#xe711;" : "&#xe60a;" }}</i>
          </div>
          <div class="details" v-if="priceopen">
            <div class="details_check">
              <div class="pricebox">
                <el-input
                  v-model="minPrice"
                  placeholder="最低价格"
                  @input="minChange"
                  @blur="search"
                  type="number"
                  @wheel.native.prevent="stopScrollFun($event)"
                ></el-input>
                <span> - </span>
                <el-input
                  v-model="maxPrice"
                  placeholder="最高价格"
                  @input="maxChange"
                  @blur="search"
                  type="number"
                  @wheel.native.prevent="stopScrollFun($event)"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="s"></div>
        <div v-for="(item, index) in screenData" :key="index">
          <div class="Classification">
            <div class="sex" @click="openstate(index)">
              {{ item.title }}
              <i class="icon fr">{{ item.open ? "&#xe711;" : "&#xe60a;" }}</i>
            </div>
            <div class="details" v-if="item.open">
              <div class="details_check" v-if="item.type == 1">
                <div v-for="(v, i) in item.next" :key="i">
                  <div v-if="v.image_show == 1" class="hasimgbox">
                    <img
                      :src="v.image"
                      alt=""
                      class="circleColor"
                      @click.stop="specChoose(index, i)"
                    />
                    <div
                      class="colorName"
                      :class="v.ison ? 'noimgname_on' : ''"
                      @click.stop="specChoose(index, i)"
                    >
                      {{ v.title }}
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="noimgname"
                      :class="v.ison ? 'noimgname_on' : ''"
                      @click.stop="specChoose(index, i)"
                    >
                      {{ v.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="checkBoxs" v-else>
                <!-- <div class="oneline" v-for="(v, i) in item.next" :key="i">
                  <el-checkbox :checked="v.ison">{{ v.title }}</el-checkbox>
                </div> -->
                <p
                  v-for="(v, i) in item.next"
                  :key="i"
                  @click.stop="specChoose(index, i)"
                  :class="v.ison ? 'check_on' : ''"
                >
                  {{ v.title }}
                </p>
              </div>
            </div>
          </div>
          <div class="s"></div>
        </div>
        <div class="Classification">
          <div class="sex" @click="brandopen = !brandopen">
            品牌
            <i class="icon fr">{{ brandopen ? "&#xe711;" : "&#xe60a;" }}</i>
          </div>
          <div class="details" v-if="brandopen">
            <!-- <div class="details_check">
            <div
              class="noimgname"
              :class="v.ison ? 'noimgname_on' : ''"
              v-for="(v, i) in brandData"
              :key="i"
              @click="brandChoose(i)"
            >
              {{ v.title }}
            </div>
          </div> -->
            <div class="checkBoxs">
              <p
                v-for="(v, i) in brandData"
                :key="i"
                @click.stop="brandChoose(i)"
                :class="v.ison ? 'check_on' : ''"
              >
                {{ v.title }}
              </p>
              <!-- <div class="oneline" v-for="(v, i) in brandData" :key="i">
                <el-checkbox :checked="v.ison" @change="brandChoose(i)">{{
                  v.title
                }}</el-checkbox>
              </div> -->
            </div>
          </div>
        </div>
        <div class="s"></div>
        <div class="Classification">
          <div class="sex" @click="labelopen = !labelopen">
            其他条件
            <i class="icon fr">{{ labelopen ? "&#xe711;" : "&#xe60a;" }}</i>
          </div>
          <div class="details" v-if="labelopen">
            <!-- <div class="details_check">
            <div
              class="noimgname"
              :class="v.ison ? 'noimgname_on' : ''"
              v-for="(v, i) in labelData"
              :key="i"
              @click="otherChoose(i)"
            >
              {{ v.title }}
            </div>
          </div> -->
            <div class="checkBoxs">
              <p
                v-for="(v, i) in labelData"
                :key="i"
                @click.stop="otherChoose(i)"
                :class="v.ison ? 'check_on' : ''"
              >
                {{ v.title }}
              </p>
              <p
                :class="show_stock ? 'check_on' : ''"
                @click.stop="chooseStock"
              >
                仅看有货
              </p>
              <!-- <div class="oneline" v-for="(v, i) in labelData" :key="i">
                <el-checkbox :checked="v.ison" @change="otherChoose(i)">{{
                  v.title
                }}</el-checkbox>
              </div> -->
            </div>
          </div>
        </div>
        <div class="s"></div>
         </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formateTimeStamp } from '../../assets/utils/utils.js'
export default {
  props: {
    screenData: Array,
    classifyData: Array,
    brandData: Array,
    labelData: Array,
    is_show_left: Boolean,
    show_stock: Boolean,
    isdaojishi:Number,
    nowtime:Number,
    active:{
       type: Object,
       default: () => ({
        name:String,
        active_type:Number,
        status:Number
      })
    },
  },
  data() {
    return {
      brandopen: false,
      labelopen: false,
      priceopen: false,
      minPrice: null,
      maxPrice: null,
      daojishi:30,
      daojishiwen:"",
      times:""
    };
  },
  mounted() {
    if(this.active.status==1||this.active.status==2){
      let that=this
     that.$nextTick(() => {
      that.mistiming();
    })
    }
  },
  beforeDestroy(){
    clearInterval();
  },
  methods: {
   mistiming () {

    // console.log( new Date('2023-1-15').getTime() , new Date().valueOf())
      var timeStamp = Number(this.isdaojishi) - Number(this.nowtime)
      this.times = formateTimeStamp(timeStamp);
      const str = `<span>${this.times.day}</span>天<span>${this.times.hour}</span>时<span>${this.times.min}</span>分<span>${this.times.seconds}</span>秒`
      this.daojishiwen = str;
      var TimeDown = setInterval(() => {
        if (timeStamp > 0) {
          timeStamp--;
          const newTime = formateTimeStamp(timeStamp);
          const str = `<p style="color:red;padding-left:5px;line-height:50px;padding-top:2px">${newTime.day}天${newTime.hour}时${newTime.min}分${newTime.seconds}秒</p>`
          if(this.active.status==1){
            this.daojishiwen = "距离活动开始"+str;
          }else{
            this.daojishiwen = "距离活动结束还有"+str;
          }
          this.nowtime=Number(this.nowtime) - 1
        } else {
          clearInterval(TimeDown);
          if(this.active.status==1){
            this.$emit("shuaxin")
            this.daojishiwen = '活动开始'
          }else{
            this.daojishiwen = '活动已结束'
          }
        }
      }, 1000)
    },

  gettime() {
    return  new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000
  },

    openstate(_index) {
      this.screenData[_index].open = !this.screenData[_index].open;
    },
    minChange(e) {
      this.$emit("minprice", e);
    },
    maxChange(e) {
      this.$emit("maxprice", e);
    },
    search() {
      this.$emit("search");
    },
    stopScrollFun(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    cateChoose(id, title, index1, index) {
      this.$emit("cate", id, title, index1, index);
    },
    brandChoose(e) {
      this.$emit("brand", e);
    },
    otherChoose(e) {
      this.$emit("other", e);
    },
    toclassify(_id, _title) {
       localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          classify_ids: _id,
          title: _title,
        },
      });
    },
    specChoose(i, index) {
      this.$emit("spec", i, index);
    },
    chooseStock() {
      this.$emit("update:show_stock", !this.show_stock);
      console.log(!this.show_stock);
    },
  },
};
</script>
<style lang="less">
.el-checkbox__label {
  // font-weight: 300;
  color: #333;
}
</style>
<style lang="less" scoped>
.sifting_hide {
  max-width: 0;
}
.sifting_show {
  max-width: 300px;
}
.sifting {
  width: 300px;
  // background-color: #f2f2f2;
  padding-top: 20px;
  border-top: 1px solid transparent;
  margin-right: 50px;
  overflow: hidden;
  .breadcrumb {
    margin-bottom: 10px;
  }
  .s {
    border-top: 1px solid #ccc;
  }
  .title {
    p {
      font-size: 24px;
      margin-bottom: 25px;
    }
    .desc {
      // display: flex;
      // flex-wrap: wrap;
      overflow: hidden;
      cursor: pointer;
      p {
        font-size: 14px;
        color: #333;
        // width: 33.3%;
        margin-bottom: 16px;
        // font-weight: 300;
        float: left;
        padding: 5px 10px;
        border: 1px solid #999999;
        margin-right: 10px;
        border-radius: 5px;
        // &:hover {
        //   color: #e00b24;
        //   border-color: #e00b24;
        // }
        &.noimgname_on {
          border: 1px solid #df1329;
          color: #df1329;
        }
      }
    }
  }
}
.Classification {
  .sex {
    padding: 20px 0;
  }
  .fr {
    float: right;
    transform: scale(0.6);
  }
  > div:nth-of-type(1) {
    font-size: 18px;
    font-family: DengXian Regular, DengXian Regular-Regular;
    font-weight: 400;
    color: #000011;
    cursor: pointer;
  }
  .details {
    padding-bottom: 20px;
    .checkBoxs {
      // display: flex;
      // flex-direction: column;
      overflow: hidden;
      .oneline {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 5px;
      }
      p {
        cursor: pointer;
      }
    }
    .details_check {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .hasimgbox {
        display: flex;
        flex-direction: column;
        margin: 20px 18px 0 0;
        cursor: pointer;
        .circleColor {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }
      .colorName {
        max-width: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: #333;
        text-align: center;
        border-radius: 5px;
      }
      .noimgname {
        height: 30px;
        // padding: 0 5px;
        border: 1px solid #999999;
        color: #333333;
        font-size: 14px;
        line-height: 30px;
        cursor: pointer;
        margin: 20px 18px 0 0;
        width: 40px;
        text-align: center;
        border-radius: 5px;
      }
      .noimgname_on {
        border: 1px solid #df1329;
        color: #df1329;
      }
      .pricebox {
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          margin: 0 20px;
          font-weight: bold;
        }
      }
    }
    p {
      font-size: 14px;
      color: #333;
      margin-bottom: 16px;
      float: left;
      padding: 5px 10px;
      border: 1px solid #999999;
      margin-right: 10px;
      border-radius: 5px;
      &.check_on {
        color: #df1329;
        border: 1px solid #df1329;
      }
    }
  }
  .css_hide {
    display: none;
  }
}

.daojishi {
  width:100%;
  height:50px;
  font-size:16px;
  line-height: 50px;
  display: flex;
  justify-content: flex-start;
    p{
      color: red;
      line-height: 50px;
      font-size:16px;
    }
}
</style>
