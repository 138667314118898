import Vue from 'vue'
import VueRouter from 'vue-router'

const index = () => import('../views/index')
Vue.use(VueRouter)


//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: index,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/index',
    name: 'HomeCenter',
    component: usercenter,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/basicinfo',
    name: 'basicinfo',
    component: basicinfo,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/noticecenter',
    name: 'noticecenter',
    component: noticecenter,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/minenotice',
    name: 'mineNotice',
    component: minenotice,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/mineselect',
    name: 'MineSelect',
    component: mineselect,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/minefoot',
    name: 'MineFoot',
    component: minefoot,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/minescore',
    name: 'MineScore',
    component: minescore,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/nearbystore',
    name: 'NearbyStore',
    component: nearbystore,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/opinionback',
    name: 'OpinionBack',
    component: opinionback,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/minecomets',
    name: 'MineComets',
    component: minecomets,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/minecoups',
    name: 'MineCoups',
    component: minecoups,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/vipcenter',
    name: 'VipCenter',
    component: vipcenter,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/salescenter',
    name: 'SalesCenter',
    component: salescenter,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/salesorderdetail',
    name: 'SalesOrderDetail',
    component: salesorderdetail, 
     meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/writeids',
    name: 'WriteIds',
    component: writeids,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/mineorders',
    name: 'MineOrders',
    component: mineorders,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/orderdetail',
    name: 'OrderDetail',
    component: orderdetail,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/applyback',
    name: 'ApplyBack',
    component: applyback,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/applybackall',
    name: 'applybackall',
    component: applybackall,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/cometorder',
    name: 'CometOrder',
    component: cometorder,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/logistics',
    name: 'Ali',
    component: logistics,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/minebaby',
    name: 'MineBaby',
    component: minebaby,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/accountsafe',
    name: 'AccountSafe',
    component: accountsafe,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/sendtype',
    name: 'Sendtype',
    component: sendtype,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/backshops',
    name: 'Backshops',
    component: backshops,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/paychoose',
    name: 'Paychoose',
    component: paychoose,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: questions,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/article',
    name: 'Article',
    component: article,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/cometdetail',
    name: 'CometDetail',
    component: cometdetail,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/onlineserver',
    name: 'OnlineServer',
    component: onlineserver,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/superchoose',
    name: 'SuperChoose',
    component: superchoose,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/Classification2',
    name: 'Classification2',//二级分类
    component: Classification2,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/Classification3',
    name: 'Classification3',//三级分类
    component: Classification3,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/flashSale',
    name: 'flashSale',//限时抢购
    component: flashSale,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/product/:s',
    name: 'flashSaleDetails',//限时抢购商品详情
    component: flashSaleDetails,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/seriesDetails',
    name: 'seriesDetails',//某系列详情页面
    component: seriesDetails,
    meta:{
      keepAlive: true // 需要被缓存
    }
  },
  {
    path: '/seriesDetailsactive',
    name: 'seriesDetailsactive',//某系列详情页面
    component: seriesDetailsactive,
    meta:{
      keepAlive: true // 需要被缓存
    }
  },
  
  {
    path: '/couponCenter',
    name: 'couponCenter',//领券中心
    component: couponCenter,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/memberGoods',
    name: 'memberGoods',//会员商品列表
    component: memberGoods,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/memberNoMerchandise',
    name: 'memberNoMerchandise',//会员没有商品的页面
    component: memberNoMerchandise,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/NonMember',
    name: 'NonMember',//非会员页面
    component: NonMember,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/register',
    name: 'register',//注册页面
    component: register,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/myCart',
    name: 'myCart',//我的购物车
    component: myCart,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',//支付成功页面
    component: paySuccess,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/login',
    name: 'login',//会员登录页面
    component: login,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/fetchPay',
    name: 'fetchPay',//调取支付页面
    component: fetchPay,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/findPassword',
    name: 'findPassword',//找回密码
    component: findPassword,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/suitDetails',
    name: 'suitDetails',//套装详情页面
    component: suitDetails,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/suitList',
    name: 'suitList',//套装列表
    component: suitList,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/addCart',
    name: 'addCart',//套装购物车
    component: addCart,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',//确认订单页面
    component: confirmOrder,
    meta:{
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/messagexq',
    name: 'messagexq',//确认订单页面
    component: messagexq,
    meta:{
      keepAlive: false // 需要被缓存
    }
  }
]

import usercenter from '@/views/HomeCenter/index.vue'
import basicinfo from '@/views/HomeCenter/basicinfo.vue'
import noticecenter from '@/views/HomeCenter/noticecenter.vue'
import minenotice from '@/views/HomeCenter/minenotice.vue'
import mineselect from '@/views/HomeCenter/mineselect.vue'
import minefoot from '@/views/HomeCenter/minefoot.vue'
import minescore from '@/views/HomeCenter/minescore.vue'
import nearbystore from '@/views/HomeCenter/nearbystore.vue'
import opinionback from '@/views/HomeCenter/opinionback.vue'
import minecomets from '@/views/HomeCenter/minecomets.vue'
import minecoups from '@/views/HomeCenter/minecoups.vue'
import vipcenter from '@/views/HomeCenter/vipcenter.vue'
import salescenter from '@/views/HomeCenter/salescenter.vue'
import salesorderdetail from '@/views/HomeCenter/salesorderdetail.vue'
import writeids from '@/views/HomeCenter/writeids.vue'
import mineorders from '@/views/HomeCenter/mineorders.vue'
import orderdetail from '@/views/HomeCenter/orderdetail.vue'
import applyback from '@/views/HomeCenter/applyback.vue'
import applybackall from '@/views/HomeCenter/applybackall.vue'
import cometorder from '@/views/HomeCenter/cometorder.vue'
import logistics from '@/views/HomeCenter/logistics.vue'
import minebaby from '@/views/HomeCenter/minebaby.vue'
import accountsafe from '@/views/HomeCenter/accountsafe.vue'
import sendtype from '@/views/masterserver/sendtype.vue'
import backshops from '@/views/masterserver/backshops.vue'
import paychoose from '@/views/masterserver/paychoose.vue'
import questions from '@/views/masterserver/questions.vue'
import messagexq from '@/views/masterserver/messagexq.vue'
import article from '@/views/masterserver/article.vue'
import cometdetail from '@/views/SeriesDetails/cometdetail.vue'
import onlineserver from '@/views/HomeCenter/onlineserver.vue'
import superchoose from '@/views/SeriesDetails/superchoose.vue'

// const index = () => import('../views/index')
import Classification2 from '../views/Classification/Classification2'
import Classification3 from '../views/Classification/Classification3'
import flashSale from '../views/flashSale/flashSale'
import seriesDetails from '../views/SeriesDetails/seriesDetails'
import seriesDetailsactive from '../views/SeriesDetails/seriesDetailsactive'
import couponCenter from '../views/couponCenter/couponCenter'
import memberGoods from '../views/Member/memberGoods'
import memberNoMerchandise from '../views/Member/memberNoMerchandise'
import NonMember from '../views/Member/NonMember'
import flashSaleDetails from '../views/flashSale/flashSaleDetails'
import register from '../views/ShoppingOrder/register'
import myCart from '../views/ShoppingOrder/myCart'
import paySuccess from '../views/ShoppingOrder/paySuccess'
import login from '../views/ShoppingOrder/login'
import fetchPay from '../views/ShoppingOrder/fetchPay'
import findPassword from '../views/ShoppingOrder/findPassword'
import suitDetails from '../views/suit/suitDetalis'
import suitList from '../views/suit/suitList'
import addCart from '../views/suit/addCart'
import confirmOrder from '../views/ShoppingOrder/confirmOrder'

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//     if (to.path == '/seriesDetails' ) { // 当进入这个页面进行记录
   
//       next();
//     }
// });

export default router
