import { render, staticRenderFns } from "./seriesDetails.vue?vue&type=template&id=01b7ae42&scoped=true&"
import script from "./seriesDetails.vue?vue&type=script&lang=js&"
export * from "./seriesDetails.vue?vue&type=script&lang=js&"
import style0 from "./seriesDetails.vue?vue&type=style&index=0&id=01b7ae42&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b7ae42",
  null
  
)

export default component.exports