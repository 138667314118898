<template>
  <div class="box mwframe">
    <div
      style="text-align: center"
      @mousedown="flag = true"
      @mouseleave="flag = false"
    >
      头部选择测试
    </div>
    <sifting v-show="flag"></sifting>
    <div class="component mw">
      <siftingStyle>
        <template v-slot:title> 长袖连体衣 </template>
      </siftingStyle>
      <div>
        <siftingList></siftingList>
      </div>
    </div>
  </div>
</template>
<script>
import siftingStyle from "../../components/Sifting/sifting_style";
import siftingList from "../../components/Sifting/sifting_list";
import sifting from "../../components/Sifting/sifting";

export default {
  props: [],
  data() {
    return {
      flag: false,
    };
  },
  mounted() {},
  methods: {},
  components: {
    siftingStyle,
    siftingList,
    sifting,
  },
};
</script>
<style lang="less" scoped>
.box {
  background: #fafafa;
  margin-top: 140px;
  .component {
    display: flex;
    justify-content: space-between;
  }
}
</style>
