<template>
  <div>
    <div class="noticepage">
      <div class="mw">
        <div v-if="!noticeData.length" class="nodatas">
          <img :src="nodataimg" alt="" class="nodataimg" />
          <div class="nodatatext">暂无任何信息！</div>
        </div>
        <div
          class="noticeOne"
          :class="openindex == index ? 'rowon' : ''"
          @click="noticechange(index)"
          v-for="(item, index) in noticeData"
          :key="index"
        >
          <div class="noticerow">
            <div class="noticeinfo">
              <div class="time">{{ item.createtime }}</div>
              <div class="title">{{ item.message_title }}</div>
            </div>
            <div class="icon tardiness arrow">
              {{ openindex == index ? "&#xe621;" : "&#xe61f;" }}
            </div>
          </div>
          <div class="detailinfo" v-if="openindex == index">
            <div class="details" v-if="item.message_type === 1">
              <!-- 订单 -->
              <div class="order">
                <div class="shops">
                  <div class="shopdetail">
                    <img :src="item.goods_image" alt="" />
                    <div class="detailbox">
                      <p class="name">{{ item.goods_title }}</p>
                      <p class="ids">订单编号：{{ item.order_sn }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="details" v-if="item.message_type === 2">
              <!-- 退款单 -->
              <div class="order">
                <div class="shops">
                  <div class="shopdetail">
                    <img :src="item.goods_image" alt="" />
                    <div class="detailbox">
                      <p class="name">{{ item.goods_title }}</p>
                      <p class="ids">退款单号：{{ item.refund_sn }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="details" v-if="item.message_type === 3">
              <!-- 优惠券 -->
              <div class="coupon">
                <h2>mikihouse送了您一张优惠券，可在个人中心查看使用</h2>
                <p>
                  注：{{
                    item.coupon_range == 1
                      ? "通用券"
                      : item.coupon_range == 2
                      ? "分类券"
                      : "商品券"
                  }}，{{
                    "满" +
                    item.condition +
                    (item.coupon_type == 1 ? "减" : "打") +
                    item.discount +
                    (item.coupon_type == 2 ? "折" : "")
                  }}，{{ item.use_start_time }} - {{ item.use_end_time }}.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="noticeData.length">
          <div class="more-goods" v-if="page < total_page" @click="loadmore">
            发现更多
          </div>
          <div class="nomore" v-else>没有更多了</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      nodataimg: require("/src/assets/img/lessnotice.png"),
      openindex: -1,
      noticeData: [],
      total_page: 1,
      page: 1,
    };
  },
  mounted() {
    this.getNoticeData();
  },
  methods: {
    getNoticeData() {
      let { page } = { ...this };
      this.$myAjax("user/message_list", "get", { page }, (res) => {
        this.noticeData = res.list;
        this.total_page = res.total_page;
      });
    },
    noticechange(_index) {
      this.openindex = _index;
    },
    loadmore() {
      this.page++;
      this.getNoticeData();
    },
  },
};
</script>
<style lang="less" scoped>
.noticepage {
  min-height: 966px;
  padding-bottom: 100px;
  background: #fafafa;
  margin-top: 140px;
  .nodatas {
    display: inline-block;
    width: 268px;
    text-align: center;
    left: 50%;
    margin: 125px 0 0 -148px;
    position: relative;
    .nodataimg {
      width: 268px;
      height: 167px;
      float: left;
    }
    .nodatatext {
      font-size: 20px;
      color: #000000;
      float: left;
      text-align: center;
      width: 100%;
    }
  }
  .noticeOne {
    display: inline-block;
    margin-top: 26px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background: #fff;
    }
    .noticerow {
      padding: 44px 32px;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      .noticeIcon {
        text-align: center;
        color: #e50010;
        float: left;
        .icon {
          font-size: 55px;
        }
      }
      .noticeinfo {
        margin-left: 38px;
        float: left;
        .time {
          color: #999999;
          font-size: 20px;
        }
        .title {
          font-size: 24px;
          color: #333333;
          margin-top: 10px;
        }
      }
      .arrow {
        float: right;
        font-size: 24px;
        line-height: 70px;
      }
    }
    .detailinfo {
      width: 100%;
      background: #fff;
      float: left;
      .details {
        margin-left: 70px;
        padding: 24px 0 37px;
        width: 922px;
        .shops {
          width: 100%;
          .ifoutsides {
            display: inline-block;
            width: 100%;
            margin-bottom: 21px;
            img {
              width: 29px;
              height: 25px;
              float: left;
            }
            .oustnumber {
              margin-left: 12px;
              font-size: 16px;
              color: #111111;
              float: left;
            }
            .outsaddress {
              margin-left: 174px;
              font-size: 16px;
              color: #111111;
              float: left;
            }
          }
          .shopdetail {
            display: inline-block;
            width: 100%;
            img {
              width: 110px;
              height: 110px;
              float: left;
            }
            .detailbox {
              margin-left: 17px;
              float: left;
              p {
                font-size: 14px;
                color: #111111;
              }
              .name {
                font-size: 18px;
                color: #111111;
              }
              .ids {
                margin-top: 10px;
              }
              .metions {
                margin-top: 16px;
                float: left;
                display: inline-block;
                .icon {
                  font-size: 16px;
                  color: #999999;
                  float: left;
                }
                span {
                  margin-left: 8px;
                  color: #999999;
                  float: left;
                  font-size: 14px;
                }
              }
            }
            .price {
              float: right;
              line-height: 110px;
              font-size: 14px;
              color: #111111;
            }
          }
        }
        .coupon {
          h2 {
            margin-bottom: 10px;
            color: #333;
            font-size: 20px;
          }
          p {
            color: #999;
          }
        }
      }
    }
  }
  .rowon {
    background: #ffffff;
  }
  .nomore {
    width: 100%;
    padding: 30px 0;
    font-size: 16px;
    color: #666666;
    text-align: center;
    float: left;
  }
  .more-goods {
    text-align: center;
    border: 1px solid #f00;
    width: 153px;
    height: 45px;
    line-height: 45px;
    margin: 30px auto 0 auto;
    color: red;
    cursor: pointer;
  }
}
</style>
