<template>
  <div class="window" v-if="show">
    <div class="messagebox">
      <div class="close" @click="show = false">
        <i class="icon">&#xe61c;</i>
      </div>
      <div class="title">友情提示</div>
      <div class="content">跨境商品与普通商品不可一起购买</div>
      <div class="btn">返回购物车</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.window {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
}
.messagebox {
  width: 25vw;
  height: 360px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  position: relative;
  margin-left: -12.5vw;
  margin-top: -180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  .close {
    position: absolute;
    top: 37px;
    right: 42px;
    cursor: pointer;
  }
  .title {
    font-size: 30px;
    color: #111;
    text-align: center;
    font-weight: bold;
    margin-top: 67px;
  }
  .content {
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-top: 53px;
  }
  .btn {
    width: 59%;
    height: 50px;
    background-color: #e60012;
    text-align: center;
    color: #fff;
    line-height: 50px;
    margin-top: 81px;
    cursor: pointer;
  }
}
</style>
