<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="10"></navbar>
        <div class="mainbox">
          <div class="title">账户与安全</div>
          <div class="showbox">
            <div class="rowbox" @click="index = 0">
              <div class="tops">
                <div class="rowleft">
                  <p class="titles">地址管理</p>
                  <p class="metion">完善信息后可方便地址管理</p>
                </div>
                <div class="icon tardiness">
                  {{ index == 0 ? "&#xe61f;" : "&#xe621;" }}
                </div>
              </div>
            </div>
            <div class="addressbox" v-if="index == 0">
              <div
                :class="
                  item.is_default == 1 ? 'morenaddressbox' : 'normaladdress'
                "
                v-for="(item, index) in addressData"
                :key="index"
              >
                <div class="moreninfos">
                  <div class="namebox">
                    <span>{{ item.contacts }}</span>
                    <div class="morentag" v-if="item.is_default == 1">默认</div>
                  </div>
                  <p class="address">
                    {{ item.province }} {{ item.city }} {{ item.area }}
                    {{ item.address }}
                  </p>
                  <p class="phone">{{ item.mobile }}</p>
                  <p class="phone">{{ item.email }}</p>
                </div>
                <div
                  class="icon icondel tardiness"
                  v-if="item.is_default == 2"
                  @click="addressdelete(item.address_id)"
                >
                  &#xec7b;
                </div>
                <div
                  class="icon iconedit tardiness"
                  @click="addressEdit(item.address_id)"
                >
                  &#xe636;
                </div>
              </div>
              <div class="addnewaddress" @click="addressEdit('')">
                +添加新地址
              </div>
            </div>
            <div class="rowbox" @click="index = 1">
              <div class="tops">
                <div class="rowleft">
                  <p class="titles">登录密码</p>
                  <p class="metion">安全性高的密码可以使账户更安全</p>
                </div>
                <div class="icon tardiness">
                  {{ index == 1 ? "&#xe61f;" : "&#xe621;" }}
                </div>
              </div>
            </div>
            <div class="passwordbox" v-if="index == 1">
              <p>
                {{
                  baseinfo.exist_password == 1
                    ? "已有登录密码"
                    : "暂未设置登录密码"
                }}
              </p>
              <div class="changebtn" @click="passwordstatus = true">
                {{ baseinfo.exist_password == 1 ? "设置密码" : "设置密码" }}
              </div>
            </div>
            <div class="rowbox" @click="index = 2">
              <div class="tops">
                <div class="rowleft">
                  <p class="titles">绑定邮箱</p>
                  <p class="metion">绑定邮箱，让账户更安全</p>
                </div>
                <div class="icon tardiness">
                  {{ index == 2 ? "&#xe61f;" : "&#xe621;" }}
                </div>
              </div>
            </div>
            <div class="passwordbox" v-if="index == 2">
              <p>
                {{
                  baseinfo.exist_email == 1
                    ? "已绑定邮箱"
                    : "还未绑定邮箱，快来绑定吧！"
                }}
              </p>
              <div class="changebtn" @click="bingdingemailstatus = true">
                {{ baseinfo.exist_email == 1 ? "修改" : "绑定" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mask"
      v-if="addressstatus || passwordstatus || bingdingemailstatus"
    >
      <div class="windowsadd" v-if="addressstatus">
        <div class="widowtitle">
          {{ editid ? "编辑地址" : "新增地址" }}
          <div class="icon tardiness" @click="addressstatus = false">
            &#xe61c;
          </div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0"
          class="contents"
        >
          <el-form-item prop="add_name">
            <p class="title">姓名</p>
            <el-input
              v-model="ruleForm.add_name"
              placeholder="请输入姓名"
              clearable
              class="eleinput"
            ></el-input>
          </el-form-item>
          <el-form-item prop="add_phone">
            <p class="title">电话</p>
            <!-- <el-select v-model="select" placeholder="请选择" class="eleinput2">
              <el-option label="中国 +86" value="1"></el-option>
            </el-select> -->
            <el-input
              v-model="ruleForm.add_phone"
              placeholder="请输入电话号码"
              maxlength="11"
              type="tel"
              clearable
              class="eleinput1"
            ></el-input>
          </el-form-item>
          <el-form-item prop="areavalue">
            <p class="title">省市区</p>
            <el-cascader
              v-model="ruleForm.areavalue"
              :options="options"
              @change="handleChange"
              class="eleinput"
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="address">
            <p class="title">详细地址</p>
            <el-input
              v-model="ruleForm.address"
              placeholder="请输入详细地址"
              clearable
              class="eleinput"
            ></el-input>
          </el-form-item>

          <div class="setbox" @click="ruleForm.choose = !ruleForm.choose">
            <div class="icon tardiness">
              {{ ruleForm.choose ? "&#xe624;" : "&#xe64c;" }}
            </div>
            <span>设为默认地址</span>
          </div>
          <div class="savebtn" @click="submitForm('ruleForm')">立即保存</div>
        </el-form>
      </div>
      <div class="windowspassword" v-if="passwordstatus">
        <div class="widowtitle">
          修改密码
          <div class="icon tardiness" @click="passwordstatus = false">
            &#xe61c;
          </div>
        </div>
        <el-form
          :model="rulePassword"
          :rules="passwordrules"
          ref="rulePassword"
          class="contents"
        >
          <el-form-item label="手机号码" prop="user">
            <el-input
              v-model="rulePassword.mobile"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input
              v-model="rulePassword.captcha"
              placeholder="请输入短信验证码"
              ><el-button
                slot="append"
                @click="sendemail2"
                :loading="loading2"
                :disabled="cannot2"
                style="width: 140px"
                >{{ countdetail2 }}</el-button
              ></el-input
            >
          </el-form-item>
          <el-form-item prop="newkey">
            <p class="title">新的登录密码</p>
            <el-input
              v-model="rulePassword.newkey"
              placeholder="请输入新的登录密码"
              clearable
              type="password"
              class="eleinput"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="surekey">
            <p class="title">确认新的登录密码</p>
            <el-input
              v-model="rulePassword.surekey"
              placeholder="请确认新的登录密码"
              clearable
              type="password"
              class="eleinput"
            ></el-input>
          </el-form-item> -->
          <div class="savebtn" @click="passwordSubmit('rulePassword')">
            立即保存
          </div>
        </el-form>
      </div>
      <div class="windowsemail" v-if="bingdingemailstatus">
        <div class="widowtitle">
          绑定邮箱
          <div class="icon tardiness" @click="bingdingemailstatus = false">
            &#xe61c;
          </div>
        </div>
        <el-form
          :model="ruleEmail"
          :rules="Emailrules"
          ref="ruleEmail"
          label-width="0"
          class="contents"
        >
          <el-form-item prop="email">
            <p class="title">请输入邮箱地址</p>
            <el-input
              v-model="ruleEmail.email"
              placeholder="请输入邮箱地址"
              clearable
              class="eleinput"
            ></el-input>
          </el-form-item>
          <el-form-item prop="ecode">
            <p class="title">请输入验证码</p>
            <el-input
              placeholder="请输入验证码"
              v-model="ruleEmail.ecode"
              maxlength="6"
              class="eleinput"
            >
              <el-button
                slot="append"
                class="elmbutton"
                @click="sendemail"
                :loading="loading"
                :disabled="cannot"
                >{{ countdetail }}</el-button
              >
            </el-input>
          </el-form-item>
          <div class="savebtn" @click="emailSubmit('ruleEmail')">立即保存</div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import { areaData } from "@/assets/js/area.js";
export default {
  components: { navbar },
  data() {
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入电话号码"));
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error("请输入有效的电话号码"));
      } else {
        callback();
      }
    };
    // var keyCheck = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请再次输入密码"));
    //   } else if (value !== this.rulePassword.newkey) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
    var emailCheck = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === "") {
        callback(new Error("请输入邮箱地址"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入有效的邮箱地址"));
      } else {
        callback();
      }
    };
    var ecodeCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      nodataimg: require("/src/assets/img/lessbaby.png"),
      editstatus: false,
      index: "9",
      addressstatus: false,
      passwordstatus: false,
      bingdingemailstatus: false,
      // select: "1",

      // 地址
      ruleForm: {
        add_name: "", //新增修改姓名
        add_phone: "", //新增修改电话号码
        areavalue: [], //省市区
        address: "", //具体地址
        choose: false,
      },
      rules: {
        add_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        add_phone: [{ required: true, validator: checkphone, trigger: "blur" }],
        areavalue: [
          {
            required: true,
            message: "请选择省市区",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },

      //登录密码
      rulePassword: {
        oldkey: "",
        newkey: "",
        surekey: "",
        captcha: "",
        mobile: "",
      },
      passwordrules: {
        // oldkey: [
        //   { required: true, message: "请输入当前登录密码", trigger: "blur" },
        // ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newkey: [
          { required: true, message: "请输入新的登录密码", trigger: "blur" },
        ],
        // surekey: [{ required: true, validator: keyCheck, trigger: "blur" }],
      },

      //邮箱
      ruleEmail: {
        email: "",
        ecode: "",
      },
      Emailrules: {
        email: [{ required: true, validator: emailCheck, trigger: "blur" }],
        ecode: [{ required: true, validator: ecodeCheck, trigger: "blur" }],
      },

      loading: false,
      cannot: false,
      countdetail: "获取邮箱验证码",
      countdetail2: "获取手机验证码",
      cannot2: false,
      loading2: false,
      mobile: "",

      options: [], //省市区json数据
      addressData: [], //地址数据
      editid: "", //地址修改id

      baseinfo: {},
    };
  },
  mounted() {
    this.getaddressList();
    this.getaccountInfo();
    this.options = areaData;
  },
  methods: {
    handleChange() {},
    getaddressList() {
      this.$myAjax("user/address_list", "get", {}, (res) => {
        this.addressData = res;
      });
    },
    addressdelete(_id) {
      this.$confirm("此操作将删除地址信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$myAjax(
          "user/del_address",
          "post",
          {
            address_id: _id,
          },
          () => {
            this.$message({
              type: "success",
              message: "删除成功!",
              offset:170
            });
            this.getaddressList();
          }
        );
      });
    },
    addressEdit(_id) {
      this.editid = _id;
      if (_id != "") {
        this.$myAjax(
          "user/address_details?address_id=" + _id,
          "get",
          {},
          (res) => {
            console.log(res);
            let array = [res.province, res.city, res.area];
            this.ruleForm.areavalue = array;
            this.ruleForm.add_name = res.contacts;
            this.ruleForm.add_phone = res.mobile;
            this.ruleForm.address = res.address;
            this.ruleForm.choose = res.is_default == 1 ? true : false;
          }
        );
      }
      this.addressstatus = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          this.editid
            ? (url = "user/edit_address")
            : (url = "user/add_address");
          let postData = {
            contacts: this.ruleForm.add_name,
            mobile: this.ruleForm.add_phone,
            province: this.ruleForm.areavalue[0],
            city: this.ruleForm.areavalue[1],
            area: this.ruleForm.areavalue[2],
            address: this.ruleForm.address,
            is_default: this.ruleForm.choose ? 1 : 2,
          };
          if (this.editid) {
            postData.address_id = this.editid;
          }
          this.$myAjax(url, "post", postData, () => {
            this.addressstatus = false;
            this.$message({
              type: "success",
              message: this.editid ? "修改成功!" : "添加成功!",
              offset:170
            });
            this.getaddressList();
          });
        }
      });
    },
    passwordSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$myAjax(
            "account/reset_password",
            "post",
            {
              type: "mobile",
              mobile: this.rulePassword.mobile,
              // oldpassword: this.rulePassword.oldkey,
              newpassword: this.rulePassword.newkey,
              captcha: this.rulePassword.captcha,
            },
            () => {
              this.addressstatus = false;
              this.$message({
                type: "success",
                message: "设置成功!",
                offset:170
              });
              this.loginOut();
            }
          );
        }
      });
    },
    emailSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$myAjax(
            "user/change_email",
            "post",
            {
              email: this.ruleEmail.email,
              captcha: this.ruleEmail.ecode,
            },
            () => {
              this.$message({
                type: "success",
                message: "绑定邮箱成功!",
                offset:170
              });
              this.bingdingemailstatus = false;
              this.getaccountInfo();
            }
          );
        }
      });
    },
    loginOut() {
      this.$myAjax("account/logout", "post", {}, () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        this.$router.push({
          name: "login",
        });
      });
    },
    sendemail() {
      if (!this.ruleEmail.email) {
        this.$message({
          message: "请输入邮箱账号",
          type: "warning",
          offset:170
        });
      } else {
        this.$myAjax(
          "ems/send",
          "post",
          {
            email: this.ruleEmail.email,
            event: "changeemail",
          },
          () => {
            this.$message({
              message: "发送成功",
              type: "success",
              offset:170
            });
            let count = 60;
            this.loading = true;
            this.cannot = true;
            var interval = setInterval(() => {
              this.loading = false;
              count--;
              if (count == 0) {
                clearInterval(interval);
                this.countdetail = "获取邮箱验证码";
                this.cannot = false;
              } else {
                this.countdetail = count + "s";
              }
            }, 1000);
          }
        );
      }
    },
    sendemail2() {
      if (this.rulePassword.mobile) {
        this.$myAjax(
          "sms/send",
          "post",
          {
            mobile: this.rulePassword.mobile,
            event: "resetpwd",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset:170
            });
            let count2 = 60;
            this.loading2 = true;
            this.cannot2 = true;
            var interval = setInterval(() => {
              this.loading2 = false;
              count2--;
              if (count2 == 0) {
                clearInterval(interval);
                this.countdetail2 = "获取验证码";
                this.cannot2 = false;
              } else {
                this.countdetail2 = count2 + "s";
              }
            }, 1000);
          }
        );
      } else {
        this.$message({
          showClose: true,
          message: "请先填写手机号码",
          offset:170
        });
      }
    },
    reset_password() {},
    getaccountInfo() {
      this.$myAjax("user/account_success", "get", {}, (res) => {
        console.log(res);
        this.baseinfo = res;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      flex: 1;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .showbox {
        padding: 0 35px;
        display: inline-block;
        width: 100%;
        float: left;
        .rowbox {
          width: 100%;
          height: 146px;
          border: 1px solid #e6e6e6;
          padding: 0 41px 0 48px;
          margin-top: 28px;
          cursor: pointer;
          .tops {
            float: left;
            width: 100%;
            .rowleft {
              float: left;
              .titles {
                font-size: 18px;
                color: #000000;
                font-weight: bold;
                margin-top: 49px;
              }
              .metion {
                margin-top: 18px;
                color: #666666;
                font-size: 14px;
              }
            }
            .icon {
              font-size: 18px;
              color: #000;
              float: right;
              margin-top: 69px;
            }
          }
        }
        .addressbox {
          padding: 1px 41px 38px 48px;
          border: 1px solid #e6e6e6;
          border-top: none;
          .morenaddressbox {
            display: inline-block;
            width: 100%;
            .moreninfos {
              float: left;
              display: inline-block;
              .namebox {
                margin-top: 27px;
                width: 100%;
                display: inline-block;
                span {
                  color: #000000;
                  font-size: 16px;
                  float: left;
                }
                .morentag {
                  margin-left: 16px;
                  width: 41px;
                  height: 19px;
                  border: 1px solid #666666;
                  text-align: center;
                  line-height: 19px;
                  font-size: 13px;
                  color: #666666;
                  float: left;
                }
              }
              .address {
                margin-top: 10px;
                font-size: 14px;
                color: #666666;
              }
              .phone {
                margin-top: 8px;
                font-size: 14px;
                color: #666666;
              }
            }
            .icon {
              font-size: 25px;
              color: #666666;
              float: right;
              margin-top: 67px;
              cursor: pointer;
            }
          }
          .normaladdress {
            display: inline-block;
            width: 100%;
            border-top: 1px solid #e6e6e6;
            margin-top: 27px;
            .moreninfos {
              float: left;
              display: inline-block;
              .namebox {
                margin-top: 27px;
                color: #000000;
                font-size: 16px;
              }
              .address {
                margin-top: 10px;
                font-size: 14px;
                color: #666666;
              }
              .phone {
                margin-top: 8px;
                font-size: 14px;
                color: #666666;
              }
            }
            .iconedit {
              font-size: 25px;
              color: #666666;
              float: right;
              margin-top: 67px;
              cursor: pointer;
              margin-right: 31px;
            }
            .icondel {
              font-size: 25px;
              color: #666666;
              float: right;
              margin-top: 67px;
              cursor: pointer;
            }
          }
          .addnewaddress {
            margin-top: 47px;
            width: 100%;
            height: 48px;
            background: #e60012;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            line-height: 48px;
            cursor: pointer;
          }
        }
        .passwordbox {
          width: 100%;
          height: 117px;
          border: 1px solid #e6e6e6;
          border-top: none;
          padding: 0 41px 0 48px;
          p {
            font-size: 16px;
            color: #000000;
            line-height: 117px;
            float: left;
          }
          .changebtn {
            float: right;
            width: 148px;
            height: 48px;
            border: 1px solid #e60012;
            text-align: center;
            line-height: 48px;
            font-size: 16px;
            color: #e60012;
            font-weight: bold;
            position: relative;
            top: 50%;
            margin-top: -24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 2000;
  .windowsadd {
    width: 603px;
    height: 627px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -313.5px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;
      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .contents {
      padding: 0 40px;
      .title {
        font-size: 14px;
        color: #000000;
      }
      .eleinput {
        width: 100%;
        margin-top: 12px;
      }
      .eleinput2 {
        width: 250px;
        margin-top: 12px;
      }
      .eleinput3 {
        margin-top: 12px;
        width: 250px;
        margin-left: 22px;
      }
      .setbox {
        margin-top: 34px;
        cursor: pointer;
        .icon {
          font-size: 17px;
          color: #000000;
          float: left;
        }
        span {
          margin-left: 9px;
          font-size: 14px;
          color: #000000;
          float: left;
        }
      }
      .savebtn {
        width: 182px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #e60012;
        margin-top: 33px;
        font-size: 16px;
        font-weight: bold;
        color: #e60012;
        text-align: center;
        line-height: 48px;
        position: relative;
        left: 50%;
        margin-left: -182px;
        float: left;
        cursor: pointer;
      }
    }
  }
  .windowspassword {
    width: 603px;
    height: 501px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -250.5px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;
      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .contents {
      padding: 0 40px;
      .title {
        font-size: 14px;
        color: #000000;
      }
      .eleinput {
        width: 100%;
        margin-top: 16px;
      }
      .savebtn {
        width: 182px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #e60012;
        margin-top: 33px;
        font-size: 16px;
        font-weight: bold;
        color: #e60012;
        text-align: center;
        line-height: 48px;
        position: relative;
        left: 50%;
        margin-left: -91px;
        cursor: pointer;
      }
    }
  }
  .windowsemail {
    width: 603px;
    height: 394px;
    background: #fff;
    top: 50%;
    left: 50%;
    margin-left: -301.5px;
    margin-top: -197px;
    position: relative;
    .widowtitle {
      position: relative;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      line-height: 55px;
      color: #fff;
      font-size: 16px;
      background: #e60012;
      .icon {
        position: absolute;
        color: #fff;
        right: 22px;
        top: 0;
        cursor: pointer;
      }
    }
    .contents {
      padding: 0 40px;
      .title {
        font-size: 14px;
        color: #000000;
      }
      .eleinput {
        width: 100%;
        margin-top: 16px;
      }
      .elmbutton {
        color: #fff;
        background: #e60012;
        border-radius: 2px;
        height: 100%;
        width: 150px;
      }
      .savebtn {
        width: 182px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #e60012;
        margin-top: 33px;
        font-size: 16px;
        font-weight: bold;
        color: #e60012;
        text-align: center;
        line-height: 48px;
        position: relative;
        left: 50%;
        margin-left: -91px;
        cursor: pointer;
      }
    }
  }
}
</style>
