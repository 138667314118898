<template>
  <div class="fetchPay">
    <div class="payText" v-show="showInfo">正在为你调取支付...</div>
    <div class="pay" v-show="!showInfo">
      <div class="pay_box">
        <div class="box_lrbox">
          <div class="lrbox_left">
            <h2>收银台</h2>
            <p class="box_tip">
              交易将在{{ expire_time }}以后关闭，请及时付款！
            </p>
            <div class="text">
              <i class="icon tardiness alipay" v-if="pay_type == 'A01'"
                >&#xe663;</i
              >
              <i class="icon tardiness wepay" v-else>&#xe612;</i>
              <span>{{ pay_type == "A01" ? "支付宝" : "微信" }}支付</span>
            </div>
            <div class="price">
              <span>￥</span><span class="big_price">{{ actual_pay }}</span>
            </div>
            <div class="img">
              <canvas id="canvas"></canvas>
            </div>
            <div class="tip">
              <a href="javascript:void(0)"
                >打开{{
                  pay_type == "A01" ? "支付宝" : "微信"
                }}手机APP扫描二维码进行支付</a
              >
            </div>
          </div>
          <!-- <div class="lrbox_right">
              <div class="img">
                <img src="../../assets/img/index/sp1.png" alt="sp">
              </div>
              <div class="btn">
                <i class="icon">&#xe601;</i>登录账户付款
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode";
export default {
  props: [],
  data() {
    return {
      pay_type: "",
      order_id: 0,
      actual_pay: 0,
      expire_time: 0,
      timer: null,
      timer2: null,
      showInfo:true
    };
  },
  created() {},
  mounted() {
    this.pay_type = this.$route.query.pay_type;
    this.order_id = this.$route.query.order_id;
    this.cashier();
  },
  destroyed() {
    clearInterval(this.timer);
    clearTimeout(this.timer2);
    this.timer = null;
    this.timer2 = null;
  },
  methods: {
    cashier() {
      let { order_id, pay_type } = { ...this };
      this.$myAjax(
        "order/cashier",
        "post",
        {
          order_id,
          pay_type,
        },
        (res) => {
          this.actual_pay = res.actual_pay;
          this.expire_time = res.expire_time;

          this.querytimer();
          this.pay_order();
        }
      );
    },
    pay_order() {
      let { order_id, pay_type } = { ...this };
      this.$myAjax(
        "order/pay_order",
        "post",
        {
          order_id,
          pay_type,
        },
        (res) => {
          this.useqrcode(res.pay_info);
          this.query_order();
        }
      );
    },
    query_order() {
      let { order_id } = { ...this };
      this.$myAjax(
        "order/query_order",
        "get",
        {
          order_id,
        },
        (res) => {
          console.log(res);
          if (res.pay_status === 2) {
            this.$router.replace({
              name: "OrderDetail",
              query: {
                id: order_id,
              },
            });
          } else {
            this.timer2 = setTimeout(() => {
              this.query_order();
            }, 5000);
          }
        }
      );
    },

    useqrcode(url) {
      //生成的二维码内容，可以添加变量
      this.QueryDetail = url;
      var canvas = document.getElementById("canvas");
      QRCode.toCanvas(canvas, this.QueryDetail, function () {
      });
      this.showInfo = false
    },
    querytimer() {
      this.timer = setInterval(() => {
        let thetime = this.expire_time;
        var d = new Date(Date.parse(thetime.replace(/-/g, "/")));
        var curDate = new Date();
        if (d <= curDate) {
          clearInterval(this.timer)
          this.$router.replace({
            name: "MineOrders",
          });
        }
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.fetchPay {
  margin-top: 140px;
  .payText {
    font-size: 18px;
    color: #111;
    height: 1129px;
    text-align: center;
    padding-top: 50%;
    transform: translateY(-50%);
  }
  .pay {
    .pay_head {
      background-color: #80878a;
      p {
        color: #fff;
        width: 49.5%;
        margin: 0 auto;
        text-align: end;
      }
    }
    .pay_logo {
      background-color: #fff;
      .logo {
        width: 49.5%;
        margin: 0 auto;
        color: #666;
      }
    }
    .pay_box {
      width: 49.5%;
      margin: 0 auto;
      padding: 50px 0;
      .box_tip {
        i.icon {
          margin-right: 20px;
        }
      }
      .box_order {
        margin: 20px 0;
      }
      .box_lrbox {
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        .lrbox_left {
          // padding: 100px 0;
          flex-grow: 1;
          text-align: center;
          h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 100px;
            height: 100px;
          }
          p.box_tip {
            color: #666;
            height: 30px;
            line-height: 30px;
          }
          > div {
            margin: 10px 0;
          }
          i {
            font-size: 25px;
            vertical-align: middle;

            margin-right: 5px;
            &.alipay {
              color: #02a9f1;
            }
            &.wepay {
              color: #00c800;
            }
          }
          span {
            font-size: 16px;
            vertical-align: middle;
          }
          .text {
            font-size: 20px;
          }
          .price {
            color: #e00b24;
            span{
              vertical-align: baseline;
            }
            .big_price {
              font-size: 30px;
            }
          }
          .tip {
            a {
              font-size: 14px;
              color: #666;
              text-decoration: underline;
            }
          }
          #canvas {
            border: 1px solid #000;
            width: 180px;
            height: 180px;
          }
        }
        .lrbox_right {
          width: 17%;
          background-color: #e6e6e6;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .btn {
            width: 108px;
            height: 38px;
            background-color: skyblue;
            margin: 0 auto;
            font-size: 14px;
            color: #fff;
            line-height: 38px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>
