<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="0"></navbar>
        <div class="basicbox">
          <div class="mineinfo">
            <div class="minetitle">
              <div class="title">我的信息</div>
            </div>
            <div class="vipinfos">
              <img :src="baseInfo.avatar" alt="" />
              <div class="infos">
                <div class="name">
                  <span>{{ baseInfo.username }}</span>

                  <span v-if="baseInfo.is_prove == 1" class="show_prove"
                    >已实名认证</span
                  >
                </div>
                <div class="vipbox">
                  <div class="icon icon1 tardiness">&#xe759;</div>
                  <div class="viplevel">您已经是{{ baseInfo.grade_name }}</div>
                  <div class="icon icon2 tardiness">&#xe620;</div>
                  <div class="uplevel" @click="tobuysomething">升级会员</div>
                </div>
                <div class="vipprogress" v-if="show">
                  <div class="progress">
                    <div
                      class="progress-bar__inner"
                      :style="{ width: widthP + 'px' }"
                    ></div>
                  </div>
                  <p>{{ user_next_grade }}：{{ year_buy }}/{{ all_bay }}</p>
                </div>
              </div>
            </div>

            <div v-if="!infoedit">
              <div class="nikenamerow">
                <div class="rowtitle">我的昵称</div>
                <div class="rowvalue">{{ baseInfo.nickname }}</div>
                <div class="editbtn" @click="infoedit = true">
                  <div class="icon tardiness">&#xe636;</div>
                  <div class="edittext">编辑</div>
                </div>
              </div>
              <div class="nikenamerow">
                <div class="rowtitle">用户名</div>
                <div class="rowvalue">{{ baseInfo.username }}</div>
              </div>
              <div class="nikenamerow">
                <div class="rowtitle">生日</div>
                <div class="rowvalue">{{ baseInfo.birthday }}</div>
              </div>
              <div class="nikenamerow">
                <div class="rowtitle">性 别</div>
                <div class="rowvalue">
                  {{
                    baseInfo.gender == 0
                      ? "未知"
                      : baseInfo.gender == 1
                      ? "男"
                      : "女"
                  }}
                </div>
              </div>
              <div class="nikenamerow">
                <div class="rowtitle">客户ID</div>
                <div class="rowvalue">{{ baseInfo.user_no }}</div>
              </div>
              <div class="nikenamerow">
                <div class="rowtitle">手 机 号</div>
                <div class="rowvalue">{{ baseInfo.mobile }}</div>
              </div>
            </div>

            <div v-else>
              <div class="editname">
                <div class="edititle">用户名</div>
                <el-input
                  v-model="username"
                  placeholder="请输入内容"
                  class="elminput"
                  disabled
                ></el-input>
              </div>
              <div class="editname">
                <div class="edititle">我的昵称</div>
                <el-input
                  v-model="nickname"
                  placeholder="请输入内容"
                  class="elminput"
                  clearable
                ></el-input>
              </div>
              <div class="editphone">
                <div class="edititle">生日</div>
                <el-date-picker
                  v-model="birthday"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  class="elminput"
                  :disabled="disabled"
                >
                </el-date-picker>
              </div>
              <div class="editsex">
                <div class="edititle">性别</div>
                <div
                  class="boys"
                  :class="gender == 1 ? 'on' : ''"
                  @click="gender = 1"
                >
                  男
                </div>
                <div
                  class="girls"
                  :class="gender == 2 ? 'on' : ''"
                  @click="gender = 2"
                >
                  女
                </div>
                <div
                  class="girls"
                  :class="gender == 0 ? 'on' : ''"
                  @click="gender = 0"
                >
                  其他
                </div>
              </div>
              <div class="editbottom">
                <div class="editID">
                  <div class="edititle">客户ID</div>
                  <el-input
                    v-model="baseInfo.user_no"
                    placeholder="请输入客户ID"
                    class="elminput"
                    :disabled="true"
                  ></el-input>
                </div>
                <div class="editphone">
                  <div class="edititle">手机号</div>
                  <el-input
                    v-model="baseInfo.mobile"
                    placeholder="请输入手机号"
                    class="elminput"
                    maxlength="11"
                    type="tel"
                    :disabled="true"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="savebtn" v-if="infoedit" @click="mainfochange">保存</div>
          <div class="basicinfo">
            <div class="minetitle">
              <div class="title">基础信息</div>
            </div>

            <div v-if="!basicedit">
              <div class="nikenamerow">
                <div class="rowtitle">真实姓名</div>
                <div class="rowvalue">{{ baseInfo.real_name || "" }}</div>
                <div
                  class="editbtn"
                  v-if="baseInfo.is_prove == 2"
                  @click="basicedit = true"
                >
                  <div class="icon tardiness">&#xe636;</div>
                  <div class="edittext">编辑</div>
                </div>
              </div>
              <div class="nikenamerow" v-if="baseInfo.is_prove == 2">
                <div class="rowtitle">实名认证</div>
                <div class="rowvalue">
                  {{ baseInfo.is_prove == 1 ? "已认证" : "未认证" }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="editbottom">
                <div class="editID">
                  <div class="edititle">真实姓名</div>
                  <el-input
                    v-model="real_name"
                    placeholder="请输入真实姓名"
                    class="elminput"
                  ></el-input>
                </div>
              </div>
              <div class="editbottom">
                <div class="editID">
                  <div class="edititle">身份证号</div>
                  <el-input
                    v-model="id_card"
                    placeholder="请输入身份证号"
                    class="elminput"
                    clearable
                  ></el-input>
                </div>
                <div class="metions">
                  订购人的实名信息，需与“支付账号实名信息”、“收货人姓名信息”一致。
                </div>
              </div>
            </div>
          </div>
          <div class="savebtn" v-if="basicedit" @click="idcheck">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      infoedit: false,
      basicedit: false,
      baseInfo: {},
      birthday: "",
      gender: null,
      username: "",
      nickname: "",
      real_name: "",
      id_card: "",
      disabled: false,

      user_next_grade: "",
      year_buy: "",
      all_bay: "",
      widthP: 0,
      show: false,
      loading2: false,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$myAjax("user/info", "get", {}, (res) => {
        this.username = res.username;
        this.nickname = res.nickname;
        this.gender = res.gender;
        this.baseInfo = res;
        if (this.baseInfo.birthday) {
          this.disabled = true;
        }
        this.birthday = res.birthday;
        this.real_name = res.real_name;
        this.id_card = res.id_card;

        this.show = !Array.isArray(this.baseInfo.next_grade);
        if (this.show) {
          this.user_next_grade = this.baseInfo.next_grade.name;
          this.year_buy = this.baseInfo.year_buy;
          if (this.baseInfo.is_first_year == 1) {
            this.widthP =
              this.baseInfo.year_buy /
              this.baseInfo.next_grade.rise_fyear_total;
            this.all_bay = this.baseInfo.next_grade.rise_fyear_total;
          } else if (this.baseInfo.is_first_year == 2) {
            this.widthP =
              this.baseInfo.year_buy /
              this.baseInfo.next_grade.rise_syear_total;
            this.all_bay = this.baseInfo.next_grade.rise_syear_total;
          }
        }
      });
    },
    tobuysomething() {
      this.$router.push({ name: "VipCenter" });
    },
    mainfochange() {
      this.$myAjax(
        "user/profile",
        "post",
        {
          nickname: this.nickname,
          gender: this.gender,
          birthday: this.disabled ? null : this.birthday,
        },
        () => {
          this.infoedit = false;
          this.getInfo();
        }
      );
    },
    idcheck() {
      if (this.real_name == "" || this.real_name == null) {
        this.$message({
          type: "warning",
          message: "请输入真实姓名",
          offset: 170,
        });
      } else if (this.id_card == "" || this.id_card == null) {
        this.$message({
          type: "warning",
          message: "请输入身份证号码",
          offset: 170,
        });
      } else {
        if (this.loading2) {
          return false;
        }
        this.loading2 = true;
        this.$myAjax(
          "user/prove",
          "post",
          {
            real_name: this.real_name,
            id_card: this.id_card,
          },
          (res) => {
            this.loading2 = false;
            if (res.code == 1) {
              this.basicedit = false;
              this.getInfo();
            } else {
              this.$alert(res.msg, "MIKI HOUSE提示您", {
                confirmButtonText: "确定",
              });
            }
          },
          true
        );
      }
    },
    changePhone() {
      if (this.phone) {
        this.$myAjax(
          "sms/send",
          "post",
          {
            mobile: this.ruleForm.phone,
            event: "profile",
          },
          () => {
            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
              offset: 170,
            });
            this.$prompt("请输入验证码", "验证码已发送至您的手机", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /\S/,
              inputErrorMessage: "请输入验证码",
            }).then(({ value }) => {
              if (value) {
                this.$myAjax(
                  "account/register",
                  "post",
                  {
                    mobile: this.phone,
                    captcha: value,
                  },
                  () => {
                    this.$message({
                      type: "success",
                      message: "修改成功",
                      offset: 170,
                    });
                  }
                );
              }
            });
          }
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  min-height: 1030px;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 33px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
  }
  .basicbox {
    margin-left: 21px;
    // width: 70%;
    display: inline-block;
    float: left;
    .mineinfo {
      width: 100%;
      padding-bottom: 50px;
      background: #fff;
      float: left;
      .minetitle {
        display: inline-block;
        height: 83px;
        line-height: 83px;
        float: left;
        border-bottom: 1px solid #e6e6e6;
        width: 100%;
        .title {
          margin-left: 34px;
          color: #000;
          font-size: 20px;
          span {
            margin-left: 12px;
            font-size: 14px;
            color: #666666;
          }
        }
      }
      .vipinfos {
        padding: 33px 0;
        border-bottom: 1px solid #e6e6e6;
        float: left;
        width: 100%;
        img {
          width: 91px;
          height: 91px;
          border-radius: 50%;
          margin-left: 37px;
          float: left;
        }
        .infos {
          margin-left: 21px;
          float: left;
          width: 310px;
          .name {
            color: #000000;
            font-size: 20px;
            margin-top: 7px;
            span {
              vertical-align: middle;
            }
            .show_prove {
              padding: 5px 10px;
              border: 1px solid #e00b24;
              color: #e00b24;
              font-weight: 400;
              margin-left: 15px;
              font-size: 15px;
            }
          }
          .vipbox {
            margin-top: 22px;
            width: 100%;
            height: 32px;
            line-height: 32px;
            background: linear-gradient(#efefef 0%, #f9f9f9 100%);
            border-radius: 4px;
            padding: 0 14px;
            cursor: pointer;
            .icon1 {
              color: #999aaa;
              float: left;
            }
            .viplevel {
              margin-left: 9px;
              color: #999aaa;
              font-size: 14px;
              float: left;
            }
            .uplevel {
              float: right;
              color: #e90112;
              font-size: 14px;
              margin-right: 5px;
            }
            .icon2 {
              color: #e60010;
              float: right;
              font-size: 12px;
              font-weight: bold;
            }
          }
          .vipprogress {
            margin-top: 22px;
            // display: none;
            .progress {
              height: 4px;
              background-color: #f0f0f0;
              border-radius: 4px;
              position: relative;
              .progress-bar__inner {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 50%;
                border-radius: 4px;
                background: #e00b24;
              }
            }
            p {
              font-size: 12px;
              margin-top: 6px;
            }
          }
        }
      }
      .nikenamerow {
        padding: 0 44px;
        margin-top: 20px;
        float: left;
        width: 100%;
        .rowtitle {
          color: #000000;
          font-size: 14px;
          float: left;
          text-align: justify;
          width: 60px;
          &:after {
            content: ".";
            display: inline-block;
            width: 100%;
            overflow: hidden;
            height: 0;
          }
        }
        .rowvalue {
          margin-left: 52px;
          color: #000000;
          font-size: 14px;
          float: left;
        }
        .editbtn {
          float: right;
          cursor: pointer;
          .icon {
            font-size: 19px;
            color: #e50014;
            float: left;
          }
          .edittext {
            margin-left: 5px;
            color: #e50014;
            font-size: 14px;
            float: left;
          }
        }
      }
      .editname {
        width: 513px;
        display: inline-block;
        float: left;
        margin-top: 23px;
        padding: 0 44px;
        .edititle {
          font-size: 14px;
          color: #000000;
        }
        .elminput {
          margin-top: 16px;
          width: 100%;
        }
      }
      .editbottom {
        display: inline-block;
        float: left;
        width: 100%;
        .editID {
          width: 513px;
          float: left;
          padding: 0 44px;
          margin-top: 30px;
          .edititle {
            font-size: 14px;
            color: #000000;
          }
          .elminput {
            margin-top: 16px;
            width: 100%;
          }
        }
      }
      .editsex {
        float: left;
        padding: 0 44px;
        margin-top: 30px;
        .edititle {
          font-size: 14px;
          color: #000000;
        }
        .boys {
          float: left;
          margin-top: 16px;
          width: 127px;
          height: 40px;
          border: 1px solid #e6e6e6;
          text-align: center;
          line-height: 40px;
          color: #000000;
          font-size: 14px;
          cursor: pointer;
        }
        .girls {
          float: left;
          margin-top: 16px;
          margin-left: 21px;
          width: 127px;
          height: 40px;
          border: 1px solid #e6e6e6;
          text-align: center;
          line-height: 40px;
          color: #000000;
          font-size: 14px;
          cursor: pointer;
        }
        .on {
          border: 1px solid #e50110;
          color: #e50110;
        }
      }
      .editphone {
        width: 513px;
        float: left;
        padding: 0 44px;
        margin-top: 30px;
        .edititle {
          font-size: 14px;
          color: #000000;
        }
        .elminput {
          margin-top: 16px;
          width: 100%;
        }
      }
    }
    .basicinfo {
      width: 100%;
      // height: 406px;
      padding-bottom: 50px;
      background: #fff;
      float: left;
      margin-top: 20px;
      .minetitle {
        display: inline-block;
        height: 83px;
        line-height: 83px;
        float: left;
        border-bottom: 1px solid #e6e6e6;
        width: 100%;
        .title {
          margin-left: 34px;
          color: #000;
          font-size: 20px;
          span {
            margin-left: 12px;
            font-size: 14px;
            color: #666666;
          }
        }
      }
      .nikenamerow {
        padding: 0 44px;
        margin-top: 20px;
        float: left;
        width: 100%;
        .rowtitle {
          color: #000000;
          font-size: 14px;
          float: left;
          text-align: justify;
          width: 60px;
          &:after {
            content: ".";
            display: inline-block;
            width: 100%;
            overflow: hidden;
            height: 0;
          }
        }
        .rowvalue {
          margin-left: 52px;
          color: #000000;
          font-size: 14px;
          float: left;
        }
        .editbtn {
          float: right;
          cursor: pointer;
          .icon {
            font-size: 19px;
            color: #e50014;
            float: left;
          }
          .edittext {
            margin-left: 5px;
            color: #e50014;
            font-size: 14px;
            float: left;
          }
        }
      }
      .editbottom {
        display: inline-block;
        float: left;
        width: 100%;
        .editID {
          width: 513px;
          float: left;
          padding: 0 44px;
          margin-top: 30px;
          .edititle {
            font-size: 14px;
            color: #000000;
          }
          .elminput {
            margin-top: 16px;
            width: 100%;
          }
        }
        .editphone {
          width: 513px;
          float: left;
          padding: 0 44px;
          margin-top: 30px;
          .edititle {
            font-size: 14px;
            color: #000000;
          }
          .elminput {
            margin-top: 16px;
            width: 100%;
          }
        }
        .metions {
          margin-top: 75px;
          margin-left: 41px;
          color: #e70013;
          font-size: 14px;
          float: left;
        }
      }
    }
    .savebtn {
      float: right;
      margin-top: 36px;
      width: 168px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: #e60012;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>
