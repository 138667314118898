<template>
  <div class="suitList">
    <div class="mw">
      <div class="back" @click="backto()">
        <i class="icon">&#xe601;</i> 返回
      </div>
      <div class="cometline" v-for="(item, key) in listdata" :key="key">
        <div class="baseinfo">
          <img :src="item.avatar" alt="头像" />
          <span>{{ item.nickname }}</span>
        </div>
        <div class="contents">
          <el-rate
            v-model="item.star"
            disabled
            :colors="colors"
            disabled-void-color="#999"
          ></el-rate>
          <p class="texts">
            {{ item.content }}
          </p>
          <div class="images">
            <img v-for="(img, k) in item.images" :key="k" :src="img" alt="" />
          </div>
          <p class="des">
            <span>{{ item.spec_value }}</span>
            <span class="time">{{ item.createtime }}</span>
          </p>
        </div>
      </div>
      <div v-if="listdata.length">
        <div class="more-goods" v-if="page < total_page" @click="loadmore">
          发现更多
        </div>
        <div class="nomore" v-else>没有更多了</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: 4,
      colors: ["#e50111", "#e50111", "#e50111"],
      listdata: [],
      total_page: 1,
      page: 1,
      goods_id: 0,
    };
  },
  mounted() {
    this.goods_id = this.$route.query.goods_id;
    this.getList();
  },
  methods: {
    backto() {
      this.$router.go(-1);
    },
    getList() {
      let { page, goods_id } = { ...this };
      this.$myAjax(
        "goods/goods_comment_list",
        "get",
        { page, goods_id },
        (res) => {
          this.listdata = res.list;
          this.total_page = res.total_page;
        }
      );
    },
    loadmore() {
      this.page++;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.suitList {
  min-height: 800px;
  padding-bottom: 27px;
  margin-top: 140px;
  .back {
    padding: 34px 0 40px 15px;
    cursor: pointer;
    font-size: 14px;
    .icon {
      font-size: 14px;
    }
  }
  .cometline {
    margin-top: 30px;
    padding-bottom: 27px;
    border-bottom: 1px solid #ededed;
    display: flex;
    flex-direction: row;
    .baseinfo {
      display: flex;
      flex-direction: row;
      width: 200px;
      img {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }
      span {
        margin-left: 14px;
        font-size: 16px;
        color: #000;
        line-height: 52px;
      }
    }
    .contents {
      flex: 1;
      display: flex;
      flex-direction: column;
      .texts {
        margin-top: 20px;
        font-size: 16px;
        color: #000000;
      }
      .images {
        margin-top: 7px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        img {
          width: 110px;
          height: 110px;
          margin: 16px 16px 0 0;
          cursor: pointer;
        }
      }
      .des {
        margin-top: 25px;
        span {
          color: #333333;
          font-size: 14px;
        }
        .time {
          margin-left: 55px;
        }
      }
    }
  }
  .nomore {
    width: 100%;
    padding: 30px 0;
    font-size: 16px;
    color: #666666;
    text-align: center;
    float: left;
  }
  .more-goods {
    text-align: center;
    border: 1px solid #f00;
    width: 153px;
    height: 45px;
    line-height: 45px;
    margin: 30px auto 0 auto;
    color: red;
    cursor: pointer;
  }
}
</style>
