<template>
    <div class="sifting">
        <div class="classChoose">
            <div class="classChoose-title">
                连体衣
            </div>
            <div class="classChoose-cotent">
                全部商品
            </div>
            <div class="classChoose-cotent">
                长袖连体衣
            </div>
            <div class="classChoose-cotent">
                短袖连体衣
            </div>
            <div class="classChoose-cotent">
                连体衣外套
            </div>
        </div>
         <div class="classChoose">
            <div class="classChoose-title">
                连体衣
            </div>
            <div class="classChoose-cotent">
                全部商品
            </div>
            <div class="classChoose-cotent">
                长袖连体衣
            </div>
            <div class="classChoose-cotent">
                短袖连体衣
            </div>
            <div class="classChoose-cotent">
                连体衣外套
            </div>
        </div> <div class="classChoose">
            <div class="classChoose-title">
                连体衣
            </div>
            <div class="classChoose-cotent">
                全部商品
            </div>
            <div class="classChoose-cotent">
                长袖连体衣
            </div>
            <div class="classChoose-cotent">
                短袖连体衣
            </div>
            <div class="classChoose-cotent">
                连体衣外套
            </div>
        </div> <div class="classChoose">
            <div class="classChoose-title">
                连体衣
            </div>
            <div class="classChoose-cotent">
                全部商品
            </div>
            <div class="classChoose-cotent">
                长袖连体衣
            </div>
            <div class="classChoose-cotent">
                短袖连体衣
            </div>
            <div class="classChoose-cotent">
                连体衣外套
            </div>
        </div> <div class="classChoose">
            <div class="classChoose-title">
                连体衣
            </div>
            <div class="classChoose-cotent">
                全部商品
            </div>
            <div class="classChoose-cotent">
                长袖连体衣
            </div>
            <div class="classChoose-cotent">
                短袖连体衣
            </div>
            <div class="classChoose-cotent">
                连体衣外套
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: [],
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.sifting{
    display: flex;
    justify-content:space-around;
    .classChoose{
        .classChoose-title{
            font-size: 14px;
            font-weight: 700;
            color: #000000;
            margin-bottom: 20px;
        }
        .classChoose-cotent{
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
}
</style>
