<template>
  <div class="NonMember mwframe">
    <!-- banner -->
    <div class="banner">
      <el-carousel trigger="click" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(v, i) in baseData.user_banner" :key="i">
          <img :src="v.image" :alt="v.title" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--会员详解 -->
    <div class="W">
      <!-- 如何成为会员 -->
      <div class="becomeMember">
        <div class="becomeMember-title">如何成为会员</div>
        <div class="becomeMember_box">
          <div
            class="card"
            v-for="(item, index) in baseData.grade_list"
            :key="index"
          >
            <div class="card_img">
              <img :src="item.image" :alt="item.name" />
            </div>
            <div class="card_name">{{ item.name }}</div>
            <div class="card_condition">
              <!-- 【绑定会员卡即可】 -->
              {{ item.desc }}
              <!-- <p>终生有效</p> -->
            </div>
            <!-- <div class="card_btn">
              去绑定
              <i class="icon">&#xe620;</i>
            </div> -->
          </div>
          <!-- <div class="card">
            <div class="card_img">
              <img src="../../assets/img/nonMember/22.png" alt="11" />
            </div>
            <div class="card_name">银牌会员</div>
            <div class="card_condition">
              店铺内消费金额
              <p>满2000元</p>
            </div>
            <div class="card_btn">
              去购买
              <i class="icon">&#xe620;</i>
            </div>
          </div> -->
          <!-- <div class="card">
            <div class="card_img">
              <img src="../../assets/img/nonMember/33.png" alt="11" />
            </div>
            <div class="card_name">金牌会员</div>
            <div class="card_condition">
              店铺内消费金额
              <p>满5000元</p>
            </div>
            <div class="card_btn">
              去购买
              <i class="icon">&#xe620;</i>
            </div>
          </div> -->
          <!-- <div class="card">
            <div class="card_img">
              <img src="../../assets/img/nonMember/44.png" alt="11" />
            </div>
            <div class="card_name">钻石会员</div>
            <div class="card_condition">
              店铺内消费金额
              <p>满10000元</p>
            </div>
            <div class="card_btn">
              去购买
              <i class="icon">&#xe620;</i>
            </div>
          </div> -->
        </div>
        <div class="bevip" @click="tologin">成为会员</div>
        <div class="becomeMember_desc" v-html="baseData.grade_desc">
          <!-- <p>* 铜牌会员终身有效</p>
          <p>* 银牌、金牌、钻石会员365天内未有成功交易时，将自动下降一个等级</p>
          <p>*会员等级不同可享受不同等的折扣</p> -->
        </div>
      </div>
      <!-- 生日臻宠礼遇 -->
      <div class="birthday">
        <div class="birthday_title">生日臻宠礼遇</div>
        <div class="birthday_box">
          <div class="birthday_box_step">
            <div class="step">
              <div class="icont">
                <i class="icon">&#xe757;</i>
              </div>
              <div class="text">登录填写生日信息</div>
              <div class="bz">(登录后不可修改)</div>
            </div>
            <div class="s"></div>
            <div class="step">
              <div class="icont">
                <i class="icon">&#xe75b;</i>
              </div>
              <div class="text">可获得生日券</div>
              <div class="bz">(系统发放)</div>
            </div>
          </div>
          <div class="birthday_box_Coupon">
            <img :src="baseData.user_gift_img" alt="" />
            <!-- <div class="title_CN">生日券</div>
            <div class="title_EN">HAPPY BIRTHDY</div>
            <div class="coupons">
              <div class="coupons_coupon">
                <p class="amount"><span>￥</span>5</p>
                <p>普通会员领取</p>
                <p>无门槛使用</p>
              </div>
              <div>/</div>
              <div class="coupons_coupon">
                <p class="amount"><span>￥</span>5</p>
                <p>普通会员领取</p>
                <p>无门槛使用</p>
              </div>
              <div>/</div>
              <div class="coupons_coupon">
                <p class="amount"><span>￥</span>5</p>
                <p>普通会员领取</p>
                <p>无门槛使用</p>
              </div>
              <div>/</div>
              <div class="coupons_coupon">
                <p class="amount"><span>￥</span>5</p>
                <p>普通会员领取</p>
                <p>无门槛使用</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 会员活动详细章程 -->
      <div class="detalis">
        <div class="detalis_head">会员活动详细章程</div>
        <div v-html="baseData.activity_details"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      baseData: {},
      // 图片父容器高度
      bannerHeight: 646,
      // 浏览器宽度
      screenWidth: 0,
    };
  },
  mounted() {
    this.getdatas();
    // 首次加载时,需要调用一次
    this.screenWidth = window.innerWidth;
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.setSize();
    };
  },
  methods: {
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight =
        (620 / 1920) * this.screenWidth >= 439.16
          ? (620 / 1920) * this.screenWidth
          : 439.16;
    },
    getdatas() {
      this.$myAjax("pc/wrong_user", "get", {}, (res) => {
        console.log(res);
        res.grade_desc = res.grade_desc
          .replace(/\n/g, "<br/>")
          .replace(/ /g, "&nbsp;");
        this.baseData = res;
      });
    },
    tologin() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.NonMember {
      margin-top: 140px;
  .banner {
    width: 100%;
  }
  .W {
    width: 77%;
    margin: auto;
    margin-top: 100px;
    .becomeMember {
      .becomeMember-title {
        font-size: 40px;
        text-align: center;
        margin-bottom: 50px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &::before {
          content: "";
          width: 130px;
          height: 2px;
          background: linear-gradient(90deg, #fff, #bfbfbf);
          margin-right: 28px;
        }
        &::after {
          content: "";
          width: 130px;
          height: 2px;
          background: linear-gradient(90deg, #bfbfbf, #fff);
          margin-left: 28px;
        }
      }
      .becomeMember_box {
        // height: 547px;
        padding: 50px 0;
        background-color: #fff;
        display: flex;
        justify-content: space-around;
        // align-items: center;
        .card {
          > div {
            width: 230px;
            margin: auto;
            text-align: center;
            margin-bottom: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .card_name {
            font-size: 28px;
          }
          .card_condition {
            font-size: 20px;
            p {
              font-size: 20px;
            }
          }
          .card_btn {
            width: 107px;
            height: 34px;
            background-color: #e60012;
            line-height: 34px;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            .icon {
              font-size: 12px;
            }
            &:hover {
              color: #e60012;
              border: 1px solid #e60012;
              background: #fff;
              transition: all 0.5s;
            }
          }
        }
      }
      .bevip {
        width: 340px;
        height: 44px;
        background-color: #e60012;
        line-height: 44px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        text-align: center;
        position: relative;
        left: 50%;
        margin-left: -170px;
        &:hover {
          color: #e60012;
          border: 1px solid #e60012;
          background: #fff;
          transition: all 0.5s;
        }
      }
      .becomeMember_desc {
        font-size: 18px;
        color: #000;
        margin-top: 34px;
        > p {
          margin-bottom: 5px;
        }
      }
    }
    .birthday {
      margin-top: 39px;
      display: flex;
      flex-direction: column;
      .birthday_title {
        text-align: center;
        font-size: 40px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &::before {
          content: "";
          width: 130px;
          height: 2px;
          background: linear-gradient(90deg, #fff, #bfbfbf);
          margin-right: 28px;
        }
        &::after {
          content: "";
          width: 130px;
          height: 2px;
          background: linear-gradient(90deg, #bfbfbf, #fff);
          margin-left: 28px;
        }
      }
      .birthday_box {
        height: 569px;
        background-color: #fff;
        margin-top: 54px;
      }
      .birthday_box_step {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        .s {
          width: 32%;
          border: 1px solid #f6dfe0;
          margin-top: 30px;
        }
        .step {
          text-align: center;
          .icont {
            i {
              font-size: 45px;
            }
          }
          .text {
            font-size: 26px;
            border: 1px solid #f6dfe0;
            height: 57px;
            line-height: 57px;
            margin: 20px 0;
            text-align: center;
            width: 285px;
          }
          .bz {
            font-size: 14px;
          }
        }
      }
      .birthday_box_Coupon {
        width: 84.6%;
        height: 270px;
        margin: auto;
        margin-top: 30px;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
        }
        .title_CN {
          padding: 20px 0;
          font-size: 24px;
        }
        .title_EN {
          letter-spacing: 27px;
        }
        .coupons {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 40px;
          p.amount {
            font-size: 50px;
            span {
              font-size: 24px;
            }
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
    .detalis {
      padding-top: 53px;
      .detalis_head {
        font-size: 28px;
        text-align: center;
        margin-bottom: 53px;
      }
      .detalis_desc {
        .desc_title {
          font-size: 22px;
          margin-bottom: 20px;
        }
        .desc_desc {
          margin-bottom: 57px;
          p {
            font-size: 16px;
          }
        }
        .table {
          table {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            tr {
              border: 1px solid #bbbcbc;
              height: 70px;
              th {
                border: 1px solid #bbbcbc;
                width: 100px;
              }
              td {
                text-align: center;
                border: 1px solid #bbbcbc;
              }
            }
          }
        }
      }
    }
  }
}
</style>
