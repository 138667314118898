<template>
  <div class="suitDetails mwframe">
    <div class="mw">
      <div class="back" @click="backto()">
        <i class="icon">&#xe601;</i> 返回
      </div>
      <div class="coponent">
        <goodsLeft :goods="menu" style="margin-right: 20px"></goodsLeft>
        <goodsRight
          :payWay="payWay"
          :deliveryInfo="deliveryInfo"
          :returnExchangeService="returnExchangeService"
        >
          <template v-slot:goods_head>
            <div class="suit">
              <div class="suit_title">{{ menu.title }}</div>
              <div class="suit_info">已有{{ menu.sales }}人购买</div>
              <div class="suit_price">
                ￥<span class="newPrice">{{ menu.price }}</span>
              </div>
              <div class="suit_btn">
                <div class="buy" @click="ishow = true">选择组合项</div>
              </div>
            </div>
          </template>
        </goodsRight>
      </div>
      <!-- <goodsBottom></goodsBottom> -->
    </div>
    <!-- 套装组合窗口 -->
    <suitBox
      :show="ishow"
      :chooseData="chooseData"
      @chooseChange="chooseChange"
      @addcar="addshoppingcar"
      @justbuy="limitbuy"
    ></suitBox>
  </div>
</template>
<script>
import goodsLeft from "../../components/goodsDetail/goodsDetailLeft";
import goodsRight from "../../components/goodsDetail/goodsDetailRight";
// import goodsBottom from "../../components/goodsDetail/goodsDetailBottom";
import suitBox from "../../components/cart/suitBox";

export default {
  components: {
    goodsLeft,
    goodsRight,
    // goodsBottom,
    suitBox,
  },
  data() {
    return {
      ishow: false,
      meal_id: "",
      productGood: {}, //产品特性
      payWay: {}, //支付方式
      deliveryInfo: {}, //配送方式
      returnExchangeService: {}, //退换货服务
      menu: {}, //套装数据
      chooseData: [], //组合选择数组
      userid: "",
    };
  },
  mounted() {
    this.meal_id = this.$route.query.id;
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
    }
    this.getDatabase();
    this.getchoose();
  },
  methods: {
    getDatabase() {
      this.$myAjax(
        "goods/menu_details?menu_id=" +
          this.meal_id +
          "&identity_id=" +
          this.userid,
        "get",
        {},
        (res) => {
          this.productGood = res.product_good;
          this.payWay = res.pay_way;
          this.deliveryInfo = res.delivery_info;
          this.returnExchangeService = res.return_exchange_service;
          this.menu = res.menu;
        }
      );
    },
    backto() {
      this.$router.go(-1);
    },
    getchoose() {
      this.$myAjax(
        "goods/menu_group?menu_id=" + this.meal_id,
        "get",
        {},
        (res) => {
          res.map((item) => {
            item.list.map((btem) => {
              btem.checked = false;
            });
          });
          this.chooseData = res;
        }
      );
    },
    closepop() {
      this.ishow = false;
    },
    chooseChange(json) {
      this.chooseData[json.key].list.map((item) => {
        item.checked = false;
      });
      this.chooseData[json.key].list[json.index].checked = true;
    },
    addshoppingcar() {
      let _sku = [];
      this.chooseData.map((item) => {
        item.list.map((btem) => {
          if (btem.checked) {
            let string = btem.goods_id + "-" + btem.sku_unique;
            _sku.push(string);
          }
        });
      });
      if (_sku.length < this.chooseData.length) {
        this.$message({
          message: "您还有未选择的内容",
          type: "warning",
          offset:170
        });
      } else {
        this.$myAjax(
          "car/add_car",
          "post",
          {
            goods_id: this.meal_id,
            type: 2,
            goods_num: 1,
            sku_unique: _sku.toString(),
          },
          () => {
            this.$message({
              message: "已添加购物车",
              type: "success",
              offset:170
            });
            this.closepop();
          }
        );
      }
    },
    limitbuy() {
      let _sku = [];
      this.chooseData.map((item) => {
        item.list.map((btem) => {
          if (btem.checked) {
            let string = btem.goods_id + "-" + btem.sku_unique;
            _sku.push(string);
          }
        });
      });
      if (_sku.length < this.chooseData.length) {
        this.$message({
          message: "您还有未选择的内容",
          type: "warning",
          offset:170
        });
      } else {
        this.$myAjax(
          "order/confirm_order",
          "post",
          {
            goods_id: this.meal_id,
            type: 2,
            goods_num: 1,
            sku_unique: _sku.toString(),
          },
          (res) => {
            this.closepop();
            this.closepop();
            sessionStorage.setItem("orderDetail", JSON.stringify(res));
            this.$router.push({ name: "confirmOrder" });
          }
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.suitDetails {
      margin-top: 140px;
  .back {
    padding: 34px 0 40px 15px;
    cursor: pointer;
    font-size: 14px;
    .icon {
      font-size: 14px;
    }
  }
  .coponent {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .suit {
      .suit_title {
        font-size: 28px;
        color: #222;
        margin-top: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .suit_info {
        font-size: 14px;
        color: #999;
        margin-top: 13px;
        margin-bottom: 28px;
      }
      .suit_price {
        .newPrice {
          font-size: 26px;
          font-weight: 700;
          color: #000;
        }
        .oldPrice {
          color: #999;
          text-decoration: line-through;
          margin-left: 16px;
        }
      }
      .suit_btn {
        display: flex;
        align-items: center;
        margin: 23px 0;
        .buy {
          width: 100%;
          height: 45px;
          background-color: red;
          color: #fff;
          text-align: center;
          line-height: 45px;
          cursor: pointer;
          border-radius: 4px;
        }
        div.icon {
          font-size: 26px;
          margin-left: 20px;
          color: #666666;
          cursor: pointer;
        }
      }
      .suit_table {
        margin-bottom: 30px;
        table {
          text-align: center;
          font-size: 13px;
          color: #111111;
          tr {
            border: 1px solid #e6e6e6;
            height: 55px;
            td {
              border: 1px solid #e6e6e6;
              width: 102px;
              height: 55px;
            }
            .usebuy {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: none;
              img {
                width: 34px;
                height: 34px;
              }
            }
          }
          .titles {
            height: 45px;
          }
        }
      }
    }
  }
}
</style>
