<template>
  <div class="couponCenter mwframe">
    <!-- banner -->
    <!-- <div class="banner"></div> -->
    <img class="banner" :src="backimgData.image" :alt="backimgData.title" />
    <!-- 优惠券 -->
    <div class="coupons mw">
      <div v-for="(item, index) in coupsData" :key="index">
        <div
          class="coupon_card"
          :class="item.receive_status == 1 ? 'used' : 'unused'"
        >
          <div class="amount">
            <p>
              {{ item.coupon_type == 1 ? "￥" : "折" }}
              <span>{{ item.discount }}</span>
            </p>
            <p>满{{ item.condition }}可用</p>
          </div>
          <div class="desc">
            <div class="desc_limit">
              <span class="seal">{{
                item.coupon_range == 1
                  ? "通用券"
                  : item.coupon_range == 2
                  ? "分类券"
                  : "商品券"
              }}</span>
              {{ item.coupon_sn }}
            </div>
            <div class="desc_time" v-if="item.receive_status == 1">
              <template v-if="item.use_time_type == 1">
                有效期:{{ item.use_start_time }} - {{ item.use_end_time }}
              </template>
              <template v-else>
                有效期:领取后{{ item.after_receive_day }}天内可用
              </template>
            </div>
            <div class="desc_rules">
              <p class="title">使用规则</p>
              <div v-html="item.use_rule"  ></div>
              <!-- <p>1.优惠券仅限在有效期内使用，过期则无法使用；</p>
              <p>
                2.少量特殊商品不能使用优惠券（有些优惠券标注全场通用则可以使用）；
              </p>
              <p>3.一张订单只能使用一张优惠券，优惠券不可合并；</p> -->
            </div>
          </div>
          <div class="isused" @click="getit(index)">
            <p>{{ item.receive_status == 1 ? "立即使用" : "立即领取" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="more-goods" v-if="page < totalPage" @click="loadmore">
      发现更多
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      backimgData: {},
      // 浏览器宽度
      screenWidth: 0,
      userid: "",
      page: 1,
      totalPage: 0,
      coupsData: [],
      loading2: false,
    };
  },
  mounted() {
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
    }
    this.getcoupsbanner();
    this.getcoupsList();
  },
  methods: {
    getcoupsbanner() {
      this.$myAjax("pc/coupon_banner", "get", {}, (res) => {
        this.backimgData = res[0];
      });
    },
    getcoupsList() {
      this.$myAjax(
        "coupon/coupon_list?identity_id=" + this.userid + "&page=" + this.page,
        "get",
        {},
        (res) => {
          this.coupsData = this.coupsData.concat(res.list);
          this.totalPage = res.total_page;
        }
      );
    },
    loadmore() {
      this.page++;
      this.getcoupsList();
    },
    getit(_index) {
      if (this.loading2) {
        return;
      }
      this.loading2 = true;
      if (this.coupsData[_index].receive_status == 2) {
        this.$myAjax(
          "coupon/receive_coupon",
          "post",
          {
            coupon_id: this.coupsData[_index].coupon_id,
          },
          (res) => {
            this.loading2 = false;
            if (res.code == 1) {
              this.$message({
                message: "领取成功",
                type: "success",
                offset: 170,
              });
              this.coupsData[_index].receive_status = 1;
            } else {
              this.$alert(res.msg, "MIKI HOUSE提示您", {
                confirmButtonText: "确定",
              });
            }
          },
          true
        );
      } else {
        this.loading2 = false;
            localStorage.removeItem("page")
        this.$router.push({ name: "seriesDetails" });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.couponCenter {
  margin-top: 140px;
  .banner {
    width: 100%;
  }
  .coupons {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .coupon_card {
      width: 711px;
      height: 186px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      overflow: hidden;
      .amount {
        p {
          font-size: 21px;
          color: #333;
        }
        span {
          font-size: 65px;
          color: #333;
          font-weight: 700;
        }
      }
      .desc {
        overflow-y:auto;
        flex-basis: 260px;
        height: 100%;
        padding: 18px 0;
        .desc_limit {
          margin-bottom: 5px;
          .seal {
            padding: 2.5px 3.5px;
            border: 1px solid red;
            font-size: 11px;
            color: red;
          }
        }
        .desc_time {
          font-size: 14px;
          color: #999;
        }
        .desc_rules {
          margin-top: 10px;
          p.title {
            font-size: 14px;
            color: 000;
          }
          p {
            font-size: 12px;
            color: #666;
          }
        }
      }
      .isused {
        p {
          width: 60%;
          font-size: 28px;
          color: #fff;
          margin: 0 auto;
          text-align: end;
          cursor: pointer;
        }
      }
    }
    .used {
      background: url("../../assets/img/couponCenter/use.png");
    }
    .unused {
      background: url("../../assets/img/couponCenter/Unused.png");
    }
  }
  .more-goods {
    text-align: center;
    border: 1px solid #f00;
    width: 153px;
    height: 45px;
    line-height: 45px;
    margin: 20px auto;
    color: red;
    cursor: pointer;
  }

  .desc::-webkit-scrollbar { width: 0 !important }
  .desc { -ms-overflow-style: none; }
  .desc { overflow: -moz-scrollbars-none; }
}
</style>
