<template>
  <div class="memberNoMerchandise mwframe">
    <div class="Search">
      <img :src="imgData.image" :alt="imgData.title" class="backimg" />
      <div class="Search_text">
        <p>暂无会员权益商品，我们即将为您准备...... 敬请期待!</p>
      </div>
      <div class="Search_search">
        <el-input v-model="seachvalue" placeholder="请输入关键字">
          <el-button
            slot="append"
            icon="el-icon-search"
            @keyup.enter.native="tosearchs"
            @click="tosearchs"
          ></el-button
        ></el-input>
      </div>
      <div class="goods-class-list">
        <div v-for="(item, index) in classifyData" :key="index">
          <p class="titles" @click.stop="toclassify(item.id, item.title)">
            {{ item.title }}
          </p>
          <li
            v-for="(a, i) in item.next"
            :key="i"
            @click.stop="toclassify(a.id, a.title)"
          >
            {{ a.title }}
          </li>
        </div>
      </div>
      <div class="more">查看更多商品<i class="icon">&#xe620;</i></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      seachvalue: "",
      imgData: {},
      classifyData: [],
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.$myAjax("pc/no_user_goods", "get", {}, (res) => {
        console.log(res);
        this.imgData = res.bg_img;
        this.classifyData = res.classify;
      });
    },
    tosearchs() {
      this.appendKeywords(this.seachvalue);
          localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          value: this.seachvalue,
        },
      });
    },
    toclassify(id, title) {
      console.log(id);
          localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          classify_ids: id,
          title: title,
          t: new Date().getTime(),
        },
      });
      // if (link) {
      //   if (link.indexOf("?") > -1) {
      //     link = link + "&t=" + new Date().getTime() + "&nav_id=" + id;
      //   } else {
      //     link = link + "?t=" + new Date().getTime() + "&nav_id=" + id;
      //   }
      //   window.location.href = link;
      // }
    },
    appendKeywords(value) {
      /**
       * 1.已经有的关键词不再添加
       * 2.添加到数组的首位，若超出10个则删除最后一个
       * 3.添加到缓存
       */
      var appendFlag = true;
      if (
        this.searchHistoryList !== null &&
        this.searchHistoryList !== undefined &&
        this.searchHistoryList.length > 0
      ) {
        this.searchHistoryList.forEach(function (currentValue) {
          if (currentValue === value) {
            appendFlag = false;
            return;
          }
        });
        // 判断-添加
        if (appendFlag === true) {
          // 长度判断
          if (this.searchHistoryList.length >= 10) {
            this.searchHistoryList.unshift(value);
            this.searchHistoryList.pop();
          } else {
            this.searchHistoryList.unshift(value);
          }
          localStorage.setItem(
            "historyItems",
            JSON.stringify(this.searchHistoryList)
          );
        }
      } else {
        this.searchHistoryList = [];
        this.searchHistoryList.push(value);
        localStorage.setItem(
          "historyItems",
          JSON.stringify(this.searchHistoryList)
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.memberNoMerchandise {
  margin-top: 140px;
  .Search {
    height: 993px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    .backimg {
      width: 100%;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .Search_text {
      margin-bottom: 100px;
      margin-top: 200px;
      p {
        width: 656px;
        margin: 0 auto;
        font-size: 36px;
        color: #fff;
        text-align: center;
      }
    }
    .Search_search {
      width: 800px;
      margin: 0 auto;
      i {
        float: right;
        position: relative;
        top: 30px;
        right: 30px;
        z-index: 2;
      }
    }
    .goods-class-list {
      width: 50%;
      margin: 0 auto;
      display: flex;
      margin-top: 100px;
      justify-content: space-between;
      > div {
        .titles {
          font-size: 18px;
          color: #fff;
          cursor: pointer;
          margin-bottom: 20px;
        }
        li {
          margin-bottom: 10px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .more {
      text-align: center;
      color: #fff;
      margin-top: 80px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>
