<template>
  <div class="goodsBottom">
    <div class="list" v-if="concatShops.length">
      <div class="list_title">关联商品</div>
      <div class="list_cards swiper-container swiper-container1">
        <div class="swiper-wrapper">
          <div
            class="card swiper-slide"
            v-for="(item, index) in concatShops"
            :key="index"
          >
            <div class="card_img">
              <img
                :src="item.image"
                :alt="item.subtitle"
                :data-id="item.goods_id"
              />
            </div>
            <div class="card_name">{{ item.subtitle }}</div>
            <div class="card_price">￥{{ item.price }}{{item.spec_type==2?'起':''}}</div>
            <div class="trans"></div>
          </div>
        </div>
        <div class="swiper-button-next" @click="next1()">
          <i class="icon tardiness icon-arrow">&#xe62c;</i>
        </div>
        <div class="swiper-button-prev" @click="prev1()">
          <i class="icon tardiness icon-arrow">&#xe62e;</i>
        </div>
      </div>
    </div>
    <div class="list" v-if="ownfoot.length">
      <div class="list_title">浏览足迹</div>
      <div class="list_cards swiper-container swiper-container2">
        <div class="swiper-wrapper">
          <div
            class="card swiper-slide"
            v-for="(item, index) in ownfoot"
            :key="index"
          >
            <div class="card_img">
              <img
                :src="item.image"
                :alt="item.subtitle"
                :data-id="item.goods_id"
              />
            </div>
            <div class="card_name">{{ item.subtitle }}</div>
            <div class="card_price">￥{{ item.price }}{{item.spec_type==2?'起':''}}</div>
            <div class="trans"></div>
          </div>
        </div>
        <div class="swiper-button-next" @click="next2()">
          <i class="icon tardiness icon-arrow">&#xe62c;</i>
        </div>
        <div class="swiper-button-prev" @click="prev2()">
          <i class="icon tardiness icon-arrow">&#xe62e;</i>
        </div>
      </div>
    </div>
    <div class="list" v-if="otherfoot.length">
      <div class="list_title">看过该商品的用户还看过</div>
      <div class="list_cards swiper-container swiper-container3">
        <div class="swiper-wrapper">
          <div
            class="card swiper-slide"
            v-for="(item, index) in otherfoot"
            :key="index"
          >
            <div class="card_img">
              <img
                :src="item.image"
                :alt="item.subtitle"
                :data-id="item.goods_id"
              />
            </div>
            <div class="card_name">{{ item.subtitle }}</div>
            <div class="card_price">￥{{ item.price }}{{item.spec_type==2?'起':''}}</div>
            <div class="trans"></div>
          </div>
        </div>
        <div class="swiper-button-next" @click="next3()">
          <i class="icon tardiness icon-arrow">&#xe62c;</i>
        </div>
        <div class="swiper-button-prev" @click="prev3()">
          <i class="icon tardiness icon-arrow">&#xe62e;</i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "../../../node_modules/swiper/dist/css/swiper.min.css";
import "../../../node_modules/swiper/dist/js/swiper.min";
export default {
  props: {
    ownfoot: Array,
    otherfoot: Array,
    concatShops: Array,
  },
  data() {
    return {
      swiper1: null,
      swiper2: null,
      swiper3: null,
    };
  },
  watch: {
    concatShops() {
      let that = this;
      this.$nextTick(() => {
        this.swiper1 = new Swiper(".swiper-container1", {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 5,
          loop: true,
          loopFillGroupWithBlank: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            click: function (e) {
              const id = e.target.dataset.id;
              if (id) {
                that.toshopdetail(id);
              }
            },
          },
        });
      });
    },
    ownfoot() {
      let that = this;
      this.$nextTick(() => {
        this.swiper2 = new Swiper(".swiper-container2", {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 5,
          loop: true,
          loopFillGroupWithBlank: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            click: function (e) {
              const id = e.target.dataset.id;
              if (id) {
                that.toshopdetail(id);
              }
            },
          },
        });
      });
    },
    otherfoot() {
      let that = this;
      this.$nextTick(() => {
        this.swiper3 = new Swiper(".swiper-container3", {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 5,
          loop: true,
          loopFillGroupWithBlank: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            click: function (e) {
              const id = e.target.dataset.id;
              if (id) {
                that.toshopdetail(id);
              }
            },
          },
        });
      });
    },
  },
  mounted() {},
  methods: {
    toshopdetail(id) {
      this.$router.push({
        name: "flashSaleDetails",
        params:{
          s:window.btoa(`type=1&id=${id}`)
        }
      });
    },
    prev1() {
      this.swiper1.slidePrev();
    },
    next1() {
      this.swiper1.slideNext();
    },
    prev2() {
      this.swiper2.slidePrev();
    },
    next2() {
      this.swiper2.slideNext();
    },
    prev3() {
      this.swiper3.slidePrev();
    },
    next3() {
      this.swiper3.slideNext();
    },
  },
};
</script>
<style lang="less">
.icon-arrow {
  color: #999999;
  font-size: 100px;
  &:hover {
    color: #fa4750;
  }
}
.list {
  .swiper-button-next,
  .swiper-button-prev {
    background-image: unset;
    opacity: 1;
    transition: all 0.5s linear;
    width: auto;
    height: auto;
    top: calc(50% - 70px);
    background: #fafafa;
    top: 0;
    height: 100%;
    &:hover {
      .el-icon-arrow-right,
      .el-icon-arrow-left {
        color: #e60012;
      }
    }
    .icon-arrow {
      position: relative;
      // top: 100px;
      top: calc(50% - 70px);
    }
  }
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next {
    right: 0px;
  }
  .el-icon-arrow-right,
  .el-icon-arrow-left {
    font-size: 30px;
    color: #999;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 5px 2px #f5f7fa;
    border-radius: 50%;
    padding: 5px;
  }
}
</style>
<style lang="less" scoped>
.goodsBottom {
  padding: 18px 0;
  .list {
    .list_title {
      font-size: 24px;
      color: #222;
      text-align: center;
      padding: 50px 0;
    }
    .swiper-container1,
    .swiper-container2,
    .swiper-container3 {
      padding: 0 100px;
      box-sizing: border-box;
      &:hover {
        .swiper-button-next,
        .swiper-button-prev {
          opacity: 1;
        }
      }
      .swiper-wrapper {
      }
    }
    .list_cards {
      display: flex;
      // border-bottom: 1px solid #dbdbdb;
      flex-wrap: wrap;
      // overflow-x: auto;
      // &::-webkit-scrollbar {

      // }
      .card {
        padding: 20px 0;
        text-align: center;
        cursor: pointer;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .card_img {
          padding: 10px;
          background-color: #fff;
          // width: 318px;
          // height: 318px;
          text-align: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .card_name {
          margin-top: 32px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
        .card_price {
          margin-top: 17px;
          font-size: 18px;
          color: #222222;
        }
        .trans {
          width: 0;
          background: #e60012;
          height: 1px;
          position: absolute;
          bottom: 0;
        }
        &:hover {
          .trans {
            width: 100%;
            transition: all 0.5s;
          }
        }
      }
    }
  }
}
</style>
