<template>
  <div>
    <div class="window_page">
      <router-link
        :to="{ name: 'basicinfo' }"
        class="row"
        :class="tag == 0 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe75e;</div>
        <div class="icontext">个人资料</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineBaby' }"
        class="row"
        :class="tag == 1 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe751;</div>
        <div class="icontext">我的宝宝</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineSelect' }"
        class="row"
        :class="tag == 2 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe75d;</div>
        <div class="icontext">我的收藏</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineFoot' }"
        class="row"
        :class="tag == 3 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe75c;</div>
        <div class="icontext">浏览足迹</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineCoups' }"
        class="row"
        :class="tag == 4 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe75b;</div>
        <div class="icontext">我的优惠券</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineScore' }"
        class="row"
        :class="tag == 5 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe75a;</div>
        <div class="icontext">我的积分</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'VipCenter' }"
        class="row"
        :class="tag == 6 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe759;</div>
        <div class="icontext">会员中心</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'SalesCenter' }"
        class="row"
        :class="tag == 7 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe758;</div>
        <div class="icontext">退款售后</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineOrders' }"
        class="row"
        :class="tag == 8 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe757;</div>
        <div class="icontext">我的订单</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'MineComets' }"
        class="row"
        :class="tag == 9 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe756;</div>
        <div class="icontext">我的评价</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'AccountSafe' }"
        class="row"
        :class="tag == 10 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe755;</div>
        <div class="icontext">账号与安全</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'OpinionBack' }"
        class="row"
        :class="tag == 11 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe754;</div>
        <div class="icontext">意见反馈</div>
        <div class="sign"></div>
      </router-link>
      <!-- <router-link
        :to="{ name: 'OnlineServer' }"
        class="row"
        :class="tag == 12 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe753;</div>
        <div class="icontext">在线客服</div>
        <div class="sign"></div>
      </router-link> -->
      <a
        target="_blank"
        href="https://care60.live800.com/live800/chatClient/chatbox.jsp?companyID=80017004&configID=2889"
        class="row"
        :class="tag == 12 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe753;</div>
        <div class="icontext">在线客服</div>
        <div class="sign"></div>
      </a>
      <router-link
        :to="{ name: 'NearbyStore' }"
        class="row"
        :class="tag == 13 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe752;</div>
        <div class="icontext">身边店铺</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'noticecenter' }"
        class="row"
        :class="tag == 14 ? 'rowon' : ''"
      >
        <div class="icon tardiness">&#xe630;</div>
        <div class="icontext">平台通知</div>
        <div class="sign"></div>
      </router-link>
      <router-link
        :to="{ name: 'login', query: { loginout: true } }"
        class="row bordernone"
      >
        <div class="icon tardiness">&#xe631;</div>
        <div class="icontext">退出登录</div>
        <div class="sign"></div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tag: Number,
  },
  data() {
    return {
      outlogin() {},
    };
  },
};
</script>

<style lang="less" scoped>
.window_page {
  width: 307px;
  background: #fff;
  float: left;
  .row {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    height: 49px;
    line-height: 49px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      .icon {
        color: #e80111;
      }
      .icontext {
        color: #e80111;
      }
    }
    .icon {
      margin-left: 31px;
      float: left;
    }
    .icontext {
      color: #333333;
      font-size: 14px;
      margin-left: 13px;
      float: left;
    }
  }
  .rowon {
    & {
      .icon {
        color: #e80111;
      }
      .icontext {
        color: #e80111;
      }
      .sign {
        display: block;
        position: absolute;
        left: 0;
        width: 2px;
        height: 100%;
        background: #e90010;
      }
    }
  }
  .bordernone {
    border: none;
  }
}
</style>
