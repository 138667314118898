<template>
  <div class="paySuccess mwframe">
    <div class="title">谢谢惠顾！</div>
    <div class="W">
      <div class="head">
        <div class="head_title">您已付款成功</div>
        <div class="head_desc">
          <p>如遇问题请拨打客服电话：400-000-0000；028-00000000</p>
          <p>服务时间：周一至周日 9:00-18:00</p>
        </div>
      </div>
      <div class="cotent">
        <p>合计:</p>
        <p>订单号：</p>
        <p>订单日期：</p>
        <div class="s"></div>
        <p class="adress">配送地址</p>
        <p>笑笑</p>
        <p>成都市武侯区武侯大道顺江段77号吾悦广场2座6楼</p>
        <p>18000000000</p>
        <div class="s"></div>
        <p>配送方式</p>
        <p>普通快递</p>
        <div class="s"></div>
        <div class="way">
          <div>付款方式</div>
          <div>
            <img src="../../assets/img/index/sp1.png" alt="sp" />
          </div>
        </div>
        <div class="s"></div>
        <div class="info">
          <div class="info_title">
            商品信息
            <span>3件商品</span>
          </div>
          <div class="s"></div>
          <div class="info_adress">
            <div class="leftbox">
              <img src="../../assets/img/ship.png" alt="sp" />
              <span class="sendwhere">跨境进口(1)</span>
            </div>
            <span class="fr">成都保税仓发货</span>
          </div>
          <div class="info_card">
            <div class="img">
              <img src="../../assets/img/index/sp1.png" alt="ss" />
            </div>
            <div class="goodsInfo">
              <div class="goodsInfo_name">商品名称</div>
              <div class="goodsInfo_lr">
                <div class="left">
                  <div class="left_id">商品编号：8888</div>
                  <div class="left_color">颜色：白色</div>
                  <div class="left_size">尺寸：M</div>
                </div>
                <div class="right">￥500</div>
              </div>
              <div class="goodsInfo_desc">
                <i class="icon tardiness">&#xe602;</i>
                <span>此产品为跨境商品</span>
              </div>
            </div>
          </div>
          <div class="s"></div>
          <div class="info_card">
            <div class="img">
              <img src="../../assets/img/index/sp1.png" alt="ss" />
            </div>
            <div class="goodsInfo">
              <div class="goodsInfo_name">商品名称</div>
              <div class="goodsInfo_lr">
                <div class="left">
                  <div class="left_id">商品编号：8888</div>
                  <div class="left_color">颜色：白色</div>
                  <div class="left_size">尺寸：M</div>
                </div>
                <div class="right">￥500</div>
              </div>
              <div class="goodsInfo_desc">
                <i class="icon tardiness">&#xe602;</i>
                <span>此产品为跨境商品</span>
              </div>
            </div>
          </div>
          <div class="s"></div>
          <div class="expense">
            <div>
              <span>商品金额</span>
              <span>￥1500</span>
            </div>
            <div>
              <span>优惠券</span>
              <span>-￥100</span>
            </div>

            <div>
              <span>活动优惠</span>
              <span>-￥0</span>
            </div>

            <div>
              <span>积分抵扣</span>
              <span>-￥100</span>
            </div>

            <div>
              <span>运费</span>
              <span>免邮</span>
            </div>
          </div>
          <div class="s"></div>
          <div class="sum">
            <span>总计</span>
            <span>￥1300</span>
          </div>
          <div class="btn1">查看订单</div>
          <div class="btn2">继续购物</div>
        </div>
      </div>
      <div class="foot">
        <div class="foot_title">温馨提示</div>
        <div class="foot_desc">
          依据《关于跨境电子商务零售进口税收政策的通知》个人单次交易额上限￥5000，年度交易额累积，上限￥26000。
          如您个人年度跨境累积消费金额达到￥26000(不限于本平 台)
          ,请更换支付人和收件人信息 前往海关官网，查询您的个人年度额度。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.paySuccess {
  margin-top: 140px;
  padding-top: 58px;
  .title {
    text-align: center;
    font-size: 30px;
    color: #111;
  }
  .W {
    width: 850px;
    margin: 0 auto;
    padding: 40px 0;
    .head {
      background-color: #e60012;
      padding: 0 74px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .head_title {
        font-size: 24px;
        color: #fff;
        margin-bottom: 21px;
      }
      .head_desc {
        font-size: 16px;
        color: #fff;
      }
    }
    .cotent {
      padding: 20px 60px;
      padding-bottom: 60px;
      background-color: #fff;
      .s {
        border-top: 1px solid #ccc;
        margin: 15px 0;
      }
      p {
        font-size: 14px;
        color: #111;
        margin-bottom: 10px;
      }
      .adress {
        margin-bottom: 26px;
      }
      .way {
        img {
          width: 163px;
          height: 50px;
          margin-top: 16px;
        }
      }
      .info {
        margin-top: 53px;
        .info_title {
          font-size: 24px;
          span {
            font-size: 16px;
          }
        }
        .info_adress {
          margin-top: 37px;
          margin-bottom: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .leftbox {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 29px;
              height: 29px;
            }
            .sendwhere {
              margin-left: 12px;
              color: #111111;
              font-size: 16px;
            }
          }
          .fr {
            color: #111111;
            font-size: 16px;
          }
        }
        .info_card {
          display: flex;
          .img {
            padding: 10px;
            width: 112px;
            height: 112px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .goodsInfo {
            flex-grow: 1;
            margin-left: 17px;
            .goodsInfo_name {
              font-size: 18px;
              margin-bottom: 14px;
              color: #111;
            }
            .goodsInfo_lr {
              margin: 5px 0;
              display: flex;
              justify-content: space-between;
              .left {
                color: #111;
                font-size: 14px;
              }
              .right {
                padding-top: 40px;
              }
            }
            .goodsInfo_desc {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 10px;
              .icon {
                font-size: 13px;
                color: #999;
                cursor: pointer;
              }
              span {
                margin-left: 8px;
                font-size: 14px;
                color: #999999;
              }
            }
          }
        }
        .expense {
          > div {
            margin-bottom: 12px;
            span:nth-of-type(2) {
              font-weight: bold;
              float: right;
            }
          }
        }
        .sum {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 24px;
          }
          span:nth-of-type(2) {
            font-weight: bold;
            font-size: 18px;
          }
        }
        .btn1 {
          width: 340px;
          height: 46px;
          background-color: #e60012;
          margin: 0 auto;
          text-align: center;
          color: #fff;
          line-height: 46px;
          margin-top: 47px;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .btn2 {
          width: 340px;
          height: 46px;
          margin: 0 auto;
          text-align: center;
          line-height: 46px;
          border: 1px solid #1b1b1b;
          cursor: pointer;
        }
      }
    }
    .foot {
      padding: 30px 60px;
      background-color: #e5e5e5;
      .foot_title {
        font-size: 18px;
        color: #111;
        margin-bottom: 20px;
      }
      .foot_desc {
        font-size: 14px;
        color: #666;
      }
    }
  }
}
</style>
