function setRem () {
    // 设计稿宽度
    const baseWidth = 1920;
    // 窗口宽度
    const clientWidth = document.documentElement.clientWidth
    // scale 缩放比例
    const scale = clientWidth / baseWidth
    document.documentElement.style.fontSize = scale + 'px'
  }


   function formateTimeStamp (timeStamp) {
    var day;
    var hour;
    var min;
    var seconds;
  
    day = parseInt(timeStamp / (24 * 60 * 60)) // 计算整数天数
    var afterDay = timeStamp - day * 24 * 60 * 60 // 取得算出天数后剩余的秒数
    hour = parseInt(afterDay / (60 * 60)) // 计算整数小时数
    var afterHour = timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 // 取得算出小时数后剩余的秒数
    min = parseInt(afterHour / 60) // 计算整数分
    seconds = parseInt(timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数
  
    if (day < 10) {
      day = '0' + day;
    }
  
    if (hour < 10) {
      hour = '0' + hour
    }
  
    if (min < 10) {
      min = '0' + min;
    }
  
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
  
    const restStamp = {
      day: day,
      hour: hour,
      min: min,
      seconds: seconds
    }
    return restStamp
  }

  export { setRem ,formateTimeStamp};
