<template>
  <div class="box mwframe">
    <div class="component mw">
      <siftingStyle>
        <template v-slot:title> 连体衣 </template>
        <template v-slot:desc>
          <p>长袖连体衣</p>
          <p>短袖连体衣</p>
          <p>连体衣外套</p>
        </template>
      </siftingStyle>
      <div><siftingList></siftingList></div>
    </div>
  </div>
</template>
<script>
import siftingStyle from "../../components/Sifting/sifting_style";
import siftingList from "../../components/Sifting/sifting_list";

export default {
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  components: {
    siftingStyle,
    siftingList,
  },
};
</script>
<style lang="less" scoped>
.box {
  background: #fafafa;
  margin-top: 140px;
  .component {
    display: flex;
    justify-content: space-between;
  }
}
</style>
