<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="13"></navbar>
        <div class="mainbox">
          <div class="title">共{{ listdata.length }}家门店</div>
          <div v-if="!listdata.length" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">暂无任何门店！</div>
          </div>
          <div v-else class="scorebox">
            <div class="showbox">
              <div
                class="onerecord"
                v-for="(item, index) in listdata"
                :key="index"
              >
                <img :src="item.image" :alt="item.name" />
                <div class="storeinfo">
                  <div class="name">{{ item.name }}</div>
                  <div class="times">营业时间：{{ item.work_time }}</div>
                  <div class="phone">联系电话：{{ item.tel }}</div>
                  <div class="address">
                    门店地址：{{ item.province }}{{ item.city }}{{ item.area
                    }}{{ item.position }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lessaddress.png"),
      listdata: [],
    };
  },
  mounted() {
    this.getshops();
  },
  methods: {
    getshops() {
      this.$myAjax("store/store_list", "get", {}, (res) => {
        this.listdata = res.list;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      flex: 1;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 20px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .scorebox {
        width: 100%;
        display: inline-block;
        .showbox {
          margin: 0 40px;
          .onerecord {
            padding: 22px 0 26px 0;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            float: left;
            img {
              width: 98px;
              height: 66px;
              float: left;
            }
            .storeinfo {
              margin-left: 24px;
              width: 450px;
              float: left;
              .name {
                font-size: 14px;
                line-height: 24px;
              }
              .times {
                margin-top: 16px;
                font-size: 12px;
                color: #333333;
              }
              .phone {
                margin-top: 11px;
                font-size: 12px;
                color: #333333;
              }
              .address {
                margin-top: 11px;
                font-size: 12px;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
}
</style>
