import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'

import httpRequest from "./axios/request";
Vue.prototype.$myAjax = httpRequest.myAjax;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import VuePhotoZoomPro from 'vue-photo-zoom-pro'
import VuePhotoZoomPro from './components/goodsDetail/photoZoomPro/index'

Vue.use(VuePhotoZoomPro)

import Footer from "./components/module/Footer.vue";
Vue.component('Footer', Footer)

import message from "./components/message.vue";
Vue.component('message', message)

import Head from "./components/module/Head.vue";
Vue.component('Head', Head)

Vue.config.productionTip = false
Vue.prototype.$ = $
Vue.directive('preventright', {
  bind: function (el) {
    console.log(el)
    el.oncontextmenu = function () { return false }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
