<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="4"></navbar>
        <div class="mainbox">
          <div class="title">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                :label="'全部（' + (numbers.all || 0) + '）'"
                name="first"
              ></el-tab-pane>
              <el-tab-pane
                :label="'未使用（' + (numbers.unuse || 0) + '）'"
                name="second"
              ></el-tab-pane>
              <el-tab-pane
                :label="'已使用（' + (numbers.use || 0) + '）'"
                name="third"
              ></el-tab-pane>
              <el-tab-pane
                :label="'已过期（' + (numbers.outs || 0) + '）'"
                name="fourth"
              ></el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="!listdata.length" class="nodatas">
            <img :src="nodataimg" alt="" class="nodataimg" />
            <div class="nodatatext">暂无任何优惠券！</div>
          </div>
          <div v-else class="showbox">
            <div
              class="onecoup"
              v-for="(item, index) in listdata"
              :key="index"
              :style="
                'background-image:url(' +
                (item.coupon_status == 1 ? nouseimg : usedimg) +
                ')'
              "
            >
              <div class="pricebox">
                <p class="price">
                  <span>{{ item.coupon_type == 1 ? "￥" : "折" }}</span
                  >{{ item.discount }}
                </p>
                <p class="ifs">满{{ item.condition }}可用</p>
              </div>
              <div class="infosbox">
                <div class="coupstatusrow">
                  <div class="status">
                    {{
                      item.coupon_range == 1
                        ? "通用券"
                        : item.coupon_range == 2
                        ? "分类券"
                        : "商品券"
                    }}
                  </div>
                  <span>{{ item.coupon_sn }}</span>
                </div>
                <p class="times">
                  <template v-if="item.use_time_type == 1">
                    有效期:{{ item.use_start_time }} - {{ item.use_end_time }}
                  </template>
                  <template v-else>
                    有效期:领取后{{ item.after_receive_day }}天内可用
                  </template>
                </p>
                <p class="usetitle">使用规则</p>
                <div v-html="item.use_rule"></div>
                <!-- <p class="userow">
                  1.优惠券仅限在有效期内使用，过期则无法使用；
                </p>
                <p class="userow">
                  2.少量特殊商品不能使用优惠券（有些优惠券标注全场通用则可以使用）；
                </p>
                <p class="userow">
                  3.一张订单只能使用一张优惠券，优惠券不可合并；
                </p> -->
              </div>
              <div class="usebox" @click="tolist(item.coupon_status)">
                <p class="fromtop2">
                  {{
                    item.coupon_status == 1
                      ? "立即使用"
                      : item.coupon_status == 2
                      ? "已使用"
                      : "已过期"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="listdata.length">
          <div class="more-goods" v-if="page > totalpage" @click="loadmore">
            发现更多
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      nodataimg: require("/src/assets/img/lesscoups.png"),
      usedimg: require("/src/assets/img/use.png"),
      nouseimg: require("/src/assets/img/Unused.png"),
      listdata: [],
      activeName: "first",
      numbers: {},
      page: 1,
      totalpage: 0,
      status: "",
    };
  },
  mounted() {
    this.getcoupsList();
  },
  methods: {
    handleClick() {
      if (this.activeName == "first") {
        this.status = "";
      } else if (this.activeName == "second") {
        this.status = 1;
      } else if (this.activeName == "third") {
        this.status = 2;
      } else if (this.activeName == "fourth") {
        this.status = 3;
      }
      this.page = 1;
      this.listdata = [];
      this.getcoupsList();
    },
    getcoupsList() {
      this.$myAjax(
        "user/coupon_list?page=" + this.page + "&coupon_status=" + this.status,
        "get",
        {},
        (res) => {
          console.log(res);
          let numbers = {
            all: res.all_count,
            unuse: res.not_used_count,
            use: res.used_count,
            outs: res.expired_count,
          };
          this.numbers = numbers;
          this.totalpage = res.coupon_list.total_page;
          this.listdata = this.listdata.concat(res.coupon_list.list);
        }
      );
    },
    loadmore() {
      this.page++;
      this.getcoupsList();
    },
    tolist(index) {
      if (index == 1) {
            localStorage.removeItem("page")
        this.$router.push({
          name: "seriesDetails",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      flex: 1;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 22px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .showbox {
        width: 100%;
        padding: 0 89px;
        display: inline-block;
        .onecoup {
          width: 100%;
          height: 254px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-top: 44px;
          .pricebox {
            float: left;
            width: 26.5%;
            height: 100%;
            .price {
              text-align: center;
              font-size: 66px;
              color: #333333;
              font-weight: bold;
              margin-top: 56px;
              span {
                font-size: 22px;
                font-weight: normal;
                color: #333333;
              }
            }
            .ifs {
              margin-top: 5px;
              font-size: 22px;
              color: #333333;
              text-align: center;
            }
          }
          .infosbox {
            width: 50%;
            float: left;
            padding: 16px 39px 32px 49px;
            height: 100%;
            overflow: hidden;
            .coupstatusrow {
              width: 100%;
              display: inline-block;
              .status {
                width: 65px;
                height: 30px;
                font-size: 15px;
                color: #e90516;
                text-align: center;
                line-height: 30px;
                border: 1px solid #e90516;
                float: left;
              }
              span {
                margin-left: 24px;
                font-size: 15px;
                float: left;
                color: #333333;
                line-height: 30px;
              }
            }
            .times {
              margin-top: 10px;
              font-size: 15px;
              color: #999999;
            }
            .usetitle {
              margin: 20px 0 15px 0;
              font-size: 15px;
              color: #000000;
            }
            .userow {
              font-size: 13px;
              color: #666666;
              margin-top: 2px;
            }
          }
          .usebox {
            width: 23.5%;
            float: right;
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              color: #fff;
              font-size: 28px;
            }
            .fromtop2 {
              width: 80px;
            }
          }
        }
      }
    }
    .more-goods {
      text-align: center;
      border: 1px solid #f00;
      width: 153px;
      height: 45px;
      line-height: 45px;
      margin: 30px auto 0 auto;
      color: red;
      cursor: pointer;
    }
  }
}
</style>
<style scope>
.el-tabs__header {
  margin: 0 !important;
}
.el-tabs__nav-wrap::after {
  display: none !important;
}
.el-tabs__item:hover {
  color: #e7020f !important;
}
.el-tabs__item.is-active {
  color: #e7020f !important;
}
.el-tabs__active-bar {
  background-color: #e7020f !important;
  bottom: -27px !important;
}
.el-tabs__nav-wrap,
.el-tabs__nav-scroll {
  overflow: visible !important;
}
</style>
