<template>
  <div class="message" v-if="list.length>0">
    <div>
      <img src="../assets/img/tongzhi.png" />
      <el-carousel
        style="max-width: 1000px; min-width: 1022px;"
        direction="vertical"
        :autoplay="true"
        arrow="always"
        >
        <el-carousel-item
          v-for="(item,index) in message"
          :key="index"
          class="con"
    
          style="
            color: red;
            width: 100%;
            background: none;
            line-height: 50px;
            padding-left: 10px;
            cursor: pointer;
            white-space: nowrap
          "
        >
            <div @click="toxq(item.id)"  class="contentb"> {{item.title}}</div>
             <div @click="toxq(item.id)"  class="contenth" v-html="item.content"> </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    message:Array,
  },
  data() {
    return {
      list: [1,2,3],
      loop:true
    };
  },
  computed: {},
  methods: {
    tomessagexq() {},
    init() {
      this.$myAjax(
        "sms/send",
        "post",
        {
          mobile: "1",
        },
        (res) => {
          this.list = res;
        }
      );
    },
    toxq(id) {
      console.log(1231)
      this.$router.push({
        path:"/messagexq",
        query:{
          id:id
        } 
      })
    },
  },

  created() {
    // this.init();
  },
};
</script>
<style lang="less">
.message {
  position: relative;
  width: 100%;
  height: 50px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  .el-carousel__item{
    display: flex;
    justify-content: flex-start;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;
    border-radius: 15px;
    img {
     width: 16px;
    height: 16px;
    margin-top: 17px;
    }
    .contentb{
      height: 50px;
      line-height: 50px;
      color: red;

      text-align: left;
      font-size: 14px;
  
    }
    .contenth{
      height: 50px;
      line-height: 50px;
      color: red;
      padding-left: 50px;
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

        p{
          color: red;

           span{
            color: red;
           }
        }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 50px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__indicator {
  display: none;
}
  
</style>
