<template>
  <div
    class="Header"
    :class="{ is_index: is_index, is_fixed_top: is_fixed_top }"
  >
    <div class="top_box">
      <div class="mw">
        <div class="logos">
          <img src="../../assets/img/logotext.png" alt="" />
          <div class="quickrotuer">
            <i class="icon tardiness" @click="open_search" title="搜索"
              >&#xe763;</i
            >
            <i class="icon tardiness" @click="toselect" title="我的收藏"
              >&#xe611;</i
            >
            <i class="icon tardiness" @click="mycenter" title="个人主页"
              >&#xe761;</i
            >
            <i class="icon tardiness" @click="opencar" title="购物车"
              >&#xe760;</i
            >
            <i
              class="icon tardiness clearfix"
              @click="tonotice"
              title="消息中心"
              style="position: relative"
            >
              &#xe60e;
              <el-badge
                v-if="message_count > 0"
                class="mark"
                :value="message_count"
              />
            </i>
          </div>
        </div>
        <div class="catorybox">
          <div v-show="is_fixed_top" class="logo">
            <img src="../../assets/img/logotext.png" alt="" />
          </div>
          <div class="box">
            <div v-for="(item, index) in list" :key="index">
                  <div class="boxone" v-if="!item.next" @click="toxq(item)">
                <img :src="item.image" alt="" />
                <span>{{ item.title }}</span>
                <div class="t_s"></div>
              </div>
              <div
                class="boxone"
                v-else
                @click="tourl(item.link, item.id)"
                @mouseenter="Navmouseon(index)"
                @mouseleave="mouseleave()"
              >
                <img :src="item.image" alt="" />
                <span>{{ item.title }}</span>
                <div class="t_s"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cards"
        v-show="cards_show"
        @mouseenter="mouseon()"
        @mouseleave="mouseleave()"
      >
        <div class="catorys">
          <div class="catory_two">
            <div
              class="catory_two_one"
              :class="towactiveIndex == index ? 'catory_two_on' : ''"
              v-for="(item, index) in showData"
              :key="index"
              @mouseenter="twoIdchange(index)"
              @click.stop="toclassify(item.link, item.id)"
            >
              {{ item.title }}
              <div class="t_s"></div>
            </div>
          </div>
          <div class="catory_three" v-if="showData[towactiveIndex]">
            <div
              class="catory_three_one"
              v-for="(item, index) in showData[towactiveIndex].next"
              :key="index"
              @click.stop="toclassify(item.link, item.id)"
            >
              <p class="title">{{ item.title }}</p>
              <div class="lis" v-if="item.next">
                <li
                  v-for="(btem, bndex) in item.next"
                  :key="bndex"
                  @click.stop="toclassify(btem.link, btem.id)"
                >
                  {{ btem.title }}
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      size="480px"
      :withHeader="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <div class="hascarshops">
        <div class="carstitle">
          <span class="carnumber">购物车（{{ total_count }}）</span>
          <span class="shopgoing" @click="drawer = false">继续购物</span>
        </div>
        <div class="boxs_content">
          <!-- 正常普通商品 -->
          <div
            v-for="(itemd,  index) in normalData"
            :key="index"
            class="normal-content"
          >
           
          <div class="item" v-if="itemd.active_type==5||itemd.active_type==6||itemd.active_type==7">
             <div v-if="itemd.active_type==5">满减 </div>
             <div v-if="itemd.active_type==6">多件多折 </div>
             <div v-if="itemd.active_type==7">千元任选 </div>
             <div> {{itemd.active_msg}}</div>
             <div @click="toactive(itemd)" style="cursor:pointer">再逛逛</div>   
             <div class="icon iconarrow-left"  > </div>
          </div>

            <div class="parcel_name" v-if="itemd.list.length > 0">
             <div
              class="cart_parcel"
              v-for="(items, indexs) in itemd.list"
              :key="indexs"
            >
            <div    class="leftbox">
              <div class="leftbox">
                <span class="sendwhere">{{items.name }}发货</span>
              </div>
              <div class="rightbox" v-if="items.name == '保税仓'">
                <i class="icon tardiness" @click="showmetion">&#xe61e;</i>
              </div>
            </div>
              
            <!-- v-if="item.type == 1" -->
              <div class="parcel1_card"  v-for="(item,indexl)  in items.list" :key="indexl">
                <div class="shops">
                  <div class="oneshop">
                    <div class="img" @click="todetail(item.goods_id,itemd.active_type)">
                      <img :src="item.goods_image" :alt="item.goods_subtitle" />
                      <div class="metionstatus" v-if="item.goods_stock == 0">
                        已售罄
                      </div>
                    </div>
                    <div class="info">
                      <div class="info_name">
                        <span class="info_name_name">{{
                          item.goods_subtitle
                        }}</span>
                        <span class="info_name_price"
                          >￥{{ item.goods_price }}</span
                        >
                      </div>
                      <div class="info_size">
                        <span>{{ item.spec_value }}</span>
                      </div>
                      <div class="info_size">
                        <span>x {{ item.goods_num }}</span>
                      </div>

                      <div class="info_btn">
                        <div class="desc" v-if="item.goods_warehouse == 2">
                          <i class="icon tardiness">&#xe602;</i>
                          <span
                            >此产品为跨境商品，更多信息请查看产品页或咨询我们</span
                          >
                        </div>
                        <div class="delete" @click="shopsdelete(item.car_id)">
                          删除
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="oneshop giftslist"
                    v-for="(v, i) in item.gift_list"
                    :key="i"
                  >
                    <div class="img">
                      <img :src="v.image" :alt="v.title" />
                    </div>
                    <div class="info">
                      <div class="info_name">
                        <span class="info_name_name">{{ v.title }}</span>
                      </div>
                      <p class="info_size gift_txt">赠品</p>
                      <p class="info_size">x 1</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
               </div>
<!-- v-else -->
              <div v-else>
                <div class="goods_check">
                  <div class="img">
                    <img
                      :src="item.menu_image"
                      :alt="item.menu_title"
                      @click="tosuit(item.goods_id)"
                    />
                  </div>
                  <div class="info">
                    <div class="info_name">
                      <span class="info_name_name">{{ item.menu_title }}</span>
                    </div>
                    <div class="info_size">
                      <span>x {{ item.goods_num }}</span>
                    </div>
                  </div>
                </div>
                <div class="goods_details">
                  <div class="details_imgs">
                    <div
                      class="imgs"
                      v-for="(q, v) in item.sku_list"
                      :key="v"
                      @click="tosuit(item.goods_id)"
                    >
                      <div class="imgs_igm">
                        <img :src="q.image" :alt="q.subtitle" />
                      </div>
                      <div class="imgs_name">{{ q.subtitle }}</div>
                    </div>
                  </div>
                  <div class="details_desc">
                    <div class="desc_price">￥{{ item.menu_price }}</div>
                    <div class="desc_delete" @click="shopsdelete(item.car_id)">
                      删除
                    </div>
                  </div>
                </div>
              </div>

          
          </div>



          <div class="nodatas" v-if="!hasNormalData">
            <p>{{ islogin ? "您的购物车还是空的" : "您还未登录" }}</p>
            <a class="tobuy" v-if="islogin" @click="tolist">前去购买</a>
            <a class="tobuy" v-else @click="tologin">前去登录</a>
          </div>
        </div>

        <div class="totals" v-if="hasNormalData">
          <span class="title">预计总计</span>
          <span class="price">￥{{ total_price }}</span>
        </div>
        <div class="jiesuanbtn" v-if="islogin" @click="tocarshop">
          查看购物车
        </div>
      </div>
    </el-drawer>

    <!-- <el-drawer :visible.sync="searchstatus" direction="ttb">
      <div class="searchbox mw">
        <el-input v-model="seachvalue" placeholder="搜索" style="width: 672px">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="tosearchs"
          ></el-button>
        </el-input>
        <div>
          <p class="title">热门搜索</p>
          <p
            v-for="(item, index) in hots"
            :key="index"
            class="onehot"
            @click="tosearch(item.title)"
          >
            {{ item.title }}
          </p>
        </div>
      </div>
    </el-drawer> -->

    <transition name="fade">
      <div class="search_wrapper" v-show="searchstatus">
        <div class="mask" @click="searchClose()"></div>
        <div class="search_container">
          <div style="width: 20%">
            <img src="../../assets/img/logotext2.png" alt="" />
          </div>
          <div class="searchbox">
            <el-input
              v-model="seachvalue"
              placeholder="搜索"
              style="width: 672px"
              @keyup.enter.native="tosearchs"
            >
              <i slot="prefix" class="el-input__icon icon tardiness search"
                >&#xe763;</i
              >
            </el-input>
            <div class="search_text">
              <div>
                <p class="title">热门搜索</p>
                <p
                  v-for="(item, index) in hots"
                  :key="index"
                  class="onehot"
                  @click="tosearch(item.title)"
                >
                  <i class="icon tardiness">&#xe763;</i>
                  {{ item.title }}
                </p>
              </div>
              <div>
                <p class="title">历史搜索</p>
                <p
                  v-for="(item, index) in searchHistoryList"
                  :key="index"
                  class="onehot"
                  @click="tosearch(item)"
                >
                  <i class="icon tardiness">&#xe763;</i>
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
          <div style="width: 20%; text-align: right" class="search_close">
            <i class="el-icon-close" @click="searchClose()"></i>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    list: Array,

    is_index: Boolean,
    message_count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cards_show: false,
      intel: "",
      time: 1,
      drawer: false,
      showData: [],
      towactiveIndex: 0,
      normalData: [],
      hasNormalData: false,
      total_price: "",
      total_count: 0,
      islogin: false,
      searchstatus: false,
      seachvalue: "",
      hots: [],
      searchHistoryList: [],
      is_fixed_top: false,
    };
  },
  created() {
    if (localStorage.getItem("historyItems")) {
      this.searchHistoryList = JSON.parse(localStorage.getItem("historyItems"));
    }
    this.gethots();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
     toactive(item) {
       localStorage.removeItem("page")
     this.$router.push({
        name: "seriesDetailsactive",
        query: {
          active_type:item.active_type,
        },
      });
      this.drawer = false
    },
    Navmouseon(index) {
      this.showData = this.list[index].next;
      clearInterval(this.intel);
      this.time = 1;
      this.cards_show = true;
    },
    mouseon() {
      clearInterval(this.intel);
      this.time = 1;
      this.cards_show = true;
    },
    mouseleave() {
      let _this = this;
      this.intel = setInterval(function () {
        if (_this.time == 0) {
          _this.cards_show = false;
          _this.towactiveIndex = 0;
          clearInterval(_this.intel);
          _this.time = 1;
        }
        _this.time--;
      }, 100);
    },
    twoIdchange: function (_index) {
      this.towactiveIndex = _index;
    },
    toxq(item){
      console.log(item)
        window.location.href = item.link;
      // this.$router.push({ name: item.link });
    },
    tourl: function (url, id) {
      // if (url && this.$route.name != url) {
      //   this.$router.push({ name: url });
      // }
      localStorage.removeItem("page")
      if (url != "") {
        if (url.indexOf("?") > -1) {
          url = url + "&t=" + new Date().getTime() + "&nav_id=" + id;
        } else {
          url = url + "?t=" + new Date().getTime() + "&nav_id=" + id;
        }
        window.location.href = url;
      }
    },
    tocarshop() {
      this.drawer = false;
      this.$router.push({ name: "myCart" });
    },
    mycenter() {
      this.$router.push({ name: "HomeCenter" });
    },
    toselect() {
      this.$router.push({ name: "MineSelect" });
    },
    toclassify(link, id) {
      localStorage.removeItem("page")
      if (link) {
        this.cards_show = false;
        if (link.indexOf("?") > -1) {
          link = link + "&t=" + new Date().getTime() + "&nav_id=" + id;
        } else {
          link = link + "?t=" + new Date().getTime() + "&nav_id=" + id;
        }
        window.location.href = link;
      }
    },
    toclassify2(_id, _title, _pid, _index) {
      if (this.showData[this.towactiveIndex].next[_index].next) {
        return;
      } else {
        localStorage.removeItem("page")
        this.$router.push({
          name: "seriesDetails",
          query: {
            classify_ids: _id,
            title: _title,
          },
        });
      }
    },
    toclassify3(_id, _title) {
       localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          classify_ids: _id,
          title: _title,
        },
      });
    },
    opencar() {
      if (localStorage.getItem("token")) {
        this.getcarList();
        this.drawer = true;
        this.islogin = true;
      } else {
        this.islogin = false;
        this.drawer = true;
      }
    },
    tonotice() {
      this.$router.push({
        name: "mineNotice",
      });
    },
    getcarList() {
      this.$myAjax("car/car_list", "get", {}, (res) => {
        console.log(res)
        if (res.list && res.list.normal) {
          this.normalData = res.list.normal;
          this.total_price = res.total_price;
          this.total_count = res.total_count;
          this.hasNormalData = true;
        } else {
          this.normalData = {};
          this.hasNormalData = false;
          this.total_price = res.total_price;
          this.total_count = res.total_count;
        }
      });
    },
    todetail(_id,active_type) {
      this.drawer = false;
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}`),
        },
       query:{
        active_type:active_type
        },
      });
    },
    tosuit(_id) {
      this.drawer = false;
      this.$router.push({
        name: "suitDetails",
        query: {
          id: _id,
        },
      });
    },
    tolist() {
      this.drawer = false;
      this.$router.push({
        name: "Home",
      });
    },
    tologin() {
      this.drawer = false;
      this.$router.push({
        name: "login",
      });
    },
    shopsdelete(_id) {
      this.$confirm("此操作将删除购物车商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$myAjax(
          "car/del_car",
          "post",
          {
            car_id: _id,
          },
          () => {
            this.$message({
              type: "success",
              message: "删除成功!",
              offset: 170,
            });
            this.getcarList();
          }
        );
      });
    },
    open_search() {
      this.searchstatus = true;
      this.header_hover = false;
    },
    gethots() {
      this.$myAjax("goods/goods_search", "get", {}, (res) => {
        this.hots = res;
      });
    },
    tosearch(_title) {
      this.searchstatus = false;
       localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          value: _title,
        },
      });
    },
    tosearchs() {
      this.searchstatus = false;
      this.appendKeywords(this.seachvalue);
       localStorage.removeItem("page")
      this.$router.push({
        name: "seriesDetails",
        query: {
          value: this.seachvalue,
        },
      });
    },
    searchClose() {
      this.searchstatus = false;
    },
    appendKeywords(value) {
      /**
       * 1.已经有的关键词不再添加
       * 2.添加到数组的首位，若超出10个则删除最后一个
       * 3.添加到缓存
       */
      var appendFlag = true;
      if (
        this.searchHistoryList !== null &&
        this.searchHistoryList !== undefined &&
        this.searchHistoryList.length > 0
      ) {
        this.searchHistoryList.forEach(function (currentValue) {
          if (currentValue === value) {
            appendFlag = false;
            return;
          }
        });
        // 判断-添加
        if (appendFlag === true) {
          // 长度判断
          if (this.searchHistoryList.length >= 10) {
            this.searchHistoryList.unshift(value);
            this.searchHistoryList.pop();
          } else {
            this.searchHistoryList.unshift(value);
          }
          localStorage.setItem(
            "historyItems",
            JSON.stringify(this.searchHistoryList)
          );
        }
      } else {
        this.searchHistoryList = [];
        this.searchHistoryList.push(value);
        localStorage.setItem(
          "historyItems",
          JSON.stringify(this.searchHistoryList)
        );
      }
    },
    handleScroll: function () {
      this.currentScroll = window.pageYOffset;
      this.innerHeight = window.innerHeight;
      if (!this.is_index) {
        if (this.currentScroll > 50) {
          this.is_fixed_top = true;
        } else {
          this.is_fixed_top = false;
        }
      }
    },
    showmetion() {
      this.$alert("跨境商品保税仓发货", "友情提示", {
        confirmButtonText: "确定",
        callback: () => {},
      });
    },
  },
};
</script>

<style lang="less">
.quickrotuer {
  .el-badge {
    position: absolute;
    top: 0;
    left: 9px;
    transform: scale(0.9);
    .el-badge__content {
      color: #e00b24;
      background-color: #ffffff;
    }
  }
}
.quickrotuer .el-input .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}
.search_wrapper {
  .search_container {
    .el-input__inner {
      border-radius: 20px;
      background: #f5f5f5;
      border: none;
      padding-left: 40px;
    }
    .icon.search {
      color: #df1329;
      font-weight: bold;
      margin-left: 10px;
    }
    .onehot {
      .icon {
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.quickrotuer .search_box {
  width: 50%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.search_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  background: #00000073;
  z-index: 200;
  .mask {
    height: 100%;
    position: absolute;
    width: 100%;
    background: #00000073;
    z-index: 201;
  }
  .search_container {
    background: #fff;
    display: flex;
    padding: 30px;
    z-index: 202;
    position: absolute;
    width: 100%;
    .search_close {
      cursor: pointer;
    }
  }
}

.Header {
  width: 100%;
  height: 140px;
  position: absolute;
  top: 0px;
  z-index: 2000;
  &.is_index {
    .top_box {
      // background: linear-gradient(to top, #fff0, #e00b24 50%, #e00b24 100%);
      // background: linear-gradient(to top, #fff0, #e00b24 35%, #e00b24 100%);
      background: linear-gradient(
        to top,
        #fff0,
        #e00b2429 33%,
        #e00b24 66%,
        #e00b24 100%
      );
      background-size: 100% 300%;
      background-position-y: 100%;
      transition: all 0.2s linear;
      &:hover {
        background-position-y: 0;
      }
    }
  }
  &.is_fixed_top {
    top: -60px;
    position: fixed;
    .logos {
      .quickrotuer {
        top: 60px;
        z-index: 1;
      }
    }
    .catorybox {
      margin-top: 25px;
      .box {
        margin-left: 230px;
      }
    }
  }
  .top_box {
    background: #e00b24;
    padding-top: 20px;
    width: 100%;
    z-index: 10;
    position: relative;
    height: 100%;
  }
  .logos {
    height: 33px;
    line-height: 33px;
    width: 100%;
    text-align: center;
    position: relative;
    img {
      width: 170px;
      height: auto;
    }
    .quickrotuer {
      position: absolute;
      right: 0;
      height: 100%;
      width: 190px;
      top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .icon {
        font-size: 21px;
        cursor: pointer;
        color: #fff;
        transition: all 0.5s;
        &.search {
          color: #df1329;
          font-weight: bold;
          line-height: 30px;
        }
        &:hover {
          transform: scale3d(1.2, 1.2, 1.2) translateY(-5px);
          font-weight: bold;
        }
      }
    }
  }
  .catorybox {
    margin-top: 20px;
    width: 100%;
    position: relative;
    transition: all 0.1s linear;
    .logo {
      position: absolute;
      left: 0;
      width: auto;
      top: 5px;
      img {
        width: 170px;
      }
    }
    .box {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 800px;
      .boxone {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        position: relative;
        img {
          width: 28px;
          transition: all 0.5s;
          &:hover {
            transform: scale3d(1.2, 1.2, 1.2) translateY(-5px);
          }
        }
        span {
          margin-top: 6px;
          color: #ffffff;
          font-size: 13px;
        }
        .t_s {
          width: 0;
          height: 1px;
          background: #fff;
          bottom: -10px;
          position: absolute;
        }
        &:hover {
          .t_s {
            width: 100%;
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .cards {
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 0 2px #f2f2f2;
    position: absolute;
    z-index: 9;
    overflow: hidden;
    animation: outs 0.2s linear forwards;
    animation-delay: 0.1s;
    max-height: 0px;
    opacity: 0;
    @keyframes outs {
      100% {
        max-height: 500px;
        opacity: 1;
      }
    }
    .catorys {
      padding: 26px 0;
      // width: 900px;
      margin: 0 auto;
      .catory_two {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 900px;
        margin: 0 auto;
        margin-bottom: 10px;
        .catory_two_one {
          position: relative;
          color: #000000;
          font-size: 14px;
          padding: 8px 0;
          cursor: pointer;
          font-weight: bold;
          display: flex;
          justify-content: center;
          flex-direction: row;
          margin: 0 auto;
          &:hover {
            color: #df1329;
          }
          .t_s {
            width: 0;
            height: 2px;
            background: #df1329;
            position: absolute;
            bottom: 0;
          }
          &:hover {
            .t_s {
              width: 100%;
              transition: all 0.5s;
            }
          }
        }
        .catory_two_on {
          .t_s {
            width: 100%;
            transition: all 0.5s;
          }
        }
      }
      .catory_three {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        .catory_three_one {
          // margin: 0 auto;
          margin: 0;
          text-align: center;
          width: 120px;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          .title {
            // color: #000000;
            // font-weight: bold;
            color: #333;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;
            &:hover {
              color: #df1329;
            }
          }
          .lis {
            margin-top: 20px;
            li {
              color: #999999;
              font-size: 14px;
              padding: 5px 0;
              cursor: pointer;
              &:hover {
                color: #df1329;
              }
            }
          }
        }
      }
    }
  }
}
.hascarshops {
  padding: 0 15px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  background: #fafafa;
  .carstitle {
    padding: 21px 0;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .carnumber {
      font-size: 20px;
      color: #111111;
    }
    .shopgoing {
      font-size: 16px;
      color: #111111;
      cursor: pointer;
    }
  }
  .boxs_content {
    // max-height: 680px;
    overflow-y: auto;
    flex: 1;
    .normal-content {
      background: #fff;
    }
    .parcel_name {
      line-height: 50px;

      .leftbox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
        span {
          margin-left: 12px;
          font-size: 16px;
          border: 1px solid #e60012;
          border-radius: 5px;
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          color: #e60012;
        }
      }
      .rightbox {
        position: relative;
        float: right;
        align-items: center;
        .icon {
          font-size: 14px;
          color: #e60012;
          cursor: pointer;
        }
      }
    }
    .cart_parcel {
      padding: 0 0 30px 0;
      background: #fff;
      margin: 15px;
      border-bottom: 1px solid #eee;
      .activitybox {
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 0 56px;
        justify-content: space-between;
        .activityleftbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          .activityname {
            width: 45px;
            height: 20px;
            border: 1px solid #e60012;
            text-align: center;
            line-height: 20px;
            color: #e60012;
            font-size: 12px;
          }
          span {
            margin-left: 13px;
            color: #111111;
            font-size: 16px;
          }
        }
        .activityrightbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          span {
            color: #111111;
            font-size: 16px;
          }
          .icon {
            font-size: 14px;
          }
        }
      }
      .parcel1_card {
        display: flex;
        justify-content: space-around;
        .choosebox {
          margin-top: 120px;
        }
        .shops {
          flex: 1;
          display: flex;
          flex-direction: column;
          .oneshop {
            display: flex;
            flex-direction: row;
            margin-top: 17px;
            .img {
              width: 100px;
              height: 100px;
              text-align: center;
              cursor: pointer;
              position: relative;
              img {
                width: 100%;
                height: 100%;
              }
              .metionstatus {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 40px;
                background: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-size: 14px;
                text-align: center;
                line-height: 40px;
              }
            }
            .info {
              margin: 0 14px;
              flex: 1;
              .info_name {
                .info_name_name {
                  font-size: 16px;
                  color: #111;
                }
                .info_name_price {
                  font-size: 16px;
                  color: #111;
                  font-weight: 700;
                  float: right;
                }
              }
              .gifts {
                margin-top: 16px;
                font-size: 14px;
              }
              .number {
                margin-top: 38px;
                font-size: 14px;
              }
              .info_size {
                margin-top: 16px;
                font-size: 14px;
                i.icon {
                  font-size: 10px;
                  margin-left: 2px;
                }
                span {
                  margin-right: 20px;
                  cursor: pointer;
                }
              }
              .gift_txt {
                display: inline-block;
                border: 1px solid #e60012;
                color: #e60012;
                padding: 0 10px;
                border-radius: 5px;
              }
              .info_num {
                width: 110px;
                height: 40px;
                border: 1px solid #ccc;
                line-height: 40px;
                text-align: center;
                margin-top: 27px;
                span {
                  margin: 0 10px;
                  font-size: 16px;
                }
                .icon {
                  margin: 0 10px;
                  cursor: pointer;
                  font-size: 14px;
                }
              }
              .info_btn {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .desc {
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  .icon {
                    font-size: 14px;
                    cursor: pointer;
                  }
                  span {
                    font-size: 12px;
                    margin-left: 6px;
                  }
                }
                .like {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  font-size: 12px;
                  cursor: pointer;
                  margin-right: 20px;
                  .icon {
                    font-size: 12px;
                    margin-right: 5px;
                  }
                  ._showred {
                    color: #e60012;
                  }
                }
                .delete {
                  text-decoration: underline;
                  font-size: 12px;
                  cursor: pointer;
                }
              }
            }
            // & + .giftslist {
            //   margin-top: 17px;
            // }
          }
          // .giftslist {
          //   background-color: #eeeeeea8;
          //   padding: 8px 20px 20px 20px;
          //   margin: 0;
          // }
        }
      }
      .goods_check {
        display: flex;
        margin-top: 17px;
        .check {
          margin-top: 88px;
        }
        .img {
          width: 100px;
          height: 100px;
          padding: 10px;
          background-color: #fff;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          margin-left: 34px;
          font-size: 20px;
          color: #111;
        }
        .info {
          margin-left: 14px;
          flex: 1;
          .info_name {
            .info_name_name {
              font-size: 16px;
              color: #111;
            }
            .info_name_price {
              font-size: 18px;
              color: #111;
              font-weight: 700;
              float: right;
            }
          }
          .gifts {
            margin-top: 16px;
            font-size: 14px;
          }
          .number {
            margin-top: 38px;
            font-size: 14px;
          }
          .info_size {
            margin-top: 16px;
            font-size: 14px;
            i.icon {
              font-size: 10px;
              margin-left: 2px;
            }
            span {
              margin-right: 20px;
              cursor: pointer;
            }
          }
          .info_num {
            width: 90px;
            height: 30px;
            border: 1px solid #ccc;
            line-height: 30px;
            text-align: center;
            margin-top: 17px;
            span {
              margin: 0 5px;
              font-size: 14px;
            }
            .icon {
              margin: 0 10px;
              cursor: pointer;
              font-size: 14px;
            }
          }
          .info_btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 40px;
            .desc {
              flex: 1;
              display: flex;
              flex-direction: row;
              .icon {
                font-size: 14px;
                cursor: pointer;
              }
              span {
                font-size: 12px;
                margin-left: 6px;
              }
            }
            .like {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 12px;
              cursor: pointer;
              margin-right: 20px;
              .icon {
                font-size: 12px;
                margin-right: 5px;
              }
              ._showred {
                color: #e60012;
              }
            }
            .delete {
              text-decoration: underline;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .goods_details {
        display: flex;
        margin-top: 18px;
        flex-direction: row;
        justify-content: space-between;
        .details_imgs {
          padding: 8px 20px 20px 20px;
          background-color: #eeeeeea8;
          .imgs {
            display: flex;
            align-content: center;
            margin-top: 12px;
            cursor: pointer;
            .imgs_igm {
              width: 80px;
              background-color: #fff;
              padding: 10px;
              height: 80px;
              margin-right: 20px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .imgs_name {
              height: 80px;
              font-size: 16px;
            }
          }
        }
        .details_desc {
          display: flex;
          width: 38%;
          justify-content: space-around;
          align-items: center;
          // background-color: #eeeeeea8;

          div {
            font-size: 14px;
            color: #111;
          }
          .desc_price {
            font-size: 16px;
            color: #e60012;
          }
          .desc_like {
            min-width: 70px;
            cursor: pointer;
            font-size: 12px;
            .icon {
              font-size: 12px;
            }
          }
          .desc_delete {
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
      &:last-child {
        border: none;
      }
    }

    .nodatas {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
      }
      a {
        text-decoration: underline;
        cursor: pointer;
        padding: 10px 0;
      }
    }
  }
  .totals {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      color: #111111;
      font-size: 16px;
    }
    .price {
      color: #111111;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .jiesuanbtn {
    margin: 28px 0;
    height: 46px;
    background: #e60012;
    text-align: center;
    line-height: 46px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
}
.searchbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  .search_text {
    display: flex;
    width: 672px;
    & > div {
      min-width: 20%;
      max-width: 50%;
    }
  }
  .title {
    color: #333;
    font-size: 16px;
    text-align: left;
    margin-top: 20px;
    text-indent: 1rem;
  }
  .onehot {
    font-size: 14px;
    text-align: left;
    margin-top: 12px;
    text-indent: 1rem;
    color: #999;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

 .normal-content{
    position: relative;
    width: 100%;
    padding-top: 1px;
  
       .item{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          margin-top: 24px;
          padding-left: 26px;
            >div:nth-child(1){
              width: 100px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 16px;
              border-radius: 18px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              border: 1px solid #e60012;
              color: #e60012;
            }
            
            >div:nth-child(2){
              width: calc(100% - 100px  - 120px);
              height: 30px;
              text-align: left;
              padding-left: 25px;
              line-height: 30px;
              color: #999999;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            
            >div:nth-child(3){
              width:80px;
              height: 30px;
              color: #999999;
              line-height: 30px;
              text-align: right;
            }
            
            >div:nth-child(4){
              width:40px;
              height: 30px;
              color: #999999;
              line-height: 30px;
            }
       }
  }
</style>
