<template>
  <div id="app">
    <!-- <message /> -->
    <Head
      :list="navList"
      :is_index="$route.path == '/' ? true : false"
      :message_count="message_count"
      :message="message"
    ></Head>

    <div>
        <keep-alive>
          <router-view  v-if="$route.meta.keepAlive"  :key="key" ></router-view>
        </keep-alive> 
         <router-view  v-if="!$route.meta.keepAlive"  :key="key"></router-view>
    </div>

    <!-- 尾部 -->
    <Footer :list="bottomNav" :info="bottomData"></Footer>

    <el-backtop></el-backtop>
  </div>
</template>
<script>
import { setRem } from "../src/assets/utils/utils";
export default {
  data() {
    return {
      navList: [], //头部导航
      bottomNav: [], //底部导航
      bottomData: {},
      message_count: 0,
      message:[]
    };
  },
  mounted() {
    // 初始化
    setRem();
    // 改变窗口大小时重新设置 rem
    window.addEventListener("resize", setRem);
    this.$myAjax("pc/top_nav", "get", {}, (res) => {
      console.log(res);
      this.navList = res;
    });
    this.$myAjax("pc/bottom_nav", "get", {}, (res) => {
      this.bottomNav = res.nav_list;
      this.bottomData = res;
    });
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });

    let userid = "";
    if (localStorage.getItem("userId")) {
      userid = localStorage.getItem("userId");
    }
    this.$myAjax(
      "common/user_data_count",
      "get",
      { identity_id: userid },
      (res) => {
        this.message_count = res.message_count;
      }
    );



    
  },
  updated() {
    this.$("#app").on("contextmenu", "img", function (e) {
      e.preventDefault();
      return false;
    });
    this.$("#app").on("mousedown ", "img", function (e) {
      e.preventDefault();
      return false;
    });
  },
  computed: {
    key() {
      // return this.$route.name !== undefined
      //   ? this.$route.name + new Date()
      //   : this.$route + new Date();
       return this.$route.fullPath
    },
  },
  watch: {
    $route() {
      let userid = "";
      if (localStorage.getItem("userId")) {
        userid = localStorage.getItem("userId");
      }
      this.$myAjax(
        "common/user_data_count",
        "get",
        { identity_id: userid },
        (res) => {
          this.message_count = res.message_count;
        }
      );
    },
  },
};
</script>
<style lang="less">
@import "../src/assets/css/common.css";
@import "../src/assets/font/iconfont.css";
@import "../src/assets/css/media.css";
body {
  overflow-y: auto !important;
  padding-right: 0 !important;
}
.el-backtop {
  z-index: 51 !important;
  color: #e60012 !important;
}
</style>
