<template>
  <div>
    <div class="noticepage">
      <div class="mw">
        <div
          class="noticeOne"
          :class="openindex == index ? 'rowon' : ''"
          @click="noticechange(index)"
          v-for="(item, index) in noticeData"
          :key="index"
        >
          <div class="noticerow">
            <div class="noticeIcon">
              <i class="icon tardiness">&#xe610;</i>
            </div>
            <div class="noticeinfo">
              <div class="time">{{ item.createtime }}</div>
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="icon tardiness arrow">
              {{ openindex == index ? "&#xe621;" : "&#xe61f;" }}
            </div>
          </div>
          <div class="detailinfo" v-if="openindex == index">
            <div class="details" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      openindex: 0,
      noticeData: [],
    };
  },
  mounted() {
    this.getNoticeData();
  },
  methods: {
    getNoticeData() {
      this.$myAjax("common/platform_notice", "get", {}, (res) => {
        console.log(res);
        this.noticeData = res;
      });
    },
    noticechange(_index) {
      this.openindex = _index;
    },
  },
};
</script>
<style lang="less" scoped>
.noticepage {
  min-height: 966px;
  padding-bottom: 100px;
  background: #fafafa;
  margin-top: 140px;
  .noticeOne {
    display: inline-block;
    margin-top: 26px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background: #fff;
    }
    .noticerow {
      padding: 44px 32px;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      .noticeIcon {
        text-align: center;
        color: #e50010;
        float: left;
        .icon{
          font-size: 55px;
        }
      }
      .noticeinfo {
        margin-left: 38px;
        float: left;
        .time {
          color: #999999;
          font-size: 20px;
        }
        .title {
          font-size: 24px;
          color: #333333;
          margin-top: 10px;
        }
      }
      .arrow {
        float: right;
        font-size: 24px;
        line-height: 70px;
      }
    }
    .detailinfo {
      width: 100%;
      background: #fff;
      float: left;
      .details {
        margin-left: 138px;
        padding: 24px 0 37px;
        width: 922px;
      }
    }
  }
  .rowon {
    background: #ffffff;
  }
}
</style>
