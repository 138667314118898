<template>
  <div class="memberGoods">
    <!-- 限时抢购组件 -->
    <div class="component mw">
      <siftingList
        :shopsData="shopsData"
        :total_page="total_page"
        :total_size="total_count"
        :page="page"
        :shiwtitle="shiwtitle"
        :active_type="3"
        @update="datachange"
        @handlesku="handlesku"
        :tips="tips"
      >
        <!-- <template v-slot:title_name>
          <p class="t30">全部商品(9)</p>
        </template> -->
        <!-- <template v-slot:goods_ptitle> 副标题 </template> -->
        <!-- <template v-slot:goods_price>
          <span class="price">￥11</span>
          <span class="sale-price">￥1111</span>
        </template> -->
      </siftingList>
    </div>
  </div>
</template>
<script>
import siftingList from "../../components/Sifting/sifting_list";
const drag = 0;
export default {
  props: [],
  data() {
    return {
      screenData: [], //筛选数据
      classifyData: [], //分类数据
      brandData: [], //品牌数据
      labelData: [], //、其他数据
      shopsData: [], //商品数据
      page: 1, //加载页数
      total_page: 1, //总页数
      total_count: 0,

      recid: "",
      classify_ids: "",
      sort_field: "",
      sort_order: "",
      shiwtitle: "会员专区",
      minPrice: "",
      maxPrice: "",
      labelid: "",
      brandid: "",
      issend: null,
      searchvalue: "",
      userid: "",
      tips: "加载中",
    };
  },
  mounted() {
    if (localStorage.getItem("userId")) {
      this.userid = localStorage.getItem("userId");
      this.getshopsData();
    } else {
      this.$router.push({ name: "NonMember" });
    }
    // this.getDatabase();
  },
  methods: {
    scrollToTop() {
      const gap = document.documentElement.scrollTop || document.body.scrollTop;
      if (gap > location) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, gap - gap / drag);
      }
    },
    getDatabase() {
      this.$myAjax(
        "goods/goods_attr?classify_id=" +
          this.classify_ids +
          "&brand_id=" +
          this.brandid,
        "get",
        {},
        (res) => {
          res.screen.map((item) => {
            item.open = false;
          });
          res.screen.map((item) => {
            item.next.map((btem) => {
              btem.ison = false;
            });
          });
          this.screenData = res.screen;
          this.classifyData = res.classify;
          res.brand.map((item) => {
            item.ison = false;
          });
          this.brandData = res.brand;
          res.label.map((item) => {
            if (this.brandid == item.id) {
              item.ison = true;
            } else {
              item.ison = false;
            }
          });
          this.labelData = res.label;
          this.getshopsData();
        }
      );
    },
    getshopsData() {
      this.tips = "加载中";
      this.$myAjax(
        "goods/member_goods_list?page=" +
          this.page +
          "&recd_id=" +
          this.recid +
          "&classify_id=" +
          this.classify_ids +
          "&sort_field=" +
          this.sort_field +
          "&sort_order=" +
          this.sort_order +
          "&brand_id=" +
          this.brandid +
          "&min_price=" +
          (this.minPrice != "" ? this.minPrice : 0) +
          "&max_price=" +
          (this.maxPrice != "" ? this.maxPrice : 1000000000) +
          "&label_id=" +
          this.labelid +
          "&keywords=" +
          this.searchvalue +
          "&identity_id=" +
          this.userid,
        "get",
        {},
        (res) => {
          res.list.map((item) => {
            item.sku_img_index = -1;
            return item;
          });
          this.shopsData = res.list;
          // this.shopsData = this.shopsData.concat(res.list);
          this.total_page = res.total_page;
          this.total_count = res.total_count;
          this.scrollToTop();
          this.tips = "";
          if (!this.shopsData.length) {
            this.$router.push({ name: "memberNoMerchandise" });
          }
        }
      );
    },
    loadmore(page) {
      this.page = page;
      this.getshopsData();
    },
    datachange(e) {
      this.sort_field = e.split(",")[0];
      this.sort_order = e.split(",")[1];
      this.shopsData = [];
      this.getshopsData();
    },
    minprice(e) {
      this.minPrice = e;
    },
    maxprice(e) {
      this.maxPrice = e;
    },
    search() {
      this.getshopsData();
    },
    brandChoose(e) {
      this.brandData[e].ison = !this.brandData[e].ison;
      this.shaix();
    },
    otherChoose(e) {
      this.labelData[e].ison = !this.labelData[e].ison;
      this.shaix();
    },
    shaix() {
      let brandid = [],
        labelid = [];
      this.brandData.map((item) => {
        if (item.ison) {
          brandid.push(item.id);
        }
      });
      this.brandid = brandid.toString();
      this.labelData.map((item) => {
        if (item.ison) {
          labelid.push(item.id);
        }
      });
      this.labelid = labelid.toString();
      this.shopsData = [];
      this.getshopsData();
    },
    handlesku({ i1, i2 }) {
      this.shopsData[i1].sku_img_index = i2;
      this.shopsData[i1].image = this.shopsData[i1].sku_image[i2];
    },
  },
  components: {
    siftingList,
  },
};
</script>
<style lang="less" scoped>
.memberGoods {
  background: #fafafa;
  margin-top: 140px;
  .component {
    display: flex;
    justify-content: space-between;
  }
}
</style>
<style>
.el-breadcrumb {
  font-size: 16px;
  margin-bottom: 28px;
}
</style>
