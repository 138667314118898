<template>
  <div class="myCart mwframe">
    <div class="lrbox">
      <div class="lrbox_left">
        <div class="cart_head">
          我的购物车
          <span>{{ total_num }}件商品</span>
        </div>
        <!-- 正常普通商品 -->
        <div class="chooseallbox">
          <el-checkbox v-model="AllChecked" @change="chooseAll"></el-checkbox>
          <span style="flex-grow: 1">全选</span>
          <span class="follow" @click="shopsFollow()">移入收藏夹</span>
          <span class="delete" @click="shopsDeleteSelect()">删除</span>
        </div>

        <div
           v-for="(itemd,  index) in normalData"
            :key="index"
            class="normal-content"
        >

            <div class="item" v-if="itemd.active_type==5||itemd.active_type==6||itemd.active_type==7">
             <div v-if="itemd.active_type==5">满减 </div>
             <div v-if="itemd.active_type==6">多件多折 </div>
             <div v-if="itemd.active_type==7">千元任选 </div>
             <div> {{itemd.active_msg}}</div>
             <div @click="toactive(itemd)" style="cursor:pointer">再逛逛</div>   
             <div class="icon iconarrow-left"  > </div>
          </div>

          <div v-if="itemd.list.length > 0" class="normal-content">
           <div
              class="cart_parcel"
              v-for="(items, indexs) in itemd.list"
              :key="indexs"
            >
            <div    class="leftbox">
              <div class="leftbox">
                <span class="sendwhere">{{items.name }}发货</span>
              </div>
              <div class="rightbox" v-if="items.name == '保税仓'">
                <i class="icon tardiness" @click="showmetion">&#xe61e;</i>
              </div>
            </div>

            
            <div
              class="cart_parcel"
              v-for="(item,indexl)  in items.list" :key="indexl"
            >

              <div class="parcel1_card" v-if="item.type == 1">
                <div class="choosebox">
                  <el-checkbox
                    :value="item.is_selected == 1 ? true : false"
                    @change="shopselect(item, index)"
                  ></el-checkbox>
                </div>
                <div class="shops">
                  <div class="oneshop">
                    <div class="img" @click="todetail(item.goods_id,itemd.active_type)">
                      <img :src="item.goods_image" :alt="item.goods_subtitle" />
                      <div class="metionstatus" v-if="item.goods_stock == 0">
                        已售罄
                      </div>
                    </div>
                    <div class="info">
                      <div class="info_name">
                        <span class="info_name_name">{{
                          item.goods_subtitle
                        }}</span>
                        <span class="info_name_price"
                          >￥{{ item.goods_price }}</span
                        >
                      </div>
                      <div class="info_size">
                        <span>{{ item.spec_value }}</span>
                      </div>
                      <div class="info_num">
                        <i
                          class="icon tardiness"
                          @click="shopsnumchange(item, index, 'less')"
                          >&#xe711;</i
                        >
                        <span>{{ item.goods_num }}</span>
                        <i
                          class="icon tardiness"
                          @click="shopsnumchange(item, index, 'plus')"
                          >&#xe60a;</i
                        >
                      </div>
                      <div class="info_btn">
                        <div class="desc" v-if="item.goods_warehouse == 2">
                          <i class="icon tardiness">&#xe602;</i>
                          <span
                            >此产品为跨境商品，更多信息请查看产品页或咨询我们</span
                          >
                        </div>
                        <div class="delete" @click="shopsdelete(item.car_id)">
                          删除
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="oneshop giftslist"
                    v-for="(v, i) in item.gift_list"
                    :key="i"
                  >
                    <div class="img">
                      <img :src="v.image" :alt="v.title" />
                    </div>
                    <div class="info">
                      <div class="info_name">
                        <span class="info_name_name">{{ v.title }}</span>
                      </div>
                      <p class="gifts gift_txt">赠品</p>
                      <p class="number">x 1</p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="cart_parcel">
                  <!-- <div class="activitybox">
              <div class="activityleftbox">
                <div class="activityname">满折</div>
                <span>已满2件，优惠9折</span>
              </div>
              <div class="activityrightbox">
                <span>再逛逛</span>
                <i class="icon tardiness">&#xe620;</i>
              </div>
            </div> -->
                  <div class="goods_check">
                    <div class="check">
                      <el-checkbox
                        :value="item.is_selected == 1 ? true : false"
                        @change="shopselect(item, index)"
                      ></el-checkbox>
                    </div>
                    <div class="img">
                      <img
                        :src="item.menu_image"
                        :alt="item.menu_title"
                        @click="tosuit(item.goods_id)"
                      />
                    </div>
                    <!-- <div class="name">{{ item.menu_title }}</div> -->

                    <div class="info">
                      <div class="info_name">
                        <span class="info_name_name">{{
                          item.menu_title
                        }}</span>
                      </div>
                      <div class="info_num">
                        <i
                          class="icon tardiness"
                          @click="shopsnumchange(item, index, 'less')"
                          >&#xe711;</i
                        >
                        <span>{{ item.goods_num }}</span>
                        <i
                          class="icon tardiness"
                          @click="shopsnumchange(item, index, 'plus')"
                          >&#xe60a;</i
                        >
                      </div>
                      <div class="info_btn">
                        <div class="desc" v-if="item.goods_warehouse == 2">
                          <i class="icon tardiness">&#xe602;</i>
                          <span
                            >此产品为跨境商品，更多信息请查看产品页或咨询我们</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="goods_details">
                    <div class="details_imgs">
                      <div
                        class="imgs"
                        v-for="(q, v) in item.sku_list"
                        :key="v"
                        @click="tosuit(q.goods_id)"
                      >
                        <div class="imgs_igm">
                          <img :src="q.image" :alt="q.subtitle" />
                        </div>
                        <div class="imgs_name">
                          {{ q.subtitle }}
                          <p class="info_size">{{ q.spec_value }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="details_desc">
                      <div class="desc_price">￥{{ item.menu_price }}</div>
                      <div
                        class="desc_delete"
                        @click="shopsdelete(item.car_id)"
                      >
                        删除
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         </div>

        <!-- 失效普通商品 -->
        <div v-if="invaliData.length > 0" class="normal-content">
          <div class="parcel_name">
            <div class="leftbox">
              <span class="sendwhere">已失效{{ invaliData.length }}商品</span>
            </div>
          </div>
          <div
            class="cart_parcel"
            v-for="(item, index) in invaliData"
            :key="index"
          >
            <div class="parcel1_card">
              <div class="choosebox">
                <el-checkbox disabled></el-checkbox>
              </div>
              <div class="shops">
                <div class="oneshop">
                  <div class="img invali">
                    <img :src="item.goods_image" :alt="item.goods_subtitle" />
                    <div class="metionstatus">商品已失效</div>
                  </div>
                  <div class="info">
                    <div class="info_name">
                      <span class="info_name_name">{{
                        item.goods_subtitle
                      }}</span>
                      <span class="info_name_price"
                        >￥{{ item.goods_price }}</span
                      >
                    </div>
                    <div class="info_size">
                      <span>{{ item.spec_value }}</span>
                      <!-- <span> 颜色:白色 <i class="icon">&#xe621;</i></span>
                    <span>尺码M<i class="icon">&#xe621;</i></span> -->
                    </div>
                    <div class="info_num">
                      <i class="icon tardiness">&#xe711;</i>
                      <span>{{ item.goods_num }}</span>
                      <i class="icon tardiness">&#xe60a;</i>
                    </div>
                    <div class="info_btn">
                      <!-- <div class="desc" v-if="item.goods_warehouse == 2">
                        <i class="icon tardiness">&#xe602;</i>
                        <span
                          >此产品为跨境商品，更多信息请查看产品页或咨询400 000
                          0000</span
                        >
                      </div> -->
                      <!-- <div class="like">
                      <i class="icon tardiness">&#xe75d;</i>移入收藏夹
                    </div> -->
                      <div class="delete" @click="shopsdelete(item.car_id)">
                        删除
                      </div>
                    </div>
                  </div>
                </div>
                <div class="oneshop" v-for="(v, i) in item.gift_list" :key="i">
                  <div class="img">
                    <img :src="v.image" :alt="v.title" />
                  </div>
                  <div class="info">
                    <div class="info_name">
                      <span class="info_name_name">{{ v.title }}</span>
                      <!-- <span class="info_name_price">￥500</span> -->
                    </div>
                    <p class="gifts gift_txt">赠品</p>
                    <p class="number">x 1</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="metions">
          <span class="coupstitle">有优惠码？请在此输入</span>
          <span class="coupsmetion"
            >提示：输入优惠券码后，请点击“确定”并检查是否生效。</span
          >
        </div> -->

        <!-- <el-input
          placeholder="请输入您的优惠券码"
          v-model="input"
          class="input-with-btn"
        >
          <el-button slot="append" class="changebtn" @click="ishow = true"
            >确定</el-button
          >
        </el-input> -->
      </div>

      <div class="lrbox_right">
        <total
          :total_price="total_price"
          :select_price="select_price"
          :selected_num="selected_num"
          :active_price="active_price"
          :delivery_info="delivery_info"
          :pay_way="pay_way"
          :electronic_invoice="electronic_invoice"
          :common_problem="common_problem"
          :order_desc="order_desc"
          :privacy_policy="privacy_policy"
          :sale_terms="sale_terms"
          @sbmit="carsubmit"
        ></total>
      </div>
    </div>
    <!-- 弹窗 -->
    <messageBox :show="ishow"></messageBox>
    <el-dialog
      title="温馨提示"
      :visible.sync="showsure"
      width="30%"
    >
      <div class="cossntent">
        <div>您购买的商品将从【保税仓】发货，请注意以下内容：</div>
        <div>1. 因海关政策要求，下单后不支持修改订单信息，包括但不限于收货人地址、手机号、商品规格等。物流不可中途拦截或拒收，请务必仔细确认订单信息；</div>
        <div>2. 每笔订单可包含的商品总价上限为5000元，个人年均跨境电商消费额度累计上限26000元，超限将无法清关；</div>
        <div>3. 受海关政策限制，保税仓发货的商品暂不支持无条件的7天无理由退换；</div>
        <div>4. 保税仓发货的商品视为从国外直接购买，根据相关政策无法为您提供发票。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sure">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import total from "../../components/cart/total";
import messageBox from "../../components/cart/messageBox";

export default {
  components: {
    total,
    messageBox,
  },
  data() {
    return {
      AllChecked: false,
      input: "",
      ishow: false,
      normalData: [], //有效购物车数据
      invaliData: [], //失效商品数据
      total_num: 0, //总数量
      selected_num: 0, //选中数量
      total_price: "", //总价
      select_price: "",
      active_price:"",
      order_desc: "", //说明

      delivery_info: {}, //配送方式
      pay_way: {}, //支付方式
      electronic_invoice: {}, //电子发票
      common_problem: {}, //常见问题
      privacy_policy: "",
      sale_terms: "",
      showsure: false,
      isbao: false,
    };
  },
  mounted() {
    this.getcaiList();
  },
  methods: {
    getcaiList() {
      this.$myAjax("car/car_list", "get", {}, (res) => {
        if (res.list && res.list.normal) {
          this.normalData = res.list.normal;
          this.hasNormalData = true;
          // let y=true
          //   this.normalData.map(i=>{
          //   if(i.list){
          //     i.list.map(ii=>{
          //       if(ii.list){
          //         ii.list.map(ib=>{
          //          if(ib.is_selected==2){
          //              y=false
          //          } 
          //         })
          //       }
          //     })
          //   }
          // })
       
          this.normalData.map(i=>{
            if(i.list){
              i.list.map(ii=>{
                  if(ii.list){
                  ii.list.map(ib=>{
                   if(ib.is_selected==1){
                      if(ii.name==="保税仓"){
                        console.log(ii.name)
                        this.isbao = true;
                      }
                   }
                  })
                }
              })
            }
          })
          console.log( this.isbao)
        } else {
          this.normalData = {};
          this.hasNormalData = false;
        }
        this.invaliData = res.list.invalid;
        this.total_num = res.total_count;
        this.selected_num = res.selected_count;
        this.total_price = res.total_price;
        this.select_price = res.selected_price;
        if(res.discount_price){
          this.active_price=res.discount_price;
        }
        this.order_desc = res.order_pay_explain;
        this.delivery_info = res.delivery_info || {};
        this.pay_way = res.pay_way || {};
        this.electronic_invoice = res.electronic_invoice || {};
        this.common_problem = res.common_problem || {};

        this.privacy_policy = res.privacy_policy;
        this.sale_terms = res.sale_terms;

        this.checks();
        this.get_goods_program();
      });
    },
    checks() {
      if (this.selected_num > 0 && this.selected_num === this.total_num) {
        this.AllChecked = true;
      } else {
        this.AllChecked = false;
      }
    },
    chooseAll() {
      console.log(this.AllChecked)
      let ids = [],
      Boolse = "";
      this.normalData.map(i=>{
            if(i.list){
              i.list.map(ii=>{
                if(ii.list){
                  ii.list.map(ib=>{
                    ids.push(ib.car_id);
                  })
                }
              })
            }
          })
      this.AllChecked ? (Boolse = 1) : (Boolse = 2);
      this.postchooses(ids.toString(), Boolse);
    },
    shopselect(item) {
      let Boolse = "";
      item.is_selected == 1
        ? (Boolse = 2)
        : (Boolse = 1);
          item.is_selected=item.is_selected == 1
        ? (Boolse = 2)
        : (Boolse = 1);
      this.postchooses(item.car_id, Boolse);
    },
    postchooses(id, _boolse) {
      this.$myAjax(
        "car/edit_car_selected",
        "post",
        {
          car_id: id,
          is_selected: _boolse,
        },
        () => {
          this.getcaiList();
        }
      );
    },
    selectshops(_index) {
      let url = "";
      if (this.normalData[_index].is_selected == 1) {
        url = "user/cancel_follow";
      } else {
        url = "user/add_follow";
      }
      this.$myAjax(
        url,
        "post",
        {
          goods_id: this.normalData[_index].goods_id,
        },
        () => {
          if (this.normalData[_index].is_selected == 1) {
            this.normalData[_index].is_selected = 2;
          } else {
            this.normalData[_index].is_selected = 1;
          }
        }
      );
    },
    shopsnumchange(_key, _index, type) {
      console.log(_key)
      let num = _key.goods_num;
      type == "less" ? num-- : type == "plus" ? num++ : "";
      if (num < 1) {
        return;
      }
      this.$myAjax(
        "car/edit_car_num",
        "post",
        {
          car_id: _key.car_id,
          type: _key.type,
          goods_num: num,
        },
        (res) => {
          if (num == 0) {
            this.getcaiList();
          } else {
            _key.goods_num = num;
            this.select_price = res.selected_price;
            // console.log(res)
          }
        }
      );
    },
    shopsdelete(_id) {
      this.$confirm("此操作将删除购物车商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$myAjax(
          "car/del_car",
          "post",
          {
            car_id: _id,
          },
          () => {
            this.$message({
              type: "success",
              message: "删除成功!",
              offset: 170,
            });
            this.getcaiList();
          }
        );
      });
    },
    shopsDeleteSelect() {
      let ids = [];
         this.normalData.map(i=>{
            if(i.list){
              i.list.map(ii=>{
                if(ii.list){
                  ii.list.map(ib=>{
                   if(ib.is_selected==1){
                     ids.push(ib.car_id);
                   }
                  })
                }
              })
            }
          })

      if (ids == "") {
        this.$message({
          type: "error",
          message: "至少选择一项",
          offset: 170,
        });
      } else {
        this.shopsdelete(ids.toString());
      }
    },
    shopsFollow() {
      let ids = [];
      // for (let index in this.normalData) {
      //   let normal = this.normalData[index];
      //   normal.map((item) => {
      //     if (item.is_selected == 1) {
      //       if (item.type == 2) {
      //         item.sku_list.map((v) => {
      //           ids.push(v.goods_id);
      //         });
      //       } else { 
      //         ids.push(item.goods_id);
      //       }
      //     }
      //   });
      // }
      this.normalData.map(i=>{
            if(i.list){
              i.list.map(ii=>{
                if(ii.list){
                  ii.list.map(ib=>{
                   if(ib.is_selected==1){
                    if(ib.type==1){
                      ids.push(ib.goods_id);
                    }
                   }
                  })
                }
              })
            }
          })

      ids = ids.toString();
      if (ids == "") {
        this.$message({
          type: "error",
          message: "至少选择一项",
          offset: 170,
        });
      } else {
        this.$myAjax(
          "user/add_follow",
          "post",
          {
            goods_id: ids,
          },
          () => {
            this.$message({
              type: "success",
              message: "成功移入收藏夹!",
              offset: 170,
            });
          }
        );
      }
    },
    showmetion() {
      this.$alert("跨境商品保税仓发货", "友情提示", {
        confirmButtonText: "确定",
        callback: () => {},
      });
    },
    todetail(_id,active_type) {
      console.log(active_type)
      this.$router.push({
        name: "flashSaleDetails",
        params: {
          s: window.btoa(`type=1&id=${_id}`),
        },
        query:{
        active_type:active_type
        },
      });
    },
    tosuit(_id) {
      this.$router.push({
        name: "suitDetails",
        query: {
          id: _id,
        },
      });
    },

    sure() {
      this.showsure=false
      this.isbao=false
    },
    toactive(item) {
       localStorage.removeItem("page")
     this.$router.push({
        name: "seriesDetailsactive",
        query: {
          active_type:item.active_type,
        },
      });
    },
    carsubmit() {
      let ids = [];
      // for (let index in this.normalData) {
      //   let normal = this.normalData[index];
      //   normal.map((item) => {
      //     if (item.is_selected == 1) {
      //       ids.push(item.car_id);
      //     }
      //   });
      // }

          this.normalData.map(i=>{
            if(i.list){
              i.list.map(ii=>{
                if(ii.list){
                  ii.list.map(ib=>{
                   if(ib.is_selected==1){
                      ids.push(ib.car_id);
                   }
                  })
                }
              })
            }
          })

      if (ids.length) {
        if (this.isbao == false) {
          this.$myAjax(
            "order/confirm_order",
            "post",
            {
              car_id: ids.toString(),
            },
            (res) => {
              sessionStorage.setItem("orderDetail", JSON.stringify(res));
              // this.$store.state.orderData = res;
              this.$router.push({ name: "confirmOrder" });
            }
          );
        } else {
          this.showsure = true;
        }
      } else {
        this.$message({
          message: "请先勾选商品",
          type: "warning",
          offset: 170,
        });
      }
    },
    get_goods_program() {
      this.$myAjax("common/order_program", "get", {}, (res) => {
        this.common_problem = res.common_problem || {};
        this.privacy_policy = res.privacy_policy || {};
        this.sale_terms = res.sale_terms || {};
        this.delivery_info = res.delivery_info || {};
        this.pay_way = res.pay_way || {};
        this.electronic_invoice = res.electronic_invoice || {};
        this.order_pay_explain = res.order_pay_explain || {};
      });
    },
  },
};
</script>
<style lang="less" scoped>
.myCart {
  margin-top: 140px;
  background: #f9f9f9;
  .lrbox {
    width: 80%;
    margin: auto;
    display: flex;
    padding-top: 40px;
    min-height: 900px;
    padding-bottom: 66px;
    justify-content: space-between;
    .lrbox_left {
      width: 57.5%;
      .cart_head {
        font-size: 24px;
        font-weight: 700;
        color: #000011;
        span {
          font-size: 16px;
          font-weight: 400;
          margin-left: 20px;
          color: #111;
        }
      }
      .normal-content {
        border-top: 1px solid #dcdcdc;
      }
      .parcel_name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // padding: 0 0 0 56px;
        // line-height: 50px;
        // height: 50px;
        margin-top: 30px;
        .leftbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 35px;
            height: 35px;
          }
          span {
            margin-left: 12px;
            color: #111111;
            font-size: 16px;
            &.sendwhere {
              border: 1px solid #e60012;
              border-radius: 5px;
              padding: 0 10px;
              height: 30px;
              line-height: 30px;
              color: #e60012;
            }
          }
        }
        .rightbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          .icon {
            font-size: 14px;
            color: #e60012;
            cursor: pointer;
          }
        }
      }
      .cart_parcel {
        padding: 32px 0;
        // border-bottom: 1px solid #dcdcdc;

        .activitybox {
          margin-top: 35px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 0 0 56px;
          justify-content: space-between;
          .activityleftbox {
            display: flex;
            flex-direction: row;
            align-items: center;
            .activityname {
              width: 45px;
              height: 20px;
              border: 1px solid #e60012;
              text-align: center;
              line-height: 20px;
              color: #e60012;
              font-size: 12px;
            }
            span {
              margin-left: 13px;
              color: #111111;
              font-size: 16px;
            }
          }
          .activityrightbox {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            span {
              color: #111111;
              font-size: 16px;
            }
            .icon {
              font-size: 14px;
            }
          }
        }
        .parcel1_card {
          display: flex;
          justify-content: space-around;
          .choosebox {
            margin-top: 32px;
          }
          .shops {
            flex: 1;
            display: flex;
            flex-direction: column;
            .oneshop {
              display: flex;
              flex-direction: row;
              margin-top: 37px;
              .img {
                width: 200px;
                height: 200px;
                background-color: #fff;
                text-align: center;
                margin-left: 45px;
                cursor: pointer;
                position: relative;
                img {
                  width: 100%;
                  height: 100%;
                }
                .metionstatus {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 40px;
                  background: rgba(0, 0, 0, 0.5);
                  color: #fff;
                  font-size: 14px;
                  text-align: center;
                  line-height: 40px;
                }
              }
              .invali {
                &::before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  background: #dcdcdc73;
                }
              }
              .info {
                margin-left: 34px;
                flex: 1;
                .info_name {
                  .info_name_name {
                    font-size: 20px;
                    color: #111;
                  }
                  .info_name_price {
                    font-size: 18px;
                    color: #111;
                    font-weight: 700;
                    float: right;
                  }
                }
                .gifts {
                  margin-top: 16px;
                  font-size: 14px;
                }
                .gift_txt {
                  display: inline-block;
                  border: 1px solid #e60012;
                  color: #e60012;
                  padding: 0 10px;
                  border-radius: 5px;
                }
                .number {
                  margin-top: 38px;
                  font-size: 14px;
                }
                .info_size {
                  margin-top: 16px;
                  font-size: 14px;
                  i.icon {
                    font-size: 10px;
                    margin-left: 2px;
                  }
                  span {
                    margin-right: 20px;
                    cursor: pointer;
                  }
                }

                .info_num {
                  width: 110px;
                  height: 40px;
                  border: 1px solid #ccc;
                  line-height: 40px;
                  text-align: center;
                  margin-top: 27px;
                  span {
                    margin: 0 10px;
                    font-size: 16px;
                  }
                  .icon {
                    margin: 0 10px;
                    cursor: pointer;
                    font-size: 14px;
                  }
                }
                .info_btn {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  margin-top: 40px;
                  .desc {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    .icon {
                      font-size: 14px;
                      cursor: pointer;
                    }
                    span {
                      font-size: 12px;
                      margin-left: 6px;
                    }
                  }
                  .like {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 12px;
                    cursor: pointer;
                    margin-right: 20px;
                    .icon {
                      font-size: 12px;
                      margin-right: 5px;
                    }
                    ._showred {
                      color: #e60012;
                    }
                  }
                  .delete {
                    text-decoration: underline;
                    font-size: 12px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .goods_check {
          display: flex;
          margin-top: 37px;
          .check {
            margin-top: -5px;
          }
          .img {
            width: 200px;
            height: 200px;
            padding: 10px;
            background-color: #fff;
            margin-left: 45px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            margin-left: 34px;
            font-size: 20px;
            color: #111;
          }
          .info {
            margin-left: 34px;
            flex: 1;
            .info_name {
              .info_name_name {
                font-size: 20px;
                color: #111;
              }
              .info_name_price {
                font-size: 18px;
                color: #111;
                font-weight: 700;
                float: right;
              }
            }
            .gifts {
              margin-top: 16px;
              font-size: 14px;
            }
            .number {
              margin-top: 38px;
              font-size: 14px;
            }
            .info_size {
              margin-top: 16px;
              font-size: 14px;
              i.icon {
                font-size: 10px;
                margin-left: 2px;
              }
              span {
                margin-right: 20px;
                cursor: pointer;
              }
            }
            .info_num {
              width: 110px;
              height: 40px;
              border: 1px solid #ccc;
              line-height: 40px;
              text-align: center;
              margin-top: 27px;
              span {
                margin: 0 10px;
                font-size: 16px;
              }
              .icon {
                margin: 0 10px;
                cursor: pointer;
                font-size: 14px;
              }
            }
            .info_btn {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-top: 40px;
              .desc {
                flex: 1;
                display: flex;
                flex-direction: row;
                .icon {
                  font-size: 14px;
                  cursor: pointer;
                }
                span {
                  font-size: 12px;
                  margin-left: 6px;
                }
              }
              .like {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 12px;
                cursor: pointer;
                margin-right: 20px;
                .icon {
                  font-size: 12px;
                  margin-right: 5px;
                }
                ._showred {
                  color: #e60012;
                }
              }
              .delete {
                text-decoration: underline;
                font-size: 12px;
                cursor: pointer;
              }
            }
          }
        }
        .goods_details {
          display: flex;
          margin-top: 18px;
          flex-direction: row;
          justify-content: space-between;
          .details_imgs {
            padding: 8px 60px 20px 60px;
            background-color: #eeeeeea8;
            width: 420px;
            .imgs {
              display: flex;
              align-content: center;
              margin-top: 12px;
              cursor: pointer;
              .imgs_igm {
                width: 120px;
                background-color: #fff;
                padding: 10px;
                height: 120px;
                margin-right: 20px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .imgs_name {
                height: 120px;
                font-size: 18px;
                .info_size {
                  margin-top: 16px;
                  font-size: 14px;
                }
              }
            }
          }
          .details_desc {
            display: flex;
            width: 38%;
            justify-content: space-around;
            align-items: center;
            // background-color: #eeeeeea8;
            div {
              font-size: 14px;
              color: #111;
            }
            .desc_price {
              font-size: 16px;
              color: #e60012;
            }
            .desc_like {
              min-width: 70px;
              cursor: pointer;
              font-size: 12px;
              .icon {
                font-size: 12px;
              }
            }
            .desc_delete {
              text-decoration: underline;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .chooseallbox {
        margin: 36px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        // padding-bottom: 35px;
        // border-bottom: 1px solid #dcdcdc;
        span {
          font-size: 16px;
          color: #111111;
          margin-left: 10px;
        }
        .delete {
          text-decoration: underline;
          font-size: 12px;
          cursor: pointer;
        }
        .follow {
          color: #e00b24;
          text-decoration: underline;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .metions {
        display: flex;
        flex-direction: row;
        align-items: center;
        .coupstitle {
          font-size: 14px;
          color: #111111;
        }
        .coupsmetion {
          margin-left: 18px;
          color: #666666;
          font-size: 14px;
        }
      }
      .input-with-btn {
        width: 600px;
        margin-top: 22px;
        .changebtn {
          width: 93px;
          background: #bfbfbf;
          color: #111;
          border: none;
          border-radius: 0;
          height: 40px;
        }
      }
    }
    .lrbox_right {
      width: 30.7%;
      padding-top: 10px;
    }
  }
}

.cossntent div{
  margin-top: 10px;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
}


 .normal-content{
    position: relative;
    width: 100%;
    padding-top: 1px;
  
       .item{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          margin-top: 24px;
          padding-left: 26px;
            >div:nth-child(1){
              width: 100px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 16px;
              border-radius: 18px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              border: 1px solid #e60012;
              color: #e60012;
            }
            
            >div:nth-child(2){
              width: calc(100% - 100px  - 120px);
              height: 30px;
              text-align: left;
              padding-left: 25px;
              line-height: 30px;
              color: #999999;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            
            >div:nth-child(3){
              width:80px;
              height: 30px;
              color: #999999;
              line-height: 30px;
              text-align: right;
            }
            
            >div:nth-child(4){
              width:40px;
              height: 30px;
              color: #999999;
              line-height: 30px;
            }
       }
  }
</style>
