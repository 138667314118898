<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="12"></navbar>
        <div class="mainbox">
          <div class="title">{{ database.title }}</div>
          <div class="scorebox">
            <div class="showbox" v-html="database.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      database: {},
    };
  },
  mounted() {
    this.getshops();
  },
  methods: {
    getshops() {
      this.$myAjax("user/online_service", "get", {}, (res) => {
        this.database = res;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      float: left;
      padding-bottom: 50px;
      flex: 1;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 20px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .scorebox {
        width: 100%;
        display: inline-block;
        .showbox {
          margin: 0 40px;
          padding: 40px 0;
        }
      }
    }
  }
}
</style>
