<template>
  <div>
    <div class="homepage">
      <div class="mw fromtop">
        <navbar :tag="5"></navbar>
        <div class="mainbox">
          <div class="title">可用积分（{{ useAbleScore }}）</div>
          <div v-if="!listdata.length" class="nodatas">
            <img src="@/assets/img/lessfoot.png" alt="" class="nodataimg" />
            <div class="nodatatext">暂无积分，购买商品得积分！</div>
          </div>
          <div v-else class="scorebox">
            <div class="titles">
              <div class="infos">来源/用途</div>
              <div class="times">时间</div>
              <div class="score">积分</div>
            </div>
            <div class="showbox">
              <div
                class="onerecord"
                v-for="(item, index) in listdata"
                :key="index"
              >
                <div class="recordinfo">
                  <div class="recordresult">
                    {{ item.type == 1 ? "收入" : "消耗" }}-{{ item.memo }}
                  </div>
                  <!-- <div class="recordordernumber">订单编号 C0000123456789</div> -->
                </div>
                <div class="recordtime">{{ item.createtime }}</div>
                <div class="recordscore">
                  {{ item.type == 1 ? "+" : "-" }}{{ item.score }}
                </div>
              </div>
            </div>
          </div>
          <div class="loadmore" v-if="page < totalpage" @click="pageUp">
            查看更多
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      listdata: [],
      useAbleScore: 0, //可用积分
      totalpage: 0,
      page: 1,
    };
  },
  mounted() {
    this.getScore();
  },
  methods: {
    getScore() {
      this.$myAjax(
        "user/score_list",
        "get",
        {
          page: this.page,
        },
        (res) => {
          this.listdata = this.listdata.concat(res.score_list.list);
          this.useAbleScore = res.usable_score_sum;
          this.totalpage = res.score_list.total_page;
        }
      );
    },
    pageUp() {
      this.page++;
      this.getScore();
    },
  },
};
</script>
<style lang="less" scoped>
.homepage {
  width: 100%;
  background: #fafafa;
  margin-top: 140px;
  display: inline-block;
  padding-bottom: 53px;
  .fromtop {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    .mainbox {
      margin-left: 21px;
      // width: 70%;
      background: #fff;
      min-height: 742px;
      flex: 1;
      float: left;
      padding-bottom: 50px;
      .title {
        padding: 28px 34px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        float: left;
        color: #000000;
        font-size: 20px;
      }
      .nodatas {
        display: inline-block;
        width: 268px;
        text-align: center;
        // top: 50%;
        left: 50%;
        margin: 125px 0 0 -148px;
        position: relative;
        .nodataimg {
          width: 268px;
          height: 167px;
          float: left;
        }
        .nodatatext {
          font-size: 20px;
          color: #000000;
          float: left;
          text-align: center;
          width: 100%;
        }
      }
      .scorebox {
        width: 100%;
        display: inline-block;
        .titles {
          padding: 24px 40px;
          width: 100%;
          float: left;
          border-bottom: 1px solid #e6e6e6;
          .infos {
            width: 70%;
            color: #000000;
            font-size: 16px;
            float: left;
          }
          .times {
            width: 20%;
            text-align: center;
            color: #000000;
            font-size: 16px;
            float: left;
          }
          .score {
            width: 10%;
            text-align: right;
            color: #000000;
            font-size: 16px;
            float: left;
          }
        }
        .showbox {
          margin: 0 40px;
          .onerecord {
            padding: 24px 0;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            float: left;
            .recordinfo {
              width: 70%;
              float: left;
              .recordresult {
                color: #000000;
                font-size: 14px;
              }
              .recordordernumber {
                color: #000000;
                font-size: 14px;
              }
            }
            .recordtime {
              color: #000000;
              font-size: 14px;
              width: 20%;
              text-align: center;
              float: left;
              line-height: 38px;
            }
            .recordscore {
              color: #000000;
              font-size: 14px;
              width: 10%;
              float: left;
              text-align: right;
              line-height: 38px;
            }
          }
        }
      }
      .loadmore {
        width: 153px;
        height: 45px;
        opacity: 1;
        background: #f2f2f2;
        border: 1px solid #e60012;
        position: relative;
        left: 50%;
        margin-left: -76.5px;
        background: #fff;
        margin-top: 50px;
        text-align: center;
        line-height: 45px;
        color: #e60012;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          color: #fff;
          background: #e60012;
        }
      }
    }
  }
}
</style>
